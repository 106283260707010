import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, Input, InputGroup, InputGroupAddon, InputGroupText, Card, CardBody, Spinner } from 'reactstrap';
import confirm from "reactstrap-confirm";
import { User } from './data/user';
import { RouteComponentProps } from 'react-router';
import { Account } from './data/accounts';
import { createUpdateFragment } from './data/database_object';


interface IState {
    accounts: Map<string, Account>;
    listSize: number;
    searchText: string;
    tempSearchText: string;
    removing: string,
    filter: string;
}

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
}

class AccountsView extends Component<IProps, IState> {
    timeout: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);
        this.state = {
            accounts: new Map(),
            listSize: 10,
            searchText: '',
            removing: null,
            tempSearchText: '',
            filter: 'NAME',
        };
        this.removeItem = this.removeItem.bind(this);
    }

    render(): JSX.Element {
        let accountList: string[] = [];
        if (this.state.searchText != null && this.state.searchText !== '') {
            for (let [accountId, account] of this.state.accounts) {
                if (account.getLowercaseName().indexOf(this.state.searchText) !== -1) {
                    accountList.push(accountId);
                }
            }
        } else {
            accountList = Array.from(this.state.accounts.keys());
        }

        accountList.sort((acAId, acBId) => {
            let acA = this.state.accounts.get(acAId);
            let acB = this.state.accounts.get(acBId);
            if (this.state.filter === 'ADMIN') {
                if (acA.isAdmin && !acB.isAdmin) {
                    return -1;
                } else if (acB.isAdmin && !acA.isAdmin) {
                    return 1;
                }
            }
            return acA.getLowercaseName().localeCompare(acB.getLowercaseName());
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All accounts</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Accounts</div>
                        <p className="cardSubTitle">All the managed user accounts. These are linked to email addresses that users and their guardians can use to access these accounts</p>
                        <div>
                            <Link to={'/accounts/-1'}>
                                <Button className="adminPagesButton" type="button">
                                    Add account
                        </Button>
                            </Link>&nbsp;&nbsp;
                    <Link to={'/accountsCsv'}>
                                <Button className="adminPagesButton" type="button">
                                    Csv upload
                        </Button>
                            </Link>
                        </div>
                        <br />
                        <div>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><span className="material-icons">search</span></InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder={"Search accounts"} type="text" name="tempSearchText" onChange={evt => this.doSearch(evt)} value={this.state.tempSearchText} />
                            </InputGroup>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name <Button disabled={this.state.filter === 'NAME'} color="link" onClick={async () => {
                                        this.setState({
                                            filter: 'NAME'
                                        })
                                    }}><i className="material-icons material-icons-xs">sort</i></Button>
                                    </th>
                                    <th>Additional</th>
                                    <th>Admin <Button disabled={this.state.filter === 'ADMIN'} color="link" onClick={async () => {
                                        this.setState({
                                            filter: 'ADMIN'
                                        })
                                    }}><i className="material-icons material-icons-xs">sort</i></Button>
                                    </th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accountList.slice(0, this.state.listSize).map((accountId) => {
                                    let account = this.state.accounts.get(accountId);
                                    return <tr key={`tr-${accountId}`}>
                                        <th key={`name-${accountId}`} scope="row"><Link to={'accounts/' + accountId}>{account.name}</Link></th>
                                        <td key={`additional-${accountId}`}>{account.additional}</td>
                                        <td key={`admin-${accountId}`}>{account.isAdmin != null && account.isAdmin ? "Yes" : ""}</td>
                                        <td key={`actions-${accountId}`}>{this.state.removing === accountId ? <Spinner /> : <Button color="link" disabled={accountId === this.props.user.accountId} onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this account", confirmText: "Confirm" });
                                            if (result) {
                                                this.removeItem(accountId)
                                            }
                                        }}><i className="material-icons">delete</i></Button>}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        {this.state.listSize < accountList.length ?
                            <div>
                                <p>Showing {this.state.listSize} of {accountList.length}</p>
                                <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.setState({
                                            listSize: this.state.listSize + 10
                                        })
                                    }}>
                                    Show more
                        </Button>&nbsp;&nbsp;
                        <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.setState({
                                            listSize: 999999
                                        })
                                    }}>
                                    Show all
                        </Button>
                            </div> :
                            <div>
                                <p>Showing {accountList.length}</p>
                            </div>
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    doSearch(e: React.ChangeEvent<HTMLInputElement>): void {
        let newSearchText = e.target.value; // this is the search text
        if (this.timeout) clearTimeout(this.timeout);
        this.setState({
            tempSearchText: newSearchText.toLowerCase(),
        });
        this.timeout = setTimeout(() => {
            this.setState({
                searchText: this.state.tempSearchText,
            });
        }, 300);
    }

    async componentDidMount(): Promise<void> {
        try {
            const accountsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts`);
            let snapshot = await accountsRef.once('value');
            let accounts = snapshot.val();
            let newState = new Map<string, Account>();
            for (let nextAccountId in accounts) {
                let nextAccount = Account.fromFirebase(accounts[nextAccountId]);
                if (!nextAccount.deactivated) {
                    newState.set(nextAccountId, nextAccount);
                }
            }

            this.setState({
                accounts: newState,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(accountId: string): Promise<void> {

        this.setState({
            removing: accountId,
        });

        let account = this.state.accounts.get(accountId);


        let assignedLicensesDoc = await firebase.firestore().doc(`schoolLicenses/${this.props.user.schoolId}/students/${accountId}`).get();
        let assignedLicenses = assignedLicensesDoc.data();

        let promises = [];
        if (assignedLicenses != null) {
            let removeAccountLicenses = firebase.functions().httpsCallable('removeAccountLicenses');
            for (let nextLicenseId of Object.keys(assignedLicenses)) {
                if (assignedLicenses[nextLicenseId]) {
                    promises.push(removeAccountLicenses({ "schoolId": this.props.user.schoolId, "accountIds": [accountId], "licenseId": nextLicenseId }));
                }
            }
        }
        for (let nextAccountGroupId of account.acGroups.keys()) {
            promises.push(firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${nextAccountGroupId}/accounts/${accountId}`).set({
                member: false,
                updated: firebase.database.ServerValue.TIMESTAMP,
            }));
        }
        promises.push(firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${accountId}`).remove());
        await Promise.all(promises);

        account.deactivated = true;
        account.title = "";
        account.personalName = "Removed";
        account.familyName = "Removed";
        account.name = "Removed";
        account.additional = "";
        account.remoteId = null;
        const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/${accountId}`);
        try {
            await accountRef.update(createUpdateFragment(account.toFirebase(), ["title", "personalName", "familyName", "name", "additional", "remoteId", "updated", "deactivated"]));
            this.state.accounts.delete(accountId);
            this.setState({
                removing: null,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }
}

export default AccountsView;
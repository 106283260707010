import { object } from 'prop-types';
import firebase from '../firebase';
import { objectToClassConverter } from './database_object';
import { Attachment } from './home_learning';

export enum TIME_MULTIPLIER { fastest = "fastest", fast = "fast", moderate = "moderate", slow = "slow", slowest = "slowest" }

export class LearningWorld {
    name: string = null;
    description: string = null;
    bossName: string = null;
    mission: string = null;
    image: string = null;
    pathSelection: boolean = null;
    pathIds: string[] = null;
    licenseId: string = null;
    licenseRequired: boolean = null;
    subject: string = null;
    introduction: string = null;
    topicIds: string[] = null;
    introductionAvatar: string = null;
    deleted: boolean = null;
    schoolId: string = null;
    grouped: boolean = null;

    static fromFirebase(data: any): LearningWorld {
        let newLearningWorld = new LearningWorld();
        if (data == null) {
            return newLearningWorld;
        }
        objectToClassConverter(data, newLearningWorld);

        return newLearningWorld;
    }

    toFirebase(): {} {
        let fbWorld: {} = Object.assign({}, this);
        return fbWorld;
    }
}

export class LearningPath {
    name: string = null;
    introduction: string = null;
    introductionAvatar: string = null;
    examBoards: string[] = null;
    licenseId: string = null;
    gameIds: string[] = null;
    zapEnabled: boolean = null;
    topologyId: string = null;
    timeMultiplier: TIME_MULTIPLIER = null;
    subjectId: string = null;
    topics: string[] = null;
    topicTags: Map<string, string[]> = null;
    introductionAvatarUrl: string = null;
    deleted: boolean = null;
    schoolId: string = null;

    static fromFirebase(data: any): LearningPath {
        let newLearningPath = new LearningPath();
        if (data == null) {
            return newLearningPath;
        }
        objectToClassConverter(data, newLearningPath, ["topicTags"]);

        return newLearningPath;
    }

    toFirebase(): {} {
        let fbPath: any = Object.assign({}, this);
        fbPath['topicTags'] = Object.fromEntries(this.topicTags);
        return fbPath;
    }
}

export enum QUESTION_TYPE { CHOICE = "CHOICE", SPELLING = "SPELLING", SPELLING_HIDDEN = "SPELLING_HIDDEN", TEXT = "TEXT", REORDER = "REORDER" }

export class LearningGame {
    schoolId: string = null;
    type: string = null;
    timeMultiplier: TIME_MULTIPLIER = null;
    deleted: boolean = null;
    updated: firebase.firestore.Timestamp = null;
    title: string = null;
    licenseId: string = null;
    subjectId: string = null;
    topics: string[] = null;
    topicTags: Map<string, string[]> = null;
    badge: LearningGameBadge = null;
    instructions: string = null;
    sound: string = null;
    audioInstructions: string = null;

    static fromFirebase(data: any): LearningGame {
        let newLearningGame = new LearningGame();
        if (data == null) {
            return newLearningGame;
        }
        objectToClassConverter(data, newLearningGame, ["topicTags"], ["badge"]);

        newLearningGame.badge = LearningGameBadge.fromFirebase(data.badge);

        return newLearningGame;
    }

    toFirebase(): {} {
        return;
    }

    getParentToFirebase(origData: any): void {
        origData["topicTags"] = Object.fromEntries(this.topicTags);
        if (this.badge != null) {
            origData["badge"] = this.badge.toFirebase();
        } else {
            origData["badge"] = null
        }
        origData.updated = firebase.firestore.FieldValue.serverTimestamp();
    }
}

export class Quizlet extends LearningGame {
    image: string = null;
    iconCodePoint: number = null;
    iconCodeString: string = null;
    iconFontFamily: string = null;
    iconFontPackage: string = null;
    hidden: boolean = null;
    videoUrl: string = null;
    attachments: Attachment[] = null;
    questionIds: string[] = null;

    static TYPE = "QUIZLET";

    static fromFirebase(data: any): Quizlet {
        let parent = LearningGame.fromFirebase(data);

        let newQuizlet = new Quizlet();
        if (data == null) {
            return newQuizlet;
        }
        objectToClassConverter(data, newQuizlet, [], ["attachments"]);

        let newAttachments = new Array<Attachment>();
        if (data.attachments != null) {
            for (let nextAttachment of data.attachments) {
                newAttachments.push(Attachment.fromFirebase(nextAttachment));
            }
        }
        newQuizlet.attachments = newAttachments;

        newQuizlet = Object.assign(newQuizlet, parent);

        return newQuizlet;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        this.getParentToFirebase(fbGame);
        let fbAttachments = [];
        if (this.attachments != null) {
            for (let nextAttachment of this.attachments) {
                fbAttachments.push(nextAttachment.toFirebase());
            }
        }
        fbGame['attachments'] = fbAttachments;
        return fbGame;
    }
}

export class BossGame extends LearningGame {
    bossTextBefore: string = null;
    bossTextAfter: string = null;
    bossTextFail: string = null;
    avatarTextBefore: string = null;
    avatarTextAfter: string = null;
    hidden: boolean = null;
    videoUrl: string = null;
    attachments: string = null;
    animalType: string = null;
    bossType: string = null;
    animalName: string = null;
    bossVehicleType: string = null;
    playerVehicleType: string = null;
    animalTypeUrl: string = null;
    bossTypeUrl: string = null;
    rounds: BossGameRound[] = null;

    static TYPE = "BOSSLEVEL";

    static fromFirebase(data: any): BossGame {
        let parent = LearningGame.fromFirebase(data);

        let newBossGame = new BossGame();
        if (data == null) {
            return newBossGame;
        }
        objectToClassConverter(data, newBossGame, [], ["rounds"]);

        let newRounds = new Array<BossGameRound>();
        if (data.rounds != null) {
            for (let nextRound of data.rounds) {
                newRounds.push(BossGameRound.fromFirebase(nextRound));
            }
        }
        newBossGame.rounds = newRounds;

        newBossGame = Object.assign(newBossGame, parent);

        return newBossGame;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        this.getParentToFirebase(fbGame);

        let roundData: any[] = [];
        if (this.rounds != null) {
            for (let nextRound of this.rounds) {
                roundData.push(nextRound.toFirebase());
            }
        }
        fbGame.rounds = roundData;

        return fbGame;
    }
}

export class BossGameRound {
    correct: string = null;
    incorrect: string[] = null;

    static fromFirebase(data: any): BossGameRound {
        let newBossGameRound = new BossGameRound();
        if (data == null) {
            return newBossGameRound;
        }
        objectToClassConverter(data, newBossGameRound);

        return newBossGameRound;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class CircleGame extends LearningGame {
    answerTemplate: string = null;
    rounds: CircleRound[] = null;
    incorrect: string[] = null;
    answersOrdered: boolean = null;

    static TYPE = "CIRCLE";

    static fromFirebase(data: any): CircleGame {
        let parent = LearningGame.fromFirebase(data);

        let newCircleGame = new CircleGame();
        if (data == null) {
            return newCircleGame;
        }
        objectToClassConverter(data, newCircleGame, [], ["rounds"]);

        let newRounds = new Array<CircleRound>();
        if (data.rounds != null) {
            for (let nextRound of data.rounds) {
                newRounds.push(CircleRound.fromFirebase(nextRound));
            }
        }
        newCircleGame.rounds = newRounds;

        newCircleGame = Object.assign(newCircleGame, parent);

        return newCircleGame;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        this.getParentToFirebase(fbGame);

        let circleRoundData: any[] = [];
        if (this.rounds != null) {
            for (let nextRound of this.rounds) {
                circleRoundData.push(nextRound.toFirebase());
            }
        }
        fbGame.rounds = circleRoundData;

        return fbGame;
    }
}

export class CircleRound {
    target: string = null;
    correct: string[] = null;

    static fromFirebase(data: any): CircleRound {
        let newCircleRound = new CircleRound();
        if (data == null) {
            return newCircleRound;
        }
        objectToClassConverter(data, newCircleRound);

        return newCircleRound;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class SplitGame extends LearningGame {

    static TYPE = "SPLIT";
    title: string;
    subjectId: string;
    topics: string[];
    topicTags: Map<string, string[]>;
    instructions: string;
    audioInstructions: string;
    type: string;
    schoolId: string;
    rounds: SplitRound[];

    static fromFirebase(data: any): SplitGame {
        let parent = LearningGame.fromFirebase(data);

        let newSplitGame = new SplitGame();
        if (data == null) {
            return newSplitGame;
        }
        objectToClassConverter(data, newSplitGame, []);
        let newSplit = new Array<SplitRound>();
        if (data.rounds != null) {
            for (let nextRound of data.rounds) {
                newSplit.push(SplitRound.fromFirebase(nextRound));
            }
        }
        newSplitGame.rounds = newSplit;

        newSplitGame = Object.assign(newSplitGame, parent);
        
        return newSplitGame;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        this.getParentToFirebase(fbGame);
        let splitRoundData: any[] = [];
        if (this.rounds != null) {
            for (let nextRound of this.rounds) {
                splitRoundData.push(nextRound.toFirebase());
            }
        }
        fbGame.rounds = splitRoundData;
        return fbGame;
    }
}

export class SplitRound {
    splitOne: string;
    splitTwo: string;

    static fromFirebase(data: any): SplitRound {
        let newSplitRound = new SplitRound();
        if (data == null) {
            return newSplitRound;
        }
        objectToClassConverter(data, newSplitRound);

        let newSplit = new Array<SplitRound>();
        if (data.rounds != null) {
            for (let nextRound of data.rounds) {
                newSplit.push(SplitRound.fromFirebase(nextRound));
            }
        }

        return newSplitRound;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class ChoiceGame extends LearningGame {
    correct: string[] = null;
    incorrect: string[] = null;

    static TYPE = "CHOICE"; 
    title: string;
    subjectId: string;
    topics: string[];
    topicTags: Map<string, string[]>;
    instructions: string;
    audioInstructions: string;
    type: string;
    schoolId: string;
    cards: ChoiceCard[] = null;
    buckets: ChoiceBucket[] = null;

    static fromFirebase(data: any): ChoiceGame {
        let parent = LearningGame.fromFirebase(data);

        let newChoiceGame = new ChoiceGame();
        if (data == null) {
            return newChoiceGame;
        }
        objectToClassConverter(data, newChoiceGame, [], ["cards"]);

        let newCards = new Array<ChoiceCard>();
        let newBuckets = new Array<ChoiceBucket>();
        if (data.cards != null) {
            for (let nextCard of data.cards) {
                newCards.push(ChoiceCard.fromFirebase(nextCard));
            }
        }
        if (data.buckets != null) {
            for (let nextBucket of data.buckets) {
                newBuckets.push(ChoiceBucket.fromFirebase(nextBucket));
            }
        }
        newChoiceGame.cards = newCards;
        newChoiceGame.buckets = newBuckets;

        newChoiceGame = Object.assign(newChoiceGame, parent);

        return newChoiceGame;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        this.getParentToFirebase(fbGame);

        let choiceCardData: any[] = [];
        let choiceBucketData: any[] = [];
        if (this.cards != null) {
            for (let nextCard of this.cards) {
                choiceCardData.push(nextCard.toFirebase());
            }
        }
        if (this.buckets != null) {
            for (let nextBucket of this.buckets) {
                choiceBucketData.push(nextBucket.toFirebase());
            }
        }
        fbGame.cards = choiceCardData;
        fbGame.buckets = choiceBucketData;

        return fbGame;
    }
}

export class ChoiceCard {
    title: string = null;
    bucketNumber: number = null;

    static fromFirebase(data: any): ChoiceCard {
        let newChoiceCard = new ChoiceCard();
        if (data == null) {
            return newChoiceCard;
        }
        objectToClassConverter(data, newChoiceCard);

        return newChoiceCard;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class ChoiceBucket {
    title: string = null;

    static fromFirebase(data: any): ChoiceBucket {
        let newChoiceBucket = new ChoiceBucket();
        if (data == null) {
            return newChoiceBucket;
        }
        objectToClassConverter(data, newChoiceBucket);

        return newChoiceBucket;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class GridGame extends LearningGame {
    answerTemplate: string = null;
    answers: Map<number, string[]> = null;
    grid: Map<number, string[]> = null;

    static TYPE = "GRID";

    static fromFirebase(data: any): GridGame {
        let parent = LearningGame.fromFirebase(data);

        let newGridGame = new GridGame();
        if (data == null) {
            return newGridGame;
        }
        objectToClassConverter(data, newGridGame, ["answers"], ["grid"]);

        let newGrid = new Map<number, string[]>();
        if (data.grid != null) {
            for (let nextGridIndex of Object.keys(data.grid)) {
                newGrid.set(parseInt(nextGridIndex), data.grid[nextGridIndex]);
            }
        }
        newGridGame.grid = newGrid;

        newGridGame = Object.assign(newGridGame, parent);

        return newGridGame;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        this.getParentToFirebase(fbGame);
        let answersObject: any = {};
        if (this.answers != null) {
            for (let [nextIndex, value] of this.answers) {
                answersObject[nextIndex.toString()] = value;
            }
        }
        fbGame['answers'] = answersObject;

        let gridObject: any = {};
        if (this.grid != null) {
            for (let [nextIndex, value] of this.grid) {
                gridObject[nextIndex.toString()] = value;
            }
        }
        fbGame['grid'] = gridObject;

        fbGame.updated = firebase.firestore.FieldValue.serverTimestamp();
        return fbGame;
    }
}

export class MTCGame extends LearningGame {
    firstOperandTimesTables: number[] = null;
    secondOperandTimesTables: number[] = null;
    questionCount: number = null;

    static TYPE = "MULSPEEDTEST";

    static fromFirebase(data: any): MTCGame {
        let parent = LearningGame.fromFirebase(data);

        let newMTCGame = new MTCGame();
        if (data == null) {
            return newMTCGame;
        }
        objectToClassConverter(data, newMTCGame);

        newMTCGame = Object.assign(newMTCGame, parent);

        return newMTCGame;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        this.getParentToFirebase(fbGame);
        fbGame.updated = firebase.firestore.FieldValue.serverTimestamp();
        return fbGame;
    }
}

export class TraceGame extends LearningGame {
    static TYPE = "TRACE"; 
    title: string;
    subjectId: string;
    topics: string[];
    topicTags: Map<string, string[]>;
    instructions: string;
    audioInstructions: string;
    type: string;
    schoolId: string;
    drawing: TraceGameDrawingOffset[] = null;

    static fromFirebase(data: any): TraceGame {
        let parent = LearningGame.fromFirebase(data);

        let newTraceGame = new TraceGame();
        if (data == null) {
            return newTraceGame;
        }
        objectToClassConverter(data, newTraceGame, [], ["drawing"]);

        let newDrawing = new Array<TraceGameDrawingOffset>();
        if (data.drawing != null) {
            for (let nextLine of data.drawing) {
                newDrawing.push(nextLine == null ? null : TraceGameDrawingOffset.fromFirebase(nextLine));
            }
        }
        newTraceGame.drawing = newDrawing;

        newTraceGame = Object.assign(newTraceGame, parent);

        return newTraceGame;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        this.getParentToFirebase(fbGame);

        let traceDrawingData: any[] = [];
        if (this.drawing != null) {
            for (let nextLine of this.drawing) {
                traceDrawingData.push(nextLine == null ? null : nextLine.toFirebase());
            }
        }
        fbGame.drawing = traceDrawingData;

        return fbGame;
    }
}

export class TraceGameDrawingOffset {
    dx: number = null;
    dy: number = null;

    static fromFirebase(data: any): TraceGameDrawingOffset {
        let newTraceGameDrawingOffset = new TraceGameDrawingOffset();
        if (data == null) {
            return newTraceGameDrawingOffset;
        }
        objectToClassConverter(data, newTraceGameDrawingOffset);

        return newTraceGameDrawingOffset;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class ShootGame extends LearningGame {
    answerTemplate: string = null;
    rounds: ShootRound[] = null;
    answersOrdered: boolean = null;

    static TYPE = "SHOOT";

    static fromFirebase(data: any): ShootGame {
        let parent = LearningGame.fromFirebase(data);

        let newShootGame = new ShootGame();
        if (data == null) {
            return newShootGame;
        }
        objectToClassConverter(data, newShootGame, [], ["rounds"]);

        let newRounds = new Array<ShootRound>();
        if (data.rounds != null) {
            for (let nextRound of data.rounds) {
                newRounds.push(ShootRound.fromFirebase(nextRound));
            }
        }
        newShootGame.rounds = newRounds;

        newShootGame = Object.assign(newShootGame, parent);

        return newShootGame;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        this.getParentToFirebase(fbGame);

        let roundData: any[] = [];
        if (this.rounds != null) {
            for (let nextRound of this.rounds) {
                roundData.push(nextRound.toFirebase());
            }
        }
        fbGame.rounds = roundData;

        fbGame.updated = firebase.firestore.FieldValue.serverTimestamp();
        return fbGame;
    }
}

export class ShootRound {
    target: string = null;
    correct: string[] = null;
    incorrect: string[] = null;

    static fromFirebase(data: any): ShootRound {
        let newShootRound = new ShootRound();
        if (data == null) {
            return newShootRound;
        }
        objectToClassConverter(data, newShootRound);

        return newShootRound;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class SliceGame extends LearningGame {
    rounds: SliceRound[] = null;

    static TYPE = "SLICE";

    static fromFirebase(data: any): SliceGame {
        let parent = LearningGame.fromFirebase(data);

        let newSliceGame = new SliceGame();
        if (data == null) {
            return newSliceGame;
        }
        objectToClassConverter(data, newSliceGame, [], ["rounds"]);

        let newRounds = new Array<SliceRound>();
        if (data.rounds != null) {
            for (let nextRound of data.rounds) {
                newRounds.push(SliceRound.fromFirebase(nextRound));
            }
        }
        newSliceGame.rounds = newRounds;

        newSliceGame = Object.assign(newSliceGame, parent);

        return newSliceGame;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        this.getParentToFirebase(fbGame);

        let roundData: any[] = [];
        if (this.rounds != null) {
            for (let nextRound of this.rounds) {
                roundData.push(nextRound.toFirebase());
            }
        }
        fbGame.rounds = roundData;

        return fbGame;
    }
}

export class SliceRound {
    correct: string[] = null;
    incorrect: string[] = null;

    static fromFirebase(data: any): SliceRound {
        let newSliceRound = new SliceRound();
        if (data == null) {
            return newSliceRound;
        }
        objectToClassConverter(data, newSliceRound);

        return newSliceRound;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class Question {
    text: string = null;
    imageUrl: string = null;
    explainer: string = null;
    questionType: QUESTION_TYPE = null;
    answers: string[] = null;
    answerImages: string[] = null;
    choices: string[] = null;
    choiceImages: string[] = null;
    topics: string[] = null;
    topicTags: Map<string, string[]> = null;
    subjectId: string = null;
    updated: firebase.firestore.Timestamp = null;
    deleted: boolean = null;
    schoolId: string = null;

    static fromFirebase(data: any): Question {
        let newQuestion = new Question();
        if (data == null) {
            return newQuestion;
        }
        objectToClassConverter(data, newQuestion, ["topicTags"]);

        return newQuestion;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        fbGame['topicTags'] = Object.fromEntries(this.topicTags);
        fbGame.updated = firebase.firestore.FieldValue.serverTimestamp();
        return fbGame;
    }
}

export class LearningImage {
    name: string = null;
    path: string = null;
    url: string = null;

    static fromFirebase(data: any): LearningImage {
        let newLearningImage = new LearningImage();
        if (data == null) {
            return newLearningImage;
        }
        objectToClassConverter(data, newLearningImage);

        return newLearningImage;
    }
}

export class LearningGameBadge {
    name: string = null;
    badgeImageUrl: string = null;
    networkBadge: boolean = null;
    overlayText: string = null;

    static fromFirebase(data: any): LearningGameBadge {
        if (data == null) {
            return null;
        }
        let newLearningGameBadge = new LearningGameBadge();
        objectToClassConverter(data, newLearningGameBadge);

        return newLearningGameBadge;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        return fbObject;
    }
}
import firebase from '../firebase';
import { objectToClassConverter } from './database_object';

export enum CHAT_MESSAGE_TYPE { TEXT, IMAGE, MAP, POLL }

export class ModerationChatStatus {
    text: string = null;
    timestamp: number = null;
    type: CHAT_MESSAGE_TYPE = null;

    static fromFirebase(data: any): ModerationChatStatus {
        let newModerationChatStatus = new ModerationChatStatus();
        if (data == null) {
            return newModerationChatStatus;
        }
        objectToClassConverter(data, newModerationChatStatus);

        return newModerationChatStatus;
    }
}

export class ChatMessage {
    accountId: string = null;
    subAccountId: string = null;
    text: string = null;
    timestamp: number = null;
    type: CHAT_MESSAGE_TYPE = null;
    deleted: boolean = null;

    static fromFirebase(data: any): ChatMessage {
        let newChatMessage = new ChatMessage();
        if (data == null) {
            return newChatMessage;
        }
        objectToClassConverter(data, newChatMessage);

        return newChatMessage;
    }
}
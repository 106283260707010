import React, { Component } from 'react';
import firebase from './firebase';
import queryString from 'query-string';
import {
    Button, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Table, Card, CardBody
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import { User, USER_ROLE } from './data/user';
import { v4 as uuidv4 } from 'uuid';
import { Adventure, AdventureAdventureSection, InformationSectionStub, AdventureSectionChoice, AdventureSectionStub, AdventureResponse } from './data/adventures';

interface IState {
    adventureId: string;
    responseId: string;
    response: AdventureResponse;
    adventure: Adventure;
    homeworkId: string;
    homeworkActivityId: string;
    homeworkName: string;
    homeworkActivityName: string;
}

interface MatchParams {
    homeworkId: string;
    adventureId: string;
    responseId: string;
    homeworkActivityId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class AdventurePathView extends Component<IProps, IState> {
    adventureSections = new Map<string, AdventureSectionStub>();
    informationSections = new Map<string, InformationSectionStub>();

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            homeworkName: values.homeworkName == null ? "" : values.homeworkName.toString(),
            homeworkActivityName: values.homeworkActivityName == null ? "" : values.homeworkActivityName.toString(),
            adventureId: props.match.params.adventureId,
            responseId: props.match.params.responseId,
            homeworkId: props.match.params.homeworkId,
            homeworkActivityId: props.match.params.homeworkActivityId,
            response: null,
            adventure: null,
        };
        this.getQuerys = this.getQuerys.bind(this);
    }

    render(): JSX.Element {
        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/homeworks'}>All homework</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={`/homeworks/${this.state.homeworkId}`}>{this.state.homeworkName}</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={`/homeworkActivityAdventure/${this.state.homeworkId}/${this.state.homeworkActivityId}`}>{this.state.homeworkActivityName}</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.state.adventure == null ? "Adventure" : this.state.adventure.details.title}</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">{this.state.adventure == null ? "Adventure" : this.state.adventure.details.title}</div>
                    <p className="cardSubTitle">The path that the student followed through the adventure</p>
                    {this.state.response == null || this.state.adventure == null ? <span /> :
                        this.state.response.adventureSectionHistory.map((sectionId) => {
                            return <div><Link to={`/adventureSections/${sectionId}?${this.getQuerys()}`}>{this.state.adventure.adventureSections.get(sectionId).title}</Link></div>
                        })
                    }
                </CardBody>
            </Card>
        </div>;
    }

    getQuerys(): string {
        return queryString.stringify({
            adventureId: this.state.adventureId,
            adventureName: this.state.adventure == null ? "" : this.state.adventure.details.title,
        });
    }

    async componentDidMount(): Promise<void> {
        const responseSnapshot = await firebase.firestore().doc(`adventureResponses/${this.state.responseId}`).get();
        let response = AdventureResponse.fromFirebase(responseSnapshot.data());

        const adventureSnapshot = await firebase.firestore().doc(`adventures/${this.state.adventureId}`).get();
        let adventure = Adventure.fromFirebase(adventureSnapshot.data());

        this.setState({
            response: response,
            adventure: adventure,
        });
    }
}

export default AdventurePathView;
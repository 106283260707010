import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import {
    FormGroup, Label, Input, Button, Spinner, Card, CardBody
} from 'reactstrap';
import ImageUpload from "./ninja_components/image_upload";
import { User } from './data/user';
import { Account } from './data/accounts';
import { sendPasswordResetEmail } from './helpers/email_services';

interface IState {
    adminAccount: {},
    currentAdminAccountName: string;
    currentAdminAccountTitle: string;
    currentAdminAccountPersonalName: string;
    currentAdminAccountFamilyName: string;
    currentAdminAccountAdditional: string;
    currentAdminAccountAvatarUrl: string;
    currentAdminAccountAvatarPath: string;
    loading: boolean;
    passwordReset: boolean;
}

interface IProps {
    user: User;
    tutorialCallback: (pageName: string) => void;
    snackbar: (text?: string) => void;
}

class MyAccountView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            adminAccount: {},
            currentAdminAccountName: '',
            currentAdminAccountTitle: '',
            currentAdminAccountPersonalName: '',
            currentAdminAccountFamilyName: '',
            currentAdminAccountAdditional: '',
            currentAdminAccountAvatarUrl: '',
            currentAdminAccountAvatarPath: '',
            loading: true,
            passwordReset: false,
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleImageChangeFail = this.handleImageChangeFail.bind(this);
    }

    render(): JSX.Element {

        return (
            <Card className="mainCard top-buffer">
                <CardBody className="d-flex flex-column">
                    <br />
                    <div className="cardTitle">My account details</div>
                    <p className="cardSubTitle">You can change your avatar here. Other changes can only be done through the admin management screens.</p>

                    <ImageUpload callback={this.handleImageChange} failureCallback={this.handleImageChangeFail} filePath={"adminAvatars"} accountId={this.props.user.accountId}
                        currentImage={{ url: this.state.currentAdminAccountAvatarUrl, path: this.state.currentAdminAccountAvatarPath }} imageLegend={"Avatar"} />

                    <FormGroup>
                        <Label>Your login details</Label>
                        <div>&nbsp;&nbsp;{this.props.user.email}</div><br />
                        &nbsp;&nbsp;<Button disabled={this.state.passwordReset} className="adminPagesButton" onClick={async () => {
                            this.setState({
                                passwordReset: true,
                            });
                            await sendPasswordResetEmail(this.props.user.email, this.props.snackbar);
                            this.setState({
                                passwordReset: false,
                            });
                        }}>
                            {this.state.passwordReset ? <span><Spinner size="sm"></Spinner>&nbsp;</span> : ""}Change password
                        </Button>
                    </FormGroup>

                    {this.props.user.permissions.includes('ACCOUNT_MANAGER') ?
                        <div>You are an account manager, so can modify your details <Link to={`accounts/${this.props.user.accountId}`}>here</Link></div>
                        : <div>To change these values contact an account manager in your organisation</div>
                    }
                    <FormGroup>
                        <Label for="currentAdminAccountTitle">Title</Label>
                        <Input type="text" disabled name="currentAdminAccountTitle" value={this.state.currentAdminAccountTitle} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="currentAdminAccountPersonalName">First name</Label>
                        <Input type="text" disabled name="currentAdminAccountPersonalName" value={this.state.currentAdminAccountPersonalName} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="currentAdminAccountFamilyName">Surname</Label>
                        <Input type="text" disabled name="currentAdminAccountFamilyName" value={this.state.currentAdminAccountFamilyName} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="currentAdminAccountAdditional">Additional information</Label>
                        <Input type="text" disabled name="currentAdminAccountAdditional" value={this.state.currentAdminAccountAdditional} />
                    </FormGroup>
                    <br /><br />
                </CardBody>
            </Card>
        );
    }

    async handleImageChange(url: string, path: string): Promise<void> {
        let schoolAccount = this.props.user.accountId;
        const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/admins/${schoolAccount}`);
        try {
            await accountRef.update({ avatarUrl: url, avatarPath: path });
            this.setState({
                currentAdminAccountAvatarUrl: url,
                currentAdminAccountAvatarPath: path
            });
            this.props.snackbar();
            this.props.tutorialCallback("myAccount");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    handleImageChangeFail(error: string): void {
        this.props.snackbar(error);
    }

    async componentDidMount(): Promise<void> {
        let schoolAccount = this.props.user.accountId;
        const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/${schoolAccount}`);
        let accountSnapshot = await accountRef.once('value');
        let account = Account.fromFirebase(accountSnapshot.val());

        const adminRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/admins/${schoolAccount}`);
        let adminAccountSnapshot = await adminRef.once('value');
        let adminAccount = adminAccountSnapshot.val();

        this.setState({
            loading: false,
            currentAdminAccountName: account.name,
            currentAdminAccountTitle: account.title,
            currentAdminAccountPersonalName: account.personalName,
            currentAdminAccountFamilyName: account.familyName,
            currentAdminAccountAdditional: account.additional,
            currentAdminAccountAvatarUrl: adminAccount.avatarUrl != null ? adminAccount.avatarUrl : '',
            currentAdminAccountAvatarPath: adminAccount.avatarPath != null ? adminAccount.avatarPath : '',
        });
    }
}

export default MyAccountView;
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { User } from "../data/user";
import firebase from '../firebase';
import {
    Button, Alert, Form, FormGroup, Label, Input, Collapse, Card, CardHeader, CardBody, Table, Spinner, Breadcrumb,
    BreadcrumbItem
} from 'reactstrap';
import { AccountGroup, AccountMinimised } from "../data/accounts";
import { StudentTopicOverview, Subject, Topic } from "../data/home_learning";
import { subjectIds } from '../Prettifier';
import queryString from 'query-string';
import { SubjectObjectives } from "../data/objectives";
import { getObjectives } from "../helpers/objectives_service";

interface TagAnalysis {
    passed: boolean;
    lastPassed: boolean;
    secure: boolean;
    attempted: boolean;
}

interface ShowAccountListDetails {
    title: string;
    includedAccountIds: string[];
    excludedAccountIds: string[];
}

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
    accountGroups: Map<string, AccountGroup>;
}

interface IState {
    dataLoaded: boolean;
    dataLoading: boolean;
    currentReportSubjectId: string;
    currentReportObjectiveCategoryId: string;
    currentReportAccountId: string;
    newReportAccountId: string;
    newReportSubjectId: string;
    newReportObjectiveCategoryId: string;
    availableAccountIds: Array<string>;
    topicAnalysis: StudentTopicOverview;
    showReportDetails: boolean;
    topics: Map<string, Topic>;
    expandedObjectiveId: string;
    showAccountListDetails: ShowAccountListDetails;
    collapse: boolean;
    objectives: Map<string, SubjectObjectives>;
}

class StudentObjectivesReportView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            dataLoaded: false,
            dataLoading: false,
            currentReportSubjectId: null,
            currentReportAccountId: null,
            newReportSubjectId: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
            newReportObjectiveCategoryId: "",
            newReportAccountId: "",
            availableAccountIds: [],
            topicAnalysis: null,
            collapse: true,
            showReportDetails: true,
            showAccountListDetails: null,
            expandedObjectiveId: null,
            topics: null,
            objectives: getObjectives(),
            currentReportObjectiveCategoryId: null,
        };
        this.createTopicAnalysisReport = this.createTopicAnalysisReport.bind(this);
        this.handleReportChange = this.handleReportChange.bind(this);
        this.loadTopicAnalysis = this.loadTopicAnalysis.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.toggleShowReportDetails = this.toggleShowReportDetails.bind(this);
    }

    render(): JSX.Element {

        let orderedAllIds = Array.from(this.props.accountsMinimised.keys());
        orderedAllIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).name.localeCompare(this.props.accountsMinimised.get(id2).name);
        });

        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/reporting/zapMathsReporting'}>All Reports</Link></BreadcrumbItem>
                    <BreadcrumbItem>Student Objectives Report</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Student objectives report</div>
                    <p className="cardSubTitle">View a specific student's attainment against objectives.</p>
                    {this.state.dataLoaded ?
                        <Button onClick={this.toggleShowReportDetails} className="altButton" style={{ width: '9%', float: 'right' }}>
                            {this.state.showReportDetails ? "Close" : "Edit report"}
                        </Button> : null
                    }
                    <Collapse isOpen={this.state.showReportDetails}>
                        <Form onSubmit={this.handleReportChange}>
                            <FormGroup>
                                <Label for="newReportAccountId">Account *</Label>
                                <Input type="select" required name="newReportAccountId"
                                    onChange={(e) => this.setState({
                                        newReportAccountId: e.target.value
                                    })}>
                                    <option value={""}>&nbsp;</option>
                                    {orderedAllIds.map((key) => {
                                        let additional = "";
                                        let account = this.props.accountsMinimised.get(key);
                                        if (account.additional != null && account.additional !== '') {
                                            additional = ` - ${account.additional}`;
                                        }
                                        return (
                                            <option value={key}
                                                key={key}>{account.name + additional}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="newReportSubjectId">Subject *</Label>
                                <Input required type="select" name="newReportSubjectId"
                                    onChange={(e) => this.setState({
                                        newReportSubjectId: e.target.value
                                    })}
                                    value={this.state.newReportSubjectId}>
                                    <option value={''}>&nbsp;</option>
                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                        let subjectName = subjectIds.get(subjectId);
                                        return <option value={subjectId}>{subjectName}</option>;
                                    })}
                                </Input>
                            </FormGroup>
                            {this.state.newReportSubjectId == null || this.state.newReportSubjectId === "" ? <span /> :
                                !this.state.objectives.has(this.state.newReportSubjectId) ? <p>There are no objectives defined for this subject</p> :
                                    <React.Fragment>
                                        <FormGroup>
                                            <Label for="newReportObjectiveCategoryId">Objective category *</Label>
                                            <Input required type="select" name="newReportObjectiveCategoryId"
                                                onChange={(e) => this.setState({
                                                    newReportObjectiveCategoryId: e.target.value
                                                })}
                                                value={this.state.newReportObjectiveCategoryId}>
                                                <option value={''}>&nbsp;</option>
                                                {Array.from(this.state.objectives.get(this.state.newReportSubjectId).categories.keys()).map(objectiveCategoryId => {
                                                    let objectiveCategory = this.state.objectives.get(this.state.newReportSubjectId).categories.get(objectiveCategoryId);
                                                    return <option value={objectiveCategoryId}>{objectiveCategory.title}</option>;
                                                })}
                                            </Input>
                                        </FormGroup>
                                        <Button className="adminPagesButton">{this.state.dataLoading ? <Spinner size="sm"></Spinner> : <span />}Run report</Button>
                                    </React.Fragment>
                            }
                        </Form>
                    </Collapse>
                </CardBody>
            </Card>
            {this.state.dataLoaded ?
                <Card className="mainCard top-buffer">
                    <CardBody className="d-flex flex-column">
                        {this.createTopicAnalysisReport()}
                    </CardBody>
                </Card> : <span />
            }
            <br /><br />
        </div>
    }

    toggleShowReportDetails() {
        this.setState({
            showReportDetails: !this.state.showReportDetails
        });
    }

    async handleReportChange(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        this.setState({
            dataLoading: true,
        });

        if (this.state.newReportSubjectId != this.state.currentReportSubjectId ||
            this.state.newReportAccountId != this.state.currentReportAccountId) {
            await this.loadTopicAnalysis(this.state.newReportSubjectId, this.state.newReportAccountId);
        }

        this.setState({
            currentReportSubjectId: this.state.newReportSubjectId,
            currentReportObjectiveCategoryId: this.state.newReportObjectiveCategoryId,
            currentReportAccountId: this.state.newReportAccountId,
            showReportDetails: false,
            dataLoaded: true,
            dataLoading: false,
        });
    }

    async loadTopicAnalysis(subjectId: string, accountId: string) {
        const subjectRef = firebase.firestore().collection(`learningTopics`).where('subjectId', '==', subjectId);
        let subjectSnapshot = await subjectRef.get();
        let topics = new Map<string, Topic>();
        subjectSnapshot.forEach((docSnapshot) => {
            let subjectData = docSnapshot.data();
            let subject = Subject.fromFirebase(subjectData);
            for (let nextTopicId of Array.from(subject.topics.keys())) {
                topics.set(nextTopicId, subject.topics.get(nextTopicId));
            }
        });

        const topicOverviewRef = firebase.firestore().doc(`studentTopicOverviews/${subjectId}--${accountId}`);
        let topicOverviewSnapshot = await topicOverviewRef.get();
        let topicOverview = StudentTopicOverview.fromFirebase(topicOverviewSnapshot.data());

        this.setState({
            topics: topics,
            topicAnalysis: topicOverview,
        });
    }

    handleAccountChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            newReportAccountId: e.target.value,
        });
    }

    createTopicAnalysisReport(): JSX.Element {
        let groups = this.state.objectives.get(this.state.currentReportSubjectId).categories.get(this.state.currentReportObjectiveCategoryId);

        return <Table style={{ "borderCollapse": "separate", "borderSpacing": "1px" }}>
            <tr style={{ "backgroundColor": "#70309b" }}>
                <th style={{ "color": "white" }}>Objective</th>
                <th style={{ "color": "white" }}>Details</th>
                <th style={{ "color": "white" }}>Attempted</th>
                <th style={{ "color": "white" }}>Flawless at least once</th>
                <th style={{ "color": "white" }}>Flawless last attempt</th>
                <th style={{ "color": "white" }}>Secure</th>
            </tr>
            {Array.from(groups.objectiveGroups.keys()).map((objectiveGroupId) => {
                let objectiveGroup = groups.objectiveGroups.get(objectiveGroupId);
                return <React.Fragment>
                    <tr style={{ "backgroundColor": "darkGrey" }}>
                        <td colSpan={6} style={{ "color": "white" }}>{objectiveGroup.title}</td>
                    </tr>
                    {Array.from(objectiveGroup.objectives.keys()).map((objectiveId) => {
                        let objective = objectiveGroup.objectives.get(objectiveId);
                        let totalAttempted = false;
                        let totalPassed = false;
                        let totalLastPassed = false;
                        let totalSecure = false;
                        let topicsDisplayAnalysis = new Map<string, Map<string, TagAnalysis>>();
                        let objectiveAttempted = false;
                        let objectiveLastPassed = true;
                        let objectivePassed = true;
                        let objectiveSecure = true;
                        let analysis = this.state.topicAnalysis;
                        if (analysis == null) {
                            analysis = new StudentTopicOverview();
                        }
                        if (objective.topicTags.size == 0) {
                            objectiveAttempted = false;
                            objectiveLastPassed = false;
                            objectivePassed = false;
                            objectiveSecure = false;
                        }
                        topicLoop: for (let nextTopicId of objective.topicTags.keys()) {
                            if (!topicsDisplayAnalysis.has(nextTopicId)) {
                                topicsDisplayAnalysis.set(nextTopicId, new Map());
                            }
                            if (!analysis.topics.has(nextTopicId)) {
                                objectiveAttempted = false;
                            }
                            let topicDisplayAnalysis = topicsDisplayAnalysis.get(nextTopicId);
                            for (let nextTagId of objective.topicTags.get(nextTopicId)) {
                                if (!topicDisplayAnalysis.has(nextTagId)) {
                                    topicDisplayAnalysis.set(nextTagId, { secure: false, lastPassed: false, attempted: false, passed: false });
                                }
                                let tagDisplayAnalysis = topicDisplayAnalysis.get(nextTagId);
                                if (analysis.topics.has(nextTopicId) && analysis.topics.get(nextTopicId).has(nextTagId)) {
                                    tagDisplayAnalysis.attempted = true;
                                    objectiveAttempted = true;
                                    let secureCount = 0;
                                    let passed = false;
                                    let historyCount = 0;
                                    let history = analysis.topics.get(nextTopicId).get(nextTagId).hist;
                                    history.sort((entry1, entry2) => {
                                        return entry1.dt - entry2.dt;
                                    });
                                    history.reverse();
                                    for (let nextEntry of history) {
                                        if (nextEntry.sc) {
                                            if (historyCount < 4) {
                                                secureCount++;
                                            }
                                            passed = true;
                                        }
                                        historyCount++;
                                    }
                                    if (passed) {
                                        tagDisplayAnalysis.passed = true;
                                    } else {
                                        objectivePassed = false;
                                    }
                                    if (secureCount == 0) {
                                        objectiveLastPassed = false;
                                    } else {
                                        tagDisplayAnalysis.lastPassed = true;
                                    }
                                    if (secureCount < 3) {
                                        objectiveSecure = false;
                                    } else {
                                        tagDisplayAnalysis.secure = true;
                                    }
                                } else {
                                    objectiveSecure = false;
                                    objectiveLastPassed = false;
                                    objectivePassed = false;
                                    break topicLoop;
                                }
                            }
                        }
                        if (objectivePassed) {
                            totalPassed = true;
                        }
                        if (objectiveAttempted) {
                            totalAttempted = true;
                        }
                        if (objectiveLastPassed) {
                            totalLastPassed = true;
                        }
                        if (objectiveSecure) {
                            totalSecure = true;
                        }
                        let row = <tr className="border-top">
                            <td colSpan={objective.title == null || objective.title === "" ? 2 : 1} className="border-top">
                                <Button color="link" style={{ textAlign: "left" }} onClick={() => {
                                    this.setState({
                                        expandedObjectiveId: this.state.expandedObjectiveId === objectiveId ? null : objectiveId
                                    });
                                }}>
                                    {objective.title == null || objective.title === "" ? objective.description : objective.title}
                                </Button>
                            </td>
                            {objective.title != null && objective.title !== "" ? <td className="border-top">{objective.description}</td> : null}
                            <td className="border-top" style={totalAttempted ? { "backgroundColor": "green" } : { "backgroundColor": "red" }}></td>
                            <td className="border-top" style={totalPassed ? { "backgroundColor": "green" } : { "backgroundColor": "red" }}></td>
                            <td className="border-top" style={totalLastPassed ? { "backgroundColor": "green" } : { "backgroundColor": "red" }}></td>
                            <td className="border-top" style={totalSecure ? { "backgroundColor": "green" } : { "backgroundColor": "red" }}></td>
                        </tr>;
                        return <React.Fragment>
                            {row}
                            {this.state.expandedObjectiveId !== objectiveId ? <span /> :
                                Array.from(objective.topicTags.keys()).map((topicId) => {
                                    let topic = this.state.topics.get(topicId);
                                    return <React.Fragment>
                                        <tr style={{ "backgroundColor": "lightGrey" }}>
                                            <td style={{ "backgroundColor": "white" }}></td>
                                            <td colSpan={5} style={{ "color": "white" }}>{topic.name}</td>
                                        </tr>
                                        {objective.topicTags.get(topicId).map((tagId) => {
                                            let tag = topic.tags.get(tagId);
                                            let analysis = topicsDisplayAnalysis.get(topicId) != null ? topicsDisplayAnalysis.get(topicId).get(tagId) : null;
                                            if (analysis == null) {
                                                analysis = { attempted: false, lastPassed: false, passed: false, secure: false };
                                            }
                                            return <tr>
                                                <td></td>
                                                <td>{tag}</td>
                                                <td style={analysis.attempted ? { "backgroundColor": "green", "padding": "2px" } : { "backgroundColor": "red" }}></td>
                                                <td style={analysis.passed ? { "backgroundColor": "green", "padding": "2px" } : { "backgroundColor": "red" }}></td>
                                                <td style={analysis.lastPassed ? { "backgroundColor": "green", "padding": "2px" } : { "backgroundColor": "red" }}></td>
                                                <td style={analysis.secure ? { "backgroundColor": "green", "padding": "2px" } : { "backgroundColor": "red" }}></td>
                                            </tr>;
                                        })}
                                    </React.Fragment>
                                })
                            }
                        </React.Fragment>
                    })}
                </React.Fragment>
            })}

        </Table>
    }
}

export default StudentObjectivesReportView;
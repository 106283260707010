import React, { Component } from 'react';
import firebase from './firebase';
import queryString from 'query-string';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, UncontrolledTooltip, InputGroup, InputGroupAddon, Card, CardBody
} from 'reactstrap';
import { Link } from "react-router-dom";
import TopicsSelector from "./ninja_components/topics_selector";
import { StoreLearningGame } from "./helpers/topic_services";
import { RouteComponentProps } from 'react-router';
import { User, USER_ROLE } from './data/user';
import { ShootGame, ShootRound, TIME_MULTIPLIER } from './data/learning_games';
import { v4 as uuidv4 } from 'uuid';
import { soundNames, subjectIds } from './Prettifier';

interface IState {
    worldId: string;
    worldName: string;
    subjectFilter: string;
    pathId: string;
    pathName: string;
    gameId: string;
    shoot: ShootGame;
    timeMultiplier: TIME_MULTIPLIER;
    currentShootTitle: string;
    currentShootInstructions: string;
    currentShootAnswerTemplate: string;
    currentShootSchoolId: string;
    currentShootSound: string;
    currentShootAnswersOrdered: boolean;
    currentSubjectId: string;
    currentTopics: string[],
    currentTopicsTags: Map<string, string[]>,
    currentShootRounds: ShootRound[],
    user: User;
}

interface MatchParams {
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class LearningShootView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            gameId: props.match.params.gameId,
            shoot: new ShootGame(),
            timeMultiplier: TIME_MULTIPLIER.moderate,
            currentShootTitle: '',
            currentShootInstructions: '',
            currentShootAnswerTemplate: '',
            currentShootSchoolId: '',
            currentShootSound: '',
            currentShootAnswersOrdered: false,
            currentSubjectId: '',
            currentTopics: [],
            currentTopicsTags: new Map<string, string[]>(),
            currentShootRounds: [],
            user: props.user,
        };
        this.dealWithChange = this.dealWithChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.addCorrectNumber = this.addCorrectNumber.bind(this);
        this.addIncorrectNumber = this.addIncorrectNumber.bind(this);
        this.addRound = this.addRound.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.handleRoundNumberChange = this.handleRoundNumberChange.bind(this);
        this.removeNumber = this.removeNumber.bind(this);
        this.removeRound = this.removeRound.bind(this);
        this.handleRoundTargetChange = this.handleRoundTargetChange.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.getGameQuerys = this.getGameQuerys.bind(this);
    }


    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.currentShootSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All minigames</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem> : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem> : null}
                        <BreadcrumbItem active>Number Shoot</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Number shoot</div>
                        <p className="cardSubTitle">Number shoot requires users to shoot approaching asteroids by tapping the correct numbers to form a sum.
                        If they use the incorrect numbers then the asteroid will fly past.</p>
                        {
                            (this.state.shoot != null) ?
                                <div>
                                    <div>
                                        <a href={`https://zapdemo.web.app/#/shoot?${this.getGameQuerys()}`} target="_blank"><Button type="button" className="adminPagesButton">Play now</Button></a>
                                    </div><br />
                                    <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                        <fieldset disabled={!editable}>
                                            <FormGroup>
                                                <Label for="currentShootTitle">Shoot title *</Label>
                                                <Input maxLength={30} type="text" required name="currentShootTitle" onChange={(e) => this.setState({
                                                    currentShootTitle: e.target.value
                                                })} value={this.state.currentShootTitle} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentSubjectId">Subject *</Label>
                                                <Input disabled={this.state.worldId != null || this.state.gameId !== "-1"} type="select" name="currentSubjectId"
                                                    onChange={(e) => this.setState({
                                                        currentSubjectId: e.target.value
                                                    })}
                                                    value={this.state.currentSubjectId}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                                        let subjectName = subjectIds.get(subjectId);
                                                        return <option value={subjectId}>{subjectName}</option>;
                                                    })}
                                                </Input>
                                            </FormGroup>
                                            {this.state.currentSubjectId === '' ? <div></div> :
                                                <TopicsSelector subject={this.state.currentSubjectId} countryCode={this.state.user.countryCode} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags} callback={this.handleTopicsChange} />
                                            }

                                            <label>
                                                Game speed <i className="fas fa-info-circle icons-info" id="help-learning-timemultiplier" />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learning-timemultiplier">
                                                    Control how fast or slow the game will play.
                                                    Overall paths can have speed controls and the game speed will work within the context of the path speed control.
                                                    For example, a game with a 'fast' speed on a path with a 'slow' speed will play at 'moderate' speed.
                                                    Use this to allow developing or advanced students to play at a speed that suits them.
                                        </UncontrolledTooltip>
                                            </label>
                                            <Input type="select" value={this.state.timeMultiplier} onChange={this.dealWithChange}>
                                                <option value="fastest">Fastest</option>
                                                <option value="fast">Fast</option>
                                                <option value="moderate">Moderate</option>
                                                <option value="slow">Slow</option>
                                                <option value="slowest">Slowest</option>
                                            </Input>
                                            <FormGroup>
                                                <h4>Shoot rounds <i className="fas fa-info-circle icons-info" id="help-learningshoot-rounds" /></h4>
                                                {this.state.currentShootRounds.map((answer, roundIndex) => {
                                                    let allowCorrectDelete = false;
                                                    if (this.state.currentShootRounds[roundIndex].correct.length > 1) {
                                                        allowCorrectDelete = true;
                                                    }
                                                    let allowIncorrectDelete = false;
                                                    if (this.state.currentShootRounds[roundIndex].incorrect.length > 1) {
                                                        allowIncorrectDelete = true;
                                                    }
                                                    return <div className="border rounded form-margin">
                                                        <div><b>Round {roundIndex + 1}</b> {roundIndex > 0 ? <Button type="button" color="link" onClick={() => this.removeRound(roundIndex)}><i
                                                            className="material-icons material-icons-xd">cancel</i></Button> : <span />}</div>
                                                        <div>Text on asteroid</div>
                                                        <Input required name={`currentShootRounds-${roundIndex}`}
                                                            onChange={(value) => this.handleRoundTargetChange(value, roundIndex)}
                                                            value={this.state.currentShootRounds[roundIndex].target} />
                                                        <div>Correct buttons</div>
                                                        {this.state.currentShootRounds[roundIndex].correct.map((answer, index) =>
                                                            <InputGroup>
                                                                <Input required name={`currentShootRounds-correct-${roundIndex}-${index}`}
                                                                    onChange={(value) => this.handleRoundNumberChange(value, roundIndex, index, true)}
                                                                    value={this.state.currentShootRounds[roundIndex].correct[index]} />
                                                                {allowCorrectDelete ? <InputGroupAddon addonType={"prepend"}>
                                                                    <Button type="button" outline onClick={() => this.removeNumber(roundIndex, index, true)}><i
                                                                        className="material-icons material-icons-xd">cancel</i></Button>
                                                                </InputGroupAddon> : null
                                                                }
                                                            </InputGroup>
                                                        )}
                                                        <Button className="altButton" onClick={() => this.addCorrectNumber(roundIndex)}>Additional correct number</Button>
                                                        <div>Incorrect buttons</div>
                                                        {this.state.currentShootRounds[roundIndex].incorrect.map((answer, index) =>
                                                            <InputGroup>
                                                                <Input required name={`currentShootRounds-incorrect-${roundIndex}-${index}`}
                                                                    onChange={(value) => this.handleRoundNumberChange(value, roundIndex, index, false)}
                                                                    value={this.state.currentShootRounds[roundIndex].incorrect[index]} />
                                                                {allowIncorrectDelete ? <InputGroupAddon addonType={"prepend"}>
                                                                    <Button type="button" outline onClick={() => this.removeNumber(roundIndex, index, false)}><i
                                                                        className="material-icons material-icons-xd">cancel</i></Button>
                                                                </InputGroupAddon> : null
                                                                }
                                                            </InputGroup>
                                                        )}
                                                        <Button className="altButton" onClick={() => this.addIncorrectNumber(roundIndex)}>Additional incorrect number</Button>
                                                    </div>
                                                })}
                                                <Button className="altButton" onClick={() => this.addRound()}>Additional round</Button>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningshoot-rounds">
                                                    Enter the numbers that will appear in each round. Correct numbers are the ones the player should select.
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentShootAnswerTemplate">Answer template <i className="fas fa-info-circle icons-info" id="help-learningshoot-answertemplate" /></Label>
                                                <Input type="text" name="currentShootAnswerTemplate" onChange={(e) => this.setState({
                                                    currentShootAnswerTemplate: e.target.value
                                                })} value={this.state.currentShootAnswerTemplate} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningshoot-answertemplate">
                                                    A number sentence or similar can appear at the top of the shoot game and be filled in as buttons are selected.<br />
                                            Use keywords ::selected1:: up to ::selected9:: for the squares selected by the user.<br />
                                            Use keyword ::target:: to show the target number<br />
                                            E.g. a standard template would be<br />
                                            ::selected1:: + ::selected2:: = ::target::
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" name="currentShootAnswersOrdered"
                                                        onChange={(e) => this.setState({
                                                            currentShootAnswersOrdered: e.target.checked
                                                        })} checked={this.state.currentShootAnswersOrdered} />
                                            Answers must follow ordering? <i className="fas fa-info-circle icons-info" id="help-learningshoot-answersordered" />
                                                </Label>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningshoot-answersordered">
                                                    Sometimes the answers must be ordered (e.g. for subtraction) and sometimes not (e.g. for addition)
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentShootInstructions">Instructions <i className="fas fa-info-circle icons-info" id="help-learningshoot-instructions" /></Label>
                                                <Input type="textarea" name="currentShootInstructions" onChange={(e) => this.setState({
                                                    currentShootInstructions: e.target.value
                                                })} value={this.state.currentShootInstructions} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningshoot-instructions">
                                                    The instructions shown to the player before they start number shoot. Advise on the types of sums to complete rather than the how to play the game.
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentShootSound">Audio instruction *</Label>
                                                <Input type="select" name="currentShootSound"
                                                    onChange={(e) => this.setState({
                                                        currentShootSound: e.target.value
                                                    })}
                                                    value={this.state.currentShootSound}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(soundNames.keys()).map((key) => {
                                                        let name = soundNames.get(key);
                                                        return (
                                                            <option value={key} key={key}>{name}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                            </FormGroup>

                                            {editable ? this.state.gameId === "-1" ?
                                                <Button disabled={this.state.currentSubjectId === ''} className="adminPagesButton">Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                            }
                                        </fieldset>
                                    </Form>
                                </div> : <div />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    getGameQuerys(): string {
        return queryString.stringify({
            instructions: this.state.currentShootInstructions,
            answerTemplate: this.state.currentShootAnswerTemplate,
            shootRounds: JSON.stringify(this.state.currentShootRounds),
            answersOrdered: this.state.currentShootAnswersOrdered,
            timeMultiplier: this.state.timeMultiplier,
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId
        });
    }

    dealWithChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    addCorrectNumber(roundIndex: number): void {
        this.state.currentShootRounds[roundIndex].correct.push("0");
        this.setState({
        });
    }

    addIncorrectNumber(roundIndex: number): void {
        this.state.currentShootRounds[roundIndex].incorrect.push("0");
        this.setState({
        });
    }

    handleRoundNumberChange(e: React.ChangeEvent<HTMLInputElement>, roundIndex: number, index: number, correct: boolean): void {
        let newNumber = e.target.value;
        if (correct) {
            this.state.currentShootRounds[roundIndex].correct[index] = newNumber;
        } else {
            this.state.currentShootRounds[roundIndex].incorrect[index] = newNumber;
        }
        this.setState({
        });
    }

    handleRoundTargetChange(e: React.ChangeEvent<HTMLInputElement>, roundIndex: number): void {
        let newNumber = e.target.value;
        this.state.currentShootRounds[roundIndex].target = newNumber;
        this.setState({});
    }

    addRound(): void {
        let initialRound = new ShootRound();
        initialRound.correct = ["0", "0"];
        initialRound.incorrect = ["0"];
        initialRound.target = "0";

        this.state.currentShootRounds.push(initialRound);
        this.setState({
        });
    }

    removeNumber(roundIndex: number, index: number, correct: boolean) {
        if (correct) {
            this.state.currentShootRounds[roundIndex].correct.splice(index, 1);
        } else {
            this.state.currentShootRounds[roundIndex].incorrect.splice(index, 1);
        }
        this.setState({
        });
    }

    removeRound(roundIndex: number): void {
        this.state.currentShootRounds.splice(roundIndex, 1);
        this.setState({});
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let shoot = this.state.shoot;
        shoot.title = this.state.currentShootTitle;
        shoot.instructions = this.state.currentShootInstructions;
        shoot.answerTemplate = this.state.currentShootAnswerTemplate;
        shoot.rounds = this.state.currentShootRounds;
        shoot.topics = this.state.currentTopics;
        shoot.topicTags = this.state.currentTopicsTags;
        shoot.sound = this.state.currentShootSound;
        shoot.answersOrdered = this.state.currentShootAnswersOrdered;
        shoot.type = 'SHOOT';
        shoot.timeMultiplier = this.state.timeMultiplier;
        shoot.subjectId = this.state.currentSubjectId;
        shoot.deleted = null;
        let newShoot = false;
        let gameId = this.state.gameId;
        if (this.state.gameId === "-1") {
            newShoot = true;
            gameId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                shoot.schoolId = this.state.user.schoolId;
            }
        }
        try {
            await StoreLearningGame(newShoot, shoot, gameId, this.state.pathId, this.state.currentShootSchoolId);

            this.props.history.replace(`/learningShoots/${gameId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
        this.setState({
            gameId: gameId
        });
    }

    async componentDidMount(): Promise<void> {
        try {
            let initialRound = new ShootRound();
            initialRound.correct = ["0", "0"];
            initialRound.incorrect = ["0"];
            initialRound.target = "0";
            if (this.state.gameId !== "-1") {
                let shootSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
                let shootData = shootSnapshot.data();
                let shoot = ShootGame.fromFirebase(shootData);
                this.setState({
                    shoot: shoot,
                    currentShootTitle: shoot.title,
                    currentShootInstructions: shoot.instructions,
                    currentShootAnswerTemplate: shoot.answerTemplate == null ? '' : shoot.answerTemplate,
                    currentShootSchoolId: shoot.schoolId == null ? null : shoot.schoolId,
                    currentShootRounds: shoot.rounds == null ? [initialRound] : shoot.rounds,
                    currentShootSound: shoot.sound == null ? '' : shoot.sound,
                    currentShootAnswersOrdered: shoot.answersOrdered == null ? false : shoot.answersOrdered,
                    currentSubjectId: shoot.subjectId,
                    currentTopics: shoot.topics == null ? [] : shoot.topics,
                    currentTopicsTags: shoot.topicTags == null ? new Map<string, string[]>() : shoot.topicTags,
                    timeMultiplier: shoot.timeMultiplier != null ? shoot.timeMultiplier : TIME_MULTIPLIER.moderate,
                });
            } else {
                let subjectId = '';
                if (this.state.worldId != null) {
                    const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                    let worldSnapshot = await worldRef.get();
                    let world = worldSnapshot.data();
                    subjectId = world.subject;
                } else {
                    // @ts-ignore
                    subjectId = this.state.subjectFilter;
                }
                this.setState({
                    shoot: new ShootGame(),
                    currentShootTitle: '',
                    currentShootInstructions: '',
                    currentShootAnswerTemplate: '',
                    currentShootSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                    currentShootSound: '',
                    currentShootAnswersOrdered: false,
                    currentShootRounds: [initialRound],
                    currentSubjectId: subjectId,
                    currentTopics: [],
                    timeMultiplier: TIME_MULTIPLIER.moderate,
                    currentTopicsTags: new Map<string, string[]>(),
                });
            }
        } catch (error) {
            console.log(error);
        }

    }
}

export default LearningShootView;
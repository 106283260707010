import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { RouteComponentProps } from 'react-router';
import { Button, Alert, Card, CardBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { User, USER_ROLE } from './data/user';

interface IState {
    user: User;
    videoId: string;
    tutorialId: string;
    tutorialName: string;
    documentId: string;
    tutorialDescription: string;
}

interface MatchParams {
    tutorialId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text: string) => void;
}

class TutorialVideoView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        let videoId: string;
        let documentId: string;
        let tutorialName: string;
        let tutorialDescription;
        if (props.match.params.tutorialId === "BookableEvent") {
            tutorialName = "Bookable Event";
            tutorialDescription = "If you would like to learn about how to create a Bookable Event, please watch our tutorial video and read our Bookable Event Guide";
            videoId = "546550925";
            documentId = "https://docs.google.com/document/d/1doyjniPwG_rd2gzGa2WaGjL6o4b2SFTcT64gYhkS0_Q/edit";
        }
        if (props.match.params.tutorialId === "NewsItem") {
            tutorialName = "News Item";
            tutorialDescription = "If you would like to learn about how to create a News Item, please watch our tutorial and read our News Item Guide";
            videoId = "529332839";
            documentId = "https://docs.google.com/document/d/1NoswtJ7k9MfQj8IdIYft21-s2z5NuwcmXS14CyEUOBQ/edit";
        }
        if (props.match.params.tutorialId === "QuizItem") {
            tutorialName = "Quiz Item";
            tutorialDescription = "If you would like to learn about how to create a Quiz, please watch our tutorial and read our Quiz Guide";
            videoId = "546550802";
            documentId = "https://docs.google.com/document/d/1o9JZ6ntEwFMvvE6BUewkKVdoMqMQaOF8vKLo11bYVuM/edit";
        }
        if (props.match.params.tutorialId === "Marketplace") {
            tutorialName = "Marketplace";
            tutorialDescription = "If you would like to learn about how use the Marketplace, please watch our tutorial and read our Marketplace Guide";
            documentId = "https://docs.google.com/document/d/1jJsClYOdbT31jcB-zAT_Ye0wWnkqTNhpy19V7oE98OE/edit"
        }
        if (props.match.params.tutorialId ===  "KeyDate") {
            tutorialName = "Key Date";
            tutorialDescription = "If you would like to learn about how to create a Key Date, please watch our tutorial and read our Key Date Guide";
            documentId = "https://docs.google.com/document/d/1Wg_NXQ6RGTdZVY_KCpuXbZHa7P-OGnyH4omJYsBMOZA/edit"
        }
        if (props.match.params.tutorialId === "Fundraiser") {
            tutorialName = "Fundraiser";
            tutorialDescription = "If you would like to learn about how to create a Fundraiser, please watch our tutorial and read our Fundraiser Guide";
            documentId = "https://docs.google.com/document/d/1eWhLUx0VDtu0LyGb06f0ALMAm8ZR5pP0yEkc6UxkrGQ/edit"
        }
        if (props.match.params.tutorialId === "CustomQuestion") {
            tutorialName = "Custom Question"
            tutorialDescription = "If you would like to learn about how to create a Custom Question, please watch our tutorial and read our Custom Question Guide";
            documentId = "https://docs.google.com/document/d/1r654ISuEywFzzj_AQarQ1FAIC_1fK6dOUQFRtnVP7nA/edit"
        }
        if (props.match.params.tutorialId === "EngagementAward") {
            tutorialName = "Engagement Award";
            tutorialDescription = "If you would like to learn about how to create an Engagement Award, please watch our tutorial and read our Engagement Award Guide";
            documentId = "https://docs.google.com/document/d/17fg0yIBpuLdYoWk0YJ4Y1xwDL7Rr4TdfDoPMdUHqFbk/edit"
        }
        this.state = {
            videoId: videoId,
            tutorialId: props.match.params.tutorialId,
            user: props.user,
            documentId: documentId,
            tutorialName: tutorialName,
            tutorialDescription: tutorialDescription,
        };

    }

    render(): JSX.Element {
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem>{this.state.tutorialName} Tutorial</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card style={{width:'50%', marginLeft:"15px"}}>
                    <CardBody>
                        <div>{this.state.tutorialDescription}</div><br />
                        {this.state.documentId != null ?  <Button className="altButton"><a target="_blank" href={this.state.documentId} className="altButton" >Read Tutorial</a></Button> : <span />}<br /><br />
                        {this.state.videoId != null ? <ReactPlayer url={`https://vimeo.com/${this.state.videoId}`} style={{textAlign:'center'}}></ReactPlayer> : <span />}
                    </CardBody>
                </Card>
            </div>
        )
    }
}
export default TutorialVideoView;   
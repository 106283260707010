//@ts-check

import React, { Component } from 'react';
import firebase from './firebase';
import queryString from 'query-string';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Card, CardBody
} from 'reactstrap';
import { Link } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import TopicsSelector from "./ninja_components/topics_selector";
import { StoreQuestion } from "./helpers/topic_services";
import { User, USER_ROLE } from './data/user';
import { LearningImage, Question, QUESTION_TYPE } from './data/learning_games';
import { v4 as uuidv4 } from 'uuid';
import { questionType, subjectIds } from './Prettifier';

interface IState {
    worldId: string;
    worldName: string;
    subjectFilter: string;
    pathId: string;
    pathName: string;
    gameId: string;
    gameName: string;
    gameType: string;
    questionId: string;
    question: Question,
    currentQuestionText: string;
    currentQuestionImageUrl: string;
    currentQuestionExplainer: string;
    currentQuestionType: QUESTION_TYPE;
    currentQuestionSchoolId: string;
    currentQuestionAnswers: string[];
    currentQuestionAnswerImages: string[];
    currentQuestionChoices: string[];
    currentQuestionChoiceImages: string[];
    currentQuestionMinChoices: number;
    currentQuestionMaxChoices: number;
    currentQuestionMinAnswers: number;
    currentQuestionMaxAnswers: number;
    currentSubjectId: string;
    currentTopics: string[],
    currentTopicsTags: Map<string, string[]>;
    user: User;
}

interface MatchParams {
    questionId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class LearningQuestionView extends Component<IProps, IState> {
    images: Map<string, LearningImage>;

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            gameId: values.gameId == null ? null : values.gameId.toString(),
            gameName: values.gameName == null ? null : values.gameName.toString(),
            gameType: values.gameType == null ? null : values.gameType.toString(),
            questionId: props.match.params.questionId,
            question: new Question(),
            currentQuestionText: '',
            currentQuestionImageUrl: '',
            currentQuestionExplainer: '',
            currentQuestionType: QUESTION_TYPE.CHOICE,
            currentQuestionSchoolId: '',
            currentQuestionAnswers: [""],
            currentQuestionAnswerImages: [],
            currentQuestionChoices: [],
            currentQuestionChoiceImages: [],
            currentQuestionMinChoices: 0,
            currentQuestionMaxChoices: 0,
            currentQuestionMinAnswers: 1,
            currentQuestionMaxAnswers: 1,
            currentSubjectId: '',
            currentTopics: [],
            currentTopicsTags: new Map<string, string[]>(),
            user: props.user
        };
        this.images = new Map();
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleAnswerEdit = this.handleAnswerEdit.bind(this);
        this.handleRemoveAnswer = this.handleRemoveAnswer.bind(this);
        this.handleAddAnswer = this.handleAddAnswer.bind(this);
        this.handleChoiceEdit = this.handleChoiceEdit.bind(this);
        this.handleRemoveChoice = this.handleRemoveChoice.bind(this);
        this.handleAddChoice = this.handleAddChoice.bind(this);
        this.handleQuestionTypeEdit = this.handleQuestionTypeEdit.bind(this);
        this.handleAnswerImageEdit = this.handleAnswerImageEdit.bind(this);
        this.handleChoiceImageEdit = this.handleChoiceImageEdit.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
    }

    render(): JSX.Element {

        let url = "learningQuizlets";
        if (this.state.gameType != null) {
            if (this.state.gameType === "QUIZLET") {
                url = "learningQuizlets";
            } else if (this.state.gameType === "GRID") {
                url = "learningGrids";
            } else if (this.state.gameType === "CIRCLE") {
                url = "learningCircles";
            } else if (this.state.gameType === "SLICE") {
                url = "learningSlices";
            } else if (this.state.gameType === "SHOOT") {
                url = "learningShoots";
            } else if (this.state.gameType === "MULSPEEDTEST") {
                url = "learningMultiplicationSpeedTest";
            } else if (this.state.gameType === "BOSSLEVEL") {
                url = "learningBossLevel";
            }
        }

        let queryValues = this.getQuerys();

        let editable = this.state.currentQuestionSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                            : this.state.gameId != null ? <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All minigames</Link></BreadcrumbItem>
                                : <BreadcrumbItem><Link to={`/questions?${queryValues}`}>All questions</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem> : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem> : null}
                        {this.state.gameId != null ?
                            <BreadcrumbItem><Link to={`/${url}/${this.state.gameId}?${queryValues}`}>{this.state.gameName}</Link></BreadcrumbItem> : null}
                        <BreadcrumbItem active>Question</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Question</div>
                        <p className="cardSubTitle">Can have several different types but ultimately a student either answers correctly or not. The explainer is available after answering to reinforce knowledge</p>
                        {
                            (this.state.question != null) ?
                                <div>
                                    <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                        <fieldset disabled={!editable}>
                                            <FormGroup>
                                                <Label for="currentQuestionText">Question text *</Label>
                                                <Input type="text" required name="currentQuestionText" onChange={(e) => this.setState({
                                                    currentQuestionText: e.target.value
                                                })} value={this.state.currentQuestionText} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentQuestionImageUrl">Question image</Label>
                                                <Input type="select" name="currentQuestionImageUrl"
                                                    onChange={(e) => this.setState({
                                                        currentQuestionImageUrl: e.target.value
                                                    })} value={this.state.currentQuestionImageUrl}>
                                                    <option>Select image</option>
                                                    {Array.from(this.images.keys()).map((key) => {
                                                        return (
                                                            <option value={key} key={key}>{this.images.get(key).name}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                                <p>Images can be uploaded via the <Link to={"/imageLibrary"}>image library</Link></p>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentSubjectId">Subject *</Label>
                                                <Input disabled={this.state.worldId != null || this.state.questionId !== "-1"} type="select" name="currentSubjectId"
                                                    onChange={(e) => this.setState({
                                                        currentSubjectId: e.target.value
                                                    })}
                                                    value={this.state.currentSubjectId}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                                        let subjectName = subjectIds.get(subjectId);
                                                        return <option value={subjectId}>{subjectName}</option>;
                                                    })}
                                                </Input>
                                            </FormGroup>
                                            {this.state.currentSubjectId === '' ? <div></div> :
                                                <TopicsSelector key={this.state.questionId} countryCode={this.state.user.countryCode} subject={this.state.currentSubjectId} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags}
                                                    callback={this.handleTopicsChange} />
                                            }
                                            <FormGroup>
                                                <Label for="currentQuestionType">Question type* </Label>
                                                <Input type="select" name="currentQuestionType" onChange={this.handleQuestionTypeEdit} value={this.state.currentQuestionType}>
                                                    <option value="CHOICE">{questionType.get("CHOICE")}</option>
                                                    <option value="SPELLING">{questionType.get("SPELLING")}</option>
                                                    <option value="SPELLING_HIDDEN">{questionType.get("SPELLING_HIDDEN")}</option>
                                                    <option value="TEXT">{questionType.get("TEXT")}</option>
                                                    <option value="REORDER">{questionType.get("REORDER")}</option>
                                                </Input>
                                            </FormGroup>
                                            {(this.state.currentQuestionMaxAnswers === -1 || this.state.currentQuestionMaxAnswers > 0) ?
                                                <FormGroup className="border rounded form-margin">
                                                    <legend>Correct answers</legend>
                                                    {this.state.currentQuestionAnswers.map((answer, pos) => {
                                                        return <div>
                                                            <Input type="text" required name={'answer-' + pos}
                                                                placeholder="Text *"
                                                                onChange={this.handleAnswerEdit}
                                                                value={answer} />
                                                            <Input type="select" name={'answerImage-' + pos}
                                                                onChange={this.handleAnswerImageEdit} value={this.state.currentQuestionAnswerImages[pos]}>
                                                                <option>Select image</option>
                                                                {Array.from(this.images.keys()).map((key) => {
                                                                    return (
                                                                        <option value={key} key={key}>{this.images.get(key).name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </Input>
                                                            {editable && (pos >= this.state.currentQuestionMinAnswers) ?
                                                                <div>
                                                                    <Button color="danger" type="button" outline onClick={() => {
                                                                        this.handleRemoveAnswer(pos)
                                                                    }}>Remove correct answer</Button><br /><br />
                                                                </div> : <br />
                                                            }
                                                        </div>
                                                    })}
                                                    {editable && (this.state.currentQuestionMaxAnswers === -1 || this.state.currentQuestionAnswers.length < this.state.currentQuestionMaxAnswers) ?
                                                        <div>
                                                            <Button type="button" className="altButton" onClick={() => {
                                                                this.handleAddAnswer()
                                                            }}>Add new correct answer</Button>
                                                        </div> : <br />
                                                    }
                                                </FormGroup> : <br />
                                            }
                                            {(this.state.currentQuestionMaxChoices === -1 || this.state.currentQuestionMaxChoices > 0) ?
                                                <FormGroup className="border rounded form-margin">
                                                    <legend>Incorrect answers</legend>
                                                    {this.state.currentQuestionChoices.map((answer, pos) => {
                                                        return <div>
                                                            <Input type="text" required name={'choice-' + pos}
                                                                placeholder="Text *"
                                                                onChange={this.handleChoiceEdit}
                                                                value={answer} />
                                                            <Input type="select" name={'choiceImage-' + pos}
                                                                onChange={this.handleChoiceImageEdit} value={this.state.currentQuestionChoiceImages[pos]}>
                                                                <option>Select image</option>
                                                                {Array.from(this.images.keys()).map((key) => {
                                                                    return (
                                                                        <option value={key} key={key}>{this.images.get(key).name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </Input>
                                                            {editable && (pos >= this.state.currentQuestionMinChoices) ?
                                                                <div>
                                                                    <Button type="button" outline color="danger" onClick={() => {
                                                                        this.handleRemoveChoice(pos)
                                                                    }}>Remove incorrect answer</Button><br /><br />
                                                                </div> : <br />
                                                            }
                                                        </div>
                                                    })}
                                                    {editable && (this.state.currentQuestionMaxChoices === -1 || this.state.currentQuestionChoices.length < this.state.currentQuestionMaxChoices) ?
                                                        <div>
                                                            <Button type="button" className="altButton" onClick={() => {
                                                                this.handleAddChoice()
                                                            }}>Add new incorrect answer</Button>
                                                        </div> : <br />
                                                    }
                                                </FormGroup> : <br />
                                            }

                                            <FormGroup>
                                                <Label for="currentQuestionType">Answer explanation</Label>
                                                <Input type="textarea" name="currentQuestionExplainer" onChange={(e) => this.setState({
                                                    currentQuestionExplainer: e.target.value
                                                })} value={this.state.currentQuestionExplainer} />
                                            </FormGroup>
                                            {editable ? this.state.questionId === "-1" ?
                                                <Button disabled={this.state.currentSubjectId === ''} className="adminPagesButton">Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                            }
                                        </fieldset>
                                    </Form>
                                </div> : <div />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>) {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId,
            gameName: this.state.gameName,
            gameType: this.state.gameType,
            questionId: this.state.questionId
        });
    }

    handleRemoveChoice(pos: number): void {
        let newChoices = this.state.currentQuestionChoices.slice();
        newChoices.splice(pos, 1);
        let newChoiceImages = this.state.currentQuestionChoiceImages.slice();
        newChoiceImages.splice(pos, 1);
        this.setState({
            currentQuestionChoices: newChoices,
            currentQuestionChoiceImages: newChoiceImages,
        });
    }

    handleChoiceEdit(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = parseInt(e.target.name.split('-')[1]);
        let newChoices = this.state.currentQuestionChoices.slice();
        newChoices[index] = e.target.value;
        this.setState({
            currentQuestionChoices: newChoices
        });
    }

    handleChoiceImageEdit(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = parseInt(e.target.name.split('-')[1]);
        let newChoiceImages = this.state.currentQuestionChoiceImages.slice();
        newChoiceImages[index] = e.target.value;
        this.setState({
            currentQuestionChoiceImages: newChoiceImages
        });
    }

    handleAddChoice(): void {
        let newChoices = this.state.currentQuestionChoices.slice();
        newChoices.push("");
        let newChoiceImages = this.state.currentQuestionChoiceImages.slice();
        newChoiceImages.push("");
        this.setState({
            currentQuestionChoices: newChoices,
            currentQuestionChoiceImages: newChoiceImages,
        });
    }

    handleQuestionTypeEdit(e: React.ChangeEvent<HTMLInputElement>): void {
        let questionType = QUESTION_TYPE[e.target.value as keyof typeof QUESTION_TYPE];
        this.refineQuestionTypeDetails(questionType);
    }

    refineQuestionTypeDetails(questionType: QUESTION_TYPE): void {
        let newChoices = this.state.currentQuestionChoices.slice();
        let newChoiceImages = this.state.currentQuestionChoiceImages.slice();
        let minChoices = 0;
        let maxChoices = -1;
        let newAnswers = this.state.currentQuestionAnswers.slice();
        let newAnswerImages = this.state.currentQuestionAnswerImages.slice();
        let minAnswers = 1;
        let maxAnswers = -1;
        if (questionType === QUESTION_TYPE.CHOICE) {
            while (newChoices.length < 1) {
                newChoices.push("");
                newChoiceImages.push("");
            }
            minChoices = 1;
        } else if (questionType === QUESTION_TYPE.REORDER) {
            while (newAnswers.length < 2) {
                newAnswers.push("");
                newAnswerImages.push("");
            }
            minAnswers = 2;
            if (newChoices.length > 0) {
                newChoices = [];
                newChoiceImages = [];
            }
            maxChoices = 0;
        } else if (questionType === QUESTION_TYPE.SPELLING || questionType === QUESTION_TYPE.SPELLING_HIDDEN || questionType === QUESTION_TYPE.TEXT) {
            if (newChoices.length > 0) {
                newChoices = [];
                newChoiceImages = [];
            }
            maxChoices = 0;
            if (newAnswers.length > 1) {
                newAnswers = [newAnswers[0]];
                newAnswerImages = [newAnswerImages[0]];
            }
            maxAnswers = 1;
        }
        this.setState({
            currentQuestionType: questionType,
            currentQuestionChoices: newChoices,
            currentQuestionChoiceImages: newChoiceImages,
            currentQuestionAnswers: newAnswers,
            currentQuestionAnswerImages: newAnswerImages,
            currentQuestionMinChoices: minChoices,
            currentQuestionMaxChoices: maxChoices,
            currentQuestionMinAnswers: minAnswers,
            currentQuestionMaxAnswers: maxAnswers
        });
    }

    handleRemoveAnswer(pos: number): void {
        let newAnswers = this.state.currentQuestionAnswers.slice();
        newAnswers.splice(pos, 1);
        let newAnswerImages = this.state.currentQuestionAnswerImages.slice();
        newAnswerImages.splice(pos, 1);
        this.setState({
            currentQuestionAnswers: newAnswers,
            currentQuestionAnswerImages: newAnswerImages,
        });
    }

    handleAnswerEdit(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = parseInt(e.target.name.split('-')[1]);
        let newAnswers = this.state.currentQuestionAnswers.slice();
        newAnswers[index] = e.target.value;
        this.setState({
            currentQuestionAnswers: newAnswers
        });
    }

    handleAnswerImageEdit(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = parseInt(e.target.name.split('-')[1]);
        let newAnswerImages = this.state.currentQuestionAnswerImages.slice();
        newAnswerImages[index] = e.target.value;
        this.setState({
            currentQuestionAnswerImages: newAnswerImages
        });
    }

    handleAddAnswer(): void {
        let newAnswers = this.state.currentQuestionAnswers.slice();
        newAnswers.push("");
        let newAnswerImages = this.state.currentQuestionAnswerImages.slice();
        newAnswerImages.push("");
        this.setState({
            currentQuestionAnswers: newAnswers,
            currentQuestionAnswerImages: newAnswerImages,
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let question = this.state.question;
        question.text = this.state.currentQuestionText;
        question.imageUrl = this.state.currentQuestionImageUrl;
        question.explainer = this.state.currentQuestionExplainer;
        question.questionType = this.state.currentQuestionType;
        question.answers = this.state.currentQuestionAnswers;
        question.answerImages = this.state.currentQuestionAnswerImages;
        question.choices = this.state.currentQuestionChoices;
        question.choiceImages = this.state.currentQuestionChoiceImages;
        question.topics = this.state.currentTopics;
        question.topicTags = this.state.currentTopicsTags;
        question.subjectId = this.state.currentSubjectId;

        question.deleted = null;
        let newQuestion = false;
        let questionId = this.state.questionId;
        if (this.state.questionId === "-1") {
            newQuestion = true;
            questionId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                question.schoolId = this.state.user.schoolId;
            }
        }
        try {
            await StoreQuestion(newQuestion, question, questionId, this.state.gameId, this.state.currentQuestionSchoolId);

            this.props.history.replace(`/learningQuestions/${questionId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
        this.setState({
            questionId: questionId
        })
    }

    async componentDidMount(): Promise<void> {
        try {
            let imagesSnapshot = await firebase.database().ref(`schoolManagement/${this.state.user.schoolId}/learningImages`).orderByChild('deleted').equalTo(null).once('value');
            this.images = new Map();
            if (imagesSnapshot.val() != null) {
                let imagesData = imagesSnapshot.val();
                for (let nextImageId in imagesData) {
                    this.images.set(nextImageId, LearningImage.fromFirebase(imagesData[nextImageId]));
                }
            }
            if (this.state.questionId !== "-1") {
                let questionSnapshot = await firebase.firestore().doc(`learningQuestions/${this.state.questionId}`).get();
                let questionData = questionSnapshot.data();
                let question = Question.fromFirebase(questionData);
                this.setState({
                    question: question,
                    currentQuestionSchoolId: question.schoolId,
                    currentQuestionText: question.text,
                    currentQuestionImageUrl: question.imageUrl == null ? "" : question.imageUrl,
                    currentQuestionExplainer: question.explainer == null ? "" : question.explainer,
                    currentQuestionType: question.questionType,
                    currentQuestionAnswers: question.answers == null ? [] : question.answers.slice(),
                    currentQuestionAnswerImages: question.answerImages == null ? [] : question.answerImages.slice(),
                    currentQuestionChoices: question.choices == null ? [] : question.choices.slice(),
                    currentQuestionChoiceImages: question.choiceImages == null ? [] : question.choiceImages.slice(),
                    currentSubjectId: question.subjectId,
                    currentTopics: question.topics == null ? [] : question.topics,
                    currentTopicsTags: question.topicTags == null ? new Map<string, string[]>() : question.topicTags,
                }, () => {
                    this.refineQuestionTypeDetails(question.questionType);
                });
            } else {
                let question = {
                    text: "",
                    questionType: QUESTION_TYPE.CHOICE
                };
                let subjectId = '';
                if (this.state.worldId != null) {
                    const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                    let worldSnapshot = await worldRef.get();
                    let world = worldSnapshot.data();
                    subjectId = world.subject;
                } else {
                    // @ts-ignore
                    subjectId = this.state.subjectFilter;
                }
                this.setState({
                    question: new Question(),
                    currentQuestionSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                    currentQuestionText: "",
                    currentQuestionImageUrl: "",
                    currentQuestionExplainer: "",
                    currentQuestionType: QUESTION_TYPE.CHOICE,
                    currentSubjectId: subjectId,
                    currentTopics: [],
                    currentTopicsTags: new Map<string, string[]>(),
                }, () => {
                    this.refineQuestionTypeDetails(question.questionType);
                });
            }
        } catch (error) {
            console.log(error);
        }

    }
}

export default LearningQuestionView;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, BreadcrumbItem, Breadcrumb, Alert, Card, CardBody } from 'reactstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from "moment";
import { EventMinimised, SchoolKeyDates } from './data/events';
import { SlotMinimised } from './data/bookings';
import { User } from './data/user';
import { AccountMinimised } from './data/accounts';

const localiser = momentLocalizer(moment);

interface IState {
    events: Map<string, EventMinimised>;
    slots: Map<string, SlotMinimised>;
    keyDates: SchoolKeyDates;
    monthsRequested: number[];
}

interface IProps extends RouteComponentProps {
    tutorialCallback: (pageName: string) => void;
    user: User;
    accountsMinimised: Map<string, AccountMinimised>;
}

interface CalendarEvent {
    start: Date;
    end: Date;
    title: string;
    allDay: boolean;
    id: string;
    type: string;
    accountId: string;
}

class EventsCalendarView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            events: new Map(),
            slots: new Map(),
            keyDates: new SchoolKeyDates(),
            monthsRequested: [],
        };
        this.getNextEventsBatch = this.getNextEventsBatch.bind(this);
        this.eventStyleGetter = this.eventStyleGetter.bind(this);
    }

    render(): JSX.Element {
        let calendarEvents: CalendarEvent[] = [];
        Array.from(this.state.events.keys()).forEach((eventId) => {
            let event = this.state.events.get(eventId);
            calendarEvents.push({
                start: new Date(event.startDateTime),
                end: new Date(event.endDateTime),
                title: event.title,
                allDay: false,
                id: eventId,
                type: 'EVENT',
                accountId: null,
            });
        });
        Array.from(this.state.slots.keys()).forEach((slotId) => {
            let slot = this.state.slots.get(slotId);
            let title = slot.name;
            let eventAccountId;
            if (slot.owners != null) {
                let first = true;
                Array.from(slot.owners.keys()).forEach((accountId) => {
                    let accountActive = slot.owners.get(accountId);
                    if (!accountActive) {
                        return;
                    }
                    let account = this.props.accountsMinimised.get(accountId);
                    if (account != null) {
                        if (first) {
                            eventAccountId = accountId;
                            first = false;
                            title += " - ";
                        } else {
                            title += ", ";
                        }
                        title += account.name;
                    }
                });
            }
            calendarEvents.push({
                start: new Date(slot.startDateTime),
                end: new Date(slot.endDateTime),
                title: title,
                allDay: false,
                id: slot.slotGroupId,
                type: 'SLOT',
                accountId: eventAccountId,
            });
        });
        Array.from(this.state.keyDates.keyDates.keys()).forEach((keyDateId) => {
            let keyDate = this.state.keyDates.keyDates.get(keyDateId);
            let startDate = new Date(new Date(new Date(keyDate.date).toDateString()));
            let endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + 1);
            calendarEvents.push({
                start: startDate,
                end: endDate,
                title: keyDate.title,
                allDay: true,
                id: keyDateId,
                type: 'KEY_DATE',
                accountId: null,
            });
        });
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>Calendar</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody>
                        <div className="cardTitle">Events, bookable events, and key dates</div>
                        <p className="cardSubTitle">All the events, bookable events and key dates in calendar format</p>
                        <div>
                            <Link to={'/events/-1?calendar=true'}>
                                <Button className="adminPagesButton" type="button" onClick={() => this.props.tutorialCallback('events')}>
                                    Create event
                        </Button>
                            </Link>&nbsp;
                    <Link to={'/slotGroups/-1?calendar=true'}>
                                <Button className="adminPagesButton" type="button">
                                    Create bookable event
                        </Button>
                            </Link>&nbsp;
                    <Link to={'/keyDates'}>
                                <Button className="adminPagesButton" type="button">
                                    Manage key dates
                        </Button>
                            </Link>
                        </div>
                        <br />
                        <div>
                            <Calendar
                                localizer={localiser}
                                events={calendarEvents}
                                defaultDate={new Date()}
                                defaultView="month"
                                style={{ height: 600 }}
                                onSelectEvent={(event) => {
                                    if (event.type === 'EVENT') {
                                        this.props.history.push(`/events/${event.id}?calendar=true`);
                                    } else if (event.type === 'SLOT') {
                                        this.props.history.push(`/slotGroups/${event.id}?calendar=true`);
                                    } else if (event.type === 'KEY_DATE') {
                                        this.props.history.push(`/keyDates`);
                                    }
                                }}
                                eventPropGetter={(this.eventStyleGetter)}
                                onRangeChange={(dates) => {
                                    console.log("Dates", dates);
                                    let firstDate;
                                    let lastDate;
                                    if (Array.isArray(dates)) {
                                        firstDate = dates[0];
                                        lastDate = dates[dates.length - 1];
                                    } else {
                                        firstDate = new Date(dates.start);
                                        lastDate = new Date(dates.end);
                                    }
                                    this.getNextEventsBatch(firstDate);
                                    this.getNextEventsBatch(lastDate);
                                }
                                }
                            />
                        </div>
                        <br />
                        <br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    eventStyleGetter(event: CalendarEvent): React.HTMLAttributes<HTMLDivElement> {
        console.log(event);
        let backgroundColor;
        if (event.accountId == null) {
            backgroundColor = "#0000ff";
        } else {
            let hashcode = this.hashCode(event.accountId);
            backgroundColor = '#' + this.intToHex(hashcode);
        }
        let style = {
            backgroundColor: backgroundColor,
            color: this.getContrastYIQ(backgroundColor),
        };
        return {
            style: style
        };
    };

    getContrastYIQ(hexcolor: string): string {
        hexcolor = hexcolor.replace("#", "");
        let r = parseInt(hexcolor.substr(0, 2), 16);
        let g = parseInt(hexcolor.substr(2, 2), 16);
        let b = parseInt(hexcolor.substr(4, 2), 16);
        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    hashCode(str: string): number {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    intToHex(i: number): string {
        let c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();

        return "00000".substring(0, 6 - c.length) + c;
    }

    makeShortString(description: string): string {
        if (description == null) {
            return "";
        }
        if (description.length < 30) {
            return description;
        }
        let descriptionResult = description.substr(0, 30);
        let spacePos = descriptionResult.lastIndexOf(" ");
        if (spacePos !== -1) {
            descriptionResult = descriptionResult.substr(0, spacePos);
        }
        descriptionResult += "...";

        return descriptionResult;
    }

    componentDidMount(): void {
        this.getNextEventsBatch(new Date());
    }


    async getNextEventsBatch(newMonth: Date) {
        let newMonthTruncated = new Date(newMonth);
        newMonthTruncated.setHours(0, 0, 0, 0);
        newMonthTruncated.setDate(1);
        if (this.state.monthsRequested.includes(newMonthTruncated.getTime())) {
            return;
        }
        this.state.monthsRequested.push(newMonthTruncated.getTime());
        let endMonthTruncatedInter = new Date();
        let endMonthTruncated = new Date(endMonthTruncatedInter.setMonth(endMonthTruncatedInter.getMonth() + 8));
        try {
            let eventsStartRef = firebase.database().ref(`schoolEvents/eventsMinimised/${this.props.user.schoolId}`).orderByChild("startDateTime").startAt(newMonthTruncated.getTime()).endAt(endMonthTruncated.getTime());
            let startSnapshot = await eventsStartRef.once('value');
            let eventsStart = startSnapshot.val();
            if (eventsStart != null) {
                console.log("events start ", eventsStart);

                for (let nextEventId in eventsStart) {
                    let nextEvent = EventMinimised.fromFirebase(eventsStart[nextEventId]);
                    this.state.events.set(nextEventId, nextEvent);
                }
            }

            let eventsEndRef = firebase.database().ref(`schoolEvents/eventsMinimised/${this.props.user.schoolId}`).orderByChild("endDateTime").startAt(newMonthTruncated.getTime()).endAt(endMonthTruncated.getTime());
            let endSnapshot = await eventsEndRef.once('value');
            let eventsEnd = endSnapshot.val();
            if (eventsEnd != null) {
                console.log("events end ", eventsEnd);

                for (let nextEventId in eventsEnd) {
                    let nextEvent = EventMinimised.fromFirebase(eventsEnd[nextEventId]);
                    this.state.events.set(nextEventId, nextEvent);
                }
            }
            let slotsStartRef = firebase.database().ref(`bookingSlots/slotsMinimised/${this.props.user.schoolId}`).orderByChild("startDateTime").startAt(newMonthTruncated.getTime()).endAt(endMonthTruncated.getTime());
            let startSlotsSnapshot = await slotsStartRef.once('value');
            let slotsStart = startSlotsSnapshot.val();
            if (slotsStart != null) {
                console.log("slots start ", slotsStart);

                for (let nextSlotId in slotsStart) {
                    let nextEvent = SlotMinimised.fromFirebase(slotsStart[nextSlotId]);
                    this.state.slots.set(nextSlotId, nextEvent);
                }
            }

            let slotsEndRef = firebase.database().ref(`bookingSlots/slotsMinimised/${this.props.user.schoolId}`).orderByChild("endDateTime").startAt(newMonthTruncated.getTime()).endAt(endMonthTruncated.getTime());
            let endSlotsSnapshot = await slotsEndRef.once('value');
            let slotsEnd = endSlotsSnapshot.val();
            if (slotsEnd != null) {
                console.log("slots end ", slotsEnd);

                for (let nextSlotId in slotsEnd) {
                    let nextEvent = SlotMinimised.fromFirebase(slotsEnd[nextSlotId]);
                    this.state.slots.set(nextSlotId, nextEvent);
                }
            }

            const schoolKeyDatesSnapshot = await firebase.firestore().doc(`schoolKeyDates/${this.props.user.schoolId}`).get();

            let newKeyDates: SchoolKeyDates;
            if (schoolKeyDatesSnapshot.data() != null) {
                newKeyDates = SchoolKeyDates.fromFirebase(schoolKeyDatesSnapshot.data());
            } else {
                newKeyDates = new SchoolKeyDates();
            }

            this.setState({
                keyDates: newKeyDates,
            });
        } catch (error) {
            console.log(error)
        }
    }
}

export default EventsCalendarView;
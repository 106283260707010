import React, { Component } from "react";
import firebase from './firebase';
import queryString from 'query-string';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    UncontrolledTooltip,
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import TopicsSelector from "./ninja_components/topics_selector";
import { User, USER_ROLE } from "./data/user";
import { soundNames, subjectIds } from "./Prettifier";
import { SplitGame, TIME_MULTIPLIER, SplitRound } from './data/learning_games';
import { StoreLearningGame } from "./helpers/topic_services";

interface IState {
    worldId: string;
    worldName: string;
    gameId: string;
    pathId: string;
    pathName: string;
    subjectFilter: string;
    splitGame: SplitGame;
    currentSplitGameTitle: string;
    currentSplitGameSubjectId: string;
    currentSplitGameSchoolId: string;
    currentSplitGameTopics: string[];
    currentSplitGameTags: Map<string, string[]>;
    currentSplitGameInstructions: string;
    currentSplitGameAudioInstructions: string;
    currentSplitGameSplitRounds: SplitRound[];
    timeMultiplier: TIME_MULTIPLIER;
    user: User;
}

interface MatchParams {
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class LearningSplitGame extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            gameId: props.match.params.gameId,
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            splitGame: null,
            currentSplitGameTitle: '',
            currentSplitGameSubjectId: '',
            currentSplitGameSchoolId: '',
            currentSplitGameInstructions: '',
            currentSplitGameSplitRounds: [],
            currentSplitGameTags: new Map(),
            currentSplitGameTopics: [],
            currentSplitGameAudioInstructions: '',
            timeMultiplier: TIME_MULTIPLIER.moderate,
            user: props.user,
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.timeMultiplierChange = this.timeMultiplierChange.bind(this);
        this.handleAddSplit = this.handleAddSplit.bind(this);
        this.removeSplitWord = this.removeSplitWord.bind(this);
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.currentSplitGameSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All Worlds</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All Minigames</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem>
                            : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem>
                            : null}
                        <BreadcrumbItem active>Split Game</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Split Game</div>
                        <p>The Split Game requires the user to Split a word into two parts.</p>
                        <div>
                            <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                <fieldset disabled={!editable}>
                                    <FormGroup>
                                        <Label for="currentSplitGameTitle">Title *</Label>
                                        <Input maxLength={30} type="text" required name="currentSplitGameTitle" value={this.state.currentSplitGameTitle} onChange={(e) => this.setState({
                                            currentSplitGameTitle: e.target.value
                                        })}></Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentSplitGameSubjectId">Subject *</Label>
                                        <Input disabled={this.state.worldId != null || this.state.gameId !== "-1"} type="select" name="currentSplitGameSubjectId"
                                            onChange={(e) => this.setState({
                                                currentSplitGameSubjectId: e.target.value
                                            })}
                                            value={this.state.currentSplitGameSubjectId}>
                                            <option value={''}>&nbsp;</option>
                                            {Array.from(subjectIds.keys()).map(subjectId => {
                                                let subjectName = subjectIds.get(subjectId);
                                                return <option value={subjectId}>{subjectName}</option>
                                            })}
                                        </Input>
                                    </FormGroup>
                                    {this.state.currentSplitGameSubjectId === '' ? <div></div> :
                                        <TopicsSelector subject={this.state.currentSplitGameSubjectId} countryCode={this.state.user.countryCode} currentTopics={this.state.currentSplitGameTopics} currentTopicsTags={this.state.currentSplitGameTags} callback={this.handleTopicsChange} />
                                    }
                                    <FormGroup>
                                        <h4>Split <i className="fas fa-info-circle icons-info" id="help-learningsplitgame" /></h4>
                                        {this.state.currentSplitGameSplitRounds.map((nextSplit, splitIndex) => {
                                            return <div className="border rounded form-margin">
                                                <div><b>Split {splitIndex + 1}</b> {splitIndex > 0 ? <Button type="button" color="link" onClick={() => this.removeSplitWord(splitIndex)}><i
                                                    className="material-icons material-icons-xd">cancel</i></Button> : <span />}</div>
                                                <div className="row">
                                                    <Input type="text" required name="currentSplitGameSplitOne" value={nextSplit.splitOne} placeholder="eg: thought" style={{ width: "25%", marginRight: "20px", marginLeft: "1.4%" }} onChange={(e) => {
                                                        nextSplit.splitOne = e.target.value;
                                                        this.setState({
                                                        });
                                                    }
                                                    }></Input>
                                                    <Input type="text" required name="currentSplitGameSplitTwo" value={nextSplit.splitTwo} placeholder="eg: ful" style={{ width: "25%" }} onChange={(e) => {
                                                        nextSplit.splitTwo = e.target.value;
                                                        this.setState({
                                                        });
                                                    }}></Input>
                                                </div>
                                            </div>
                                        })}
                                        <Button className="altButton" onClick={() => this.handleAddSplit()}>Additional Split</Button>
                                        <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningsplitgame">
                                            Enter the words that the user will have to split up into two words.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            Game Speed <i className="fas fa-info-circle icons-info" id="help-learning-timemultiplier" />
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-learning-timemultiplier">
                                                Control how fast or slow the game will play.
                                                Overall paths can have speed controls and the game speed will work within the context of the path speed control.
                                                For example, a game with a 'fast' speed on a path with a 'slow' speed will play at 'moderate' speed.
                                                Use this to allow developing or advanced students to play at a speed that suits them.
                                            </UncontrolledTooltip>
                                        </label>
                                        <Input type="select" value={this.state.timeMultiplier} onChange={this.timeMultiplierChange}>
                                            <option value="fatest">Fastest</option>
                                            <option value="fast">Fast</option>
                                            <option value="moderate">Moderate</option>
                                            <option value="slow">Slow</option>
                                            <option value="slowest">Slowest</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentSplitGameInstructions">Instructions <i className="fas fa-info-circle icons-info" id="help-learningsplitGame-instructions" /></Label>
                                        <Input type="textarea" name="currentSplitGameInstructions" onChange={(e) => this.setState({
                                            currentSplitGameInstructions: e.target.value
                                        })} value={this.state.currentSplitGameInstructions} />
                                        <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningsplitGame-instructions">
                                            The Instructions shown to the player before they start the Split Game.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentSplitGameAudio">Audio Insructions</Label>
                                        <Input type="select" name="currentSplitGameAudio" onChange={(e) => this.setState({
                                            currentSplitGameAudioInstructions: e.target.value
                                        })} value={this.state.currentSplitGameAudioInstructions}>
                                            <option value={''}>&nbsp;</option>
                                            {Array.from(soundNames.keys()).map((key) => {
                                                let name = soundNames.get(key);
                                                return (
                                                    <option value={key} key={key}>{name}</option>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                    {editable ? this.state.gameId === "-1" ?
                                        <Button disabled={this.state.currentSplitGameSubjectId === ''} className="adminPagesButton">Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                    }
                                </fieldset>
                            </Form>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    removeSplitWord(splitIndex: number): void {
        this.state.currentSplitGameSplitRounds.splice(splitIndex, 1);
        this.setState({});
    }

    handleAddSplit(): void {
        let initialSplit = new SplitRound();
        initialSplit.splitOne = "";
        initialSplit.splitTwo = "";
        this.state.currentSplitGameSplitRounds.push(initialSplit);
        this.setState({});
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentSplitGameTopics: topics,
            currentSplitGameTags: topicsTags
        });
    }

    timeMultiplierChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId,
        })
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let splitGame = new SplitGame(); {/*this.state.splitGame;*/ }
        splitGame.title = this.state.currentSplitGameTitle;
        splitGame.subjectId = this.state.currentSplitGameSubjectId
        splitGame.topics = this.state.currentSplitGameTopics;
        splitGame.instructions = this.state.currentSplitGameInstructions;
        splitGame.audioInstructions = this.state.currentSplitGameAudioInstructions;
        splitGame.topicTags = this.state.currentSplitGameTags;
        splitGame.rounds = this.state.currentSplitGameSplitRounds;
        splitGame.timeMultiplier = this.state.timeMultiplier;
        splitGame.schoolId = this.state.currentSplitGameSchoolId;
        splitGame.type = "SPLIT";
        let newSplitGame = false;
        let gameId = this.state.gameId;
        if (this.state.gameId === "-1") {
            newSplitGame = true;
            gameId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                splitGame.schoolId = this.state.user.schoolId;
            }
        }
        try {
            await StoreLearningGame(newSplitGame, splitGame, gameId, this.state.pathId, this.state.currentSplitGameSchoolId);

            this.props.history.replace(`/learningSplitGame/${gameId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save Failed");
        }
        this.setState({
            gameId: gameId
        })
    }

    async componentDidMount(): Promise<void> {
        try {
            if (this.state.gameId !== "-1") {
                let splitGameSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
                let splitGameData = splitGameSnapshot.data();
                let splitGame = SplitGame.fromFirebase(splitGameData);
                this.setState({
                    splitGame: splitGame,
                    currentSplitGameTitle: splitGame.title,
                    currentSplitGameSubjectId: splitGame.subjectId,
                    currentSplitGameSchoolId: splitGame.schoolId,
                    currentSplitGameInstructions: splitGame.instructions,
                    currentSplitGameAudioInstructions: splitGame.audioInstructions,
                    currentSplitGameTags: splitGame.topicTags,
                    currentSplitGameTopics: splitGame.topics,
                    currentSplitGameSplitRounds: splitGame.rounds,
                    timeMultiplier: splitGame.timeMultiplier != null ? splitGame.timeMultiplier : TIME_MULTIPLIER.moderate,
                });
            } else {
                let subjectId = '';
                if (this.state.worldId != null) {
                    const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                    let worldSnapshot = await worldRef.get();
                    let world = worldSnapshot.data();
                    subjectId = world.subject;
                } else {
                    //@ts-ignore
                    subjectId = this.state.subjectFilter;
                }
                this.setState({
                    splitGame: new SplitGame(),
                    currentSplitGameTitle: '',
                    currentSplitGameInstructions: '',
                    currentSplitGameSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                    currentSplitGameAudioInstructions: '',
                    currentSplitGameSubjectId: subjectId,
                    currentSplitGameTopics: [],
                    currentSplitGameSplitRounds: [new SplitRound()],
                    timeMultiplier: TIME_MULTIPLIER.moderate,
                    currentSplitGameTags: new Map<string, string[]>(),
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
};
export default LearningSplitGame;
import firebase from '../firebase';
import { mapToObjectConverter, objectToClassConverter } from './database_object';

export class Adventure {
    constructor() {
        this.adventureSections = new Map<string, AdventureSectionStub>();
        this.informationSections = new Map<string, InformationSectionStub>();
        this.details = new AdventureDetails();
        this.disabled = false;
    }

    schoolId: string = null;
    details: AdventureDetails = null;
    disabled: boolean;
    creation: firebase.firestore.Timestamp = null;
    adventureSections: Map<string, AdventureSectionStub> = null;
    informationSections: Map<string, InformationSectionStub> = null;

    static fromFirebase(data: any): Adventure {
        let newAdventure = new Adventure();
        if (data == null) {
            return newAdventure;
        }
        objectToClassConverter(data, newAdventure, [], ["details", "adventureSections", "informationSections"]);

        let newAdventureSectionStubs = new Map<string, AdventureSectionStub>();
        if (data.adventureSections != null) {
            for (let nextSectionId in data.adventureSections) {
                newAdventureSectionStubs.set(nextSectionId, AdventureSectionStub.fromFirebase(data.adventureSections[nextSectionId]));
            }
        }
        newAdventure.adventureSections = newAdventureSectionStubs;

        let newInformationSectionStubs = new Map<string, InformationSectionStub>();
        if (data.informationSections != null) {
            for (let nextSectionId in data.informationSections) {
                newInformationSectionStubs.set(nextSectionId, InformationSectionStub.fromFirebase(data.informationSections[nextSectionId]));
            }
        }
        newAdventure.informationSections = newInformationSectionStubs;

        newAdventure.details = AdventureDetails.fromFirebase(data.details);

        return newAdventure;
    }

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);

        let adventureSectionsData: any = {};
        if (this.adventureSections != null) {
            for (let nextAdventureSectionId in this.adventureSections) {
                adventureSectionsData[nextAdventureSectionId] = this.adventureSections.get(nextAdventureSectionId).toFirebase();
            }
        }
        fbGame.adventureSections = adventureSectionsData;

        let informationSectionsData: any = {};
        if (this.informationSections != null) {
            for (let nextInformationSectionId in this.informationSections) {
                informationSectionsData[nextInformationSectionId] = this.informationSections.get(nextInformationSectionId).toFirebase();
            }
        }
        fbGame.informationSections = informationSectionsData;

        fbGame.details = this.details.toFirebase();

        if (this.creation == null) {
            fbGame.creation = firebase.firestore.FieldValue.serverTimestamp();
        }

        fbGame.updated = firebase.firestore.FieldValue.serverTimestamp();
        return fbGame;
    }
}

export class AdventureSectionStub {
    title: string = null;
    descriptionStub: string = null;
    imageUrl: string = null;
    choices: AdventureSectionChoice[] = null;

    static fromFirebase(data: any): AdventureSectionStub {
        let newAdventureSectionStub = new AdventureSectionStub();
        if (data == null) {
            return newAdventureSectionStub;
        }
        objectToClassConverter(data, newAdventureSectionStub, [], ["choices"]);

        let newChoices = new Array<AdventureSectionChoice>();
        if (data.choices != null) {
            for (let nextChoice of data.choices) {
                newChoices.push(AdventureSectionChoice.fromFirebase(nextChoice));
            }
        }
        newAdventureSectionStub.choices = newChoices;

        return newAdventureSectionStub;
    }

    toFirebase(): {} {
        let fbDetails: any = Object.assign({}, this);

        let choiceData: any[] = [];
        if (this.choices != null) {
            for (let nextChoice of this.choices) {
                choiceData.push(nextChoice.toFirebase());
            }
        }
        fbDetails.choices = choiceData;

        fbDetails.updated = firebase.firestore.FieldValue.serverTimestamp();
        return fbDetails;
    }

}

export class InformationSectionStub {
    title: string = null;
    descriptionStub: string = null;
    imageUrl: string = null;

    static fromFirebase(data: any): InformationSectionStub {
        let newInformationSectionStub = new InformationSectionStub();
        if (data == null) {
            return newInformationSectionStub;
        }
        objectToClassConverter(data, newInformationSectionStub);

        return newInformationSectionStub;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class AdventureDetails {
    title: string = null;
    description: string = null;
    minAge: number = null;
    maxAge: number = null;
    startAdventureSectionId: string = null;
    imageUrl: string = null;
    imagePath: string = null;
    subjectId: string = null;
    topics: string[] = null;
    topicTags: Map<string, string[]> = null;

    static fromFirebase(data: any): AdventureDetails {
        let newAdventureDetails = new AdventureDetails();
        if (data == null) {
            return newAdventureDetails;
        }
        objectToClassConverter(data, newAdventureDetails, ["topicTags"]);

        return newAdventureDetails;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        if (this.topicTags != null) {
            fbObject['topicTags'] = Object.fromEntries(this.topicTags);
        }
        return fbObject;
    }
}

export class AdventureAdventureSection {
    text: string = null;
    name: string = null;
    title: string = "";
    choices: AdventureSectionChoice[] = null;
    informationLinkIds: Map<string, string> = null;
    schoolId: string = null;
    imageUrl: string = null;
    imagePath: string = null;

    static fromFirebase(data: any): AdventureAdventureSection {
        let newAdventureAdventureSection = new AdventureAdventureSection();
        if (data == null) {
            return newAdventureAdventureSection;
        }
        objectToClassConverter(data, newAdventureAdventureSection, ["informationLinkIds"], ["Choices"]);

        let newChoices = new Array<AdventureSectionChoice>();
        if (data.choices != null) {
            for (let nextChoice of data.choices) {
                newChoices.push(AdventureSectionChoice.fromFirebase(nextChoice));
            }
        }
        newAdventureAdventureSection.choices = newChoices;

        return newAdventureAdventureSection;
    }

    toFirebase(): {} {
        let fbDetails: any = Object.assign({}, this);

        let choiceData: any[] = [];
        if (this.choices != null) {
            for (let nextChoice of this.choices) {
                choiceData.push(nextChoice.toFirebase());
            }
        }
        fbDetails.choices = choiceData;

        if (this.informationLinkIds != null) {
            fbDetails.informationLinkIds = mapToObjectConverter(this.informationLinkIds);
        }

        fbDetails.updated = firebase.firestore.FieldValue.serverTimestamp();
        return fbDetails;
    }
}

export class AdventureSectionChoice {
    text: string = null;
    linkId: string = null;
    delay: number = null;

    static fromFirebase(data: any): AdventureSectionChoice {
        let newAdventureSectionChoice = new AdventureSectionChoice();
        if (data == null) {
            return newAdventureSectionChoice;
        }
        objectToClassConverter(data, newAdventureSectionChoice);

        return newAdventureSectionChoice;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class AdventureInformationSection {
    title: string = null;
    text: string = null;
    informationLinkIds: Map<string, string> = null;
    schoolId: string = null;
    imageUrl: string = null;
    imagePath: string = null;

    static fromFirebase(data: any): AdventureInformationSection {
        let newAdventureInformationSection = new AdventureInformationSection();
        if (data == null) {
            return newAdventureInformationSection;
        }
        objectToClassConverter(data, newAdventureInformationSection, ["informationLinkIds"]);

        return newAdventureInformationSection;
    }

    toFirebase(): {} {
        let fbDetails: any = Object.assign({}, this);

        if (this.informationLinkIds != null) {
            fbDetails.informationLinkIds = mapToObjectConverter(this.informationLinkIds);
        }

        fbDetails.updated = firebase.firestore.FieldValue.serverTimestamp();
        return fbDetails;
    }
}

export class AdventureResponse {
    constructor() {
        this.adventureSectionHistory = [];
    }

    adventureId: string = null;
    accountId: string = null;
    adventureSectionId: string = null;
    gameStart: number = null;
    completed: boolean = null;
    schoolId: string = null;
    secondsPassed: number = null;
    adventureSectionHistory: Array<string> = null;

    static fromFirebase(data: any): AdventureResponse {
        let newAdventureResponse = new AdventureResponse();
        if (data == null) {
            return newAdventureResponse;
        }
        objectToClassConverter(data, newAdventureResponse);

        return newAdventureResponse;
    }
}
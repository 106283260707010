import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input, Alert, Spinner, CustomInput, Card, CardBody
} from 'reactstrap';
import GoogleSuggest from "./google_suggest";
import queryString from 'query-string';
import { sendVerificationEmail } from "./helpers/email_services";
import { Link, RouteComponentProps } from "react-router-dom";
import { OrganisationContactDetails, OrganisationCreation, OrganisationDetails, OrganisationReferenceDetails } from './data/organisation';
import { License } from './data/license';
import { User, UserAccount } from './data/user';

interface IState {
    organisationDetails: OrganisationDetails;
    currentOrganisationName: string;
    currentOrganisationType: string;
    currentOrganisationImageUrl: string;
    currentOrganisationImagePath: string;
    currentFirstName: string;
    currentSurname: string;
    currentSupportEmail: string;
    currentPhoneNumber: string;
    currentEventPlaceId: string;
    currentEventPlaceAddress: string;
    createdOrgId: string;
    createdAccountId: string;
    referenceSource: string;
    referenceAdditional: string;
    licenseSelection: string[];
    licenses: Map<string, License>;
    currency: string;
    currentCountryCode: string;
    licenseIdsOrdered: string[],
    currentStep: number;
    saving: boolean;
    sendingVerificationEmail: boolean;
    checkingVerified: boolean;
    tosConfirmed: boolean;
    loginEmailAddress: string;
}

interface IProps extends RouteComponentProps {
    newUser: boolean;
    user: User;
    callback: () => void;
    snackbar: (text?: string) => void;
}

class CreateOrganisationView extends Component<IProps, IState> {
    firebaseUser: firebase.User;
    interval: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            organisationDetails: null,
            currentOrganisationName: '',
            currentOrganisationType: '',
            currentOrganisationImageUrl: '',
            currentOrganisationImagePath: '',
            currentFirstName: '',
            currentSurname: '',
            currentSupportEmail: '',
            currentPhoneNumber: '',
            currentEventPlaceId: '',
            currentEventPlaceAddress: '',
            createdOrgId: '',
            createdAccountId: '',
            referenceSource: "",
            referenceAdditional: '',
            licenseSelection: [],
            licenses: new Map(),
            currency: 'GBP',
            currentCountryCode: 'GB',
            licenseIdsOrdered: [],
            currentStep: 0,
            saving: false,
            sendingVerificationEmail: false,
            checkingVerified: false,
            tosConfirmed: false,
            loginEmailAddress: null,
        };

        this.handleUpdate = this.handleUpdate.bind(this);
        this.storeOrganisationDetails = this.storeOrganisationDetails.bind(this);
        this.handlePlaceUpdate = this.handlePlaceUpdate.bind(this);
        this.handleLicenseSelectionChange = this.handleLicenseSelectionChange.bind(this);
        this.setcurrency = this.setcurrency.bind(this);
        this.handlePersonalUpdate = this.handlePersonalUpdate.bind(this);
        this.checkVerificationStatus = this.checkVerificationStatus.bind(this);
    }

    render(): JSX.Element {

        return (
            <div className="container">
                <br />
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Organisation registration</div>
                        <p className="cardSubTitle">{this.props.newUser ? `Your email address ${this.firebaseUser != null ? this.firebaseUser.email : ""} is not linked to an existing organisation. You can now register a new organisation.` : ""}</p>
                    </CardBody>
                </Card>
                <Card className="mainCard top-buffer">
                    <CardBody className="d-flex flex-column">
                        {this.state.currentStep === 0 ?
                            <Form onSubmit={this.handlePersonalUpdate}>
                                <fieldset disabled={this.state.saving}>
                                    Step {this.state.currentStep + 1} of {this.firebaseUser == null ? "" : this.firebaseUser.emailVerified ? "2" : "3"}
                                    <div className="cardTitle2">Your details</div>
                                    <p className="cardSubTitle">First we need to create your administration account</p>
                                    <FormGroup>
                                        <Label for="currentFirstName">Your first name *</Label>
                                        <Input type="text" required name="currentFirstName" onChange={(e) => this.setState({
                                            currentFirstName: e.target.value
                                        })} value={this.state.currentFirstName} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentSurname">Your surname *</Label>
                                        <Input type="text" required name="currentSurname" onChange={(e) => this.setState({
                                            currentSurname: e.target.value
                                        })} value={this.state.currentSurname} />
                                    </FormGroup>
                                    {this.props.newUser ?
                                        <FormGroup>
                                            <Label for="referenceSource">How did you hear about us?</Label>
                                            <Input type="select" required name="referenceSource" onChange={(e) => this.setState({
                                                referenceSource: e.target.value
                                            })} value={this.state.referenceSource}>
                                                <option>Select</option>
                                                <option value={"webSearch"}>Web search</option>
                                                <option value={"advert"}>Advert</option>
                                                <option value={"socialMedia"}>Social media</option>
                                                <option value={"recommendationParent"}>Recommendation from parent</option>
                                                <option value={"recommendationTeacher"}>Recommendation from teacher/educational expert</option>
                                                <option value={"tradeshow"}>Tradeshow</option>
                                                <option value={"other"}>Other</option>
                                            </Input>
                                        </FormGroup>
                                        : <span />}
                                    {this.state.referenceSource === "socialMedia" ?
                                        <FormGroup>
                                            <Label for="referenceAdditional">Please could you let us know which social media source you heard from?</Label>
                                            <Input type="text" required name="referenceAdditional" onChange={(e) => this.setState({
                                                referenceAdditional: e.target.value
                                            })} value={this.state.referenceAdditional} />
                                        </FormGroup>
                                        : <span />}
                                    <br />
                                    <Button className="adminPagesButton">Next</Button><br /><br />
                                </fieldset>
                            </Form> : <span />}
                        {this.state.currentStep === 1 ?
                            <Form onSubmit={this.handleUpdate}>
                                <fieldset disabled={this.state.saving}>
                                    Step {this.state.currentStep + 1} of {this.firebaseUser == null ? "" : this.firebaseUser.emailVerified ? "2" : "3"}
                                    <div className="cardTitle2">Organisation details</div>
                                    <p className="cardSubTitle">Please describe the organisation you are registering</p>

                                    <FormGroup>
                                        <Label for="currentOrganisationName">Organisation name *</Label>
                                        <Input type="text" required name="currentOrganisationName" onChange={(e) => this.setState({
                                            currentOrganisationName: e.target.value
                                        })} value={this.state.currentOrganisationName} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentOrganisationType">Type * (e.g. School, Club)</Label>
                                        <Input type="select" required name="currentOrganisationType" onChange={(e) => this.setState({
                                            currentOrganisationType: e.target.value
                                        })} value={this.state.currentOrganisationType}>
                                            <option>Select</option>
                                            <option value={"primarySchool"}>Primary School</option>
                                            <option value={"middleSchool"}>Middle School</option>
                                            <option value={"secondarySchool"}>Secondary School</option>
                                            <option value={"educationEstablishment"}>Other education establishment (e.g. College, University, Nursery)</option>
                                            <option value={"privateTutor"}>Private tutor</option>
                                            <option value={"club"}>Club</option>
                                            <option value={"contentCreator"}>Content creator</option>
                                            <option value={"other"}>Other</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentSupportEmail">Support email *</Label>
                                        <Input type="email" required name="currentSupportEmail" onChange={(e) => this.setState({
                                            currentSupportEmail: e.target.value
                                        })} value={this.state.currentSupportEmail} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentPhoneNumber">Organisation phone number *</Label>
                                        <Input type="tel" required name="currentPhoneNumber" onChange={(e) => this.setState({
                                            currentPhoneNumber: e.target.value
                                        })} value={this.state.currentPhoneNumber} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Organisation address *&nbsp;&nbsp;<img alt={""} src={'/images/powered_by_google.png'} height={16} /></Label>
                                        <GoogleSuggest updatePlace={this.handlePlaceUpdate} initialValue={this.state.currentEventPlaceAddress} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currency">Default currency</Label>
                                        <Input type="select" required name="currency" onChange={this.setcurrency} value={this.state.currency}>
                                            <option value="GBP" defaultValue="true">GBP</option>
                                            <option value="USD">USD</option>
                                            <option value="ZAR">ZAR</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input required type="checkbox" value="true" name='tosConfirmed'
                                                checked={this.state.tosConfirmed}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        this.setState({
                                                            tosConfirmed: true,
                                                        });
                                                    } else {
                                                        this.setState({
                                                            tosConfirmed: false,
                                                        });
                                                    }
                                                }} />
                                            &nbsp;Please confirm you are authorised to act on behalf of this organisation and that you agree to the <a target="_blank" rel="noopener norefferer" href={"https://lifeninja.net/licence-agreement/"}>terms of
                                            service</a>
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup>
                                        <Label for="licenseSelection">Let us know which licenses you are interested in</Label>
                                        {this.state.licenseIdsOrdered.map((licenseId) => {
                                            let license = this.state.licenses.get(licenseId);
                                            return <CustomInput id={`licenseSelection-${licenseId}`} type="switch" checked={this.state.licenseSelection.indexOf(licenseId) !== -1} name={`licenseSelection-${licenseId}`} label={<a href={license.weblink} target="_blank">{license.name}</a>} onChange={(val) => {
                                                if (val.target.checked) {
                                                    if (this.state.licenseSelection.indexOf(licenseId) == -1) {
                                                        console.log("Added", licenseId);
                                                        this.state.licenseSelection.push(licenseId);
                                                        console.log(this.state.licenseSelection);
                                                    }
                                                } else {
                                                    if (this.state.licenseSelection.indexOf(licenseId) != -1) {
                                                        console.log("REmoved", licenseId);
                                                        this.state.licenseSelection.splice(this.state.licenseSelection.indexOf(licenseId), 1);
                                                    }
                                                }
                                                this.setState({});
                                            }} />
                                        })}
                                    </FormGroup>
                                    <br />
                                    <Button color="success" onClick={() => {
                                        this.setState({
                                            currentStep: 0,
                                        });
                                    }}>Back</Button>&nbsp;&nbsp;
                            <Button disabled={this.state.saving} color="success">{this.state.saving ? <Spinner size="sm"></Spinner> : <span />} Create organisation</Button>
                                    <br /><br />
                                </fieldset>
                            </Form>
                            : <span />}
                        {this.state.currentStep === 2 ?
                            <div>
                                <div className="cardTitle">Account verification</div>
                                <p className="cardSubTitle">A verification email has been sent to prove that this is your email address.<br />
                                    Please check your email inbox or spam folder for an email from Life Ninja<br />
                                    Once verified you can login to complete your organisation setup.
                                </p>

                                <Button style={{ color: "#FFFFFF", backgroundColor: "#a5d118", borderRadius: '15px 15px 15px 15px', borderColor: "#a5d118" }} type="button" onClick={async () => {
                                    this.setState({
                                        sendingVerificationEmail: true,
                                    });
                                    try {
                                        await sendVerificationEmail(this.state.loginEmailAddress, this.props.snackbar);
                                    } finally {
                                        this.setState({
                                            sendingVerificationEmail: false,
                                        });
                                    }
                                }}>
                                    {this.state.sendingVerificationEmail ? <Spinner size="sm"></Spinner> :
                                        <span className="material-icons material-icons-xd">email</span>}&nbsp;&nbsp;Send again
                                </Button>
                                <br /><br />
                                Current status: {this.firebaseUser.emailVerified ? "Verified" : <span>Not verified&nbsp;<Button disabled={this.state.checkingVerified} onClick={this.checkVerificationStatus}>{this.state.checkingVerified ? <Spinner size="sm"></Spinner> : <span />}Check now</Button></span>}
                            </div>

                            : <span />}
                        {!this.props.newUser ?
                            <Link to={"/"}><Button className="altButton">Cancel</Button></Link> : <span />}
                        <br /><br />
                    </CardBody>
                </Card>
            </div>

        );
    }

    setcurrency(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            currency: e.target.value,
        });
    }

    handlePlaceUpdate(place: google.maps.GeocoderResult): void {
        // Get the country
        let country = "GB";
        for (let addressComponent of place.address_components) {
            let typeFound = false;
            for (let type of addressComponent.types) {
                if (type === "country") {
                    typeFound = true;
                    break;
                }
            }
            if (typeFound) {
                country = addressComponent.short_name;
                break;
            }
        }
        this.setState({
            currentEventPlaceId: place.place_id,
            currentEventPlaceAddress: place.formatted_address,
            currentCountryCode: country,
        });
    }

    handleLicenseSelectionChange(e: React.ChangeEvent<HTMLInputElement>) {
        let index = this.state.licenseSelection.indexOf(e.target.value);
        if (index > -1) {
            this.state.licenseSelection.splice(index, 1);
        } else {
            this.state.licenseSelection.push(e.target.value);
        }
        this.setState({});
    }

    handlePersonalUpdate(e: React.FormEvent): void {
        e.preventDefault();
        this.setState({
            currentStep: 1,
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();

        await this.storeOrganisationDetails();
        if (!this.firebaseUser.emailVerified) {
            this.interval = setInterval(async () => {
                this.checkVerificationStatus();
            }, 10000);
            this.setState({
                currentStep: 2,
            });
        } else {
            this.loginUser();
        }
    }

    async checkVerificationStatus(): Promise<void> {
        this.setState({
            checkingVerified: true,
        });
        await this.firebaseUser.getIdToken(true);
        await this.firebaseUser.reload();
        if (this.firebaseUser.emailVerified) {
            clearInterval(this.interval);
            this.loginUser();
        } else {
            this.setState({
                checkingVerified: false,
            })
        }
    }

    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    async storeOrganisationDetails(): Promise<void> {
        this.setState({
            saving: true,
        });

        let organisationDetails = new OrganisationCreation();
        organisationDetails.name = this.state.currentOrganisationName;
        organisationDetails.type = this.state.currentOrganisationType;
        organisationDetails.supportEmail = this.state.currentSupportEmail;
        organisationDetails.imageUrl = this.state.currentOrganisationImageUrl;
        organisationDetails.imagePath = this.state.currentOrganisationImagePath;
        organisationDetails.placeId = this.state.currentEventPlaceId;
        organisationDetails.placeAddress = this.state.currentEventPlaceAddress;
        organisationDetails.phone = this.state.currentPhoneNumber;
        organisationDetails.currency = this.state.currency;
        organisationDetails.countryCode = this.state.currentCountryCode;

        organisationDetails.contact = new OrganisationContactDetails();
        organisationDetails.contact.email = this.props.user.email;
        organisationDetails.contact.firstName = this.state.currentFirstName;
        organisationDetails.contact.surname = this.state.currentSurname;

        organisationDetails.signup = new OrganisationReferenceDetails();
        organisationDetails.signup.referenceSource = this.state.referenceSource;
        organisationDetails.signup.referenceAdditional = this.state.referenceAdditional;
        organisationDetails.signup.tosConfirmed = this.state.tosConfirmed;
        organisationDetails.signup.licenseSelection = this.state.licenseSelection;

        try {

            let createOrganisationCall = firebase.functions().httpsCallable('createOrganisation');
            let response = await createOrganisationCall({
                organisationDetails: organisationDetails
            });
            let deets = response.data;

            this.props.snackbar("Creation completed");
            this.setState({
                createdAccountId: deets.adminAccountId,
                createdOrgId: deets.organisationId,
                saving: false,
            });
        } catch (error) {
            console.log(error);
            this.props.snackbar("Failed to create your organisation");
            this.setState({
                saving: false,
            });
        }
    }

    loginUser() {
        if (this.props.user.accountIds == null) {
            this.props.user.accountIds = new Map();
        }
        this.props.user.accountIds.set(this.state.createdAccountId, { schoolId: this.state.createdOrgId, subAccountId: null });
        this.props.callback();
        this.props.history.push('/');
    }

    async componentDidMount(): Promise<void> {
        let licensesSnapshot = await firebase.firestore().doc('generalConfig/licenseDetails').get();
        let licenseDetails = licensesSnapshot.data();
        let newLicensesState = new Map<string, License>();
        for (let nextLicenseId in licenseDetails.licenses) {
            let nextLicense = licenseDetails.licenses[nextLicenseId];
            newLicensesState.set(nextLicenseId, License.fromFirebase(nextLicense));
        }

        let newLicenseIdsOrdered = Array.from(newLicensesState.keys()).sort((a, b) => {
            return newLicensesState.get(a).name.localeCompare(newLicensesState.get(b).name);
        });

        this.firebaseUser = await firebase.auth().currentUser;

        this.setState({
            licenses: newLicensesState,
            licenseIdsOrdered: newLicenseIdsOrdered,
        });
    }
}

export default CreateOrganisationView;
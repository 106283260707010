//@ts-check

import { string } from 'prop-types';
import React, { Component } from 'react';
import {
    Button, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem, InputGroup, InputGroupAddon
} from 'reactstrap';
import { Topic } from '../data/home_learning';
import { LearningWorld } from '../data/learning_games';
import firebase from "../firebase";
import { yearGroups, YearGroup } from "../Prettifier";
import { ukToRegional } from "../year_group_mapper";

export const LIFE_NINJA_TRANSACTION = 0.01;
export const PAYMENT_PROCESSOR_TRANSACTION = 0.025;

interface IProps {
    worldId?: string;
    subjectId?: string;
    currentTopics?: string[];
    currentTopicsTags?: string[];
    countryCode: string;
    callback: (topic: string, topicTags: string[][]) => void;
}

interface IState {
    worldId?: string;
    subjectId?: string;
    currentTopics?: string[];
    currentTopicsTags?: string[];
    currentOrgCountry: string;
    currentTopic: string;
    currentTopicTags: string[],
    world: string,
    topicsOrdered: string[],
    currentTopicSearch: string,
    currentTopicSearchResults: SearchEntry[],
    topics: Map<string, Topic>;
    newTag: string;
    showYearGroupSelector: boolean;
    showTagSelector: boolean;
}

interface SearchEntry {
    topicId: string;
    tagId: string;
    text: string;
}

class TopicSearch extends Component<IProps, IState> {
    regionalYearGroups: Map<string, YearGroup>;
    allYearGroupTags: string[];
    regionalTagMapping: Map<string, string>;

    constructor(props: IProps) {
        super(props);
        let countryCode = props.countryCode == null ? "GB" : props.countryCode;
        if (!yearGroups.has(countryCode)) {
            countryCode = "GB";
        }
        this.regionalYearGroups = yearGroups.get(countryCode)!;
        let newAllYearGroupTags: string[] = [];
        for (let nextRegionId of Array.from(yearGroups.keys())) {
            newAllYearGroupTags.push(...Array.from(yearGroups.get(nextRegionId)!.keys()));
        }
        this.allYearGroupTags = newAllYearGroupTags;
        this.state = {
            worldId: props.worldId,
            subjectId: props.subjectId,
            currentTopics: props.currentTopics,
            currentTopicsTags: props.currentTopicsTags,
            currentOrgCountry: countryCode,
            currentTopic: null,
            currentTopicTags: [],
            world: null,
            topicsOrdered: null,
            currentTopicSearch: '',
            currentTopicSearchResults: [],
            topics: null,
            newTag: null,
            showYearGroupSelector: false,
            showTagSelector: false,
        };
        this.regionalTagMapping = ukToRegional.get(countryCode);
        this.setToggleTag = this.setToggleTag.bind(this);
        this.prepareTopics = this.prepareTopics.bind(this);
        this.handleSelectTag = this.handleSelectTag.bind(this);
        this.handleTopicChange = this.handleTopicChange.bind(this);
        this.handleTopicSearch = this.handleTopicSearch.bind(this);
        this.handleTopicSearchSelect = this.handleTopicSearchSelect.bind(this);
        this.setToggleTagWithTopic = this.setToggleTagWithTopic.bind(this);
        this.clearTopic = this.clearTopic.bind(this);
    }

    componentDidMount(): void {
        this.prepareTopics();
    }

    async prepareTopics(): Promise<void> {
        console.log("Preparing topics");
        let subjectId = this.state.subjectId;
        if (subjectId == null) {
            const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
            let worldSnapshot = await worldRef.get();
            let worldData = worldSnapshot.data();
            let world = LearningWorld.fromFirebase(worldData);
            if (world != null) {
                subjectId = world.subject;
            }
        }

        const topicsRef = firebase.firestore().collection(`learningTopics`).where('subjectId', '==', subjectId);
        let topicSnapshot = await topicsRef.get();
        let topicsOrdered = new Array<string>();
        let topics = new Map<string, Topic>();
        topicSnapshot.forEach((docSnapshot) => {
            let nextTopics = docSnapshot.data();
            Object.keys(nextTopics.topics).forEach((topicId) => {
                let nextTopic = Topic.fromFirebase(nextTopics.topics[topicId]);
                topicsOrdered.push(topicId);
                topics.set(topicId, nextTopic);
            });
        });
        topicsOrdered.sort((id1, id2) => {
            return topics.get(id1).name.localeCompare(topics.get(id2).name);
        });
        this.setState({
            topicsOrdered: topicsOrdered,
            topics: topics,
        });
    }

    handleTopicSearch(e: React.ChangeEvent<HTMLInputElement>) {
        let searchText = e.target.value.toLowerCase();
        let responses: SearchEntry[] = [];
        for (let nextTopicId of Array.from(this.state.topics.keys())) {
            let nextTopic = this.state.topics.get(nextTopicId);
            let tagIds = Array.from(nextTopic.tags.keys());
            tagIds = tagIds.filter((tagId) => {
                // Remove suggested year groups
                return this.allYearGroupTags.indexOf(tagId) === -1;
            });
            if (nextTopic.name.toLowerCase().search(searchText) !== -1) {
                responses.push({
                    topicId: nextTopicId,
                    text: nextTopic.name,
                    tagId: null,
                })
            }
            for (let nextTagId of tagIds) {
                let tagName = nextTopic.tags.get(nextTagId);
                if (tagName.toLowerCase().search(searchText) !== -1) {
                    responses.push({
                        topicId: nextTopicId,
                        tagId: nextTagId,
                        text: `${nextTopic.name} -> ${tagName}`,
                    })
                }
            }
        }
        responses.sort((searchEntry1, searchEntry2) => {
            return searchEntry1.text.localeCompare(searchEntry2.text);
        });
        this.setState({
            currentTopicSearchResults: responses,
            currentTopicSearch: searchText,
        });
    }

    handleTopicSearchSelect(searchEntry: SearchEntry) {
        let topicId = searchEntry.topicId;
        if (searchEntry.tagId != null) {
            this.setState({
                currentTopic: topicId,
            });
            this.setToggleTagWithTopic(topicId, [], searchEntry.tagId);
        } else {
            this.setState({
                currentTopic: topicId,
                currentTopicTags: [],
            });
            this.props.callback(topicId, []);
        }
    }

    clearTopic() {
        this.setState({
            currentTopic: null,
            currentTopicTags: [],
        })
    }

    render() {
        if (this.state.topics == null) {
            return <div>
                <FormGroup>
                    <Label>Topic</Label>
                    <div>Loading...</div>
                </FormGroup>
            </div>
        }
        let orderedTagIds: string[] = [];
        if (this.state.currentTopic != null && this.state.topics.has(this.state.currentTopic) && this.state.topics.get(this.state.currentTopic).tags != null && this.state.topics.get(this.state.currentTopic).tags.size != 0) {
            orderedTagIds = Array.from(this.state.topics.get(this.state.currentTopic).tags.keys());
            orderedTagIds = orderedTagIds.filter((tagId) => {
                // Remove suggested year groups
                return this.allYearGroupTags.indexOf(tagId) === -1;
            });

            orderedTagIds.sort((id1, id2) => {
                let tag1 = this.state.topics.get(this.state.currentTopic).tags.get(id1);
                let tag2 = this.state.topics.get(this.state.currentTopic).tags.get(id2);

                return tag1.localeCompare(tag2);
            });
        }
        let currentTopicName;
        if (this.state.currentTopic != null) {
            currentTopicName = this.state.topics.get(this.state.currentTopic).name;
        }
        return <div>
            <div>
                {this.state.currentTopic == null ?
                    <div>
                        <FormGroup>
                            <Label for="currentTopic">Topic</Label>
                            <Input required type="select" name="currentTopic"
                                onChange={this.handleTopicChange}
                                value={this.state.currentTopic}>
                                <option key={'def'}>Select topic</option>
                                {this.state.topicsOrdered.map((topicId) => {
                                    let topic = this.state.topics.get(topicId);
                                    return (
                                        <option value={topicId} key={topicId}>{topic.name}</option>
                                    )
                                })
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="currentTopicSearch">or search topics and tags</Label>
                            <Input required type="search" name="currentTopicSearch"
                                onChange={this.handleTopicSearch}
                                value={this.state.currentTopicSearch}>
                            </Input>
                        </FormGroup>
                        <ListGroup flush>
                            {this.state.currentTopicSearchResults.map((searchEntry) => {
                                return <ListGroupItem tag="button" action onClick={() => this.handleTopicSearchSelect(searchEntry)}>{searchEntry.text}</ListGroupItem>
                            })}
                        </ListGroup>
                    </div> : <FormGroup>
                        <Label for="currentTopic">Topic</Label>
                        <InputGroup>
                            <Input required type="text" name="currentTopic"
                                disabled={true} value={currentTopicName}>
                            </Input>
                            <InputGroupAddon addonType="append"><Button onClick={this.clearTopic}>x</Button></InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                }
                <FormGroup>
                    {this.state.currentTopicTags == null || this.state.currentTopicTags.length <= 0 ? <span /> :
                        <span>
                            {this.state.currentTopicTags.map((tagId) => {
                                let tagName;
                                if (this.allYearGroupTags.indexOf(tagId) == -1) {
                                    tagName = this.state.topics.get(this.state.currentTopic).tags.get(tagId);
                                } else {
                                    tagName = this.regionalYearGroups.get(tagId).name;
                                }
                                if (tagName == null) {
                                    return;
                                }
                                return <span><Button key={tagId}
                                    color={'success'}
                                    onClick={() => this.setToggleTag(tagId)}>
                                    {tagName}&nbsp;&nbsp;<b>X</b>
                                </Button>&nbsp;</span>;
                            })}
                        </span>
                    }
                </FormGroup>
            </div>
            {this.state.currentTopic == null ? <span /> : !this.state.topics.has(this.state.currentTopic) || this.state.topics.get(this.state.currentTopic).tags == null || this.state.topics.get(this.state.currentTopic).tags.size === 0 ?
                <span>No tags available</span> :
                <span>
                    <Button key={"addTag"}
                        color={'primary'}
                        onClick={() => this.setState({ showTagSelector: true })}>
                        {"Add tag  +"}
                    </Button>&nbsp;
                    <Button key={"addYearGroup"}
                        color={'primary'}
                        onClick={() => this.setState({ showYearGroupSelector: true })}>
                        {"Add year group  +"}
                    </Button>
                    <Modal isOpen={this.state.showTagSelector || this.state.showYearGroupSelector} toggle={() => this.setState({ showTagSelector: false, showYearGroupSelector: false })}>
                        <ModalHeader toggle={() => this.setState({ showTagSelector: false, showYearGroupSelector: false })}>Add tag</ModalHeader>
                        <ModalBody>
                            <Label for="newTag">Tags</Label>
                            <Input required type="select" name="newTag"
                                onChange={this.handleSelectTag}
                                value={this.state.newTag}>
                                <option value={""} key={'def'}>Select tag</option>
                                {this.state.showTagSelector ? orderedTagIds.map((tagId) => {
                                    if (this.state.currentTopicTags.indexOf(tagId) !== -1) {
                                        return null;
                                    }
                                    let tag = this.state.topics.get(this.state.currentTopic).tags.get(tagId);
                                    return (
                                        <option value={tagId} key={tagId}>{tag}</option>
                                    )
                                })
                                    : Array.from(this.regionalYearGroups.keys()).map((tagId) => {
                                        let tag = this.regionalYearGroups.get(tagId);
                                        if (tag.errorTag != null) {
                                            return;
                                        }
                                        if (this.state.currentTopicTags.indexOf(tagId) !== -1) {
                                            return null;
                                        }

                                        return (
                                            <option value={tagId} key={tagId}>{tag.name}</option>
                                        )
                                    })
                                }
                            </Input>
                            <br />
                            <Button color="success"
                                disabled={this.state.newTag == null || this.state.newTag === ""}
                                onClick={() => {
                                    this.setToggleTag(this.state.newTag);
                                    this.setState({
                                        newTag: null,
                                    });
                                }}>
                                Add
                    </Button>&nbsp;
                    <Button color="success"
                                disabled={this.state.newTag == null || this.state.newTag === ""}
                                onClick={() => {
                                    this.setToggleTag(this.state.newTag);
                                    this.setState({
                                        showTagSelector: false,
                                        showYearGroupSelector: false,
                                        newTag: null,
                                    });
                                }}>
                                Add & close
                    </Button>
                        </ModalBody>
                    </Modal>
                </span>
            }
        </div>
    }

    setToggleTag(tagName: string): void {
        this.setToggleTagWithTopic(this.state.currentTopic, [...this.state.currentTopicTags], tagName);
    }

    setToggleTagWithTopic(topic: string, existingTags: string[], tagName: string): void {
        let index = existingTags.indexOf(tagName);
        if (index === -1) {
            existingTags.push(tagName);
        } else {
            existingTags.splice(index, 1);
        }
        this.setState({
            currentTopicTags: existingTags,
        });

        let errorTagMappings = new Map<string, string[]>();
        for (let nextTagId of Array.from(this.regionalYearGroups.keys())) {
            let nextTag = this.regionalYearGroups.get(nextTagId);
            if (nextTag.errorTag != null) {
                if (!errorTagMappings.has(nextTag.errorTag)) {
                    errorTagMappings.set(nextTag.errorTag, []);
                }
                errorTagMappings.get(nextTag.errorTag).push(nextTagId);
            }
        }

        let callbackTagIds = [];
        for (let nextTagId of existingTags) {
            let nextOrGroup = [nextTagId];
            // Add inherited search terms
            if (this.regionalYearGroups.has(nextTagId) && this.regionalYearGroups.get(nextTagId).subTags != null) {
                for (let nextSubTagId of this.regionalYearGroups.get(nextTagId).subTags) {
                    if (nextOrGroup.indexOf(nextSubTagId) === -1) {
                        nextOrGroup.push(nextSubTagId);
                    }
                }
            }
            if (this.regionalTagMapping != null && this.regionalYearGroups.has(nextTagId)) {
                for (let nextUkTagId in Array.from(this.regionalTagMapping.keys())) {
                    let mapping = this.regionalTagMapping.get(nextUkTagId);
                    if (mapping === nextTagId) {
                        nextOrGroup.push(nextUkTagId);
                    }
                }
            }
            for (let nextTagId of nextOrGroup) {
                if (errorTagMappings.has(nextTagId)) {
                    for (let nextErrorTag of errorTagMappings.get(nextTagId)) {
                        if (nextOrGroup.indexOf(nextErrorTag) === -1) {
                            nextOrGroup.push(nextErrorTag);
                        }
                    }
                }
            }
            callbackTagIds.push(nextOrGroup);
        }
        this.props.callback(topic, callbackTagIds);
    }

    handleTopicChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let topicId = e.target.value;
        this.setState({
            currentTopic: topicId,
            currentTopicTags: [],
        });
        this.props.callback(topicId, []);
    }

    handleSelectTag(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            newTag: e.target.value
        });
    }
}

export default TopicSearch;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import { Table, BreadcrumbItem, Breadcrumb, Alert, Card, CardBody } from 'reactstrap';
import { User } from './data/user';
import { AccountGroup, AccountMinimised } from './data/accounts';
import { ModerationChatStatus } from './data/chats';

interface IState {
    accountGroupId: string;
    moderationChats: Map<string, ModerationChatStatus>;
}

interface MatchParams {
    accountGroupId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    accountsMinimised: Map<string, AccountMinimised>;
    accountGroups: Map<string, AccountGroup>;
}

class ModeratorChatsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            accountGroupId: props.match.params.accountGroupId,
            moderationChats: new Map(),
        };
    }

    render() {
        let chatsSorted: string[];
        if (this.state.moderationChats == null) {
            chatsSorted = [];
        } else {
            chatsSorted = Array.from(this.state.moderationChats.keys());
        }
        chatsSorted.sort((ac1, ac2) => {
            return this.state.moderationChats.get(ac1).timestamp - this.state.moderationChats.get(ac2).timestamp;
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/chats'}>All chats</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/chats/${this.state.accountGroupId}`}>{this.props.accountGroups.has(this.state.accountGroupId) ? this.props.accountGroups.get(this.state.accountGroupId).details.name : ""}</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Moderator chats</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Moderator chats</div>
                        <p className="cardSubTitle">Private chats with moderators.</p>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Account</th>
                                    <th>Last message</th>
                                    <th>Sent</th>
                                </tr>
                            </thead>
                            <tbody>
                                {chatsSorted.map((accountId) => {
                                    let lastMessage = this.state.moderationChats.get(accountId);
                                    let lastMessageSent = new Date(lastMessage.timestamp);
                                    return <tr>
                                        <th scope="row"><Link to={`/chats/${this.state.accountGroupId}/moderatorChats/${accountId}`}>{this.props.accountsMinimised.has(accountId) ? this.props.accountsMinimised.get(accountId).name : "<Not named>"}</Link></th>
                                        <td>{lastMessage.text}</td>
                                        <td>{lastMessageSent.toDateString()} {lastMessageSent.toLocaleTimeString()}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    async componentDidMount() {
        try {
            const chatsRef = firebase.database().ref(`chats/groups/${this.props.user.schoolId}/${this.state.accountGroupId}/historicalModerationGroupStatuses`);
            let chatsSnapshot = await chatsRef.once('value');
            let chatsData = chatsSnapshot.val();
            let chats = new Map<string, ModerationChatStatus>();
            if (chatsData != null) {
                for (let nextModChatId in chatsData) {
                    chats.set(nextModChatId, ModerationChatStatus.fromFirebase(chatsData[nextModChatId]));
                }
            }

            this.setState({
                moderationChats: chats,
            });
        } catch (error) {
            console.log(error)
        }
    }
}
export default ModeratorChatsView;
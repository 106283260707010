import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, FormGroup, Input, Label, Card, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";
import queryString from 'query-string';
import { subjectIds } from './Prettifier';
import { QuizTemplate } from './data/quizzes';
import { User } from './data/user';

interface IState {
    quizTemplates: Map<string, QuizTemplate>,
    quizTemplateIdsSorted: string[];
    subjectFilter: string;
    moreQuizTemplates: boolean;
}

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
}

class QuizTemplatesView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            quizTemplates: new Map(),
            quizTemplateIdsSorted: [],
            subjectFilter: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
            moreQuizTemplates: true,
        };
        this.removeItem = this.removeItem.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.getNextQuizTemplatesBatch = this.getNextQuizTemplatesBatch.bind(this);
    }

    render(): JSX.Element {

        let queryValues = this.getQuerys();

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All quiz templates</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Quiz Templates</div>
                        <p className="cardSubTitle">All the quiz templates created by the school. A quiz template can be run many times as a live quiz.</p>
                        <FormGroup>
                            <Label for="subjectFilter">Subject filter</Label>

                            <Input type="select" name="subjectFilter"
                                onChange={this.updateFilter}
                                value={this.state.subjectFilter}>
                                <option value={''}>All</option>
                                {Array.from(subjectIds.keys()).map((key) => {
                                    let name = subjectIds.get(key);
                                    return (
                                        <option value={key} key={key}>{name}</option>
                                    )
                                })
                                }
                            </Input>
                        </FormGroup>
                        <div>
                            <Link to={`/quizTemplates/-1/false?${queryValues}`}>
                                <Button className="adminPagesButton" type="button">
                                    Create quiz template
                        </Button>
                            </Link>
                            <Link to={`/tutorialVideo/QuizItem`}>
                                <Button style={{marginLeft:"15px"}} className="altButton">Watch Tutorial</Button>
                            </Link>
                            <a target="_blank" href="https://docs.google.com/document/d/1o9JZ6ntEwFMvvE6BUewkKVdoMqMQaOF8vKLo11bYVuM/edit">
                                <Button style={{marginLeft:"15px"}} className="altButton">Read Tutorial</Button>
                            </a>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Subject</th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.quizTemplateIdsSorted.map((quizTemplateId) => {
                                    let quizTemplate = this.state.quizTemplates.get(quizTemplateId);

                                    if (quizTemplate == null) {
                                        return;
                                    }

                                    return <tr>
                                        <th scope="row"><Link to={`/quizTemplates/${quizTemplateId}/false?${queryValues}`}>{quizTemplate.name}</Link></th>
                                        <td>{subjectIds.get(quizTemplate.subjectId)}</td>
                                        <td>
                                            <Link to={`/quizTemplates/${quizTemplateId}/true?${queryValues}`}>
                                                <Button className="adminPagesButton" type="button">
                                                    Start quiz
                                        </Button>
                                            </Link>
                                        </td>
                                        <td><Button color="link" onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this quiz template", confirmText: "Confirm" });
                                            if (result) {
                                                this.removeItem(quizTemplateId)
                                            }
                                        }}><i className="material-icons">delete</i></Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        {this.state.moreQuizTemplates ?
                            <div>
                                <p>Showing {this.state.quizTemplateIdsSorted.length}</p>
                                <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.getNextQuizTemplatesBatch(false, this.state.subjectFilter);
                                    }}>
                                    Show more
                        </Button>
                            </div> : <span />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
        });
    }

    async componentDidMount(): Promise<void> {
        this.getNextQuizTemplatesBatch(true, this.state.subjectFilter);
    }

    async updateFilter(e: React.ChangeEvent<HTMLInputElement>) {
        let newSubjectId = e.target.value;
        this.setState({
            subjectFilter: newSubjectId
        });
        await this.getNextQuizTemplatesBatch(true, newSubjectId);
    }

    async getNextQuizTemplatesBatch(seed: boolean, subjectFilter: string): Promise<void> {
        if (seed != null && seed) {
            this.setState({
                quizTemplates: new Map(),
                quizTemplateIdsSorted: [],
                moreQuizTemplates: true,
            });
        }
        let quizTemplatesRef = firebase.firestore().collection(`quizTemplates`)
            .where('schoolId', '==', this.props.user.schoolId)
            .where('deactivated', '==', false);

        if (subjectFilter !== "") {
            quizTemplatesRef = quizTemplatesRef.where("subjectId", "==", subjectFilter);
        }
        quizTemplatesRef = quizTemplatesRef.orderBy("updated");
        if (seed == null || !seed) {
            // Find last homework
            let lastId = this.state.quizTemplateIdsSorted[this.state.quizTemplateIdsSorted.length - 1];
            if (lastId != null) {
                quizTemplatesRef = quizTemplatesRef.endAt(this.state.quizTemplates.get(lastId).updated);
            }
        }
        quizTemplatesRef = quizTemplatesRef.limitToLast(10);
        try {
            let snapshot = await quizTemplatesRef.get();
            let newMoreQuizTemplates = this.state.moreQuizTemplates;
            let quizTemplatesData = snapshot.docs;
            let newQuizTemplates = new Map<string, QuizTemplate>();
            if (quizTemplatesData != null) {
                for (let quizTemplate of quizTemplatesData) {
                    newQuizTemplates.set(quizTemplate.id, QuizTemplate.fromFirebase(quizTemplate.data()));
                }
            }

            if (newQuizTemplates.size < 10) {
                newMoreQuizTemplates = false;
            }
            let quizTemplates = new Map<string, QuizTemplate>([...this.state.quizTemplates, ...newQuizTemplates]);
            let quizTemplateIdsSorting = Array.from(quizTemplates.keys());
            quizTemplateIdsSorting.sort((aId, bId) => {
                return quizTemplates.get(bId).updated.toMillis() - quizTemplates.get(aId).updated.toMillis();
            });
            this.setState({
                quizTemplateIdsSorted: quizTemplateIdsSorting,
                quizTemplates: quizTemplates,
                moreQuizTemplates: newMoreQuizTemplates,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(quizId: string): Promise<void> {
        const quizRef = firebase.firestore().doc(`quizTemplates/${quizId}`);
        try {
            quizRef.update({ deactivated: true });
            this.state.quizTemplates.delete(quizId);
            this.state.quizTemplateIdsSorted.splice(this.state.quizTemplateIdsSorted.indexOf(quizId), 1);
            this.setState({});
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }
}
export default QuizTemplatesView;
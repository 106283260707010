import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, BreadcrumbItem, Breadcrumb, Alert, FormGroup, Label, Input, Card, CardHeader, CardBody, CardImg } from 'reactstrap';
import confirm from "reactstrap-confirm";
import queryString from 'query-string';
import { User, USER_ROLE } from './data/user';
import { Adventure } from './data/adventures';
import RootView from './root_view';
import { subjectIds } from './Prettifier';

interface IState {
    adventures: Map<string, Adventure>;
    adventureIdsSorted: string[];
    moreAdventures: boolean;
    subjectFilter: string;
}

interface IProps extends RouteComponentProps {
    tutorialCallback: (pageName: string) => void;
    user: User;
    snackbar: (text?: string) => void;
}

class AdventuresView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            adventures: new Map(),
            adventureIdsSorted: [],
            moreAdventures: true,
            subjectFilter: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
        };
        this.createCard = this.createCard.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.getNextAdventuresBatch = this.getNextAdventuresBatch.bind(this);
        this.makeShortString = this.makeShortString.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
    }

    createCard(nextAdventureId: string) {
        let nextAdventure = this.state.adventures.get(nextAdventureId);
        let editable = nextAdventure.schoolId === this.props.user.schoolId || this.props.user.role === USER_ROLE.admin || (this.props.user.roles.has(USER_ROLE.learningCreator) && this.props.user.roles.get(USER_ROLE.learningCreator));

        return <Card style={{ width: "100%" }}>
            <CardHeader>{nextAdventure.details.title}{!editable ? <span style={{ "color": "red" }}> - View only</span> : ""}</CardHeader>
            <CardBody className="d-flex flex-column">
                {nextAdventure.details.imageUrl != null && nextAdventure.details.imageUrl != "" ?
                    <CardImg src={nextAdventure.details.imageUrl}></CardImg> : <span />
                }
                <div className="mt-auto">
                    <Link to={`/adventures/${nextAdventureId}?${this.getQuerys()}`}>
                        <Button block className="altButton" type="button">
                            Open
                        </Button>
                    </Link><br />
                    {editable ?
                        <Button block outline color="danger" onClick={async () => {
                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this adventure", confirmText: "Confirm" });
                            if (result) {
                                this.removeItem(nextAdventureId)
                            }
                        }}>Remove</Button> : <span />
                    }
                </div>
            </CardBody>
        </Card>
    }

    render(): JSX.Element {
        let adventureIds = Array.from(this.state.adventures.keys());
        adventureIds.sort((id1, id2) => {
            let adventure1 = this.state.adventures.get(id1);
            let adventure2 = this.state.adventures.get(id2);
            let comparator1 = adventure1.details == null || adventure1.details.title == null ? id1 : adventure1.details.title;
            let comparator2 = adventure2.details == null || adventure2.details.title == null ? id2 : adventure2.details.title;
            return comparator1.localeCompare(comparator2);
        });

        let rows = RootView.createCardRows(adventureIds, this.createCard, "");

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All adventures</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Adventures</div>
                        <p className="cardSubTitle">An adventure is a series of questions resulting in different outcomes, finally culminating in success or (optionally) failure.</p>
                        <FormGroup>
                            <Label for="subjectFilter">Subject filter</Label>

                            <Input type="select" name="subjectFilter"
                                onChange={this.updateFilter}
                                value={this.state.subjectFilter}>
                                <option value={''}>All</option>
                                {Array.from(subjectIds.keys()).map((key) => {
                                    let name = subjectIds.get(key);
                                    return (
                                        <option value={key} key={key}>{name}</option>
                                    )
                                })
                                }
                            </Input>
                        </FormGroup>
                        <div>
                            <Link to={`/adventures/-1?${this.getQuerys()}`}>
                                <Button className="adminPagesButton" type="button" onClick={() => this.props.tutorialCallback('adventures')}>
                                    Create adventure
                        </Button>
                            </Link>
                        </div>
                        <br />
                        <div>
                            {rows}
                        </div><br />
                        {this.state.moreAdventures ?
                            <div>
                                <p>Showing {this.state.adventureIdsSorted.length}</p>
                                <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.getNextAdventuresBatch(false, this.state.subjectFilter);
                                    }}>
                                    Show more
                        </Button>
                            </div> : <span />
                        }
                        <br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
        });
    }

    makeShortString(description: string): string {
        if (description == null) {
            return "";
        }
        if (description.length < 30) {
            return description;
        }
        let descriptionResult = description.substr(0, 30);
        let spacePos = descriptionResult.lastIndexOf(" ");
        if (spacePos !== -1) {
            descriptionResult = descriptionResult.substr(0, spacePos);
        }
        descriptionResult += "...";

        return descriptionResult;
    }

    async updateFilter(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let newSubject = e.target.value;
        await this.getNextAdventuresBatch(true, newSubject);
        this.setState({
            subjectFilter: newSubject
        });
    }

    async componentDidMount(): Promise<void> {
        this.getNextAdventuresBatch(true, this.state.subjectFilter);
    }

    async getNextAdventuresBatch(seed: boolean, subjectFilter: string): Promise<void> {
        let newAdventures = new Map<string, Adventure>();
        let newAdventureIdsSorted: string[] = [];

        if (seed != null && seed) {
            this.setState({
                adventures: new Map(),
                adventureIdsSorted: [],
                moreAdventures: true,
            });
        }

        if (seed != null && seed) {
            let adventuresRef = firebase.firestore().collection(`adventures`)
                .where('schoolId', '==', null)
                .where('disabled', '==', false)
                .orderBy("details.title").orderBy("creation");

            let snapshot = await adventuresRef.get();
            let adventures = snapshot.docs;
            if (adventures != null) {
                for (let nextAdventureSnapshot of adventures) {
                    let nextAdventureId = nextAdventureSnapshot.id;
                    if (!this.state.adventures.has(nextAdventureId)) {
                        newAdventures.set(nextAdventureId, Adventure.fromFirebase(nextAdventureSnapshot.data()));
                        newAdventureIdsSorted.push(nextAdventureId);
                    }
                }
            }
        }
        let adventuresRef = firebase.firestore().collection(`adventures`)
            .where('schoolId', '==', this.props.user.schoolId)
            .where('disabled', '==', false);

        if (subjectFilter !== "") {
            adventuresRef = adventuresRef.where("details.subjectId", "==", subjectFilter);
        }

        adventuresRef = adventuresRef.orderBy("details.title").orderBy("creation");
        if (seed == null || !seed) {
            // Find last homework
            let lastId = this.state.adventureIdsSorted[this.state.adventureIdsSorted.length - 1];
            if (lastId != null) {
                let lastAdventure = this.state.adventures.get(lastId);
                adventuresRef = adventuresRef.startAt(lastAdventure.details.title, lastAdventure.creation);
            }
            adventuresRef = adventuresRef.limit(11);
        } else {
            adventuresRef = adventuresRef.limit(10);
        }
        try {
            let snapshot = await adventuresRef.get();
            let newMoreAdventures = this.state.moreAdventures;
            let adventures = snapshot.docs;


            if (adventures != null) {
                for (let nextAdventureSnapshot of adventures) {
                    let nextAdventureId = nextAdventureSnapshot.id;
                    if (!this.state.adventures.has(nextAdventureId)) {
                        newAdventures.set(nextAdventureId, Adventure.fromFirebase(nextAdventureSnapshot.data()));
                        newAdventureIdsSorted.push(nextAdventureId);
                    }
                }
            }
            newAdventureIdsSorted.reverse();

            if (newAdventureIdsSorted.length < 10) {
                newMoreAdventures = false;
            }
            this.setState({
                adventureIdsSorted: [...this.state.adventureIdsSorted, ...newAdventureIdsSorted],
                adventures: new Map([...this.state.adventures, ...newAdventures]),
                moreAdventures: newMoreAdventures,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(adventureId: string): Promise<void> {
        try {
            const homeworkDeactivatedRef = firebase.firestore().doc(`adventures/${adventureId}`);
            await homeworkDeactivatedRef.update({ disabled: true });

            this.state.adventures.delete(adventureId);

            this.setState({});
            this.props.snackbar("Deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }
}
export default AdventuresView;
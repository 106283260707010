import firebase from '../firebase';
import { objectToClassConverter } from './database_object';

export enum LICENSE_TYPE {PER_ACCOUNT = "perAccount", TOGGLE = "toggle"}

export class License {
    name: string;
    description: string;
    learningWorlds: boolean;
    type: LICENSE_TYPE;
    pricing: LicensePrice[];
    screenshots: string[];
    trialLength: number;
    videoId: string;
    weblink: string;
    longDescription: string;

    static fromFirebase(data: any): License {
        let newLicense = new License();
        if (data == null) {
            return newLicense;
        }
        objectToClassConverter(data, newLicense, [], ["pricing"]);

        let newPricing: LicensePrice[] = [];
        if (data.pricing != null) {
            for (let nextPricing of data.pricing) {
                newPricing.push(LicensePrice.fromFirebase(nextPricing));
            }
        }
        newLicense.pricing = newPricing;

        return newLicense;
    }
}

export class LicensePrice {
    cost: Map<string, number>; // TODO change database entries to numbers
    max: number;

    static fromFirebase(data: any): LicensePrice {
        let newLicensePrice = new LicensePrice();
        if (data == null) {
            return newLicensePrice;
        }
        objectToClassConverter(data, newLicensePrice, ["cost"], []);

        return newLicensePrice;
    }
}

export class LicenseActivity {
    enabled: boolean;
    trialStart: number;

    static fromFirebase(data: any): LicenseActivity {
        let newLicenseActivity = new LicenseActivity();
        if (data == null) {
            return newLicenseActivity;
        }
        objectToClassConverter(data, newLicenseActivity);

        return newLicenseActivity;
    }
}

export class LicenseAssignments {
    assignments: Map<string, LicenseAssignment[]>;

    static fromFirebase(data: any): LicenseAssignments {
        let newLicenseAssignments = new LicenseAssignments();
        if (data == null) {
            return newLicenseAssignments;
        }
        objectToClassConverter(data, newLicenseAssignments, [], ["assignments"]);

        let newAssignments = new Map<string, LicenseAssignment[]>();
            for (let nextAssignmentId of Object.keys(data)) {
                let newAssignmentsList: LicenseAssignment[] = [];
                newAssignments.set(nextAssignmentId, newAssignmentsList);
                for (let nextAssignment of data[nextAssignmentId]) {
                    newAssignmentsList.push(LicenseAssignment.fromFirebase(nextAssignment));
                }
            }
        newLicenseAssignments.assignments = newAssignments;

        return newLicenseAssignments;
    }
}

export class LicenseAssignment {
    start: number;
    end: number;

    static fromFirebase(data: any): LicenseAssignment {
        let newLicenseAssignment = new LicenseAssignment();
        if (data == null) {
            return newLicenseAssignment;
        }
        objectToClassConverter(data, newLicenseAssignment);

        return newLicenseAssignment;
    }
}

export class LicenseApp {
    description: string;
    name: string;

    static fromFirebase(data: any): LicenseApp {
        let newLicenseApp = new LicenseApp();
        if (data == null) {
            return newLicenseApp;
        }
        objectToClassConverter(data, newLicenseApp);

        return newLicenseApp;
    }
}
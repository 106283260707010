import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import {
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Alert,
    Table,
    Card,
    CardBody,
} from 'reactstrap';
import moment from "moment";
import { CSVLink } from "react-csv";
import { User } from './data/user';
import { PaymentRecord } from './data/payment_settings';
import { AccountMinimised } from './data/accounts';

interface IState {
    currentAccountId: string;
    currentStartDate: string;
    paymentRecords: Map<string, PaymentRecord>,
    paymentRecordIdsOrdered: string[];
    remainingPaymentEntries: boolean;
}

interface IProps {
    user: User;
    accountsMinimised: Map<string, AccountMinimised>;
}

class PaymentHistoryView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            currentAccountId: '',
            currentStartDate: '',
            paymentRecords: new Map(),
            paymentRecordIdsOrdered: [],
            remainingPaymentEntries: true,
        };
        this.loadPaymentHistoryBatch = this.loadPaymentHistoryBatch.bind(this);
    }

    render() {
        let headers = ["AccountId", "Name", "Additional", "Email", "SquareCustomerId",
            "Amount", "Date", "SquareOrderId", "Type", "Title", "TypeId", "TypeSubId",];
        let data: string[][] = [];
        Array.from(this.state.paymentRecords.keys()).forEach((paymentRecordId) => {
            let paymentHistory = this.state.paymentRecords.get(paymentRecordId);
            let account = this.props.accountsMinimised.get(paymentHistory.accountId);
            if (account == null) {
                account = new AccountMinimised();
                account.name = "REMOVED";
                account.additional = "REMOVED";
            }
            let amount = (paymentHistory.cost / 100).toFixed(2);
            data.push([paymentHistory.accountId, account.name, account.additional, paymentHistory.email, paymentHistory.customerId,
                amount, new Date(paymentHistory.timestamp).toISOString(), paymentHistory.orderId,
            paymentHistory.objectType, paymentHistory.title, paymentHistory.objectId, paymentHistory.subObjectId == null ? "" : paymentHistory.subObjectId,
            ]);
        });
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All payments</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Payment history</div>
                        <p className="cardSubTitle">All payments received by the Message Ninja platform</p>
                        {this.state.paymentRecords != null ?
                            <div>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Account</th>
                                            <th>Additional</th>
                                            <th>Type</th>
                                            <th>Title</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th>Email</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.paymentRecordIdsOrdered.map((paymentHistoryId) => {
                                            let paymentHistory = this.state.paymentRecords.get(paymentHistoryId);
                                            let account = this.props.accountsMinimised.get(paymentHistory.accountId);
                                            if (account == null) {
                                                account = new AccountMinimised();
                                                account.name = "REMOVED";
                                                account.additional = "REMOVED";
                                            }
                                            let amount = (paymentHistory.cost / 100).toFixed(2);
                                            let linkUrl = "";
                                            if (paymentHistory.objectType === "event") {
                                                linkUrl = `events/${paymentHistory.objectId}`;
                                            } else if (paymentHistory.objectType === "slot") {
                                                linkUrl = `slotGroups/${paymentHistory.objectId}`;
                                            } else if (paymentHistory.objectType === "fundraising") {
                                                linkUrl = `fundraisers/${paymentHistory.objectId}`;
                                            } else if (paymentHistory.objectType === "marketplace") {
                                                linkUrl = `purchases`;
                                            }
                                            return <tr>
                                                <th scope="row"><Link to={`accounts/${paymentHistory.accountId}`}>{account.name}</Link></th>
                                                <td>{account.additional}</td>
                                                <td><Link to={linkUrl}>{paymentHistory.objectType}</Link></td>
                                                <td>{paymentHistory.title}</td>
                                                <td>£{amount}</td>
                                                <td>{moment(paymentHistory.timestamp).format("ddd, MMM Do YYYY, h:mm a")}</td>
                                                <td>{paymentHistory.email}</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                                {this.state.remainingPaymentEntries ?
                                    <Button color="link" onClick={this.loadPaymentHistoryBatch}>
                                        Show more
                            </Button> : <span />
                                }<br />
                                <CSVLink data={[headers, ...data]}
                                    filename={"Payments-Report.csv"}
                                    className="btn btn-primary" 
                                    target="_blank">Download</CSVLink>
                            </div> : <span />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    async loadPaymentHistoryBatch() {
        let batchLength = 0;
        let paymentHistoryRef = firebase.database().ref(`paymentHistory/${this.props.user.schoolId}`).orderByChild('timestamp');
        if (this.state.paymentRecordIdsOrdered.length === 0) {
            batchLength = 20;
            paymentHistoryRef = paymentHistoryRef.limitToLast(batchLength);
        } else {
            batchLength = 10;
            let lastId = this.state.paymentRecordIdsOrdered[this.state.paymentRecordIdsOrdered.length - 1];
            let lastTimestamp = this.state.paymentRecords.get(lastId).timestamp - 1;
            paymentHistoryRef = paymentHistoryRef.limitToLast(batchLength).endAt(lastTimestamp);
        }
        let snapshot = await paymentHistoryRef.once('value');
        let newPaymentEntries = new Map<string, PaymentRecord>();
        let remainingPaymentEntries = true;
        snapshot.forEach(function (child) {
            let paymentEntry = PaymentRecord.fromFirebase(child.val());
            newPaymentEntries.set(child.key, PaymentRecord.fromFirebase(paymentEntry));
        });
        if (newPaymentEntries.size < batchLength) {
            remainingPaymentEntries = false;
        }

        let allPaymentRecords = new Map([...this.state.paymentRecords, ...newPaymentEntries]);

        let paymentEntriesOrdered = this.state.paymentRecordIdsOrdered.slice();
        paymentEntriesOrdered.push(...Array.from(newPaymentEntries.keys()));

        paymentEntriesOrdered.sort((id1, id2) => {
            return allPaymentRecords.get(id2).timestamp - allPaymentRecords.get(id1).timestamp;
        });

        this.setState({
            paymentRecordIdsOrdered: paymentEntriesOrdered,
            paymentRecords: allPaymentRecords,
            remainingPaymentEntries: remainingPaymentEntries,
        });
    }

    async componentDidMount(): Promise<void> {
        this.loadPaymentHistoryBatch();
    }
}

export default PaymentHistoryView;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, BreadcrumbItem, Breadcrumb, Alert, Card, CardBody } from 'reactstrap';
import { AccountGroup } from './data/accounts';
import { User } from './data/user';

interface IState {
    accountGroups: Map<string, AccountGroup>;
}

interface IProps {
    user: User;
}

class ChatsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            accountGroups: new Map(),
        };
    }

    render(): JSX.Element {

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All chats</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Chats</div>
                        <p className="cardSubTitle">Account groups can have chats and you can view and manage them here.</p>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Additional</th>
                                    <th># Accounts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(this.state.accountGroups.keys()).map((accountGroupId) => {
                                    let accountGroup = this.state.accountGroups.get(accountGroupId);
                                    return <tr>
                                        <th scope="row"><Link to={`chats/${accountGroupId}`}>{accountGroup.details != null ? accountGroup.details.name : "<Not named>"}</Link></th>
                                        <td>{accountGroup.details != null ? accountGroup.details.additional : ""}</td>
                                        <td>{accountGroup.accounts != null ? accountGroup.accounts.size.toString() : ""}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    async componentDidMount(): Promise<void> {
        const accountGroupsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups`).orderByChild('deactivated').equalTo(null);
        try {
            let snapshot = await accountGroupsRef.once('value');
            let accountGroups = snapshot.val();
            let newState = new Map<string, AccountGroup>();
            for (let nextAccountGroupId in accountGroups) {
                let nextAccountGroup = AccountGroup.fromFirebase(accountGroups[nextAccountGroupId]);
                if (nextAccountGroup.accounts == null) {
                    nextAccountGroup.accounts = new Map();
                }
                newState.set(nextAccountGroupId, nextAccountGroup);
            }
            this.setState({
                accountGroups: newState,
            });
        } catch (error) {
            console.log(error);
        }
    }
}
export default ChatsView;
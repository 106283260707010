import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Card, CustomInput, CardBody
} from 'reactstrap';
import ImageUpload from './ninja_components/image_upload';
import { Link } from "react-router-dom";
import GoogleSuggest from "./google_suggest";
import { NEW_MEMBER_LOGIN_TYPE, OrganisationDetails } from './data/organisation';
import { User, USER_ROLE } from './data/user';
import { AccountGroup } from './data/accounts';
import { createUpdateFragment } from './data/database_object';
import { License } from './data/license';

interface IState {
    organisationDetails: OrganisationDetails,
    currentOrganisationName: string;
    currentOrganisationType: string;
    currentOrganisationImageUrl: string;
    currentOrganisationImagePath: string;
    currentOrganisationSupportEmail: string;
    currentOrganisationPhone: string;
    currentOrganisationPublic: boolean;
    currentEventPlaceId: string;
    currentEventPlaceAddress: string;
    currentCountryCode: string;
    currency: string;
    licenseSelection: string[];
    newOrganisationAccountGroup: string;
    imageUploading: boolean;
    newAccountGroupView: boolean;
    publicIdRequesting: boolean;
    licenses: Map<string, License>;
    licenseIdsOrdered: string[],
    signupDeets: SignupDeets;
    pendingVerification: boolean;
    currentOrganisationNewMemberAccountGroups: Map<string, boolean>;
    currentOrganisationLoginType: NEW_MEMBER_LOGIN_TYPE;
    currentOrganisationPublicId: string;
}

interface IProps {
    user: User;
    snackbar: (text?: string) => void;
    accountGroups: Map<string, AccountGroup>;
}

interface SignupDeets {
    tosConfirmed: boolean;
    referenceSource: string;
    referenceAdditional: string;
    licenseSelection: string[];
}

class OrganisationView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            organisationDetails: null,
            currentOrganisationName: '',
            currentOrganisationType: '',
            currentOrganisationImageUrl: '',
            currentOrganisationImagePath: '',
            currentOrganisationSupportEmail: '',
            currentOrganisationPhone: '',
            currentOrganisationPublic: false,
            currentEventPlaceId: '',
            currentEventPlaceAddress: '',
            currentCountryCode: 'GB',
            licenses: new Map(),
            licenseIdsOrdered: [],
            licenseSelection: [],
            currency: 'GBP',
            newOrganisationAccountGroup: '',
            imageUploading: false,
            newAccountGroupView: false,
            publicIdRequesting: false,
            signupDeets: null,
            pendingVerification: false,
            currentOrganisationNewMemberAccountGroups: new Map(),
            currentOrganisationLoginType: NEW_MEMBER_LOGIN_TYPE.email,
            currentOrganisationPublicId: null,
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.storeOrganisationDetails = this.storeOrganisationDetails.bind(this);
        this.handleImageChangeFail = this.handleImageChangeFail.bind(this);
        this.handleAccountGroupRemove = this.handleAccountGroupRemove.bind(this);
        this.toggleNewAccountGroupModal = this.toggleNewAccountGroupModal.bind(this);
        this.handleOrganisationGroupAdd = this.handleOrganisationGroupAdd.bind(this);
        this.handleAccessUpdate = this.handleAccessUpdate.bind(this);
        this.requestPublicId = this.requestPublicId.bind(this);
        this.handlePlaceUpdate = this.handlePlaceUpdate.bind(this);
        this.handlePendingVerification = this.handlePendingVerification.bind(this);
        this.setcurrency = this.setcurrency.bind(this);
    }

    render(): JSX.Element {

        return (
            <div>
                {
                    (this.state.pendingVerification) ?
                        <div className="container">
                            <Card style={{ backgroundColor: '#ffbe75', padding: '10px' }} ><h3>Pending verification of name change</h3></Card>
                        </div>
                        : ""
                }
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>Organisation details</BreadcrumbItem>
                    </Breadcrumb>
                </div>

                {this.state.organisationDetails != null ?
                    <React.Fragment>
                        <Card className="mainCard">
                            <CardBody className="d-flex flex-column">
                                <div className="cardTitle">Logo</div>
                                <p className="cardSubTitle">Users will see your logo when they select an account</p>
                                <ImageUpload callback={this.handleImageChange} failureCallback={this.handleImageChangeFail} schoolId={this.props.user.schoolId}
                                    currentImage={{ url: this.state.currentOrganisationImageUrl, path: this.state.currentOrganisationImagePath }} filePath={"organisationImages"}
                                    imageLegend={"Organisation logo"} />
                            </CardBody>
                        </Card>
                        <Card className="mainCard top-buffer">
                            <CardBody className="d-flex flex-column">
                                <div className="cardTitle2">Basic details</div>
                                <p className="cardSubTitle">Key details about your organisation that all users will see</p>
                                <Form onSubmit={this.handleUpdate}>
                                    <FormGroup>
                                        <Label for="currentOrganisationName">Name *</Label>
                                        <Input type="text" required name="currentOrganisationName" onChange={(e) => this.setState({
                                            currentOrganisationName: e.target.value
                                        })} value={this.state.currentOrganisationName} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentOrganisationType">Type * (e.g. School, Club)</Label>
                                        <Input type="select" required name="currentOrganisationType" onChange={(e) => this.setState({
                                            currentOrganisationType: e.target.value
                                        })} value={this.state.currentOrganisationType}>
                                            <option>&nbsp;</option>
                                            <option value={"primarySchool"}>Primary School</option>
                                            <option value={"middleSchool"}>Middle School</option>
                                            <option value={"secondarySchool"}>Secondary School</option>
                                            <option value={"educationEstablishment"}>Other education establishment (e.g. College, University, Nursery)</option>
                                            <option value={"drivingSchool"}>Driving School</option>
                                            <option value={"privateTutor"}>Private tutor</option>
                                            <option value={"club"}>Club</option>
                                            <option value={"contentCreator"}>Content creator</option>
                                            <option value={"other"}>Other</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currency">Default currency</Label>
                                        <Input type="select" required name="currency" onChange={this.setcurrency} value={this.state.currency}>
                                            <option value="GBP">GBP</option>
                                            <option value="USD">USD</option>
                                            <option value="ZAR">ZAR</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentOrganisationSupportEmail">Support email *</Label>
                                        <Input type="email" required name="currentOrganisationSupportEmail" onChange={(e) => this.setState({
                                            currentOrganisationSupportEmail: e.target.value
                                        })} value={this.state.currentOrganisationSupportEmail} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentOrganisationPhone">Organisation phone number *</Label>
                                        <Input type="tel" required name="currentOrganisationPhone" onChange={(e) => this.setState({
                                            currentOrganisationPhone: e.target.value
                                        })} value={this.state.currentOrganisationPhone} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Organisation address *&nbsp;&nbsp;<img alt={""} src={'/images/powered_by_google.png'} height={16} /></Label>
                                        <GoogleSuggest updatePlace={this.handlePlaceUpdate} initialValue={this.state.currentEventPlaceAddress} />
                                    </FormGroup>
                                    <Button className="adminPagesButton">Update</Button>
                                </Form>
                            </CardBody></Card>
                        <Card className="mainCard top-buffer">
                            <CardBody className="d-flex flex-column">
                                <div className="cardTitle2">Access</div>
                                <p className="cardSubTitle">Specify whether your organisation is private or public. <i className="fas fa-info-circle icons-info" id="help-org-publicprivate" /></p>
                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-org-publicprivate">
                                    Some private organisations may choose to use the public id feature in certain circumstances like allowing access to community events (e.g. school fun day)
                                </UncontrolledTooltip>
                                <Form onSubmit={this.handleAccessUpdate}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" name='currentOrganisationPublic'
                                                checked={this.state.currentOrganisationPublic}
                                                onChange={(e) => this.setState({
                                                    currentOrganisationPublic: e.target.checked
                                                })} />
                                        Can the public join your organisation via a public id?
                                    </Label>
                                    </FormGroup><br />
                                    {this.state.currentOrganisationPublic ?
                                        <div>
                                            <FormGroup>
                                                <Label for="currentOrganisationPublicId">Public id (People find your organisation with this id) - Letters, numbers, and underscores <i className="fas fa-info-circle icons-info" id="help-org-publicid" /></Label>
                                                <Input type="text" placeholder={"e.g. Bobs_pizza_restaurant"} required name="currentOrganisationPublicId" minlength={8} pattern={"[A-Za-z0-9_]+"}
                                                    onChange={(e) => this.setState({
                                                        currentOrganisationPublicId: e.target.value
                                                    })} value={this.state.currentOrganisationPublicId} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-org-publicid">
                                                    New users will type this identifier when they choose to join your organisation. Try to make it unique enough that a) you won't have accidental joiners and b) you won't impact another organisation
                                            </UncontrolledTooltip>
                                            </FormGroup>
                                            <Label>Automatically assigned account groups for public users <i className="fas fa-info-circle icons-info" id="help-org-automaticgroups" /></Label><br />
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-org-automaticgroups">
                                                When a user joins your organisation they can be automatically placed into account groups. This will give them access to events, bookings and news associated with those account groups.
                                        </UncontrolledTooltip>
                                            &nbsp;&nbsp;<Button key={'addAccountGroupButton2'} className="adminPagesButton buttonBuffer" onClick={() => {
                                                this.toggleNewAccountGroupModal();
                                            }}>Add account group</Button>
                                            {this.state.currentOrganisationNewMemberAccountGroups.size > 0 ?
                                                <Table>
                                                    <tbody>
                                                        {Array.from(this.state.currentOrganisationNewMemberAccountGroups.keys()).map((accountGroupId) => {
                                                            console.log("Drawing", accountGroupId);
                                                            let accountGroup = this.props.accountGroups.get(accountGroupId);
                                                            let name;
                                                            if (accountGroup == null) {
                                                                name = "Deactivated account group";
                                                            } else {
                                                                name = accountGroup.details.name;
                                                            }
                                                            return <tr key={accountGroupId + 'tr'}>
                                                                <th scope="row" key={accountGroupId + 'th'}><Link to={`/accountGroups/${accountGroupId}`}>{name}</Link></th>
                                                                <td key={accountGroupId + 'td5'}>
                                                                    <Button key={accountGroupId + 'button2'} color="link" onClick={() => {
                                                                        this.handleAccountGroupRemove(accountGroupId);
                                                                    }}><i className="material-icons">delete</i></Button>
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </Table> : <span />
                                            }

                                            <FormGroup>
                                                <Label for="licenseSelection">Will they automatically get a license?</Label>
                                                {this.state.licenseIdsOrdered.map((licenseId) => {
                                                    let license = this.state.licenses.get(licenseId);
                                                    return <CustomInput id={`licenseSelection-${licenseId}`} type="switch" checked={this.state.licenseSelection.indexOf(licenseId) !== -1} name={`licenseSelection-${licenseId}`} label={<a href={license.weblink} target="_blank">{license.name}</a>} onChange={(val) => {
                                                        if (val.target.checked) {
                                                            if (this.state.licenseSelection.indexOf(licenseId) == -1) {
                                                                console.log("Added", licenseId);
                                                                this.state.licenseSelection.push(licenseId);
                                                                console.log(this.state.licenseSelection);
                                                            }
                                                        } else {
                                                            if (this.state.licenseSelection.indexOf(licenseId) != -1) {
                                                                console.log("REmoved", licenseId);
                                                                this.state.licenseSelection.splice(this.state.licenseSelection.indexOf(licenseId), 1);
                                                            }
                                                        }
                                                        this.setState({});
                                                    }} />
                                                })}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentOrganisationLoginType">What type of login will be created for them?</Label>
                                                <Input type="select" name="currentOrganisationLoginType" value={this.state.currentOrganisationLoginType}
                                                    onChange={(e) => {
                                                        let loginType = NEW_MEMBER_LOGIN_TYPE[e.target.value as keyof typeof NEW_MEMBER_LOGIN_TYPE];
                                                        this.setState({
                                                            currentOrganisationLoginType: loginType
                                                        })
                                                    }}>
                                                    <option value={NEW_MEMBER_LOGIN_TYPE.email}>Email</option>
                                                    <option value={NEW_MEMBER_LOGIN_TYPE.username}>Generated username</option>
                                                    <option value={NEW_MEMBER_LOGIN_TYPE.both}>Email &amp; generated username</option>
                                                </Input>
                                            </FormGroup>
                                        </div> : <span />
                                    }
                                    <Button className="adminPagesButton" disabled={this.state.publicIdRequesting}>Update</Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </React.Fragment>
                    : ""
                }
                {this.props.user.role !== USER_ROLE.admin ? <span /> :
                    <div><br /><br />
                        <b>Super admin - sign-up details</b><br />
                        Org id: {this.props.user.schoolId}<br />
                        {this.state.signupDeets == null ? <span /> :
                            <span>
                                ToS confirmed: {this.state.signupDeets.tosConfirmed != null ? this.state.signupDeets.tosConfirmed.toString() : "false"}<br />
                                Reference: {this.state.signupDeets.referenceSource}<br />
                                Reference additional: {this.state.signupDeets.referenceAdditional}<br />
                                Licenses: {this.state.signupDeets.licenseSelection == null ? "None" : this.state.signupDeets.licenseSelection.join(", ")}
                            </span>
                        }
                    </div>
                }
                <br /><br /><br />
                <Modal isOpen={this.state.newAccountGroupView} toggle={this.toggleNewAccountGroupModal}>
                    <ModalHeader toggle={this.toggleNewAccountGroupModal}>Assign account groups</ModalHeader>
                    <ModalBody>
                        <div className="border rounded form-margin">
                            <FormGroup>
                                <Label for="newOrganisationAccountGroup">Account Groups</Label>
                                <Input type="select" name="newOrganisationAccountGroup"
                                    onChange={(e) => this.setState({
                                        newOrganisationAccountGroup: e.target.value
                                    })}>
                                    <option>&nbsp;</option>
                                    {Array.from(this.props.accountGroups.keys()).map((key) => {
                                        return (
                                            <option value={key}
                                                key={key}>{this.props.accountGroups.get(key).details.name}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup>
                            <Button color="success" disabled={this.state.newOrganisationAccountGroup == null || this.state.newOrganisationAccountGroup === ""}
                                onClick={this.handleOrganisationGroupAdd}>Assign account group</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    setcurrency(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            currency: e.target.value,
        });
    }

    handlePendingVerification(): void {
        this.setState({
            pendingVerification: true
        });
        try {
            firebase.functions().httpsCallable('pendingVerification');
        } catch (error) {
            console.log(error);
        };
    }

    async handleImageChange(url: string, path: string): Promise<void> {
        let imageDeets = {
            imageUrl: url,
            imagePath: path,
        };
        const orgRef = firebase.database().ref(`organisationDetails/${this.props.user.schoolId}`);
        try {
            await orgRef.update(imageDeets);
            this.setState({
                currentOrganisationImageUrl: url,
                currentOrganisationImagePath: path,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
    }

    handlePlaceUpdate(place: google.maps.GeocoderResult): void {
        console.log("PLacee", place);
        // Get the country
        let country = "GB";
        for (let addressComponent of place.address_components) {
            let typeFound = false;
            for (let type of addressComponent.types) {
                if (type === "country") {
                    typeFound = true;
                    break;
                }
            }
            if (typeFound) {
                country = addressComponent.short_name;
                break;
            }
        }
        this.setState({
            currentEventPlaceId: place.place_id,
            currentEventPlaceAddress: place.formatted_address,
            currentCountryCode: country,
        });
    }

    handleOrganisationGroupAdd(): void {
        console.log("Adding group", this.state.newOrganisationAccountGroup);
        this.state.currentOrganisationNewMemberAccountGroups.set(this.state.newOrganisationAccountGroup, true);
        this.setState({});
        console.log("New state", this.state.currentOrganisationNewMemberAccountGroups);
    }

    toggleNewAccountGroupModal(): void {
        this.setState({
            newAccountGroupView: !this.state.newAccountGroupView,
        })
    }

    handleAccountGroupRemove(accountGroupId: string): void {
        this.state.currentOrganisationNewMemberAccountGroups.delete(accountGroupId);
        this.setState({});
    }

    handleImageChangeFail(): void {
        this.props.snackbar("Image upload failed");
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let organisationDetails = new OrganisationDetails();
        organisationDetails.name = this.state.currentOrganisationName;
        organisationDetails.type = this.state.currentOrganisationType;
        organisationDetails.supportEmail = this.state.currentOrganisationSupportEmail;
        organisationDetails.placeId = this.state.currentEventPlaceId;
        organisationDetails.placeAddress = this.state.currentEventPlaceAddress;
        organisationDetails.phone = this.state.currentOrganisationPhone;
        organisationDetails.currency = this.state.currency;
        organisationDetails.countryCode = this.state.currentCountryCode;

        if (this.state.organisationDetails.name != this.state.currentOrganisationName) {
            organisationDetails.pendingVerification = true;

        };
        await this.storeOrganisationDetails(organisationDetails, ["name", "type", "supportEmail", "placeId", "placeAddress", "phone", "currency", "countryCode", "pendingVerification"]);
        this.setState({
            pendingVerification: true
        });
    }

    async handleAccessUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let success = await this.requestPublicId();
        if (!success) {
            this.props.snackbar("Public id taken");
        } else {
            let organisationDetails = new OrganisationDetails();
            organisationDetails.public = this.state.currentOrganisationPublic;
            organisationDetails.publicId = this.state.currentOrganisationPublicId;
            organisationDetails.newMemberAccountGroups = this.state.currentOrganisationNewMemberAccountGroups;
            let newMemberLicenses = new Map<string, boolean>();
            for (let nextLicenseId of this.state.licenseSelection) {
                newMemberLicenses.set(nextLicenseId, true);
            }
            organisationDetails.newMemberLicenses = newMemberLicenses;
            organisationDetails.newMemberLoginType = this.state.currentOrganisationLoginType;

            await this.storeOrganisationDetails(organisationDetails, ["public", "publicId", "newMemberAccountGroups", "newMemberLicenses", "newMemberLoginType"]);
        }
    }

    async storeOrganisationDetails(organisationDetails: OrganisationDetails, updatedFields: string[]): Promise<void> {
        const paymentRef = firebase.database().ref(`organisationDetails/${this.props.user.schoolId}`);
        try {
            await paymentRef.update(createUpdateFragment(organisationDetails.toFirebase(), updatedFields));
            this.setState({
                organisationDetails: organisationDetails,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
    }

    async requestPublicId(): Promise<boolean> {
        this.setState({
            publicIdRequesting: true,
        });
        let idToken = await firebase.auth().currentUser.getIdToken();
        let authorization = 'Bearer ' + idToken;
        let response = await fetch('https://admin.lifeninja.net/adminRequests/requestOrganisationPublicId', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            },
            body: JSON.stringify({
                schoolId: this.props.user.schoolId,
                publicId: this.state.currentOrganisationPublicId,
            })
        });
        console.log(response);
        this.setState({
            publicIdRequesting: false,
        });
        return response.status === 200;
    }

    async componentDidMount(): Promise<void> {
        const detailsRef = firebase.database().ref(`organisationDetails/${this.props.user.schoolId}`);
        try {
            let snapshot = await detailsRef.once('value')
            let organisationDetailsData = snapshot.val();
            let organisationDetails = OrganisationDetails.fromFirebase(organisationDetailsData);
            if (organisationDetails.name == null) {
                organisationDetails.name = "";
            }
            let licensesSnapshot = await firebase.firestore().doc('generalConfig/licenseDetails').get();
            let licenseDetails = licensesSnapshot.data();
            let newLicensesState = new Map<string, License>();
            for (let nextLicenseId in licenseDetails.licenses) {
                let nextLicense = licenseDetails.licenses[nextLicenseId];
                newLicensesState.set(nextLicenseId, License.fromFirebase(nextLicense));
            }

            let newLicenseIdsOrdered = Array.from(newLicensesState.keys()).sort((a, b) => {
                return newLicensesState.get(a).name.localeCompare(newLicensesState.get(b).name);
            });

            let licensesSelected = new Array<string>();
            if (organisationDetails.newMemberLicenses != null) {
                licensesSelected = Array.from(organisationDetails.newMemberLicenses.keys());
            }

            this.setState({
                organisationDetails: organisationDetails,
                currentOrganisationName: organisationDetails.name,
                currentOrganisationType: organisationDetails.type,
                currentOrganisationImageUrl: organisationDetails.imageUrl,
                currentOrganisationImagePath: organisationDetails.imagePath,
                currentOrganisationSupportEmail: organisationDetails.supportEmail,
                currentOrganisationPublic: organisationDetails.public,
                currentOrganisationPublicId: organisationDetails.publicId,
                currentOrganisationPhone: organisationDetails.phone,
                pendingVerification: organisationDetails.pendingVerification,
                currency: organisationDetails.currency == null ? "GBP" : organisationDetails.currency,
                currentEventPlaceId: organisationDetails.placeId != null ? organisationDetails.placeId : '',
                currentEventPlaceAddress: organisationDetails.placeAddress != null ? organisationDetails.placeAddress : '',
                currentOrganisationNewMemberAccountGroups: organisationDetails.newMemberAccountGroups == null ? new Map() : organisationDetails.newMemberAccountGroups,
                currentOrganisationLoginType: organisationDetails.newMemberLoginType == null ? NEW_MEMBER_LOGIN_TYPE.email : organisationDetails.newMemberLoginType,
                licenseSelection: licensesSelected,
                licenses: newLicensesState,
                licenseIdsOrdered: newLicenseIdsOrdered,
            });
        } catch (error) {
            console.log(error);
        }

        if (this.props.user.role === USER_ROLE.admin) {
            const signupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/signup`);
            let signupSnapshot = await signupRef.once('value');
            this.setState({
                signupDeets: signupSnapshot.val(),
            })
        }
    }
}

export default OrganisationView;
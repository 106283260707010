//@ts-check

import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Spinner, Table, Modal, ModalHeader, ModalBody, Breadcrumb, BreadcrumbItem, Card, CardBody
} from 'reactstrap';
import { Link } from "react-router-dom";
import { User } from './data/user';
import { RouteComponentProps  } from "react-router";
import { Conversation, Ticket } from './data/support_tickets';

interface IState {
    createTicketReplyModal: boolean;
    newTicketReply: string;
    conversations: Array<Conversation>,
    ticket: Ticket,
    loading: boolean;
    closing: boolean;
    replying: boolean;
}

interface MatchParams {
    ticketId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}


class TicketView extends Component<IProps, IState> {
    ticketId: string;

    constructor(props: IProps) {
        super(props);
        this.state = {
            createTicketReplyModal: false,
            newTicketReply: '',
            conversations: [],
            ticket: null,
            loading: true,
            closing: false,
            replying: false,
        };

        this.ticketId = props.match.params.ticketId;

        this.toggleCreateTicketReplyModal = this.toggleCreateTicketReplyModal.bind(this);
        this.handleCreateTicketReply = this.handleCreateTicketReply.bind(this);
        this.handleCloseTicket = this.handleCloseTicket.bind(this);
    }

    render(): JSX.Element {
        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={`/support`}>All tickets</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.state.ticket == null ? "" : this.state.ticket.subject}</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                <div className="cardTitle">{this.state.loading || this.state.ticket == null ? "" : `${this.state.ticket.type === "Incident" ? "Issue" : this.state.ticket.type}: ${this.state.ticket.subject}`}</div>
                <p>{this.state.loading || this.state.ticket == null ? "" : <div dangerouslySetInnerHTML={{__html: this.state.ticket.description}}/>}</p>
            <Table>
                <thead>
                    <tr>
                    <th>Date</th>
                        <th></th>
                        <th>Reply</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.conversations.map((conversation) => {
                        let conversationId = conversation.id;
                        let updated = new Date(conversation.updated_at);
                        return <tr key={conversationId + 'tr'}>
                            <th scope="row" key={conversationId + 'th'}>{updated.toDateString()} {updated.toLocaleTimeString()}</th>
                            <td>{conversation.incoming ? this.props.user.schoolAvatarUrl == null || this.props.user.schoolAvatarUrl.length === 0 ? <span/> : <img src={this.props.user.schoolAvatarUrl} width="15" height="15"/> : <img src={'/images/LNN-p.png'} width="15" height="15"/>}</td>
                            <td key={conversationId + 'td1'}><div dangerouslySetInnerHTML={{__html: conversation.body}}/></td>
                            <td key={conversationId + 'td5'}>
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
            {this.state.loading || this.state.ticket == null || this.state.ticket.status != 2 ? <span /> :
                <Button disabled={this.state.replying} onClick={this.toggleCreateTicketReplyModal}>
                    Add reply {this.state.replying ? <Spinner size="sm"></Spinner> : <span />}
                </Button>
            }
            <br /><br />
            {this.state.loading || this.state.ticket == null || this.state.ticket.status != 2 ? <span /> :
                <Button disabled={this.state.closing} onClick={this.handleCloseTicket}>
                    Close support ticket {this.state.closing ? <Spinner size="sm"></Spinner> : <span />}
                </Button>
            }
            </CardBody>
            </Card>
            {this.state.loading ? <Spinner size="l"></Spinner> : <span />}
            <Modal isOpen={this.state.createTicketReplyModal} toggle={this.toggleCreateTicketReplyModal}>
                <ModalHeader toggle={this.toggleCreateTicketReplyModal}>Create reply</ModalHeader>
                <ModalBody>
                    <div className="border rounded form-margin">
                        <Form onSubmit={this.handleCreateTicketReply}>
                            <FormGroup>
                                <Label for="newTicketReply">Reply *</Label>
                                <Input type="textarea" required name="newTicketReply"
                                    onChange={(e) => this.setState({
                                        newTicketReply: e.target.value
                                    })}
                                    value={this.state.newTicketReply} />
                            </FormGroup>
                            <br />
                            <Button color="success">
                                Send
                            </Button>&nbsp;
                        </Form>
                    </div>
                </ModalBody>
            </Modal>
        </div>;
    }

    toggleCreateTicketReplyModal(): void {
        this.setState({
            createTicketReplyModal: !this.state.createTicketReplyModal,
        });
    }

    async handleCloseTicket(): Promise<void> {

        this.setState({
            closing: true,
        });

        try {
            let closeTicket = firebase.functions().httpsCallable('closeSupportTicket');
            await closeTicket({
                "schoolId": this.props.user.schoolId,
                "ticketId": this.ticketId,
            });

            this.props.history.goBack();

            this.props.snackbar();
        } catch (error) {
            this.setState({
                closing: false,
            });
            console.log("Ticket close failed", error);
            this.props.snackbar("Close ticket failed");
        }
    }

    async handleCreateTicketReply(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh

        this.setState({
            replying: true,
            createTicketReplyModal: false,
        });

        try {
            let addSupportTicketReply = firebase.functions().httpsCallable('addSupportTicketReply');
            let newTicketResponse = await addSupportTicketReply({
                "schoolId": this.props.user.schoolId,
                "ticketId": this.ticketId,
                "reply": this.state.newTicketReply,
            });

            let newTicket = newTicketResponse.data;
            this.state.conversations.push(newTicket);

            this.setState({
                replying: false,
            });

            this.props.snackbar();
        } catch (error) {
            this.setState({
                createTicketReplyModal: false,
                replying: false,
            });
            console.log("Ticket creation failed", error);
            this.props.snackbar("Ticket creation failed");
        }
    }

    async componentDidMount(): Promise<void> {
        try {
            let getTicket = firebase.functions().httpsCallable('getTicketData');
            let newTicketResponse = await getTicket({ "schoolId": this.props.user.schoolId, "ticketId": this.ticketId });
            let newTicketData = newTicketResponse.data;
            let newTicket = Ticket.fromFirebase(newTicketData['ticket']);
            let newConversations = new Array<Conversation>();
            if (newTicketData['conversations'] != null) {
                for (let nextConversation of newTicketData['conversations']) {
                    newConversations.push(Conversation.fromFirebase(nextConversation));
                }
            }

            this.setState({
                conversations: newConversations,
                ticket: newTicket,
                loading: false,
            });
        } catch (error) {
            console.log(error);
            this.props.snackbar("Failed to get support tickets");
        }
    }
}

export default TicketView;
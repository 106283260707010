import { objectToClassConverter } from "./database_object";

export class LibraryImage {
    name: string;
    path: string;
    url: string;
    deleted: boolean;
    _lowerCaseName: string;

    getLowerCaseName(): string {
        if (this._lowerCaseName != null) {
            return this._lowerCaseName;
        } else {
            this._lowerCaseName = this.name.toLowerCase();
            return this._lowerCaseName;
        }
    }

    static fromFirebase(data: any): LibraryImage {
        let newLibraryImage = new LibraryImage();
        if (data == null) {
            return newLibraryImage;
        }
        objectToClassConverter(data, newLibraryImage);

        return newLibraryImage;
    }
}
//@ts-check

import React, { Component } from 'react';
import firebase from './firebase';
import queryString from 'query-string';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, UncontrolledTooltip, InputGroup, InputGroupAddon, Card, CardBody
} from 'reactstrap';
import { Link } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import TopicsSelector from "./ninja_components/topics_selector";
import { StoreLearningGame } from "./helpers/topic_services";
import { CircleGame, CircleRound, TIME_MULTIPLIER } from './data/learning_games';
import { User, USER_ROLE } from './data/user';
import { v4 as uuidv4 } from 'uuid';
import { soundNames, subjectIds } from './Prettifier';

interface IState {
    worldId: string;
    worldName: string;
    subjectFilter: string;
    pathId: string;
    pathName: string;
    gameId: string;
    circle: CircleGame,
    timeMultiplier: TIME_MULTIPLIER;
    currentCircleTitle: string;
    currentCircleInstructions: string;
    currentCircleAnswerTemplate: string;
    currentCircleSound: string;
    currentSubjectId: string;
    currentTopics: string[],
    currentTopicsTags: Map<string, string[]>,
    currentCircleSchoolId: string;
    currentCircleAnswersOrdered: boolean;
    currentCircleRounds: CircleRound[],
    currentCircleIncorrect: string[],
    user: User;
}

interface MatchParams {
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class LearningCircleView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            gameId: props.match.params.gameId,
            circle: null,
            timeMultiplier: TIME_MULTIPLIER.moderate,
            currentCircleTitle: '',
            currentCircleInstructions: '',
            currentCircleAnswerTemplate: '',
            currentCircleSound: '',
            currentSubjectId: '',
            currentTopics: [],
            currentTopicsTags: new Map<string, string[]>(),
            currentCircleSchoolId: '',
            currentCircleAnswersOrdered: false,
            currentCircleRounds: [],
            currentCircleIncorrect: ["0"],
            user: props.user,
        };

        this.dealWithChange = this.dealWithChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleRemoveIncorrect = this.handleRemoveIncorrect.bind(this);
        this.addNumber = this.addNumber.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.removeRound = this.removeRound.bind(this);
        this.handleRoundTargetChange = this.handleRoundTargetChange.bind(this);
        this.removeNumber = this.removeNumber.bind(this);
        this.addCorrectNumber = this.addCorrectNumber.bind(this);
        this.addRound = this.addRound.bind(this);
        this.handleRoundNumberChange = this.handleRoundNumberChange.bind(this);
        this.handleIncorrectNumberChange = this.handleIncorrectNumberChange.bind(this);
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.currentCircleSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All minigames</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem> : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem> : null}
                        <BreadcrumbItem active>Number Circle</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Number circle</div>
                        <p className="cardSubTitle">A number circle requires users to tap numbers to make a sum.
                        A surrounding circle slowly reduces in size as a timer.</p>
                        {
                            (this.state.circle != null) ?
                                <div>
                                    <div>
                                        <a href={`https://zapdemo.web.app/#/circle?${this.getGameQuerys()}`} target="_blank"><Button type="button" className="adminPagesButton">Play now</Button></a>
                                    </div><br />
                                    <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                        <fieldset disabled={!editable}>
                                            <FormGroup>
                                                <Label for="currentCircleTitle">Circle title *</Label>
                                                <Input maxLength={30} type="text" required name="currentCircleTitle" onChange={(e) => this.setState({
                                                    currentCircleTitle: e.target.value
                                                })} value={this.state.currentCircleTitle} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentSubjectId">Subject *</Label>
                                                <Input disabled={this.state.worldId != null || this.state.gameId !== "-1"} type="select" name="currentSubjectId"
                                                    onChange={(e) => this.setState({
                                                        currentSubjectId: e.target.value
                                                    })}
                                                    value={this.state.currentSubjectId}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                                        let subjectName = subjectIds.get(subjectId);
                                                        return <option value={subjectId}>{subjectName}</option>;
                                                    })}
                                                </Input>
                                            </FormGroup>
                                            {this.state.currentSubjectId === '' ? <div></div> :
                                                <TopicsSelector subject={this.state.currentSubjectId} countryCode={this.state.user.countryCode} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags} callback={this.handleTopicsChange} />
                                            }

                                            <label>
                                                Game speed <i className="fas fa-info-circle icons-info" id="help-learning-timemultiplier" />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learning-timemultiplier">
                                                    Control how fast or slow the game will play.
                                                    Overall paths can have speed controls and the game speed will work within the context of the path speed control.
                                                    For example, a game with a 'fast' speed on a path with a 'slow' speed will play at 'moderate' speed.
                                                    Use this to allow developing or advanced students to play at a speed that suits them.
                                        </UncontrolledTooltip>
                                            </label>
                                            <Input type="select" value={this.state.timeMultiplier} onChange={this.dealWithChange}>
                                                <option value="fastest">Fastest</option>
                                                <option value="fast">Fast</option>
                                                <option value="moderate">Moderate</option>
                                                <option value="slow">Slow</option>
                                                <option value="slowest">Slowest</option>
                                            </Input>

                                            <FormGroup>
                                                <h4>Circle rounds <i className="fas fa-info-circle icons-info" id="help-learningcircle-rounds" /></h4>
                                                {this.state.currentCircleRounds.map((nextCirleRound, roundIndex) => {
                                                    let allowDelete = false;
                                                    if (nextCirleRound.correct.length > 1) {
                                                        allowDelete = true;
                                                    }
                                                    return <div className="border rounded form-margin">
                                                        <div><b>Round {roundIndex + 1}</b> {roundIndex > 0 ? <Button type="button" color="link" onClick={() => this.removeRound(roundIndex)}><i
                                                            className="material-icons material-icons-xd">cancel</i></Button> : <span />}</div>
                                                        <div>Centre text</div>
                                                        <Input required name={`currentCircleRounds-${roundIndex}`}
                                                            onChange={(value) => this.handleRoundTargetChange(value, roundIndex)}
                                                            value={nextCirleRound.target} />
                                                            
                                                        <div>Correct buttons</div>
                                                        {this.state.currentCircleRounds[roundIndex].correct.map((nextCorrectAnswer, index) =>
                                                            <InputGroup>
                                                                <Input required name={`currentCircleRounds-correct-${roundIndex}-${index}`}
                                                                    onChange={(value) => this.handleRoundNumberChange(value, roundIndex, index)}
                                                                    value={nextCorrectAnswer} />
                                                                {allowDelete ? <InputGroupAddon addonType={"prepend"}>
                                                                    <Button type="button" outline onClick={() => this.removeNumber(roundIndex, index)}><i
                                                                        className="material-icons material-icons-xd">cancel</i></Button>
                                                                </InputGroupAddon> : null
                                                                }
                                                            </InputGroup>
                                                        )}
                                                        <Button outline className="altButton" onClick={() => this.addCorrectNumber(roundIndex)}>Additional correct button</Button>
                                                    </div>
                                                })}
                                                <Button outline className="altButton" onClick={() => this.addRound()}>Additional round</Button>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningcircle-rounds">
                                                    Enter the buttons that will appear around the circle and should be selected when the center text is shown.
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label>Incorrect buttons <i className="fas fa-info-circle icons-info" id="help-learningcircle-incorrect" /></Label>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningcircle-incorrect">
                                                    Enter the additional buttons that should not be selected.
                                        </UncontrolledTooltip>
                                                {this.state.currentCircleIncorrect.map((answer, index) =>
                                                    <InputGroup>
                                                        <Input required name={`currentCircleIncorrect-${index}`} onChange={(value) => this.handleIncorrectNumberChange(value, index)} value={this.state.currentCircleIncorrect[index]} />
                                                        <InputGroupAddon addonType={"prepend"}>
                                                            <Button type="button" outline onClick={() => this.handleRemoveIncorrect(index)}><i
                                                                className="material-icons material-icons-xd">cancel</i></Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                )}
                                                <Button className="altButton" onClick={this.addNumber}>Additional incorrect button</Button>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentCircleAnswerTemplate">Answer template <i className="fas fa-info-circle icons-info" id="help-learningcircle-answertemplate" /></Label>
                                                <Input type="text" name="currentCircleAnswerTemplate" onChange={(e) => this.setState({
                                                    currentCircleAnswerTemplate: e.target.value
                                                })} value={this.state.currentCircleAnswerTemplate} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningcircle-answertemplate">
                                                    A number sentence or similar can appear at the bottom of the circle game and be filled in as answers are selected.<br />
                                            Use keywords ::selected1:: up to ::selected9:: for the buttons selected by the user.<br />
                                            Use keyword ::target:: to show the centre number<br />
                                            E.g. a standard template would be<br />
                                            ::selected1:: + ::selected2:: = ::target::
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" name="currentCircleAnswersOrdered"
                                                        onChange={(e) => this.setState({
                                                            currentCircleAnswersOrdered: e.target.checked
                                                        })} checked={this.state.currentCircleAnswersOrdered} />
                                            Answers must follow ordering? <i className="fas fa-info-circle icons-info" id="help-learningcircle-answersordered" />
                                                </Label>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningcircle-answersordered">
                                                    Sometimes the answers must be ordered (e.g. for subtraction) and sometimes not (e.g. for addition)
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentCircleInstructions">Instructions <i className="fas fa-info-circle icons-info" id="help-learningcircle-instructions" /></Label>
                                                <Input type="textarea" name="currentCircleInstructions" onChange={(e) => this.setState({
                                                    currentCircleInstructions: e.target.value
                                                })} value={this.state.currentCircleInstructions} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningcircle-instructions">
                                                    The instructions shown to the player before they start the circle. Advise on the types of sums to complete rather than the how to play the game.
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentCircleSound">Audio instruction *</Label>
                                                <Input type="select" name="currentCircleSound"
                                                    onChange={(e) => this.setState({
                                                        currentCircleSound: e.target.value
                                                    })}
                                                    value={this.state.currentCircleSound}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(soundNames.keys()).map((key) => {
                                                        let name = soundNames.get(key);
                                                        return (
                                                            <option value={key} key={key}>{name}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                            </FormGroup>

                                            {editable ? this.state.gameId === "-1" ?
                                                <Button disabled={this.state.currentSubjectId === ''} className="adminPagesButton">Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                            }
                                        </fieldset>
                                    </Form>
                                </div> : <div />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    getGameQuerys(): string {
        return queryString.stringify({
            instructions: this.state.currentCircleInstructions,
            answerTemplate: this.state.currentCircleAnswerTemplate,
            circleRounds: JSON.stringify(this.state.currentCircleRounds),
            answersOrdered: this.state.currentCircleAnswersOrdered,
            timeMultiplier: this.state.timeMultiplier,
            incorrect: JSON.stringify(this.state.currentCircleIncorrect),
        });
    }

    removeRound(roundIndex: number): void {
        this.state.currentCircleRounds.splice(roundIndex, 1);
        this.setState({});
    }

    handleRoundTargetChange(e: React.ChangeEvent<HTMLInputElement>, roundIndex: number) {
        this.state.currentCircleRounds[roundIndex].target = e.target.value;
        this.setState({});
    }

    handleRoundNumberChange(e: React.ChangeEvent<HTMLInputElement>, roundIndex: number, index: number) {
        this.state.currentCircleRounds[roundIndex].correct[index] = e.target.value;
        this.setState({
        });
    }

    handleIncorrectNumberChange(e: React.ChangeEvent<HTMLInputElement>, index: number): void {
        this.state.currentCircleIncorrect[index] = e.target.value;
        this.setState({
        });
    }

    removeNumber(roundIndex: number, index: number): void {
        this.state.currentCircleRounds[roundIndex].correct.splice(index, 1);
        this.setState({
        });
    }

    addRound(): void {
        let initialCircleRound = new CircleRound();
        initialCircleRound.correct = ["0"];
        initialCircleRound.target = "0";

        this.state.currentCircleRounds.push(initialCircleRound);
        this.setState({
        });
    }

    addCorrectNumber(roundIndex: number): void {
        this.state.currentCircleRounds[roundIndex].correct.push("0");
        this.setState({
        });
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId
        });
    }

    dealWithChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    handleRemoveIncorrect(index: number): void {
        this.state.currentCircleIncorrect.splice(index, 1);
        this.setState({
        });
    }

    addNumber(): void {
        this.state.currentCircleIncorrect.push("0");
        this.setState({
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let circle = this.state.circle;
        circle.title = this.state.currentCircleTitle;
        circle.instructions = this.state.currentCircleInstructions;
        circle.answerTemplate = this.state.currentCircleAnswerTemplate;
        circle.rounds = this.state.currentCircleRounds;
        circle.incorrect = this.state.currentCircleIncorrect;
        circle.subjectId = this.state.currentSubjectId;
        circle.topics = this.state.currentTopics;
        circle.topicTags = this.state.currentTopicsTags;
        circle.sound = this.state.currentCircleSound;
        circle.answersOrdered = this.state.currentCircleAnswersOrdered;
        circle.timeMultiplier = this.state.timeMultiplier;
        circle.deleted = null;
        circle.type = 'CIRCLE';
        let newCircle = false;
        let gameId = this.state.gameId;
        if (this.state.gameId === "-1") {
            newCircle = true;
            gameId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                circle.schoolId = this.state.user.schoolId;
            }
        }
        try {
            await StoreLearningGame(newCircle, circle, gameId, this.state.pathId, this.state.currentCircleSchoolId);

            this.props.history.replace(`/learningCircles/${gameId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
        this.setState({
            gameId: gameId
        })
    }

    async componentDidMount(): Promise<void> {
        try {

            let initialCircleRound = new CircleRound();
            initialCircleRound.correct = ["0"];
            initialCircleRound.target = "0";
            if (this.state.gameId !== "-1") {
                let circleSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
                let circleData = circleSnapshot.data();
                let circle = CircleGame.fromFirebase(circleData);
                this.setState({
                    circle: circle,
                    currentCircleTitle: circle.title,
                    currentCircleInstructions: circle.instructions,
                    currentCircleSchoolId: circle.schoolId == null ? null : circle.schoolId,
                    currentCircleRounds: circle.rounds == null ? [initialCircleRound] : circle.rounds,
                    currentCircleIncorrect: circle.incorrect == null ? ["0"] : circle.incorrect,
                    currentCircleSound: circle.sound == null ? '' : circle.sound,
                    currentCircleAnswersOrdered: circle.answersOrdered == null ? false : circle.answersOrdered,
                    currentSubjectId: circle.subjectId,
                    currentTopics: circle.topics == null ? [] : circle.topics,
                    currentTopicsTags: circle.topicTags == null ? new Map<string, string[]>() : circle.topicTags,
                    timeMultiplier: circle.timeMultiplier != null ? circle.timeMultiplier : TIME_MULTIPLIER.moderate,
                    currentCircleAnswerTemplate: circle.answerTemplate == null ? '' : circle.answerTemplate,
                });
            } else {
                let subjectId = '';
                if (this.state.worldId != null) {
                    const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                    let worldSnapshot = await worldRef.get();
                    let world = worldSnapshot.data();
                    subjectId = world.subject;
                } else {
                    //@ts-ignore
                    subjectId = this.state.subjectFilter;
                }
                this.setState({
                    circle: new CircleGame(),
                    currentCircleTitle: '',
                    currentCircleInstructions: '',
                    currentCircleAnswerTemplate: '',
                    currentCircleSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                    currentCircleAnswersOrdered: false,
                    currentCircleRounds: [initialCircleRound],
                    currentCircleIncorrect: ["0"],
                    currentCircleSound: '',
                    currentSubjectId: subjectId,
                    currentTopics: [],
                    currentTopicsTags: new Map<string, string[]>(),
                    timeMultiplier: TIME_MULTIPLIER.moderate,
                });
            }
        } catch (error) {
            console.log(error);
        }

    }
}

export default LearningCircleView;
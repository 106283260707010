import firebase from '../firebase';
import { objectToClassConverter } from './database_object';

export class Attachment {
    url: string = null;
    name: string = null;
    path: string = null;

    static fromFirebase(data: any): Attachment {
        let newAttachment = new Attachment();
        if (data == null) {
            return newAttachment;
        }
        objectToClassConverter(data, newAttachment);

        return newAttachment;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        return fbObject;
    }
}

export class Image {
    url: string = null;
    path: string = null;

    static fromFirebase(data: any): Image {
        let newImage = new Image();
        if (data == null) {
            return newImage;
        }
        objectToClassConverter(data, newImage);

        return newImage;
    }
}

export class Subject {
    subjectId: string = null;
    topics: Map<string, Topic> = null;

    static fromFirebase(data: any): Subject {
        let newSubject = new Subject();
        if (data == null) {
            return newSubject;
        }
        objectToClassConverter(data, newSubject, [], ["topics"]);

        let newTopics = new Map<string, Topic>();
        if (data.topics != null) {
            for (let nextTopicId of Object.keys(data.topics)) {
                newTopics.set(nextTopicId, Topic.fromFirebase(data.topics[nextTopicId]));
            }
        }
        newSubject.topics = newTopics;

        return newSubject;
    }
}

export class Topic {
    name: string = null;
    tags: Map<string, string> = null;

    static fromFirebase(data: any): Topic {
        let newTopic = new Topic();
        if (data == null) {
            return newTopic;
        }
        objectToClassConverter(data, newTopic, ["tags"]);

        return newTopic;
    }
}

export class StudentTopicOverview {
    accountId: string;
    schoolId: string;
    subject: string;
    topics: Map<string, Map<string, StudentTopicOverviewHistory>>;

    constructor() {
        this.topics = new Map();
    }

    static fromFirebase(data: any): StudentTopicOverview {
        let newStudentTopicOverview = new StudentTopicOverview();
        if (data == null) {
            return newStudentTopicOverview;
        }
        objectToClassConverter(data, newStudentTopicOverview, [], ["topics"]);

        let newTopics = new Map<string, Map<string, StudentTopicOverviewHistory>>();
        if (data.topics != null) {
            for (let nextTopicId of Object.keys(data.topics)) {
                let newTopicHistory = new Map<string, StudentTopicOverviewHistory>();
                for (let nextTagId of Object.keys(data.topics[nextTopicId])) {
                    newTopicHistory.set(nextTagId, StudentTopicOverviewHistory.fromFirebase(data.topics[nextTopicId][nextTagId]));
                }
                newTopics.set(nextTopicId, newTopicHistory); 
            }
        }
        newStudentTopicOverview.topics = newTopics;

        return newStudentTopicOverview;
    }
}

export class StudentTopicOverviewHistory {
    hist: Array<StudentTopicOverviewEntry>;
    scCount: number;

    static fromFirebase(data: any): StudentTopicOverviewHistory {
        let newStudentTopicOverviewHistory = new StudentTopicOverviewHistory();
        if (data == null) {
            return newStudentTopicOverviewHistory;
        }
        objectToClassConverter(data, newStudentTopicOverviewHistory, [], ["hist"]);

        let newHist = new Array<StudentTopicOverviewEntry>();
        if (data.hist != null) {
            for (let nextHist of data.hist) {
                newHist.push(StudentTopicOverviewEntry.fromFirebase(nextHist));
            }
        }
        newStudentTopicOverviewHistory.hist = newHist;

        return newStudentTopicOverviewHistory;
    }
}

export class StudentTopicOverviewEntry {
    dt: number;
    sc: boolean;

    static fromFirebase(data: any): StudentTopicOverviewEntry {
        let newStudentTopicOverviewEntry = new StudentTopicOverviewEntry();
        if (data == null) {
            return newStudentTopicOverviewEntry;
        }
        objectToClassConverter(data, newStudentTopicOverviewEntry);

        return newStudentTopicOverviewEntry;
    }
}

export class ReadingRecord {
    readingDateTime: firebase.firestore.Timestamp = null;
    accountId: string;
    bookName: string = null;
    position: string = null;
    description: string = null;
    parentReading: boolean = null;

    static fromFirebase(data: any): ReadingRecord {
        let newReadingRecord = new ReadingRecord();
        if (data == null) {
            return newReadingRecord;
        }
        objectToClassConverter(data, newReadingRecord);

        return newReadingRecord;
    }
}

export class HomeLearning {
    title: string = null;
    description: string = null;
    startDateTime: number = null;
    dueDateTime: number = null;
    accounts: string[] = null;
    subjectId: string = null;
    links: HomeLearningLink[] = null;
    schoolId: string = null;
    attachments: Attachment[] = null;
    setById: string = null;
    updatedBy: string = null;
    createdBy: string = null;
    disabled: boolean = null;
    activities: Map<string, HomeLearningActivity> = null;
    activityIdsOrdered: string[] = null;
    dateCreated: firebase.firestore.Timestamp = null;
    dateUpdated: firebase.firestore.Timestamp = null;

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);

        let fbAttachments = [];
        if (this.attachments != null) {
            for (let nextAttachment of this.attachments) {
                fbAttachments.push(nextAttachment.toFirebase());
            }
        }
        fbObject['attachments'] = fbAttachments;

        let fbLinks = [];
        if (this.links != null) {
            for (let nextLink of this.links) {
                fbLinks.push(nextLink.toFirebase());
            }
        }
        fbObject['links'] = fbLinks;

        if (this.dateCreated == null) {
            fbObject['dateCreated'] = firebase.firestore.FieldValue.serverTimestamp();
        }
        fbObject['dateUpdated'] = firebase.firestore.FieldValue.serverTimestamp();
        return fbObject;
    }

    static fromFirebase(data: any): HomeLearning {
        let newHomeLearning = new HomeLearning();
        if (data == null) {
            return newHomeLearning;
        }
        objectToClassConverter(data, newHomeLearning, [], ["links", "attachments"]);

        let newLinks = new Array<HomeLearningLink>();
        if (data.links != null) {
            for (let nextLink of data.links) {
                newLinks.push(HomeLearningLink.fromFirebase(nextLink));
            }
        }
        newHomeLearning.links = newLinks;

        let newAttachments = new Array<Attachment>();
        if (data.attachments != null) {
            for (let nextAttachment of data.attachments) {
                newAttachments.push(Attachment.fromFirebase(nextAttachment));
            }
        }
        newHomeLearning.attachments = newAttachments;

        let newActivities = new Map<string, HomeLearningActivity>();
        if (data.activities != null) {
            for (let nextActivityId of Object.keys(data.activities)) {
                newActivities.set(nextActivityId, HomeLearningActivity.fromFirebaseInherited(data.activities[nextActivityId]));
            }
        }
        newHomeLearning.activities = newActivities;

        return newHomeLearning;
    }
}

export class HomeLearningActivity {
    title: string = null;
    type: string = null;
    description: string = null;
    links: HomeLearningLink[] = null;
    attachments: Attachment[] = null;

    getParentToFirebase(fbObject: any): void {
        let fbAttachments = [];
        if (this.attachments != null) {
            for (let nextAttachment of this.attachments) {
                fbAttachments.push(nextAttachment.toFirebase());
            }
        }
        fbObject['attachments'] = fbAttachments;

        let fbLinks = [];
        if (this.links != null) {
            for (let nextLink of this.links) {
                fbLinks.push(nextLink.toFirebase());
            }
        }
        fbObject['links'] = fbLinks;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        this.getParentToFirebase(fbObject);
        return fbObject;
    }

    static fromFirebaseInherited(data: any): HomeLearningActivity {
        let newHomeLearningActivity;
        if (data.type === HomeLearningActivityBasic.TYPE) {
            newHomeLearningActivity = HomeLearningActivityBasic.fromFirebase(data);
        } else if (data.type === HomeLearningActivityWorksheet.TYPE) {
            newHomeLearningActivity = HomeLearningActivityWorksheet.fromFirebase(data);
        } else if (data.type === HomeLearningActivityLearningPath.TYPE) {
            newHomeLearningActivity = HomeLearningActivityLearningPath.fromFirebase(data);
        } else if (data.type === HomeLearningActivityAdventure.TYPE) {
            newHomeLearningActivity = HomeLearningActivityAdventure.fromFirebase(data);
        } else {
            newHomeLearningActivity = HomeLearningActivity.fromFirebase(data);
        }
        return newHomeLearningActivity;
    }

    static fromFirebase(data: any): HomeLearningActivity {
        let newHomeLearningActivity = new HomeLearningActivity();
        if (data == null) {
            return newHomeLearningActivity;
        }
        objectToClassConverter(data, newHomeLearningActivity, [], ["links", "attachments"]);

        let newLinks = new Array<HomeLearningLink>();
        if (data.links != null) {
            for (let nextLink of data.links) {
                newLinks.push(HomeLearningLink.fromFirebase(nextLink));
            }
        }
        newHomeLearningActivity.links = newLinks;

        let newAttachments = new Array<Attachment>();
        if (data.attachments != null) {
            for (let nextAttachment of data.attachments) {
                newAttachments.push(Attachment.fromFirebase(nextAttachment));
            }
        }
        newHomeLearningActivity.attachments = newAttachments;

        return newHomeLearningActivity;
    }

    getTypeName(): string {
        let typeName = "";
        if (this.type == HomeLearningActivityBasic.TYPE) {
            typeName = "Basic";
        } else if (this.type == HomeLearningActivityWorksheet.TYPE) {
            typeName = "Worksheet";
        } else if (this.type == HomeLearningActivityLearningPath.TYPE) {
            typeName = "Learning path";
        } else if (this.type == HomeLearningActivityAdventure.TYPE) {
            typeName = "Adventure";
        }

        return typeName;
    }
}

export class HomeLearningActivityBasic extends HomeLearningActivity {
    topics: string[] = null;
    topicTags: Map<string, string[]> = null;

    static TYPE = "ACTIVITY";

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        this.getParentToFirebase(fbObject);
        fbObject['topicTags'] = Object.fromEntries(this.topicTags);
        return fbObject;
    }

    static fromFirebase(data: any): HomeLearningActivityBasic {
        let parent = HomeLearningActivity.fromFirebase(data);

        let newHomeLearningActivityBasic = new HomeLearningActivityBasic();
        if (data == null) {
            return newHomeLearningActivityBasic;
        }
        objectToClassConverter(data, newHomeLearningActivityBasic, ["topicTags"]);

        newHomeLearningActivityBasic = Object.assign(newHomeLearningActivityBasic, parent);

        return newHomeLearningActivityBasic;
    }
}

export class HomeLearningActivityWorksheet extends HomeLearningActivity {
    worksheetUrl: string = null;
    worksheetPath: string = null;
    topics: string[] = null;
    topicTags: Map<string, string[]> = null;

    static TYPE = "WORKSHEET";

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        this.getParentToFirebase(fbObject);
        fbObject['topicTags'] = Object.fromEntries(this.topicTags);
        return fbObject;
    }

    static fromFirebase(data: any): HomeLearningActivityWorksheet {
        let parent = HomeLearningActivity.fromFirebase(data);

        let newHomeLearningActivityWorksheet = new HomeLearningActivityWorksheet();
        if (data == null) {
            return newHomeLearningActivityWorksheet;
        }
        objectToClassConverter(data, newHomeLearningActivityWorksheet, ["topicTags"]);

        newHomeLearningActivityWorksheet = Object.assign(newHomeLearningActivityWorksheet, parent);

        return newHomeLearningActivityWorksheet;
    }
}

export class HomeLearningActivityLearningPath extends HomeLearningActivity {
    worldId: string = null;
    pathId: string = null;

    static TYPE = "LEARNING_PATH";

    static fromFirebase(data: any): HomeLearningActivityLearningPath {
        let parent = HomeLearningActivity.fromFirebase(data);

        let newHomeLearningActivityLearningPath = new HomeLearningActivityLearningPath();
        if (data == null) {
            return newHomeLearningActivityLearningPath;
        }
        objectToClassConverter(data, newHomeLearningActivityLearningPath);

        newHomeLearningActivityLearningPath = Object.assign(newHomeLearningActivityLearningPath, parent);

        return newHomeLearningActivityLearningPath;
    }
}

export class HomeLearningActivityAdventure extends HomeLearningActivity {
    adventureId: string = null;

    static TYPE = "ADVENTURE";

    static fromFirebase(data: any): HomeLearningActivityAdventure {
        let parent = HomeLearningActivity.fromFirebase(data);

        let newHomeLearningActivityAdventure = new HomeLearningActivityAdventure();
        if (data == null) {
            return newHomeLearningActivityAdventure;
        }
        objectToClassConverter(data, newHomeLearningActivityAdventure);

        newHomeLearningActivityAdventure = Object.assign(newHomeLearningActivityAdventure, parent);

        return newHomeLearningActivityAdventure;
    }
}

export class HomeLearningLink {
    name: string = null;
    url: string = null;

    static fromFirebase(data: any): HomeLearningLink {
        let newHomeLearningLink = new HomeLearningLink();
        if (data == null) {
            return newHomeLearningLink;
        }
        objectToClassConverter(data, newHomeLearningLink);

        return newHomeLearningLink;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        return fbObject;
    }
}

export class HomeLearningResponse {
    accountId: string = null;
    homeworkId: string = null;
    activityResponses: Map<string, HomeLearningActivityResponse>;
    schoolId: string = null;
    completed: boolean = null;
    reviewed: boolean = null;
    feedbackRead: boolean = null;
    feedback: string = null;
    urls: HomeLearningResponseAttachment[] = null;

    static fromFirebase(data: any): HomeLearningResponse {
        let newHomeLearningResponse = new HomeLearningResponse();
        if (data == null) {
            return newHomeLearningResponse;
        }
        objectToClassConverter(data, newHomeLearningResponse, [], ["activityResponses"]);

        let newActivityResponses = new Map<string, HomeLearningActivityResponse>();
        if (data.activityResponses != null) {
            for (let nextActivityId of Object.keys(data.activityResponses)) {
                newActivityResponses.set(nextActivityId, HomeLearningActivityResponse.fromFirebase(data.activityResponses[nextActivityId]));
            }
        }
        newHomeLearningResponse.activityResponses = newActivityResponses;

        return newHomeLearningResponse;
    }
}

export class HomeLearningActivityResponse {
    accountId: string = null;
    dateCompleted: firebase.firestore.Timestamp = null;
    dateUpdated: firebase.firestore.Timestamp = null;
    homeworkId: string = null;
    homeworkActivityId: string = null;
    schoolId: string = null;
    completed: boolean = null;
    reviewed: boolean = null;

    worksheetId: string = null;
    adventureStatusId: string = null;
    urls: HomeLearningResponseAttachment[] = null;
    text: string = null;
    feedback: ActivityResponseFeedback = null;
    feedbackRead: boolean = null;

    static fromFirebase(data: any): HomeLearningActivityResponse {
        let newHomeLearningActivityResponse = new HomeLearningActivityResponse();
        if (data == null) {
            return newHomeLearningActivityResponse;
        }
        objectToClassConverter(data, newHomeLearningActivityResponse, [], ["urls", "feedback"]);

        let newUrls = new Array<HomeLearningResponseAttachment>();
        if (data.urls != null) {
            for (let nextUrl of data.urls) {
                newUrls.push(HomeLearningResponseAttachment.fromFirebase(nextUrl));
            }
        }
        newHomeLearningActivityResponse.urls = newUrls;

        newHomeLearningActivityResponse.feedback = ActivityResponseFeedback.fromFirebase(data.feedback);

        return newHomeLearningActivityResponse;
    }
}

export class ActivityResponseFeedback {
    awardId: string = null;
    imageUrl: string = null;
    points: number = null;
    message: string = null;

    static fromFirebase(data: any): ActivityResponseFeedback {
        let newActivityResponseFeedback = new ActivityResponseFeedback();
        if (data == null) {
            return newActivityResponseFeedback;
        }
        objectToClassConverter(data, newActivityResponseFeedback);

        return newActivityResponseFeedback;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        return fbObject;
    }
}

export class HomeLearningWorksheet {
    worksheetPoints: WorksheetPointList[] = null;

    static fromFirebase(data: any): HomeLearningWorksheet {
        let newHomeLearningWorksheet = new HomeLearningWorksheet();
        if (data == null) {
            return newHomeLearningWorksheet;
        }
        objectToClassConverter(data, newHomeLearningWorksheet, [], ["worksheetPoints"]);

        let newWorksheetPoints = new Array<WorksheetPointList>();
        if (data.worksheetPoints != null) {
            for (let nextWorksheetPoints of data.worksheetPoints) {
                newWorksheetPoints.push(WorksheetPointList.fromFirebase(nextWorksheetPoints));
            }
        }
        newHomeLearningWorksheet.worksheetPoints = newWorksheetPoints;

        return newHomeLearningWorksheet;
    }
}

export class WorksheetPointList {
    type: string = null;
    text: string = null;
    adjustments: Map<string, string> = new Map<string, string>();
    offsets: WorksheetPointOffsets[] = null;

    static fromFirebase(data: any): WorksheetPointList {
        let newWorksheetPoints = new WorksheetPointList();
        if (data == null) {
            return newWorksheetPoints;
        }
        objectToClassConverter(data, newWorksheetPoints, [], ["offsets", "adjustments"]);

        let newWorksheetPointOffsets = new Array<WorksheetPointOffsets>();
        if (data.offsets != null) {
            for (let nextWorksheetOffsets of data.offsets) {
                newWorksheetPointOffsets.push(WorksheetPointOffsets.fromFirebase(nextWorksheetOffsets));
            }
        }
        newWorksheetPoints.offsets = newWorksheetPointOffsets;

        let newAdjustments = new Map<string, string>();
        if (data.adjustments != null) {
            for (let nextAdjustment of Object.keys(data.adjustments)) {
                newAdjustments.set(nextAdjustment, data.adjustments[nextAdjustment]);
            }
        }
        newWorksheetPoints.adjustments = newAdjustments;

        return newWorksheetPoints;
    }
}

export class WorksheetPointOffsets {
    dx: number = null;
    dy: number = null;

    static fromFirebase(data: any): WorksheetPointOffsets {
        let newWorksheetPointOffsets = new WorksheetPointOffsets();
        if (data == null) {
            return newWorksheetPointOffsets;
        }
        objectToClassConverter(data, newWorksheetPointOffsets);

        return newWorksheetPointOffsets;
    }
}

export class HomeLearningResponseAttachment {
    publicUrl: string = null;

    static fromFirebase(data: any): HomeLearningResponseAttachment {
        let newHomeLearningResponseAttachment = new HomeLearningResponseAttachment();
        if (data == null) {
            return newHomeLearningResponseAttachment;
        }
        objectToClassConverter(data, newHomeLearningResponseAttachment);

        return newHomeLearningResponseAttachment;
    }
}

export class LearningOverview {
    paths: Map<string, LearningPathOverview> = null;

    static fromFirebase(data: any): LearningOverview {
        let newLearningOverview = new LearningOverview();
        if (data == null) {
            return newLearningOverview;
        }
        objectToClassConverter(data, newLearningOverview, [], ["paths"]);

        let newPaths = new Map<string, LearningPathOverview>();
        if (data.paths != null) {
            for (let nextPathId of Object.keys(data.paths)) {
                newPaths.set(nextPathId, LearningPathOverview.fromFirebase(data.paths[nextPathId]));
            }
        }
        newLearningOverview.paths = newPaths;

        return newLearningOverview;
    }
}

export class LearningPathOverview {
    completed: number = null;
    stars: number = null;
    time: number = null;

    static fromFirebase(data: any): LearningPathOverview {
        let newLearningPathOverview = new LearningPathOverview();
        if (data == null) {
            return newLearningPathOverview;
        }
        objectToClassConverter(data, newLearningPathOverview);

        return newLearningPathOverview;
    }
}

export class LearningGameOutcome {
    completed: boolean = null;
    flawless: boolean = null;
    startDateTime: number = null;
    endDateTime: number = null;
    gameId: string = null;
    accountId: string = null;

    static fromFirebase(data: any): LearningGameOutcome {
        let newLearningGameOutcome = new LearningGameOutcome();
        if (data == null) {
            return newLearningGameOutcome;
        }
        objectToClassConverter(data, newLearningGameOutcome);

        return newLearningGameOutcome;
    }
}
import React, {Component} from 'react';
import {
    Button, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { codeString } from '../icon_codepoints';

interface IState {
    currentIcon: string;
    currentIconFamily: string;
    showIconModal: boolean;
}

interface IProps {
    currentIcon: string;
    currentIconFamily: string;
    iconLegend?: string;
    callback: (iconCodeString: string, fontFamily: string) => void;
}

class IconSelection extends Component<IProps, IState> {
    legend: string;

    constructor(props: IProps) {
        super(props);
        this.state = {
            currentIcon: props.currentIcon,
            currentIconFamily: props.currentIconFamily,
            showIconModal: false,
        };
        this.legend = props.iconLegend != null && props.iconLegend.length > 0 ? props.iconLegend : "Icon";

        this.handleIconCodeSelect = this.handleIconCodeSelect.bind(this);
        this.handleIconCodeChange = this.handleIconCodeChange.bind(this);
        this.showIconModal = this.showIconModal.bind(this);
    }

    componentDidUpdate(prevProps: IProps, prevState: IState, snapshot: any): void {
        if (this.props.currentIcon !== this.state.currentIcon ||
        this.props.currentIconFamily !== this.state.currentIconFamily) {
            this.setState({
                currentIcon: this.props.currentIcon,
                currentIconFamily: this.props.currentIconFamily,
            });
        }
    }

    render(): JSX.Element {
        return <div>
            <FormGroup>
                <Label>{this.legend}</Label><br/>
                {this.state.currentIcon != null ?
                    this.state.currentIconFamily === 'MaterialIcons' ?
                        <span>
                            <i className="material-icons">{this.state.currentIcon}</i>
                        </span>
                        : this.state.currentIconFamily === 'FontAwesomeSolid' ?
                        <span>
                                <i className="fas">{this.state.currentIcon}</i>
                            </span>
                        : <span>
                            <i className="far">{this.state.currentIcon}</i>
                        </span>
                    : <br/>
                }&nbsp;&nbsp;
                <Button type="button" outline onClick={this.showIconModal}>Change Icon</Button>
            </FormGroup>
            <Modal isOpen={this.state.showIconModal} toggle={this.showIconModal}>
                <ModalHeader toggle={this.showIconModal}>Select icon</ModalHeader>
                <ModalBody>
                    {codeString.map((iconCodeString) => {
                        return <Button key={iconCodeString} onClick={() => {
                            this.handleIconCodeSelect(iconCodeString);
                            this.showIconModal();
                        }}><i className="material-icons">{iconCodeString}</i></Button>
                    })}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.showIconModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    }

    handleIconCodeSelect(iconId: string): void {
        this.props.callback(iconId, "MaterialIcons");
    }

    handleIconCodeChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.handleIconCodeSelect(e.target.value);
    }

    showIconModal(): void {
        this.setState({
            showIconModal: !this.state.showIconModal
        });
    }
}

export default IconSelection;
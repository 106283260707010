import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { User } from "../data/user";
import firebase from '../firebase';
import {
    Button, Alert, Form, FormGroup, Label, Input, Collapse, Card, CardHeader, CardBody, Table, Modal, ModalHeader,
    ModalBody, Col, Container, Row, Spinner, Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import { AccountGroup, AccountMinimised } from "../data/accounts";
import { StudentTopicOverview, Subject, Topic } from "../data/home_learning";
import { subjectIds } from '../Prettifier';
import queryString from 'query-string';
import { SubjectObjectives } from "../data/objectives";
import { getObjectives } from "../helpers/objectives_service";

interface TagAnalysis {
    passed: string[];
    lastPassed: string[];
    secure: string[];
    attempted: string[];
}

interface ShowAccountListDetails {
    title: string;
    includedAccountIds: string[];
    excludedAccountIds: string[];
}

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
    accountGroups: Map<string, AccountGroup>;
}

interface IState {
    dataLoaded: boolean;
    dataLoading: boolean;
    currentReportAccountGroups: Array<string>;
    currentReportSubjectId: string;
    currentReportObjectiveCategoryId: string;
    newReportAccountGroups: Array<string>;
    newAccountIdList: Array<string>;
    newReportSubjectId: string;
    newReportObjectiveCategoryId: string;
    availableAccountIds: Array<string>;
    accountIdList: Array<string>;
    topicAnalysis: Map<string, StudentTopicOverview>;
    showReportDetails: boolean;
    topics: Map<string, Topic>;
    expandedObjectiveId: string;
    showAccountListDetails: ShowAccountListDetails;
    collapse: boolean;
    objectives: Map<string, SubjectObjectives>;
}

class ObjectivesReportView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            dataLoaded: false,
            dataLoading: false,
            currentReportAccountGroups: [],
            currentReportSubjectId: null,
            newReportAccountGroups: [],
            newAccountIdList: [],
            newReportSubjectId: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
            newReportObjectiveCategoryId: "",
            availableAccountIds: [],
            accountIdList: [],
            topicAnalysis: new Map(),
            collapse: true,
            showReportDetails: true,
            showAccountListDetails: null,
            expandedObjectiveId: null,
            topics: null,
            objectives: getObjectives(),
            currentReportObjectiveCategoryId: null,
        };
        this.createTopicAnalysisReport = this.createTopicAnalysisReport.bind(this);
        this.handleReportChange = this.handleReportChange.bind(this);
        this.selectAllAccounts = this.selectAllAccounts.bind(this);
        this.loadTopicAnalysis = this.loadTopicAnalysis.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleAccountGroupChange = this.handleAccountGroupChange.bind(this);
        this.toggleShowReportDetails = this.toggleShowReportDetails.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    render(): JSX.Element {
        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/reporting/zapMathsReporting'}>All Reports</Link></BreadcrumbItem>
                    <BreadcrumbItem>Objectives Report</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Objectives report</div>
                    <p className="cardSubTitle">View student attainment against objectives.</p>

                    {this.state.dataLoaded ?
                        <Button onClick={this.toggleShowReportDetails} className="altButton" style={{ width: '9%', float: 'right' }}>
                            {this.state.showReportDetails ? "Close" : "Edit report"}
                        </Button> : null
                    }
                    <Collapse isOpen={this.state.showReportDetails}>
                        <Form onSubmit={this.handleReportChange}>
                            <FormGroup>
                                <Label for="newReportAccountGroups">Account groups *</Label>
                                <Input type="select" required name="newReportAccountGroups" multiple onChange={this.handleAccountGroupChange} value={this.state.newReportAccountGroups}>
                                    {Array.from(this.props.accountGroups.keys()).map((accountGroupId) => {
                                        return (
                                            <option value={accountGroupId} key={accountGroupId}>{this.props.accountGroups.get(accountGroupId).details.name}</option>
                                        )
                                    })}
                                </Input><br />
                            </FormGroup>
                            <Collapse isOpen={!this.state.collapse}>
                                <FormGroup>
                                    <Label for="newAccountIdList">Accounts * <Button type="button" onClick={this.selectAllAccounts} color="secondary">Select all</Button></Label>
                                    <Input type="select" required name="newAccountIdList" multiple onChange={this.handleAccountChange} value={this.state.newAccountIdList}>
                                        {this.state.availableAccountIds.map((accountId) => {
                                            let account = this.props.accountsMinimised.get(accountId);
                                            if (account == null) {
                                                return null;
                                            }
                                            return (
                                                <option value={accountId} key={accountId}>{account.name}</option>
                                            )
                                        })
                                        }
                                    </Input><br />
                                </FormGroup>
                            </Collapse>
                            <FormGroup>
                                <Label for="newReportSubjectId">Subject *</Label>
                                <Input required type="select" name="newReportSubjectId"
                                    onChange={(e) => this.setState({
                                        newReportSubjectId: e.target.value
                                    })}
                                    value={this.state.newReportSubjectId}>
                                    <option value={''}>&nbsp;</option>
                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                        let subjectName = subjectIds.get(subjectId);
                                        return <option value={subjectId}>{subjectName}</option>;
                                    })}
                                </Input>
                            </FormGroup>
                            {this.state.newReportSubjectId == null || this.state.newReportSubjectId === "" ? <span /> :
                                !this.state.objectives.has(this.state.newReportSubjectId) ? <p>There are no objectives defined for this subject</p> :
                                    <React.Fragment>
                                        <FormGroup>
                                            <Label for="newReportObjectiveCategoryId">Objective category *</Label>
                                            <Input required type="select" name="newReportObjectiveCategoryId"
                                                onChange={(e) => this.setState({
                                                    newReportObjectiveCategoryId: e.target.value
                                                })}
                                                value={this.state.newReportObjectiveCategoryId}>
                                                <option value={''}>&nbsp;</option>
                                                {Array.from(this.state.objectives.get(this.state.newReportSubjectId).categories.keys()).map(objectiveCategoryId => {
                                                    let objectiveCategory = this.state.objectives.get(this.state.newReportSubjectId).categories.get(objectiveCategoryId);
                                                    return <option value={objectiveCategoryId}>{objectiveCategory.title}</option>;
                                                })}
                                            </Input>
                                        </FormGroup>
                                        <Button className="adminPagesButton">{this.state.dataLoading ? <Spinner size="sm"></Spinner> : <span />}Run report</Button>
                                    </React.Fragment>
                            }
                        </Form>
                    </Collapse>
                </CardBody>
            </Card>
            <Modal isOpen={this.state.showAccountListDetails != null} toggle={() => this.setState({ showAccountListDetails: null })}>
                <ModalHeader toggle={() => this.setState({ showAccountListDetails: null })}>{this.state.showAccountListDetails == null ? "" : this.state.showAccountListDetails.title}</ModalHeader>
                <ModalBody>
                    {this.state.showAccountListDetails == null ? <span /> :
                        <Container className="border rounded form-margin">
                            <Row>
                                <Col><h3>Yes</h3>
                                    {this.state.showAccountListDetails.includedAccountIds.map((accountId) => {
                                        let account = this.props.accountsMinimised.get(accountId);
                                        if (account == null) {
                                            return null;
                                        } else {
                                            return <span>{account.name}<br /></span>;
                                        }
                                    })}
                                </Col>
                                <Col><h3>No</h3>
                                    {this.state.showAccountListDetails.excludedAccountIds.map((accountId) => {
                                        let account = this.props.accountsMinimised.get(accountId);
                                        if (account == null) {
                                            return null;
                                        } else {
                                            return <span>{account.name}<br /></span>;
                                        }
                                    })}
                                </Col>
                            </Row>
                        </Container>
                    }
                </ModalBody>
            </Modal>
            {this.state.dataLoaded ?
                <React.Fragment>
                    <Card className="mainCard top-buffer">
                        <CardBody className="d-flex flex-column">
                            <div className="cardTitle2">{this.state.accountIdList.length} student{this.state.accountIdList.length !== 1 ? "s" : ""} included in report</div>
                            {this.createTopicAnalysisReport()}
                        </CardBody>
                    </Card>
                </React.Fragment> : <span />
            }
            <br /><br />
        </div>
    }

    toggleShowReportDetails() {
        this.setState({
            showReportDetails: !this.state.showReportDetails
        });
    }

    async handleReportChange(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        this.setState({
            dataLoading: true,
        });

        let accountIdList = this.state.newAccountIdList;

        if (this.state.newReportSubjectId != this.state.currentReportSubjectId) {
            await this.loadTopicAnalysis(this.state.newReportSubjectId, accountIdList);
        }

        this.setState({
            currentReportAccountGroups: this.state.newReportAccountGroups,
            currentReportSubjectId: this.state.newReportSubjectId,
            currentReportObjectiveCategoryId: this.state.newReportObjectiveCategoryId,
            accountIdList: accountIdList,
            showReportDetails: false,
            dataLoading: false,
            dataLoaded: true,
        });
    }

    async loadTopicAnalysis(subjectId: string, accountIdList: Array<string>) {
        const subjectRef = firebase.firestore().collection(`learningTopics`).where('subjectId', '==', subjectId);
        let subjectSnapshot = await subjectRef.get();
        let topics = new Map<string, Topic>();
        subjectSnapshot.forEach((docSnapshot) => {
            let subjectData = docSnapshot.data();
            let subject = Subject.fromFirebase(subjectData);
            for (let nextTopicId of Array.from(subject.topics.keys())) {
                topics.set(nextTopicId, subject.topics.get(nextTopicId));
            }
        });

        let promises = [];
        for (let accountId of accountIdList) {
            console.log("Looking for accountId", accountId);
            const topicOverviewRef = firebase.firestore().doc(`studentTopicOverviews/${subjectId}--${accountId}`);
            promises.push(topicOverviewRef.get());
        }
        let topicAnalysis = new Map<string, StudentTopicOverview>();
        let results = await Promise.all(promises);
        results.forEach((topicOverviewSnapshot) => {
            if (topicOverviewSnapshot.exists) {
                let topicOverview = StudentTopicOverview.fromFirebase(topicOverviewSnapshot.data());
                topicAnalysis.set(topicOverview.accountId, topicOverview);
            }
        });

        this.setState({
            topics: topics,
            topicAnalysis: topicAnalysis,
        });
    }

    handleAccountChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newAccountIdList.indexOf(e.target.value);
        if (index > -1) {
            this.state.newAccountIdList.splice(index, 1);
        } else {
            this.state.newAccountIdList.push(e.target.value);
        }
        this.setState({});
    }

    selectAllAccounts(): void {
        this.setState({
            newAccountIdList: this.state.availableAccountIds.slice(),
        })
    }

    handleAccountGroupChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newReportAccountGroups.indexOf(e.target.value);
        if (index > -1) {
            this.state.newReportAccountGroups.splice(index, 1);
        } else {
            this.state.newReportAccountGroups.push(e.target.value);
        }
        let accountIdList = [];
        for (let nextAccountGroupId of this.state.newReportAccountGroups) {
            let nextAccountGroup = this.props.accountGroups.get(nextAccountGroupId);
            if (nextAccountGroup != null) {
                for (let nextAccountId of nextAccountGroup.accounts.keys()) {
                    let nextAccountDeets = nextAccountGroup.accounts.get(nextAccountId);
                    if (nextAccountDeets.member != null && nextAccountDeets.member &&
                        accountIdList.indexOf(nextAccountId) === -1 && this.props.accountsMinimised.has(nextAccountId)) {
                        accountIdList.push(nextAccountId);
                    }
                }
            }
        }
        this.setState({
            availableAccountIds: accountIdList,
            collapse: false,
            accountIdList: [],
        });
    }

    createTopicAnalysisReport(): JSX.Element {
        let groups = this.state.objectives.get(this.state.currentReportSubjectId).categories.get(this.state.currentReportObjectiveCategoryId);

        return <Table>
            <tr style={{ "backgroundColor": "#70309b" }}>
                <th style={{ "color": "white" }}>Objective</th>
                <th style={{ "color": "white" }}>Details</th>
                <th style={{ "color": "white" }}># attempted</th>
                <th style={{ "color": "white" }}># flawless at least once</th>
                <th style={{ "color": "white" }}># flawless last attempt</th>
                <th style={{ "color": "white" }}># secure</th>
            </tr>
            {Array.from(groups.objectiveGroups.keys()).map((objectiveGroupId) => {
                let objectiveGroup = groups.objectiveGroups.get(objectiveGroupId);
                return <React.Fragment>
                    <tr style={{ "backgroundColor": "darkGrey" }}>
                        <td colSpan={6} style={{ "color": "white" }}>{objectiveGroup.title}</td>
                    </tr>
                    {Array.from(objectiveGroup.objectives.keys()).map((objectiveId) => {
                        let objective = objectiveGroup.objectives.get(objectiveId);
                        let totalAttempted = new Array<string>();
                        let totalPassed = new Array<string>();
                        let totalLastPassed = new Array<string>();
                        let totalSecure = new Array<string>();
                        let topicsDisplayAnalysis = new Map<string, Map<string, TagAnalysis>>();
                        for (let nextAccountId of this.state.accountIdList) {
                            if (this.state.topicAnalysis.has(nextAccountId)) {
                                let objectiveAttempted = false;
                                let objectiveLastPassed = true;
                                let objectivePassed = true;
                                let objectiveSecure = true;
                                let analysis = this.state.topicAnalysis.get(nextAccountId);
                                if (objective.topicTags.size == 0) {
                                    objectiveAttempted = false;
                                    objectiveLastPassed = false;
                                    objectivePassed = false;
                                    objectiveSecure = false;
                                }
                                topicLoop: for (let nextTopicId of objective.topicTags.keys()) {
                                    if (!topicsDisplayAnalysis.has(nextTopicId)) {
                                        topicsDisplayAnalysis.set(nextTopicId, new Map());
                                    }
                                    if (!analysis.topics.has(nextTopicId)) {
                                        objectiveAttempted = false;
                                    }
                                    let topicDisplayAnalysis = topicsDisplayAnalysis.get(nextTopicId);
                                    for (let nextTagId of objective.topicTags.get(nextTopicId)) {
                                        if (!topicDisplayAnalysis.has(nextTagId)) {
                                            topicDisplayAnalysis.set(nextTagId, { secure: [], lastPassed: [], attempted: [], passed: [] });
                                        }
                                        let tagDisplayAnalysis = topicDisplayAnalysis.get(nextTagId);
                                        if (analysis.topics.has(nextTopicId) && analysis.topics.get(nextTopicId).has(nextTagId)) {
                                            tagDisplayAnalysis.attempted.push(nextAccountId);
                                            objectiveAttempted = true;
                                            let secureCount = 0;
                                            let passed = false;
                                            let historyCount = 0;
                                            let history = analysis.topics.get(nextTopicId).get(nextTagId).hist;
                                            history.sort((entry1, entry2) => {
                                                return entry1.dt - entry2.dt;
                                            });
                                            history.reverse();
                                            for (let nextEntry of history) {
                                                if (nextEntry.sc) {
                                                    if (historyCount < 4) {
                                                        secureCount++;
                                                    }
                                                    passed = true;
                                                }
                                                historyCount++;
                                            }
                                            if (passed) {
                                                tagDisplayAnalysis.passed.push(nextAccountId);
                                            } else {
                                                objectivePassed = false;
                                            }
                                            if (secureCount == 0) {
                                                objectiveLastPassed = false;
                                            } else {
                                                tagDisplayAnalysis.lastPassed.push(nextAccountId);
                                            }
                                            if (secureCount < 3) {
                                                objectiveSecure = false;
                                            } else {
                                                tagDisplayAnalysis.secure.push(nextAccountId);
                                            }
                                        } else {
                                            objectiveSecure = false;
                                            objectiveLastPassed = false;
                                            objectivePassed = false;
                                            break topicLoop;
                                        }
                                    }
                                }
                                if (objectivePassed) {
                                    totalPassed.push(nextAccountId);
                                }
                                if (objectiveAttempted) {
                                    totalAttempted.push(nextAccountId);
                                }
                                if (objectiveLastPassed) {
                                    totalLastPassed.push(nextAccountId);
                                }
                                if (objectiveSecure) {
                                    totalSecure.push(nextAccountId);
                                }
                            }
                        }
                        let row = <tr className="border-top">
                            <td colSpan={objective.title == null || objective.title === "" ? 2 : 1} className="border-top"><Button color="link" style={{ textAlign: "left" }} onClick={() => {
                                this.setState({
                                    expandedObjectiveId: this.state.expandedObjectiveId === objectiveId ? null : objectiveId
                                });
                            }}>{objective.title == null || objective.title === "" ? objective.description : objective.title}</Button></td>
                            {objective.title != null && objective.title !== "" ? <td className="border-top">{objective.description}</td> : null}
                            <td className="border-top">{<Button color="link" onClick={() => this.openModal(totalAttempted, `Attempted ${objective.title}`)}>{totalAttempted.length}</Button>}</td>
                            <td className="border-top">{totalPassed.length == 0 ? "" : <Button color="link" onClick={() => this.openModal(totalPassed, `Passed ${objective.title}`)}>{totalPassed.length}</Button>}</td>
                            <td className="border-top">{totalLastPassed.length == 0 ? "" : <Button color="link" onClick={() => this.openModal(totalLastPassed, `Passed last attempt ${objective.title}`)}>{totalLastPassed.length}</Button>}</td>
                            <td className="border-top">{totalSecure.length == 0 ? "" : <Button color="link" onClick={() => this.openModal(totalSecure, `Secure ${objective.title}`)}>{totalSecure.length}</Button>}</td>
                        </tr>;
                        return <React.Fragment>
                            {row}
                            {this.state.expandedObjectiveId !== objectiveId ? <span /> :
                                Array.from(objective.topicTags.keys()).map((topicId) => {
                                    let topic = this.state.topics.get(topicId);
                                    return <React.Fragment>
                                        <tr style={{ "backgroundColor": "lightGrey" }}>
                                            <td style={{ "backgroundColor": "white" }}></td>
                                            <td colSpan={5} style={{ "color": "white" }}>{topic.name}</td>
                                        </tr>
                                        {objective.topicTags.get(topicId).map((tagId) => {
                                            let tag = topic.tags.get(tagId);
                                            let analysis = topicsDisplayAnalysis.get(topicId) == null ? null : topicsDisplayAnalysis.get(topicId).get(tagId);
                                            if (analysis == null) {
                                                analysis = { attempted: [], lastPassed: [], passed: [], secure: [] };
                                            }
                                            return <tr>
                                                <td></td>
                                                <td>{tag}</td>
                                                <td>{<Button color="link" onClick={() => this.openModal(analysis.attempted, `Attempted ${objective.title}`)}>{analysis.attempted.length}</Button>}</td>
                                                <td>{analysis.passed.length == 0 ? "" : <Button color="link" onClick={() => this.openModal(analysis.passed, `Passed ${objective.title}`)}>{analysis.passed.length}</Button>}</td>
                                                <td>{analysis.lastPassed.length == 0 ? "" : <Button color="link" onClick={() => this.openModal(analysis.lastPassed, `Passed last attempt ${objective.title}`)}>{analysis.lastPassed.length}</Button>}</td>
                                                <td>{analysis.secure.length == 0 ? "" : <Button color="link" onClick={() => this.openModal(analysis.secure, `Secure ${objective.title}`)}>{analysis.secure.length}</Button>}</td>
                                            </tr>;
                                        })}
                                    </React.Fragment>
                                })
                            }
                        </React.Fragment>
                    })}
                </React.Fragment>
            })}

        </Table>
    }

    openModal(includedIds: string[], title: string): void {
        let excludedIds = Array.from(this.state.accountIdList);
        excludedIds = excludedIds.filter((nextId) => {
            return includedIds.indexOf(nextId) === -1;
        });
        includedIds.sort((id1, id2) => {
            let account1 = this.props.accountsMinimised.get(id1);
            let account2 = this.props.accountsMinimised.get(id2);
            return account1.name.localeCompare(account2.name);
        });
        excludedIds.sort((id1, id2) => {
            let account1 = this.props.accountsMinimised.get(id1);
            let account2 = this.props.accountsMinimised.get(id2);
            return account1.name.localeCompare(account2.name);
        });
        this.setState({
            showAccountListDetails: {
                includedAccountIds: includedIds,
                title: title,
                excludedAccountIds: excludedIds,
            }
        });
    }
}

export default ObjectivesReportView;
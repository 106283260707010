//@ts-check

import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Breadcrumb,
    BreadcrumbItem,
    Alert,
    Table,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Modal,
    ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Spinner
} from 'reactstrap';
import DatePicker from "react-datepicker";
import confirm from "reactstrap-confirm";
import TopicsSearch from "./ninja_components/topics_search";
import queryString from 'query-string';
import { searchMinigames, searchQuestions, LearningGameSearchStub, QuestionSearchStub } from './helpers/topic_services';
import { subjectIds } from './Prettifier';
import { Quiz, QuizRound, QuizTemplate } from './data/quizzes';
import { User } from './data/user';
import { LearningGame, Question } from './data/learning_games';
import { Topic } from './data/home_learning';
import { AccountGroup } from './data/accounts';

interface IState {
    quizId: string;
    quiz: QuizTemplate;
    subjectFilter: string;
    currentQuizName: string;
    currentQuizAccountGroups: {},
    currentQuizQuestions: QuizRound[],
    currentQuizSubjectId: string;
    newQuestionId: string;
    newQuizScheduled: boolean;
    newQuizDateTime: Date;
    newQuizAccountGroups: string[];
    collapse: boolean;
    collapseAddQuestion: boolean;
    showModal: boolean;
    showMinigameSelect: boolean;
    showQuestionSelect: boolean;
    currentSearchTopic: string;
    currentSearchTopicTags: string[][];
    currentSearchGameIds: string[];
    currentSearchAllTopicGames: Map<string, LearningGameSearchStub>;
    currentSearchQuestionIds: string[];
    currentAllTopicQuestions: Map<string, QuestionSearchStub>,
    currentSearchSchoolGameIds: string[];
    currentSearchAllTopicSchoolGames: Map<string, LearningGameSearchStub>;
    currentSearchSchoolQuestionIds: string[];
    currentAllTopicSchoolQuestions: Map<string, QuestionSearchStub>,
    questions: Map<string, Question>;
    minigames: Map<string, LearningGame>;
    topics: Map<string, Topic>;
    zapEnabled: boolean;
    searched: boolean;
}

class QuizRoundDisplay {
    name: string;
    topic: string;
    type: string;
}

interface MatchParams {
    quizId: string;
    startQuiz: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
    schoolLicenses: Map<string, boolean>;
    accountGroups: Map<string, AccountGroup>;
    tutorialCallback: (name: string) => void;
}

class QuizTemplateView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            quizId: props.match.params.quizId,
            quiz: null,
            subjectFilter: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
            currentQuizName: '',
            currentQuizAccountGroups: {},
            currentQuizQuestions: [],
            currentQuizSubjectId: null,
            newQuestionId: null,
            newQuizScheduled: false,
            newQuizDateTime: null,
            newQuizAccountGroups: [],
            collapse: props.match.params.quizId !== "-1",
            collapseAddQuestion: true,
            showModal: props.match.params.startQuiz != null && props.match.params.startQuiz === "true",
            showMinigameSelect: false,
            showQuestionSelect: false,
            currentSearchTopic: '',
            currentSearchTopicTags: [],
            currentSearchGameIds: [],
            currentSearchAllTopicGames: new Map(),
            currentSearchQuestionIds: [],
            currentAllTopicQuestions: new Map(),
            currentSearchSchoolGameIds: [],
            currentSearchAllTopicSchoolGames: new Map(),
            currentSearchSchoolQuestionIds: [],
            currentAllTopicSchoolQuestions: new Map(),
            questions: new Map(),
            minigames: new Map(),
            topics: new Map(),
            searched: false,
            zapEnabled: false,
        };
        this.handleAccountGroupChange = this.handleAccountGroupChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleQuizStart = this.handleQuizStart.bind(this);
        this.handleQuestionChange = this.handleQuestionChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleAddQuestion = this.toggleAddQuestion.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.persistQuestions = this.persistQuestions.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.moveItem = this.moveItem.bind(this);
        this.handleQuizDateTimeChange = this.handleQuizDateTimeChange.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.toggleMinigameSelect = this.toggleMinigameSelect.bind(this);
        this.handleSearchTopicsChange = this.handleSearchTopicsChange.bind(this);
        this.searchMinigames = this.searchMinigames.bind(this);
        this.loadMinigame = this.loadMinigame.bind(this);
        this.loadQuestion = this.loadQuestion.bind(this);
        this.toggleQuestionSelect = this.toggleQuestionSelect.bind(this);
        this.searchQuestions = this.searchQuestions.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.zapEnabledChange = this.zapEnabledChange.bind(this);
    }


    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let orderedAllGroupIds = Array.from(this.props.accountGroups.keys());
        orderedAllGroupIds.sort((id1, id2) => {
            return this.props.accountGroups.get(id1).details.getLowercaseName().localeCompare(this.props.accountGroups.get(id2).details.getLowercaseName());
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={`/quizTemplates?${queryValues}`}>All quiz templates</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.quiz != null ? this.state.currentQuizName : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Quiz Template
                        </div>
                        <p className="cardSubTitle">Create a quiz template so that you can run a live or scheduled quiz</p>
                        {this.state.quiz != null ?
                            <div>
                                {this.state.quizId !== "-1" ?
                                    <Button onClick={this.toggle} className="altButton">
                                        {this.state.collapse ? "View details" : "Close"}
                                    </Button> : null
                                }&nbsp;
                                <Button disabled={this.state.quizId === "-1" || this.state.currentQuizQuestions.length === 0} onClick={this.toggleModal} className="adminPagesButton">
                                    Start quiz
                                </Button>
                                <Collapse isOpen={!this.state.collapse}>
                                    <Form onSubmit={this.handleUpdate}>
                                        <FormGroup>
                                            <Label for="currentQuizName">Name *</Label>
                                            <Input type="text" required name="currentQuizName"
                                                onChange={(e) => this.setState({
                                                    currentQuizName: e.target.value
                                                })}
                                                value={this.state.currentQuizName} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentQuizSubjectId">Subject *</Label>
                                            <Input disabled={this.state.quizId !== "-1"} type="select" name="currentQuizSubjectId"
                                                onChange={this.handleSubjectChange}
                                                value={this.state.currentQuizSubjectId}>
                                                <option value={''}>&nbsp;</option>
                                                {Array.from(subjectIds.keys()).map(subjectId => {
                                                    let subjectName = subjectIds.get(subjectId);
                                                    return <option value={subjectId}>{subjectName}</option>;
                                                })}
                                            </Input>
                                        </FormGroup>
                                        {this.props.schoolLicenses.has('maths') && this.props.schoolLicenses.get('maths') && this.state.currentQuizSubjectId === 'maths' ?
                                            <FormGroup check>
                                                <Input type="checkbox" name="zapEnabledCheckbox" disabled={this.state.quizId != "-1"} checked={this.state.zapEnabled} onChange={this.zapEnabledChange} />
                                                <Label for="zapEnabledCheckbox">Zap Enabled <i className="fas fa-info-circle icons-info" id="help-zapEnabled-checkbox" /></Label>

                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-zapEnabled-checkbox">
                                                    Zap Enabled Setting - Can only be set at creation
                                                            <br />
                                                            Zap enabled paths have more game options but can only be played on Zap Apps
                                                        </UncontrolledTooltip>
                                            </FormGroup> : <span />}
                                        {this.state.quizId === "-1" ?
                                            <Button className="adminPagesButton"
                                                disabled={this.state.currentQuizSubjectId === "" || this.state.currentQuizSubjectId == null}
                                            >Create</Button> :
                                            <Button className="adminPagesButton">Update</Button>
                                        }
                                    </Form>
                                </Collapse>
                            </div> : <Spinner />
                        }
                    </CardBody>
                </Card>

                {this.state.quiz != null && this.state.quizId !== "-1" ?
                    <Card className="mainCard top-buffer">
                        <CardBody className="d-flex flex-column">
                            <div className="cardTitle2">Rounds</div>
                            <div>
                                {this.state.zapEnabled ?
                                    <Button onClick={this.toggleMinigameSelect} className="adminPagesButton buttonBuffer">
                                        Add game
                                    </Button> : null
                                }&nbsp;
                                <Button onClick={this.toggleQuestionSelect} className="adminPagesButton buttonBuffer" type="button">
                                    Add question
                                </Button>
                            </div>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Topic</th>
                                        <th>Type</th>
                                        <th>Title</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentQuizQuestions.map((questionDeets, questionIndex) => {
                                        let questionId = questionDeets.id;
                                        let question = new QuizRoundDisplay();
                                        if (questionDeets.type === "MINIGAME") {
                                            let minigame = this.state.minigames.get(questionId);
                                            if (minigame == null) {
                                                console.log("No minigame", questionId);
                                                return null;
                                            }
                                            question.name = minigame.title;
                                            if (minigame.topics != null && minigame.topics[0] != null && this.state.topics.get(minigame.topics[0]) != null) {
                                                question.topic = this.state.topics.get(minigame.topics[0]).name;
                                            } else {
                                                question.topic = "";
                                            }
                                            question.type = minigame.type.charAt(0).toUpperCase() + minigame.type.slice(1).toLowerCase();
                                        } else {
                                            let actualQuestion = this.state.questions.get(questionId);
                                            if (actualQuestion == null) {
                                                console.log("No question", questionId);
                                                return null;
                                            }
                                            question.name = actualQuestion.text;
                                            question.topic = actualQuestion.topics == null || actualQuestion.topics[0] == null || !this.state.topics.has(actualQuestion.topics[0]) ? "" : this.state.topics.get(actualQuestion.topics[0]).name;
                                            question.type = "Question";
                                        }
                                        return <tr key={questionId + 'tr'}>
                                            <th scope="row"
                                                key={questionId + 'th'}>{question.topic}</th>
                                            <td key={questionId + 'td1'}>{question.type}</td>
                                            <td key={questionId + 'td2'}>{question.name}</td>
                                            <td key={questionId + 'td4'}>
                                                {
                                                    questionIndex > 0 ?
                                                        <Button key={questionIndex + 'buttonMove'} color="link"
                                                            onClick={() => {
                                                                this.moveItem(questionDeets)
                                                            }}>
                                                            <i className="material-icons">arrow_upward</i>
                                                        </Button> : ""
                                                }
                                                <Button key={questionIndex + 'buttonRemove'} color="link"
                                                    onClick={async () => {
                                                        let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove this question", confirmText: "Confirm" });
                                                        if (result) {
                                                            this.removeItem(questionDeets)
                                                        }
                                                    }}>
                                                    <i className="material-icons">delete</i>
                                                </Button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table><br />

                        </CardBody></Card> : <br />
                }

                <Modal isOpen={this.state.showMinigameSelect} toggle={this.toggleMinigameSelect}>
                    <ModalHeader toggle={this.toggleMinigameSelect}>Select learning game</ModalHeader>
                    <ModalBody>
                        <div className="border rounded form-margin">
                            <TopicsSearch key={"Geoff"} countryCode={this.props.user.countryCode}
                                subjectId={this.state.currentQuizSubjectId} callback={this.handleSearchTopicsChange} />
                            <br />
                            <Button disabled={this.state.currentSearchTopic === ""} className="adminPagesButton" onClick={this.searchMinigames}>
                                Search
                            </Button>
                            <br />
                            {!this.state.searched ? <span /> :
                                <div>
                                    {this.state.currentSearchSchoolGameIds == null || this.state.currentSearchSchoolGameIds.length === 0 ? <div><b>Custom minigames</b><br />No results</div> :
                                        <div><b>Custom minigames</b><br />
                                            {this.state.currentSearchSchoolGameIds.map((gameId) => {
                                                let hideAdd = false;
                                                for (let nextEntry of this.state.currentQuizQuestions) {
                                                    if (nextEntry.type === "MINIGAME" && nextEntry.id === gameId) {
                                                        hideAdd = true;
                                                        break;
                                                    }
                                                }
                                                return <div>{this.state.currentSearchAllTopicSchoolGames.get(gameId).text} ({this.state.currentSearchAllTopicSchoolGames.get(gameId).type[0].toUpperCase()}{this.state.currentSearchAllTopicSchoolGames.get(gameId).type.slice(1).toLowerCase()})
                                        <Button color={'link'} disabled={hideAdd} onClick={() => this.handleQuestionChange(gameId, "MINIGAME")}><i
                                                        className="material-icons">add</i>
                                                    </Button>
                                                </div>
                                            })}
                                        </div>
                                    }
                                    {this.state.currentSearchGameIds == null || this.state.currentSearchGameIds.length === 0 ? <div><b>Library minigames</b><br />No results</div> :
                                        <div><b>Library minigames</b><br />
                                            {this.state.currentSearchGameIds.map((gameId) => {
                                                let hideAdd = false;
                                                for (let nextEntry of this.state.currentQuizQuestions) {
                                                    if (nextEntry.type === "MINIGAME" && nextEntry.id === gameId) {
                                                        hideAdd = true;
                                                        break;
                                                    }
                                                }
                                                return <div>{this.state.currentSearchAllTopicGames.get(gameId).text} ({this.state.currentSearchAllTopicGames.get(gameId).type[0].toUpperCase()}{this.state.currentSearchAllTopicGames.get(gameId).type.slice(1).toLowerCase()})
                                        <Button color={'link'} disabled={hideAdd} onClick={() => this.handleQuestionChange(gameId, "MINIGAME")}><i
                                                        className="material-icons">add</i>
                                                    </Button>
                                                </div>
                                            })}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.showQuestionSelect} toggle={this.toggleQuestionSelect}>
                    <ModalHeader toggle={this.toggleQuestionSelect}>Select question</ModalHeader>
                    <ModalBody>
                        <div className="border rounded form-margin">
                            <TopicsSearch key={"Jim"} countryCode={this.props.user.countryCode}
                                subjectId={this.state.currentQuizSubjectId} callback={this.handleSearchTopicsChange} />
                            <br />
                            <Button disabled={this.state.currentSearchTopic === ""} className="adminPagesButton" onClick={this.searchQuestions}>
                                Search
                            </Button>
                            <div>
                                {this.state.currentSearchSchoolQuestionIds == null || this.state.currentSearchSchoolQuestionIds.length === 0 ? this.state.searched ? <div><b>Custom questions</b><br />No results</div> : "" :
                                    <div><b>Custom questions</b><br />
                                        {this.state.currentSearchSchoolQuestionIds.map((questionId) => {
                                            let hideAdd = false;
                                            for (let nextEntry of this.state.currentQuizQuestions) {
                                                if (nextEntry.type === "QUESTION" && nextEntry.id === questionId) {
                                                    hideAdd = true;
                                                    break;
                                                }
                                            }
                                            return <div>{this.state.currentAllTopicSchoolQuestions.get(questionId).text}  ({this.state.currentAllTopicSchoolQuestions.get(questionId).type[0].toUpperCase()}{this.state.currentAllTopicSchoolQuestions.get(questionId).type.slice(1).toLowerCase()})
                                            <Button disabled={hideAdd} color={'link'} onClick={() => this.handleQuestionChange(questionId, "QUESTION")}>
                                                    <i className="material-icons">add</i>
                                                </Button>
                                            </div>
                                        })}
                                    </div>
                                }
                                {this.state.currentSearchQuestionIds == null || this.state.currentSearchQuestionIds.length === 0 ? this.state.searched ? <div><b>Library questions</b><br />No results</div> : "" :
                                    <div><b>Library questions</b><br />
                                        {this.state.currentSearchQuestionIds.map((questionId) => {
                                            let hideAdd = false;
                                            for (let nextEntry of this.state.currentQuizQuestions) {
                                                if (nextEntry.type === "QUESTION" && nextEntry.id === questionId) {
                                                    hideAdd = true;
                                                    break;
                                                }
                                            }
                                            return <div>{this.state.currentAllTopicQuestions.get(questionId).text}  ({this.state.currentAllTopicQuestions.get(questionId).type[0].toUpperCase()}{this.state.currentAllTopicQuestions.get(questionId).type.slice(1).toLowerCase()})
                                            <Button disabled={hideAdd} color={'link'} onClick={() => this.handleQuestionChange(questionId, "QUESTION")}>
                                                    <i className="material-icons">add</i>
                                                </Button>
                                            </div>
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
                    <Form onSubmit={this.handleQuizStart}>
                        <ModalHeader toggle={this.toggleModal}>Quiz settings</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="newQuizAccountGroups">Account groups</Label>
                                <Input type="select" required name="newQuizAccountGroups" multiple onChange={this.handleAccountGroupChange}>
                                    {orderedAllGroupIds.map((accountGroupId) => {
                                        return (
                                            <option value={accountGroupId} key={accountGroupId}>{this.props.accountGroups.get(accountGroupId).details.name}</option>
                                        )
                                    })
                                    }
                                </Input><br />
                            </FormGroup>
                            <FormGroup>
                                <legend>Scheduled (automated at a specific time)</legend>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" value="true" name='newQuizScheduled'
                                            checked={this.state.newQuizScheduled}
                                            onChange={(e) => this.setState({
                                                newQuizScheduled: e.target.checked
                                            })} />
                                        &nbsp;Yes
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" value="false" name='newQuizScheduled'
                                            checked={!this.state.newQuizScheduled}
                                            onChange={(e) => this.setState({
                                                newQuizScheduled: !e.target.checked
                                            })} />
                                        &nbsp;No
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="currentEventStartDateTime">Start date & time *</Label><br />
                                <DatePicker
                                    disabled={!this.state.newQuizScheduled}
                                    selected={this.state.newQuizDateTime}
                                    showTimeSelect
                                    timeIntervals={1}
                                    required
                                    dateFormat="dd MMM yyyy - HH:mm"
                                    timeFormat="HH:mm"
                                    minDate={new Date()}
                                    onChange={this.handleQuizDateTimeChange}
                                    className="form-control"
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="adminPagesButton">Start quiz</Button>{' '}
                            <Button type="button" onClick={this.toggleModal} color="secondary">Cancel</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div >
        );
    }

    zapEnabledChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            zapEnabled: e.target.checked,
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
        });
    }

    handleQuizDateTimeChange(date: Date): void {
        this.setState({
            newQuizDateTime: date,
        });
    }

    handleAccountGroupChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newQuizAccountGroups.indexOf(e.target.value);
        if (index > -1) {
            this.state.newQuizAccountGroups.splice(index, 1);
        } else {
            this.state.newQuizAccountGroups.push(e.target.value);
        }
        this.setState({});
    }

    handleSearchTopicsChange(topicId: string, topicsTags: string[][]): void {
        let newTopicTags: string[][] = topicsTags == null ? [] : topicsTags;
        this.setState({
            currentSearchTopic: topicId,
            currentSearchTopicTags: newTopicTags.slice(),
            searched: false,
        });
    }

    toggleMinigameSelect(): void {
        this.setState({
            showMinigameSelect: !this.state.showMinigameSelect,
            currentSearchGameIds: [],
            currentSearchSchoolGameIds: [],
            searched: false,
        });
    }

    toggleQuestionSelect(): void {
        this.setState({
            showQuestionSelect: !this.state.showQuestionSelect,
            currentSearchQuestionIds: [],
            currentSearchSchoolQuestionIds: [],
            searched: false,
        });
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleAddQuestion(): void {
        this.setState({ collapseAddQuestion: !this.state.collapseAddQuestion });
    }

    async searchMinigames(): Promise<void> {
        let response = await searchMinigames(this.state.currentSearchTopic, this.state.currentSearchTopicTags, this.props.user.schoolId);
        this.setState({
            searched: true,
            currentSearchGameIds: response.gameIds,
            currentSearchAllTopicGames: response.allTopicGames,
            currentSearchSchoolGameIds: response.schoolGameIds,
            currentSearchAllTopicSchoolGames: response.allTopicSchoolGames,
        });
    }

    async searchQuestions(): Promise<void> {
        let response = await searchQuestions(this.state.currentSearchTopic, this.state.currentSearchTopicTags, this.props.user.schoolId)

        this.setState({
            searched: true,
            currentSearchQuestionIds: response.questionIds,
            currentAllTopicQuestions: response.allTopicQuestions,
            currentSearchSchoolQuestionIds: response.schoolQuestionIds,
            currentAllTopicSchoolQuestions: response.allTopicSchoolQuestions,
        });
    }

    async handleQuestionChange(gameId: string, type: string): Promise<void> {
        let newQuizRound = new QuizRound();
        newQuizRound.id = gameId;
        newQuizRound.type = type;
        this.state.currentQuizQuestions.push(newQuizRound);
        this.persistQuestions(this.state.currentQuizQuestions);
        if (type === "MINIGAME") {
            await this.loadMinigame(gameId);
        } else {
            await this.loadQuestion(gameId);
        }
        this.setState({});
    }

    toggleModal(): void {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    async persistQuestions(newQuestions: QuizRound[]): Promise<void> {

        let newQuestionsData: any[];
        if (newQuestions != null) {
            newQuestionsData = [];
            for (let nextRound of newQuestions) {
                newQuestionsData.push(nextRound.toFirebase());
            }
        }

        const itemsRef = firebase.firestore().doc(`quizTemplates/${this.state.quizId}`);
        try {
            await itemsRef.update({
                questionIds: newQuestionsData
            });
            this.state.quiz.questionIds = newQuestions;
            this.setState({});
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async handleSubjectChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let subjectId = e.target.value;
        let likelyZapPath = this.props.schoolLicenses.has('maths') && this.props.schoolLicenses.get('maths') && subjectId === 'maths';
        this.setState({
            currentQuizSubjectId: subjectId,
            zapEnabled: likelyZapPath,
        });
    }

    async handleQuizStart(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let accountIds: string[] = [];
        this.props.tutorialCallback('quizTemplates');
        this.state.newQuizAccountGroups.forEach((accountGroupId) => {
            Array.from(this.props.accountGroups.get(accountGroupId).accounts.keys()).forEach((accountId) => {
                let nextAccountDeets = this.props.accountGroups.get(accountGroupId).accounts.get(accountId);
                if (nextAccountDeets.member != null && nextAccountDeets.member) {
                    accountIds.push(accountId);
                }
            });
        });

        let accountObject = new Map<string, boolean>();
        accountIds.forEach((accountId) => {
            accountObject.set(accountId, true);
        });

        if (accountIds.length <= 0) {
            this.props.snackbar("Failed - no student accounts");
            return;
        }

        const quizRef = firebase.database().ref('schoolQuizzes/quizzes/');
        let quizInstance = new Quiz();
        quizInstance.details.name = this.state.currentQuizName;
        quizInstance.details.subjectId = this.state.currentQuizSubjectId;
        quizInstance.details.type = this.state.zapEnabled ? "tap" : "";
        quizInstance.details.currentQuestionIndex = -1;
        quizInstance.details.currentQuestionActive = false;
        quizInstance.details.questionIds = this.state.quiz.questionIds;
        quizInstance.details.schoolId = this.props.user.schoolId;
        quizInstance.schoolId = this.props.user.schoolId;
        quizInstance.accounts = accountObject;

        if (this.state.newQuizScheduled) {
            this.state.newQuizDateTime.setSeconds(0, 0);
            quizInstance.details.schedule = this.state.newQuizDateTime.toISOString();
        }
        let promise = quizRef.push(quizInstance.toFirebase());
        let newPostKey = promise.key;
        await promise;

        this.props.history.push(`/quizzes/${newPostKey}`);
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let quiz = this.state.quiz;
        quiz.name = this.state.currentQuizName;
        quiz.schoolId = this.props.user.schoolId;
        quiz.subjectId = this.state.currentQuizSubjectId;
        quiz.deactivated = false;
        quiz.zapEnabled = this.state.zapEnabled;
        quiz.updatedBy = this.props.user.accountId;
        console.log(quiz);
        if (this.state.quizId === "-1") {
            try {
                const quizRef = firebase.firestore().collection('quizTemplates');
                let ref = await quizRef.add(quiz.toFirebase());
                let newPostKey = ref.id;
                this.setState({
                    quiz: quiz,
                    quizId: newPostKey,
                    collapse: true,
                });
                this.props.history.replace(`/quizTemplates/${newPostKey}/false`);
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }
            let topics = await this.loadTopics(quiz.subjectId);
            this.setState({
                topics: topics,
            });
        } else {
            const itemsRef = firebase.firestore().doc(`quizTemplates/${this.state.quizId}`);
            try {
                await itemsRef.set(quiz.toFirebase());
                this.setState({
                    quiz: quiz,
                    collapse: true,
                });
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        }
    }

    async componentDidMount(): Promise<void> {
        try {
            if (this.state.quizId !== "-1") {
                const itemsRef = firebase.firestore().doc(`quizTemplates/${this.state.quizId}`);
                let quizSnapshot = await itemsRef.get();
                let quizData = quizSnapshot.data();
                let quiz = QuizTemplate.fromFirebase(quizData);

                let currentQuestions = quiz.questionIds != null ? quiz.questionIds : [];
                let questionPromises: Promise<any>[] = [];
                currentQuestions.forEach((questionDeets) => {
                    console.log("Next question deets", questionDeets);
                    let questionId = questionDeets.id;
                    if (questionId != null) {
                        if (questionDeets.type === "MINIGAME") {
                            console.log("Have a minigame");
                            questionPromises.push(this.loadMinigame(questionId));
                        } else {
                            questionPromises.push(this.loadQuestion(questionId));
                        }
                    }
                });

                Promise.all(questionPromises).then(() => {
                    this.setState({});
                });

                let topics = await this.loadTopics(quiz.subjectId);

                this.setState({
                    quiz: quiz,
                    topics: topics,
                    currentQuizName: quiz.name,
                    currentQuizQuestions: currentQuestions,
                    currentQuizSubjectId: quiz.subjectId,
                    zapEnabled: quiz.zapEnabled,
                });
            } else {
                let likelyZapPath = this.props.schoolLicenses.has('maths') && this.props.schoolLicenses.get('maths') && this.state.subjectFilter === 'maths';
                let newQuizTemplate = new QuizTemplate();
                newQuizTemplate.questionIds = [];
                this.setState({
                    quiz: newQuizTemplate,
                    currentQuizName: "",
                    currentQuizQuestions: [],
                    currentQuizSubjectId: this.state.subjectFilter,
                    zapEnabled: likelyZapPath,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    async loadTopics(subjectId: string): Promise<Map<string, Topic>> {
        const topicsRef = firebase.firestore().collection(`learningTopics`).where('subjectId', '==', subjectId);
        let topicSnapshot = await topicsRef.get();
        let topics = new Map<string, Topic>();
        topicSnapshot.forEach((docSnapshot) => {
            let nextTopics = docSnapshot.data();
            Object.keys(nextTopics.topics).forEach((topicId) => {
                let nextTopicData = nextTopics.topics[topicId];
                let nextTopic = Topic.fromFirebase(nextTopicData);
                topics.set(topicId, nextTopic);
            });
        });

        return topics;
    }

    async loadMinigame(minigameId: string): Promise<void> {
        let snapshot = await firebase.firestore().doc(`learningGames/${minigameId}`).get();
        this.state.minigames.set(minigameId, LearningGame.fromFirebase(snapshot.data()));
    }

    async loadQuestion(questionId: string): Promise<void> {
        let snapshot = await firebase.firestore().doc(`learningQuestions/${questionId}`).get();
        this.state.questions.set(questionId, Question.fromFirebase(snapshot.data()));
    }

    async removeItem(questionDeets: QuizRound): Promise<void> {
        try {
            let questionIndex = this.state.currentQuizQuestions.indexOf(questionDeets);
            this.state.currentQuizQuestions.splice(questionIndex, 1);
            this.persistQuestions(this.state.currentQuizQuestions);
        } catch (error) {
            console.log(error);
            this.props.snackbar("Remove failed");
        }
    }

    async moveItem(questionDeets: QuizRound): Promise<void> {
        try {
            let questionIndex = this.state.currentQuizQuestions.indexOf(questionDeets);
            this.state.currentQuizQuestions.splice(questionIndex, 1);
            this.state.currentQuizQuestions.splice(questionIndex - 1, 0, questionDeets);
            this.persistQuestions(this.state.currentQuizQuestions);
        } catch (error) {
            console.log(error);
            this.props.snackbar("Remove failed");
        }
    }
}

export default QuizTemplateView;
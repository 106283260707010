import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import {
    Card, CardBody, Col, Row, Spinner,
    CardHeader, Button, BreadcrumbItem, Breadcrumb, Alert
} from 'reactstrap';
import { License, LicenseActivity, LicenseApp, LicenseAssignment, LicenseAssignments, LICENSE_TYPE } from './data/license';
import { User } from './data/user';
import moment from 'moment';

interface IState {
    licenses: Map<string, License>;
    assignments: Map<string, LicenseAssignments>;
    appIdsOrdered: string[];
    licenseIdsOrdered: string[];
    appLicenseIdsOrdered: string[];
    appNames: Map<string, LicenseApp>;
    disableSpinner: string;
}

interface IProps {
    schoolLicenses: Map<string, LicenseActivity>;
    user: User;
}

class LicensesView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            licenses: new Map(),
            assignments: new Map(),
            appIdsOrdered: [],
            licenseIdsOrdered: [],
            appLicenseIdsOrdered: [],
            appNames: new Map(),
            disableSpinner: '',
        };
        this.createCard = this.createCard.bind(this);
        this.enableLicense = this.enableLicense.bind(this);
        this.disableLicense = this.disableLicense.bind(this);
    }

    createCard(nextLicenseId: string): JSX.Element {
        let nextLicense = this.state.licenses.get(nextLicenseId);
        let usersString;
        let userCount = 0;
        let purchased = false;
        let schoolLicense = this.props.schoolLicenses.get(nextLicenseId);
        let trialString = null;
        if (schoolLicense != null && schoolLicense) {
            purchased = true;
        }
        if (nextLicense.type === LICENSE_TYPE.PER_ACCOUNT) {
            if (nextLicense.pricing == null) {
                usersString = "This license is free for unlimited users";
            } else {
                usersString = "This license is pay per user";
                if (nextLicense.trialLength != null) {
                    if (schoolLicense == null || schoolLicense.trialStart == null) {
                        usersString += ` and has a ${nextLicense.trialLength} day free trial`;
                    } else {
                        let endDate = moment(schoolLicense.trialStart);
                        let currentDate = moment();
                        let duration = moment.duration(currentDate.diff(endDate));
                        let remainingDuration = Math.ceil(90 - duration.asDays());
                        if (remainingDuration > 0) {
                            usersString += ` and has ${remainingDuration} days of free trial remaining`;
                        }
                    }
                }
            }
            if (!this.state.assignments.has(nextLicenseId)) {
                userCount = 0;
            } else {
                let nextLicenseAssignments = this.state.assignments.get(nextLicenseId);
                for (let nextAccountId of nextLicenseAssignments.assignments.keys()) {
                    let nextAssignment = nextLicenseAssignments.assignments.get(nextAccountId);
                    if (nextAssignment != null && nextAssignment.length > 0 && nextAssignment[nextAssignment.length - 1].end == null) {
                        userCount++;
                    }
                }
            }
        } else {
            usersString = "This license is a single payment";
            userCount = 0;
        }

        return <Card style={{ width: "100%" }}>
            <CardHeader style={{ backgroundColor: purchased ? '#7036a4' : null, color: purchased ? '#fff' : null }}>{nextLicense.name}</CardHeader>
            <CardBody className="d-flex flex-column">
                <div>
                    <Row>
                        <Col key={`column1-${nextLicenseId}`} md="4">
                            <Row>
                                {nextLicense.screenshots.map((screenshotUrl) => {
                                    return <Col>
                                        <img src={screenshotUrl} height="150px" alt="" />
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col key={`column2-${nextLicenseId}`} md="6">
                            <div>{nextLicense.description}</div>
                            <br />
                            <div>{usersString}</div>
                            <br />
                            {purchased ?
                                <div>You have <b>{userCount}</b> account{userCount != 1 ? "s" : ""} assigned to this license</div> : <span />
                            }
                        </Col>
                        <Col key={`column3-${nextLicenseId}`} md="2">
                            {!purchased ? <Button className="adminPagesRedButton" type="button" onClick={() => this.enableLicense(nextLicenseId)}>
                                Enable
                            </Button> : <Button disabled={this.state.disableSpinner !== ""} className="adminPagesRedButton" type="button" onClick={() => this.disableLicense(nextLicenseId)}>
                                    {this.state.disableSpinner === nextLicenseId ? <span><Spinner size="sm"></Spinner>&nbsp;Disabling...</span> : "Disable"}
                                </Button>}
                            <br /><br />
                            <div className="mt-auto">
                                <Link to={`/licenses/${nextLicenseId}`}>
                                    <Button outline className="altButton" style={{float:'right'}} type="button">
                                        {purchased ? "Manage" : "Details"}
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    }

    render(): JSX.Element {
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All network licenses</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Network licenses</div>
                        <p className="cardSubTitle">Choose which parts of the Life Ninja network to make available to your organisation</p>
                        {this.state.licenseIdsOrdered.map((licenseId) => {
                            return <div>
                                <div>
                                    {this.createCard(licenseId)}
                                </div>
                                <br />
                            </div>
                        })}
                    </CardBody>
                </Card>
            </div>
        );
    }

    async enableLicense(licenseId: string): Promise<void> {
        await firebase.firestore().doc(`schoolLicenses/${this.props.user.schoolId}`).set({
            licenseData: {[licenseId]: {enabled: true,},},
        }, { merge: true });
        this.props.schoolLicenses.get(licenseId).enabled = true;
        this.setState({
        });
    }

    async disableLicense(licenseId: string): Promise<void> {
        this.setState({
            disableSpinner: licenseId
        })
        await firebase.firestore().doc(`schoolLicenses/${this.props.user.schoolId}`).set({
            licenseData: {[licenseId]: {enabled: false,},},
        }, { merge: true });

        let accountsToRemove = !this.state.assignments.has(licenseId) ? [] : Array.from(this.state.assignments.get(licenseId).assignments.keys());
        let removeAccountLicenses = firebase.functions().httpsCallable('removeAccountLicenses');
        await removeAccountLicenses({ "schoolId": this.props.user.schoolId, "accountIds": accountsToRemove, "licenseId": licenseId });
        this.props.schoolLicenses.get(licenseId).enabled = false;
        this.state.assignments.delete(licenseId);
        this.setState({
            disableSpinner: ""
        });
    }

    async componentDidMount(): Promise<void> {
        try {
            let licensesSnapshot = await firebase.firestore().doc('generalConfig/licenseDetails').get();
            let licenseDetails = licensesSnapshot.data();
            let newLicensesState = new Map<string, License>();
            let newAppNames = new Map<string, LicenseApp>();
            for (let nextLicenseId in licenseDetails.licenses) {
                let nextLicense = licenseDetails.licenses[nextLicenseId];
                newLicensesState.set(nextLicenseId, License.fromFirebase(nextLicense));
            }
            for (let nextAppId in licenseDetails.apps) {
                let nextApp = licenseDetails.apps[nextAppId];
                newAppNames.set(nextAppId, LicenseApp.fromFirebase(nextApp));
            }

            // let schoolLicensesSnapshot = await firebase.firestore().doc(`schoolLicenses/${this.props.user.schoolId}`).get();
            // let schoolLicenses = schoolLicensesSnapshot.data();
            // let newSchoolLicensesState = new Map<string, boolean>();
            // for (let nextSchoolLicenseId in schoolLicenses) {
            //     let nextSchoolLicense = schoolLicenses[nextSchoolLicenseId];
            //     newSchoolLicensesState.set(nextSchoolLicenseId, nextSchoolLicense);
            // }

            let newAppIdsOrdered = Array.from(newAppNames.keys());
            newAppIdsOrdered.sort((a, b) => {
                return newAppNames.get(a).name.localeCompare(newAppNames.get(b).name);
            });

            let newLicenseIdsOrdered = Array.from(newLicensesState.keys());
            newLicenseIdsOrdered.sort((a, b) => {
                if (this.props.schoolLicenses.has(a) && this.props.schoolLicenses.get(a) && (!this.props.schoolLicenses.has(b) || !this.props.schoolLicenses.get(b))) {
                    return -1;
                } else if (this.props.schoolLicenses.has(b) && this.props.schoolLicenses.get(b) && (!this.props.schoolLicenses.has(a) || !this.props.schoolLicenses.get(a))) {
                    return 1;
                } else {
                    return newLicensesState.get(a).name.localeCompare(newLicensesState.get(b).name);
                }
            });

            let promises: Promise<any>[] = [];
            for (let nextLicenseId of newLicenseIdsOrdered) {
                promises.push(firebase.firestore().doc(`schoolLicenses/${this.props.user.schoolId}/licenses/${nextLicenseId}`).get());
            }

            await Promise.all(promises);

            let newAssignmentsState = new Map<string, LicenseAssignments>();
            for (let nextDocumentFuture of promises) {
                let nextAssignments = await nextDocumentFuture;
                if (nextAssignments != null && nextAssignments.data() != null) {
                    newAssignmentsState.set(nextAssignments.id, LicenseAssignments.fromFirebase(nextAssignments.data()));
                }
            }

            this.setState({
                licenses: newLicensesState,
                assignments: newAssignmentsState,
                appNames: newAppNames,
                appIdsOrdered: newAppIdsOrdered,
                licenseIdsOrdered: newLicenseIdsOrdered,
            });
        } catch (error) {
            console.log(error);
        }
    }
}

export default LicensesView;
import firebase from '../firebase';
import { objectToClassConverter } from './database_object';

export class Fundraiser {

    constructor() {
        this.details = new FundraiserDetails();
    }

    details: FundraiserDetails = null;
    orgTotal: number = null;
    orgDonationTotal: number = null;

    static fromFirebase(data: any): Fundraiser {
        let newFundraiser = new Fundraiser();
        if (data == null) {
            return newFundraiser;
        }
        objectToClassConverter(data, newFundraiser, [], ["details"]);

        newFundraiser.details = FundraiserDetails.fromFirebase(data.details);

        return newFundraiser;
    }
}

export class FundraiserDetails {
    name: string = null;
    additional: string = null;

    static fromFirebase(data: any): FundraiserDetails {
        let newFundraiserDetails = new FundraiserDetails();
        if (data == null) {
            return newFundraiserDetails;
        }
        objectToClassConverter(data, newFundraiserDetails);

        return newFundraiserDetails;
    }
}

// export class FundraisingEarn {
//     accountId: string;
//     completedTimestamp: number;
//     stars: number;
// }
import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Card, CardBody, Modal, ModalHeader, ModalBody, FormGroup, Label, Input } from 'reactstrap';
import { User } from './data/user';
import { Account, AccountMinimised } from './data/accounts';

interface IState {
    adminAccounts: Map<string, Account>,
    removeModal: string;
    collapseAdminConvert: boolean;
    newAdminConvert: string;
}

interface IProps {
    user: User;
    accountsMinimised: Map<string, AccountMinimised>;
    snackbar: (text?: string) => void;
}

class AdminAccountsView extends Component<IProps, IState> {
    accountsRef: firebase.database.Query;

    constructor(props: IProps) {
        super(props);
        this.state = {
            adminAccounts: new Map(),
            removeModal: null,
            collapseAdminConvert: false,
            newAdminConvert: null,
        };

        this.removeItem = this.removeItem.bind(this);
        this.openRemoveModal = this.openRemoveModal.bind(this);
        this.closeRemoveModal = this.closeRemoveModal.bind(this);
        this.toggleAdminConvert = this.toggleAdminConvert.bind(this);
    }

    render(): JSX.Element {

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All admin-enabled user accounts</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Admin-enabled user accounts</div>
                        <p className="cardSubTitle">These are the user accounts with administrator permissions.</p>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Additional</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(this.state.adminAccounts.keys()).map((adminAccountId) => {
                                    let adminAccount = this.state.adminAccounts.get(adminAccountId);
                                    return <tr>
                                        <th scope="row"><Link to={'/adminAccounts/' + adminAccountId}>{adminAccount.name != null && adminAccount.name.trim() !== "" ? adminAccount.name : "Unset"}</Link></th>
                                        <td>{adminAccount.additional}</td>
                                        <td>{this.props.user.permissions.includes('ADMIN_MANAGER') ?
                                            <Button color="link" onClick={() => {
                                                this.openRemoveModal(adminAccountId)
                                            }}><i className="material-icons">delete</i></Button>
                                            : <span />
                                        }
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        {this.props.user.permissions.includes('ADMIN_MANAGER') ||
                            this.props.user.permissions.includes('ADMIN_ADMINISTRATOR') ||
                            this.props.user.permissions.includes('ACCOUNT_ADMINISTRATOR') ||
                            this.props.user.permissions.includes('MODULE_ADMINISTRATOR') ||
                            this.props.user.permissions.includes('MESSAGE_ADMINISTRATOR') ||
                            this.props.user.permissions.includes('EVENT_ADMINISTRATOR') ||
                            this.props.user.permissions.includes('REPORTING_ADMINISTRATOR') ?
                            <div>
                                <Button onClick={this.toggleAdminConvert} className="adminPagesButton" type="button">
                                    Admin enable existing account
                        </Button>&nbsp;
                    </div> : <span />
                        }
                        <br /><br />
                        <Modal isOpen={this.state.removeModal != null} toggle={this.closeRemoveModal}>
                            <ModalHeader toggle={this.closeRemoveModal}>Remove admin</ModalHeader>
                            <ModalBody>
                                <p>This will disable their admin permissions, but will not remove their user account.</p>
                                <Button color="success" onClick={this.removeItem}>Ok</Button>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.collapseAdminConvert} toggle={this.toggleAdminConvert}>
                            <ModalHeader toggle={this.toggleAdminConvert}>Admin enable account</ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label for="newAdminConvert">Account</Label>
                                    <Input type="select" required name="newAdminConvert"
                                        onChange={(e) => this.setState({
                                            newAdminConvert: e.target.value
                                        })}>
                                        <option>&nbsp;</option>
                                        {Array.from(this.props.accountsMinimised.keys()).map((key) => {
                                            return (
                                                <option value={key}
                                                    key={key}>{this.props.accountsMinimised.get(key).name + " - " + this.props.accountsMinimised.get(key).additional}</option>
                                            )
                                        })
                                        }
                                    </Input><br />
                                </FormGroup>
                                <Link to={`/adminAccounts/${this.state.newAdminConvert}`}>
                                    <Button className="adminPagesButton" type="button">
                                        Admin enable
                            </Button>
                                </Link>
                            </ModalBody>
                        </Modal>
                    </CardBody>
                </Card>
            </div>
        );
    }

    toggleAdminConvert(): void {
        this.setState({ collapseAdminConvert: !this.state.collapseAdminConvert });
    }

    openRemoveModal(adminAccountId: string): void {
        this.setState({
            removeModal: adminAccountId
        });
    }

    closeRemoveModal(): void {
        this.setState({
            removeModal: null
        });
    }

    async componentDidMount(): Promise<void> {
        this.accountsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts`).orderByChild("isAdmin").equalTo(true);
        try {
            let snapshot = await this.accountsRef.once('value');
            let allAccounts = snapshot.val();
            let newAdminAccountsState = new Map<string, Account>();
            for (let nextAccountId in allAccounts) {
                let nextAccount = Account.fromFirebase(allAccounts[nextAccountId]);
                if (!nextAccount.deactivated) {
                    newAdminAccountsState.set(nextAccountId, nextAccount);
                }
            }
            this.setState({
                adminAccounts: newAdminAccountsState,
            });
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount(): void {
        this.accountsRef.off();
    }

    async removeItem(): Promise<void> {
        const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/admins/${this.state.removeModal}/active`);
        try {
            await accountRef.set(false);

            this.state.adminAccounts.delete(this.state.removeModal);
            this.setState({ removeModal: null });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }
}

export default AdminAccountsView;
import React, { Component } from "react";
import firebase from './firebase';
import queryString from 'query-string';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    UncontrolledTooltip,
} from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import TopicsSelector from "./ninja_components/topics_selector";
import { User, USER_ROLE } from "./data/user";
import { soundNames, subjectIds } from "./Prettifier";
import { TIME_MULTIPLIER, TraceGame, TraceGameDrawingOffset } from "./data/learning_games"
import { StoreLearningGame } from "./helpers/topic_services";
import Canvas from "./ninja_components/letter_trace_canvas";

interface IState {
    worldId: string;
    worldName: string;
    gameId: string;
    pathId: string;
    pathName: string;
    subjectFilter: string,
    traceGame: TraceGame;
    currentTraceGameTitle: string;
    currentTraceGameSubjectId: string;
    currentTraceGameSchoolId: string;
    currentTraceGameTopics: string[];
    currentTraceGameTags: Map<string, string[]>;
    currentTraceGameInstructions: string;
    currentTraceGameAudioInstructions: string;
    timeMultiplier: TIME_MULTIPLIER,
    currentTraceGameDrawing: TraceGameDrawingOffset[],
    user: User,
}

interface MatchParams {
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class LearningTraceGame extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            gameId: props.match.params.gameId,
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            traceGame: null,
            currentTraceGameTitle: '',
            currentTraceGameSubjectId: '',
            currentTraceGameSchoolId: '',
            currentTraceGameInstructions: '',
            currentTraceGameTags: new Map(),
            currentTraceGameTopics: [],
            currentTraceGameAudioInstructions: '',
            currentTraceGameDrawing: null,
            timeMultiplier: TIME_MULTIPLIER.moderate,
            user: props.user,
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.dealWithChange = this.dealWithChange.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.drawingCallback = this.drawingCallback.bind(this);
        this.clearCallback = this.clearCallback.bind(this);
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.currentTraceGameSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All Minigames</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem>
                            : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem>
                            : null}
                        <BreadcrumbItem active>Trace Game</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Trace Game</div>
                        <p>The trace game requires the user to trace a letter or character following the correct flow.</p>
                        <div>
                            <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                <fieldset disabled={!editable}>
                                    <FormGroup>
                                        <Label for="currentTraceGameTitle">Title *</Label>
                                        <Input maxLength={30} type="text" required name="currentTraceGameTitle" value={this.state.currentTraceGameTitle} onChange={(e) => this.setState({
                                            currentTraceGameTitle: e.target.value
                                        })}></Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentTraceGameSubjectId">Subject *</Label>
                                        <Input disabled={this.state.worldId != null || this.state.gameId !== "-1"} type="select" name="currentTraceGameSubjectId"
                                            onChange={(e) => this.setState({
                                                currentTraceGameSubjectId: e.target.value
                                            })}
                                            value={this.state.currentTraceGameSubjectId}>
                                            <option value={''}>&nbsp;</option>
                                            {Array.from(subjectIds.keys()).map(subjectId => {
                                                let subjectName = subjectIds.get(subjectId);
                                                return <option value={subjectId}>{subjectName}</option>
                                            })}
                                        </Input>
                                    </FormGroup>
                                    {this.state.currentTraceGameSubjectId === '' ? <div></div> :
                                        <TopicsSelector subject={this.state.currentTraceGameSubjectId} countryCode={this.state.user.countryCode} currentTopics={this.state.currentTraceGameTopics} currentTopicsTags={this.state.currentTraceGameTags} callback={this.handleTopicsChange} />
                                    }
                                    <FormGroup>
                                        <label>
                                            Game speed <i className="fas fa-info-circle icons-info" id="help-learning-timemultiplier" />
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-learning-timemultiplier">
                                                Control how fast or slow the game will play.
                                                Overall paths can have speed controls and the game speed will work within the context of the path speed control.
                                                For example, a game with a 'fast' speed on a path with a 'slow' speed will play at 'moderate' speed.
                                                Use this to allow developing or advanced students to play at a speed that suits them.
                                        </UncontrolledTooltip>
                                        </label>
                                        <Input type="select" value={this.state.timeMultiplier} onChange={this.dealWithChange}>
                                            <option value="fastest">Fastest</option>
                                            <option value="fast">Fast</option>
                                            <option value="moderate">Moderate</option>
                                            <option value="slow">Slow</option>
                                            <option value="slowest">Slowest</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Drawing to trace</label>
                                        {this.state.currentTraceGameDrawing != null ?
                                            <Canvas points={this.state.currentTraceGameDrawing} drawCallback={this.drawingCallback} clearCallback={this.clearCallback} />
                                            : <span />
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentTraceGameInstructions">Instructions <i className="fas fa-info-circle icons-info" id="help-learningTraceGame-instructions" /></Label>
                                        <Input type="textarea" name="currentTraceGameInstructions" onChange={(e) => this.setState({
                                            currentTraceGameInstructions: e.target.value
                                        })} value={this.state.currentTraceGameInstructions} />
                                        <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningTraceGame-instructions">
                                            The Instructions shown to the player before they start the Trace Game.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentTraceGameAudioInstructions">Audio Instruction</Label>
                                        <Input type="select" name="currentTraceGameAudioInstructions"
                                            onChange={(e) => this.setState({
                                                currentTraceGameAudioInstructions: e.target.value
                                            })}
                                            value={this.state.currentTraceGameAudioInstructions}>
                                            <option value={''}>&nbsp;</option>
                                            {Array.from(soundNames.keys()).map((key) => {
                                                let name = soundNames.get(key);
                                                return (
                                                    <option value={key} key={key}>{name}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                    {editable ? this.state.gameId === "-1" ?
                                        <Button disabled={this.state.currentTraceGameSubjectId === ''} className="adminPagesButton">Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                    }
                                </fieldset>
                            </Form>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    drawingCallback(nextOffset: TraceGameDrawingOffset) {
        this.state.currentTraceGameDrawing.push(nextOffset);
        this.setState({
        });
    }

    clearCallback() {
        this.setState({
            currentTraceGameDrawing: []
        });
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentTraceGameTopics: topics,
            currentTraceGameTags: topicsTags
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId,
        });
    }

    dealWithChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let traceGame = this.state.traceGame;
        traceGame.title = this.state.currentTraceGameTitle;
        traceGame.drawing = this.state.currentTraceGameDrawing;
        traceGame.subjectId = this.state.currentTraceGameSubjectId;
        traceGame.topics = this.state.currentTraceGameTopics;
        traceGame.topicTags = this.state.currentTraceGameTags;
        traceGame.instructions = this.state.currentTraceGameInstructions;
        traceGame.audioInstructions = this.state.currentTraceGameAudioInstructions;
        traceGame.timeMultiplier = this.state.timeMultiplier;
        traceGame.schoolId = this.state.user.schoolId;
        traceGame.type = 'TRACE';
        let newTraceGame = false;
        let gameId = this.state.gameId;
        if (this.state.gameId === "-1") {
            newTraceGame = true;
            gameId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                traceGame.schoolId = this.state.user.schoolId;
            }
        }
        try {
            await StoreLearningGame(newTraceGame, traceGame, gameId, this.state.pathId, this.state.currentTraceGameSchoolId);

            this.props.history.replace(`/learningTrace/${gameId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save Failed");
        }
        this.setState({
            gameId: gameId
        })
    }

    async componentDidMount(): Promise<void> {
        try {
            if (this.state.gameId !== "-1") {
                let traceGameSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
                let traceGameData = traceGameSnapshot.data();
                let traceGame = TraceGame.fromFirebase(traceGameData);
                this.setState({
                    traceGame: traceGame,
                    currentTraceGameTitle: traceGame.title,
                    currentTraceGameSubjectId: traceGame.subjectId,
                    currentTraceGameTopics: traceGame.topics,
                    currentTraceGameTags: traceGame.topicTags,
                    currentTraceGameInstructions: traceGame.instructions,
                    currentTraceGameAudioInstructions: traceGame.audioInstructions,
                    currentTraceGameDrawing: traceGame.drawing,
                    currentTraceGameSchoolId: traceGame.schoolId == null ? null : traceGame.schoolId,
                    timeMultiplier: traceGame.timeMultiplier != null ? traceGame.timeMultiplier : TIME_MULTIPLIER.moderate,
                });
            } else {
                let subjectId = '';
                if (this.state.worldId != null) {
                    const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                    let worldSnapshot = await worldRef.get();
                    let world = worldSnapshot.data();
                    subjectId = world.subject;
                } else {
                    //@ts-ignore
                    subjectId = this.state.subjectFilter;
                }
                this.setState({
                    traceGame: new TraceGame(),
                    currentTraceGameTitle: '',
                    currentTraceGameInstructions: '',
                    currentTraceGameSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                    currentTraceGameAudioInstructions: '',
                    currentTraceGameSubjectId: subjectId,
                    currentTraceGameTopics: [],
                    currentTraceGameDrawing: [],
                    timeMultiplier: TIME_MULTIPLIER.moderate,
                    currentTraceGameTags: new Map<string, string[]>(),
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

};
export default LearningTraceGame;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";
import { User } from './data/user';
import { AccountGroup, AccountGroupLink, AccountMinimised } from './data/accounts';
import { RouteComponentProps } from 'react-router';

interface IState {
    accountGroups: Map<string, AccountGroup>,
    accounts: string[],
    listSize: number;
    searchText: string;
    tempSearchText: string;
}

interface MatchParams {
    accountGroupId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
}

class AccountGroupsView extends Component<IProps, IState> {
    timeout: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);
        this.state = {
            accountGroups: new Map<string, AccountGroup>(),
            accounts: [],
            listSize: 10,
            searchText: '',
            tempSearchText: '',
        };
        this.removeItem = this.removeItem.bind(this);
        this.doSearch = this.doSearch.bind(this);
    }

    render(): JSX.Element {

        let accountGroupList: string[] = [];
        if (this.state.searchText != null && this.state.searchText !== '') {
            for (let [accountGroupId, accountGroup] of this.state.accountGroups) {
                if (accountGroup.details.getLowercaseName().indexOf(this.state.searchText) !== -1) {
                    accountGroupList.push(accountGroupId);
                }
            }
        } else {
            accountGroupList = Array.from(this.state.accountGroups.keys());
        }

        accountGroupList.sort((acA, acB) => {
            return this.state.accountGroups.get(acA).details.getLowercaseName().localeCompare(this.state.accountGroups.get(acB).details.getLowercaseName());
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All account groups</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Account groups</div>
                        <p className="cardSubTitle">Account groups link groups of users to messages, events, learning modules etc and also aid with reporting</p>
                        <div>
                            <Link to={'/accountGroups/-1'}>
                                <Button className="adminPagesButton" type="button">
                                    Add account group
                        </Button>
                            </Link>
                        </div>
                        <br />
                        <div>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><span className="material-icons">search</span></InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder={"Search account groups"} type="text" name="tempSearchText" onChange={evt => this.doSearch(evt)} value={this.state.tempSearchText} />
                            </InputGroup>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Additional</th>
                                    <th># Accounts</th>
                                    <th>Account names</th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {accountGroupList.slice(0, this.state.listSize).map((accountGroupId: string) => {
                                    let accountGroup: AccountGroup = this.state.accountGroups.get(accountGroupId);
                                    let accountCount = 0;
                                    Array.from(accountGroup.accounts.keys()).forEach((accountId) => {
                                        let account = accountGroup.accounts.get(accountId);
                                        if (account.member) {
                                            accountCount++;
                                        }
                                    });
                                    return <tr>
                                        <th scope="row"><Link to={'accountGroups/' + accountGroupId}>{accountGroup.details != null ? accountGroup.details.name : "<Not named>"}</Link></th>
                                        <td>{accountGroup.details != null ? accountGroup.details.additional : ""}</td>
                                        <td>{accountCount}</td>
                                        <td>{this.createAccountString(accountGroup)}</td>
                                        <td>
                                            <Button color="link" onClick={async () => {
                                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this account group", confirmText: "Confirm" });
                                                if (result) {
                                                    this.removeItem(accountGroupId)
                                                }
                                            }}><i className="material-icons">delete</i></Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        {this.state.listSize < Array.from(this.state.accountGroups.keys()).length ?
                            <div>
                                <p>Showing {this.state.listSize} of {Array.from(this.state.accountGroups.keys()).length}</p>
                                <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.setState({
                                            listSize: this.state.listSize + 10
                                        })
                                    }}>
                                    Show more
                        </Button>&nbsp;&nbsp;
                        <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.setState({
                                            listSize: 999999
                                        })
                                    }}>
                                    Show all
                        </Button>
                            </div> :
                            <div>
                                <p>Showing {Array.from(this.state.accountGroups.keys()).length}</p>
                            </div>
                        }
                        <br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    doSearch(e: React.ChangeEvent<HTMLInputElement>): void {
        let newSearchText = e.target.value; // this is the search text
        if (this.timeout) clearTimeout(this.timeout);
        this.setState({
            tempSearchText: newSearchText.toLowerCase(),
        });
        this.timeout = setTimeout(() => {
            this.setState({
                searchText: this.state.tempSearchText,
            });
        }, 300);
    }

    createAccountString(accountGroup: AccountGroup): string {
        let accountsString = "";
        let i = 0;
        let firstEntryAdded = false;
        let remainingEntries = false;
        for (; i < Array.from(accountGroup.accounts.keys()).length; i++) {
            if (!this.props.accountsMinimised.has(Array.from(accountGroup.accounts.keys())[i])) {
                continue;
            }
            let nextAddition = "";
            if (firstEntryAdded) {
                nextAddition = ", ";
            } else {
                firstEntryAdded = true;
            }
            nextAddition += this.props.accountsMinimised.get(Array.from(accountGroup.accounts.keys())[i]).name;
            if (accountsString.length + nextAddition.length > 60) {
                remainingEntries = true;
                break;
            }
            accountsString += nextAddition;
        }
        if (remainingEntries) {
            accountsString += "...";
        }

        return accountsString;
    }

    async componentDidMount(): Promise<void> {
        const accountGroupsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups`).orderByChild('deactivated').equalTo(null);
        let snapshot = await accountGroupsRef.once('value');
        console.log("snapshot", snapshot);
        let accountGroups = snapshot.val();
        let newState = new Map<string, AccountGroup>();
        for (let nextAccountGroupId in accountGroups) {
            let nextAccountGroup = AccountGroup.fromFirebase(accountGroups[nextAccountGroupId]);
            if (nextAccountGroup.accounts == null) {
                nextAccountGroup.accounts = new Map<string, AccountGroupLink>();
            }
            newState.set(nextAccountGroupId, nextAccountGroup);
        }
        this.setState({
            accountGroups: newState,
        });
    }

    async removeItem(accountGroupId: string): Promise<void> {
        try {
            const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${accountGroupId}/deactivated`);
            await accountGroupRef.set(true);
            this.state.accountGroups.delete(accountGroupId);
            this.setState({});
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }
}
export default AccountGroupsView;
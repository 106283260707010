import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, Modal, ModalHeader, ModalBody, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip, Card, CardBody, Spinner, Collapse
} from 'reactstrap';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import CostEntry from "./ninja_components/cost_entry";
import confirm from "reactstrap-confirm";
import DayPicker from 'react-day-picker';
import { DateUtils } from "react-day-picker";
import queryString from "query-string";
import SlotDetails from "./ninja_components/slot_details";
import SlotDetailsPrint from "./ninja_components/slot_details_print";
import { v4 as uuidv4 } from 'uuid';
import { BookableEvent, BookableEventAccount, BookableEventAccountGroup, BookableEventDetails, Slot, SlotBookingDetails, SlotOwner, SlotWrapper } from './data/bookings';
import { RouteComponentProps } from "react-router";
import { User } from './data/user';
import { AccountGroup, AccountMinimised } from './data/accounts';
import { string } from 'prop-types';

interface IState {
    slotGroup: BookableEvent;
    slotGroupId: string;
    currentSlotGroupName: string;
    currentSlotGroupDescription: string;
    currentSlotGroupAccounts: Map<string, BookableEventAccount>;
    currentSlotGroupAccountGroups: Map<string, BookableEventAccountGroup>;
    currentSlotGroupSingular: boolean;
    currentSlotGroupMaxPerPerson: number;
    currentSlotGroupAutoConfirm: boolean;
    currentSlotGroupAllowCancellation: boolean;
    currentSlotGroupPreview: boolean;
    allConfirmed: boolean;
    allLocked: boolean;
    currentSlots: Map<string, SlotWrapper>,
    currentSlotEvents: {},
    newSlotStartDate: Date;
    newSlotEndDate: Date;
    newSlotMaxPerPerson: number;
    newSlotMaxPeople: number;
    newSlotCost: number;
    newSlotCostDescription: string;
    newSlotAccount: string;
    newSlotGroupAccount: string;
    newSlotGroupAccountGroup: string;
    newSlotGroupAccountGroupFutures: boolean;
    newSlotRepeatingSelectedDays: Date[],
    newSlotVideoLink: string;
    newSlotVideoRestricted: boolean;
    newSlotVideoAvailableFrom: number;
    newSlotCallLink: string;
    slotModalSlotId: string;
    bookedModalSlotId: string;
    ownerModalSlotId: string;
    showPrintView: boolean;
    listSize: number;
    searchText: string;
    tempSearchText: string;
    collapse: boolean;
    generateSlotsModal: boolean;
    repeatingSlotsModal: boolean;
    newSlotLength: number;
    newSlotBuffer: number;
    newSlotCount: number;
    accountModal: boolean;
}

interface MatchParams {
    slotGroupId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
    accountGroups: Map<string, AccountGroup>;
}

class SlotGroupView extends Component<IProps, IState> {
    listenRef: firebase.database.Reference;
    calendar: string;
    timeout: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);
        this.state = {
            slotGroup: null,
            slotGroupId: props.match.params.slotGroupId,
            currentSlotGroupName: '',
            currentSlotGroupDescription: '',
            currentSlotGroupAccounts: new Map(),
            currentSlotGroupAccountGroups: new Map(),
            currentSlotGroupSingular: true,
            currentSlotGroupMaxPerPerson: null,
            currentSlotGroupAutoConfirm: true,
            currentSlotGroupAllowCancellation: false,
            currentSlotGroupPreview: false,
            allConfirmed: true,
            allLocked: true,
            currentSlots: new Map(),
            currentSlotEvents: {},
            newSlotStartDate: null,
            newSlotEndDate: null,
            newSlotMaxPerPerson: null,
            newSlotMaxPeople: null,
            newSlotCost: null,
            newSlotCostDescription: null,
            newSlotAccount: null,
            newSlotGroupAccount: '',
            newSlotGroupAccountGroup: '',
            newSlotGroupAccountGroupFutures: true,
            newSlotRepeatingSelectedDays: [],
            newSlotVideoLink: '',
            newSlotVideoRestricted: true,
            newSlotVideoAvailableFrom: 5,
            newSlotCallLink: '',
            slotModalSlotId: null,
            bookedModalSlotId: null,
            ownerModalSlotId: null,
            showPrintView: false,
            listSize: 10,
            searchText: '',
            tempSearchText: '',
            collapse: props.match.params.slotGroupId !== "-1",
            generateSlotsModal: false,
            repeatingSlotsModal: false,
            newSlotLength: 1,
            newSlotBuffer: 0,
            newSlotCount: 1,
            accountModal: false,
        };
        const values = queryString.parse(props.location.search);
        this.calendar = values.calendar == null ? null : values.calendar.toString();

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleSlotAdd = this.handleSlotAdd.bind(this);
        this.handleSlotRemove = this.handleSlotRemove.bind(this);
        this.handleRecipientAdd = this.handleRecipientAdd.bind(this);
        this.handleRecipientGroupAdd = this.handleRecipientGroupAdd.bind(this);
        this.createSlotGroup = this.createSlotGroup.bind(this);
        this.closeSlotModal = this.closeSlotModal.bind(this);
        this.openSlotModal = this.openSlotModal.bind(this);
        this.closeGenerateSlotsModal = this.closeGenerateSlotsModal.bind(this);
        this.openGenerateSlotsModal = this.openGenerateSlotsModal.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleSlotGenerate = this.handleSlotGenerate.bind(this);
        this.toggleSlotConfirm = this.toggleSlotConfirm.bind(this);
        this.confirmAllBookedSlots = this.confirmAllBookedSlots.bind(this);
        this.openAccountModal = this.openAccountModal.bind(this);
        this.closeAccountModal = this.closeAccountModal.bind(this);
        this.handleBookedSet = this.handleBookedSet.bind(this);
        this.openBookedModal = this.openBookedModal.bind(this);
        this.closeBookedModal = this.closeBookedModal.bind(this);
        this.handleBookedCancel = this.handleBookedCancel.bind(this);
        this.listenOnSlot = this.listenOnSlot.bind(this);
        this.lockAllSlots = this.lockAllSlots.bind(this);
        this.handleCostChange = this.handleCostChange.bind(this);
        this.toggleRequestConfirm = this.toggleRequestConfirm.bind(this);
        this.toggleSlotLocked = this.toggleSlotLocked.bind(this);
        this.handleBookedAdd = this.handleBookedAdd.bind(this);
        this.toggleRecipientGroup = this.toggleRecipientGroup.bind(this);
        this.openRepeatingSlotsModal = this.openRepeatingSlotsModal.bind(this);
        this.handleSlotRepeating = this.handleSlotRepeating.bind(this);
        this.closeRepeatingSlotsModal = this.closeRepeatingSlotsModal.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleShareSlotGroup = this.handleShareSlotGroup.bind(this);
        this.openOwnerModal = this.openOwnerModal.bind(this);
        this.closeOwnerModal = this.closeOwnerModal.bind(this);
        this.handleOwnerSet = this.handleOwnerSet.bind(this);
        this.handleOwnerRemove = this.handleOwnerRemove.bind(this);
        this.showSlotOwnerSelect = this.showSlotOwnerSelect.bind(this);
        this.showPrintView = this.showPrintView.bind(this);
        this.makePayment = this.makePayment.bind(this);
    }

    render() {

        let bookedAccountIds = new Array<string>();
        for (let [slotId, slot] of this.state.currentSlots) {
            if (slot.accounts != null) {
                bookedAccountIds.push(...Array.from(slot.accounts.keys()));
            }
        }

        let accountList = new Array<string>();
        Array.from(this.state.currentSlotGroupAccounts.keys()).forEach((accountId) => {
            let account = this.props.accountsMinimised.get(accountId);
            if (account == null) {
                return;
            }
            let accountDeets = this.state.currentSlotGroupAccounts.get(accountId);
            if (accountDeets.recipient != null && accountDeets.recipient) {
                if (this.state.searchText != null && this.state.searchText !== '') {
                    if (account.name.toLowerCase().indexOf(this.state.searchText) !== -1) {
                        accountList.push(accountId);
                    }
                } else {
                    accountList.push(accountId);
                }
            }
        });

        accountList.sort((acAId, acBId) => {
            let acA = this.props.accountsMinimised.get(acAId);
            let acB = this.props.accountsMinimised.get(acBId);
            return acA.getLowercaseName().localeCompare(acB.getLowercaseName());
        });

        let bookableAccountList = new Array<string>();
        Array.from(this.state.currentSlotGroupAccounts.keys()).forEach((accountId) => {
            let account = this.props.accountsMinimised.get(accountId);
            if (account == null) {
                return;
            }
            let accountDeets = this.state.currentSlotGroupAccounts.get(accountId);
            if (accountDeets.recipient != null && accountDeets.recipient) {
                bookableAccountList.push(accountId);
            }
        });

        bookableAccountList.sort((acAId, acBId) => {
            let acA = this.props.accountsMinimised.get(acAId);
            let acB = this.props.accountsMinimised.get(acBId);
            return acA.getLowercaseName().localeCompare(acB.getLowercaseName());
        });

        let changeAutoConfirm = true;
        if (this.state.slotGroup != null && !this.state.slotGroup.details.autoConfirm && (!this.state.allLocked || !this.state.allConfirmed)) {
            changeAutoConfirm = false;
        }

        if (this.state.showPrintView) {
            return <div>
                <Button outline onClick={this.showPrintView}><i className="material-icons">close</i></Button><br />
                <SlotDetailsPrint slotGroup={this.state.slotGroup}
                    currentSlots={this.state.currentSlots}
                    accountsMinimised={this.props.accountsMinimised} />
            </div>;
        }

        let orderedAllIds = Array.from(this.props.accountsMinimised.keys());
        orderedAllIds = orderedAllIds.filter((el) => {
            return !accountList.includes(el);
        });
        orderedAllIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).getLowercaseName().localeCompare(this.props.accountsMinimised.get(id2).getLowercaseName());
        });

        let orderedAllGroupIds = Array.from(this.props.accountGroups.keys());
        orderedAllGroupIds.sort((id1, id2) => {
            return this.props.accountGroups.get(id1).details.getLowercaseName().localeCompare(this.props.accountGroups.get(id2).details.getLowercaseName());
        });

        let futureGroupIds = Array.from(this.state.currentSlotGroupAccountGroups.keys());
        futureGroupIds = futureGroupIds.filter((e1) => {
            return this.state.currentSlotGroupAccountGroups.get(e1).futureMembers;
        });
        futureGroupIds.sort((id1, id2) => {
            return this.props.accountGroups.get(id1).details.getLowercaseName().localeCompare(this.props.accountGroups.get(id2).details.getLowercaseName());
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.calendar == null || this.calendar !== 'true' ?
                            <BreadcrumbItem><Link to={'/slotGroups'}>All bookable events</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={'/eventsCalendar'}>Calendar</Link></BreadcrumbItem>
                        }
                        <BreadcrumbItem active>{this.state.slotGroup != null && this.state.slotGroup.details != null ? this.state.slotGroup.details.name : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Bookable event</div>
                        <p className="cardSubTitle">A bookable event allows users to book onto specific slots</p>
                        {this.state.slotGroup != null ?
                            <div>
                                {this.state.slotGroupId === "-1" ? null :
                                    <Button onClick={this.toggle} className="altButton">
                                        {this.state.collapse ? "View details" : "Close"}
                                    </Button>
                                }
                                <Collapse isOpen={!this.state.collapse}>
                                    <Form id="saveBookableEvent" onSubmit={this.handleUpdate}></Form>
                                    <div>
                                        <FormGroup>
                                            <Label for="currentSlotGroupName">Name *</Label>
                                            <Input form="saveBookableEvent" type="text" required name="currentSlotGroupName" onChange={(e) => this.setState({
                                                currentSlotGroupName: e.target.value
                                            })} value={this.state.currentSlotGroupName} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentSlotGroupDescription">Description *</Label>
                                            <Input form="saveBookableEvent" type="textarea" required name="currentSlotGroupDescription" onChange={(e) => this.setState({
                                                currentSlotGroupDescription: e.target.value
                                            })} value={this.state.currentSlotGroupDescription} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentSlotGroupMaxPerPerson">Max total bookings per account across all slots [Leave empty for unlimited]</Label>
                                            <Input form="saveBookableEvent" type="text" name="currentSlotGroupMaxPerPerson" onChange={(e) => this.setState({
                                                currentSlotGroupMaxPerPerson: e.target.value === "" ? null : parseInt(e.target.value)
                                            })} value={this.state.currentSlotGroupMaxPerPerson} />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Confirmation process</label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input form="saveBookableEvent" disabled={!changeAutoConfirm} type="radio" value="true" name='currentSlotGroupAutoConfirm'
                                                        checked={this.state.currentSlotGroupAutoConfirm}
                                                        onChange={(e) => this.setState({
                                                            currentSlotGroupAutoConfirm: e.target.checked
                                                        })} />
                                            &nbsp;Automatic <i className="fas fa-info-circle icons-info" id="help-slotgroup-automaticconfirmation" /> {changeAutoConfirm ? "" :
                                                        <i>Can only be set if all slots are locked and there are no outstanding requests</i>}
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input form="saveBookableEvent" type="radio" value="false" name='currentSlotGroupAutoConfirm'
                                                        checked={!this.state.currentSlotGroupAutoConfirm}
                                                        onChange={(e) => this.setState({
                                                            currentSlotGroupAutoConfirm: !e.target.checked
                                                        })} />
                                            &nbsp;Manual <i className="fas fa-info-circle icons-info" id="help-slotgroup-manualconfirmation" />
                                                </Label>
                                            </FormGroup>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-slotgroup-automaticconfirmation">
                                                Booking requests by users are automatically confirmed allowing users to instantly see the booking in their calendar and make any necessary payments.
                                                Useful for first-come first-served events or where there are no limits on available bookings.
                                    </UncontrolledTooltip>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-slotgroup-manualconfirmation">
                                                Booking requests by users remain in requested state until they are manually confirmed.
                                                Useful for events where there is a need to check requests before confirming them,
                                                or where you want to get user availability before deciding which slots are assigned to which users (e.g. parents' evening)
                                    </UncontrolledTooltip>
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Allow cancellation of confirmed bookings by users?</label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input form="saveBookableEvent" type="radio" value="true" name='currentSlotGroupAllowCancellation'
                                                        checked={this.state.currentSlotGroupAllowCancellation}
                                                        onChange={(e) => this.setState({
                                                            currentSlotGroupAllowCancellation: e.target.checked
                                                        })} />
                                            &nbsp;Yes
                                        </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input form="saveBookableEvent" type="radio" value="false" name='currentSlotGroupAllowCancellation'
                                                        checked={!this.state.currentSlotGroupAllowCancellation}
                                                        onChange={(e) => this.setState({
                                                            currentSlotGroupAllowCancellation: !e.target.checked
                                                        })} />
                                            &nbsp;No
                                        </Label>
                                            </FormGroup>
                                        </FormGroup>
                                        {this.state.slotGroupId !== "-1" ?
                                            <div><Button form="saveBookableEvent" className="adminPagesButton">Update</Button></div>
                                            : <div><br /><Button form="saveBookableEvent" className="adminPagesButton">Create Bookable Event</Button></div>
                                        }
                                    </div></Collapse></div> : <Spinner />
                        }
                    </CardBody></Card>
                {this.state.slotGroup != null ?
                    <div>
                        {this.state.slotGroupId !== "-1" ?
                            <React.Fragment>

                                {!this.state.currentSlotGroupPreview ? <span /> :
                                    <Card className="mainCard top-buffer">
                                        <CardBody className="d-flex flex-column">
                                            <div className="cardTitle2">Preview</div>
                                            <div className="cardSubtitle">This bookable event is currently in preview mode so invitees cannot view or interact with it
                                                    &nbsp;<i className="fas fa-info-circle icons-info" id="help-slotgroup-share" />
                                            </div>
                                            <div>
                                                <Button className="adminPagesButton" type="button" onClick={() => this.handleShareSlotGroup()}>
                                                    Publish
                                                </Button>
                                            </div>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-slotgroup-share">
                                                This bookable event is not visible to invitees until you choose to 'Publish'. This
                                                allows you to setup the event with any pre-existing bookings before invitees can start
                                                requesting slots.
                                                </UncontrolledTooltip>
                                        </CardBody></Card>
                                }

                                <Card className="mainCard top-buffer">
                                    <CardBody className="d-flex flex-column">
                                        <div className="cardTitle2">Slots</div>
                                        <div>
                                            <Button onClick={() => {
                                                this.openSlotModal("-1");
                                            }} className="adminPagesButton buttonBuffer">
                                                Add single slot
                                                </Button>&nbsp;
                                                <Button onClick={this.openGenerateSlotsModal} className="adminPagesButton buttonBuffer">
                                                Add consecutive slots
                                                </Button>
                                                &nbsp;
                                                <Button onClick={this.openRepeatingSlotsModal} className="adminPagesButton buttonBuffer">
                                                Add repeating slots
                                                </Button>
                                                &nbsp;
                                                <Button onClick={this.showPrintView} className="altButton buttonBuffer">
                                                <i className="material-icons">print</i>
                                            </Button>
                                        </div>
                                        {Array.from(this.state.currentSlots.keys()).slice().length > 0 ? <SlotDetails slotGroup={this.state.slotGroup}
                                            currentSlots={this.state.currentSlots}
                                            confirmAllBookedSlots={this.confirmAllBookedSlots}
                                            handleOwnerRemove={this.handleOwnerRemove}
                                            lockAllSlots={this.lockAllSlots}
                                            toggleSlotLocked={this.toggleSlotLocked}
                                            openSlotModal={this.openSlotModal}
                                            openBookedModal={this.openBookedModal}
                                            openOwnerModal={this.openOwnerModal}
                                            handleSlotRemove={this.handleSlotRemove}
                                            handleBookedAdd={this.handleBookedAdd}
                                            handleBookedCancel={this.handleBookedCancel}
                                            toggleRequestConfirm={this.toggleRequestConfirm}
                                            accountsMinimised={this.props.accountsMinimised}
                                            makePayment={this.makePayment}
                                            toggleSlotConfirm={this.toggleSlotConfirm} />
                                            : <span />
                                        }
                                    </CardBody></Card>

                                <Card className="mainCard top-buffer">
                                    <CardBody className="d-flex flex-column">
                                        <div className="cardTitle2">Invitees</div>
                                        <div>
                                            <Button onClick={this.openAccountModal} className="adminPagesButton">
                                                Add invitee
                                            </Button>
                                        </div>
                                        {!this.state.currentSlotGroupPreview ? "" :
                                            <p><i>Note: Event not yet published</i></p>
                                        }
                                        <br />
                                        {accountList.length > 0 ?
                                            <div>
                                                <div>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText><span className="material-icons">search</span></InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder={"Search accounts"} type="text" name="tempSearchText" onChange={evt => this.doSearch(evt)} value={this.state.tempSearchText} />
                                                    </InputGroup>
                                                </div>
                                                <br />
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Additional</th>
                                                            <th>Booked</th>
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {accountList.slice(0, this.state.listSize).map((accountId) => {
                                                            let account = this.props.accountsMinimised.get(accountId);
                                                            let name = account == null ? "Deactivated account" : account.name;
                                                            let additional = account == null ? "" : account.additional;
                                                            return <tr key={accountId + 'tr'}>
                                                                <th scope="row" key={accountId + 'th'}><Link to={'/accounts/' + accountId}>{name}</Link></th>
                                                                <td key={accountId + 'td1'}>{additional}</td>
                                                                <td key={accountId + 'td2'}>{bookedAccountIds.indexOf(accountId) > -1 ? "Yes" : "No"}</td>
                                                                <td key={accountId + 'td4'}>
                                                                    {bookedAccountIds.indexOf(accountId) === -1 ?
                                                                        <Button key={accountId + 'removeButton'} color="link" onClick={async () => {
                                                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove this recipient", confirmText: "Confirm" });
                                                                            if (result) {
                                                                                this.removeRecipient(accountId)
                                                                            }
                                                                        }}>
                                                                            <i className="material-icons">delete</i>
                                                                        </Button> : <span />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </Table>
                                                {this.state.listSize < accountList.length ?
                                                    <div>
                                                        <p>Showing {this.state.listSize} of {accountList.length}</p>
                                                        <Button color="primary" type="button"
                                                            onClick={() => {
                                                                this.setState({
                                                                    listSize: this.state.listSize + 10
                                                                })
                                                            }}>
                                                            Show more
                                                    </Button>&nbsp;&nbsp;
                                                    <Button color="primary" type="button"
                                                            onClick={() => {
                                                                this.setState({
                                                                    listSize: 999999
                                                                })
                                                            }}>
                                                            Show all
                                                    </Button>
                                                    </div> :
                                                    <div>
                                                        <p>Showing {accountList.length}</p>
                                                    </div>
                                                }
                                            </div> : <span />
                                        }
                                    </CardBody></Card>

                                {futureGroupIds.length <= 0 ? <br /> :
                                    <Card className="mainCard top-buffer">
                                        <CardBody className="d-flex flex-column">
                                            <div className="cardTitle2">Invite future account group members <i className="fas fa-info-circle icons-info" id="help-slotGroup-futureMembers" /></div>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-slotGroup-futureMembers">
                                                Adding an account to an account group can automatically add the account to this bookable event.
                                                Simply add the account group to the invitees above and select to include future members.
                                                </UncontrolledTooltip>
                                            <div className="cardSubtitle">Note this will only apply while the bookable event is in the future. <i className="fas fa-info-circle icons-info"
                                                id="help-slotGroup-futureMembersExpire" /></div>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-slotGroup-futureMembersExpire">
                                                Once a bookable event is in the past new members of the group will no longer be added to the event.
                                                Otherwise new group members would have their event feed filled with historical events that are not relevant.
                                                Note that you can manually add accounts to historical bookable events if required.
                                                </UncontrolledTooltip>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Account group name</th>
                                                        <th>New account group members see booking slots</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {futureGroupIds.map((accountGroupId) => {
                                                        let accountGroup = this.props.accountGroups.get(accountGroupId);
                                                        let accountGroupRecipientDeets = this.state.currentSlotGroupAccountGroups.get(accountGroupId);
                                                        let name;
                                                        if (accountGroup == null || !accountGroupRecipientDeets.futureMembers) {
                                                            return "";
                                                        } else {
                                                            name = accountGroup.details.name;
                                                        }
                                                        return <tr key={accountGroupId + 'tr'}>
                                                            <th scope="row" key={accountGroupId + 'th'}><Link to={`/accountGroups/${accountGroupId}`}>{name}</Link></th>
                                                            <td key={accountGroupId + 'td1'}>{accountGroupRecipientDeets.futureMembers ? "Yes" : "No"}</td>
                                                            <td key={accountGroupId + 'td5'}>
                                                                <Button key={accountGroupId + 'button'} color="link" onClick={async () => {
                                                                    let result = await confirm({
                                                                        title: "Confirm",
                                                                        message: "Please confirm you want to remove this account group linkage",
                                                                        confirmText: "Confirm"
                                                                    });
                                                                    if (result) {
                                                                        this.toggleRecipientGroup(accountGroupId, false)
                                                                    }
                                                                }}>
                                                                    <i className="material-icons">delete</i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </Table>
                                        </CardBody></Card>
                                }
                                <br /><br /><br /><br /><br /><br />
                            </React.Fragment> : <span />
                        }
                        <Modal isOpen={this.state.accountModal} toggle={this.closeAccountModal}>
                            <ModalHeader toggle={this.closeAccountModal}>Accounts</ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label for="newSlotGroupAccount">Accounts</Label>
                                    <Input type="select" name="newSlotGroupAccount"
                                        onChange={(e) => this.setState({
                                            newSlotGroupAccount: e.target.value
                                        })}>
                                        <option value={""}>&nbsp;</option>
                                        {orderedAllIds.map((key) => {
                                            return (
                                                <option value={key}
                                                    key={key}>{this.props.accountsMinimised.get(key).name}</option>
                                            )
                                        })
                                        }
                                    </Input>
                                </FormGroup>
                                <Button color="success" disabled={this.state.newSlotGroupAccount == null || this.state.newSlotGroupAccount === ""}
                                    onClick={(e) => this.handleRecipientAdd(false)}>
                                    Add
                                    </Button>&nbsp;
                                    <Button color="success" disabled={this.state.newSlotGroupAccount == null || this.state.newSlotGroupAccount === ""}
                                    onClick={(e) => this.handleRecipientAdd(true)}>
                                    Add & close
                                    </Button>
                                <br /><br />
                                <FormGroup>
                                    <Label for="newSlotGroupAccountGroup">Account
                                            Groups</Label>
                                    <Input type="select" name="newSlotGroupAccountGroup"
                                        onChange={(e) => this.setState({
                                            newSlotGroupAccountGroup: e.target.value
                                        })}>
                                        <option value={""}>&nbsp;</option>
                                        {orderedAllGroupIds.map((key) => {
                                            return (
                                                <option value={key}
                                                    key={key}>{this.props.accountGroups.get(key).details.name}</option>
                                            )
                                        })
                                        }
                                    </Input>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" name='newSlotGroupAccountGroupFutures'
                                            checked={this.state.newSlotGroupAccountGroupFutures}
                                            onChange={(e) => this.setState({
                                                newSlotGroupAccountGroupFutures: e.target.checked
                                            })} />
                                            Future account group members can view slot booking?
                                        </Label>
                                </FormGroup><br />
                                <Button color="success" disabled={this.state.newSlotGroupAccountGroup == null || this.state.newSlotGroupAccountGroup === ""}
                                    onClick={() => this.handleRecipientGroupAdd(false)}>
                                    Add
                                    </Button>&nbsp;
                                    <Button color="success" disabled={this.state.newSlotGroupAccountGroup == null || this.state.newSlotGroupAccountGroup === ""}
                                    onClick={() => this.handleRecipientGroupAdd(true)}>
                                    Add & close
                                    </Button>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.slotModalSlotId != null} toggle={this.closeSlotModal}>
                            <ModalHeader toggle={this.closeSlotModal}>Slot</ModalHeader>
                            <ModalBody>
                                <Form id="slotAdd" onSubmit={this.handleSlotAdd}></Form>
                                <div>
                                    <FormGroup>
                                        <Label for="newSlotStartDate">Start date & time *</Label><br />
                                        <DatePicker
                                            dropdownMode='scroll'
                                            selected={this.state.newSlotStartDate}
                                            showTimeSelect
                                            required
                                            dateFormat="dd MMM yyyy - HH:mm"
                                            timeFormat="HH:mm"
                                            minDate={new Date()}
                                            timeIntervals={1}
                                            onChange={this.handleStartDateChange}
                                            className="form-control"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newSlotEndDate">End date & time *</Label><br />
                                        <DatePicker
                                            dropdownMode='scroll'
                                            selected={this.state.newSlotEndDate}
                                            showTimeSelect
                                            required
                                            dateFormat="dd MMM yyyy - HH:mm"
                                            timeFormat="HH:mm"
                                            timeIntervals={1}
                                            minDate={this.state.newSlotStartDate}
                                            onChange={this.handleEndDateChange}
                                            className="form-control"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newSlotMaxPeople">Available bookings on this slot</Label>
                                        <Input form="slotAdd" type="number" name="newSlotMaxPeople" min={1} onChange={(e) => this.setState({
                                            newSlotMaxPeople: e.target.value === "" ? null : parseInt(e.target.value)
                                        })}
                                            value={this.state.newSlotMaxPeople} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newSlotMaxPerPerson">Max bookings per person</Label>
                                        <Input form="slotAdd" type="number" max={this.state.newSlotMaxPeople}
                                            name="newSlotMaxPerPerson" onChange={(e) => this.setState({
                                                newSlotMaxPerPerson: e.target.value === "" ? null : parseInt(e.target.value)
                                            })}
                                            value={this.state.newSlotMaxPerPerson} />
                                    </FormGroup>
                                    {this.showSlotOwnerSelect()}
                                    <CostEntry enabled={true} callback={this.handleCostChange} currentCost={this.state.newSlotCost} />
                                    {this.state.newSlotCost != null && this.state.newSlotCost > 0 ?
                                        <FormGroup>
                                            <Label for="newSlotCostDescription">Description of cost *</Label>
                                            <Input form="slotAdd" type="text" required name="newSlotCostDescription" onChange={(e) => this.setState({
                                                newSlotCostDescription: e.target.value
                                            })} value={this.state.newSlotCostDescription} />
                                        </FormGroup> : <span />
                                    }
                                    <table width={"100%"}>
                                        <tr>
                                            <td width={"50%"}>
                                                <FormGroup>
                                                    <Label for="newSlotVideoLink">Pre-recorded/Live video link<br />(YouTube url) <i className="fas fa-info-circle icons-info"
                                                        id="help-slot-videolink" /></Label>
                                                    <Input form="slotAdd" disabled={this.state.newSlotCallLink != null && this.state.newSlotCallLink !== ''} type="text" name="newSlotVideoLink"
                                                        onChange={(e) => this.setState({
                                                            newSlotVideoLink: e.target.value
                                                        })} value={this.state.newSlotVideoLink} />
                                                    <UncontrolledTooltip placement="bottom" autohide={false} target="help-slot-videolink">
                                                        Show a video or stream live on the event. This needs to be a YouTube url (web address) like
                                                        https://www.youtube.com/watch?v=ZtDGssvp_cU&t=1s
                                                            </UncontrolledTooltip>
                                                </FormGroup>
                                            </td>
                                            <td width={"50%"}>
                                                <FormGroup>
                                                    <Label for="newSlotCallLink">Video call link<br />(Google hangout url) <i className="fas fa-info-circle icons-info"
                                                        id="help-slot-calllink" /></Label>
                                                    <Input form="slotAdd" disabled={this.state.newSlotVideoLink != null && this.state.newSlotVideoLink !== ''} type="text" name="newSlotCallLink"
                                                        onChange={(e) => this.setState({
                                                            newSlotCallLink: e.target.value
                                                        })} value={this.state.newSlotCallLink} />
                                                    <UncontrolledTooltip placement="bottom" autohide={false} target="help-slot-calllink">
                                                        Allow a student to join a google hangout for 2 way conversations. Will have a format like https://meet.google.com/uoo-nxwd-zio
                                                            </UncontrolledTooltip>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                    </table>
                                    {((this.state.newSlotVideoLink != null && this.state.newSlotVideoLink !== '') ||
                                        (this.state.newSlotCallLink != null && this.state.newSlotCallLink !== '')) &&
                                        this.state.newSlotCost != null && this.state.newSlotCost > 0 ?
                                        <FormGroup>
                                            <label>Video restricted to paid attendees? * <i className="fas fa-info-circle icons-info" id="help-slot-restrictedvideo" /></label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input form="slotAdd" type="radio" value="true" name='newSlotVideoRestricted'
                                                        checked={this.state.newSlotVideoRestricted}
                                                        onChange={(e) => this.setState({
                                                            newSlotVideoRestricted: e.target.checked
                                                        })} />
                                                            &nbsp;Yes
                                                        </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input form="slotAdd" type="radio" value="false" name='newSlotVideoRestricted'
                                                        checked={!this.state.newSlotVideoRestricted}
                                                        onChange={(e) => this.setState({
                                                            newSlotVideoRestricted: !e.target.checked
                                                        })} />
                                                            &nbsp;No
                                                        </Label>
                                            </FormGroup>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-slot-restrictedvideo">
                                                Whether only those who have paid the event costs can see the video feed.
                                                    </UncontrolledTooltip>
                                        </FormGroup> : <span />
                                    }
                                    {(this.state.newSlotVideoLink != null && this.state.newSlotVideoLink !== '') ||
                                        (this.state.newSlotCallLink != null && this.state.newSlotCallLink !== '') ?
                                        <FormGroup>
                                            <Label for="newSlotVideoAvailableFrom">Video access before event start in minutes (Leave empty for always available) <i
                                                className="fas fa-info-circle icons-info" id="help-slot-videoavailablefrom" /></Label>
                                            <Input form="slotAdd" type="number" name="newSlotVideoAvailableFrom" min={0} onChange={(e) => this.setState({
                                                newSlotVideoAvailableFrom: e.target.value === "" ? null : parseInt(e.target.value)
                                            })}
                                                value={this.state.newSlotVideoAvailableFrom} />
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-slot-videoavailablefrom">
                                                How long before the event should the user be able to see the video. If the video should always be available then leave this empty.
                                                    </UncontrolledTooltip>
                                        </FormGroup> : <span />
                                    }
                                    <Button form="slotAdd" color="success">{this.state.slotModalSlotId === "-1" ? "Add" : "Update"}</Button>
                                </div>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.bookedModalSlotId != null} toggle={this.closeBookedModal}>
                            <ModalHeader toggle={this.closeBookedModal}>Booking</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={this.handleBookedSet}>
                                    <FormGroup>
                                        <Label for="newSlotAccount">Select invitee to book on this slot</Label>
                                        <Input type="select" name="newSlotAccount"
                                            onChange={(e) => this.setState({
                                                newSlotAccount: e.target.value
                                            })}
                                            value={this.state.newSlotAccount}>
                                            <option value={""}>&nbsp;</option>
                                            {bookableAccountList.map((accountId) => {
                                                let accountDeets = this.state.currentSlotGroupAccounts.get(accountId);
                                                let account = this.props.accountsMinimised.get(accountId);
                                                if (account == null || accountDeets.recipient == null || !accountDeets.recipient) {
                                                    return null;
                                                }
                                                return (
                                                    <option value={accountId}
                                                        key={accountId}>{account.name}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                    <Button disabled={this.state.newSlotAccount == null || this.state.newSlotAccount === ""} color="success">Update</Button>
                                </Form>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.ownerModalSlotId != null} toggle={this.closeOwnerModal}>
                            <ModalHeader toggle={this.closeOwnerModal}>Add slot owner</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={this.handleOwnerSet}>
                                    {this.showSlotOwnerSelect()}
                                    <Button disabled={this.state.newSlotAccount == null || this.state.newSlotAccount === ""} color="success">Update</Button>
                                </Form>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.generateSlotsModal} toggle={this.closeGenerateSlotsModal}>
                            <ModalHeader toggle={this.closeGenerateSlotsModal}>Consecutive slots</ModalHeader>
                            <ModalBody>
                                <Form id="generateConsecutive" onSubmit={this.handleSlotGenerate}></Form>
                                <div>
                                    <FormGroup>
                                        <Label for="newSlotStartDate">Start date & time *</Label><br />
                                        <DatePicker
                                            dropdownMode='scroll'
                                            selected={this.state.newSlotStartDate}
                                            showTimeSelect
                                            required
                                            dateFormat="dd MMM yyyy - HH:mm"
                                            timeFormat="HH:mm"
                                            minDate={new Date()}
                                            timeIntervals={1}
                                            onChange={this.handleStartDateChange}
                                            className="form-control"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newSlotMaxPeople">Available bookings for each slot</Label>
                                        <Input form="generateConsecutive" type="number" name="newSlotMaxPeople" min={1} onChange={(e) => this.setState({
                                            newSlotMaxPeople: e.target.value === "" ? null : parseInt(e.target.value)
                                        })}
                                            value={this.state.newSlotMaxPeople} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newSlotMaxPerPerson">Max bookings per person for each slot</Label>
                                        <Input form="generateConsecutive" type="number" max={this.state.newSlotMaxPeople} disabled={this.state.newSlotMaxPeople == null || this.state.newSlotMaxPeople === 1}
                                            name="newSlotMaxPerPerson" onChange={(e) => this.setState({
                                                newSlotMaxPerPerson: e.target.value === "" ? null : parseInt(e.target.value)
                                            })}
                                            value={this.state.newSlotMaxPerPerson} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newSlotLength">Length of slots (minutes)</Label>
                                        <Input form="generateConsecutive" type="number" step='1' min='0' name="newSlotLength" onChange={(e) => this.setState({
                                            newSlotLength: e.target.value === "" ? null : parseInt(e.target.value)
                                        })}
                                            value={this.state.newSlotLength} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newSlotBuffer">Time between slots (minutes)</Label>
                                        <Input form="generateConsecutive" type="number" step='1' min='0' name="newSlotBuffer" onChange={(e) => this.setState({
                                            newSlotBuffer: e.target.value === "" ? null : parseInt(e.target.value)
                                        })}
                                            value={this.state.newSlotBuffer} />
                                    </FormGroup>
                                    {this.showSlotOwnerSelect()}
                                    <FormGroup>
                                        <Label for="newSlotCount">Number of slots</Label>
                                        <Input form="generateConsecutive" type="number" step='1' min='1' name="newSlotCount" onChange={(e) => this.setState({
                                            newSlotCount: e.target.value === "" ? null : parseInt(e.target.value)
                                        })}
                                            value={this.state.newSlotCount} />
                                    </FormGroup>
                                    <CostEntry enabled={true} callback={this.handleCostChange} currentCost={this.state.newSlotCost} />
                                    {this.state.newSlotCost != null && this.state.newSlotCost > 0 ?
                                        <FormGroup>
                                            <Label for="newSlotCostDescription">Description of cost *</Label>
                                            <Input form="generateConsecutive" type="text" required name="newSlotCostDescription" onChange={(e) => this.setState({
                                                newSlotCostDescription: e.target.value
                                            })} value={this.state.newSlotCostDescription} />
                                        </FormGroup> : <span />
                                    }
                                    <Button form="generateConsecutive" color="success">Generate slots</Button>
                                </div>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.repeatingSlotsModal} toggle={this.closeRepeatingSlotsModal}>
                            <ModalHeader toggle={this.closeRepeatingSlotsModal}>Repeating slots</ModalHeader>
                            <ModalBody>
                                <Form id="generateRepeating" onSubmit={this.handleSlotRepeating}></Form>
                                <div>
                                    <FormGroup>
                                        <Label>Slot time *</Label><br />
                                        <DatePicker
                                            dropdownMode='scroll'
                                            selected={this.state.newSlotStartDate}
                                            showTimeSelectOnly
                                            showTimeSelect
                                            required
                                            timeFormat="HH:mm"
                                            dateFormat="HH:mm"
                                            timeIntervals={1}
                                            onChange={this.handleStartDateChange}
                                            className="form-control"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Slot dates *</Label><br />
                                        <DayPicker
                                            selectedDays={this.state.newSlotRepeatingSelectedDays}
                                            onDayClick={this.handleDayClick}
                                            disabledDays={{ before: new Date() }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newSlotMaxPeople">Available bookings for each slot</Label>
                                        <Input form="generateRepeating" type="number" name="newSlotMaxPeople" min={1} onChange={(e) => this.setState({
                                            newSlotMaxPeople: e.target.value === "" ? null : parseInt(e.target.value)
                                        })}
                                            value={this.state.newSlotMaxPeople} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newSlotMaxPerPerson">Max bookings per person for each slot</Label>
                                        <Input form="generateRepeating" type="number" max={this.state.newSlotMaxPeople} disabled={this.state.newSlotMaxPeople == null || this.state.newSlotMaxPeople === 1}
                                            name="newSlotMaxPerPerson" onChange={(e) => this.setState({
                                                newSlotMaxPerPerson: e.target.value === "" ? null : parseInt(e.target.value)
                                            })}
                                            value={this.state.newSlotMaxPerPerson} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newSlotLength">Length of slots (minutes)</Label>
                                        <Input form="generateRepeating" type="number" step='1' min='0' name="newSlotLength" onChange={(e) => this.setState({
                                            newSlotLength: e.target.value === "" ? null : parseInt(e.target.value)
                                        })}
                                            value={this.state.newSlotLength} />
                                    </FormGroup>
                                    {this.showSlotOwnerSelect()}
                                    <CostEntry enabled={true} callback={this.handleCostChange} currentCost={this.state.newSlotCost} />
                                    {this.state.newSlotCost != null && this.state.newSlotCost > 0 ?
                                        <FormGroup>
                                            <Label for="newSlotCostDescription">Description of cost *</Label>
                                            <Input form="generateRepeating" type="text" required name="newSlotCostDescription" onChange={(e) => this.setState({
                                                newSlotCostDescription: e.target.value
                                            })} value={this.state.newSlotCostDescription} />
                                        </FormGroup> : <span />
                                    }
                                    <Button form="generateRepeating" color="success">Generate slots</Button>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div> : <br />
                }
            </div>
        );
    }

    showSlotOwnerSelect() {
        let orderedIds = Array.from(this.props.accountsMinimised.keys());
        orderedIds.sort((id1, id2) => {
            let account1 = this.props.accountsMinimised.get(id1);
            let account2 = this.props.accountsMinimised.get(id2);
            return account1.getLowercaseName().localeCompare(account2.getLowercaseName());
        });


        return <FormGroup>
            <Label for="newSlotAccount">Owned by</Label>
            <Input type="select" name="newSlotAccount"
                onChange={(e) => this.setState({
                    newSlotAccount: e.target.value
                })}
                value={this.state.newSlotAccount}>
                <option value={""}>&nbsp;</option>
                {orderedIds.map((accountId) => {
                    let account = this.props.accountsMinimised.get(accountId);
                    if (account == null) {
                        return null;
                    }
                    return (
                        <option value={accountId}
                            key={accountId}>{account.name}</option>
                    )
                })
                }
            </Input>
        </FormGroup>
    }

    handleCostChange(newCost: number): void {
        console.log("Setting new cost", newCost);
        this.setState({
            newSlotCost: newCost
        });
        if (newCost === NaN) {
            newCost === 0
        };
    }

    async lockAllSlots(): Promise<void> {
        let promises: Promise<any>[] = [];
        Array.from(this.state.currentSlots.keys()).forEach((slotId) => {
            promises.push(this.toggleSlotLocked(slotId, true));
        });
        await Promise.all(promises);
    }

    async confirmAllBookedSlots() {
        let promises: Promise<any>[] = [];
        Array.from(this.state.currentSlots.keys()).forEach((slotId) => {
            let slot = this.state.currentSlots.get(slotId);
            if (slot.accounts != null) {
                promises.push(this.toggleSlotConfirm(slotId, true));
            }
        });
        await Promise.all(promises);
    }

    async toggleRequestConfirm(accountId: string, slotId: string, count: number): Promise<void> {
        const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/bookings/${slotId}/accounts/${accountId}/confirmed`);
        await slotGroupRef.set({
            count: count,
            updated: firebase.database.ServerValue.TIMESTAMP,
        });
    }

    async toggleSlotConfirm(slotId: string, confirmed: boolean): Promise<void> {
        let slot = this.state.currentSlots.get(slotId);
        let promises = [];
        for (let [accountId, accountDeets] of slot.accounts) {
            promises.push(this.toggleRequestConfirm(accountId, slotId, confirmed ? accountDeets.count : 0));
        }
        await Promise.all(promises);
    }

    async toggleSlotLocked(slotId: string, locked: boolean): Promise<void> {
        const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/slots/${slotId}/locked`);
        await slotGroupRef.set(locked);
    }

    handleDayClick(day: Date): void {
        const selectedIndex = this.state.newSlotRepeatingSelectedDays.findIndex(selectedDay =>
            DateUtils.isSameDay(selectedDay, day)
        );
        if (selectedIndex !== -1) {
            this.state.newSlotRepeatingSelectedDays.splice(selectedIndex, 1);
        } else {
            this.state.newSlotRepeatingSelectedDays.push(day);
        }
        console.log(this.state.newSlotRepeatingSelectedDays);
        this.setState({});
    }

    showPrintView(): void {
        this.setState({
            showPrintView: !this.state.showPrintView,
        })
    }

    doSearch(e: React.ChangeEvent<HTMLInputElement>): void {
        let newSearchText = e.target.value; // this is the search text
        if (this.timeout) clearTimeout(this.timeout);
        this.setState({
            tempSearchText: newSearchText.toLowerCase(),
        });
        this.timeout = setTimeout(() => {
            this.setState({
                searchText: this.state.tempSearchText,
            });
        }, 300);
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    handleStartDateChange(date: Date): void {
        if (date != null && (this.state.newSlotEndDate == null || date.getTime() > this.state.newSlotEndDate.getTime())) {
            this.setState({
                newSlotStartDate: date,
                newSlotEndDate: date,
            });
        } else {
            this.setState({
                newSlotStartDate: date,
            });
        }
    }

    closeSlotModal(): void {
        this.setState({
            slotModalSlotId: null
        });
    }

    openGenerateSlotsModal(): void {
        this.setState({
            generateSlotsModal: true
        });
    }

    openRepeatingSlotsModal(): void {
        this.setState({
            repeatingSlotsModal: true
        });
    }

    closeAccountModal(): void {
        this.setState({
            accountModal: false,
            newSlotGroupAccount: '',
            newSlotGroupAccountGroup: '',
        });
    }

    openAccountModal(): void {
        this.setState({
            accountModal: true,
            newSlotGroupAccount: '',
            newSlotGroupAccountGroup: '',
        });
    }

    closeGenerateSlotsModal(): void {
        this.setState({
            generateSlotsModal: false
        });
    }

    closeRepeatingSlotsModal(): void {
        this.setState({
            repeatingSlotsModal: false
        });
    }

    openBookedModal(slotId: string): void {
        this.setState({
            bookedModalSlotId: slotId,
            newSlotAccount: null //this.state.currentSlots.get(slotId).accountId
        });
    }

    openOwnerModal(slotId: string): void {
        this.setState({
            ownerModalSlotId: slotId,
            newSlotAccount: null // this.state.currentSlots.get(slotId).accountId
        });
    }

    closeBookedModal(): void {
        this.setState({
            bookedModalSlotId: null
        });
    }

    closeOwnerModal(): void {
        this.setState({
            ownerModalSlotId: null
        });
    }

    openSlotModal(slotId: string): void {
        if (slotId !== "-1") {
            let slot = this.state.currentSlots.get(slotId).slot;
            this.setState({
                slotModalSlotId: slotId,
                newSlotStartDate: slot.startDate,
                newSlotEndDate: slot.endDate,
                newSlotCost: slot.cost,
                newSlotMaxPeople: slot.maxPeople,
                newSlotMaxPerPerson: slot.maxPerPerson,
                newSlotCostDescription: slot.costDescription == null ? null : slot.costDescription,
                newSlotVideoLink: slot.videoLink == null ? null : slot.videoLink,
                newSlotVideoRestricted: slot.videoRestricted == null ? true : slot.videoRestricted,
                newSlotVideoAvailableFrom: slot.videoAvailableFrom != null ? slot.videoAvailableFrom : null,
                newSlotCallLink: slot.callLink != null ? slot.callLink : '',
            });

        } else {
            this.setState({
                slotModalSlotId: slotId
            });
        }
    }

    handleEndDateChange(date: Date): void {
        this.setState({
            newSlotEndDate: date,
        });
    }

    async handleSlotGenerate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let currentStartDate = moment(this.state.newSlotStartDate.getTime());
        let slotLength = this.state.newSlotLength;
        let slotBuffer = this.state.newSlotBuffer;
        let newSlotsData: any = {};
        let slotOwner;
        if (this.state.newSlotAccount != null && this.state.newSlotAccount.trim() !== "") {
            slotOwner = new SlotOwner();
            slotOwner.owner = true;
            slotOwner.updated = firebase.database.ServerValue.TIMESTAMP;
        }
        for (let i = 0; i < this.state.newSlotCount; i++) {
            let nextEndDate = moment(currentStartDate).add(slotLength, 'm');
            let id = uuidv4();
            let newSlot = new Slot();
            newSlot.startDate = new Date(currentStartDate.valueOf());
            newSlot.endDate = new Date(nextEndDate.valueOf());
            newSlot.maxPeople = this.state.newSlotMaxPeople;
            newSlot.maxPerPerson = this.state.newSlotMaxPerPerson;
            newSlot.cost = this.state.newSlotCost == null || this.state.newSlotCost === 0 ? null : this.state.newSlotCost;
            newSlot.costDescription = this.state.newSlotCostDescription == null || this.state.newSlotCostDescription === "" ? null : this.state.newSlotCostDescription;

            if (slotOwner != null) {
                newSlot.owners.set(this.state.newSlotAccount, slotOwner);
            }
            newSlotsData[id] = newSlot.toFirebase();
            currentStartDate.add(slotLength + slotBuffer, 'm');
        }
        const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/slots`);
        await slotGroupRef.update(newSlotsData, (error) => {
            if (error == null) {
                this.props.snackbar();
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
        this.createSlotGroup();
        this.closeGenerateSlotsModal();
    }

    async handleSlotRepeating(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let startTime = new Date(this.state.newSlotStartDate.getTime());
        let slotLength = this.state.newSlotLength;
        let newSlotsData: any = {};
        let slotOwner;
        if (this.state.newSlotAccount != null && this.state.newSlotAccount.trim() !== "") {
            slotOwner = new SlotOwner();
            slotOwner.owner = true;
            slotOwner.updated = firebase.database.ServerValue.TIMESTAMP;
        }
        for (let i = 0; i < this.state.newSlotRepeatingSelectedDays.length; i++) {
            let nextStartDate = this.state.newSlotRepeatingSelectedDays[i];
            nextStartDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
            let nextEndDate = moment(nextStartDate).add(slotLength, 'm');
            let id = uuidv4();
            let newSlot = new Slot();
            newSlot.startDate = new Date(nextStartDate.valueOf());
            newSlot.endDate = new Date(nextEndDate.valueOf());
            newSlot.maxPeople = this.state.newSlotMaxPeople;
            newSlot.maxPerPerson = this.state.newSlotMaxPerPerson;
            newSlot.cost = this.state.newSlotCost == null || this.state.newSlotCost === 0 ? null : this.state.newSlotCost;
            newSlot.costDescription = this.state.newSlotCostDescription == null || this.state.newSlotCostDescription === "" ? null : this.state.newSlotCostDescription;
            if (slotOwner != null) {
                newSlot.owners.set(this.state.newSlotAccount, slotOwner);
            }
            newSlotsData[id] = newSlot.toFirebase();
        }
        const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/slots`);
        await slotGroupRef.update(newSlotsData, (error) => {
            if (error == null) {
                this.props.snackbar();
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
        this.createSlotGroup();
        this.closeRepeatingSlotsModal();
    }

    async handleBookedCancel(accountId: string, slotId: string): Promise<void> {
        let idToken = await firebase.auth().currentUser.getIdToken();
        let authorization = 'Bearer ' + idToken;
        let response = await fetch('https://admin.lifeninja.net/adminRequests/cancelBooking', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            },
            body: JSON.stringify({
                slotGroupId: this.state.slotGroupId,
                slotId: slotId,
                accountId: accountId,
            })
        });
        if (response.status === 200) {
            this.props.snackbar();
        } else {
            this.props.snackbar("Update failed");
        }
    }

    async handleBookedSet(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        await this.handleBookedAdd(this.state.newSlotAccount, this.state.bookedModalSlotId);
        this.closeBookedModal();
    }

    async handleOwnerSet(e: React.FormEvent): Promise<void> {
        e.preventDefault();

        let newOwner = {
            owner: true,
            updated: firebase.database.ServerValue.TIMESTAMP,
        };

        const teacherRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/slots/${this.state.ownerModalSlotId}/owners/${this.state.newSlotAccount}`);
        await teacherRef.set(newOwner, (error) => {
            if (error == null) {
                this.props.snackbar();
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
        this.closeOwnerModal();
    }

    async handleOwnerRemove(slotId: string, accountId: string): Promise<void> {
        let newOwner = {
            owner: false,
            updated: firebase.database.ServerValue.TIMESTAMP,
        };

        const teacherRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/slots/${slotId}/owners/${accountId}`);
        await teacherRef.set(newOwner, (error) => {
            if (error == null) {
                this.props.snackbar();
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
    }

    async handleBookedAdd(accountId: string, slotId: string): Promise<void> {
        let idToken = await firebase.auth().currentUser.getIdToken();
        let authorization = 'Bearer ' + idToken;
        let response = await fetch('https://admin.lifeninja.net/adminRequests/makeBooking', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            },
            body: JSON.stringify({
                slotGroupId: this.state.slotGroupId,
                slotId: slotId,
                accountId: accountId,
            })
        });
        if (response.status === 200) {
            this.props.snackbar("Booked");
        } else {
            this.props.snackbar("Booking failed");
        }
    }

    async makePayment(accountId: string, slotId: string, count: number): Promise<void> {
        await firebase.database().ref(`/bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/bookings/${slotId}/accounts/${accountId}/paid`).transaction(
            (oldValue) => {
                console.log("Old Value", oldValue);
                if (oldValue == null) {
                    oldValue = {};
                }
                if (oldValue.count == null) {
                    oldValue.count = 0;
                }
                oldValue.count += count;

                let now = new Date();
                let timestamp = now.getTime();
                oldValue.updated = timestamp;
                console.log("New value", oldValue);
                return oldValue;
            }
        );
    }

    async handleSlotAdd(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let newSlot = new Slot();
        newSlot.startDate = new Date(this.state.newSlotStartDate.valueOf());
        newSlot.endDate = new Date(this.state.newSlotEndDate.valueOf());
        newSlot.maxPeople = this.state.newSlotMaxPeople;
        newSlot.maxPerPerson = this.state.newSlotMaxPerPerson;
        newSlot.cost = this.state.newSlotCost == null || this.state.newSlotCost === 0 ? null : this.state.newSlotCost;
        newSlot.costDescription = this.state.newSlotCostDescription == null || this.state.newSlotCostDescription === "" ? null : this.state.newSlotCostDescription;
        newSlot.videoLink = this.state.newSlotVideoLink;
        newSlot.videoRestricted = this.state.newSlotVideoRestricted;
        newSlot.videoAvailableFrom = this.state.newSlotVideoAvailableFrom != null ? this.state.newSlotVideoAvailableFrom : null;
        newSlot.callLink = this.state.newSlotCallLink;

        if (this.state.slotModalSlotId === "-1") {
            let id = uuidv4();

            // newSlot.booked = false;

            if (this.state.newSlotAccount != null && this.state.newSlotAccount.trim() !== "") {
                let slotOwner = new SlotOwner();
                slotOwner.owner = true;
                slotOwner.updated = firebase.database.ServerValue.TIMESTAMP;
                newSlot.owners.set(this.state.newSlotAccount, slotOwner);
            }

            const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/slots/${id}`);
            await slotGroupRef.set(newSlot.toFirebase(), (error) => {
                if (error == null) {
                    this.props.snackbar();
                } else {
                    console.log(error);
                    this.props.snackbar("Save failed");
                }
            });
        } else {
            const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/slots/${this.state.slotModalSlotId}`);
            await slotGroupRef.update(newSlot.toFirebase(), (error) => {
                if (error == null) {
                    this.props.snackbar();
                } else {
                    console.log(error);
                    this.props.snackbar("Save failed");
                }
            });
        }
        this.createSlotGroup();
        this.closeSlotModal();
    }

    async handleSlotRemove(uuid: string): Promise<void> {
        const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/slots/${uuid}`);
        await slotGroupRef.remove((error) => {
            if (error == null) {
                this.props.snackbar();
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
        this.createSlotGroup();
    }

    async handleRecipientAdd(close: boolean): Promise<void> {
        let accountRecipientDeets = {
            recipient: true,
            updated: firebase.database.ServerValue.TIMESTAMP,
        };
        const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/accounts/${this.state.newSlotGroupAccount}`);
        try {
            await slotGroupRef.update(accountRecipientDeets);
            this.props.snackbar();
            this.setState({
                accountModal: !close,
            });
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async handleShareSlotGroup() {
        const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/preview`);
        await slotGroupRef.set(false);
        await this.createSlotGroup();
    }

    async handleRecipientGroupAdd(close: boolean): Promise<void> {
        let newGroup = this.props.accountGroups.get(this.state.newSlotGroupAccountGroup);
        await this.toggleRecipientGroup(this.state.newSlotGroupAccountGroup, this.state.newSlotGroupAccountGroupFutures);
        let newUpdate = new Map<string, BookableEventAccount>();
        for (let accountId of Array.from(newGroup.accounts.keys())) {
            let nextDeets = newGroup.accounts.get(accountId);
            if (nextDeets.member) {
                if (!this.state.currentSlotGroupAccounts.has(accountId)) {
                    let nextAccount = new BookableEventAccount();
                    nextAccount.recipient = true;
                    nextAccount.updated = firebase.database.ServerValue.TIMESTAMP;
                    newUpdate.set(accountId, nextAccount);
                }
            }
        }

        let fbUpdate: any = {};
        for (let nextDeetsId of Array.from(newUpdate.keys())) {
            fbUpdate[nextDeetsId] = newUpdate.get(nextDeetsId).toFirebase();
        }

        const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/accounts`);
        try {
            await slotGroupRef.update(fbUpdate);
            this.props.snackbar();
            this.setState({
                accountModal: !close,
            });
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async toggleRecipientGroup(accountGroupId: string, enabled: boolean): Promise<void> {
        let newGroupUpdate = new BookableEventAccountGroup();
        newGroupUpdate.futureMembers = enabled;
        newGroupUpdate.updated = firebase.database.ServerValue.TIMESTAMP;

        const accountGroupFutureRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/accountGroups/${accountGroupId}`);
        await accountGroupFutureRef.update(newGroupUpdate.toFirebase(), (error) => {
            if (error == null) {
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
        this.state.currentSlotGroupAccountGroups.set(accountGroupId, newGroupUpdate);
        this.setState({});
    }

    async removeRecipient(accountId: string): Promise<void> {
        let accountRecipientDeets = new BookableEventAccount();
        accountRecipientDeets.recipient = false;
        accountRecipientDeets.updated = firebase.database.ServerValue.TIMESTAMP;

        const accountGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/accounts/${accountId}`);
        await accountGroupRef.update(accountRecipientDeets.toFirebase(), (error) => {
            if (error == null) {
            } else {
                console.log(error);
            }
        });
    }

    handleUpdate(e: React.FormEvent): void {
        e.preventDefault();

        this.createSlotGroup();
    }

    async createSlotGroup(): Promise<void> {
        let slotGroupDetails = new BookableEventDetails();
        slotGroupDetails.name = this.state.currentSlotGroupName;
        slotGroupDetails.description = this.state.currentSlotGroupDescription;
        slotGroupDetails.maxPerPerson = this.state.currentSlotGroupMaxPerPerson;
        slotGroupDetails.autoConfirm = this.state.currentSlotGroupAutoConfirm;
        slotGroupDetails.allowConfirmedCancellation = this.state.currentSlotGroupAllowCancellation;

        let startDate: Date = null;
        let endDate: Date = null;
        Array.from(this.state.currentSlots.keys()).forEach((slotId) => {
            let nextSlot = this.state.currentSlots.get(slotId).slot;
            if (startDate == null || startDate.getTime() > nextSlot.startDate.getTime()) {
                startDate = nextSlot.startDate;
            }
            if (endDate == null || endDate.getTime() < nextSlot.endDate.getTime()) {
                endDate = nextSlot.endDate;
            }
        });

        slotGroupDetails.startDate = startDate != null ? startDate.toISOString() : null;
        slotGroupDetails.endDate = endDate != null ? endDate.toISOString() : null;


        if (this.state.slotGroupId === "-1") {
            const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}`);
            let promise = slotGroupRef.push({
                details: slotGroupDetails.toFirebase(),
                preview: this.state.currentSlotGroupPreview
            });
            let newKey = promise.key;
            let slotGroup = new BookableEvent();
            slotGroup.preview = this.state.currentSlotGroupPreview;
            slotGroup.details = slotGroupDetails;
            try {
                await promise;
                this.setState({
                    slotGroup: slotGroup,
                    slotGroupId: newKey,
                    collapse: true,
                });
                this.props.history.replace(`/slotGroups/${newKey}`);
                this.props.snackbar();
                this.listenOnSlot();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed")
            }
        } else {
            const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}/details`);
            try {
                this.state.slotGroup.details = slotGroupDetails;
                await slotGroupRef.update(slotGroupDetails.toFirebase());
                this.setState({});
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Update failed");
            }
        }
    }

    async componentDidMount(): Promise<void> {
        if (this.state.slotGroupId !== "-1") {
            this.listenOnSlot();
        } else {
            this.setState({
                slotGroup: new BookableEvent(),
                currentSlotGroupName: '',
                currentSlotGroupDescription: '',
                currentSlotGroupAccounts: new Map(),
                currentSlotGroupSingular: true,
                currentSlotGroupMaxPerPerson: null,
                currentSlotGroupAutoConfirm: true,
                currentSlotGroupAllowCancellation: false,
                currentSlotGroupPreview: true,
                currentSlots: new Map(),
                newSlotGroupAccountGroup: '',
                newSlotGroupAccount: '',
                newSlotAccount: '',
            });
        }
    }

    async componentWillUnmount(): Promise<void> {
        if (this.listenRef != null) {
            this.listenRef.off();
        }
    }

    async listenOnSlot(): Promise<void> {
        this.listenRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${this.state.slotGroupId}`);
        this.listenRef.on('value', (snapshot) => {
            let slotGroupData = snapshot.val();
            let slotGroup = BookableEvent.fromFirebase(slotGroupData);
            let slots = new Map<string, SlotWrapper>();
            let allLocked = true;
            let allConfirmed = true;
            if (slotGroup.slots != null) {
                Array.from(slotGroup.slots.keys()).forEach((slotId) => {
                    let accounts = new Map<string, SlotBookingDetails>();
                    if (slotGroup.bookings != null && slotGroup.bookings.has(slotId)) {
                        accounts = slotGroup.bookings.get(slotId).accounts;
                        if (accounts != null) {
                            Array.from(accounts.keys()).forEach((accountId) => {
                                let account = accounts.get(accountId);
                                if (account.count != null && account.count > 0 && (account.confirmed == null || account.confirmed.count == null || account.confirmed.count < account.count)) {
                                    allConfirmed = false;
                                }
                            });
                        }
                    }
                    let nextSlot = slotGroup.slots.get(slotId);
                    let bookedAccounts = new Map<string, SlotBookingDetails>();
                    Array.from(accounts.keys()).forEach((accountId) => {
                        let accountDeets = accounts.get(accountId);
                        let requestedCount = accountDeets.count == null ? 0 : accountDeets.count;
                        if (requestedCount > 0) {
                            bookedAccounts.set(accountId, accountDeets);
                        }
                    });
                    let newOwners = new Map<string, SlotOwner>();
                    if (nextSlot.owners != null) {
                        Array.from(nextSlot.owners.keys()).forEach((accountId) => {
                            let accountDeets = nextSlot.owners.get(accountId);
                            if (accountDeets.owner != null && accountDeets.owner) {
                                newOwners.set(accountId, accountDeets);
                            }
                        });
                    }
                    slots.set(slotId, new SlotWrapper(nextSlot, bookedAccounts, newOwners));
                    //     startDate: new Date(nextSlot.startDate),
                    //     endDate: new Date(nextSlot.endDate),
                    //     maxPeople: nextSlot.maxPeople,
                    //     maxPerPerson: nextSlot.maxPerPerson,
                    //     cost: nextSlot.cost == null ? 0 : nextSlot.cost / 100,
                    //     costDescription: nextSlot.costDescription == null ? "" : nextSlot.costDescription,
                    //     locked: nextSlot.locked == null ? false : nextSlot.locked,
                    //     videoLink: nextSlot.videoLink == null ? null : nextSlot.videoLink,
                    //     videoRestricted: nextSlot.videoRestricted == null ? true : nextSlot.videoRestricted,
                    //     videoAvailableFrom: nextSlot.videoAvailableFrom != null ? nextSlot.videoAvailableFrom : null,
                    //     callLink: nextSlot.callLink != null ? nextSlot.callLink : '',
                    //     accounts: bookedAccounts,
                    //     owners: newOwners,
                    //     confirmed: nextSlot.confirmed,
                    // };
                    if (!nextSlot.locked) {
                        allLocked = false;
                    }
                });
            }
            this.setState({
                slotGroup: slotGroup,
                currentSlotGroupName: slotGroup.details.name,
                currentSlotGroupDescription: slotGroup.details.description,
                currentSlotGroupAccounts: slotGroup.accounts != null ? slotGroup.accounts : new Map(),
                currentSlotGroupAccountGroups: slotGroup.accountGroups != null ? slotGroup.accountGroups : new Map(),
                currentSlotGroupMaxPerPerson: slotGroup.details.maxPerPerson != null ? slotGroup.details.maxPerPerson : null,
                currentSlotGroupAutoConfirm: slotGroup.details.autoConfirm != null ? slotGroup.details.autoConfirm : false,
                currentSlotGroupAllowCancellation: slotGroup.details.allowConfirmedCancellation != null ? slotGroup.details.allowConfirmedCancellation : false,
                currentSlotGroupPreview: slotGroup.preview != null ? slotGroup.preview : false,
                allConfirmed: allConfirmed,
                allLocked: allLocked,
                currentSlotGroupSingular: true,
                currentSlots: slots,
                newSlotGroupAccountGroup: '',
                newSlotGroupAccount: '',
                newSlotAccount: '',
            });
            this.setState({});
        }, (error: any) => console.log(error));
    }
}

export default SlotGroupView;
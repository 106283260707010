import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Breadcrumb,
    BreadcrumbItem,
    Alert,
    Card,
    CardHeader,
    CardBody, Table, Spinner,
} from 'reactstrap';
import CostEntry from "./ninja_components/cost_entry";
import { Fundraiser } from './data/fundraising';
import { User } from './data/user';
import { AccountMinimised } from './data/accounts';

interface IState {
    fundraiserId: string;
    earns: Map<string, number>,
    donations: Map<string, number>,
    fundraiser: Fundraiser;
    currentFundraiserName: string;
    currentFundraiserAdditional: string;
}

interface MatchParams {
    fundraiserId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
}

class FundraiserView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            fundraiserId: props.match.params.fundraiserId,
            earns: new Map(),
            donations: new Map(),
            fundraiser: null,
            currentFundraiserName: '',
            currentFundraiserAdditional: '',
        };
        this.handleUpdate = this.handleUpdate.bind(this);
    }


    render() {
        let totalEarnStars = 0;
        Array.from(this.state.earns.values()).forEach((earn) => {
            totalEarnStars += earn;
        });
        let totalEarnPence = totalEarnStars * 0.1;
        let totalPreDonation = 0;
        Array.from(this.state.donations.values()).forEach((donation) => {
            totalPreDonation += donation;
        });
        let totalDonations = totalPreDonation - CostEntry.getProcessingCost(totalPreDonation);
        let totalRaised = totalDonations + totalEarnPence;
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/fundraisers'}>All fundraisers</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.fundraiser != null ? this.state.currentFundraiserName : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Fundraiser</div>
                        <p className="cardSubTitle">Raise money for a good cause or generally for your organisation</p>
                        {this.state.fundraiser != null ?
                            <div>
                                <Form onSubmit={this.handleUpdate}>
                                    <FormGroup>
                                        <Label for="currentFundraiserName">Name *</Label>
                                        <Input type="text" required name="currentFundraiserName"
                                            onChange={(e) => this.setState({
                                                currentFundraiserName: e.target.value
                                            })}
                                            value={this.state.currentFundraiserName} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentFundraiserAdditional">Description</Label>
                                        <Input type="text" required name="currentFundraiserAdditional"
                                            onChange={(e) => this.setState({
                                                currentFundraiserAdditional: e.target.value
                                            })}
                                            value={this.state.currentFundraiserAdditional} />
                                    </FormGroup>
                                    {this.state.fundraiserId === "-1" ?
                                        <Button className="adminPagesButton">Create</Button> :
                                        <Button className="adminPagesButton">Update</Button>
                                    }
                                </Form>
                            </div> : <Spinner />
                        }
                    </CardBody></Card>
                {this.state.fundraiser != null ?
                    <div>
                        {this.state.fundraiserId !== "-1" ?
                            <React.Fragment>
                                <Card className="mainCard top-buffer">
                                    <CardBody className="d-flex flex-column">
                                        <div className="cardTitle2">Totals</div>
                                        <p className="cardSubTitle">The total amount of money raised via earns and donations</p>
                                        <FormGroup>
                                            <Label for="totalEarnStars">Total stars</Label>
                                            <Input disabled name="totalEarnStars"
                                                value={totalEarnStars} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="totalEarnPence">Total stars value</Label>
                                            <Input disabled name="totalEarnPence"
                                                value={`£${(totalEarnPence / 100).toFixed(2)}`} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="totalDonations">Total donations</Label>
                                            <Input disabled name="totalDonations"
                                                value={`£${(totalDonations / 100).toFixed(2)}`} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="fundraisingTotal">Fundraising total</Label>
                                            <Input disabled name="fundraisingTotal"
                                                value={`£${(totalRaised / 100).toFixed(2)}`} />
                                        </FormGroup>
                                        </CardBody></Card>
                                <Card className="mainCard top-buffer">
                                    <CardBody className="d-flex flex-column">
                                        <div className="cardTitle2">All earns</div>
                                        <p className="cardSubTitle">All the stars earned. Each star is worth 0.1p</p>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Account</th>
                                                    <th>Additional</th>
                                                    <th>Stars</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.from(this.state.earns.keys()).map((accountId) => {
                                                    let account = this.props.accountsMinimised.get(accountId);
                                                    if (account == null) {
                                                        account = new AccountMinimised();
                                                        account.name = "REMOVED";
                                                        account.additional = "REMOVED";
                                                    }
                                                    let earnAmount = this.state.earns.get(accountId);
                                                    let value = (earnAmount * 0.1) / 100;
                                                    return <tr>
                                                        <th scope="row"><Link to={`accounts/${accountId}`}>{account.name}</Link></th>
                                                        <td>{account.additional}</td>
                                                        <td>{earnAmount}</td>
                                                        <td>£{value.toFixed(2)}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </CardBody></Card>
                                <Card className="mainCard top-buffer">
                                    <CardBody className="d-flex flex-column">
                                        <div className="cardTitle2">All donations</div>
                                        <p className="cardSubTitle">All the money donated via direct payment</p>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Account</th>
                                                    <th>Additional</th>
                                                    <th>Amount donated</th>
                                                    <th>Final amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.from(this.state.donations.keys()).map((accountId) => {
                                                    let account = this.props.accountsMinimised.get(accountId);
                                                    if (account == null) {
                                                        account = new AccountMinimised();
                                                        account.name = "REMOVED";
                                                        account.additional = "REMOVED";
                                                    }
                                                    let donationAmount = this.state.donations.get(accountId) / 100;
                                                    let finalDonationAmount = (this.state.donations.get(accountId) - CostEntry.getProcessingCost(this.state.donations.get(accountId))) / 100;
                                                    return <tr>
                                                        <th scope="row"><Link to={`accounts/${accountId}`}>{account.name}</Link></th>
                                                        <td>{account.additional}</td>
                                                        <td>{`£${donationAmount.toFixed(2)}`}</td>
                                                        <td>{`£${finalDonationAmount.toFixed(2)}`}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table></CardBody></Card></React.Fragment>
                            : <span />}
                    </div> : <span />
                }
                <br/><br/><br/>
            </div>
        );
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let fundraiser = this.state.fundraiser;
        fundraiser.details.name = this.state.currentFundraiserName;
        fundraiser.details.additional = this.state.currentFundraiserAdditional;
        console.log(fundraiser);
        let fundraiserRef = firebase.database().ref(`fundraising/${this.props.user.schoolId}/fundraisers/`);
        if (this.state.fundraiserId === "-1") {
            let promise = fundraiserRef.push(fundraiser);
            let newPostKey = promise.key;
            try {
                await promise;
                this.setState({
                    fundraiser: fundraiser,
                    fundraiserId: newPostKey,
                });
                this.props.history.replace(`/fundraisers/${newPostKey}`);
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        } else {
            let fundraiserDetailsRef = fundraiserRef.child(`${this.state.fundraiserId}/details`);
            try {
                await fundraiserDetailsRef.set(fundraiser.details);
                this.setState({
                    fundraiser: fundraiser,
                });
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        }
    }

    async componentDidMount() {
        try {
            if (this.state.fundraiserId !== "-1") {
                const fundraiserRef = firebase.database().ref(`fundraising/${this.props.user.schoolId}/fundraisers/${this.state.fundraiserId}`);
                let fundraiserSnapshot = await fundraiserRef.once('value');
                let fundraiser = fundraiserSnapshot.val();
                if (fundraiser == null) {
                    fundraiser = {};
                }
                if (fundraiser.details == null) {
                    fundraiser.details = {};
                    if (this.state.fundraiserId === 'DEFAULT') {
                        fundraiser.details.name = 'General fundraising';
                        fundraiser.details.additional = "Default general fundraising option";
                    }
                }

                const earnsRef = firebase.database().ref(`fundraising/${this.props.user.schoolId}/totals/${this.state.fundraiserId}/accounts`);
                let earnsSnapshot = await earnsRef.once('value');
                let earnsAll = earnsSnapshot.val();
                let earns = new Map<string, number>();
                if (earnsAll != null) {
                    Object.keys(earnsAll).forEach((accountId) => {
                        let earn = earnsAll[accountId];
                        if (earn > 250) {
                            earns.set(accountId, Math.floor(earn / 250) * 250);
                        }
                    });
                }

                const donationsRef = firebase.database().ref(`fundraising/${this.props.user.schoolId}/donationTotals/${this.state.fundraiserId}/accounts`);
                let donationsSnapshot = await donationsRef.once('value');
                let donationsAll = donationsSnapshot.val() == null ? new Map() : donationsSnapshot.val();
                let donations = new Map<string, number>();
                if (donationsAll != null) {
                    Object.keys(donationsAll).forEach((accountId) => {
                        donations.set(accountId, donationsAll[accountId]);
                    });
                }

                this.setState({
                    fundraiser: fundraiser,
                    earns: earns,
                    donations: donations,
                    currentFundraiserName: fundraiser.details.name,
                    currentFundraiserAdditional: fundraiser.details.additional,
                });
            } else {
                this.setState({
                    fundraiser: new Fundraiser(),
                    earns: new Map(),
                    donations: new Map(),
                    currentFundraiserName: "",
                    currentFundraiserAdditional: "",
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export default FundraiserView;
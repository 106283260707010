import React, { Component } from 'react';
import firebase from './firebase';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import queryString from 'query-string';
import { HomeLearning, HomeLearningActivityLearningPath, LearningGameOutcome } from './data/home_learning';
import { LearningGame, LearningPath } from './data/learning_games';

interface IState {
    homeworkName: string;
    homeworkActivityName: string;
    studentName: string;
    homeworkId: string;
    homeworkActivityId: string;
    accountId: string;
    outcomesMap: Map<string, LearningGameOutcome[]>;
    learningGames: Map<string, LearningGame>;
    path: LearningPath;
}

interface MatchParams {
    homeworkId: string;
    homeworkActivityId: string;
    accountId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
}

class HomeworkStudentAnalysis extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            homeworkName: values.homeworkName == null ? "" : values.homeworkName.toString(),
            homeworkActivityName: values.homeworkActivityName == null ? "" : values.homeworkActivityName.toString(),
            studentName: values.studentName == null ? "" : values.studentName.toString(),
            homeworkId: props.match.params.homeworkId,
            homeworkActivityId: props.match.params.homeworkActivityId,
            accountId: props.match.params.accountId,
            outcomesMap: new Map(),
            learningGames: new Map(),
            path: null,
        };
    }

    render(): JSX.Element {
        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/homeworks'}>All homework</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={`/homeworks/${this.state.homeworkId}`}>{this.state.homeworkName}</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={`/homeworkActivityLearningPath/${this.state.homeworkId}/${this.state.homeworkActivityId}`}>{this.state.homeworkActivityName}</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.state.studentName}</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">{this.state.studentName}</div>
                    <p className="cardSubTitle">{this.state.homeworkName}</p>
                    {
                        (this.state.path != null) ? <div>
                            {this.state.path.gameIds.map(gameId => {
                                let gameNumber = this.state.path.gameIds.indexOf(gameId) + 1;
                                let gameName = this.state.learningGames.get(gameId).title;
                                let outcomes = this.state.outcomesMap.get(gameId);
                                let analysis;
                                if (outcomes == null || outcomes.length == 0) {
                                    analysis = <div>Not played</div>
                                } else {
                                    let successCount = 0;
                                    let flawlessCount = 0;
                                    let failureCount = 0;
                                    let fastest = -1;
                                    outcomes.forEach(nextOutcome => {
                                        if (nextOutcome.completed) {
                                            successCount++;
                                        }
                                        if (nextOutcome.flawless) {
                                            flawlessCount++;
                                        } else {
                                            failureCount++;
                                        }
                                        let timeTaken = nextOutcome.endDateTime - nextOutcome.startDateTime;
                                        if (fastest === -1 || fastest > timeTaken) {
                                            fastest = timeTaken;
                                        }
                                    });
                                    analysis = <div>Completed: {successCount > 0 ? <span>&#10004; ({successCount} time{successCount === 1 ? "" : "s"})</span> : <span>&#10060;</span>}
                                        <br />Flawless: {flawlessCount > 0 ? <span>&#10004;</span> : <span>&#10060;</span>}
                                        <br />Fastest: {(fastest / 1000).toFixed(2)} seconds</div>
                                }
                                return <div><b>Game {gameNumber} - {gameName}</b><br />{analysis}<br /></div>
                            })}
                        </div> : <div>Loading...</div>
                    }
                </CardBody>
            </Card>
        </div>
    }

    async componentDidMount(): Promise<void> {
        const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
        let homeworkSnapshot = await homeworkRef.get();
        let homework = HomeLearning.fromFirebase(homeworkSnapshot.data());
        let homeworkActivity = homework.activities.get(this.state.homeworkActivityId) as HomeLearningActivityLearningPath;

        const schoolLearningPathsRef = firebase.firestore().doc(`learningPaths/${homeworkActivity.pathId}`);
        let pathSnapshot = await schoolLearningPathsRef.get();
        let path = LearningPath.fromFirebase(pathSnapshot.data());

        let promises: Promise<firebase.firestore.DocumentData>[] = [];
        path.gameIds.forEach(gameId => {
            promises.push(firebase.firestore().doc(`learningGames/${gameId}`).get());
        });

        const outcomesRef = await firebase.firestore().collection(`learningGameOutcomes`)
            .where('accountId', '==', this.state.accountId)
            .where('pathId', '==', homeworkActivity.pathId).get();
        let outcomesMap = new Map<string, LearningGameOutcome[]>();
        outcomesRef.forEach(doc => {
            let outcome = LearningGameOutcome.fromFirebase(doc.data());
            if (!outcomesMap.has(outcome.gameId)) {
                outcomesMap.set(outcome.gameId, []);
            }
            outcomesMap.get(outcome.gameId).push(outcome);
        });

        let learningGameRefs = await Promise.all(promises);
        let learningGames = new Map<string, LearningGame>();
        learningGameRefs.forEach(learningGameRef => {
            let learningGame = LearningGame.fromFirebase(learningGameRef.data());
            learningGames.set(learningGameRef.id, learningGame);
        })

        this.setState({
            path: path,
            outcomesMap: outcomesMap,
            learningGames: learningGames,
        });
    }
}

export default HomeworkStudentAnalysis;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, Spinner, Card, CardBody } from 'reactstrap';
import CSVReader from 'react-csv-reader'
import { createUsername, confirmUsernameBatch } from "./helpers/username_services";
import { CSVLink } from "react-csv";
import { v4 as uuidv4 } from 'uuid';
import { User } from './data/user';
import { RouteComponentProps } from 'react-router';
import { Account, AccountGroup, AccountSubAccount, CHAT_TYPE, SUB_ACCOUNT_TYPE } from './data/accounts';
import { objectToMapConverter } from './data/database_object';
import { ParseConfig } from 'papaparse';

interface CsvIssue {
    text: string;
    button?: string;
    callback?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

interface AccountStub {
    remoteId: string;
    accountStatus: string;
    personalName: string;
    familyName: string;
    additional: string;
    createUsername: boolean;
    logins: LoginStub[];
    groupIds: string[];
    groupNames: string[];
    warnings: string[];
    information: string[];
    persisted?: boolean;
    issues?: string[];
    newUsername?: string;
}

interface AccountSubAccountStub {
    email: string;
    relationship: string;
}

interface LoginStub {
    email: string;
    name: string;
}

interface IState {
    accountsById: Map<string, Account>;
    allUsernames: Map<string, string>;
    newAccounts: AccountStub[];
    inputKey: string;
    majorIssues: CsvIssue[];
    usernameDetails: string[];
    usernameTableData: Array<Array<string>>;
    persisting: boolean;
    persisted: boolean;
    latestData: any[];
}


interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
    accountGroups: Map<string, AccountGroup>;
}

class AccountsCsvView extends Component<IProps, IState> {
    suggestedUsernames: string[] = [];
    newUsernames: Map<string, AccountStub> = new Map();

    constructor(props: IProps) {
        super(props);
        this.state = {
            accountsById: new Map(),
            allUsernames: new Map(),
            newAccounts: null,
            inputKey: '',
            majorIssues: [],
            usernameDetails: [],
            usernameTableData: [],
            persisting: false,
            persisted: false,
            latestData: [],
        };
        this.uploadCsv = this.uploadCsv.bind(this);
        this.csvUploadFail = this.csvUploadFail.bind(this);
        this.createAccount = this.createAccount.bind(this);
        this.persistAccounts = this.persistAccounts.bind(this);
        this.handleUpdateSubAccount = this.handleUpdateSubAccount.bind(this);
        this.handleRemoveSubAccount = this.handleRemoveSubAccount.bind(this);
        this.updateSubAccount = this.updateSubAccount.bind(this);
        this.addSubAccount = this.addSubAccount.bind(this);
        this.prepareUsernameLookups = this.prepareUsernameLookups.bind(this);
        this.suggestUsername = this.suggestUsername.bind(this);
        this.createAccountGroup = this.createAccountGroup.bind(this);
    }


    render(): JSX.Element {
        // skipEmptyLines greedy
        // header
        const papaparseOptions: ParseConfig = {
            skipEmptyLines: "greedy",
            header: true,
            transformHeader: (header: any) => {
                let lcaseHeader = header.toLowerCase()
                if (lcaseHeader.startsWith("name")) {
                    let number = lcaseHeader.slice(4);
                    lcaseHeader = `login${number}_name`
                }
                if (lcaseHeader.startsWith("email")) {
                    let number = lcaseHeader.slice(5);
                    lcaseHeader = `login${number}_email`
                }
                if (lcaseHeader === "remoteid") {
                    lcaseHeader = "accountid";
                }
                return lcaseHeader;
            }
        };

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/accounts'}>All accounts</Link></BreadcrumbItem>
                        <BreadcrumbItem active>CSV upload</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">CSV upload</div>
                        <p className="cardSubTitle">Upload a set of accounts from a csv file<br />
                        The file format is one header row with titles as below, followed by one row per account<br />
                            <a target={"_blank"} href={"https://docs.google.com/document/d/1VoqabqcYdPwGGd2u-zstNCa1tdyePORGytGkPvIs2so/edit?usp=sharing"}>Full documentation</a>
                        </p>
                        <div key={this.state.inputKey}>
                            <CSVReader
                                disabled={this.state.persisting}
                                label="Select CSV file"
                                onFileLoaded={this.uploadCsv}
                                onError={this.csvUploadFail}
                                inputId="csvUploadButton"
                                parserOptions={papaparseOptions}
                            />
                        </div>
                        {this.state.usernameDetails.length > 0 && this.state.persisted ? <div><b>Username passwords</b> Record these as they are not stored and will not be available once you leave this page<br />
                            <CSVLink data={[["RemoteId", "First name", "Surname", "Username", "Password"], ...this.state.usernameTableData]}
                                filename={"LifeNinja-Usernames.csv"}
                                className="btn btn-primary"
                                target="_blank">Download usernames</CSVLink></div> : <span />}
                        {this.state.persisted ? this.state.usernameDetails.map((nextIssue) => {
                            return <div>{nextIssue}</div>;
                        }) : <span />}
                        {this.state.usernameDetails.length > 0 && this.state.persisted ? <div><br /><br /></div> : <span />}
                        {this.state.majorIssues.length > 0 ?
                            <div>
                                <Alert color="danger">
                                    <h3>Csv issues</h3>
                                </Alert>
                                {this.state.majorIssues.map((nextIssue) => {
                                    return <div> - {nextIssue.text} {nextIssue.button != null && nextIssue.callback != null ?
                                        <Button className="adminPagesButton" type="button" onClick={nextIssue.callback}>
                                            {nextIssue.button}
                                        </Button> : <span />
                                    }</div>;
                                })}
                                <br /><br />
                            </div> : <span />
                        }
                        {this.state.newAccounts != null ?
                            <div>
                                <Alert color="success">
                                    <h3>Upload data</h3>
                                    <p>Check the data and when you are happy press the Save button at the bottom. When all accounts are ticked upload is complete.</p>
                                </Alert>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Additional</th>
                                            <th>Login</th>
                                            <th>Additional logins</th>
                                            <th>Groups</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.newAccounts.map((account) => {
                                            let optionalLoginsString = "";
                                            let ignoredFirst = false;
                                            for (let login of account.logins) {
                                                if (!ignoredFirst) {
                                                    ignoredFirst = true;
                                                } else {
                                                    optionalLoginsString += login.email + " (" + login.name + ") ";
                                                }
                                            }
                                            let nameTag = account.accountStatus != null && account.accountStatus === 'deactivated' ?
                                                <del style={{ "color": "red" }}>
                                                    {account.personalName + " " + account.familyName}
                                                </del>
                                                : account.personalName + " " + account.familyName;
                                            let row = <tr color={account.persisted ? account.issues.length === 0 ? "green" : "red" : account.warnings.length === 0 ? account.information.length === 0 ? "" : "cyan" : "yellow"}>
                                                <th scope="row">
                                                    {account.persisted && account.issues.length === 0 ? <i className="material-icons">check_circle</i> : ""}
                                                    {account.remoteId != null && this.state.accountsById.has(account.remoteId) ?
                                                        <Link to={`accounts/${this.state.accountsById.get(account.remoteId)._id}`}>{nameTag}</Link>
                                                        : nameTag}
                                                </th>
                                                <td>{account.additional}</td>
                                                <td>{account.logins != null && account.logins.length > 0 ? `${account.logins[0].email} ${account.logins[0].name == null || account.logins[0].name === "" ? "" : `(${account.logins[0].name})`}` : ""}</td>
                                                <td>{optionalLoginsString}</td>
                                                <td>{account.groupNames != null ? account.groupNames.join(", ") : ""}</td>
                                            </tr>;
                                            if (account.issues != null && account.issues.length > 0) {
                                                let errorRow = <tr color={"red"}>
                                                    <td colSpan={5}>{account.issues.map(s => <span><i className="material-icons">error</i>{s}<br /></span>)}</td>
                                                </tr>;
                                                return <React.Fragment>
                                                    {row}
                                                    {errorRow}
                                                </React.Fragment>;
                                            } else if (!account.persisted &&
                                                ((account.warnings != null && account.warnings.length > 0)
                                                    || (account.information != null && account.information.length > 0))) {
                                                let errorRow = <tr color={account.warnings.length === 0 ? account.information.length === 0 ? "" : "cyan" : "yellow"}>
                                                    <td colSpan={5}>
                                                        {account.warnings != null ? account.warnings.map(s => <span><i className="material-icons">warning</i>{s}<br /></span>) : <span />}
                                                        {account.information != null ? account.information.map(s => <span><i className="material-icons">info</i>{s}<br /></span>) : <span />}
                                                    </td>
                                                </tr>;
                                                return <React.Fragment>
                                                    {row}
                                                    {errorRow}
                                                </React.Fragment>;
                                            } else {
                                                return row;
                                            }
                                        })}
                                    </tbody>
                                </Table><br />
                                <div>
                                    {this.state.persisted ? <div>Save complete</div> :
                                        <Button disabled={this.state.persisting} color="primary" type="button" onClick={this.persistAccounts}>
                                            {this.state.persisting ? <Spinner size="sm"></Spinner> : <span />} Save accounts
                                </Button>
                                    }
                                </div>
                            </div> : <span />
                        }
                        <br /><br /><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    async prepareUsernameLookups(clearCache: boolean): Promise<void> {
        if (this.state.allUsernames == null || clearCache) {
            const usernamesRef = firebase.firestore().doc(`usernames/${this.props.user.schoolId}`);
            let usernamesSnapshot = await usernamesRef.get();
            this.setState({
                allUsernames: usernamesSnapshot.data() == null ? new Map() : objectToMapConverter(usernamesSnapshot.data().usernames),
            });
        }
    }

    suggestUsername(firstName: string, surname: string): string {
        let firstSuggestion = firstName.replace(/\s/g, '') + surname.replace(/\s/g, '')[0];
        firstSuggestion = firstSuggestion.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
        let suggestion = firstSuggestion + "1";
        for (let i = 2; this.state.allUsernames.has(suggestion) || this.suggestedUsernames.indexOf(suggestion) !== -1; i++) {
            suggestion = firstSuggestion + i;
        }
        this.suggestedUsernames.push(suggestion);
        return suggestion;
    }

    async loadAccounts(): Promise<void> {
        try {
            const accountsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts`).orderByChild('remoteId').startAt(false);
            let snapshot = await accountsRef.once('value');
            let accounts = snapshot.val();
            let newState = new Map<string, Account>();
            for (let nextAccountId in accounts) {
                let nextAccount = Account.fromFirebase(accounts[nextAccountId]);
                nextAccount._id = nextAccountId;
                newState.set(nextAccount.remoteId, nextAccount);
            }

            this.setState({
                accountsById: newState,
            });
        } catch (error) {
            console.log(error);
        }
    }

    csvUploadFail(error: any): void {
        console.log(error);
        this.props.snackbar("Save failed\n" + error);
    }

    async createAccountGroup(groupName: string): Promise<void> {
        let accountGroup = new AccountGroup();
        accountGroup.details.name = groupName;
        accountGroup.details.additional = "";
        accountGroup.details.chatEnabled = false;
        accountGroup.details.leaderboardEnabled = false;
        accountGroup.details.chatType = CHAT_TYPE.groupChatAndPrivate;
        accountGroup.details.moderatorsName = "Moderators";
        accountGroup.details.autoJoin = false;

        let accountGroupData: {} = {
            details: accountGroup.details.toFirebase()
        };

        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/`);
        try {
            await accountGroupRef.push(accountGroupData);
            this.uploadCsv(this.state.latestData);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Create failed")
        }
    }

    async uploadCsv(data: any[]): Promise<void> {
        await this.loadAccounts();
        await this.prepareUsernameLookups(true);

        const randomString = Math.random().toString(36);
        this.setState({
            inputKey: randomString,
            majorIssues: [],
            latestData: data,
        });

        let groupMap: Map<String, string> = new Map();
        for (let nextGroupId of Array.from(this.props.accountGroups.keys())) {
            let nextGroup = this.props.accountGroups.get(nextGroupId);
            groupMap.set(nextGroup.details.name, nextGroupId);
        }

        let uploadRemoteIds: string[] = [];
        let newMajorIssues: CsvIssue[] = [];

        this.setState({
            newAccounts: []
        });
        let newAccountList: AccountStub[] = [];
        if (data.length === 0) {
            newMajorIssues.push({ text: "No data in csv file" });
        } else {
            let validHeaders = [/^firstname$/, /^surname$/, /^accountid$/, /^status$/, /^information$/, /^createusername$/, /^login\d*_name$/, /^login\d*_email$/, /^group\d*$/];
            for (let nextHeader of Object.keys(data[0])) {
                let found = false;
                for (let nextReg of validHeaders) {
                    if (nextReg.test(nextHeader)) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    newMajorIssues.push({ text: `Incorrect header ${nextHeader} found in csv file` });
                }
            }
        }
        let missingGroupIds: string[] = [];
        data.map((nextAccountArray) => {
            if (nextAccountArray['firstname'] == null || nextAccountArray['firstname'].trim() === "" ||
                nextAccountArray['surname'] == null || nextAccountArray['surname'].trim() === "") {
                return "";
            }
            let newGroupIdList = [];
            let newGroupNamesList = [];
            let newLoginsList = [];
            let warnings = [];
            let information = [];
            for (let i = 1; nextAccountArray['group' + i] != null; i++) {
                let nextGroupName = nextAccountArray['group' + i].trim();
                if (nextGroupName !== "") {
                    newGroupNamesList.push(nextGroupName);
                    let groupId = groupMap.get(nextGroupName);
                    if (groupId == null) {
                        warnings.push(`Account group '${nextGroupName}' does not exist, so account membership cannot be set`);
                        if (missingGroupIds.indexOf(nextGroupName) === -1) {
                            missingGroupIds.push(nextGroupName);
                            newMajorIssues.push({ text: `Missing account group '${nextGroupName}'`, button: "Create", callback: () => this.createAccountGroup(nextGroupName) })
                        }
                        console.log();
                    } else {
                        newGroupIdList.push(groupId);
                    }
                }
            }
            for (let i = 1; nextAccountArray[`login${i}_email`] != null && nextAccountArray[`login${i}_email`].trim() !== ""; i++) {
                let nextEmail = nextAccountArray[`login${i}_email`].trim().toLowerCase();
                let nextName = nextAccountArray[`login${i}_name`] == null ? "" : nextAccountArray[`login${i}_name`].trim();
                let re = /\S+@\S+\.\S+/;
                let correct = re.test(nextEmail);
                if (correct) {
                    newLoginsList.push({ name: nextName, email: nextEmail });
                } else {
                    warnings.push(`Email address ${nextEmail} is not a valid email address`);
                }
            }
            if (nextAccountArray['accountid'] != null && nextAccountArray['accountid'].trim() !== "") {
                let nextRemoteId = nextAccountArray['accountid'].trim();
                if (uploadRemoteIds.indexOf(nextRemoteId) !== -1) {
                    newMajorIssues.push({ text: `Multiple entries with same remote id ${nextRemoteId}` });
                    return "";
                } else {
                    uploadRemoteIds.push(nextRemoteId);
                }
                let remoteAccount = this.state.accountsById.get(nextRemoteId);
                if (remoteAccount == null) {
                    if (nextAccountArray['status'] != null && nextAccountArray['status'].trim().toLowerCase() === 'deactivated') {
                        return ""; // No need to do anything because already deactivated
                    }
                    information.push("A new account will be created because the remote id could not be found on existing accounts");
                }
            } else {
                warnings.push('No remote id was specified, so updates to this account will create duplicates');
            }
            let createUsername;
            if (nextAccountArray['createusername'] == null || nextAccountArray['createusername'].toLowerCase() !== "y") {
                createUsername = false;
            } else {
                createUsername = true;
                if (nextAccountArray['status'] == null || nextAccountArray['status'].trim().toLowerCase() !== 'deactivated') {
                    information.push("A username will be created if none exists");
                }
            }

            newAccountList.push({
                remoteId: nextAccountArray['accountid'] == null ? null : nextAccountArray['accountid'].trim(),
                accountStatus: nextAccountArray['status'] == null ? null : nextAccountArray['status'].trim().toLowerCase(),
                personalName: nextAccountArray['firstname'].trim(),
                familyName: nextAccountArray['surname'].trim(),
                additional: nextAccountArray['information'] == null ? "" : nextAccountArray['information'].trim(),
                createUsername: createUsername,
                logins: newLoginsList,
                groupIds: newGroupIdList,
                groupNames: newGroupNamesList,
                warnings: warnings,
                information: information,
            });
            return "";
        });
        this.setState({
            newAccounts: newAccountList.slice(),
            persisted: false,
            majorIssues: newMajorIssues,
            usernameDetails: [],
            usernameTableData: [],
        });
        let jsonString = JSON.stringify({
            data: data,
            output: newAccountList,
        });
        let now = new Date();
        firebase.storage().ref(`csvUploads/${this.props.user.schoolId}/${now.getTime()}.txt`).putString(jsonString);
    }

    async persistAccounts(): Promise<void> {
        console.log("Persist accounts");

        this.setState({
            persisting: true,
        });

        await this.loadAccounts();

        let promises: Promise<any>[] = [];
        this.state.newAccounts.slice().forEach((account) => {
            promises.push(this.createAccount(account));
        });
        await Promise.all(promises);

        let usernamesToAccountIds = new Map<string, string>();
        Array.from(this.newUsernames.keys()).forEach(accountId => {
            usernamesToAccountIds.set(this.newUsernames.get(accountId).newUsername, accountId);
        });
        let failedConfirmationUsernames = await confirmUsernameBatch(this.props.user.schoolId, usernamesToAccountIds);
        if (failedConfirmationUsernames.length > 0) {
            this.props.snackbar("Username clash. Is someone else uploading at the same time?");
        }
        let usernamePromises: Promise<any>[] = [];
        Array.from(this.newUsernames.keys()).forEach((accountId) => {
            if (failedConfirmationUsernames.indexOf(accountId) === -1) {
                let csvAccount = this.newUsernames.get(accountId);
                usernamePromises.push(this.createUsernameDetails(accountId, csvAccount));
            }
        });
        await Promise.all(usernamePromises);


        this.setState({
            persisting: false,
            persisted: true,
        });

        this.props.snackbar("Upload completed");
    }

    async createAccount(csvAccount: AccountStub): Promise<void> {
        let issues = [];
        let account = new Account();
        let existing = false;
        let waitForUsername = false;
        try {
            let deactivateAccount = csvAccount.accountStatus != null && csvAccount.accountStatus === 'deactivated';
            if (csvAccount.remoteId != null && csvAccount.remoteId !== "" && this.state.accountsById.has(csvAccount.remoteId)) {
                let existingAccount = this.state.accountsById.get(csvAccount.remoteId);
                if (existingAccount != null) {
                    if (account.deactivated && !deactivateAccount) {
                        account = new Account();
                    } else {
                        account = existingAccount;
                        existing = true;
                    }
                } else {
                    issues.push(`Unable to find account ${csvAccount.remoteId}`);
                }
            } else {
                let uniqueIdHopefully = uuidv4().substring(2, 5).toLowerCase();
                uniqueIdHopefully += new Date().getTime().toString(36);
                account.sharingCode = uniqueIdHopefully;
            }

            if (existing && deactivateAccount) {
                let assignedLicensesDoc = await firebase.firestore().doc(`schoolLicenses/${this.props.user.schoolId}/students/${account._id}`).get();
                let assignedLicenses = assignedLicensesDoc.data();

                let promises = [];
                if (assignedLicenses != null) {
                    let removeAccountLicenses = firebase.functions().httpsCallable('removeAccountLicenses');
                    for (let nextLicenseId of Object.keys(assignedLicenses)) {
                        if (assignedLicenses[nextLicenseId]) {
                            promises.push(removeAccountLicenses({ "schoolId": this.props.user.schoolId, "accountIds": [account._id], "licenseId": nextLicenseId }));
                        }
                    }
                }
            }

            if (deactivateAccount) {
                account.deactivated = true;
                account.title = "";
                account.personalName = "Removed";
                account.familyName = "Removed";
                account.name = "Removed";
                account.additional = "";
                account.remoteId = null;
            } else {
                account.remoteId = csvAccount.remoteId;
                account.personalName = csvAccount.personalName;
                account.familyName = csvAccount.familyName;
                account.name = account.personalName + " " + account.familyName;
                account.additional = csvAccount.additional;
            }
            account.subscribed = true;

            let subAccounts = new Map<string, string>();;
            let subAccountDetails = new Map<string, AccountSubAccount>();
            if (existing) {
                const subAccountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${account._id}`);
                let subAccountSnapshot = await subAccountRef.once('value');
                let newSubAccountsData = subAccountSnapshot.val();
                if (newSubAccountsData != null) {
                    for (let nextSubAccountId in newSubAccountsData.emails) {
                        subAccounts.set(nextSubAccountId, newSubAccountsData.emails[nextSubAccountId]);
                    }
                    for (let nextSubAccountId in newSubAccountsData.subAccounts) {
                        subAccountDetails.set(nextSubAccountId, AccountSubAccount.fromFirebase(newSubAccountsData.subAccounts[nextSubAccountId]));
                    }
                }
            }

            let accountData: {} = account.toFirebase();

            let accountId;
            if (!existing) {
                const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/`);
                let promise = accountRef.push(accountData);
                accountId = promise.key;
                account._id = accountId;
                await promise;
            } else {
                const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/${account._id}`);
                await accountRef.update(accountData);
                accountId = account._id;
            }

            let emails = [];
            if (!account.deactivated) {
                for (let login of csvAccount.logins) {
                    emails.push(login.email);
                    await this.updateSubAccount(accountId, login.email, login.name === "" ? null : login.name, subAccounts);
                }
            }
            let existingUsername = false;
            if (existing) {
                for (let subAccountId of subAccountDetails.keys()) {
                    if (account.deactivated) {
                        await this.handleRemoveSubAccount(accountId, subAccountId);
                    } else {
                        let subAccountEmail = subAccountDetails.get(subAccountId).email;
                        let subAccountType = subAccountDetails.get(subAccountId).accountType;
                        if (subAccountType === SUB_ACCOUNT_TYPE.EMAIL && !emails.includes(subAccountEmail)) {
                            await this.handleRemoveSubAccount(accountId, subAccountId);
                        } else if (subAccountType === SUB_ACCOUNT_TYPE.USERNAME) {
                            existingUsername = true;
                        }
                    }
                }
            }

            if (!account.deactivated && !existingUsername && csvAccount.createUsername) {
                waitForUsername = true;
                csvAccount.newUsername = this.suggestUsername(account.personalName, account.familyName);
                this.newUsernames.set(accountId, csvAccount);
            }

            if (!account.deactivated) {
                for (let groupId of csvAccount.groupIds) {
                    const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${groupId}/accounts/${accountId}`);
                    await accountRef.set({
                        member: true,
                        updated: firebase.database.ServerValue.TIMESTAMP,
                    }, (error) => {
                        if (error != null) {
                            issues.push(`Failed to store account group ref ${groupId}`);
                            console.log(error);
                        }
                    });
                }
            }
            if (existing && account.acGroups != null) {
                for (let i = 0; i < Array.from(account.acGroups.keys()).length; i++) {
                    let groupId = Array.from(account.acGroups.keys())[i];
                    if (account.deactivated || !csvAccount.groupIds.includes(groupId)) {
                        const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${groupId}/accounts/${accountId}`);
                        await accountRef.set({
                            member: false,
                            updated: firebase.database.ServerValue.TIMESTAMP,
                        }, (error) => {
                            if (error != null) {
                                issues.push(`Failed to store account group ref ${groupId}`);
                                console.log(error);
                            }
                        });
                    }
                }
            }
        } catch (error) {
            issues.push("Failed to store user");
            console.log(error)
        } finally {
            if (!waitForUsername) {
                csvAccount.persisted = true;
            }
            csvAccount.issues = issues;
            this.setState({});
        }
    }

    async updateSubAccount(accountId: string, email: string, relationship: string, existingSubAccounts: Map<string, string>): Promise<void> {
        let subAccountDeets: AccountSubAccountStub = {
            email: email,
            relationship: relationship === "" ? null : relationship,
        };
        if (existingSubAccounts != null && existingSubAccounts.has(email.replace(/\./g, ','))) {
            await this.handleUpdateSubAccount(accountId, existingSubAccounts.get(email.replace(/\./g, ',')), subAccountDeets);
        } else {
            await this.addSubAccount(accountId, subAccountDeets);
        }
    }

    async handleUpdateSubAccount(accountId: string, subAccountId: string, subAccount: AccountSubAccountStub): Promise<void> {
        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${accountId}/subAccounts/${subAccountId}`);
        let subAccountData: {} = Object.assign({}, subAccount);
        await accountGroupRef.update(subAccountData);
        console.log("Updated sub account", subAccountId);
    }

    async handleRemoveSubAccount(accountId: string, subAccountId: string): Promise<void> {
        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${accountId}/subAccounts/${subAccountId}`);
        await accountGroupRef.remove();
        console.log("Remove sub account", subAccountId);
    }

    async addSubAccount(accountId: string, subAccount: AccountSubAccountStub): Promise<void> {
        let newEmailEscaped = subAccount.email.replace(/\./g, ',');
        let subAccountId = uuidv4();
        const subAccountEmailRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${accountId}/emails/${newEmailEscaped}`);
        await subAccountEmailRef.set(subAccountId);
        const subAccountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${accountId}/subAccounts/${subAccountId}`);
        let subAccountData: {} = Object.assign({}, subAccount);
        await subAccountGroupRef.set(subAccountData);
        console.log("Added sub account", subAccountId);
    }

    async createUsernameDetails(accountId: string, csvAccount: AccountStub): Promise<void> {
        let newUsername = csvAccount.newUsername
        let personalName = csvAccount.personalName;
        let familyName = csvAccount.familyName;
        let remoteId = csvAccount.remoteId;

        let newEmail = newUsername.toLowerCase();

        newEmail = `${newEmail}_${this.props.user.schoolId}@usernames.lifeninja.net`.toLowerCase();

        let subAccountId = uuidv4();
        let subAccountDeets = {
            email: newEmail,
            accountType: "username",
            mnAccess: "view",
        };

        try {
            let newEmailEscaped = newEmail.replace(/\./g, ',');
            const subAccountEmailRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${accountId}/emails/${newEmailEscaped}`);
            try {
                await subAccountEmailRef.set(subAccountId);
                const subAccountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${accountId}/subAccounts/${subAccountId}`);
                await subAccountGroupRef.set(subAccountDeets);
            } catch (error) {
                console.log("Change email failed");
                console.log("Clearing email entry");
                const subAccountEmailRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${accountId}/emails/${newEmailEscaped}`);
                try {
                    await subAccountEmailRef.remove();
                } catch (error) {
                    this.props.snackbar("Email issue - contact support");
                    return;
                }
            }

            let password = await createUsername(accountId, subAccountId);

            this.state.usernameDetails.push(`${personalName} ${familyName} - ${newUsername} - ${password}`);
            this.state.usernameTableData.push([remoteId, personalName, familyName, newUsername, password]);
        } catch (error) {
            console.log(error);
            csvAccount.issues.push(`Failed to create username ${newUsername}`);
        } finally {
            csvAccount.persisted = true;
            this.setState({
            })
        }
    }
}

export default AccountsCsvView;
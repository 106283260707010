import React, { Component } from 'react';
import firebase from '../firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";
import queryString from 'query-string';
import { User } from '../data/user';
import { Product } from '../data/marketplace';

interface IState {
    products: Map<string, Product>;
    productIdsSorted: string[];
    moreProducts: boolean;
}

interface IProps extends RouteComponentProps {
    tutorialCallback: (pageName: string) => void;
    user: User;
    snackbar: (text?: string) => void;
}

class ProductsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            products: new Map(),
            productIdsSorted: [],
            moreProducts: true,
        };
        this.removeItem = this.removeItem.bind(this);
        this.getNextProductsBatch = this.getNextProductsBatch.bind(this);
        this.makeShortString = this.makeShortString.bind(this);
        this.getNextProductsBatch = this.getNextProductsBatch.bind(this);
    }

    render(): JSX.Element {

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All Products</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Products</div>
                    <p className="cardSubTitle">All the Products that have been shared with accouts. These appear in the products feed of users linked to the accounts.</p>
                <div>
                    <Link to={'/product/-1'}>
                        <Button className="adminPagesButton" type="button" onClick={() => this.props.tutorialCallback('products')}>
                            Create Product
                        </Button>
                    </Link>
                    <Link to={'/categories'}>
                        <Button style={{marginLeft: '10px'}} className="adminPagesButton" type="button" onClick={() => this.props.tutorialCallback('categories')}>
                            Manage Categories
                        </Button>
                    </Link>
                    <a target="_blank" href="https://docs.google.com/document/d/1jJsClYOdbT31jcB-zAT_Ye0wWnkqTNhpy19V7oE98OE/edit">
                        <Button style={{marginLeft:"15px"}} className="altButton">Read Tutorial</Button>
                    </a>
                </div>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Variants</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.productIdsSorted.map((productId) => {
                            let product = this.state.products.get(productId);
                            if (product == null) {
                                return null;
                            }
                            return <tr>
                                <th scope="row" ><Link to={'/product/' + productId}>{product.name}</Link></th>
                                <td>{this.makeShortString(product.description)}</td>
                                <td>{ }</td>
                                <td><Button color="link" onClick={async () => {
                                    let result = await confirm({ title: "Confirm", message: "Please Confirm you want to delete this Product", confirmText: "Confirm" });
                                    if (result) {
                                        this.removeItem(productId);
                                    }
                                }}><i className="material-icons">delete</i></Button></td>
                            </tr>
                        })}
                    </tbody>
                </Table><br />
                {this.state.moreProducts ?
                    <div>
                        <p>Showing {this.state.productIdsSorted.length}</p>
                        <Button className="adminPagesButton" type="button"
                            onClick={() => {
                                this.getNextProductsBatch(false);
                            }}>
                            Show More
                    </Button>
                    </div> : <span />
                }
                </CardBody>
                </Card>
            </div>
        );
    }

    makeShortString(description: string): string {
        if (description == null) {
            return "";
        }
        if (description.length < 30) {
            return description;
        }
        let descriptionResult = description.substr(0, 30);
        let spacePos = descriptionResult.lastIndexOf(" ");
        if (spacePos !== -1) {
            descriptionResult = descriptionResult.substr(0, spacePos);
        }
        descriptionResult += "...";

        return descriptionResult;
    }

    componentDidMount(): void {
        this.getNextProductsBatch(true);
    }

    async getNextProductsBatch(seed: boolean): Promise<void> {
        if (seed != null && seed) {
            this.setState({
                products: new Map(),
                productIdsSorted: [],
                moreProducts: true,
            });
        }
        let productsRef = firebase.firestore().collection(`marketplace/${this.props.user.schoolId}/products`)
            .where('removed', '==', null)
            .orderBy('updateDate');

        if (seed == null || !seed) {
            // Find last homework
            let lastId = this.state.productIdsSorted[this.state.productIdsSorted.length - 1];
            if (lastId != null) {
                productsRef = productsRef.endAt(this.state.products.get(lastId).updateDate);
            }
            productsRef = productsRef.limitToLast(11);
        } else {
            productsRef = productsRef.limitToLast(10);
        }
        try {
            let snapshot = await productsRef.get();
            let newMoreProducts = this.state.moreProducts;
            let products = snapshot.docs;

            let newProducts = new Map<string, Product>();
            let newProductIdsSorted: string[] = [];
            if (products != null) {
                for (let nextProductSnapshot of products) {
                    let nextProductId = nextProductSnapshot.id;
                    if (!this.state.products.has(nextProductId)) {
                        newProducts.set(nextProductId, Product.fromFirebase(nextProductSnapshot.data()));
                        newProductIdsSorted.push(nextProductId);
                    }
                }
            }
            newProductIdsSorted.reverse();

            if (newProductIdsSorted.length < 10) {
                newMoreProducts = false;
            }
            this.setState({
                productIdsSorted: [...this.state.productIdsSorted, ...newProductIdsSorted],
                products: new Map([...this.state.products, ...newProducts]),
                moreProducts: newMoreProducts,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(productId: string): Promise<void> {

        let batch = firebase.firestore().batch();
        try {
            let overviewPath = `marketplace/${this.props.user.schoolId}`;
            let productPath = `${overviewPath}/products/${productId}`;

            batch.update(firebase.firestore().doc(productPath), {
                removed: true,
            });

            batch.set(firebase.firestore().doc(overviewPath), {
                products: {
                    [productId]: firebase.firestore.FieldValue.delete(),
                }
            }, { merge: true });

            await batch.commit();

            this.state.products.delete(productId);
            this.state.productIdsSorted.splice(this.state.productIdsSorted.indexOf(productId), 1);

            this.setState({});
            this.props.snackbar("Deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }
}
export default ProductsView;
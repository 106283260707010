import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Card, CardBody, Col, Row,
    CardHeader, Button, BreadcrumbItem, Breadcrumb, Alert
} from 'reactstrap';

class ReportType {
    title: string;
    image: string;
    path: string;
    description: string;
}

interface IState {
}

interface IProps {
}

class LearningNinjaReportsOverview extends Component<IProps, IState> {
    reports = new Array<ReportType>();

    constructor(props: IProps) {
        super(props);
        this.state = {
        };
        this.createCard = this.createCard.bind(this);

        let groupObjectivesReport = new ReportType();
        groupObjectivesReport.title = "Objectives report - student group";
        groupObjectivesReport.description = "Report the progress of student groups against UK curriculum objectives";
        groupObjectivesReport.image = "groupObjectives.png";
        groupObjectivesReport.path = "groupObjectives";

        let studentObjectivesReport = new ReportType();
        studentObjectivesReport.title = "Objectives report - individual student";
        studentObjectivesReport.description = "Report the progress of a single student against UK curriculum objectives";
        studentObjectivesReport.image = "studentObjectives.png";
        studentObjectivesReport.path = "studentObjectives";

        let topicAnalysisReport = new ReportType();
        topicAnalysisReport.title = "Reading records";
        topicAnalysisReport.description = "View daily counts and student counts.";
        topicAnalysisReport.image = "readingRecords.png";
        topicAnalysisReport.path = "readingRecords";

        let appDownloadsReport = new ReportType();
        appDownloadsReport.title = "App usage";
        appDownloadsReport.description = "View whether Learning Ninja has been downloaded and student last usage date.";
        appDownloadsReport.image = "appDownloads.png";
        appDownloadsReport.path = "appDownloads?appFilter=LearningNinja";

        let learningPathAnalysisReport = new ReportType();
        learningPathAnalysisReport.title = "Learning path analysis";
        learningPathAnalysisReport.description = "View student engagement, flawless, and time played within a specific learning path.";
        learningPathAnalysisReport.image = "learningPathAnalysis.png";
        learningPathAnalysisReport.path = "learningPathAnalysis?appFilter=LearningNinja";

        this.reports.push(groupObjectivesReport, studentObjectivesReport, topicAnalysisReport, appDownloadsReport, learningPathAnalysisReport);
    }

    createCard(nextReportIndex: number): JSX.Element {
        let report = this.reports[nextReportIndex];

        return <Card style={{ width: "100%" }}>
            <CardHeader><b>{report.title}</b></CardHeader>
            <CardBody className="d-flex flex-column">
                <div>
                    <Row>
                        <Col key={`column1-${nextReportIndex}`} md="4">
                            <img className={"img-fluid"} src={`/images/reports/${report.image}`} alt="" />
                        </Col>
                        <Col key={`column2-${nextReportIndex}`} md="6">
                            {report.description}
                        </Col>
                        <Col key={`column3-${nextReportIndex}`} md="2">
                            <Link to={`/reporting/${report.path}`}>
                                <Button className="altButton" style={{float:'right'}} type="button">
                                    Open
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    }

    render(): JSX.Element {
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All reports</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Learning Ninja Reports</div>
                        <p className="cardSubTitle">A selection of reports suitable for Learning Ninja</p>
                        {this.reports.map((report, index) => {
                            return <div>
                                <div>
                                    {this.createCard(index)}
                                </div>
                                <br />
                            </div>
                        })}
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default LearningNinjaReportsOverview;
import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, Modal, ModalHeader, ModalBody, Card, CardHeader, Collapse, CardBody, InputGroup, InputGroupAddon, UncontrolledTooltip, Spinner
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import ImageUpload from "./ninja_components/image_upload";
import AttachmentUpload from "./ninja_components/attachment_upload";
import IconSelection from "./ninja_components/icon_selection";
import EmojiSelection from "./ninja_components/emoji_selection";
import confirm from "reactstrap-confirm";
import { NewsItemAccount, NewsItem, NewsItemAccountGroup, NewsItemDetails, NewsItemResponses, NewsItemReward } from './data/news_items';
import { User } from './data/user';
import { AccountGroup, AccountMinimised } from './data/accounts';
import { Attachment } from './data/home_learning';
import { EngagementAward } from './data/engagement';

interface IState {
    message: NewsItem,
    messageId: string;
    currentMessageTitle: string;
    currentMessageDescription: string;
    currentMessageImageUrl: string;
    currentMessageImagePath: string;
    currentMessageAttachments: Attachment[],
    currentMessageIcon: string;
    currentMessageIconFamily: string;
    currentMessageAccounts: Map<string, NewsItemAccount>,
    currentMessageAccountGroups: Map<string, NewsItemAccountGroup>,
    currentMessageSentFrom: string;
    currentMessageWebLink: string;
    currentMessageShowAvatar: boolean;
    currentMessageRestricted: boolean;
    currentMessageRewardPoints: number;
    currentMessageRewardReason: string;
    currentMessageRewardImageUrl: string;
    currentMessageRewardId: string;
    newMessageAccountGroup: string;
    newMessageAccount: string;
    newRecipientsView: boolean;
    filter: string;
    newMessageAccountGroupFutures: boolean;
    collapse: boolean;
    award: boolean;
    awards: Map<string, string>;
    awardIdsSorted: Array<string>;
    createReward: boolean;
    selectAward: boolean;
    tempAwardId: string;
    tempRewardPoints: number;
    tempRewardReason: string;
}

interface MatchParams {
    messageId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    accountsMinimised: Map<string, AccountMinimised>;
    accountGroups: Map<string, AccountGroup>;
    snackbar: (text?: string) => void;
}

interface NewsItemAccountStub {
    id: string;
    name: string;
    additional: string;
    read: boolean;
    liked: boolean;
}

class MessageView extends Component<IProps, IState> {
    listenRef: firebase.database.Reference;

    constructor(props: IProps) {
        super(props);
        this.state = {
            message: null,
            messageId: props.match.params.messageId,
            currentMessageTitle: '',
            currentMessageDescription: '',
            currentMessageImageUrl: '',
            currentMessageImagePath: '',
            currentMessageAttachments: [],
            currentMessageIcon: null,
            currentMessageIconFamily: '',
            currentMessageAccounts: new Map(),
            currentMessageAccountGroups: new Map(),
            currentMessageSentFrom: '',
            currentMessageWebLink: '',
            currentMessageShowAvatar: true,
            currentMessageRestricted: false,
            currentMessageRewardPoints: null,
            currentMessageRewardReason: null,
            currentMessageRewardImageUrl: null,
            currentMessageRewardId: null,
            newMessageAccountGroup: '',
            newMessageAccount: '',
            newRecipientsView: false,
            filter: 'NAME',
            newMessageAccountGroupFutures: true,
            collapse: props.match.params.messageId !== "-1",
            award: false,
            awards: new Map(),
            awardIdsSorted: [],
            createReward: false,
            selectAward: false,
            tempAwardId: null,
            tempRewardPoints: 0,
            tempRewardReason: "",
        };

        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleRecipientAdd = this.handleRecipientAdd.bind(this);
        this.handleRecipientGroupAdd = this.handleRecipientGroupAdd.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleAttachmentChange = this.handleAttachmentChange.bind(this);
        this.handleIconChange = this.handleIconChange.bind(this);
        this.toggleNewRecipientsModal = this.toggleNewRecipientsModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleEmojiChange = this.handleEmojiChange.bind(this);
        this.handleImageChangeFail = this.handleImageChangeFail.bind(this);
        this.handleAttachmentChangeFail = this.handleAttachmentChangeFail.bind(this);
        this.startResponseListener = this.startResponseListener.bind(this);
        this.toggleRecipientGroup = this.toggleRecipientGroup.bind(this);
        this.toggleCreateReward = this.toggleCreateReward.bind(this);
        this.toggleSelectAward = this.toggleSelectAward.bind(this);
        this.storeAwardId = this.storeAwardId.bind(this);
    }

    render(): JSX.Element {

        let orderedAccountIds = new Array<NewsItemAccountStub>();
        let usedAccountIds = new Array<string>();
        let totalRead = 0;
        let totalLiked = 0;
        Array.from(this.state.currentMessageAccounts.keys()).forEach((accountId) => {
            let accountRecipientDeets = this.state.currentMessageAccounts.get(accountId);
            if (accountRecipientDeets.recipient != null && accountRecipientDeets.recipient) {
                usedAccountIds.push(accountId);
                let account = this.props.accountsMinimised.get(accountId);
                let name;
                let additional;
                if (account == null) {
                    name = "Deactivated account";
                    additional = "";
                } else {
                    name = account.name;
                    additional = account.additional;
                }
                let read = this.state.message.responses != null && this.state.message.responses.read != null && this.state.message.responses.read.has(accountId) && this.state.message.responses.read.get(accountId);
                let liked = this.state.message.responses != null && this.state.message.responses.likes != null && this.state.message.responses.likes.has(accountId);
                orderedAccountIds.push({
                    id: accountId,
                    name: name,
                    additional: additional,
                    read: read,
                    liked: liked,
                });
                if (read) totalRead++;
                if (liked) totalLiked++;
            }
        });
        orderedAccountIds.sort((entry1, entry2) => {
            if (this.state.filter === 'READ') {
                if (entry1.read && !entry2.read) {
                    return -1;
                } else if (entry2.read && !entry1.read) {
                    return 1;
                }
            } else if (this.state.filter === 'LIKED') {
                if (entry1.liked && !entry2.liked) {
                    return -1;
                } else if (entry2.liked && !entry1.liked) {
                    return 1;
                }
            }
            return entry1.name.localeCompare(entry2.name);
        });

        let orderedAllIds = Array.from(this.props.accountsMinimised.keys());
        orderedAllIds = orderedAllIds.filter((el) => {
            return !usedAccountIds.includes(el);
        });
        orderedAllIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).getLowercaseName().localeCompare(this.props.accountsMinimised.get(id2).getLowercaseName());
        });

        let orderedAllGroupIds = Array.from(this.props.accountGroups.keys());
        orderedAllGroupIds.sort((id1, id2) => {
            return this.props.accountGroups.get(id1).details.getLowercaseName().localeCompare(this.props.accountGroups.get(id2).details.getLowercaseName());
        });

        let futureGroupIds = Array.from(this.state.currentMessageAccountGroups.keys());
        futureGroupIds = futureGroupIds.filter((e1) => {
            return this.state.currentMessageAccountGroups.get(e1).futureMembers;
        });
        futureGroupIds.sort((id1, id2) => {
            return this.props.accountGroups.get(id1).details.getLowercaseName().localeCompare(this.props.accountGroups.get(id2).details.getLowercaseName());
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/messages'}>All News Items</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.message != null ? this.state.message.details.title : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">News Items</div>
                        <p className="cardSubTitle">A news item/notice is sent to specified accounts. All linked users are notified when a news item is sent.</p>
                        {this.state.message != null ?
                            <div>
                                {this.state.messageId !== "-1" ?
                                    <Button onClick={this.toggle} className="altButton">
                                        {this.state.collapse ? "View details" : "Close"}
                                    </Button> : null
                                }
                                <Collapse isOpen={!this.state.collapse}>
                                    <Form onSubmit={this.handleUpdate}>
                                        <FormGroup>
                                            <Label for="currentMessageTitle">Title *</Label>
                                            <InputGroup>
                                                <Input type="text" required name="currentMessageTitle" onChange={(e) => this.setState({
                                                    currentMessageTitle: e.target.value
                                                })} value={this.state.currentMessageTitle} />
                                                <InputGroupAddon addonType={"prepend"}>
                                                    <EmojiSelection callback={(emoji) => {
                                                        this.handleEmojiChange(emoji, true);
                                                    }} />
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentMessageDescription">Description *</Label>
                                            <InputGroup>
                                                <Input type="textarea" required name="currentMessageDescription" onChange={(e) => this.setState({
                                                    currentMessageDescription: e.target.value
                                                })} value={this.state.currentMessageDescription} />
                                                <InputGroupAddon addonType={"prepend"}>
                                                    <EmojiSelection callback={(emoji) => {
                                                        this.handleEmojiChange(emoji, false);
                                                    }} />
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <IconSelection callback={this.handleIconChange} currentIcon={this.state.currentMessageIcon} currentIconFamily={this.state.currentMessageIconFamily} />
                                        <ImageUpload callback={this.handleImageChange} failureCallback={this.handleImageChangeFail} schoolId={this.props.user.schoolId} filePath={"messageImages"}
                                            currentImage={{ url: this.state.currentMessageImageUrl, path: this.state.currentMessageImagePath }} />
                                        <AttachmentUpload callback={this.handleAttachmentChange} failureCallback={this.handleAttachmentChangeFail} filePath={"messageAttachments"}
                                            schoolId={this.props.user.schoolId} currentAttachments={this.state.currentMessageAttachments} />
                                        <FormGroup>
                                            <Label for="currentMessageSentFrom">Sent from</Label>
                                            <Input type="text" name="currentMessageSentFrom" onChange={(e) => this.setState({
                                                currentMessageSentFrom: e.target.value
                                            })} value={this.state.currentMessageSentFrom} />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>Reward</Label>
                                            {this.state.currentMessageRewardId != null ?
                                                <div>
                                                    &nbsp;&nbsp;Award: {this.state.currentMessageRewardReason}<br />
                                                        &nbsp;&nbsp;Points: {this.state.currentMessageRewardPoints}
                                                </div> :
                                                this.state.currentMessageRewardPoints != null && this.state.currentMessageRewardPoints !== 0 ?
                                                    <div>
                                                        &nbsp;&nbsp;Reason: {this.state.currentMessageRewardReason}<br />
                                                            &nbsp;&nbsp;Points: {this.state.currentMessageRewardPoints}
                                                    </div> : <span />
                                            }
                                                &nbsp;&nbsp;{this.state.currentMessageRewardId != null || this.state.currentMessageRewardPoints != null ?
                                                <Button color="success"
                                                    onClick={() => this.setState({
                                                        currentMessageRewardId: null,
                                                        currentMessageRewardPoints: null,
                                                        currentMessageRewardReason: null,
                                                        currentMessageRewardImageUrl: null,
                                                    })}>
                                                    Remove reward
                                                </Button> :
                                                <div>
                                                    &nbsp;&nbsp;No reward specified<br />
                                                        &nbsp;&nbsp;<Button className="adminPagesButton"
                                                        onClick={this.toggleSelectAward}>
                                                        Add award
                                                    </Button>&nbsp;
                                                    <Button className="adminPagesButton"
                                                        onClick={this.toggleCreateReward}>
                                                        Create custom reward
                                                    </Button>
                                                </div>
                                            }
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="currentMessageWebLink">Web link</Label>
                                            <Input type="url" name="currentMessageWebLink" onChange={(e) => this.setState({
                                                currentMessageWebLink: e.target.value
                                            })} value={this.state.currentMessageWebLink} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Show my avatar? *</Label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="radio" value="true" name='currentMessageShowAvatar'
                                                        checked={this.state.currentMessageShowAvatar}
                                                        onChange={(e) => this.setState({
                                                            currentMessageShowAvatar: e.target.checked
                                                        })} />
                                                        &nbsp;Yes
                                                    </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="radio" value="false" name='currentMessageShowAvatar'
                                                        checked={!this.state.currentMessageShowAvatar}
                                                        onChange={(e) => this.setState({
                                                            currentMessageShowAvatar: !e.target.checked
                                                        })} />
                                                        &nbsp;No
                                                    </Label>
                                            </FormGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Restricted to full access logins only? * <i className="fas fa-info-circle icons-info" id="help-newsitem-restricted" /></Label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="radio" value="true" name='currentMessageRestricted'
                                                        checked={this.state.currentMessageRestricted}
                                                        onChange={(e) => this.setState({
                                                            currentMessageRestricted: e.target.checked
                                                        })} />
                                                        &nbsp;Yes
                                                    </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="radio" value="false" name='currentMessageRestricted'
                                                        checked={!this.state.currentMessageRestricted}
                                                        onChange={(e) => this.setState({
                                                            currentMessageRestricted: !e.target.checked
                                                        })} />
                                                        &nbsp;No
                                                    </Label>
                                            </FormGroup>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-newsitem-restricted">
                                                Full access is generally set for parents/guardians. Any news items that students should not see should be marked as restricted.
                                                </UncontrolledTooltip>
                                        </FormGroup>
                                        {this.state.messageId === "-1" ?
                                            <div><Button className="adminPagesButton" >Create</Button></div> : <div><Button className="adminPagesButton">Update</Button></div>
                                        }
                                    </Form>
                                </Collapse></div> : <Spinner />
                        }
                    </CardBody></Card>
                {this.state.message != null ?
                    <div>
                        {this.state.messageId === "-1" ? <br /> :
                            <React.Fragment>
                                <Card className="mainCard top-buffer">
                                    <CardBody className="d-flex flex-column">
                                        <div className="cardTitle2">Recipients {orderedAccountIds.length > 0 ? ` (${orderedAccountIds.length})` : ""}</div>
                                        <div><Button onClick={this.toggleNewRecipientsModal} className="adminPagesButton buttonBuffer">
                                            Add recipients
                                    </Button></div>
                                        {orderedAccountIds.length > 0 ?
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Name <Button disabled={this.state.filter === 'NAME'} color="link" onClick={async () => {
                                                            this.setState({
                                                                filter: 'NAME'
                                                            })
                                                        }}><i className="material-icons material-icons-xs">sort</i></Button></th>
                                                        <th>Additional</th>
                                                        <th>Read {totalRead > 0 ? `(${totalRead})` : ""} <Button disabled={this.state.filter === 'READ'} color="link" onClick={async () => {
                                                            this.setState({
                                                                filter: 'READ'
                                                            })
                                                        }}><i className="material-icons material-icons-xs">sort</i></Button></th>
                                                        <th>Liked {totalLiked > 0 ? `(${totalLiked})` : ""} <Button disabled={this.state.filter === 'LIKED'} color="link" onClick={async () => {
                                                            this.setState({
                                                                filter: 'LIKED'
                                                            })
                                                        }}><i className="material-icons material-icons-xs">sort</i></Button></th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderedAccountIds.map((accountEntry) => {
                                                        let accountId = accountEntry.id;

                                                        return <tr key={accountId + 'tr'}>
                                                            <th scope="row" key={accountId + 'th'}><Link to={'/accounts/' + accountId}>{accountEntry.name}</Link></th>
                                                            <td key={accountId + 'td1'}>{accountEntry.additional}</td>
                                                            <td key={accountId + 'td2'}>{accountEntry.read ? "Yes" : "No"}</td>
                                                            <td key={accountId + 'td3'}>{accountEntry.liked ? "Yes" : "No"}</td>
                                                            <td key={accountId + 'td4'}>
                                                                <Button key={accountId + 'button'} color="link" onClick={async () => {
                                                                    let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove this recipient", confirmText: "Confirm" });
                                                                    if (result) {
                                                                        this.removeRecipient(accountId)
                                                                    }
                                                                }}>
                                                                    <i className="material-icons">delete</i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </Table> : <div>No recipients</div>
                                        }
                                    </CardBody>
                                </Card>
                                {futureGroupIds.length <= 0 ? <br /> :
                                    <Card className="mainCard top-buffer">
                                        <CardBody className="d-flex flex-column">
                                            <div className="cardTitle2">Invite future account group members <i className="fas fa-info-circle icons-info" id="help-messages-futureMembers" /></div>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-messages-futureMembers">
                                                Adding an account to an account group can automatically add the account to this news item.
                                                Simply add the account group to the invitees above and select to include future members.
                                                </UncontrolledTooltip>
                                            <p className="cardSubTitle">Note this will only applies for four weeks after the news item was posted. <i className="fas fa-info-circle icons-info" id="help-messages-futureMembersExpire" /></p>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-messages-futureMembersExpire">
                                                Once a news item is more than four weeks in the past new members of the group will no longer be added to the news item.
                                                Otherwise new group members would have their news feed filled with historical news items that are not relevant.
                                                Note that you can manually add accounts to historical news items if required.
                                                </UncontrolledTooltip>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Account group name</th>
                                                        <th>New account group members see news item</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {futureGroupIds.map((accountGroupId) => {
                                                        let accountGroup = this.props.accountGroups.get(accountGroupId);
                                                        let accountGroupRecipientDeets = this.state.currentMessageAccountGroups.get(accountGroupId);
                                                        let name;
                                                        if (accountGroup == null || !accountGroupRecipientDeets.futureMembers) {
                                                            return "";
                                                        } else {
                                                            name = accountGroup.details.name;
                                                        }
                                                        return <tr key={accountGroupId + 'tr'}>
                                                            <th scope="row" key={accountGroupId + 'th'}><Link to={`/accountGroups/${accountGroupId}`}>{name}</Link></th>
                                                            <td key={accountGroupId + 'td1'}>{accountGroupRecipientDeets.futureMembers ? "Yes" : "No"}</td>
                                                            <td key={accountGroupId + 'td5'}>
                                                                <Button key={accountGroupId + 'button'} color="link" onClick={async () => {
                                                                    let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove this account group linkage", confirmText: "Confirm" });
                                                                    if (result) {
                                                                        this.toggleRecipientGroup(accountGroupId, false)
                                                                    }
                                                                }}>
                                                                    <i className="material-icons">delete</i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                }
                            </React.Fragment>
                        }
                        <br /><br /><br /><br /><br /><br />
                    </div> : <br />
                }
                <Modal isOpen={this.state.createReward} toggle={this.toggleCreateReward}>
                    <ModalHeader toggle={this.toggleCreateReward}>Create reward</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="tempRewardPoints">Reward points <i className="fas fa-info-circle icons-info" id="help-newsitem-rewardpoints" /></Label>
                            <Input type="number" name="tempRewardPoints" min={1} onChange={(e) => this.setState({
                                tempRewardPoints: e.target.value === "" ? 0 : parseInt(e.target.value)
                            })} value={this.state.tempRewardPoints} />
                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-newsitem-rewardpoints">
                                Does the student deserve reward points for this news item
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <Label for="tempRewardReason">Reason for reward</Label>
                            <Input type="text" name="tempRewardReason" onChange={(e) => this.setState({
                                tempRewardReason: e.target.value
                            })} value={this.state.tempRewardReason} />
                        </FormGroup>
                        <Button className="adminPagesButton"
                            disabled={this.state.tempRewardPoints == null || this.state.tempRewardPoints === 0}
                            onClick={() => this.setState({
                                createReward: false,
                                currentMessageRewardPoints: this.state.tempRewardPoints,
                                tempRewardPoints: 0,
                                currentMessageRewardReason: this.state.tempRewardReason,
                                tempRewardReason: "",
                            })}>
                            Create
                        </Button>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.selectAward} toggle={this.toggleSelectAward}>
                    <ModalHeader toggle={this.toggleSelectAward}>Select award</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="tempAwardId">Awards</Label>
                            <Input type="select" required name="tempAwardId"
                                onChange={(e) => this.setState({
                                    tempAwardId: e.target.value
                                })}>
                                <option value={""}>&nbsp;</option>
                                {this.state.awardIdsSorted.map((awardId) => {
                                    return (
                                        <option value={awardId}
                                            key={awardId}>{this.state.awards.get(awardId)}</option>
                                    )
                                })
                                }
                            </Input>
                        </FormGroup>
                        <Button color="success"
                            disabled={this.state.tempAwardId == null || this.state.tempAwardId === ''}
                            onClick={this.storeAwardId}>
                            Set
                        </Button>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.newRecipientsView} toggle={this.toggleNewRecipientsModal}>
                    <ModalHeader toggle={this.toggleNewRecipientsModal}>Add account</ModalHeader>
                    <ModalBody>
                        <div className="border rounded form-margin">
                            <FormGroup>
                                <Label for="newMessageAccount">Accounts</Label>
                                <Input type="select" required name="newMessageAccount"
                                    onChange={(e) => this.setState({
                                        newMessageAccount: e.target.value
                                    })}>
                                    <option value={""}>&nbsp;</option>
                                    {orderedAllIds.map((key) => {
                                        return (
                                            <option value={key}
                                                key={key}>{this.props.accountsMinimised.get(key).name}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup>
                            <Button color="success"
                                disabled={this.state.newMessageAccount == null || this.state.newMessageAccount === ""}
                                onClick={() => { this.handleRecipientAdd(false); }}>
                                Add
                            </Button>&nbsp;
                            <Button color="success"
                                disabled={this.state.newMessageAccount == null || this.state.newMessageAccount === ""}
                                onClick={() => { this.handleRecipientAdd(true); }}>
                                Add & close
                            </Button>
                        </div>
                        <div className="border rounded form-margin">
                            <FormGroup>
                                <Label for="newMessageAccountGroup">Account Groups</Label>
                                <Input type="select" required name="newMessageAccountGroup"
                                    onChange={(e) => this.setState({
                                        newMessageAccountGroup: e.target.value
                                    })} value={this.state.newMessageAccountGroup} label={"bob"}>
                                    <option value={""}>&nbsp;</option>
                                    {orderedAllGroupIds.map((key) => {
                                        return (
                                            <option value={key}
                                                key={key}>{this.props.accountGroups.get(key).details.name}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name='newMessageAccountGroupFutures'
                                        checked={this.state.newMessageAccountGroupFutures}
                                        onChange={(e) => this.setState({
                                            newMessageAccountGroupFutures: e.target.checked
                                        })} />
                                    Future account group members can view news item?
                                </Label>
                            </FormGroup><br />
                            <Button color="success"
                                disabled={this.state.newMessageAccountGroup == null || this.state.newMessageAccountGroup === ""}
                                onClick={() => { this.handleRecipientGroupAdd(false); }}>
                                Add
                            </Button>&nbsp;
                            <Button color="success"
                                disabled={this.state.newMessageAccountGroup == null || this.state.newMessageAccountGroup === ""}
                                onClick={() => { this.handleRecipientGroupAdd(true); }}>
                                Add & close
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleCreateReward(): void {
        this.setState({ createReward: !this.state.createReward });
    }

    toggleSelectAward(): void {
        this.setState({ selectAward: !this.state.selectAward });
    }

    toggleNewRecipientsModal(): void {
        this.setState({
            newRecipientsView: !this.state.newRecipientsView,
            newMessageAccountGroupFutures: true,
            newMessageAccountGroup: '',
            newMessageAccount: '',
        })
    }

    async storeAwardId(): Promise<void> {
        let path = `engagementAwards/${this.props.user.schoolId}/awards/${this.state.tempAwardId}`;
        let awardSnapshot = await firebase.firestore().doc(path).get();
        let award = EngagementAward.fromFirebase(awardSnapshot.data());

        this.setState({
            currentMessageRewardId: this.state.tempAwardId,
            currentMessageRewardPoints: award.points,
            currentMessageRewardReason: award.title,
            currentMessageRewardImageUrl: award.imageUrl,
            tempAwardId: null,
            selectAward: false,
        });
    }

    handleEmojiChange(emojiCharacter: string, titleEmoji: boolean): void {
        if (titleEmoji) {
            this.setState({
                currentMessageTitle: this.state.currentMessageTitle + emojiCharacter
            });
        } else {
            this.setState({
                currentMessageDescription: this.state.currentMessageDescription + emojiCharacter
            });
        }
    }

    async handleImageChange(url: string, path: string): Promise<void> {
        if (this.state.messageId !== "-1") {
            let imageDeets = {
                imageUrl: url,
                imagePath: path,
            };
            const messageRef = firebase.database().ref(`schoolMessages/messages/${this.props.user.schoolId}/${this.state.messageId}/details`);
            try {
                await messageRef.update(imageDeets);
                this.setState({
                    currentMessageImageUrl: url,
                    currentMessageImagePath: path,
                });
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Update failed");
            }
        } else {
            this.setState({
                currentMessageImageUrl: url,
                currentMessageImagePath: path,
            });
        }
    }

    handleImageChangeFail(): void {
        this.props.snackbar("Image upload failed");
    }

    handleAttachmentChange(currentAttachments: Attachment[]): void {
        this.setState({
            currentMessageAttachments: currentAttachments
        });
    }

    handleAttachmentChangeFail(): void {
        this.props.snackbar("Attachment upload failed");
    }

    handleIconChange(currentIcon: string, currentIconFamily: string): void {
        this.setState({
            currentMessageIcon: currentIcon,
            currentMessageIconFamily: currentIconFamily,
        });
    }

    async handleRecipientAdd(close: boolean): Promise<void> {
        let accountRecipientDeets = {
            recipient: true,
            updated: firebase.database.ServerValue.TIMESTAMP,
        };
        const accountGroupRef = firebase.database().ref(`schoolMessages/messages/${this.props.user.schoolId}/${this.state.messageId}/accounts/${this.state.newMessageAccount}`);
        try {
            await accountGroupRef.set(accountRecipientDeets);

            this.state.currentMessageAccounts.set(this.state.newMessageAccount, { recipient: true, updated: new Date().getTime() });
            this.setState({
                newRecipientsView: close == null ? true : !close,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async handleRecipientGroupAdd(close: boolean): Promise<void> {
        let newGroup = this.props.accountGroups.get(this.state.newMessageAccountGroup);
        await this.toggleRecipientGroup(this.state.newMessageAccountGroup, this.state.newMessageAccountGroupFutures);
        let newUpdate: any = {};
        let newStateUpdate = new Map<string, NewsItemAccount>()
        Array.from(newGroup.accounts.keys()).forEach((accountId) => {
            let nextDeets = newGroup.accounts.get(accountId);
            if (nextDeets.member) {
                newUpdate[accountId] = {
                    recipient: true,
                    updated: firebase.database.ServerValue.TIMESTAMP,
                }
                let newNewsItemAccount = new NewsItemAccount();
                newNewsItemAccount.recipient = true;
                newNewsItemAccount.updated = new Date().getTime();
                newStateUpdate.set(accountId, newNewsItemAccount);
            }
        });
        const accountGroupRef = firebase.database().ref(`schoolMessages/messages/${this.props.user.schoolId}/${this.state.messageId}/accounts/`);
        await accountGroupRef.update(newUpdate, (error) => {
            if (error == null) {
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });

        this.setState({
            currentMessageAccounts: new Map([...this.state.currentMessageAccounts, ...newStateUpdate]),
            newRecipientsView: close == null ? true : !close,
        });
        this.props.snackbar();
    }

    async toggleRecipientGroup(accountGroupId: string, enabled: boolean): Promise<void> {
        let newGroupUpdate = {
            futureMembers: enabled,
            updated: firebase.database.ServerValue.TIMESTAMP
        };
        const accountGroupFutureRef = firebase.database().ref(`schoolMessages/messages/${this.props.user.schoolId}/${this.state.messageId}/accountGroups/${accountGroupId}`);
        await accountGroupFutureRef.update(newGroupUpdate, (error) => {
            if (error == null) {
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
        this.state.currentMessageAccountGroups.set(accountGroupId, { futureMembers: enabled, updated: new Date().getTime() });
        this.setState({});
    }

    async removeRecipient(accountId: string): Promise<void> {
        let accountRecipientDeets = {
            recipient: false,
            updated: firebase.database.ServerValue.TIMESTAMP,
        };
        const accountGroupRef = firebase.database().ref(`schoolMessages/messages/${this.props.user.schoolId}/${this.state.messageId}/accounts/${accountId}`);
        await accountGroupRef.set(accountRecipientDeets, (error) => {
            if (error == null) {
            } else {
                console.log(error);
            }
        });
        this.state.currentMessageAccounts.delete(accountId);
        this.setState({});
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let message = this.state.message;
        if (message.details == null) {
            message.details = new NewsItemDetails();
        }
        if (message.details.reward == null) {
            message.details.reward = new NewsItemReward();
        }
        message.details.title = this.state.currentMessageTitle;
        message.details.description = this.state.currentMessageDescription;
        message.details.imageUrl = this.state.currentMessageImageUrl;
        message.details.imagePath = this.state.currentMessageImagePath;
        message.details.attachments = this.state.currentMessageAttachments;
        message.details.iconCodePoint = 59123;
        message.details.iconCodeString = this.state.currentMessageIcon;
        message.details.iconFontFamily = this.state.currentMessageIconFamily;
        if (message.details.iconFontFamily === "MaterialIcons") {
            message.details.iconFontPackage = null;
        } else {
            message.details.iconFontPackage = "font_awesome_flutter";
        }
        message.details.webLink = this.state.currentMessageWebLink;
        message.details.sentFrom = this.state.currentMessageSentFrom;
        message.details.showAvatar = this.state.currentMessageShowAvatar;
        message.details.restricted = this.state.currentMessageRestricted;
        message.details.reward.points = this.state.currentMessageRewardPoints;
        message.details.reward.reason = this.state.currentMessageRewardReason;
        message.details.reward.imageUrl = this.state.currentMessageRewardImageUrl;
        message.details.reward.awardId = this.state.currentMessageRewardId;

        if (this.state.messageId === "-1") {
            message.details.sentFromId = this.props.user.accountId;
        }

        await this.sendMessage(message);
    }

    async sendMessage(message: NewsItem): Promise<void> {
        if (this.state.messageId === "-1") {
            const messageRef = firebase.database().ref(`schoolMessages/messages/${this.props.user.schoolId}`);
            let promise = messageRef.push({
                details: message.details.toFirebase()
            });
            let newKey = promise.key;
            try {
                await promise
                this.setState({
                    message: message,
                    messageId: newKey,
                    collapse: true,
                });
                this.props.history.replace(`/messages/${newKey}`);
                this.startResponseListener();
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        } else {
            const messageRef = firebase.database().ref(`schoolMessages/messages/${this.props.user.schoolId}/${this.state.messageId}/details`);
            try {
                await messageRef.update(message.details.toFirebase());
                this.setState({
                    message: message,
                    collapse: true,
                });
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Update failed");
            }
        }
    }

    async componentDidMount(): Promise<void> {
        try {
            let awardsSnapshot = await firebase.firestore().doc(`engagementAwards/${this.props.user.schoolId}`).get();
            let awardsData = awardsSnapshot.data();
            let awards = new Map<string, string>();
            if (awardsData != null && awardsData["awardsOverview"] != null) {
                let awardsObject = awardsData["awardsOverview"];
                for (let nextAwardId of Object.keys(awardsObject)) {
                    awards.set(nextAwardId, awardsObject[nextAwardId]);
                }
            }
            let awardIdsSorted = Array.from(awards.keys());
            awardIdsSorted.sort((id1, id2) => {
                return awards.get(id1).localeCompare(awards.get(id2));
            });
            this.setState({
                awards: awards,
                awardIdsSorted: awardIdsSorted,
            });
        } catch (error) {
            console.log(error);
        }

        if (this.state.messageId !== "-1") {
            try {
                const itemsRef = firebase.database().ref(`schoolMessages/messages/${this.props.user.schoolId}/${this.state.messageId}`);
                let messageSnapshot = await itemsRef.once('value');
                let messageData = messageSnapshot.val();
                let message = NewsItem.fromFirebase(messageData);
                let allAccountDeets = message.accounts != null ? message.accounts : new Map<string, NewsItemAccount>();
                let activeAccountDeets = new Map<string, NewsItemAccount>();
                Array.from(allAccountDeets.keys()).map((accountId) => {
                    let accountRecipientDeets = allAccountDeets.get(accountId);
                    if (accountRecipientDeets.recipient == null || accountRecipientDeets.recipient) {
                        activeAccountDeets.set(accountId, accountRecipientDeets);
                    }
                });
                this.setState({
                    message: message,
                    currentMessageTitle: message.details.title,
                    currentMessageDescription: message.details.description,
                    currentMessageImageUrl: message.details.imageUrl != null ? message.details.imageUrl : null,
                    currentMessageImagePath: message.details.imagePath != null ? message.details.imagePath : null,
                    currentMessageAttachments: message.details.attachments != null ? message.details.attachments : [],
                    currentMessageIcon: message.details.iconCodeString != null ? message.details.iconCodeString : "email",
                    currentMessageIconFamily: message.details.iconFontFamily != null ? message.details.iconFontFamily : "MaterialIcons",
                    currentMessageAccounts: activeAccountDeets,
                    currentMessageAccountGroups: message.accountGroups != null ? message.accountGroups : new Map(),
                    currentMessageSentFrom: message.details.sentFrom != null ? message.details.sentFrom : "",
                    currentMessageWebLink: message.details.webLink != null ? message.details.webLink : "",
                    currentMessageShowAvatar: message.details.showAvatar != null ? message.details.showAvatar : true,
                    currentMessageRestricted: message.details.restricted != null ? message.details.restricted : false,
                    currentMessageRewardPoints: message.details.reward != null ? message.details.reward.points : null,
                    currentMessageRewardReason: message.details.reward != null ? message.details.reward.reason : null,
                });
                this.startResponseListener();
            } catch (error) {
                console.log(error);
            }
        } else {
            this.setState({
                message: new NewsItem(),
                currentMessageTitle: '',
                currentMessageDescription: '',
                currentMessageImageUrl: '',
                currentMessageImagePath: '',
                currentMessageAttachments: [],
                currentMessageIcon: "email",
                currentMessageIconFamily: "MaterialIcons",
                currentMessageAccounts: new Map(),
                currentMessageAccountGroups: new Map(),
                currentMessageSentFrom: (this.props.user.title != null && this.props.user.title !== "" ? this.props.user.title : this.props.user.personalName) + " " + this.props.user.familyName,
                currentMessageWebLink: '',
                currentMessageShowAvatar: true,
                currentMessageRestricted: false,
                currentMessageRewardPoints: null,
                currentMessageRewardReason: null,
            });
        }
    }

    startResponseListener(): void {
        this.listenRef = firebase.database().ref(`schoolMessages/messages/${this.props.user.schoolId}/${this.state.messageId}/responses`);
        this.listenRef.on('value', (snapshot) => {
            this.state.message.responses = NewsItemResponses.fromFirebase(snapshot.val());
            this.setState({});
        }, (error: any) => console.log(error));
    }

    async componentWillUnmount() {
        if (this.listenRef != null) {
            this.listenRef.off();
        }
    }
}

export default MessageView;
import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, Modal, ModalHeader, ModalBody, Card, CardHeader, Collapse, CardBody, InputGroup, InputGroupAddon, Spinner, CustomInput
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import EmojiSelection from "./ninja_components/emoji_selection";
import AttachmentUpload from './ninja_components/attachment_upload';
import queryString from 'query-string';
import ImageUpload from './ninja_components/image_upload';
import { v4 as uuidv4 } from 'uuid';
import { ActivityResponseFeedback, Attachment, HomeLearning, HomeLearningActivityResponse, HomeLearningActivityWorksheet, HomeLearningLink, HomeLearningResponse } from './data/home_learning';
import { User } from './data/user';
import { AccountMinimised } from './data/accounts';
import TopicsSelector from './ninja_components/topics_selector';
import { EngagementAward } from './data/engagement';
import EngagementAwardSelection from './ninja_components/engagement_award_selection';

interface IState {
    homework: HomeLearning;
    homeworkId: string;
    homeworkActivityId: string;
    homeworkSubjectId: string;
    subjectFilter: string;
    currentHomeworkActivityTitle: string;
    currentHomeworkActivityDescription: string;
    currentHomeworkActivityAttachments: Attachment[],
    currentHomeworkActivityWorksheetUrl: string;
    currentHomeworkActivityWorksheetPath: string;
    currentHomeworkActivityLinks: HomeLearningLink[],
    currentHomeworkResponses: Map<string, HomeLearningResponse>,
    currentTopics: string[];
    currentTopicsTags: Map<string, string[]>;
    showFeedback: boolean;
    feedbackAccountId: string;
    newFeedback: string;
    newReviewed: boolean;
    newAwardId: string;
    filter: string;
    collapse: boolean;
}

interface MatchParams {
    homeworkId: string;
    homeworkActivityId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    accountsMinimised: Map<string, AccountMinimised>;
    user: User;
    snackbar: (text?: string) => void;
}

class HomeworkActivityWorksheetView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            homework: null,
            homeworkId: props.match.params.homeworkId,
            homeworkActivityId: props.match.params.homeworkActivityId,
            homeworkSubjectId: values.subjectId == null ? null : values.subjectId.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            currentHomeworkActivityTitle: '',
            currentHomeworkActivityDescription: '',
            currentHomeworkActivityAttachments: [],
            currentHomeworkActivityWorksheetUrl: '',
            currentHomeworkActivityWorksheetPath: '',
            currentHomeworkActivityLinks: [],
            currentHomeworkResponses: new Map(),
            currentTopics: [],
            currentTopicsTags: new Map<string, string[]>(),
            showFeedback: false,
            feedbackAccountId: '',
            newFeedback: '',
            newReviewed: false,
            newAwardId: '',
            filter: 'NAME',
            collapse: props.match.params.homeworkActivityId !== "-1",
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.createHomeworkActivity = this.createHomeworkActivity.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleEmojiChange = this.handleEmojiChange.bind(this);
        this.handleAttachmentChange = this.handleAttachmentChange.bind(this);
        this.handleAttachmentChangeFail = this.handleAttachmentChangeFail.bind(this);
        this.handleLinkAdd = this.handleLinkAdd.bind(this);
        this.handleLinkRemove = this.handleLinkRemove.bind(this);
        this.handleLinkNameChange = this.handleLinkNameChange.bind(this);
        this.handleLinkUrlChange = this.handleLinkUrlChange.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.handleWorksheetChange = this.handleWorksheetChange.bind(this);
        this.handleWorksheetChangeFail = this.handleWorksheetChangeFail.bind(this);
        this.showFeedback = this.showFeedback.bind(this);
        this.closeFeedback = this.closeFeedback.bind(this);
        this.handleFeedback = this.handleFeedback.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.getGameQuerys = this.getGameQuerys.bind(this);
    }

    render() {

        if (this.state.homework == null) {
            return <Spinner></Spinner>
        }

        let queryValues = this.getQuerys();

        let orderedAccountIds: string[] = this.state.homework.accounts == null ? [] : this.state.homework.accounts.slice();

        orderedAccountIds = orderedAccountIds.filter((id) => this.props.accountsMinimised.get(id) != null);

        orderedAccountIds.sort((entry1, entry2) => {
            let account1 = this.props.accountsMinimised.get(entry1);
            let account2 = this.props.accountsMinimised.get(entry2);
            return account1.getLowercaseName().localeCompare(account2.getLowercaseName());
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={`/homeworks?${queryValues}`}>All homework</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/homeworks/${this.state.homeworkId}?${queryValues}`}>{this.state.homework != null ? this.state.homework.title : ""}</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.currentHomeworkActivityTitle}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Worksheet activity</div>
                        <p className="cardSubTitle">Users complete a worksheet using their digital pen(TM)</p>
                            <div><Button onClick={this.toggle} className="altButton">
                                {this.state.collapse ? "View details" : "Close"}
                            </Button></div>
                            <Collapse isOpen={!this.state.collapse}>
                                <Form onSubmit={this.handleUpdate}>
                                    <FormGroup>
                                        <Label for="currentHomeworkActivityTitle">Title *</Label>
                                        <InputGroup>
                                            <Input type="text" required name="currentHomeworkActivityTitle" onChange={(e) => this.setState({
                                                currentHomeworkActivityTitle: e.target.value
                                            })} value={this.state.currentHomeworkActivityTitle} />
                                            <InputGroupAddon addonType={"append"}>
                                                <EmojiSelection callback={(emoji) => {
                                                    this.handleEmojiChange(emoji, "currentHomeworkActivityTitle");
                                                }} />
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentHomeworkActivityDescription">Description *</Label>
                                        <InputGroup>
                                            <Input type="textarea" required name="currentHomeworkActivityDescription" onChange={(e) => this.setState({
                                                currentHomeworkActivityDescription: e.target.value
                                            })} value={this.state.currentHomeworkActivityDescription} />
                                            <InputGroupAddon addonType={"append"}>
                                                <EmojiSelection callback={(emoji) => {
                                                    this.handleEmojiChange(emoji, "currentHomeworkActivityDescription");
                                                }} />
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <TopicsSelector key={"bob"} countryCode={this.props.user.countryCode} subject={this.state.homeworkSubjectId} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags} callback={this.handleTopicsChange} />
                                    <AttachmentUpload callback={this.handleAttachmentChange} failureCallback={this.handleAttachmentChangeFail} filePath={"homeworkAttachments"}
                                        schoolId={this.props.user.schoolId} currentAttachments={this.state.currentHomeworkActivityAttachments} />
                                    <Label>Web links</Label>
                                    {this.state.currentHomeworkActivityLinks.map((nextLink, index) => {
                                        return <FormGroup>
                                            <InputGroup>
                                                <Input type="text" placeholder="Title" name={`currentHomeworkActivityLinkName${index}`} onChange={(value) => this.handleLinkNameChange(value, index)} value={nextLink.name} />
                                                <Input type="url" placeholder="Link address" name={`currentHomeworkActivityLinkUrl${index}`} onChange={(value) => this.handleLinkUrlChange(value, index)} value={nextLink.url} />
                                                <InputGroupAddon addonType={"append"}>
                                                    <Button onClick={() => this.handleLinkRemove(index)} color={"link"}>
                                                        <i className="material-icons">delete</i>
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    })}
                                    <FormGroup>
                                        <Button onClick={this.handleLinkAdd} outline>Add web link</Button>
                                    </FormGroup>
                                    <ImageUpload callback={this.handleWorksheetChange} failureCallback={this.handleWorksheetChangeFail} imageLegend="Worksheet" newUploadButtonTitle="Add worksheet" removeUploadButtonTitle="Remove worksheet" schoolId={this.props.user.schoolId} filePath={"homeworkAttachments"}
                                        currentImage={{ url: this.state.currentHomeworkActivityWorksheetUrl, path: this.state.currentHomeworkActivityWorksheetPath }} />

                                    <div>
                                        <Button className="adminPagesButton">
                                            {this.state.homeworkActivityId !== "-1" ? "Update" : "Create activity"}
                                        </Button>
                                    </div>
                                </Form>
                            </Collapse>
                          </CardBody></Card>
                            {this.state.homeworkActivityId === "-1" ? <br /> :
                                <Card className="mainCard top-buffer">
                                <CardBody className="d-flex flex-column">
                                    <div className="cardTitle2">Responses {orderedAccountIds.length > 0 ? ` (${orderedAccountIds.length})` : ""}
                                    </div>
                                    {orderedAccountIds.length > 0 ?
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Name <Button disabled={this.state.filter === 'NAME'} color="link" onClick={async () => {
                                                        this.setState({
                                                            filter: 'NAME'
                                                        })
                                                    }}><i className="material-icons material-icons-xs">sort</i></Button>
                                                    </th>
                                                    <th>Additional</th>
                                                    <th>Progress</th>
                                                    <th>Worksheet</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderedAccountIds.map((accountId) => {
                                                    let homeworkResponse = this.state.currentHomeworkResponses.get(accountId);
                                                    let homeworkActivityResponse;
                                                    if (homeworkResponse != null) {
                                                        homeworkActivityResponse = homeworkResponse.activityResponses.get(this.state.homeworkActivityId);
                                                    }
                                                    let account = this.props.accountsMinimised.get(accountId);

                                                    let status;
                                                    if (homeworkActivityResponse == null) {
                                                        status = "Not started";
                                                    } else {
                                                        status = homeworkActivityResponse.reviewed != null && homeworkActivityResponse.reviewed ? "Reviewed" : homeworkActivityResponse.completed
                                                            ? "Completed" : "Incomplete";
                                                    }

                                                    let name;
                                                    let additional;
                                                    if (account == null) {
                                                        name = "Deactivated account";
                                                        additional = "";
                                                    } else {
                                                        name = account.name;
                                                        additional = account.additional;
                                                    }
                                                    return <tr key={accountId + 'tr'}>
                                                        <th scope="row" key={accountId + 'th'}><Link to={`/accounts/${accountId}`}>{name}</Link></th>
                                                        <td key={accountId + 'td1'}>{additional}</td>
                                                        <td key={accountId + 'td2'}>{status
                                                        }</td>
                                                        <td>
                                                            {homeworkActivityResponse != null && homeworkActivityResponse.completed ?
                                                                <div>

                                                                    {homeworkActivityResponse.worksheetId != null ?
                                                                        <div>
                                                                            <Link to={`/worksheets/${this.state.homeworkId}/${this.state.homeworkActivityId}/${accountId}?${this.getGameQuerys(this.state.homework.title, this.state.currentHomeworkActivityTitle)}`}>
                                                                                Worksheet
                                                                        </Link></div> : <span />
                                                                    }
                                                                    {homeworkActivityResponse.urls != null ?
                                                                        homeworkActivityResponse.urls.map((nextEntry, index) => {

                                                                            return <div><a target="_blank" href={nextEntry.publicUrl}>
                                                                                Attachment {index + 1}
                                                                            </a></div>
                                                                        }) : <span />
                                                                    }
                                                                </div>
                                                                : <span />
                                                            }
                                                        </td>
                                                        <td key={accountId + 'td5'}>
                                                            {homeworkActivityResponse != null && homeworkActivityResponse.completed ?
                                                                <span>
                                                                    <Button onClick={() => {
                                                                        this.showFeedback(accountId);
                                                                    }} color={"link"}>
                                                                        <i className="material-icons">{homeworkActivityResponse.reviewed == null || !homeworkActivityResponse.reviewed ? "rate_review" : "done_all"}</i>
                                                                    </Button>
                                                                &nbsp;
                                                            </span> : <span />
                                                            }
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table> : <div>No Invitees</div>
                                    }
                                </CardBody></Card>
                            }
                            <br /><br /><br /><br /><br /><br />
                        <Modal isOpen={this.state.showFeedback} toggle={this.closeFeedback}>
                            <ModalHeader toggle={this.closeFeedback}>Give feedback</ModalHeader>
                            <ModalBody>
                                <div>
                                    <Form onSubmit={this.handleFeedback}>
                                        <FormGroup>
                                            <Label for="newFeedback">Feedback</Label>
                                            <InputGroup>
                                                <Input type="textarea" name="newFeedback" onChange={(e) => this.setState({
                                                    newFeedback: e.target.value
                                                })} value={this.state.newFeedback} />
                                                <InputGroupAddon addonType={"append"}>
                                                    <EmojiSelection callback={(emoji) => {
                                                        this.handleEmojiChange(emoji, "newFeedback");
                                                    }} />
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <EngagementAwardSelection awardId={this.state.newAwardId} callback={(value) => this.setState({ newAwardId: value })} user={this.props.user} />
                                        <FormGroup>
                                            <CustomInput id={"newReviewed"} type="switch" name={"newReviewed"} checked={this.state.newReviewed} label={"Mark reviewed"} onChange={(val) => {
                                                this.setState({
                                                    newReviewed: val.target.checked
                                                });
                                            }} />
                                        </FormGroup>
                                        <Button className="adminPagesButton">
                                            Send
                            </Button>
                                    </Form>
                                </div>
                            </ModalBody>
                        </Modal>
            </div>
        );
    }

    getGameQuerys(homeworkName: string, activityName: string): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
            homeworkName: homeworkName,
            activityName: activityName,
        });
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    async handleWorksheetChange(url: string, path: string): Promise<void> {
        if (this.state.homeworkId !== "-1") {
            const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
            await homeworkRef.update({
                worksheetUrl: url,
                worksheetPath: path,
                dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            }).catch((error) => {
                console.log(error);
                this.props.snackbar("Save failed");
            });
            this.setState({
                currentHomeworkActivityWorksheetUrl: url,
                currentHomeworkActivityWorksheetPath: path,
            });
            this.props.snackbar();
        } else {
            this.setState({
                currentHomeworkActivityWorksheetUrl: url,
                currentHomeworkActivityWorksheetPath: path,
            });
        }
    }

    handleWorksheetChangeFail(): void {
        this.props.snackbar("Image upload failed");
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
            subjectId: this.state.homeworkSubjectId,
        });
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    closeFeedback() {
        this.setState({
            showFeedback: false,
        });
    }

    showFeedback(feedbackAccountId: string): void {
        let response = this.state.currentHomeworkResponses.get(feedbackAccountId);
        let activityResponse;
        if (response != null) {
            activityResponse = response.activityResponses.get(this.state.homeworkActivityId);
        }
        this.setState({
            showFeedback: !this.state.showFeedback,
            feedbackAccountId: feedbackAccountId,
            newFeedback: activityResponse != null && activityResponse.feedback != null ? activityResponse.feedback.message : "",
            newReviewed: activityResponse != null ? activityResponse.reviewed : true,
        });
    }

    handleLinkRemove(index: number): void {
        let newLinks = this.state.currentHomeworkActivityLinks.slice();
        newLinks.splice(index, 1);
        this.setState({
            currentHomeworkActivityLinks: newLinks,
        });
    }

    handleLinkAdd(): void {
        let newLinks = this.state.currentHomeworkActivityLinks.slice();
        let newLink = new HomeLearningLink();
        newLink.name = "";
        newLink.url = "";
        newLinks.push(newLink);
        this.setState({
            currentHomeworkActivityLinks: newLinks,
        });
    }

    handleLinkNameChange(e: React.ChangeEvent<HTMLInputElement>, index: number): void {
        let newLinks = this.state.currentHomeworkActivityLinks.slice();
        newLinks[index].name = e.target.value;
        this.setState({
            currentHomeworkActivityLinks: newLinks,
        });
    }

    handleLinkUrlChange(e: React.ChangeEvent<HTMLInputElement>, index: number): void {
        let newLinks = this.state.currentHomeworkActivityLinks.slice();
        newLinks[index].url = e.target.value;
        this.setState({
            currentHomeworkActivityLinks: newLinks,
        });
    }

    handleAttachmentChange(currentAttachments: Attachment[]): void {
        this.setState({
            currentHomeworkActivityAttachments: currentAttachments
        });
    }

    handleAttachmentChangeFail(): void {
        this.props.snackbar("Attachment upload failed");
    }

    handleEmojiChange(emojiCharacter: string, target: string): void {
        if (target === "currentHomeworkActivityTitle") {
            this.setState({
                currentHomeworkActivityTitle: this.state.currentHomeworkActivityTitle + emojiCharacter
            });
        } else if (target === "currentHomeworkActivityDescription") {
            this.setState({
                currentHomeworkActivityDescription: this.state.currentHomeworkActivityDescription + emojiCharacter
            });
        } else {
            this.setState({
                newFeedback: this.state.newFeedback + emojiCharacter
            });
        }
    }

    async handleFeedback(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let response = this.state.currentHomeworkResponses.get(this.state.feedbackAccountId);
        let activityResponse: HomeLearningActivityResponse;
        if (response != null) {
            activityResponse = response.activityResponses.get(this.state.homeworkActivityId);
        }
        let newFeedbackRead = false;
        if (activityResponse != null) {
            newFeedbackRead = activityResponse.feedback != null && activityResponse.feedback.message !== this.state.newFeedback ? false : activityResponse.feedbackRead;
        } else {
            activityResponse = new HomeLearningActivityResponse();
        }
        if (activityResponse.feedback == null) {
            activityResponse.feedback = new ActivityResponseFeedback();
        }
        activityResponse.feedback.message = this.state.newFeedback;
        activityResponse.reviewed = this.state.newReviewed;
        activityResponse.feedbackRead = newFeedbackRead;
        if (this.state.newAwardId != null && this.state.newAwardId !== "") {
            let path = `engagementAwards/${this.props.user.schoolId}/awards/${this.state.newAwardId}`;
            let awardSnapshot = await firebase.firestore().doc(path).get();
            let award = EngagementAward.fromFirebase(awardSnapshot.data());
            activityResponse.feedback.awardId = this.state.newAwardId;
            activityResponse.feedback.imageUrl = award.imageUrl;
            activityResponse.feedback.points = award.points;
        }

        let url = `/homeworkResponses/${this.state.feedbackAccountId}--${this.state.homeworkId}`;
        const responseRef = firebase.firestore().doc(url);
        let update = {
            [`activityResponses.${this.state.homeworkActivityId}.reviewed`]: activityResponse.reviewed,
            [`activityResponses.${this.state.homeworkActivityId}.dateUpdated`]: firebase.firestore.FieldValue.serverTimestamp(),
            [`activityResponses.${this.state.homeworkActivityId}.feedbackRead`]: activityResponse.feedbackRead,
            [`activityResponses.${this.state.homeworkActivityId}.feedback`]: activityResponse.feedback.toFirebase(),
            [`dateUpdated`]: firebase.firestore.FieldValue.serverTimestamp(),
        }
        this.closeFeedback();
        this.state.currentHomeworkResponses.get(this.state.feedbackAccountId).activityResponses.set(this.state.homeworkActivityId, activityResponse);
        this.setState({});
        try {
            await responseRef.update(update);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let homeworkActivity = new HomeLearningActivityWorksheet();
        homeworkActivity.title = this.state.currentHomeworkActivityTitle;
        homeworkActivity.description = this.state.currentHomeworkActivityDescription;
        homeworkActivity.attachments = this.state.currentHomeworkActivityAttachments;
        homeworkActivity.links = this.state.currentHomeworkActivityLinks;
        homeworkActivity.worksheetUrl = this.state.currentHomeworkActivityWorksheetUrl;
        homeworkActivity.worksheetPath = this.state.currentHomeworkActivityWorksheetPath;
        homeworkActivity.type = HomeLearningActivityWorksheet.TYPE;
        homeworkActivity.topics = this.state.currentTopics;
        homeworkActivity.topicTags = this.state.currentTopicsTags;

        await this.createHomeworkActivity(homeworkActivity);
    }

    async createHomeworkActivity(homeworkActivity: HomeLearningActivityWorksheet): Promise<void> {
        let activityId;
        if (this.state.homeworkActivityId === "-1") {
            activityId = uuidv4();
        } else {
            activityId = this.state.homeworkActivityId;
        }
        const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
        try {
            await homeworkRef.update({
                [`activities.${activityId}`]: homeworkActivity.toFirebase(),
                activityIdsOrdered: firebase.firestore.FieldValue.arrayUnion(activityId),
                dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            });
            this.setState({
                homeworkActivityId: activityId,
                collapse: true,
            });
            this.props.history.replace(`/homeworkActivityWorksheet/${this.state.homeworkId}/${activityId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async componentDidMount(): Promise<void> {
        const homeworkSnapshot = await firebase.firestore().doc(`homeworks/${this.state.homeworkId}`).get();

        let homework = HomeLearning.fromFirebase(homeworkSnapshot.data());

        if (this.state.homeworkActivityId !== "-1") {

            const responsesSnapshot = await firebase.firestore().collection(`homeworkResponses`).where("schoolId", "==", this.props.user.schoolId).where("homeworkId", "==", this.state.homeworkId).get();
            let responses = responsesSnapshot.docs;
            let responseMap = new Map<string, HomeLearningResponse>();
            for (let nextResponse of responses) {
                let nextHomeLearning = HomeLearningResponse.fromFirebase(nextResponse.data());
                responseMap.set(nextHomeLearning.accountId, nextHomeLearning);
            }

            let homeworkActivity = homework.activities.get(this.state.homeworkActivityId) as HomeLearningActivityWorksheet;

            this.setState({
                homework: homework,
                currentHomeworkActivityTitle: homeworkActivity.title,
                currentHomeworkActivityDescription: homeworkActivity.description,
                currentHomeworkActivityAttachments: homeworkActivity.attachments != null ? homeworkActivity.attachments : [],
                currentHomeworkActivityLinks: homeworkActivity.links != null ? homeworkActivity.links : [],
                currentHomeworkResponses: responseMap,
                currentHomeworkActivityWorksheetUrl: homeworkActivity.worksheetUrl != null ? homeworkActivity.worksheetUrl : "",
                currentHomeworkActivityWorksheetPath: homeworkActivity.worksheetPath != null ? homeworkActivity.worksheetPath : "",
                currentTopics: homeworkActivity.topics == null ? [] : homeworkActivity.topics,
                currentTopicsTags: homeworkActivity.topicTags == null ? new Map<string, string[]>() : homeworkActivity.topicTags,
            });
        } else {
            this.setState({
                homework: homework,
                currentHomeworkActivityTitle: '',
                currentHomeworkActivityDescription: '',
                currentHomeworkActivityAttachments: [],
                currentHomeworkActivityLinks: [],
                currentHomeworkActivityWorksheetUrl: '',
                currentHomeworkActivityWorksheetPath: '',
                currentTopics: [],
                currentTopicsTags: new Map<string, string[]>(),
            });
        }
    }
}

export default HomeworkActivityWorksheetView;
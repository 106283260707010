import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import queryString from 'query-string';
import RootView from "./root_view";
import {
    Card, CardBody,
    CardHeader, CardSubtitle, Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Collapse, CardImg, Spinner
} from 'reactstrap';
import confirm from "reactstrap-confirm";
import { Adventure, AdventureAdventureSection, AdventureDetails, AdventureInformationSection } from './data/adventures';
import { User, USER_ROLE } from './data/user';
import ImageUpload from './ninja_components/image_upload';
import { subjectIds } from './Prettifier';
import TopicsSelector from './ninja_components/topics_selector';

interface IState {
    adventureId: string;
    adventure: Adventure;
    currentSubjectId: string;
    subjectFilter: string;
    currentAdventureTitle: string;
    currentAdventureDescription: string;
    currentAdventureMinAge: number;
    currentAdventureMaxAge: number;
    currentAdventureSchoolId: string;
    currentAdventureStartSection: string;
    currentAdventureImageUrl: string;
    currentAdventureImagePath: string;
    currentTopics: string[];
    currentTopicsTags: Map<string, string[]>;
    collapse: boolean;
}

interface MatchParams {
    adventureId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class AdventureView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            adventureId: props.match.params.adventureId,
            adventure: null,
            currentSubjectId: '',
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            currentAdventureTitle: '',
            currentAdventureDescription: '',
            currentAdventureMinAge: 0,
            currentAdventureMaxAge: -1,
            currentAdventureSchoolId: '',
            currentAdventureStartSection: '',
            currentAdventureImageUrl: null,
            currentAdventureImagePath: null,
            currentTopics: [],
            currentTopicsTags: new Map<string, string[]>(),
            collapse: props.match.params.adventureId !== "-1",
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.createAdventureSectionCard = this.createAdventureSectionCard.bind(this);
        this.createInformationSectionCard = this.createInformationSectionCard.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleImageChangeFail = this.handleImageChangeFail.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
    }

    createAdventureSectionCard(nextSectionId: string, queryValues: string) {
        let nextSection = this.state.adventure.adventureSections.get(nextSectionId);
        let editable = this.state.currentAdventureSchoolId === this.props.user.schoolId || this.props.user.role === USER_ROLE.admin || (this.props.user.roles.has(USER_ROLE.learningCreator) && this.props.user.roles.get(USER_ROLE.learningCreator));

        let displayText = nextSection.descriptionStub;

        let startSection = this.state.adventure.details.startAdventureSectionId === nextSectionId;

        return <Card style={{ width: "100%" }} outline={startSection} color={startSection ? "success" : ""}>
            <CardHeader>{startSection ? <b>{nextSection.title}</b> : nextSection.title}<br />{!editable ? <span style={{ "color": "red" }}>View only</span> : ""}</CardHeader>
            <CardBody className="d-flex flex-column">
                {nextSection.imageUrl != null && nextSection.imageUrl != "" ?
                    <CardImg src={nextSection.imageUrl}></CardImg> : <span />
                }
                <CardSubtitle>{displayText}</CardSubtitle><br />
                <p>{nextSection.choices == null ? "" : nextSection.choices.map((choice, pos) => {
                    let linkId = choice.linkId;
                    let adventureSection = this.state.adventure.adventureSections.get(linkId);
                    let title = '';
                    if (adventureSection != null) {
                        title = adventureSection.title;
                    }
                    return adventureSection != null ?
                        <div><Link to={`/adventureSections/${linkId}?${queryValues}`}>- {title}</Link></div>
                        : <div>- {choice.text}</div>
                })
                }
                </p>
                <div className="mt-auto">
                    <Link to={`/adventureSections/${nextSectionId}?${queryValues}`}>
                        <Button block outline color="primary" type="button">
                            Open
                        </Button>
                    </Link><br />
                    {editable ?
                        <Button block outline color="danger" onClick={async () => {
                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this adventure section", confirmText: "Confirm" });
                            if (result) {
                                this.removeAdventureSection(nextSectionId)
                            }
                        }}>Remove</Button> : <span />
                    }
                </div>
            </CardBody>
        </Card>
    }

    createInformationSectionCard(nextSectionId: string, queryValues: string) {
        let nextSection = this.state.adventure.informationSections.get(nextSectionId);

        let editable = this.state.currentAdventureSchoolId === this.props.user.schoolId || this.props.user.role === USER_ROLE.admin || (this.props.user.roles.has(USER_ROLE.learningCreator) && this.props.user.roles.get(USER_ROLE.learningCreator));

        let displayText = nextSection.descriptionStub;

        return <Card style={{ width: "100%" }}>
            <CardHeader>{nextSection.title}<br />{!editable ? <span style={{ "color": "red" }}>View only</span> : ""}</CardHeader>
            <CardBody className="d-flex flex-column">
                {nextSection.imageUrl != null && nextSection.imageUrl != "" ?
                    <CardImg src={nextSection.imageUrl}></CardImg> : <span />
                }
                <CardSubtitle>{displayText}</CardSubtitle><br />
                <div className="mt-auto">
                    <Link to={`/informationSections/${nextSectionId}?${queryValues}`}>
                        <Button block outline color="primary" type="button">
                            Open
                        </Button>
                    </Link><br />
                    {editable ?
                        <Button block outline color="danger" onClick={async () => {
                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this information section", confirmText: "Confirm" });
                            if (result) {
                                this.removeInformationSection(nextSectionId)
                            }
                        }}>Remove</Button> : <span />
                    }
                </div>
            </CardBody>
        </Card>
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let adventureSectionRows: JSX.Element[] = [];
        let informationSectionRows: JSX.Element[] = [];
        if (this.state.adventure != null) {
            let adventureSectionIds = Array.from(this.state.adventure.adventureSections.keys());
            adventureSectionIds.sort((id1, id2) => {
                let title1 = this.state.adventure.adventureSections.get(id1).title;
                let title2 = this.state.adventure.adventureSections.get(id2).title;
                title1 = title1 == null ? "" : title1;
                title2 = title2 == null ? "" : title2;
                return title1.localeCompare(title2);
            });
            let informationSectionIds = Array.from(this.state.adventure.informationSections.keys());
            informationSectionIds.sort((id1, id2) => {
                let section1 = this.state.adventure.informationSections.get(id1);
                let section2 = this.state.adventure.informationSections.get(id2);
                return section1.title.localeCompare(section2.title);
            });
            adventureSectionRows = RootView.createCardRows(adventureSectionIds, this.createAdventureSectionCard, queryValues);
            informationSectionRows = RootView.createCardRows(informationSectionIds, this.createInformationSectionCard, queryValues);
        }

        let editable = this.state.currentAdventureSchoolId === this.props.user.schoolId || this.props.user.role === USER_ROLE.admin || (this.props.user.roles.has(USER_ROLE.learningCreator) && this.props.user.roles.get(USER_ROLE.learningCreator));

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={`/adventures?${this.getQuerys()}`}>All adventures</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.adventure != null ? this.state.adventure.details.title : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Adventure</div>
                        <p className="cardSubTitle">An adventure is a series of questions resulting in different outcomes,
                        finally culminating in success or (optionally) failure.</p>
                        {this.state.adventure != null ?
                            <div>
                                {this.state.adventureId === "-1" ? null :
                                    <Button onClick={this.toggle} className="altButton">
                                        {this.state.collapse ? "View details" : "Close"}
                                    </Button>
                                }
                                <br />
                                <Collapse isOpen={!this.state.collapse}>

                                    <Form onSubmit={this.handleUpdate}>
                                        <fieldset disabled={!editable}>
                                            <FormGroup>
                                                <Label for="currentAdventureTitle">Title</Label>
                                                <Input type="text" required name="currentAdventureTitle" onChange={(e) => this.setState({
                                                    currentAdventureTitle: e.target.value
                                                })} value={this.state.currentAdventureTitle} />
                                            </FormGroup>
                                            <ImageUpload callback={this.handleImageChange} failureCallback={this.handleImageChangeFail} schoolId={this.props.user.schoolId}
                                                currentImage={{ url: this.state.currentAdventureImageUrl, path: this.state.currentAdventureImagePath }} filePath={"adventureImages"}
                                                imageLegend={"Adventure image"} />
                                            <FormGroup>
                                                <Label for="currentAdventureDescription">Description</Label>
                                                <Input type="textarea" rows="5" required name="currentAdventureDescription" onChange={(e) => this.setState({
                                                    currentAdventureDescription: e.target.value
                                                })} value={this.state.currentAdventureDescription} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentSubjectId">Subject *</Label>
                                                <Input type="select" name="currentSubjectId"
                                                    onChange={(e) => this.setState({
                                                        currentSubjectId: e.target.value,
                                                    })}
                                                    value={this.state.currentSubjectId}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                                        let subjectName = subjectIds.get(subjectId);
                                                        return <option value={subjectId}>{subjectName}</option>;
                                                    })}
                                                </Input>
                                            </FormGroup>
                                            {this.state.currentSubjectId === '' ? <div></div> :
                                                <TopicsSelector key={"bob"} countryCode={this.props.user.countryCode} subject={this.state.currentSubjectId} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags} callback={this.handleTopicsChange} />
                                            }
                                            <FormGroup>
                                                <Label for="currentAdventureMinAge">Minimum recommended age</Label>
                                                <Input type="number" min={0} name="currentAdventureMinAge" onChange={(e) => this.setState({
                                                    currentAdventureMinAge: e.target.value === "" ? 0 : parseInt(e.target.value)
                                                })} value={this.state.currentAdventureMinAge} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentAdventureMaxAge">Maximum recommended age</Label>
                                                <Input type="number" min={0} name="currentAdventureMaxAge" onChange={(e) => this.setState({
                                                    currentAdventureMaxAge: e.target.value === "" ? 0 : parseInt(e.target.value)
                                                })} value={this.state.currentAdventureMaxAge} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentAdventureMaxAge">Starting section</Label>
                                                <Input type="select" name="currentAdventureStartSection"
                                                    value={this.state.currentAdventureStartSection}
                                                    onChange={(e) => this.setState({
                                                        currentAdventureStartSection: e.target.value
                                                    })}>
                                                    <option></option>
                                                    {Array.from(this.state.adventure.adventureSections.keys()).map((key) => {
                                                        return (
                                                            <option value={key} key={key}>{this.state.adventure.adventureSections.get(key).title}</option>
                                                        )
                                                    })}
                                                </Input>
                                                {this.state.currentAdventureStartSection != null && this.state.currentAdventureStartSection.length > 0 ?

                                                    <Link
                                                        to={'/adventureSections/' + this.state.currentAdventureStartSection + '?' + queryValues}>{this.state.adventure.adventureSections.get(this.state.currentAdventureStartSection).title}</Link>

                                                    : <span />
                                                }
                                            </FormGroup>
                                            {editable ? this.state.adventureId === "-1" ?
                                                <Button className="adminPagesButton">Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                            }
                                        </fieldset>
                                    </Form>

                                </Collapse>
                            </div> : <Spinner />
                        }
                    </CardBody>
                </Card>


                {this.state.adventure == null || this.state.adventureId === "-1" ? <br /> :
                    <React.Fragment>
                        <Card className="mainCard top-buffer">
                            <CardBody className="d-flex flex-column">
                                <div className="cardTitle2">Adventure sections</div>
                                {editable ?
                                    <div className="bottom-buffer">
                                        <Link to={`/adventureSections/-1?${queryValues}`}>
                                            <Button className="adminPagesButton" type="button">
                                                Add adventure section
                                                </Button>
                                        </Link>
                                    </div> : <span />
                                }
                                <div>
                                    {adventureSectionRows}
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="mainCard top-buffer">
                            <CardBody className="d-flex flex-column">
                                <div className="cardTitle2">Information sections</div>
                                {editable ?
                                    <div className="bottom-buffer">
                                        <Link to={`/informationSections/-1?${queryValues}`}>
                                            <Button className="adminPagesButton" type="button">
                                                Add information section
                                                </Button>
                                        </Link>
                                    </div> : <span />
                                }
                                <div>
                                    {informationSectionRows}
                                </div>
                            </CardBody></Card>
                    </React.Fragment>
                }

            </div>
        );
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
            adventureId: this.state.adventureId,
            adventureTitle: this.state.adventure != null && this.state.adventure.details != null ? this.state.adventure.details.title : ""
        });
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    handleImageChangeFail(): void {
        this.props.snackbar("Image upload failed");
    }

    async handleImageChange(url: string, path: string): Promise<void> {
        let imageDeets = {
            "details.imageUrl": url,
            "details.imagePath": path,
        };
        const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
        try {
            await adventureRef.update(imageDeets);
            this.setState({
                currentAdventureImageUrl: url,
                currentAdventureImagePath: path,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let adventure = this.state.adventure;
        if (adventure.details == null) {
            adventure.details = new AdventureDetails();
        }
        adventure.details.title = this.state.currentAdventureTitle;
        adventure.details.description = this.state.currentAdventureDescription;
        adventure.details.minAge = this.state.currentAdventureMinAge;
        adventure.details.maxAge = this.state.currentAdventureMaxAge;
        adventure.details.startAdventureSectionId = this.state.currentAdventureStartSection;
        adventure.details.subjectId = this.state.currentSubjectId;
        adventure.details.topics = this.state.currentTopics;
        adventure.details.topicTags = this.state.currentTopicsTags;
        adventure.details.imageUrl = this.state.currentAdventureImageUrl;
        adventure.details.imagePath = this.state.currentAdventureImagePath;

        const itemsRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
        try {
            await itemsRef.update({ "details": adventure.details.toFirebase() });
            this.setState({
                adventure: adventure,
                collapse: true,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async componentDidMount(): Promise<void> {
        if (this.state.adventureId !== "-1") {
            const itemsRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
            try {
                let adventureSnapshot = await itemsRef.get();
                let adventure = Adventure.fromFirebase(adventureSnapshot.data());

                this.setState({
                    adventure: adventure,
                    currentAdventureTitle: adventure.details.title,
                    currentAdventureDescription: adventure.details.description,
                    currentAdventureSchoolId: adventure.schoolId,
                    currentAdventureMinAge: adventure.details.minAge,
                    currentAdventureMaxAge: adventure.details.maxAge,
                    currentAdventureStartSection: adventure.details.startAdventureSectionId,
                    currentAdventureImageUrl: adventure.details.imageUrl,
                    currentAdventureImagePath: adventure.details.imagePath,
                    currentSubjectId: adventure.details.subjectId,
                    currentTopics: adventure.details.topics == null ? [] : adventure.details.topics,
                    currentTopicsTags: adventure.details.topicTags == null ? new Map<string, string[]>() : adventure.details.topicTags,
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            let adventure = new Adventure();
            if (this.props.user.role !== USER_ROLE.admin && !this.props.user.roles.get(USER_ROLE.learningCreator)) {
                adventure.schoolId = this.props.user.schoolId;
            }
            adventure.details.subjectId = this.state.subjectFilter;
            const adventureRef = firebase.firestore().collection('adventures');
            try {
                let doc = await adventureRef.add(adventure.toFirebase());
                let newPostKey = doc.id;
                this.setState({
                    adventureId: newPostKey,
                    adventure: adventure,
                    collapse: false,
                    currentAdventureTitle: adventure.details.title,
                    currentAdventureDescription: adventure.details.description,
                    currentAdventureSchoolId: adventure.schoolId,
                    currentAdventureMinAge: adventure.details.minAge,
                    currentAdventureMaxAge: adventure.details.maxAge,
                    currentAdventureStartSection: adventure.details.startAdventureSectionId,
                    currentSubjectId: adventure.details.subjectId,
                    currentTopics: [],
                    currentTopicsTags: new Map<string, string[]>(),
                });
                this.props.history.replace(`/adventures/${newPostKey}?${this.getQuerys()}`);
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed")
            }
            this.setState({
                adventure: new Adventure(),
                currentAdventureSchoolId: this.props.user.schoolId,
            });
        }
    }

    async removeAdventureSection(adventureSectionId: string): Promise<void> {
        let batch = firebase.firestore().batch();
        const sectionRef = firebase.firestore().doc(`adventures/${this.state.adventureId}/adventureSections/${adventureSectionId}`);
        const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
        try {
            batch.delete(sectionRef);
            this.state.adventure.adventureSections.delete(adventureSectionId);
            batch.update(adventureRef, `adventureSections.${adventureSectionId}`, firebase.firestore.FieldValue.delete());

            await batch.commit();
            this.setState({});
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async removeInformationSection(informationSectionId: string): Promise<void> {
        let batch = firebase.firestore().batch();
        const sectionRef = firebase.firestore().doc(`adventures/${this.state.adventureId}/informationSections/${informationSectionId}`);
        const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
        try {
            batch.delete(sectionRef);
            this.state.adventure.informationSections.delete(informationSectionId);
            batch.update(adventureRef, `informationSections.${informationSectionId}`, firebase.firestore.FieldValue.delete());

            await batch.commit();
            this.setState({});
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }
}

export default AdventureView;
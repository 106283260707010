import React, { Component } from 'react';
import firebase from '../firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, FormGroup, Label, Input, CustomInput, Row, Col, Card, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";
import queryString from 'query-string';
import moment from 'moment';
import { User } from '../data/user';
import { Marketplace, Purchase } from '../data/marketplace';
import { AccountMinimised } from '../data/accounts';

interface IState {
    purchases: Map<string, Purchase>;
    purchaseIdsSorted: string[];
    morePurchases: boolean;
    productIdFilter: string;
    accountIdFilter: string;
    showReceived: boolean;
    pathId: string;
    marketplace: Marketplace;
}

interface IProps extends RouteComponentProps {
    accountsMinimised: Map<string, AccountMinimised>;
    tutorialCallback: (pageName: string) => void;
    user: User;
    snackbar: (text?: string) => void;
}

class PurchasesView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            pathId: values.pathId == null ? null : values.pathId.toString(),
            marketplace: null,
            purchases: new Map(),
            purchaseIdsSorted: [],
            morePurchases: true,
            productIdFilter: "",
            accountIdFilter: "",
            showReceived: false,
        };
        this.receivedItem = this.receivedItem.bind(this);
        this.getNextPurchasesBatch = this.getNextPurchasesBatch.bind(this);
    }

    render(): JSX.Element {

        let orderedAllIds = Array.from(this.props.accountsMinimised.keys());
        orderedAllIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).name.localeCompare(this.props.accountsMinimised.get(id2).name);
        });

        let productIdsSorted: Array<string>;
        if (this.state.marketplace != null) {
            productIdsSorted = Array.from(this.state.marketplace.products.keys());
            productIdsSorted.sort((id1, id2) => {
                return this.state.marketplace.products.get(id1).name.localeCompare(this.state.marketplace.products.get(id2).name);
            });
        } else {
            productIdsSorted = [];
        }
        // Include two filter drop downs after the header, 1 updates productIdFilter, 1 updates acountIdFilter
        // List purchaseIdsSorted like you do productIdsSorted on the products page
        // Include a product Received toggle on each row which will call the receivedItem(productId, received) method
        // Include a 'More purchases' button at the end if morePurchases == true (like on the products page) that calls getNextPurchasesBatch()
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>Purchases</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Purchases</div>
                    <p className="cardSubTitle">All the purchases that have been made will appear here.</p>
                <div>
                    <Row form>
                        <Col md={4}>
                            Filter by product<Input type="select" required name="productIdFilter"
                                onChange={(e) => {
                                    this.getNextPurchasesBatch(true, this.state.showReceived, e.target.value, this.state.accountIdFilter);
                                }}>
                                <option value={""}>&nbsp;</option>
                                {productIdsSorted.map((key) => {
                                    let product = this.state.marketplace.products.get(key);
                                    return (
                                        <option value={key} key={key}>{product.name}</option>
                                    )
                                })
                                }
                            </Input>
                        </Col>
                        <Col md={4}>
                            Filter by account<Input type="select" required name="accountIdFilter"
                                onChange={(e) => {
                                    this.getNextPurchasesBatch(true, this.state.showReceived, this.state.productIdFilter, e.target.value);
                                }}>
                                <option value={""}>&nbsp;</option>
                                {orderedAllIds.map((key) => {
                                    let additional = "";
                                    let account = this.props.accountsMinimised.get(key);
                                    if (account.additional != null && account.additional !== '') {
                                        additional = ` - ${account.additional}`;
                                    }
                                    return (
                                        <option value={key} key={key}>{account.name + additional}</option>
                                    )
                                })
                                }
                            </Input>
                        </Col>
                        <Col md={4}>
                            Show received purchases <CustomInput id="filterReceived" checked={this.state.showReceived} type="switch" name="filterReceived" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.getNextPurchasesBatch(true, !this.state.showReceived, this.state.productIdFilter, this.state.accountIdFilter);
                            }}></CustomInput>
                        </Col>
                    </Row>
                </div>
                <br />
                <div>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Order Id</th>
                                <th>Count</th>
                                <th>Product Name</th>
                                <th>Order Date</th>
                                <th>Recieved</th>
                            </tr>
                        </thead>
                        {this.state.purchaseIdsSorted.map((purchaseId) => {
                            let purchase = this.state.purchases.get(purchaseId);
                            let account = this.props.accountsMinimised.get(purchase.accountId);
                            if (purchase == null) {
                                return null;
                            }
                            let name;
                            if (account == null) {
                                name = "Deactivated account";
                            } else {
                                name = account.name;
                            }
                            let title;
                            if (purchase.title == null) {
                                title = "No Title"
                            } else {
                                title = purchase.title;
                            }
                            return <tr>
                                <th scope="row" ><Link to={`/accounts/${purchase.accountId}`}>{name}</Link></th>
                                <td>{purchase.orderId}</td>
                                <td>{purchase.count}</td>
                                <td><Link to={`/product/${purchase.productId}`}>{title}</Link></td>
                                <td>{purchase.orderDate == null ? "Unset" : moment(purchase.orderDate.toDate()).format("ddd, MMM Do YYYY, h:mm a")}</td>
                                <td><CustomInput id={`purchaseRecieved-${purchaseId}`} checked={purchase.received} type="switch" name={`purchaseRecieved-${purchaseId}`} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.receivedItem(purchaseId, e.target.checked) }}></CustomInput></td>
                            </tr>
                        })}
                    </Table>
                    <br /><br />
                    {this.state.morePurchases === true ?
                        <Button className="adminPagesButton" onClick={() => this.getNextPurchasesBatch}>More Purchases</Button> : null}
                </div>
                </CardBody>
                </Card>
            </div>
        )
    }

    async componentDidMount(): Promise<void> {
        const marketplaceSnapshot = await firebase.firestore().doc(`marketplace/${this.props.user.schoolId}`).get();
        let marketplaceData = marketplaceSnapshot.data();

        let marketplace = Marketplace.fromFirebase(marketplaceData);

        this.setState({
            marketplace: marketplace,
        });

        this.getNextPurchasesBatch(true, this.state.showReceived, this.state.productIdFilter, this.state.accountIdFilter);
    }

    async getNextPurchasesBatch(seed: boolean, showReceived: boolean, productIdFilter: string, accountIdFilter: string): Promise<void> {
        if (seed != null && seed) {
            this.setState({
                purchases: new Map(),
                purchaseIdsSorted: [],
                showReceived: showReceived,
                productIdFilter: productIdFilter,
                accountIdFilter: accountIdFilter,
            });
        }
        let purchasesRef: firebase.firestore.Query = firebase.firestore().collection(`marketplace/${this.props.user.schoolId}/purchases`);

        if (!showReceived) {
            purchasesRef = purchasesRef.where('received', '==', false);
        }
        if (productIdFilter != null && productIdFilter !== "") {
            purchasesRef = purchasesRef.where("productId", "==", productIdFilter);
        }
        if (accountIdFilter != null && accountIdFilter !== "") {
            purchasesRef = purchasesRef.where("accountId", "==", accountIdFilter);
        }

        if (seed == null || !seed) {
            // Find last homework
            let lastId = this.state.purchaseIdsSorted[this.state.purchaseIdsSorted.length - 1];
            if (lastId != null) {
                purchasesRef = purchasesRef.endAt(this.state.purchases.get(lastId).orderDate);
            }
            purchasesRef = purchasesRef.limitToLast(11);
        } else {
            purchasesRef = purchasesRef.limitToLast(10);
        }

        purchasesRef = purchasesRef.orderBy('orderDate');

        try {
            let snapshot = await purchasesRef.get();
            let newMorePurchases = this.state.morePurchases;
            let products = snapshot.docs;

            let newPurchases = new Map<string, Purchase>();
            let newPurchaseIdsSorted: string[] = [];
            if (products != null) {
                for (let nextPurchaseSnapshot of products) {
                    let nextPurchaseId = nextPurchaseSnapshot.id;
                    if (!this.state.purchases.has(nextPurchaseId)) {
                        newPurchases.set(nextPurchaseId, Purchase.fromFirebase(nextPurchaseSnapshot.data()));
                        newPurchaseIdsSorted.push(nextPurchaseId);
                    }
                }
            }
            newPurchaseIdsSorted.reverse();

            if (newPurchaseIdsSorted.length < 10) {
                newMorePurchases = false;
            }
            this.setState({
                purchaseIdsSorted: [...this.state.purchaseIdsSorted, ...newPurchaseIdsSorted],
                purchases: new Map([...this.state.purchases, ...newPurchases]),
                morePurchases: newMorePurchases,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async receivedItem(purchaseId: string, received: boolean): Promise<void> {
        try {
            let overviewPath = `marketplace/${this.props.user.schoolId}`;
            let purchasePath = `${overviewPath}/purchases/${purchaseId}`;

            console.log("purchasePath", purchasePath);

            var update =  {
                received: received,
                updateDate: firebase.firestore.FieldValue.serverTimestamp(),
            };

            await firebase.firestore().doc(purchasePath).update(update);

            if (!this.state.showReceived) {
                this.state.purchaseIdsSorted.splice(this.state.purchaseIdsSorted.indexOf(purchaseId), 1);
            }

            this.setState({});
            this.props.snackbar("Marked received");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
    }
}

export default PurchasesView;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, Card, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";
import { NewsItemMinimised } from './data/news_items';
import { User } from './data/user';

interface IState {
    messages: Map<string, NewsItemMinimised>,
    messageIdsSorted: string[],
    moreMessages: boolean;
}

interface IProps {
    user: User;
    tutorialCallback: (pageName: string) => void;
    snackbar: (text?: string) => void;
}

class MessagesView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            messages: new Map(),
            messageIdsSorted: [],
            moreMessages: true,
        };
        this.removeItem = this.removeItem.bind(this);
        this.getNextMessageBatch = this.getNextMessageBatch.bind(this);
    }

    render(): JSX.Element {

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All News Items</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody>
                        <div className="cardTitle">News Items</div>
                        <p className="cardSubTitle">All the news items sent to users. These are linked to user accounts and appear in their news feed.</p><br />
                        <div>
                            <Link to={'/messages/-1'} onClick={() => this.props.tutorialCallback('messages')}>
                                <Button className="adminPagesButton" type="button">
                                    Send news item
                            </Button>
                            </Link>
                            <Link to={"/tutorialVideo/NewsItem"}>
                                <Button style={{marginLeft:"15px"}} className="altButton">Watch Tutorial</Button>
                            </Link>
                            <a target="_blank" href="https://docs.google.com/document/d/1NoswtJ7k9MfQj8IdIYft21-s2z5NuwcmXS14CyEUOBQ/edit">
                                <Button style={{marginLeft:"15px"}} className="altButton">Read Tutorial</Button>
                            </a>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Sent/Updated</th>
                                    <th>Recipients</th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.messageIdsSorted.map((messageId) => {
                                    let message = this.state.messages.get(messageId);
                                    if (message == null) {
                                        return "";
                                    }
                                    return <tr>
                                        <th scope="row"><Link to={'messages/' + messageId}>{message.title}</Link></th>
                                        <td>{this.makeShortString(message.description)}</td>
                                        <td>{new Date(message.dateUpdated).toDateString()}</td>
                                        <td>{message.recipients}</td>
                                        <td><Button color="link" onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this news item", confirmText: "Confirm" });
                                            if (result) {
                                                this.removeItem(messageId)
                                            }
                                        }}><i className="material-icons">delete</i></Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        {this.state.moreMessages ?
                            <div>
                                <p>Showing {this.state.messageIdsSorted.length}</p>
                                <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.getNextMessageBatch(false);
                                    }}>
                                    Show more
                        </Button>
                            </div> : <span />
                        }
                        <br /><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    makeShortString(description: string): string {
        if (description == null) {
            return "";
        }
        if (description.length < 30) {
            return description;
        }
        let descriptionResult = description.substr(0, 30);
        let spacePos = descriptionResult.lastIndexOf(" ");
        if (spacePos !== -1) {
            descriptionResult = descriptionResult.substr(0, spacePos);
        }
        descriptionResult += "...";

        return descriptionResult;
    }

    componentDidMount(): void {
        this.getNextMessageBatch(true);
    }

    async getNextMessageBatch(seed: boolean): Promise<void> {
        let accountsRef = firebase.database().ref(`schoolMessages/messagesMinimised/${this.props.user.schoolId}`).orderByChild("dateUpdated").startAt(true);
        if (seed == null || !seed) {
            // Find last message
            let lastId = this.state.messageIdsSorted[this.state.messageIdsSorted.length - 1];
            if (lastId != null) {
                accountsRef = accountsRef.endAt(this.state.messages.get(lastId).dateUpdated);
            }
        }
        accountsRef = accountsRef.limitToLast(10);
        try {
            let snapshot = await accountsRef.once('value');
            let newMoreMessages = this.state.moreMessages;
            let messagesData = snapshot.val();

            let newMessages = new Map<string, NewsItemMinimised>();
            let newMessageIdsOrdered: string[] = [];
            if (messagesData != null) {
                if (Object.keys(messagesData).length < 10) {
                    newMoreMessages = false;
                }
                for (let nextMessageId in messagesData) {
                    if (this.state.messageIdsSorted.indexOf(nextMessageId) == -1) {
                        newMessageIdsOrdered.push(nextMessageId);
                        newMessages.set(nextMessageId, NewsItemMinimised.fromFirebase(messagesData[nextMessageId]));
                    }
                }
            } else {
                newMoreMessages = false;
            }
            newMessageIdsOrdered.reverse();

            this.setState({
                messageIdsSorted: [...this.state.messageIdsSorted, ...newMessageIdsOrdered],
                messages: new Map([...this.state.messages, ...newMessages]),
                moreMessages: newMoreMessages,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(messageId: string): Promise<void> {
        try {
            const messageAccountsRef = firebase.database().ref(`schoolMessages/messages/${this.props.user.schoolId}/${messageId}/deactivated`);
            await messageAccountsRef.set(true);

            this.state.messages.delete(messageId);

            this.setState({});
            this.props.snackbar("Deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }
}
export default MessagesView;
import React, { Component } from 'react';
import firebase from '../firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";
import queryString from 'query-string';
import { User } from '../data/user';
import { EngagementAward } from '../data/engagement';

interface IState {
    awards: Map<string, EngagementAward>;
    awardIdsSorted: string[];
    moreAwards: boolean;
}

interface IProps extends RouteComponentProps {
    tutorialCallback: (pageName: string) => void;
    user: User;
    snackbar: (text?: string) => void;
}

class AwardsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            awards: new Map(),
            awardIdsSorted: [],
            moreAwards: true,
        };

        this.removeItem = this.removeItem.bind(this);
        this.getNextAwardsBatch = this.getNextAwardsBatch.bind(this);
        this.makeShortString = this.makeShortString.bind(this);
    }

    render(): JSX.Element {

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All Engagement Awards</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Engagement Awards</div>
                        <p className="cardSubTitle">All the engagement awards created by the school. An engagement award can be linked to a news items, home learning feedback, chat message or avatar.</p>
                        <div>
                            <Link to={`/engagementAwards/-1`}>
                                <Button className="adminPagesButton" type="button">
                                    Create Engagement Award
                        </Button>
                            </Link>
                            <a target="_blank" href="https://docs.google.com/document/d/17fg0yIBpuLdYoWk0YJ4Y1xwDL7Rr4TdfDoPMdUHqFbk/edit#">
                                <Button style={{marginLeft:"15px"}} className="altButton">Read Tutorial</Button>
                            </a>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Points</th>
                                    <th>Cost</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.awardIdsSorted.map((awardId) => {
                                    let award = this.state.awards.get(awardId);

                                    if (award == null) {
                                        return;
                                    }

                                    return <tr>
                                        <th scope="row"><Link to={`/engagementAwards/${awardId}`}>{award.title}</Link></th>
                                        <td>{this.makeShortString(award.description)}</td>
                                        <td>{award.points}</td>
                                        <td>{award.cost}</td>
                                        <td><Button color="link" onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this award", confirmText: "Confirm" });
                                            if (result) {
                                                this.removeItem(awardId)
                                            }
                                        }}><i className="material-icons">delete</i></Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        {this.state.moreAwards ?
                            <div>
                                <p>Showing {this.state.awardIdsSorted.length}</p>
                                <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.getNextAwardsBatch(false);
                                    }}>
                                    Show more
                        </Button>
                            </div> : <span />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    makeShortString(description: string): string {
        if (description == null) {
            return "";
        }
        if (description.length < 30) {
            return description;
        }
        let descriptionResult = description.substr(0, 30);
        let spacePos = descriptionResult.lastIndexOf(" ");
        if (spacePos !== -1) {
            descriptionResult = descriptionResult.substr(0, spacePos);
        }
        descriptionResult += "...";

        return descriptionResult;
    }

    componentDidMount(): void {
        this.getNextAwardsBatch(true);
    }

    async getNextAwardsBatch(seed: boolean): Promise<void> {
        if (seed != null && seed) {
            this.setState({
                awards: new Map(),
                awardIdsSorted: [],
                moreAwards: true,
            });
        }
        let awardsRef = firebase.firestore().collection(`engagementAwards/${this.props.user.schoolId}/awards`)
            .where('removed', '==', false)
            .orderBy('updateDate');

        if (seed == null || !seed) {
            // Find last award
            let lastId = this.state.awardIdsSorted[this.state.awardIdsSorted.length - 1];
            if (lastId != null) {
                awardsRef = awardsRef.endAt(this.state.awards.get(lastId).updateDate);
            }
            awardsRef = awardsRef.limitToLast(11);
        } else {
            awardsRef = awardsRef.limitToLast(10);
        }
        try {
            let snapshot = await awardsRef.get();
            let newMoreAwards = this.state.moreAwards;
            let awards = snapshot.docs;

            let newAwards = new Map<string, EngagementAward>();
            let newAwardIdsSorted: string[] = [];
            if (awards != null) {
                for (let nextAwardSnapshot of awards) {
                    let nextAwardId = nextAwardSnapshot.id;
                    if (!this.state.awards.has(nextAwardId)) {
                        newAwards.set(nextAwardId, EngagementAward.fromFirebase(nextAwardSnapshot.data()));
                        newAwardIdsSorted.push(nextAwardId);
                    }
                }
            }
            newAwardIdsSorted.reverse();

            if (newAwardIdsSorted.length < 10) {
                newMoreAwards = false;
            }
            this.setState({
                awardIdsSorted: [...this.state.awardIdsSorted, ...newAwardIdsSorted],
                awards: new Map([...this.state.awards, ...newAwards]),
                moreAwards: newMoreAwards,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(awardId: string): Promise<void> {
        try {
            await firebase.firestore().runTransaction(async (batch) => {
                let overviewPath = `engagementAwards/${this.props.user.schoolId}`;
                let productPath = `${overviewPath}/awards/${awardId}`;
                const awardDeactivatedRef = firebase.firestore().doc(productPath);
                batch.update(awardDeactivatedRef, { removed: true });

                batch.set(firebase.firestore().doc(overviewPath), {
                    awardsOverview: {
                        [awardId]: firebase.firestore.FieldValue.delete()
                    },
                    updated: firebase.firestore.FieldValue.serverTimestamp(),
                }, { merge: true });
            });

            this.state.awards.delete(awardId);

            this.setState({});
            this.props.snackbar("Deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }
}
export default AwardsView;
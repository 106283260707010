import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, Modal, ModalHeader, ModalBody, Card, CardHeader, Collapse, CardBody, InputGroup, InputGroupAddon, UncontrolledTooltip, Spinner
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import DatePicker from "react-datepicker";
import GoogleSuggest from "./google_suggest";
import "react-datepicker/dist/react-datepicker.css";
import ImageUpload from "./ninja_components/image_upload";
import IconSelection from "./ninja_components/icon_selection";
import EmojiSelection from "./ninja_components/emoji_selection";
import CostEntry from "./ninja_components/cost_entry";
import confirm from "reactstrap-confirm";
import queryString from 'query-string'
import { v4 as uuidv4 } from 'uuid';
import { Event, EventAccount, EventAccountGroup, EventDetails, EventQuestion, EventResponses, QuestionResponse } from './data/events';
import { User } from './data/user';
import { AccountGroup, AccountMinimised } from './data/accounts';

interface IState {
    event: Event;
    eventId: string;
    currentEventTitle: string;
    currentEventStartDateTime: Date,
    currentEventEndDateTime: Date,
    currentEventDescription: string;
    currentEventQuestionRequests: Map<string, EventQuestion>,
    currentEventImageUrl: string;
    currentEventImagePath: string;
    currentEventCost: number;
    currentEventCostDescription: string;
    currentEventIcon: string;
    currentEventIconFamily: string;
    currentEventAccounts: Map<string, EventAccount>;
    currentEventAccountGroups: Map<string, EventAccountGroup>,
    currentEventWebLink: string;
    currentEventVideoLink: string;
    currentEventVideoRestricted: boolean;
    currentEventVideoAvailableFrom: number;
    currentEventPlaceAddress: string;
    currentEventPlaceId: string;
    currentEventCallLink: string;
    newEventAccountGroup: string;
    newEventAccountGroupFutures: boolean;
    newEventAccount: string;
    newQuestionRequestText: string;
    newQuestionRequestAnswers: string[];
    showIconModal: boolean;
    showQuestion: boolean;
    modalAnswerView: string;
    newQuestionView: boolean;
    newInviteesView: boolean;
    filter: string;
    collapse: boolean;
}

interface MatchParams {
    eventId: string;

}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    accountGroups: Map<string, AccountGroup>;
    accountsMinimised: Map<string, AccountMinimised>;
    snackbar: (text?: string) => void;
}

interface AccountDetailsStub {
    id: string;
    name: string;
    additional: string;
    read: boolean;
    attending: boolean;
    paid: boolean;
}

class EventView extends Component<IProps, IState> {
    calendar: boolean; // Whether we came from calendar page
    listenRef: firebase.database.Reference;

    constructor(props: IProps) {
        super(props);
        this.state = {
            event: null,
            eventId: props.match.params.eventId,
            currentEventTitle: '',
            currentEventStartDateTime: null,
            currentEventEndDateTime: null,
            currentEventDescription: '',
            currentEventQuestionRequests: new Map(),
            currentEventImageUrl: '',
            currentEventImagePath: '',
            currentEventCost: null,
            currentEventCostDescription: '',
            currentEventIcon: null,
            currentEventIconFamily: '',
            currentEventAccounts: new Map(),
            currentEventAccountGroups: new Map(),
            currentEventWebLink: '',
            currentEventVideoLink: '',
            currentEventVideoRestricted: true,
            currentEventVideoAvailableFrom: 5,
            currentEventPlaceAddress: '',
            currentEventPlaceId: '',
            currentEventCallLink: '',
            newEventAccountGroup: '',
            newEventAccountGroupFutures: true,
            newEventAccount: '',
            newQuestionRequestText: '',
            newQuestionRequestAnswers: ["Yes", "No"],
            showIconModal: false,
            showQuestion: null,
            modalAnswerView: null,
            newQuestionView: false,
            newInviteesView: false,
            filter: 'NAME',
            collapse: props.match.params.eventId !== "-1",
        };
        const values = queryString.parse(props.location.search);
        this.calendar = values.calendar == null ? false : values.calendar === "true";
        this.listenRef = null;
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleQuestionAdd = this.handleQuestionAdd.bind(this);
        this.handleQuestionRemove = this.handleQuestionRemove.bind(this);
        this.handleRecipientAdd = this.handleRecipientAdd.bind(this);
        this.handleIconChange = this.handleIconChange.bind(this);
        this.handleRecipientGroupAdd = this.handleRecipientGroupAdd.bind(this);
        this.createEvent = this.createEvent.bind(this);
        this.handlePlaceUpdate = this.handlePlaceUpdate.bind(this);
        this.handleRemoveAnswer = this.handleRemoveAnswer.bind(this);
        this.handleAddAnswer = this.handleAddAnswer.bind(this);
        this.handleAnswerEdit = this.handleAnswerEdit.bind(this);
        this.closeAnswerModal = this.closeAnswerModal.bind(this);
        this.toggleNewQuestionModal = this.toggleNewQuestionModal.bind(this);
        this.toggleNewInviteesModal = this.toggleNewInviteesModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleEmojiChange = this.handleEmojiChange.bind(this);
        this.handleCostChange = this.handleCostChange.bind(this);
        this.handleImageChangeFail = this.handleImageChangeFail.bind(this);
        this.startResponseListener = this.startResponseListener.bind(this);
        this.toggleRecipientGroup = this.toggleRecipientGroup.bind(this);
    }

    render() {

        let orderedAccountIds = new Array<AccountDetailsStub>();
        let totalRead = 0;
        let totalAttending = 0;
        let totalPaid = 0;
        let usedAccountIds = Array<string>();
        Array.from(this.state.currentEventAccounts.keys()).forEach((accountId) => {
            let accountRecipientDeets = this.state.currentEventAccounts.get(accountId);
            if (accountRecipientDeets.recipient != null && accountRecipientDeets.recipient) {
                usedAccountIds.push(accountId);
                let account = this.props.accountsMinimised.get(accountId);
                let name;
                let additional;
                if (account == null) {
                    name = "Deactivated account";
                    additional = "";
                } else {
                    name = account.name;
                    additional = account.additional;
                }
                let read = this.state.event.responses != null && this.state.event.responses.read != null && this.state.event.responses.read.has(accountId) && this.state.event.responses.read.get(accountId);
                let attending = this.state.event.responses != null && this.state.event.responses.attendance != null && this.state.event.responses.attendance.has(accountId) && this.state.event.responses.attendance.get(accountId).attending != null && this.state.event.responses.attendance.get(accountId).attending;
                let paid = this.state.event.responses != null && this.state.event.responses.payment != null && this.state.event.responses.payment.has(accountId);
                orderedAccountIds.push({
                    id: accountId,
                    name: name,
                    additional: additional,
                    read: read,
                    attending: attending,
                    paid: paid,
                });
                if (read) totalRead++;
                if (attending) totalAttending++;
                if (paid) totalPaid++;
            }
        });
        orderedAccountIds.sort((entry1, entry2) => {
            if (this.state.filter === 'READ') {
                if (entry1.read && !entry2.read) {
                    return -1;
                } else if (entry2.read && !entry1.read) {
                    return 1;
                }
            } else if (this.state.filter === 'ATTENDING') {
                if (entry1.attending && !entry2.attending) {
                    return -1;
                } else if (entry2.attending && !entry1.attending) {
                    return 1;
                }
            }
            return entry1.name.localeCompare(entry2.name);
        });

        let orderedAllIds = Array.from(this.props.accountsMinimised.keys());
        orderedAllIds = orderedAllIds.filter((el) => {
            return !usedAccountIds.includes(el);
        });
        orderedAllIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).getLowercaseName().localeCompare(this.props.accountsMinimised.get(id2).getLowercaseName());
        });

        let orderedAllGroupIds = Array.from(this.props.accountGroups.keys());
        orderedAllGroupIds.sort((id1, id2) => {
            return this.props.accountGroups.get(id1).details.getLowercaseName().localeCompare(this.props.accountGroups.get(id2).details.getLowercaseName());
        });

        let futureGroupIds = Array.from(this.state.currentEventAccountGroups.keys());
        futureGroupIds = futureGroupIds.filter((e1) => {
            return this.state.currentEventAccountGroups.get(e1).futureMembers;
        });
        futureGroupIds.sort((id1, id2) => {
            return this.props.accountGroups.get(id1).details.getLowercaseName().localeCompare(this.props.accountGroups.get(id2).details.getLowercaseName());
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {!this.calendar ?
                            <BreadcrumbItem><Link to={'/events'}>All events</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={'/eventsCalendar'}>Calendar</Link></BreadcrumbItem>
                        }
                        <BreadcrumbItem active>{this.state.event != null ? this.state.event.details.title : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Event</div>
                        <p className="cardSubTitle">All the events that have been shared with accounts. These appear in the events feed of users linked to the accounts.</p>
                        {this.state.event != null ?
                            <div>
                                {this.state.eventId === "-1" ? null :
                                    <Button onClick={this.toggle} className="altButton">
                                        {this.state.collapse ? "View details" : "Close"}
                                    </Button>
                                }
                                <Collapse isOpen={!this.state.collapse}>
                                    <Form id="updateEvent" onSubmit={this.handleUpdate}></Form>
                                    <FormGroup>
                                        <Label for="currentEventTitle">Title *</Label>
                                        <InputGroup>
                                            <Input form="updateEvent" type="text" required name="currentEventTitle" onChange={(e) => this.setState({
                                                currentEventTitle: e.target.value
                                            })} value={this.state.currentEventTitle} />
                                            <InputGroupAddon addonType={"append"}>
                                                <EmojiSelection callback={(emoji) => {
                                                    this.handleEmojiChange(emoji, true);
                                                }} />
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentEventDescription">Description *</Label>
                                        <InputGroup>
                                            <Input form="updateEvent" type="textarea" required name="currentEventDescription" onChange={(e) => this.setState({
                                                currentEventDescription: e.target.value
                                            })} value={this.state.currentEventDescription} />
                                            <InputGroupAddon addonType={"append"}>
                                                <EmojiSelection callback={(emoji) => {
                                                    this.handleEmojiChange(emoji, false);
                                                }} />
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <CostEntry callback={this.handleCostChange} enabled={this.state.eventId === "-1"}
                                        currentCost={this.state.currentEventCost} />
                                    {this.state.currentEventCost != null && this.state.currentEventCost > 0 ?
                                        <FormGroup>
                                            <Label for="currentEventCostDescription">Description of cost *</Label>
                                            <Input form="updateEvent" type="text" required name="currentEventCostDescription" onChange={(e) => this.setState({
                                                currentEventCostDescription: e.target.value
                                            })} value={this.state.currentEventCostDescription} />
                                        </FormGroup> : <span />
                                    }
                                    <FormGroup>
                                        <Label for="currentEventStartDateTime">Start date & time *</Label><br />
                                        <DatePicker
                                            dropdownMode='scroll'
                                            selected={this.state.currentEventStartDateTime}
                                            showTimeSelect
                                            required={true}
                                            dateFormat="dd MMM yyyy - HH:mm"
                                            timeFormat="HH:mm"
                                            minDate={new Date()}
                                            onChange={this.handleStartDateChange}
                                            className="form-control"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentEventEndDateTime">End date & time *</Label><br />
                                        <DatePicker
                                            dropdownMode='scroll'
                                            selected={this.state.currentEventEndDateTime}
                                            showTimeSelect
                                            required={true}
                                            dateFormat="dd MMM yyyy - HH:mm"
                                            timeFormat="HH:mm"
                                            minDate={this.state.currentEventStartDateTime}
                                            onChange={this.handleEndDateChange}
                                            className="form-control"
                                        />
                                    </FormGroup>
                                    <IconSelection callback={this.handleIconChange} currentIcon={this.state.currentEventIcon} currentIconFamily={this.state.currentEventIconFamily} />
                                    <ImageUpload callback={this.handleImageChange} schoolId={this.props.user.schoolId} failureCallback={this.handleImageChangeFail} filePath={"eventImages"}
                                        currentImage={{ url: this.state.currentEventImageUrl, path: this.state.currentEventImagePath }} />
                                    <table width={"100%"}>
                                        <tr>
                                            <td width={"50%"}>
                                                <FormGroup>
                                                    <Label for="currentEventVideoLink">Pre-recorded Video/Live Stream link (YouTube url) <i className="fas fa-info-circle icons-info" id="help-event-videolink" /></Label>
                                                    <Input form="updateEvent" disabled={this.state.currentEventCallLink != null && this.state.currentEventCallLink !== ''} type="text" name="currentEventVideoLink" onChange={(e) => this.setState({
                                                        currentEventVideoLink: e.target.value
                                                    })} value={this.state.currentEventVideoLink} />
                                                    <UncontrolledTooltip placement="bottom" autohide={false} target="help-event-videolink">
                                                        Show a video or stream live on the event. This needs to be a YouTube url (web address) like
                                                        https://www.youtube.com/watch?v=ZtDGssvp_cU&t=1s
                                                            </UncontrolledTooltip>
                                                </FormGroup>
                                            </td>
                                            <td width={"50%"}>
                                                <FormGroup>
                                                    <Label for="currentEventCallLink">Video call link (Google hangout url) <i className="fas fa-info-circle icons-info" id="help-event-calllink" /></Label>
                                                    <Input form="updateEvent" disabled={this.state.currentEventVideoLink != null && this.state.currentEventVideoLink !== ''} type="text" name="currentEventCallLink" onChange={(e) => this.setState({
                                                        currentEventCallLink: e.target.value
                                                    })} value={this.state.currentEventCallLink} />
                                                    <UncontrolledTooltip placement="bottom" autohide={false} target="help-event-calllink">
                                                        Allow a student to join a google hangout for 2 way conversations. Will have a format like https://meet.google.com/uoo-nxwd-zio
                                                            </UncontrolledTooltip>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                    </table>
                                    {((this.state.currentEventVideoLink != null && this.state.currentEventVideoLink !== '') ||
                                        (this.state.currentEventCallLink != null && this.state.currentEventCallLink !== '')) &&
                                        this.state.currentEventCost != null && this.state.currentEventCost > 0 ?
                                        <FormGroup>
                                            <label>Video restricted to paid attendees? * <i className="fas fa-info-circle icons-info" id="help-event-restrictedvideo" /></label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input form="updateEvent" type="radio" value="true" name='currentEventVideoRestricted'
                                                        checked={this.state.currentEventVideoRestricted}
                                                        onChange={(e) => this.setState({
                                                            currentEventVideoRestricted: e.target.checked
                                                        })} />
                                                            &nbsp;Yes
                                                        </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input form="updateEvent" type="radio" value="false" name='currentEventVideoRestricted'
                                                        checked={!this.state.currentEventVideoRestricted}
                                                        onChange={(e) => this.setState({
                                                            currentEventVideoRestricted: !e.target.checked
                                                        })} />
                                                            &nbsp;No
                                                        </Label>
                                            </FormGroup>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-event-restrictedvideo">
                                                Whether only those who have paid the event costs can see the video feed.
                                                    </UncontrolledTooltip>
                                        </FormGroup> : <span />
                                    }
                                    {(this.state.currentEventVideoLink != null && this.state.currentEventVideoLink !== '') ||
                                        (this.state.currentEventCallLink != null && this.state.currentEventCallLink !== '') ?
                                        <FormGroup>
                                            <Label for="currentEventVideoAvailableFrom">Video access before event start in minutes (Leave empty for always available) <i
                                                className="fas fa-info-circle icons-info" id="help-event-videoavailablefrom" /></Label>
                                            <Input form="updateEvent" type="number" name="currentEventVideoAvailableFrom" min={0} onChange={(e) => this.setState({
                                                currentEventVideoAvailableFrom: e.target.value === "" ? 0 : parseInt(e.target.value)
                                            })}
                                                value={this.state.currentEventVideoAvailableFrom} />
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-event-videoavailablefrom">
                                                How long before the event should the user be able to see the video. If the video should always be available then leave this empty.
                                                    </UncontrolledTooltip>
                                        </FormGroup> : <span />
                                    }
                                    <FormGroup>
                                        <Label for="currentEventWebLink">Web link <i className="fas fa-info-circle icons-info" id="help-event-weblink" /></Label>
                                        <Input form="updateEvent" type="text" name="currentEventWebLink" onChange={(e) => this.setState({
                                            currentEventWebLink: e.target.value
                                        })} value={this.state.currentEventWebLink} />
                                        <UncontrolledTooltip placement="bottom" autohide={false} target="help-event-weblink">
                                            Shows a web link on the event information. If a user clicks on it then they are transferred to a web browser to view the web link.
                                                </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Map location&nbsp;&nbsp;<img alt={""} src={'/images/powered_by_google.png'} height={16} /></Label>
                                        <GoogleSuggest updatePlace={this.handlePlaceUpdate} initialValue={this.state.currentEventPlaceAddress} />
                                    </FormGroup>
                                    {this.state.eventId !== "-1" ?
                                        <div><Button form="updateEvent" className="adminPagesButton">Update</Button></div> : <div><br /><Button form="updateEvent" className="adminPagesButton">Create event</Button></div>
                                    }
                                </Collapse>
                            </div> : <Spinner />
                        }
                    </CardBody>
                </Card>
                {this.state.event != null ?
                    <div>
                        {this.state.eventId === "-1" ? <br /> :
                            <Card className="mainCard top-buffer">
                                <CardBody className="d-flex flex-column">
                                    <div className="cardTitle2">Questions</div>
                                    <div>
                                        <Button onClick={this.toggleNewQuestionModal} className="adminPagesButton buttonBuffer">
                                            Add question
                                        </Button>
                                    </div>
                                    {Array.from(this.state.currentEventQuestionRequests.keys()).length > 0 ?
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Question</th>
                                                    <th>Options</th>
                                                    <th>Responded</th>
                                                    <th>Not responded</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.from(this.state.currentEventQuestionRequests.keys()).map((questionId) => {
                                                    let request = this.state.currentEventQuestionRequests.get(questionId);
                                                    let responded = 0;
                                                    if (this.state.event.responses != null && this.state.event.responses.questions != null && this.state.event.responses.questions.has(questionId)) {
                                                        let respondedValues: string[] = Array.from(this.state.event.responses.questions.get(questionId).keys());
                                                        respondedValues = respondedValues.filter((el) => {
                                                            return !orderedAllIds.includes(el);
                                                        });
                                                        responded = respondedValues.length;
                                                    }
                                                    let notResponded = usedAccountIds.length - responded;
                                                    return <tr key={questionId + 'tr'}>
                                                        <th scope="row" key={questionId + 'th'}>{request.description}</th>
                                                        <td key={questionId + 'td1'}>
                                                            {request.answers.join(", ")}
                                                        </td>
                                                        <td key={questionId + 'td2'}>
                                                            {responded}
                                                        </td>
                                                        <td key={questionId + 'td3'}>
                                                            {notResponded}
                                                        </td>
                                                        <td key={questionId + 'td4'}>
                                                            <Button key={questionId + 'button1'} color="link" onClick={() => {
                                                                this.toggleAnswerModal(questionId);
                                                            }}><i className="material-icons">list</i></Button>
                                                            <Button key={questionId + 'button2'} color="link" onClick={async () => {
                                                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this question", confirmText: "Confirm" });
                                                                if (result) {
                                                                    this.handleQuestionRemove(questionId);
                                                                }
                                                            }}><i className="material-icons">delete</i></Button>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                        : <span />
                                    }
                                </CardBody></Card>
                        }
                        {this.state.eventId === "-1" ? <br /> :
                            <React.Fragment>
                                <Card className="mainCard top-buffer">
                                    <CardBody className="d-flex flex-column">
                                        <div className="cardTitle2">Invitees {orderedAccountIds.length > 0 ? ` (${orderedAccountIds.length})` : ""}</div>
                                        <div>
                                            <Button onClick={this.toggleNewInviteesModal} className="adminPagesButton buttonBuffer">
                                                Add invitees
                                                </Button>
                                        </div>
                                        {orderedAccountIds.length > 0 ?
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Name <Button disabled={this.state.filter === 'NAME'} color="link" onClick={async () => {
                                                            this.setState({
                                                                filter: 'NAME'
                                                            })
                                                        }}><i className="material-icons material-icons-xs">sort</i></Button>
                                                        </th>
                                                        <th>Additional</th>
                                                        <th>Read {totalRead > 0 ? `(${totalRead})` : ""} <Button disabled={this.state.filter === 'READ'} color="link" onClick={async () => {
                                                            this.setState({
                                                                filter: 'READ'
                                                            })
                                                        }}><i className="material-icons material-icons-xs">sort</i></Button></th>
                                                        <th>Attending {totalAttending > 0 ? `(${totalAttending})` : ""} <Button disabled={this.state.filter === 'ATTENDING'} color="link" onClick={async () => {
                                                            this.setState({
                                                                filter: 'ATTENDING'
                                                            })
                                                        }}><i className="material-icons material-icons-xs">sort</i></Button></th>
                                                        <th>{this.state.currentEventCost != null && this.state.currentEventCost > 0 ? `Paid ${totalPaid > 0 ? `(${totalPaid})` : ""}` : ""}</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderedAccountIds.map((accountEntry) => {
                                                        let accountId = accountEntry.id;
                                                        return <tr key={accountId + 'tr'}>
                                                            <th scope="row" key={accountId + 'th'}><Link to={`/accounts/${accountId}`}>{accountEntry.name}</Link></th>
                                                            <td key={accountId + 'td1'}>{accountEntry.additional}</td>
                                                            <td key={accountId + 'td2'}>{accountEntry.read ? "Yes" : "No"}</td>
                                                            <td key={accountId + 'td3'}>{accountEntry.attending ? "Yes" : "No"}</td>
                                                            <td key={accountId + 'td4'}>{this.state.currentEventCost != null && this.state.currentEventCost > 0 ? accountEntry.paid ? "Yes" : "No" : ""}</td>
                                                            <td key={accountId + 'td5'}>
                                                                <Button key={accountId + 'button'} color="link" onClick={async () => {
                                                                    let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove this recipient", confirmText: "Confirm" });
                                                                    if (result) {
                                                                        this.removeRecipient(accountId)
                                                                    }
                                                                }}>
                                                                    <i className="material-icons">delete</i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </Table> : <div>No Invitees</div>
                                        }
                                    </CardBody></Card>
                                {futureGroupIds.length <= 0 ? <br /> :
                                    <Card className="mainCard top-buffer">
                                        <CardBody className="d-flex flex-column">
                                            <div>
                                                <div className="cardTitle2">Invite future account group members <i className="fas fa-info-circle icons-info" id="help-event-futureMembers" /></div>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-event-futureMembers">
                                                    Adding an account to an account group can automatically add the account to this event.
                                                    Simply add the account group to the invitees above and select to include future members.
                                                        </UncontrolledTooltip>
                                                <p>Note this will only apply while the event is in the future. <i className="fas fa-info-circle icons-info" id="help-event-futureMembersExpire" /></p>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-event-futureMembersExpire">
                                                    Once an event is in the past new members of the group will no longer be added to the event.
                                                    Otherwise new group members would have their event feed filled with historical events that are not relevant.
                                                    Note that you can manually add accounts to historical events if required.
                                                        </UncontrolledTooltip>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Account group name</th>
                                                            <th>New account group members see event</th>
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {futureGroupIds.map((accountGroupId) => {
                                                            let accountGroup = this.props.accountGroups.get(accountGroupId);
                                                            let accountGroupRecipientDeets = this.state.currentEventAccountGroups.get(accountGroupId);
                                                            let name;
                                                            if (accountGroup == null || !accountGroupRecipientDeets.futureMembers) {
                                                                return "";
                                                            } else {
                                                                name = accountGroup.details.name;
                                                            }
                                                            return <tr key={accountGroupId + 'tr'}>
                                                                <th scope="row" key={accountGroupId + 'th'}><Link to={`/accountGroups/${accountGroupId}`}>{name}</Link></th>
                                                                <td key={accountGroupId + 'td1'}>{accountGroupRecipientDeets.futureMembers ? "Yes" : "No"}</td>
                                                                <td key={accountGroupId + 'td5'}>
                                                                    <Button key={accountGroupId + 'button'} color="link" onClick={async () => {
                                                                        let result = await confirm({
                                                                            title: "Confirm",
                                                                            message: "Please confirm you want to remove this account group linkage",
                                                                            confirmText: "Confirm"
                                                                        });
                                                                        if (result) {
                                                                            this.toggleRecipientGroup(accountGroupId, false)
                                                                        }
                                                                    }}>
                                                                        <i className="material-icons">delete</i>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div></CardBody></Card>
                                }
                            </React.Fragment>
                        }
                        <br /><br /><br /><br /><br /><br />
                    </div> : <br />
                }
                <Modal isOpen={this.state.newInviteesView} toggle={this.toggleNewInviteesModal}>
                    <ModalHeader toggle={this.toggleNewInviteesModal}>Question responses</ModalHeader>
                    <ModalBody>
                        <div className="border rounded form-margin">
                            <FormGroup>
                                <Label for="newEventAccount">Accounts</Label>
                                <Input type="select" name="newEventAccount"
                                    onChange={(e) => this.setState({
                                        newEventAccount: e.target.value
                                    })}>
                                    <option value={""}>&nbsp;</option>
                                    {/*TODO*/}
                                    {orderedAllIds.map((key) => {
                                        return (
                                            <option value={key}
                                                key={key}>{this.props.accountsMinimised.get(key).name}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup>
                            <Button color="success" disabled={this.state.newEventAccount == null || this.state.newEventAccount === ""}
                                onClick={() => this.handleRecipientAdd(false)}>
                                Add
                            </Button>&nbsp;
                            <Button color="success" disabled={this.state.newEventAccount == null || this.state.newEventAccount === ""}
                                onClick={() => this.handleRecipientAdd(true)}>
                                Add & close
                            </Button>
                        </div>
                        <div className="border rounded form-margin">
                            <FormGroup>
                                <Label for="newEventAccountGroup">Account Groups</Label>
                                <Input type="select" name="newEventAccountGroup"
                                    onChange={(e) => this.setState({
                                        newEventAccountGroup: e.target.value
                                    })}>
                                    <option value={""}>&nbsp;</option>
                                    {/*TODO*/}
                                    {orderedAllGroupIds.map((key) => {
                                        return (
                                            <option value={key}
                                                key={key}>{this.props.accountGroups.get(key).details.name}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name='newEventAccountGroupFutures'
                                        checked={this.state.newEventAccountGroupFutures}
                                        onChange={(e) => this.setState({
                                            newEventAccountGroupFutures: e.target.checked
                                        })} />
                                    Future account group members can access event?
                                </Label>
                            </FormGroup><br />
                            <Button color="success" disabled={this.state.newEventAccountGroup == null || this.state.newEventAccountGroup === ""}
                                onClick={() => this.handleRecipientGroupAdd(false)}>
                                Add
                            </Button>&nbsp;
                            <Button color="success" disabled={this.state.newEventAccountGroup == null || this.state.newEventAccountGroup === ""}
                                onClick={() => this.handleRecipientGroupAdd(true)}>
                                Add & close
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.newQuestionView} toggle={this.toggleNewQuestionModal}>
                    <ModalHeader toggle={this.toggleNewQuestionModal}>Add question</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.handleQuestionAdd}>
                            <FormGroup>
                                <Label for="newQuestionRequestText">Question text</Label>
                                <Input required type="text" name="newQuestionRequestText"
                                    onChange={(e) => this.setState({
                                        newQuestionRequestText: e.target.value
                                    })} />
                            </FormGroup>
                            <div className="border rounded form-margin">
                                <FormGroup>
                                    <Label>Options</Label>
                                    {this.state.newQuestionRequestAnswers.map((answer, pos) => {
                                        return <div>
                                            <Input type="text" required name={'answer-' + pos}
                                                placeholder="Text *"
                                                onChange={this.handleAnswerEdit}
                                                value={answer} />
                                            {pos >= 2 ?
                                                <div>
                                                    <Button color="danger" type="button" outline onClick={() => {
                                                        this.handleRemoveAnswer(pos)
                                                    }}>Remove</Button><br /><br />
                                                </div> : <br />
                                            }
                                        </div>
                                    })}
                                    <div>
                                        <Button type="button" outline onClick={() => {
                                            this.handleAddAnswer()
                                        }}>Additional option</Button>
                                    </div>
                                </FormGroup>
                            </div>
                            <br />
                            <Button color="success">Add</Button>
                        </Form>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modalAnswerView != null} toggle={this.closeAnswerModal}>
                    <ModalHeader toggle={this.closeAnswerModal}>Add account</ModalHeader>
                    <ModalBody>
                        {this.state.modalAnswerView != null && this.state.currentEventQuestionRequests.get(this.state.modalAnswerView).answers.map((answer) => {
                            let responses = new Map<string, QuestionResponse>();
                            if (this.state.event.responses != null && this.state.event.responses.questions != null && this.state.event.responses.questions.has(this.state.modalAnswerView)) {
                                responses = this.state.event.responses.questions.get(this.state.modalAnswerView);
                            }
                            return <div>
                                <Alert color="dark">
                                    <h3>{answer}</h3>
                                </Alert>
                                {responses.size > 0 ?
                                    Array.from(responses.keys()).map((accountId) => {
                                        if (usedAccountIds.indexOf(accountId) !== -1) {
                                            let userResponse = responses.get(accountId);
                                            if (userResponse.response === answer) {
                                                return <div>
                                                    <Link to={'/accounts/' + accountId}>{this.props.accountsMinimised.get(accountId).name}</Link>
                                                </div>
                                            } else {
                                                return <span />
                                            }
                                        } else {
                                            return <span />
                                        }
                                    }) : <div>None</div>
                                }
                                <br />
                            </div>
                        })
                        }
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleNewQuestionModal(): void {
        this.setState({
            newQuestionView: !this.state.newQuestionView
        })
    }

    toggleNewInviteesModal(): void {
        this.setState({
            newInviteesView: !this.state.newInviteesView,
            newEventAccountGroupFutures: true,
            newEventAccountGroup: '',
            newEventAccount: '',
        })
    }

    closeAnswerModal(): void {
        this.toggleAnswerModal(null);
    }

    toggleAnswerModal(questionId: string): void {
        this.setState({ modalAnswerView: questionId });
    }

    handlePlaceUpdate(place: google.maps.GeocoderResult): void {
        this.setState({
            currentEventPlaceId: place.place_id,
            currentEventPlaceAddress: place.formatted_address,
        });
    }

    handleStartDateChange(date: Date): void {
        if (date != null && (this.state.currentEventEndDateTime == null || date.getTime() > this.state.currentEventEndDateTime.getTime())) {
            this.setState({
                currentEventStartDateTime: date,
                currentEventEndDateTime: date,
            });
        } else {
            this.setState({
                currentEventStartDateTime: date,
            });
        }
    }

    handleIconChange(currentIcon: string, currentIconFamily: string): void {
        this.setState({
            currentEventIcon: currentIcon,
            currentEventIconFamily: currentIconFamily,
        });
    }

    handleCostChange(newCost: number): void {
        this.setState({
            currentEventCost: newCost
        });
    }

    handleRemoveAnswer(pos: number): void {
        let newAnswers = this.state.newQuestionRequestAnswers.slice();
        newAnswers.splice(pos, 1);
        this.setState({
            newQuestionRequestAnswers: newAnswers,
        });
    }

    handleAnswerEdit(e: React.ChangeEvent<HTMLInputElement>) {
        let index = parseInt(e.target.name.split('-')[1]);
        let newAnswers = this.state.newQuestionRequestAnswers.slice();
        newAnswers[index] = e.target.value;
        this.setState({
            newQuestionRequestAnswers: newAnswers
        });
    }

    handleAddAnswer(): void {
        let newAnswers = this.state.newQuestionRequestAnswers.slice();
        newAnswers.push("");
        this.setState({
            newQuestionRequestAnswers: newAnswers,
        });
    }

    handleEndDateChange(date: Date): void {
        this.setState({
            currentEventEndDateTime: date,
        });
    }

    async handleImageChange(url: string, path: string): Promise<void> {
        if (this.state.eventId !== "-1") {
            let imageDeets = {
                imageUrl: url,
                imagePath: path,
            };
            const eventRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${this.state.eventId}/details`);
            try {
                await eventRef.update(imageDeets);
                this.setState({
                    currentEventImageUrl: url,
                    currentEventImagePath: path,
                });
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Update failed");
            }
        } else {
            this.setState({
                currentEventImageUrl: url,
                currentEventImagePath: path,
            });
        }
    }

    handleImageChangeFail(): void {
        this.props.snackbar("Image upload failed");
    }

    async handleRecipientAdd(close: boolean): Promise<void> {
        let accountRecipientDeets = new EventAccount();
        accountRecipientDeets.recipient = true;
        const accountGroupRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${this.state.eventId}/accounts/${this.state.newEventAccount}`);
        try {
            await accountGroupRef.set(accountRecipientDeets.toFirebase());
            this.state.currentEventAccounts.set(this.state.newEventAccount, accountRecipientDeets);
            this.setState({
                newInviteesView: close == null ? true : !close,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async handleQuestionAdd(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let id = uuidv4();
        let newQuestionRequest = new EventQuestion();
        newQuestionRequest.description = this.state.newQuestionRequestText;
        newQuestionRequest.answers = this.state.newQuestionRequestAnswers;
        const accountGroupRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${this.state.eventId}/details/questionRequests/${id}`);
        try {
            await accountGroupRef.set(newQuestionRequest.toFirebase());
            this.setState({
                newQuestionView: false,
                currentEventQuestionRequests: new Map([...this.state.currentEventQuestionRequests, [id, newQuestionRequest]])
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async handleQuestionRemove(uuid: string): Promise<void> {
        let questions = this.state.currentEventQuestionRequests;
        const accountGroupRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${this.state.eventId}/details/questionRequests/${uuid}`);
        try {
            await accountGroupRef.remove()
            questions.delete(uuid);
            this.setState({});
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }

    async handleRecipientGroupAdd(close: boolean): Promise<void> {
        let newGroup = this.props.accountGroups.get(this.state.newEventAccountGroup);
        await this.toggleRecipientGroup(this.state.newEventAccountGroup, this.state.newEventAccountGroupFutures);

        let newUpdate: any = {};
        let newEventUpdate = new Map<string, EventAccount>();
        Array.from(newGroup.accounts.keys()).forEach((accountId) => {
            let nextDeets = newGroup.accounts.get(accountId);
            if (nextDeets.member) {
                let newEventAccount = new EventAccount();
                newEventAccount.recipient = true;
                newUpdate[accountId] = newEventAccount.toFirebase();
                newEventUpdate.set(accountId, newEventAccount);
            }
        });
        const accountGroupRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${this.state.eventId}/accounts/`);
        try {
            await accountGroupRef.update(newUpdate);

            this.setState({
                currentEventAccounts: new Map([...this.state.currentEventAccounts, ...newEventUpdate]),
                newInviteesView: close == null ? true : !close,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async toggleRecipientGroup(accountGroupId: string, enabled: boolean): Promise<void> {
        let newGroupUpdate = new EventAccountGroup();
        newGroupUpdate.futureMembers = enabled;

        const accountGroupFutureRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${this.state.eventId}/accountGroups/${accountGroupId}`);
        try {
            await accountGroupFutureRef.update(newGroupUpdate.toFirebase());

            this.setState({
                currentEventAccountGroups: new Map([...this.state.currentEventAccountGroups, [accountGroupId, newGroupUpdate]])
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    handleEmojiChange(emojiCharacter: string, title: boolean): void {
        if (title) {
            this.setState({
                currentEventTitle: this.state.currentEventTitle + emojiCharacter
            });
        } else {
            this.setState({
                currentEventDescription: this.state.currentEventDescription + emojiCharacter
            });
        }
    }

    async removeRecipient(accountId: string): Promise<void> {
        let accountRecipientDeets = new EventAccount();
        accountRecipientDeets.recipient = false;
        const accountGroupRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${this.state.eventId}/accounts/${accountId}`);
        await accountGroupRef.set(accountRecipientDeets.toFirebase(), (error) => {
            if (error == null) {
            } else {
                console.log(error);
            }
        });

        this.setState({
            currentEventAccounts: new Map([...this.state.currentEventAccounts, [accountId, accountRecipientDeets]])
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let event = this.state.event;
        if (event.details == null) {
            event.details = new EventDetails();
        }
        event.details.title = this.state.currentEventTitle;
        event.details.description = this.state.currentEventDescription;
        event.details.imageUrl = this.state.currentEventImageUrl;
        event.details.imagePath = this.state.currentEventImagePath;
        event.details.cost = this.state.currentEventCost;
        event.details.costDescription = this.state.currentEventCostDescription;
        event.details.startDateTime = this.state.currentEventStartDateTime.getTime();
        event.details.endDateTime = this.state.currentEventEndDateTime.getTime();
        event.details.iconCodePoint = 58915;
        event.details.iconCodeString = this.state.currentEventIcon;
        event.details.iconFontFamily = this.state.currentEventIconFamily;
        if (event.details.iconFontFamily === "MaterialIcons") {
            event.details.iconFontPackage = null;
        } else {
            event.details.iconFontPackage = "font_awesome_flutter";
        }
        event.details.webLink = this.state.currentEventWebLink;
        event.details.videoLink = this.state.currentEventVideoLink;
        event.details.videoRestricted = this.state.currentEventVideoRestricted;
        event.details.videoAvailableFrom = this.state.currentEventVideoAvailableFrom != null ? this.state.currentEventVideoAvailableFrom : null;
        event.details.callLink = this.state.currentEventCallLink;
        event.details.placeAddress = this.state.currentEventPlaceAddress;
        event.details.placeId = this.state.currentEventPlaceId;

        event.details.questionRequests = this.state.currentEventQuestionRequests;
        await this.createEvent(event);
    }

    async createEvent(event: Event): Promise<void> {
        if (this.state.eventId === "-1") {
            const eventRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}`);
            try {
                let promise = eventRef.push(event.toFirebase());
                let newKey = promise.key;

                await promise;
                this.setState({
                    event: event,
                    eventId: newKey,
                    collapse: true,
                });
                this.props.history.replace(`/events/${newKey}`);
                this.startResponseListener();
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        } else {
            const eventRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${this.state.eventId}/details`);
            try {
                eventRef.update(event.details.toFirebase());
                this.setState({
                    event: event,
                    collapse: true,
                });
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Update failed");
            }
        }
    }

    async componentDidMount(): Promise<void> {
        if (this.state.eventId !== "-1") {
            const itemsRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${this.state.eventId}`);
            itemsRef.once('value', (eventSnapshot) => {
                let event = Event.fromFirebase(eventSnapshot.val());
                this.setState({
                    event: event,
                    currentEventTitle: event.details.title,
                    currentEventStartDateTime: event.details.startDateTime != null ? new Date(event.details.startDateTime) : null,
                    currentEventEndDateTime: event.details.endDateTime != null ? new Date(event.details.endDateTime) : null,
                    currentEventDescription: event.details.description,
                    currentEventImageUrl: event.details.imageUrl != null ? event.details.imageUrl : '',
                    currentEventImagePath: event.details.imagePath != null ? event.details.imagePath : null,
                    currentEventCost: event.details.cost != null ? event.details.cost : null,
                    currentEventCostDescription: event.details.costDescription,
                    currentEventIcon: event.details.iconCodeString != null ? event.details.iconCodeString : "calendar_today",
                    currentEventIconFamily: event.details.iconFontFamily != null ? event.details.iconFontFamily : "MaterialIcons",
                    currentEventAccounts: event.accounts != null ? event.accounts : new Map(),
                    currentEventAccountGroups: event.accountGroups != null ? event.accountGroups : new Map(),
                    currentEventQuestionRequests: event.details.questionRequests != null ? event.details.questionRequests : new Map(),
                    currentEventWebLink: event.details.webLink != null ? event.details.webLink : '',
                    currentEventPlaceAddress: event.details.placeAddress != null ? event.details.placeAddress : '',
                    currentEventPlaceId: event.details.placeId != null ? event.details.placeId : '',
                    currentEventVideoLink: event.details.videoLink != null ? event.details.videoLink : '',
                    currentEventVideoRestricted: event.details.videoRestricted != null ? event.details.videoRestricted : true,
                    currentEventVideoAvailableFrom: event.details.videoAvailableFrom != null ? event.details.videoAvailableFrom : null,
                    currentEventCallLink: event.details.callLink != null ? event.details.callLink : '',
                });
                this.startResponseListener();
            }, (error) => console.log(error));

        } else {
            this.setState({
                event: new Event(),
                currentEventTitle: '',
                currentEventDescription: '',
                currentEventStartDateTime: null,
                currentEventEndDateTime: null,
                currentEventImageUrl: '',
                currentEventImagePath: '',
                currentEventCost: null,
                currentEventCostDescription: '',
                currentEventIcon: "calendar_today",
                currentEventIconFamily: "MaterialIcons",
                currentEventAccounts: new Map(),
                currentEventAccountGroups: new Map(),
                currentEventQuestionRequests: new Map(),
                currentEventWebLink: '',
                currentEventVideoLink: '',
                currentEventVideoRestricted: true,
                currentEventVideoAvailableFrom: 5,
                currentEventPlaceAddress: '',
                currentEventPlaceId: '',
                currentEventCallLink: '',
            });
        }
    }

    startResponseListener(): void {
        this.listenRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${this.state.eventId}/responses`);
        this.listenRef.on('value', (snapshot) => {
            this.state.event.responses = EventResponses.fromFirebase(snapshot.val());
            this.setState({});
        }, (error: any) => console.log(error));
    }

    async componentWillUnmount() {
        if (this.listenRef != null) {
            this.listenRef.off();
        }
    }
}

export default EventView;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, BreadcrumbItem, Breadcrumb, Alert, Button, Card, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";
import { LIFE_NINJA_TRANSACTION, PAYMENT_PROCESSOR_TRANSACTION } from "./ninja_components/cost_entry";
import { Fundraiser } from './data/fundraising';
import { User } from './data/user';

interface IState {
    fundraisers: Map<string, Fundraiser>,
}

interface IProps {
    user: User;
}

class FundraisersView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            fundraisers: new Map(),
        };
    }

    render(): JSX.Element {

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All fundraisers</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Fundraisers</div>
                        <p className="cardSubTitle">Raise money for a specific cause or just generally for your organisation.</p>
                        <div>
                            <Link to={'/fundraisers/-1'}>
                                <Button className="adminPagesButton" type="button">
                                    Add fundraiser
                        </Button>
                            </Link>
                            <a target="_blank" href="https://docs.google.com/document/d/1eWhLUx0VDtu0LyGb06f0ALMAm8ZR5pP0yEkc6UxkrGQ/edit#">
                                <Button style={{marginLeft:"15px"}} className="altButton">Read Tutorial</Button>
                            </a>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Additional</th>
                                    <th>Stars</th>
                                    <th>Donations</th>
                                    <th>Total</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(this.state.fundraisers.keys()).map((fundraiserId) => {
                                    let fundraiser = this.state.fundraisers.get(fundraiserId);
                                    if (fundraiser.details == null) {
                                        fundraiser = new Fundraiser();
                                    }
                                    let totalEarnPence = 0;
                                    let totalStars = 0;
                                    if (fundraiser.orgTotal != null) {
                                        totalStars = fundraiser.orgTotal;
                                        totalEarnPence = totalStars * 0.1;
                                    }
                                    let totalDonations = 0;
                                    if (fundraiser.orgDonationTotal != null) {
                                        totalDonations = fundraiser.orgDonationTotal - (fundraiser.orgDonationTotal * (LIFE_NINJA_TRANSACTION + PAYMENT_PROCESSOR_TRANSACTION));
                                    }
                                    let totalRaised = ((totalEarnPence + totalDonations) / 100).toFixed(2);
                                    return <tr>
                                        <th scope="row"><Link to={`fundraisers/${fundraiserId}`}>{fundraiser.details.name}</Link></th>
                                        <td>{fundraiser.details.additional}</td>
                                        <td>{totalStars}</td>
                                        <td>{`£${(totalDonations / 100).toFixed(2)}`}</td>
                                        <td>{`£${totalRaised}`}</td>
                                        <td>{fundraiserId === 'DEFAULT' ? <span /> : <Button color="link" onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to deactivate this fundraiser", confirmText: "Confirm" });
                                            if (result) {
                                                this.removeItem(fundraiserId)
                                            }
                                        }}><i className="material-icons">delete</i></Button>}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        <br /><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    async removeItem(fundraiserId: string): Promise<void> {
        const accountRef = firebase.database().ref(`fundraising/${this.props.user.schoolId}/fundraisers/${fundraiserId}/deactivated`);
        await accountRef.set(true);
        this.state.fundraisers.delete(fundraiserId);
        this.setState({});
    }

    async componentDidMount(): Promise<void> {
        const fundraisersRef = firebase.database().ref(`fundraising/${this.props.user.schoolId}/fundraisers`).orderByChild('deactivated').equalTo(null);
        try {
            let snapshot = await fundraisersRef.once('value');
            let fundraisersData = snapshot.val();
            let fundraisers = new Map<string, Fundraiser>();
            if (fundraisersData != null) {
                for (let nextFundraiserId in fundraisersData) {
                    fundraisers.set(nextFundraiserId, Fundraiser.fromFirebase(fundraisersData[nextFundraiserId]));
                }
            }
            if (!fundraisers.has('DEFAULT')) {
                let defaultFundraiser = new Fundraiser();
                defaultFundraiser.details.name = 'General fundraising';
                defaultFundraiser.details.additional = "Default general fundraising option";
                fundraisers.set('DEFAULT', defaultFundraiser);
            }
            this.setState({
                fundraisers: fundraisers,
            });
        } catch (error) {
            console.log(error)
        }
    }
}
export default FundraisersView;
//@ts-check

import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import {
    Input, FormGroup, Label,
    Button, BreadcrumbItem, Breadcrumb, Alert, Collapse, Table, Card, CardBody
} from 'reactstrap';
import confirm from "reactstrap-confirm";
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router';
import { User, USER_ROLE } from './data/user';
import { subjectIds } from './Prettifier';
import { LearningWorld } from './data/learning_games';
import { License } from './data/license';

interface IState {
    licenseLearningWorlds: Map<string, Map<string, LearningWorld>>,
    schoolLearningWorlds: Map<string, LearningWorld>;
    user: User;
    expandedStream: string;
    subjectFilter: string;
}

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
    schoolLicenses: Map<string, boolean>;
    tutorialCallback: (pageName: string) => void;
}

class LearningLicensesView extends Component<IProps, IState> {
    licenses: Map<string, License>;

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            licenseLearningWorlds: new Map(),
            schoolLearningWorlds: new Map(),
            user: props.user,
            expandedStream: null,
            subjectFilter: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
        };
        this.licenses = new Map();
        this.getQuerys = this.getQuerys.bind(this);
        this.getAllWorlds = this.getAllWorlds.bind(this);
        this.getFilteredWorlds = this.getFilteredWorlds.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.createWorldRows = this.createWorldRows.bind(this);
    }

    createWorldRows(worlds: Map<string, LearningWorld>, queryValues: string): JSX.Element[] {
        let rows: JSX.Element[] = [];

        if (worlds == null) {
            return rows;
        }
        let worldIdsSorted = Array.from(worlds.keys());
        worldIdsSorted.sort((id1, id2) => {
            return worlds.get(id1).name.localeCompare(worlds.get(id2).name);
        })
        for (let pos = 0; pos < worldIdsSorted.length; pos++) {
            let nextWorldId = worldIdsSorted[pos];
            let nextWorld = worlds.get(nextWorldId);
            let editable = nextWorld.schoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);
            rows.push(
                <tr key={"column-" + (pos)}>
                    <td>
                        <Link to={`/learningWorlds/${nextWorldId}?${queryValues}`}>
                            {nextWorld.name}</Link>
                        {editable && nextWorld.licenseId != null ?
                            <div style={{ "color": "red" }}>Licensed: {nextWorld.licenseId}</div> : ""}
                    </td>
                    <td>
                        <img style={{ height: "40px", width: "auto", objectFit: "contain" }} src={`/images/${nextWorld.image}`} alt="" />
                    </td>
                    <td>
                        {subjectIds.get(nextWorld.subject)}
                    </td>
                    <td>
                        {editable ?
                            <Button color="primary" onClick={async () => {
                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this learning world. Note that any learning paths and minigames will not be removed.", confirmText: "Confirm" });
                                if (result) {
                                    this.removeItem(nextWorldId, this.state.user.schoolId);
                                }
                            }}><i className="material-icons">remove_circle</i></Button> : <span />
                        }
                    </td>
                </tr>
            );
        }
        return rows;
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All learning worlds</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Custom learning worlds</div>
                        <p className="cardSubTitle">Learning worlds are a way to manage the learning paths that are assigned to learning tasks.</p>
                        <div>
                        {this.state.expandedStream === "" ?
                            <Button onClick={() => { this.toggle(null); }} className="altButton">
                                Close
                            </Button> :
                            <Button onClick={() => { this.toggle(""); }} className="altButton">
                                View
                            </Button>
                        }
                        </div>
                        <Collapse isOpen={this.state.expandedStream === ""}>
                            <FormGroup>
                                <Label for="subjectFilter">Subject filter</Label>

                                <Input type="select" name="subjectFilter"
                                    onChange={this.updateFilter}
                                    value={this.state.subjectFilter}>
                                    <option value={''}>All</option>
                                    {Array.from(subjectIds.keys()).map((key) => {
                                        let name = subjectIds.get(key);
                                        return (
                                            <option value={key} key={key}>{name}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup><br />
                            <div className="bottom-buffer">
                                <Button tag={Link} onClick={() => this.props.tutorialCallback('learningWorlds')} to={`/learningWorlds/-1?${queryValues}`} className="adminPagesButton">
                                    Add learning world
                        </Button>
                            </div>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Topology</th>
                                        <th>Subject</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.createWorldRows(this.state.schoolLearningWorlds, queryValues)}
                                </tbody>
                            </Table>
                            {/* <div>
                        {RootView.createCardRows(this.state.schoolLearningWorlds, this.createCard, "")}
                    </div> */}
                        </Collapse>
                    </CardBody>
                </Card><br/>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Bundled learning worlds</div>
                        <p className="cardSubTitle">Learning worlds that are supplied as part of your license selections</p>
                        {Array.from(this.state.licenseLearningWorlds.keys()).map((licenseId) => {
                            return <div>
                                <div className="cardTitle2">{this.licenses.get(licenseId).name}</div>
                            {this.state.expandedStream === licenseId ?
                                    <Button onClick={() => { this.toggle(null); }} className="altButton">
                                        Close
                            </Button> :
                                    <Button onClick={() => { this.toggle(licenseId); }} className="altButton">
                                        View
                            </Button>
                                }
                                <Collapse isOpen={this.state.expandedStream === licenseId}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Topology</th>
                                                <th>Subject</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.createWorldRows(this.state.licenseLearningWorlds.get(licenseId), queryValues)}
                                        </tbody>
                                    </Table>
                                </Collapse>
                                <br /><br/>
                            </div>
                        })}
                    </CardBody>
                </Card>
            </div>
        );
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
        });
    }

    toggle(streamId: string): void {
        this.setState({ expandedStream: streamId });
    }

    async componentDidMount(): Promise<void> {

        try {
            let licensesDoc = await firebase.firestore().doc(`generalConfig/licenseDetails`).get();
            let allLicenses = licensesDoc.data();
            let newLicenses = new Map<string, License>();
            for (let nextLicenseId in allLicenses.licenses) {
                let nextLicenseData = allLicenses.licenses[nextLicenseId];
                let nextLicense = License.fromFirebase(nextLicenseData);
                if (nextLicense.learningWorlds != null
                    && nextLicense.learningWorlds
                    && this.props.schoolLicenses.has(nextLicenseId)
                    && this.props.schoolLicenses.get(nextLicenseId)) {
                    newLicenses.set(nextLicenseId, nextLicense);
                }
            }

            this.licenses = newLicenses;

            await this.getAllWorlds(this.state.subjectFilter);
        } catch (error) {
            console.log(error);
        }
    }

    async updateFilter(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let subjectFilter = e.target.value;
        await this.getAllWorlds(subjectFilter);
        this.setState({
            subjectFilter: subjectFilter,
        });
    }

    async getAllWorlds(subjectId: string) {

        let newSchoolLearningWorldsState = await this.getFilteredWorlds('schoolId', this.state.user.schoolId, subjectId);

        let newLicenseLearningWorldsState = new Map<string, Map<string, LearningWorld>>();
        for (let licenseId of Array.from(this.licenses.keys())) {
            let nextLicenseLearningWorlds = await this.getFilteredWorlds('licenseId', licenseId, "");
            newLicenseLearningWorldsState.set(licenseId, nextLicenseLearningWorlds);
        }
        this.setState({
            schoolLearningWorlds: newSchoolLearningWorldsState,
            licenseLearningWorlds: newLicenseLearningWorldsState,
        })
    }

    async getFilteredWorlds(filterKey: string, filterValue: string, subjectId: string): Promise<Map<string, LearningWorld>> {
        let learningWorldsRef = firebase.firestore().collection('learningWorlds').where(filterKey, '==', filterValue).where('deleted', "==", null);
        if (subjectId != "") {
            learningWorldsRef = learningWorldsRef.where('subject', '==', subjectId);
        }
        let learningWorldsSnapshot = await learningWorldsRef.get();
        let newLearningWorldsState = new Map<string, LearningWorld>();
        learningWorldsSnapshot.forEach((nextLearningWorldSnapshot) => {
            let nextLearningWorldId = nextLearningWorldSnapshot.id;
            let nextLearningWorldData = nextLearningWorldSnapshot.data();
            let nextLearningWorld = LearningWorld.fromFirebase(nextLearningWorldData);
            newLearningWorldsState.set(nextLearningWorldId, nextLearningWorld);
        });

        return newLearningWorldsState;
    }

    async removeItem(worldId: string, schoolId: string): Promise<void> {
        this.state.schoolLearningWorlds.delete(worldId);

        const worldRef = firebase.firestore().doc(`learningWorlds/${worldId}`);
        try {
            await worldRef.update({ deleted: true });
            let updateDeets = {
                updated: firebase.database.ServerValue.TIMESTAMP,
                updateType: 'WORLD',
                updateId: worldId,
            };
            if (schoolId == null) {
                await firebase.database().ref('moduleInformation/changeHistory').push(updateDeets);
            } else {
                await firebase.database().ref(`moduleInformation/changeHistorySchools/${schoolId}`).push(updateDeets);
            }
            this.setState({});
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Remove failed");
        }
    }
}

export default LearningLicensesView;
import { objectToClassConverter } from "./database_object";

export enum TICKET_TYPE { INCIDENT = "Incident", FEATURE = "Feature request", QUESTION = "Question" }

export class Ticket {
    id: string;
    type: TICKET_TYPE;
    subject: string;
    description_text: string;
    status: number;
    updated_at: number;
    description: string;

    static fromFirebase(data: any): Ticket {
        let newTicket = new Ticket();
        if (data == null) {
            return newTicket;
        }
        objectToClassConverter(data, newTicket);

        return newTicket;
    }
}

export class Conversation {
    id: string;
    updated_at: number;
    incoming: boolean;
    body: string;

    static fromFirebase(data: any): Conversation {
        let newConversation = new Conversation();
        if (data == null) {
            return newConversation;
        }
        objectToClassConverter(data, newConversation);

        return newConversation;
    }
}
import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Row, Col, Card, Table, CardBody, Spinner, CardDeck, CardImg
} from 'reactstrap';
import { Link } from "react-router-dom";
import { User } from './data/user';
import { Ticket } from './data/support_tickets';
import { makeShortString } from './helpers/text_lib';
import { Account, AccountGroup, AccountMinimised } from './data/accounts';
import accountsBubble from "./root_view";

interface IState {
    tickets: Ticket[],
    hideChecklist: boolean,
    hideQuestions: boolean,
    checked: boolean,
}

interface IProps {
    tutorialCallback: (pageName: string) => void;
    callback: (title: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
    accountGroups: Map<string, AccountGroup>;
    user: User;
}

class SchoolNameView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            tickets: null,
            hideChecklist: true,
            hideQuestions: true,
            checked: false,
        };
        this.avatarTutorial = this.avatarTutorial.bind(this);
        this.accountCount = this.accountCount.bind(this);
        this.accountGroupCount = this.accountGroupCount.bind(this);
        this.toggleVisibleChecklist = this.toggleVisibleChecklist.bind(this);
        this.toggleVisibleQuestions = this.toggleVisibleQuestions.bind(this);
        this.handleShowTutorials = this.handleShowTutorials.bind(this);
        this.handleChecklistVerify = this.handleChecklistVerify.bind(this);
        this.highlightMenuAccounts = this.highlightMenuAccounts.bind(this);
        this.highlightMenuAdminAccounts = this.highlightMenuAdminAccounts.bind(this);
        this.highlightMenuOrganisationDetails = this.highlightMenuOrganisationDetails.bind(this);
        this.highlightMenuOrganisationLicenses = this.highlightMenuOrganisationLicenses.bind(this);
        this.highlightMenuAccountGroups = this.highlightMenuAccountGroups.bind(this);
        this.highlightMenuAvatar = this.highlightMenuAvatar.bind(this);
    }

    render(): JSX.Element {
        return (
            <div className="homescreen-buffer">
                <CardDeck>
                    <Card style={{ borderColor: '#f6f6f6', boxShadow: '2px 1px 10px #d3d3d3' }}>
                        {this.avatarTutorial()}
                    </Card>
                    <Card style={{ borderColor: '#f6f6f6', boxShadow: '2px 1px 10px #d3d3d3' }}>
                        {this.accountCount()}
                    </Card>
                    <Card style={{ borderColor: '#f6f6f6', boxShadow: '2px 1px 10px #d3d3d3' }}>
                        {this.accountGroupCount()}
                    </Card>
                </CardDeck>
                <br /><br />
                <Card style={{ borderColor: '#f6f6f6f6', boxShadow: '2px 1px 10px #d3d3d3' }}>
                    <CardBody>
                        <h5>Active support requests<span className={"toggle-icon"}><Button className="altButton" onClick={() => this.props.callback("support")}><Link to="/support">Support</Link></Button></span></h5>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Type</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Last updated</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tickets == null ? <Spinner /> :
                                    <div>
                                        {this.state.tickets.map((ticket) => {
                                            let ticketId = ticket.id;
                                            return <tr key={ticketId + 'tr'}>
                                                <th scope="row" key={ticketId + 'th'}><Link to={`support/${ticket.id}`}>{ticket.id}</Link></th>
                                                <td key={ticketId + 'tdType'}>{ticket.type === "Incident" ? "Issue" : ticket.type}</td>
                                                <td key={ticketId + 'td1'}>{ticket.subject}</td>
                                                <td key={ticketId + 'td2'}>{makeShortString(ticket.description_text, 60)}</td>
                                                <td key={ticketId + 'td3'}>{new Date(ticket.updated_at).toDateString()} {new Date(ticket.updated_at).toLocaleTimeString()}</td>
                                                <td key={ticketId + 'td5'}>
                                                </td>
                                            </tr>
                                        })}

                                        {this.state.tickets.length == 0 ? <tr><td>No active tickets</td></tr> : null}
                                    </div>
                                }
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <div className="tutorialPopUp" style={{ right: '15px', width: '21%', top: '77px', position: 'absolute' }}>
                    {this.state.hideChecklist == false ?
                        <Card style={{ backgroundColor: '#7036a4', boxShadow: '8px 8px 10px #d3d3d3', borderRadius: '20px' }}>
                            <CardBody>
                                <i className="far fa-times-circle" style={{ float: 'right', cursor: 'pointer' }} onClick={this.toggleVisibleChecklist} ></i>
                                <h6 style={{ color: 'white' }}>The Admin Ninja Checklist</h6><br />
                                {this.props.user.tutorials.indexOf("accounts") == -1 ?
                                    <i className="far fa-circle" style={{ cursor: 'pointer' }}></i> : <i className="far fa-check-circle"></i>}<Button className="checklistButton" onClick={this.highlightMenuAccounts} >Create Account</Button><br />
                                {this.props.user.tutorials.indexOf("accountsGroups") == 1 ?
                                    <i className="far fa-circle" style={{ cursor: 'pointer' }}></i> : <i className="far fa-check-circle"></i>}<Button className="checklistButton" onClick={this.highlightMenuAvatar}>Set Your Account Avatar</Button><br />
                                {this.props.user.tutorials.indexOf("organisationDetails") == -1 ?
                                    <i className="far fa-circle" style={{ cursor: 'pointer' }}></i> : <i className="far fa-check-circle"></i>}<Button className="checklistButton" onClick={this.highlightMenuOrganisationDetails}>Verify Organization Details</Button><br />
                                {this.props.user.tutorials.indexOf("licenses") == -1 ?
                                    <i className="far fa-circle" style={{ cursor: 'pointer' }}></i> : <i className="far fa-check-circle"></i>}<Button className="checklistButton" onClick={this.highlightMenuOrganisationLicenses}>Organization Licenses</Button><br />
                                {this.props.user.tutorials.indexOf("adminAccounts") == -1 ?
                                    <i className="far fa-circle" style={{ cursor: 'pointer' }}></i> : <i className="far fa-check-circle"></i>}<Button className="checklistButton" onClick={this.highlightMenuAdminAccounts}>Admin-enable an Account</Button><br />
                                {this.props.user.tutorials.indexOf("accountsGroups") == -1 ?
                                    <i className="far fa-circle" style={{ cursor: 'pointer' }}></i> : <i className="far fa-check-circle"></i>}<Button className="checklistButton" onClick={this.highlightMenuAccountGroups}>Setup an Account Group</Button><br /><br />
                            </CardBody>
                        </Card>
                        : <span />}<br />
                    {this.state.hideQuestions == false ?
                        <Card style={{ boxShadow: '8px 8px 10px #d3d3d3', borderRadius: '20px' }}>
                            <CardBody>
                                <i className="far fa-times-circle" style={{ float: 'right', cursor: 'pointer' }} onClick={this.toggleVisibleQuestions} ></i>
                                <h5 className="freqQuestions">Helpful Hints</h5><br />
                                <ul>
                                    <li><Link to={"/tutorialVideo/BookableEvent"} className="freqQuestions">Creating a bookable event</Link></li>
                                    <li><Link to={"/tutorialVideo/NewsItem"} className="freqQuestions">Sending a news item</Link></li>
                                    <li><Link to={`/tutorialVideo/QuizItem`} className="freqQuestions">Creating a quiz</Link></li>
                                    <li><Link to={"/tutorialVideo/Marketplace"} className="freqQuestions">Using the marketplace</Link></li>
                                    <li><Link to={"/tutorialVideo/KeyDate"} className="freqQuestions">Creating a key date</Link></li>
                                    <li><Link to={"/tutorialVideo/Fundraiser"} className="freqQuestions">Creating a fundraiser</Link></li>
                                    <li><Link to={"/tutorialVideo/CustomQuestion"} className="freqQuestions">Creating a custom question</Link></li>
                                    <li><Link to={"/tutorialVideo/EngagementAward"} className="freqQuestions">Creating an engagement award</Link></li>
                                </ul>
                                <h6>For more helpful tips check out<br /><Link to="/support" style={{ color: "7036a4" }}>Help and Support</Link></h6><br />
                            </CardBody>
                        </Card> : <span />}
                </div>
            </div>
        );
    }

    async componentDidMount() {
        let getTickets = firebase.functions().httpsCallable('getSupportTickets');
        let newTicketsResponse = await getTickets({ "schoolId": this.props.user.schoolId, "status": 2 });
        let newTickets = newTicketsResponse.data;

        let activeTickets: Ticket[] = [];
        newTickets['results'].forEach((nextTicketData: any) => {
            activeTickets.push(Ticket.fromFirebase(nextTicketData));
        });

        this.setState({
            tickets: activeTickets,
            hideChecklist: this.props.user.tutorials.indexOf("checklist") != -1,
            hideQuestions: this.props.user.tutorials.indexOf("questions") != -1
        });
    }

    avatarTutorial(): JSX.Element {
        let suggestedTutorial;
        if (this.props.user.avatarUrl == null || this.props.user.avatarUrl === "") {
            suggestedTutorial = <React.Fragment>
                <p><b><span className="material-icons material-icons-xd">person</span>&nbsp;&nbsp;Avatar</b></p>
                <p>
                    Set your avatar that is displayed on news items, events, and home learning<br /><br />
                    <span className={"toggle-icon"}><Button outline onClick={() => this.props.callback("myAccount")}>Show menu option</Button>
                        <br /><br /><Button className="showTutorialButton" onClick={this.handleShowTutorials}>Show Tutorials</Button></span>
                </p>
            </React.Fragment>
        } else {
            suggestedTutorial = <React.Fragment>
                <p><b><img src={'/images/zap-icon-circle.png'} height="30px" alt="" />&nbsp;&nbsp;Zap Maths</b></p>
                <p>Learn how to setup and use Zap Maths<br /><br />
                    <span className={"toggle-icon"}><Button className="altButton" onClick={() => this.props.callback("zapMathsTutorial")}>Coming soon</Button>
                        <br /><br /><Button className="showTutorialButton" onClick={this.handleShowTutorials}>Show Tutorials</Button></span>
                </p>
            </React.Fragment>
        }

        return <React.Fragment>
            <CardBody>
                <h5>Suggested tutorial</h5>
                {suggestedTutorial}
            </CardBody>
            </React.Fragment>
    }

    accountCount(): JSX.Element {
        return <React.Fragment>
            <CardBody>
                <h5><span className="material-icons material-icons-xd">people</span>&nbsp;&nbsp;Accounts</h5>
                <p>Total number of registered accounts on the network</p>
                <h5>{this.props.accountsMinimised.size}<br />
                    {this.props.user.permissions.includes('ACCOUNT_MANAGER') ||
                        this.props.user.permissions.includes('ADMIN_MANAGER') ?
                        <span className={"toggle-icon"}><Button className="altButton"><Link to="/accounts">Manage accounts</Link></Button></span>
                        : <span />
                    }
                </h5>
            </CardBody>
        </React.Fragment>
    }

    accountGroupCount(): JSX.Element {
        return <React.Fragment>
            <CardBody>
                <h5><span className="material-icons material-icons-xd">groups</span>&nbsp;&nbsp;Account groups</h5>
                <p>Total number of registered account groups on the network</p>
                <h5>{this.props.accountGroups.size}<br />
                    {this.props.user.permissions.includes('ACCOUNT_MANAGER') ?
                        <span className={"toggle-icon"}><Button className="altButton" onClick={() => this.props.callback("accountGroups")}><Link to="/accountGroups">Manage account groups</Link></Button></span>
                        : <span />
                    }
                </h5>
            </CardBody>
        </React.Fragment>
    }

    toggleVisibleChecklist(): void {
        const path = `schoolManagement/${this.props.user.schoolId}/admins/${this.props.user.accountId}/tutorials`;
        console.log(path);
        firebase.database().ref(path).update({ "checklist": true });
        this.props.user.tutorials.push("checklist");
        this.setState({
            hideChecklist: !this.state.hideChecklist
        })
    }

    toggleVisibleQuestions(): void {
        const path = `schoolManagement/${this.props.user.schoolId}/admins/${this.props.user.accountId}/tutorials`;
        console.log(path);
        firebase.database().ref(path).update({ "questions": true });
        this.props.user.tutorials.push("questions");
        this.setState({
            hideQuestions: !this.state.hideQuestions
        })
    }

    handleShowTutorials(): void {
        const path = `schoolManagement/${this.props.user.schoolId}/admins/${this.props.user.accountId}/tutorials`;
        console.log(path);
        firebase.database().ref(path).update({ "questions": null, "checklist": null });
        let index = this.props.user.tutorials.indexOf("questions");
        if (index > -1) {
            this.props.user.tutorials.splice(index, 1);
        }
        index = this.props.user.tutorials.indexOf("checklist");
        if (index > -1) {
            this.props.user.tutorials.splice(index, 1);
        }
        this.setState({
            hideQuestions: false,
            hideChecklist: false
        })
    }

    handleChecklistVerify(): void {
        this.setState({
            checked: true,
        })
    }

    highlightMenuAdminAccounts(): void {
        this.removeMenuStyling();
        var element = document.getElementById("accountsButton");
        element.classList.toggle("highlight");
        this.props.tutorialCallback("adminAccounts");
    }

    highlightMenuAccounts(): void {
        this.removeMenuStyling();
        var element = document.getElementById("accountsButton");
        element.classList.toggle("highlight");
        this.props.tutorialCallback("accounts");
    }

    highlightMenuOrganisationDetails(): void {
        this.removeMenuStyling();
        var element = document.getElementById("organisationDetailsButton");
        element.classList.toggle("highlight");
        this.props.tutorialCallback("organisationDetails");
    }

    highlightMenuOrganisationLicenses(): void {
        this.removeMenuStyling();
        var element = document.getElementById("licensesButton");
        element.classList.toggle("highlight");
        this.props.tutorialCallback("licenses");
    }

    highlightMenuAccountGroups(): void {
        this.removeMenuStyling();
        var element = document.getElementById("accountGroupsButton");
        element.classList.toggle("highlight");
        this.props.tutorialCallback("accountsGroups");
    }

    highlightMenuAvatar(): void {
        this.removeMenuStyling();
        this.props.callback("myAccount");
    }

    removeMenuStyling(): void {
        document.getElementById("accountsButton").classList.remove("highlight");
        document.getElementById("organisationDetailsButton").classList.remove("highlight");
        document.getElementById("licensesButton").classList.remove("highlight");
        document.getElementById("accountGroupsButton").classList.remove("highlight");
    }

}
export default SchoolNameView;
import React, { Component } from "react";
import { Button, Collapse, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap";
import { User } from "../data/user";
import { Link } from "react-router-dom";

export interface TableDetails {
    headers: Array<string>;
    data: Array<Array<any>>;
}

interface IProps {
    user: User;
    callback: (title: string) => void;
}

interface IState {
    eventsOpen: boolean;
    messagesOpen: boolean;
    slotsOpen: boolean;
    paymentsOpen: boolean;
}

export class MessageNinjaTutorialView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            eventsOpen: false,
            messagesOpen: false,
            slotsOpen: false,
            paymentsOpen: false,
        };
    }

    render(): JSX.Element {
        return <ListGroup flush>
            {
                this.props.user.permissions.includes('MESSAGE_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('messages') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ messagesOpen: !this.state.messagesOpen }); }}>Send a news item</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.messagesOpen}>
                            <ListGroupItemText>
                                <i>News items are sent via Message Ninja and allow you to share exciting or important news.</i><br /><br />
                     Why not send your first news item?<br /><br />
                                <Button outline block onClick={() => this.props.callback("messages")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
            {
                this.props.user.permissions.includes('EVENT_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('events') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ eventsOpen: !this.state.eventsOpen }); }}>Create an event</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.eventsOpen}>
                            <ListGroupItemText>
                                <i>Events allow users to see what is coming up, to confirm attendance, answer event questions, and make payments.</i><br /><br />
                     Why not create your first event?<br /><br />
                                <Button outline block onClick={() => this.props.callback("events")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
            {
                this.props.user.permissions.includes('EVENT_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('slots') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ slotsOpen: !this.state.slotsOpen }); }}>Create a bookable event</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.slotsOpen}>
                            <ListGroupItemText>
                                <i>Bookable events allow users to request bookings for an event. For example, a club, school production or parents' evening are bookable events.</i><br /><br />
                     Why not create a bookable event?
                     <Button outline block onClick={() => this.props.callback("slots")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
            {
                this.props.user.permissions.includes('ADMIN_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('paymentSettings') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ paymentsOpen: !this.state.paymentsOpen }); }}>Setup payment details</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.paymentsOpen}>
                            <ListGroupItemText>
                                <i>If you are planning on taking payments in Message Ninja then you will need to setup payment details.</i><br /><br />
                     Firstly you will need to <a target={"_blank"} href="https://squareup.com/us/en">setup a Square account</a>, and <a href="mailto:paymentsetup@lifeninja.net">contact Life Ninja</a> to
                     have a merchant id set up on the app.
                     Once completed, you can enter the relevant data in the payments form.<br /><br />
                                <Button outline block onClick={() => this.props.callback("paymentSettings")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
        </ListGroup>
    }
}

export default MessageNinjaTutorialView;
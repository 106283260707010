import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import {
    Table, Button, BreadcrumbItem, Breadcrumb, Alert, FormGroup, Label, Input,
    DropdownItem, DropdownToggle, ButtonDropdown, DropdownMenu, Card, CardBody
} from 'reactstrap';
import confirm from "reactstrap-confirm";
import queryString from 'query-string';
import { subjectIds } from './Prettifier';
import { LearningGame } from './data/learning_games';
import { User } from './data/user';
import { Subject } from './data/home_learning';

interface IState {
    minigames: Map<string, LearningGame>,
    minigameIdsSorted: string[],
    moreMinigames: boolean;
    dropDownOpen: boolean;
    subjectTags: Map<string, Subject>,
    subjectFilter: string;
    dropdownOpen: boolean;
}

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
}

class MinigamesView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            minigames: new Map(),
            minigameIdsSorted: [],
            moreMinigames: true,
            dropDownOpen: false,
            subjectTags: new Map(),
            subjectFilter: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
            dropdownOpen: false,
        };
        this.removeItem = this.removeItem.bind(this);
        this.getNextMinigamesBatch = this.getNextMinigamesBatch.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.makeShortString = this.makeShortString.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
    }

    render(): JSX.Element {

        let queryValues = this.getQuerys();

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All minigames</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Custom Minigames</div>
                        <p className="cardSubTitle">Minigames created within your organisation that can be added to live quizzes or learning paths</p>
                        <FormGroup>
                            <Label for="subjectFilter">Subject filter</Label>

                            <Input type="select" name="subjectFilter"
                                onChange={this.updateFilter}
                                value={this.state.subjectFilter}>
                                <option value={''}>All</option>
                                {Array.from(subjectIds.keys()).map((key) => {
                                    let name = subjectIds.get(key);
                                    return (
                                        <option value={key} key={key}>{name}</option>
                                    )
                                })
                                }
                            </Input>
                        </FormGroup>
                        <div className="bottom-buffer">
                            <ButtonDropdown className="adminPagesButton" isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                <DropdownToggle caret className="adminPagesButton" >
                                    Create new game
                        </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem>
                                        <Link to={`/learningQuizlets/-1?${queryValues}`}>
                                            New quizlet
                                </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={`/learningChoiceGame/-1?${queryValues}`}>
                                            New Choice Game
                                </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={`/learningSplitGame/-1?${queryValues}`}>
                                            New Split Game
                                </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={`/learningGrids/-1?${queryValues}`}>
                                            New number grid
                                </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={`/learningCircles/-1?${queryValues}`}>
                                            New number circle
                                </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={`/learningShoots/-1?${queryValues}`}>
                                            New number shoot
                                </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={`/learningSlices/-1?${queryValues}`}>
                                            New number slice
                                </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={`/learningMultiplicationSpeedTest/-1?${queryValues}`}>
                                            New Multiplication Speed test
                                </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={`/learningBossLevel/-1?${queryValues}`}>
                                            New Boss Level
                                    </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to={`/learningTrace/-1?${queryValues}`}>
                                            New letter trace game
                                    </Link>
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>&nbsp;
                </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Subject</th>
                                    <th>Topic</th>
                                    <th>Tags</th>
                                    <th>Created/Updated</th>
                                    <th>Type</th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.minigameIdsSorted.map((minigameId) => {
                                    let minigame = this.state.minigames.get(minigameId);
                                    if (minigame == null) {
                                        return "";
                                    }

                                    let topicName = "";
                                    let tagsString = "";
                                    let topicId = minigame.topics[0];
                                    if (topicId != null) {
                                        let topic = this.state.subjectTags.get(minigame.subjectId).topics.get(topicId);
                                        if (topic != null) {
                                            topicName = topic.name;
                                            if (minigame.topicTags.has(topicId)) {
                                                for (let nextTagId of minigame.topicTags.get(topicId)) {
                                                    if (tagsString !== "") {
                                                        tagsString += ", ";
                                                    }
                                                    tagsString += `${topic.tags.get(nextTagId)}`;
                                                }
                                            }
                                        }
                                    }

                                    let url = "learningQuizlets";
                                    let typeName = "Quizlet";
                                    if (minigame.type === "QUIZLET") {
                                        url = "learningQuizlets";
                                        typeName = "Quizlet";
                                    } else if (minigame.type === "GRID") {
                                        url = "learningGrids";
                                        typeName = "Number grid";
                                    } else if (minigame.type === "CIRCLE") {
                                        url = "learningCircles";
                                        typeName = "Number circle";
                                    } else if (minigame.type === "SLICE") {
                                        url = "learningSlices";
                                        typeName = "Number slice";
                                    } else if (minigame.type === "SHOOT") {
                                        url = "learningShoots";
                                        typeName = "Number shoot";
                                    } else if (minigame.type === "MULSPEEDTEST") {
                                        url = "learningMultiplicationSpeedTest";
                                        typeName = "Multiplication speed test";
                                    } else if (minigame.type === "BOSSLEVEL") {
                                        url = "learningBossLevel";
                                        typeName = "Boss Level";
                                    } else if (minigame.type === "CHOICE") {
                                        url = "learningChoiceGame";
                                        typeName = "Choice Game";
                                    } else if (minigame.type === "SPLIT") {
                                        url = "learningSplitGame";
                                        typeName = "Split Game";
                                    } else if (minigame.type === "TRACE") {
                                        url = "learningTrace";
                                        typeName = "Trace Game";
                                    }

                                    return <tr>
                                        <th scope="row"><Link to={`${url}/${minigameId}?${queryValues}`}>{minigame.title == null ? minigameId : minigame.title}</Link></th>
                                        <td>{this.makeShortString(minigame.instructions)}</td>
                                        <td>{subjectIds.get(minigame.subjectId)}</td>
                                        <td>{topicName}</td>
                                        <td>{tagsString}</td>
                                        <td>{minigame.updated == null ? "" : minigame.updated.toDate().toDateString()}</td>
                                        <td>{typeName}</td>
                                        <td><Button color="link" onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this minigame", confirmText: "Confirm" });
                                            if (result) {
                                                this.removeItem(minigameId)
                                            }
                                        }}><i className="material-icons">delete</i></Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        <p>Showing {this.state.minigameIdsSorted.length}</p>
                        {this.state.moreMinigames ?
                            <div>
                                <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.getNextMinigamesBatch(false, this.state.subjectFilter);
                                    }}>
                                    Show more
                        </Button>
                            </div> : <span />
                        }
                        <br /><br /><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    toggleDropdown(): void {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }

    async updateFilter(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let newSubjectFilter = e.target.value;
        await this.getNextMinigamesBatch(true, newSubjectFilter);
        this.setState({
            subjectFilter: newSubjectFilter
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
        });
    }

    async removeItem(minigameId: string): Promise<void> {
        try {
            await firebase.firestore().doc(`learningGames/${minigameId}`).update({ deleted: true });

            this.state.minigames.delete(minigameId);

            this.setState({});
            this.props.snackbar("Deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }

    makeShortString(description: string): string {
        if (description == null) {
            return "";
        }
        if (description.length < 30) {
            return description;
        }
        let descriptionResult = description.substr(0, 30);
        let spacePos = descriptionResult.lastIndexOf(" ");
        if (spacePos !== -1) {
            descriptionResult = descriptionResult.substr(0, spacePos);
        }
        descriptionResult += "...";

        return descriptionResult;
    }

    componentDidMount(): void {
        this.getNextMinigamesBatch(true, this.state.subjectFilter);
    }

    async getNextMinigamesBatch(seed: boolean, subjectFilter: string): Promise<void> {
        if (seed != null && seed) {
            this.setState({
                minigames: new Map(),
                minigameIdsSorted: [],
                moreMinigames: true,
            });
        }
        let minigamesRef = firebase.firestore().collection(`learningGames`).where("deleted", "==", null).where("schoolId", "==", this.props.user.schoolId);

        if (subjectFilter !== "") {
            minigamesRef = minigamesRef.where("subjectId", "==", subjectFilter);
        }
        minigamesRef = minigamesRef.orderBy("updated", "desc")
        if (seed == null || !seed) {
            // Find last minigame
            let lastId = this.state.minigameIdsSorted[this.state.minigameIdsSorted.length - 1];
            if (lastId != null) {
                minigamesRef = minigamesRef.startAt(this.state.minigames.get(lastId).updated);
            }
            minigamesRef = minigamesRef.limit(11); // Because of duplicate first entry
        } else {
            minigamesRef = minigamesRef.limit(10);
        }

        let collection = await minigamesRef.get();
        let newMoreMinigames = this.state.moreMinigames;
        let minigameDocs = collection.docs;

        let newMinigames = new Map<string, LearningGame>();
        let newMinigameIdsOrdered = new Array<string>();
        if (minigameDocs != null) {
            for (let nextMinigameDoc of minigameDocs) {
                let nextMinigame = nextMinigameDoc.data();
                if (!this.state.minigames.has(nextMinigameDoc.id)) {
                    newMinigameIdsOrdered.push(nextMinigameDoc.id);
                    newMinigames.set(nextMinigameDoc.id, LearningGame.fromFirebase(nextMinigame));
                    if (!this.state.subjectTags.has(nextMinigame.subjectId)) {
                        let subjectTagDoc = await firebase.firestore().doc(`learningTopics/${nextMinigame.subjectId}`).get();
                        this.state.subjectTags.set(nextMinigame.subjectId, Subject.fromFirebase(subjectTagDoc.data()));
                    }
                }
            }
        }

        if (newMinigames.size < 10) {
            newMoreMinigames = false;
        }
        let allMinigames = new Map([...this.state.minigames, ...newMinigames]);

        let minigameIdsSorting = [...this.state.minigameIdsSorted, ...newMinigameIdsOrdered];
        this.setState({
            minigameIdsSorted: minigameIdsSorting,
            minigames: allMinigames,
            moreMinigames: newMoreMinigames,
        });
    }
}

export default MinigamesView;
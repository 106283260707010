import React, { Component } from "react";
import { Button, Collapse, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap";
import { User } from "../data/user";
import { Link } from "react-router-dom";

export interface TableDetails {
    headers: Array<string>;
    data: Array<Array<any>>;
}

interface IProps {
    user: User;
    callback: (title: string) => void;
}

interface IState {
    adminOpen: boolean;
    licensesOpen: boolean;
    accountsOpen: boolean;
    accountGroupsOpen: boolean;
    orgDetailsOpen: boolean;
    myAccountOpen: boolean;
}

export class AdministratorTutorialView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            adminOpen: false,
            licensesOpen: false,
            accountsOpen: false,
            accountGroupsOpen: false,
            orgDetailsOpen: false,
            myAccountOpen: false,
        };
    }

    render(): JSX.Element {
        return <ListGroup flush>
            <ListGroupItem>
                <ListGroupItemHeading>{this.props.user.tutorials.includes('myAccount') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ myAccountOpen: !this.state.myAccountOpen }); }}>Set your account avatar</Link></ListGroupItemHeading>
                <Collapse isOpen={this.state.myAccountOpen}>
                    <ListGroupItemText>
                        <i>Your avatar is used throughout the apps.</i><br /><br />
                                Upload your avatar<br /><br />
                        <Button outline block onClick={() => this.props.callback("myAccount")}>Show menu option</Button>
                    </ListGroupItemText>
                </Collapse>
            </ListGroupItem>
            {
                this.props.user.permissions.includes('ADMIN_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('organisationDetails') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ orgDetailsOpen: !this.state.orgDetailsOpen }); }}>Verify organisation details</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.orgDetailsOpen}>
                            <ListGroupItemText>
                                <i>Life Ninja apps read the name and type of your organisation from these admin pages.</i><br /><br />
                                        Why not verify that your details are correct in the organisation details form?<br /><br />
                                <Button outline block onClick={() => this.props.callback("organisationDetails")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
            {
                this.props.user.permissions.includes('ADMIN_MANAGER') ||
                    this.props.user.permissions.includes('ACCOUNT_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('adminAccounts') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ licensesOpen: !this.state.licensesOpen }); }}>Organisation licenses</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.licensesOpen}>
                            <ListGroupItemText>
                                <i>Licenses manage the functionality of your organisation</i><br />
                                            Some licenses are restricted to a certain number of accounts. Managing the accounts assigned to each license is done within the licenses screen.<br /><br />
                                        Why not view the licenses and decide the functionality your organisation wants from Life Ninja?<br /><br />
                                <Button outline block onClick={() => this.props.callback("licenses")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
            {
                this.props.user.permissions.includes('ACCOUNT_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('accounts') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ accountsOpen: !this.state.accountsOpen }); }}>Setup an account</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.accountsOpen}>
                            <ListGroupItemText>
                                <i>Accounts are linked to email addresses and allow access to Life Ninja apps.</i><br /><br />
                                        Why not view the accounts and perhaps setup your first account?<br /><br />
                                <Button outline block onClick={() => this.props.callback("accounts")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
            {
                this.props.user.permissions.includes('ADMIN_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('licenses') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ adminOpen: !this.state.adminOpen }); }}>Admin-enable an account</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.adminOpen}>
                            <ListGroupItemText>
                                <i>Accounts can be administrator-enabled so that the user can access these admin screens.</i><br /><br />
                                        Why not view the admin-enabled users?<br /><br />
                                <Button outline block onClick={() => this.props.callback("adminAccounts")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
            {
                this.props.user.permissions.includes('ACCOUNT_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('accountGroups') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ accountGroupsOpen: !this.state.accountGroupsOpen }); }}>Setup an account group</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.accountGroupsOpen}>
                            <ListGroupItemText>
                                <i>Accounts groups are used to assign learning modules, send messages/events, and run reports.</i><br /><br />
                                        Why not view the existing account groups and perhaps setup a group for the account you created?<br /><br />
                                <Button outline block onClick={() => this.props.callback("accountGroups")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
        </ListGroup>
    }
}

export default AdministratorTutorialView;
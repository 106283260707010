import moment from "moment";
import React, {Component} from "react";
import { RouteComponentProps } from 'react-router';

interface IState {}

interface IProps extends RouteComponentProps {
    schedule: moment.Moment;
}

class CountdownTimer extends Component<IProps, IState> {
    timer!: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);
        this.cancelTimer = this.cancelTimer.bind(this);
    }

    render(): JSX.Element {
        console.log("Quiz refresh timer render");

        let now = moment();
        if (now.isAfter(this.props.schedule)) {
            this.cancelTimer();
            return <br/>;
        }
        let diff = this.props.schedule.diff(now, 'seconds');
        let days = parseInt(moment().startOf('year').add(diff, 'seconds').format("DDD")) - 1;
        let countdown = moment().startOf('year').add(diff, 'seconds').format("HH:mm:ss");

        countdown = (days <= 9 ? `0${days}` : days) + `:${countdown}`;

        return <div>{countdown}</div>;
    }

    componentDidMount(): void {
        this.timer = setInterval(() => {
            this.setState({});
        }, 1000);
    }

    cancelTimer(): void {
        if (this.timer != null) {
            clearInterval(this.timer);
        }
    }

    componentWillUnmount(): void {
        this.cancelTimer();
    }
}

export default CountdownTimer;
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';

var config = {
    apiKey: "AIzaSyDf_AItCB_z_ua6w8sJhNVXdaR9vrAjopU",
    authDomain: "admin.lifeninja.net",
    databaseURL: "https://gcse-english-lit.firebaseio.com",
    projectId: "gcse-english-lit",
    storageBucket: "gcse-english-lit.appspot.com",
    messagingSenderId: "509523871673"
};
firebase.initializeApp(config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase;
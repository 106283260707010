import React, { Component } from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    Alert,
    Row,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
    Button,
    Card,
    Collapse,
    Spinner,
    NavbarText,
} from 'reactstrap';
import { auth } from './firebase';
import { BrowserRouter as Router, NavLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import LearningLicensesView from './learning_licenses_view';
import LearningWorldView from './learning_world_view';
import LearningPathView from './learning_path_view';
import LearningQuizletView from './learning_quizlet_view';
import LearningQuestionView from './learning_question_view';
import AccountsView from './accounts_view';
import AccountView from './account_view';
import AccountGroupsView from './accountGroups_view';
import AccountGroupView from './accountGroup_view';
import ReportingView from './reporting_view';
import firebase from "./firebase";
import { Link } from "react-router-dom";
import MessagesView from "./messages_view";
import MessageView from "./message_view";
import EventView from "./event_view";
import EventsView from "./events_view";
import EventsCalendarView from "./events_calendar_view";
// import ModuleBadgeView from "./badge_view";
import AdminAccountsView from "./admin_accounts_view";
import AdminAccountView from "./admin_account_view";
import PaymentSelectionView from "./payment_selection_view";
import StripePaymentsView from "./stripe_payments_view";
import SquarePaymentsView from "./square_payments_view";
import SchoolNameView from "./school_name_view";
import QuizzesView from "./quizzes_view";
import QuizTemplateView from "./quiz_template_view";
import QuizTemplatesView from "./quiz_templates_view";
// import DevicesView from "./devices_view";
// import DeviceView from "./device_view";
import MyAccountView from "./my_account_view";
// import StudySectionView from "./study_section_view";
import OrganisationView from "./organisation_view";
import CreateOrganisationView from "./create_organisation_view";
import AdventureView from "./adventure_view";
import AdventureSectionView from "./adventure_section_view";
import AdventuresView from "./adventures_view";
import InformationSectionView from "./information_section_view";
import AccountCsvView from "./accounts_csv_view";
import AccountGroupEmailView from "./account_group_email_view";
import ChatsView from './chats_view';
import ChatTranscriptView from './chat_transcript_view';
import ModeratorChatsView from './moderator_chats_view';
import ModeratorChatTranscriptView from './moderator_chat_transcript_view';
import LicensesView from './licenses_view';
import LicenseView from './license_view';
// import PayLicensesView from './pay_licenses_view';
// import ModuleCsvView from './module_csv_view';
import OrganisationSelectionView from './organisation_selection_view';
import ImageLibraryView from './image_library_view';
import SlotGroupsView from './slot_groups_view';
import SlotGroupView from './slot_group_view';
import PaymentAuthResponseView from './payment_auth_response_view';
import FundraisersView from './fundraisers_view';
import FundraiserView from './fundraiser_view';
import PaymentHistoryView from "./payment_history";
import AllReadingRecordsView from "./all_reading_records_view";
import ReadingRecordsView from "./reading_records_view";
import LearningGridView from "./learning_grid_view";
import LearningCircleView from "./learning_circle_view";
import LearningSliceView from "./learning_slice_view";
import LearningShootView from "./learning_shoot_view";
import AutomatedQuizView from "./automated_quiz_view";
import HomeworksView from "./homeworks_view";
import HomeworkView from "./homework_view";
import HomeworkStudentAnalysis from "./homework_student_analysis";
import HomeworkGameAnalysis from "./homework_game_analysis";
import HomeworkActivityView from "./homework_activity_basic_view";
import HomeworkActivityWorksheetView from "./homework_activity_worksheet_view";
import HomeworkActivityLearningPathView from "./homework_activity_learning_path_view";
import HomeworkActivityAdventureView from "./homework_activity_adventure_view";
import LoginRegisterView from "./login_register";
import { sendVerificationEmail } from "./helpers/email_services";
import LearningMultiplicationSpeedTest from './learning_multiplicationspeedtest';
import LearningChoiceGame from './learning_choice_view';
import LearningSplitGame from './learning_split_view';
import LearningBossLevelView from './learning_bosslevel';
import MinigamesView from './minigames_view';
import QuestionsView from './questions_view';
import TicketsView from './tickets_view';
import TicketView from './ticket_view';
import WorksheetView from './worksheet_view';
import AccountVerificationView from './account_verification_view';
import { permissions } from './Prettifier';
import { User, UserAccount, USER_ROLE } from './data/user';
import { Account, AccountGroup, AccountMinimised } from './data/accounts';
import { OrganisationDetails } from './data/organisation';
import { objectToMapConverter } from './data/database_object';
import ReadingRecordsReportView from './reports/reading_records_report_view';
import ObjectivesReportView from './reports/objectives_report_view';
import StudentObjectivesReportView from './reports/student_objectives_report_view';
import TopicTagReportView from './reports/topic_tag_report_view';
import ZapReportsOverview from './reports/zap_reports_overview';
import LearningNinjaReportsOverview from './reports/learning_ninja_reports_overview';
import AppDownloadsReportView from './reports/app_downloads_report_view';
import LearningPathAnalysisReportView from './reports/learning_path_analysis_report_view';
import AdventurePathView from './adventure_path_view';
import KeyDatesView from './key_dates_view';
import AwardsView from './awards/awards_view';
import AwardView from './awards/award_view';
import ProductView from './marketplace/product_view';
import ProductsView from './marketplace/products_view';
import ProductCategoriesView from './marketplace/product_categories';
import { Purchase } from './data/marketplace';
import PurchasesView from './marketplace/purchases_view';
import ZapMathsTutorialView from './tutorials/zap_maths_tutorial_view';
import LearningNinjaTutorialView from './tutorials/learning_ninja_tutorial_view';
import MessageNinjaTutorialView from './tutorials/message_ninja_tutorial_view';
import AdministratorTutorialView from './tutorials/administrator_tutorial_view';
import ScrollToTop from './helpers/scroll_to_top';
import TutorialVideoView from './tutorial_video_view';
import { ChoiceBucket } from './data/learning_games';
import { LicenseActivity } from './data/license';
import LearningTraceGame from './learning_trace_view';

const { detect } = require('detect-browser');
const browser = detect();

const VERSION = 39;

interface IState {
    currentItem: string;
    username: string;
    items: [],
    user: User;
    accountsMinimised: Map<string, AccountMinimised>,
    accountGroups: Map<string, AccountGroup>,
    licenses: Map<string, boolean>,
    enabledLicenseDetails: Map<string, LicenseActivity>,
    fadeIn: boolean;
    snackbarText: string;
    messageDropDownOpen: boolean;
    studentDropDownOpen: boolean;
    adminDropDownOpen: boolean;
    learningDropDownOpen: boolean;
    tapMathsDropDownOpen: boolean;
    accountDropDownOpen: boolean;
    loginEmailAddress: string;
    loginPassword: string;
    authUserChecked: boolean;
    highlightMenuItem: string;
    currentVersion: string;
    reloadRequired: boolean;
    reloading: boolean;
    userTypeSelected: string;
    loginTypeSelected: string;
    tosConfirmed: boolean;
    verificationEmail: boolean;
    schoolList: Map<string, OrganisationDetails>,
    emailVerification: boolean;
    initialisingAccount: boolean;
    collapse: boolean;
    visible: boolean,
    appDropDownOpen: boolean,
    reportingDropDownOpen: boolean,
    zapCollapse: boolean,
    messageCollapse: boolean,
    learningCollapse: boolean,
    usersCollapse: boolean,
    helpCollapse: boolean,
    accountsBubble: boolean,
    adminAccountsBubble: boolean,
    accountsGroupsBubble: boolean,
    organisationDetailsBubble: boolean,
    paymentProviderBubble: boolean,
    paymentsReceivedBubble: boolean,
    licensesBubble: boolean,
    correctBucket: Map<string, ChoiceBucket>,
}

interface IProps {
}

class RootView extends Component<IProps, IState> {

    firebaseUser: firebase.User;
    currentSnackbarTimeout: NodeJS.Timeout;
    accountsMinimisedChangedListener: firebase.database.Query;
    accountsMinimisedRemovedListener: firebase.database.Query;
    accountsMinimisedAddedListener: firebase.database.Query;
    accountGroupsListener: firebase.database.Query;
    licensesListener: () => void;
    schoolRef: firebase.database.Reference;
    userRef: firebase.database.Reference;
    adminRef: firebase.database.Reference;
    accountsMinimisedListener: firebase.database.Reference;

    constructor(props: IProps) {
        super(props);
        this.state = {
            currentItem: '',
            username: '',
            items: [],
            user: null,
            accountsMinimised: new Map(),
            accountGroups: new Map(),
            licenses: new Map(),
            enabledLicenseDetails: new Map(),
            fadeIn: false,
            snackbarText: null,
            messageDropDownOpen: false,
            studentDropDownOpen: false,
            adminDropDownOpen: false,
            learningDropDownOpen: false,
            tapMathsDropDownOpen: false,
            accountDropDownOpen: false,
            loginEmailAddress: '',
            loginPassword: '',
            authUserChecked: false,
            highlightMenuItem: null,
            currentVersion: null,
            reloadRequired: false,
            reloading: false,
            userTypeSelected: null,
            loginTypeSelected: null,
            tosConfirmed: false,
            verificationEmail: false,
            schoolList: new Map(),
            emailVerification: false,
            initialisingAccount: false,
            collapse: true,
            visible: true,
            appDropDownOpen: false,
            reportingDropDownOpen: false,
            zapCollapse: true,
            messageCollapse: true,
            learningCollapse: true,
            usersCollapse: true,
            helpCollapse: true,
            accountsBubble: false,
            adminAccountsBubble: false,
            accountsGroupsBubble: false,
            organisationDetailsBubble: false,
            paymentProviderBubble: false,
            paymentsReceivedBubble: false,
            licensesBubble: false,
            correctBucket: new Map(),
        };

        this.showSnackbar = this.showSnackbar.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.toggleStudents = this.toggleStudents.bind(this);
        this.toggleAdmins = this.toggleAdmins.bind(this);
        this.toggleLearning = this.toggleLearning.bind(this);
        this.toggleAccount = this.toggleAccount.bind(this);
        this.highlightMenu = this.highlightMenu.bind(this);
        this.navAction = this.navAction.bind(this);
        this.logout = this.logout.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.waitOnReloadRequest = this.waitOnReloadRequest.bind(this);
        this.handleSchoolSelect = this.handleSchoolSelect.bind(this);
        this.handleSwitchSchool = this.handleSwitchSchool.bind(this);
        this.listenOnAccountChange = this.listenOnAccountChange.bind(this);
        this.setupAccountsListeners = this.setupAccountsListeners.bind(this);
        this.navClear = this.navClear.bind(this);
        this.toggleTapMaths = this.toggleTapMaths.bind(this);
        this.handleSchoolSelectAdmin = this.handleSchoolSelectAdmin.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleApp = this.toggleApp.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
        this.toggleReporting = this.toggleReporting.bind(this);
        this.zapToggle = this.zapToggle.bind(this);
        this.messageToggle = this.messageToggle.bind(this);
        this.learningToggle = this.learningToggle.bind(this);
        this.usersToggle = this.usersToggle.bind(this);
        this.helpToggle = this.helpToggle.bind(this);
        this.toggleVisibleLicensesBubble = this.toggleVisibleLicensesBubble.bind(this);
        this.toggleVisiblePaymentsReceivedBubble = this.toggleVisiblePaymentsReceivedBubble.bind(this);
        this.toggleVisiblePaymentProviderBubble = this.toggleVisiblePaymentProviderBubble.bind(this);
        this.toggleVisibleOrganisationDetailsBubble = this.toggleVisibleOrganisationDetailsBubble.bind(this);
        this.toggleVisibleAccountGroupsBubble = this.toggleVisibleAccountGroupsBubble.bind(this);
        this.toggleVisibleAccountsBubble = this.toggleVisibleAccountsBubble.bind(this);
        this.toggleVisibleAdminAccountsBubble = this.toggleVisibleAdminAccountsBubble.bind(this);
    }

    learningToggle(): void {
        this.setState({
            learningCollapse: !this.state.learningCollapse
        });
    }

    usersToggle(): void {
        this.setState({
            usersCollapse: !this.state.usersCollapse
        });
    }

    zapToggle(): void {
        this.setState({
            zapCollapse: !this.state.zapCollapse
        });
    }

    messageToggle(): void {
        this.setState({
            messageCollapse: !this.state.messageCollapse
        });
    }

    helpToggle(): void {
        this.setState({
            helpCollapse: !this.state.helpCollapse
        });
    }

    toggleApp(): void {
        this.setState({
            appDropDownOpen: !this.state.appDropDownOpen
        });
    }

    toggleNav(): void {
        this.setState({
            visible: !this.state.visible
        });
    }

    toggleReporting(): void {
        this.setState({
            reportingDropDownOpen: !this.state.reportingDropDownOpen
        });
    }

    toggleMessage(): void {
        this.setState({
            messageDropDownOpen: !this.state.messageDropDownOpen
        });
    }

    toggleTapMaths(): void {
        this.setState({
            tapMathsDropDownOpen: !this.state.tapMathsDropDownOpen
        });
    }

    toggleStudents(): void {
        this.setState({
            studentDropDownOpen: !this.state.studentDropDownOpen
        });
    }

    toggleLearning(): void {
        this.setState({
            learningDropDownOpen: !this.state.learningDropDownOpen
        });
    }

    toggleAdmins(): void {
        this.setState({
            adminDropDownOpen: !this.state.adminDropDownOpen
        });
    }

    toggleAccount(): void {
        this.setState({
            accountDropDownOpen: !this.state.accountDropDownOpen
        });
    }

    toggleVisibleAccountsBubble(): void {
        this.setState({
            accountsBubble: !this.state.accountsBubble
        });
    }

    toggleVisibleAdminAccountsBubble(): void {
        this.setState({
            adminAccountsBubble: !this.state.adminAccountsBubble
        });
    }

    toggleVisibleAccountGroupsBubble(): void {
        this.setState({
            accountsGroupsBubble: !this.state.accountsGroupsBubble
        });
    }

    toggleVisibleOrganisationDetailsBubble(): void {
        this.setState({
            organisationDetailsBubble: !this.state.organisationDetailsBubble
        });
    }

    toggleVisiblePaymentProviderBubble(): void {
        this.setState({
            paymentProviderBubble: !this.state.paymentProviderBubble
        });
    }

    toggleVisiblePaymentsReceivedBubble(): void {
        this.setState({
            paymentsReceivedBubble: !this.state.paymentsReceivedBubble
        });
    }

    toggleVisibleLicensesBubble(): void {
        this.setState({
            licensesBubble: !this.state.licensesBubble
        });
    }

    render(): JSX.Element {
        let browserSupported = true;
        switch (browser && browser.name) {
            case 'chrome':
            case 'firefox':
            case 'edge':
            case 'safari':
                break;
            case 'ie':
                browserSupported = false;
                break;

            default:
                console.log('Assume supported', browser.name);
        }
        if (!browserSupported) {
            return <div>Unfortunately your browser ({browser.name === "ie" ? "Internet Explorer" : browser.name}) is not supported for the Life Ninja web dashboard.<br />
                Supported web browsers are:
                <ul>
                    <li>Chrome</li>
                    <li>Firefox</li>
                    <li>Edge</li>
                    <li>Safari</li>
                </ul>
            </div>
        }
        return (
            <Router>
                <ScrollToTop />
                <div style={{ height: "100%" }}>
                    <Navbar className="custNav navbar-inverse py-0" style={{ backgroundColor: '#7036a4' }} expand="sm">
                        <NavbarBrand className="custNav" tag={Link} to="/">
                            {this.state.user == null || this.state.user.schoolAvatarUrl == null || this.state.user.schoolAvatarUrl.length === 0 ?
                                <img className="navLogo" src={'/images/LNN.png'} width="60" height="60" style={{margin:"5px"}} alt="" />
                                : <img className="d-inline-block align-top rounded" src={this.state.user.schoolAvatarUrl} width="50" height="50" alt="" />
                            }
                        </NavbarBrand>

                        {this.state.user && this.state.user.permissions != null && this.state.user.accountId != null ?
                            <React.Fragment>
                                <Nav className="ml-auto">
                                    <NavbarText style={{ color: '#FFFFFF', marginRight: "20px" }}>Welcome back, {this.state.user.personalName}</NavbarText>
                                    <Dropdown nav isOpen={this.state.accountDropDownOpen} toggle={this.toggleAccount}>
                                        <DropdownToggle nav caret style={{ color: '#FFFFFF', padding: "0px" }}>
                                            {this.state.user.avatarUrl == null || this.state.user.avatarUrl === "" ?
                                                <span className="avatarCircle"><i className="fas fa-user-ninja"></i></span>
                                                :
                                                <img src={this.state.user.avatarUrl} alt="" className="avatarCircle" />
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu right={true}>
                                            <DropdownItem tag={Link} id="myAccountButton" to="/myAccount" onClick={() => this.navAction('myAccount')}
                                                active={this.state.highlightMenuItem === 'myAccount'}>
                                                My account
                                        </DropdownItem>
                                            <DropdownItem tag={Link} to="/" onClick={this.handleSwitchSchool}>
                                                Switch organisation
                                        </DropdownItem>
                                            {this.state.user.role === USER_ROLE.admin ?
                                                <DropdownItem tag={Link} to="/createOrganisation">
                                                    Create organisation
                                            </DropdownItem> : ""
                                            }
                                            <DropdownItem tag={Link} to="/" onClick={() => {
                                                this.logout(this.state.user);
                                            }}>
                                                Log Out
                                        </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </Nav>
                            </React.Fragment> : this.state.user != null ?
                                <Nav className="ml-auto" navbar>
                                    <NavItem tag={Button} className="adminPagesButton" to="/" onClick={() => {
                                        this.logout(this.state.user);
                                    }}>
                                        Log Out
                                </NavItem>
                                </Nav> : ""
                        }
                    </Navbar>
                    <table width="100%" style={{ borderStyle: "none", height: "100%" }}>
                        <tr>
                            {this.state.user == null || this.state.user.switchSchool || this.state.user.accountId == null ? <span /> :
                                <td valign="top" style={{ padding: "0", height: "100%", width: this.state.visible ? "210px" : "60px", minWidth: this.state.visible ? "210px" : "60px" }}>
                                    {this.state.user && this.state.user.permissions != null && this.state.user.accountId != null ?
                                        <div id="mySidebar" className="sidebar" style={{ width: this.state.visible ? "250px" : "50px", boxShadow: '2px 1px 10px #d3d3d3' }}>
                                            <div style={{ verticalAlign: "top", textAlign: "right", position: "relative" }}>
                                                {!this.state.visible ?
                                                    <i className="fas fa-arrow-alt-circle-right" style={{ fontSize: '20px', cursor: 'pointer', right: "-10px", top: "5px", color: "#7036a4", position: "absolute" }} onClick={this.toggleNav} ></i>
                                                    : <i className="fas fa-arrow-alt-circle-left" style={{ fontSize: '20px', cursor: 'pointer', right: "-10px", top: "5px", color: "#7036a4", position: "absolute" }} onClick={this.toggleNav}></i>}
                                            </div>
                                            <br />
                                            <Button className="menuTitle" color="link">
                                                <NavLink to="/" exact activeClassName="activeMenuOption" style={{ color: "#6f778f" }}><span className="menuIcon"><i className="fas fa-home" style={{ opacity: "0.7" }} ></i></span>{this.state.visible ? "Dashboard" : ""}</NavLink><br />
                                            </Button>

                                            {this.state.licenses.has('messaging') && this.state.licenses.get('messaging') &&
                                                (this.state.user.permissions.includes('MESSAGE_MANAGER') || this.state.user.permissions.includes('EVENT_MANAGER')) ?
                                                this.state.visible ? <div><Button color="link" className="menuTitle" onClick={this.messageToggle}><span className="menuIcon"><img src="../images/mn-1b.ico" width="15px" className="MessageIcon" /></span>Message Ninja<span className="menuToggle">{this.state.messageCollapse ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}</span></Button><br />
                                                    <Collapse isOpen={!this.state.messageCollapse}>
                                                        {this.state.user.permissions.includes('CHAT_MANAGER') ?
                                                            <div><NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/chats">Chats</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('MESSAGE_MANAGER') ?
                                                            <div><NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/messages">News Items</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('MESSAGE_MANAGER') || this.state.user.permissions.includes('EVENT_MANAGER') ?
                                                            <div><NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/engagementAwards">Engagement awards</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('EVENT_MANAGER') ?
                                                            <div><NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/eventsCalendar">Calendar</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('EVENT_MANAGER') ?
                                                            <div><NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/events">Events</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('EVENT_MANAGER') ?
                                                            <div><NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/slotGroups">Bookable Events</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('EVENT_MANAGER') ?
                                                            <React.Fragment>
                                                                <div>
                                                                    <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/keyDates">Key dates</NavLink>
                                                                    <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/purchases">Purchases</NavLink>
                                                                    <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/products">Products</NavLink>
                                                                    <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/fundraisers">Fundraisers</NavLink>
                                                                </div></React.Fragment> : null
                                                        }
                                                    </Collapse></div> : <Button color="link" className="menuTitle" onClick={this.messageToggle}><span className="menuIcon"><i className="fab fa-facebook-messenger"></i></span></Button>
                                                : null
                                            }

                                            {this.state.licenses.has('learningNinja') != null && this.state.licenses.get('learningNinja') &&
                                                (this.state.user.permissions.includes('MODULE_MANAGER') || this.state.user.permissions.includes('REPORTING_MANAGER')) ?
                                                this.state.visible ? <div><Button color="link" className="menuTitle" onClick={this.learningToggle}><span className="menuIcon"><img src="../images/ln.ico" width="15px" className="LearningIcon" /></span>Learning Ninja<span className="menuToggle">{this.state.learningCollapse ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}</span></Button><br />
                                                    <Collapse isOpen={!this.state.learningCollapse}>
                                                        {this.state.user.permissions.includes('MODULE_MANAGER') ?
                                                            <div>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/homeworks">Home Learning</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/learningWorlds">Learning Worlds</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/adventures">Adventures</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/quizTemplates">Quiz Templates</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/minigames">Custom Minigames</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/questions">Custom Questions</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/quizzes">Live Quizzes</NavLink>
                                                            </div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('REPORTING_MANAGER') ?
                                                            <div>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/reporting/learningNinjaReporting">Reporting</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/readingRecords"> Reading Records</NavLink>
                                                            </div> : null
                                                        }
                                                        {this.state.user.permissions.includes('MODULE_MANAGER') ?
                                                            <div>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/imageLibrary">Image Library</NavLink>
                                                            </div> : null
                                                        }
                                                    </Collapse></div> : <Button color="link" className="menuTitle" onClick={this.learningToggle}><span className="menuIcon"><i className="fas fa-graduation-cap"></i></span></Button>
                                                : null}


                                            {this.state.licenses.has('maths') && this.state.licenses.get('maths') &&
                                                (this.state.user.permissions.includes('MODULE_MANAGER') || this.state.user.permissions.includes('REPORTING_MANAGER')) ?
                                                this.state.visible ? <div><Button color="link" className="menuTitle" onClick={this.zapToggle}><span className="menuIcon"><img src="../images/zap-1.ico" width="15px" className="ZapIcon" /></span>Zap Maths<span className="menuToggle">{this.state.zapCollapse ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}</span></Button><br />
                                                    <Collapse isOpen={!this.state.zapCollapse}>
                                                        {this.state.user.permissions.includes('MODULE_MANAGER') ?
                                                            <div><NavLink activeClassName="activeMenuOption" exact className="inactiveMenuOption"
                                                                to="/homeworks?subjectFilter=maths"
                                                                isActive={(match, location) => location != null && location.pathname === "/homeworks" && location.search === "?subjectFilter=maths"}>Home Learning</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('MODULE_MANAGER') ?
                                                            <div><NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/quizzes">Live Quizzes</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('REPORTING_MANAGER') ?
                                                            <div><NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/reporting/zapMathsReporting">Reporting</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('MODULE_MANAGER') ?
                                                            <div>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption"
                                                                    to="/learningWorlds?subjectFilter=maths"
                                                                    isActive={(match, location) => {
                                                                        console.log("location", location);
                                                                        return location != null && location.pathname === "/learningWorlds" && location.search === "?subjectFilter=maths"
                                                                    }}>Learning Worlds</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption"
                                                                    to="/quizTemplates?subjectFilter=maths"
                                                                    isActive={(match, location) => location != null && location.pathname === "/quizTemplates" && location.search === "?subjectFilter=maths"}>Quiz Templates</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption"
                                                                    to="/adventures?subjectFilter=maths"
                                                                    isActive={(match, location) => location != null && location.pathname === "/adventures" && location.search === "?subjectFilter=maths"}>Adventures</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption"
                                                                    to="/minigames?subjectFilter=maths"
                                                                    isActive={(match, location) => location != null && location.pathname === "/minigames" && location.search === "?subjectFilter=maths"}>Custom Minigames</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption"
                                                                    to="/questions?subjectFilter=maths"
                                                                    isActive={(match, location) => location != null && location.pathname === "/questions" && location.search === "?subjectFilter=maths"}>Custom Questions</NavLink>
                                                                <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/imageLibrary">Image Library</NavLink>
                                                            </div>
                                                            : null
                                                        }
                                                    </Collapse></div> : <Button color="link" className="menuTitle" onClick={this.zapToggle}><span className="menuIcon"><i className="fas fa-bolt"></i></span></Button>
                                                : null
                                            }

                                            {this.state.user.permissions.includes('ADMIN_MANAGER') ||
                                                this.state.user.permissions.includes('ADMIN_ADMINISTRATOR') ||
                                                this.state.user.permissions.includes('ACCOUNT_ADMINISTRATOR') ||
                                                this.state.user.permissions.includes('ACCOUNT_MANAGER') ||
                                                this.state.user.permissions.includes('MODULE_ADMINISTRATOR') ||
                                                this.state.user.permissions.includes('MESSAGE_ADMINISTRATOR') ||
                                                this.state.user.permissions.includes('EVENT_ADMINISTRATOR') ||
                                                this.state.user.permissions.includes('REPORTING_ADMINISTRATOR') ?
                                                this.state.visible ? <div><Button color="link" className="menuTitle" onClick={this.usersToggle}><span className="menuIcon"><img src="../images/settings-1.ico" width="15px" className="settingsicon" /></span>Settings<span className="menuToggle">{this.state.usersCollapse ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}</span></Button><br />
                                                    <Collapse isOpen={!this.state.usersCollapse}>
                                                        {this.state.user.permissions.includes('ACCOUNT_MANAGER') ||
                                                            this.state.user.permissions.includes('ADMIN_MANAGER') ?
                                                            <div><NavLink id="accountsButton" activeClassName="activeMenuOption" className="inactiveMenuOption" to="/accounts">Accounts</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.accountsBubble ?
                                                            <section>
                                                                <blockquote id="accountsBubble" className="speech-bubble"><i className="far fa-times-circle" style={{ float: 'right', cursor: 'pointer', margin: '0', padding: '0' }} onClick={this.toggleVisibleAccountsBubble} ></i>
                                                                    <br />Click the menu option labelled <br /> 'Accounts' to Create an account.<br /><br />
                                                                    {/*<a href="Licenses" style={{color:'white'}}><i style={{color:"white"}} className="far fa-play-circle"></i> Play</a>*/}
                                                                </blockquote>
                                                            </section> : <span />}
                                                        {this.state.adminAccountsBubble ?
                                                            <section>
                                                                <blockquote id="adminAccountsBubble" className="speech-bubble"><i className="far fa-times-circle" style={{ float: 'right', cursor: 'pointer', margin: '0', padding: '0' }} onClick={this.toggleVisibleAdminAccountsBubble} ></i>
                                                                    <br />Click the menu option labelled <br /> 'Accounts' to Admin Enable an Account.<br /><br />
                                                                    {/*<a href="Licenses" style={{color:'white'}}><i style={{color:"white"}} className="far fa-play-circle"></i> Play</a>*/}
                                                                </blockquote>
                                                            </section> : <span />}
                                                        {this.state.user.permissions.includes('ACCOUNT_MANAGER') ?
                                                            <div>
                                                                <NavLink id="accountGroupsButton" activeClassName="activeMenuOption" className="inactiveMenuOption" to="/accountGroups">
                                                                    Account Groups
                                                                </NavLink>
                                                            </div>
                                                            : null
                                                        }
                                                        {this.state.accountsGroupsBubble ?
                                                            <section>
                                                                <blockquote id="accountGroupsBubble" className="speech-bubble"><i className="far fa-times-circle" style={{ float: 'right', cursor: 'pointer', margin: '0', padding: '0' }} onClick={this.toggleVisibleAccountGroupsBubble} ></i>
                                                                    <br />Click the menu option labelled 'Account Groups' <br /> to Create an Account Group.<br /><br />
                                                                    {/*<a href="Licenses" style={{color:'white'}}><i style={{color:"white"}} className="far fa-play-circle"></i> Play</a>*/}
                                                                </blockquote>
                                                            </section> : <span />}
                                                        {!this.state.user.permissions.includes('ADMIN_MANAGER') &&
                                                            !this.state.user.permissions.includes('ACCOUNT_MANAGER') && (
                                                                this.state.user.permissions.includes('ADMIN_ADMINISTRATOR') ||
                                                                this.state.user.permissions.includes('ACCOUNT_ADMINISTRATOR') ||
                                                                this.state.user.permissions.includes('MODULE_ADMINISTRATOR') ||
                                                                this.state.user.permissions.includes('MESSAGE_ADMINISTRATOR') ||
                                                                this.state.user.permissions.includes('EVENT_ADMINISTRATOR') ||
                                                                this.state.user.permissions.includes('REPORTING_ADMINISTRATOR')) ?
                                                            <div><NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="adminAccounts">Admin accounts</NavLink></div>
                                                            : null
                                                        }
                                                        {this.state.user.permissions.includes('ADMIN_MANAGER') ?
                                                            <NavLink id="organisationDetailsButton" activeClassName="activeMenuOption" className="inactiveMenuOption" to="/organisationDetails">
                                                                Organisation details
                                                        </NavLink> : null
                                                        }
                                                        {this.state.organisationDetailsBubble ?
                                                            <section>
                                                                <blockquote id="organisationDetailsBubble" className="speech-bubble"><i className="far fa-times-circle" style={{ float: 'right', cursor: 'pointer', margin: '0', padding: '0' }} onClick={this.toggleVisibleOrganisationDetailsBubble} ></i>
                                                                    <br />Click the menu option labelled 'Organisation Details' <br /> to Verify your Organisation Details.<br /><br />
                                                                    {/*<a href="Licenses" style={{color:'white'}}><i style={{color:"white"}} className="far fa-play-circle"></i> Play</a>*/}
                                                                </blockquote>
                                                            </section> : <span />}

                                                        {this.state.user.permissions.includes('ADMIN_MANAGER') ?
                                                            <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/paymentSettings">
                                                                Payment provider
                                                        </NavLink> : null
                                                        }

                                                        {this.state.user.permissions.includes('ADMIN_MANAGER') ?
                                                            <NavLink activeClassName="activeMenuOption" className="inactiveMenuOption" to="/paymentHistory">
                                                                Payments received
                                                        </NavLink> : ""
                                                        }

                                                        {this.state.user.permissions.includes('ADMIN_MANAGER') ||
                                                            this.state.user.permissions.includes('ACCOUNT_MANAGER') ?
                                                            <NavLink id="licensesButton" activeClassName="activeMenuOption" className="inactiveMenuOption" to="/licenses">
                                                                Licenses
                                                        </NavLink> : ""
                                                        }
                                                        {this.state.licensesBubble ?
                                                            <section>
                                                                <blockquote id="licensesBubble" className="speech-bubble"><i className="far fa-times-circle" style={{ float: 'right', cursor: 'pointer', margin: '0', padding: '0' }} onClick={this.toggleVisibleLicensesBubble} ></i>
                                                                    <br />Click the menu option labelled 'Licenses' <br /> to review your Licenses.<br /><br />
                                                                    {/*<a href="Licenses" style={{color:'white'}}><i style={{color:"white"}} className="far fa-play-circle"></i> Play</a>*/}
                                                                </blockquote>
                                                            </section> : <span />}
                                                    </Collapse></div> : <Button color="link" className="menuTitle" onClick={this.usersToggle}><span className="menuIcon"><i className="fas fa-users-cog" style={{ alignItems: "center" }}></i></span></Button>
                                                : null
                                            }
                                            {this.state.visible ?
                                                <div>
                                                    <Button color="link" className="menuTitle" onClick={this.helpToggle}><span className="menuIcon"><i className="far fa-question-circle" style={{ opacity: "0.7" }}></i></span>Help and Support<span className="menuToggle">{this.state.helpCollapse ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}</span></Button><br />
                                                    <Collapse isOpen={!this.state.helpCollapse}>
                                                        <div>
                                                            <a href="https://lifeninja.net/about" target="blank" style={{ color: '#6f778f' }} id="accountsButton" className="inactiveMenuOption">FAQ</a>
                                                            <NavLink id="accountsButton" activeClassName="activeMenuOption" className="inactiveMenuOption" to="/support">Support Requests</NavLink>
                                                        </div>
                                                    </Collapse>
                                                </div> : null
                                            }
                                            {/*<Button className="menuTitle" color="link">
                                                <a href="https://lifeninja.net/about" target="_blank" style={{ color: '#6f778f' }}><span className="menuIcon"><i className="far fa-question-circle"></i></span>{this.state.visible ? "FAQ" : null}</a><br />
                                            </Button>
                                            <Button className="menuTitle" color="link">
                                            <NavLink to="/tutorial" exact activeClassName="activeMenuOption" style={{color:'#6f778f'}}><span className="menuIcon"><i className="fas fa-pencil-alt"></i></span>{this.state.visible ? "Tutorials" : null}</NavLink><br />
                                        </Button>
                                            <Button className="menuTitle" color="link">
                                                <NavLink to="/support" exact activeClassName="activeMenuOption" style={{ color: '#6f778f' }}><span className="menuIcon"><i className="fas fa-ticket-alt"></i></span>{this.state.visible ? "Support Requests" : null}</NavLink>
                                        </Button>*/}
                                        </div> : <span />}
                                </td>
                            }
                            <td style={{ verticalAlign: "top" }}>
                                {this.state.initialisingAccount ? <Spinner size="sm"></Spinner> : <span />}
                                <div>
                                    {this.state.reloading ?
                                        <div className="container">
                                            <div className="top-buffer">
                                                Updating web pages. One moment please...
                                            </div>
                                        </div> :
                                        this.state.user != null && this.state.user.accountId != null && this.firebaseUser != null && this.firebaseUser.emailVerified ?
                                            <div>
                                                {this.state.user.schoolVerified ? <span /> :
                                                    <div className="container">
                                                        <Card style={{ backgroundColor: '#ffbe75', padding: '10px' }} >
                                                            <h3>Pending verification <i className="fas fa-caret-down" onClick={this.toggle}></i></h3>
                                                            <Collapse isOpen={!this.state.collapse} ><p>To protect the safety of your users, Life Ninja will contact you to verify your organisation. We will aim to contact you within one working day of your
                                                registration.<br />
                                                Your ability to create and manage administrators, users and licenses is restricted until verification is complete.<br />
                                                However, we suggest you try the other parts of the dashboard and test the functionality with your own account.
                                                {(this.state.user.role !== USER_ROLE.admin) ? <span /> :
                                                                    <Button color="secondary" outline type="button" style={{ verticalAlign: "middle" }} onClick={() => {
                                                                        this.verifyAccount();
                                                                    }}>
                                                                        Verify account
                                                        </Button>
                                                                }
                                                            </p></Collapse>
                                                        </Card>
                                                    </div>
                                                }
                                                <Switch>
                                                    <Route path="/learningWorlds/:worldId" render={props => <LearningWorldView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningPaths/:pathId" render={props => <LearningPathView schoolLicenses={this.state.licenses} user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningQuizlets/:gameId" render={props => <LearningQuizletView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    {/* <Route path="/badges/:badgeId" render={props => <ModuleBadgeView user={this.state.user} snackbar={this.showSnackbar} {...props} />} /> */}
                                                    <Route path="/learningQuestions/:questionId" render={props => <LearningQuestionView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningWorlds" render={props => <LearningLicensesView user={this.state.user} schoolLicenses={this.state.licenses} tutorialCallback={this.navAction} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/accounts/:accountId"
                                                        render={props => <AccountView user={this.state.user} schoolLicenses={this.state.licenses} accountGroups={this.state.accountGroups}
                                                            snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/accounts" render={props => <AccountsView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/accountGroups/:accountGroupId"
                                                        render={props => <AccountGroupView user={this.state.user} schoolLicenses={this.state.licenses} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups}
                                                            snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/accountGroups" render={props => <AccountGroupsView user={this.state.user} accountsMinimised={this.state.accountsMinimised} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/reporting/readingRecords" render={props => <ReadingRecordsReportView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/reporting/groupObjectives" render={props => <ObjectivesReportView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/reporting/studentObjectives" render={props => <StudentObjectivesReportView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/reporting/topicTagAnalysis" render={props => <TopicTagReportView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/reporting/zapMathsReporting" render={props => <ZapReportsOverview {...props} />} />
                                                    <Route path="/reporting/learningNinjaReporting" render={props => <LearningNinjaReportsOverview {...props} />} />
                                                    <Route path="/reporting/appDownloads" render={props => <AppDownloadsReportView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/reporting/learningPathAnalysis" render={props => <LearningPathAnalysisReportView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/reporting" render={props => <ReportingView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/messages/:messageId"
                                                        render={props => <MessageView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups}
                                                            snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/messages" render={props => <MessagesView user={this.state.user} snackbar={this.showSnackbar} tutorialCallback={this.navAction} {...props} />} />
                                                    <Route path="/events/:eventId"
                                                        render={props => <EventView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups}
                                                            snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/keyDates" render={props => <KeyDatesView user={this.state.user} snackbar={this.showSnackbar} tutorialCallback={this.navAction} {...props} />} />
                                                    <Route path="/events" render={props => <EventsView user={this.state.user} snackbar={this.showSnackbar} tutorialCallback={this.navAction} {...props} />} />
                                                    <Route path="/eventsCalendar" render={props => <EventsCalendarView user={this.state.user} accountsMinimised={this.state.accountsMinimised} tutorialCallback={this.navAction} {...props} />} />
                                                    <Route path="/slotGroups/:slotGroupId"
                                                        render={props => <SlotGroupView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups}
                                                            snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/slotGroups" render={props => <SlotGroupsView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/adminAccounts/:adminAccountId" render={props => <AdminAccountView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/adminAccounts"
                                                        render={props => <AdminAccountsView user={this.state.user} accountsMinimised={this.state.accountsMinimised} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/paymentSettings/stripe" render={props => <StripePaymentsView user={this.state.user} {...props} />} />
                                                    <Route path="/paymentSettings/square" render={props => <SquarePaymentsView user={this.state.user} snackbar={this.showSnackbar} tutorialCallback={this.navAction} {...props} />} />
                                                    <Route path="/paymentSettings" render={props => <PaymentSelectionView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/quizTemplates/:quizId/:startQuiz" render={props => <QuizTemplateView user={this.state.user} schoolLicenses={this.state.licenses} accountGroups={this.state.accountGroups} tutorialCallback={this.navAction} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/quizTemplates" render={props => <QuizTemplatesView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/quizzes/:quizId" render={props => <AutomatedQuizView user={this.state.user} accountsMinimised={this.state.accountsMinimised} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/quizzes" render={props => <QuizzesView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/automatedQuizzes/:quizId" render={props => <AutomatedQuizView user={this.state.user} accountsMinimised={this.state.accountsMinimised} snackbar={this.showSnackbar} {...props} />} />
                                                    {/* <Route path="/devices/:deviceId" render={props => <DeviceView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                        <Route path="/devices" render={props => <DevicesView user={this.state.user} snackbar={this.showSnackbar} {...props} />} /> */}
                                                    <Route path="/myAccount" render={props => <MyAccountView user={this.state.user} snackbar={this.showSnackbar} tutorialCallback={this.navAction} {...props} />} />
                                                    {/* <Route path="/studySections/:studySectionId" render={props => <StudySectionView user={this.state.user} snackbar={this.showSnackbar} {...props} />} /> */}
                                                    <Route path="/organisationDetails"
                                                        render={props => <OrganisationView user={this.state.user} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/createOrganisation"
                                                        render={props => <CreateOrganisationView user={this.state.user} newUser={false} callback={this.handleStateChange} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/adventures/:adventureId" render={props => <AdventureView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/adventures" render={props => <AdventuresView user={this.state.user} snackbar={this.showSnackbar} tutorialCallback={this.navAction} {...props} />} />
                                                    <Route path="/adventureSections/:adventureSectionId" render={props => <AdventureSectionView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/informationSections/:informationSectionId"
                                                        render={props => <InformationSectionView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/accountsCsv" render={props => <AccountCsvView user={this.state.user} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/accountGroupBulkEmail/:accountGroupId/:appId"
                                                        render={props => <AccountGroupEmailView user={this.state.user} accountsMinimised={this.state.accountsMinimised} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/chats/:accountGroupId/moderatorChats/:accountId"
                                                        render={props => <ModeratorChatTranscriptView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups}
                                                            snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/chats/:accountGroupId/moderatorChats"
                                                        render={props => <ModeratorChatsView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups} {...props} />} />
                                                    <Route path="/chats/:accountGroupId"
                                                        render={props => <ChatTranscriptView user={this.state.user} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups}
                                                            snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/chats/" render={props => <ChatsView user={this.state.user} {...props} />} />
                                                    {/* <Route path="/licenses/payment" render={props => <PayLicensesView user={this.state.user} snackbar={this.showSnackbar} {...props} />} /> */}
                                                    <Route path="/licenses/:licenseId" render={props => <LicenseView user={this.state.user} schoolLicenses={this.state.enabledLicenseDetails} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/paymentAuthResponse" render={props => <PaymentAuthResponseView user={this.state.user} {...props} />} />
                                                    <Route path="/licenses/" render={props => <LicensesView user={this.state.user} schoolLicenses={this.state.enabledLicenseDetails} {...props} />} />
                                                    {/* <Route path="/moduleCsv" render={props => <ModuleCsvView user={this.state.user} snackbar={this.showSnackbar} {...props} />} /> */}
                                                    <Route path="/imageLibrary" render={props => <ImageLibraryView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/fundraisers/:fundraiserId" render={props => <FundraiserView user={this.state.user} accountsMinimised={this.state.accountsMinimised} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/fundraisers" render={props => <FundraisersView user={this.state.user} {...props} />} />
                                                    <Route path="/paymentHistory" render={props => <PaymentHistoryView user={this.state.user} accountsMinimised={this.state.accountsMinimised} {...props} />} />
                                                    <Route path="/readingRecords/:accountId" render={props => <ReadingRecordsView user={this.state.user} accountsMinimised={this.state.accountsMinimised} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/readingRecords" render={props => <AllReadingRecordsView user={this.state.user} accountsMinimised={this.state.accountsMinimised} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningGrids/:gameId" render={props => <LearningGridView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningCircles/:gameId" render={props => <LearningCircleView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningTrace/:gameId" render={props => <LearningTraceGame user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningSlices/:gameId" render={props => <LearningSliceView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningBossLevel/:gameId" render={props => <LearningBossLevelView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningMultiplicationSpeedTest/:gameId" render={props => <LearningMultiplicationSpeedTest user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningChoiceGame/:gameId" render={props => <LearningChoiceGame user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningSplitGame/:gameId" render={props => <LearningSplitGame user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/learningShoots/:gameId" render={props => <LearningShootView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/homeworkGameAnalysis/:homeworkId/:homeworkActivityId/:gameId" render={props => <HomeworkGameAnalysis accountsMinimised={this.state.accountsMinimised} {...props} />} />
                                                    <Route path="/homeworkStudentAnalysis/:homeworkId/:homeworkActivityId/:accountId" render={props => <HomeworkStudentAnalysis {...props} />} />
                                                    <Route path="/worksheets/:homeworkId/:activityId/:accountId" render={props => <WorksheetView {...props} />} />
                                                    <Route path="/homeworkAdventureAnalysis/:homeworkId/:homeworkActivityId/:adventureId/:responseId" render={props => <AdventurePathView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/homeworks/:homeworkId/:accountId/:homeworkActivityId" render={props => <HomeworkStudentAnalysis {...props} />} />
                                                    <Route path="/homeworkActivityBasic/:homeworkId/:homeworkActivityId" render={props => <HomeworkActivityView accountsMinimised={this.state.accountsMinimised} user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/homeworkActivityWorksheet/:homeworkId/:homeworkActivityId" render={props => <HomeworkActivityWorksheetView accountsMinimised={this.state.accountsMinimised} user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/homeworkActivityLearningPath/:homeworkId/:homeworkActivityId" render={props => <HomeworkActivityLearningPathView schoolLicenses={this.state.licenses} accountsMinimised={this.state.accountsMinimised} user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/homeworkActivityAdventure/:homeworkId/:homeworkActivityId" render={props => <HomeworkActivityAdventureView schoolLicenses={this.state.licenses} accountsMinimised={this.state.accountsMinimised} user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/homeworks/:homeworkId" render={props => <HomeworkView user={this.state.user} schoolLicenses={this.state.licenses} accountsMinimised={this.state.accountsMinimised} accountGroups={this.state.accountGroups} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/homeworks" render={props => <HomeworksView user={this.state.user} tutorialCallback={this.navAction} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/engagementAwards/:awardId" render={props => <AwardView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/engagementAwards" render={props => <AwardsView user={this.state.user} tutorialCallback={this.navAction} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/minigames" render={props => <MinigamesView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/questions" render={props => <QuestionsView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/support/:ticketId" render={props => <TicketView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/support" render={props => <TicketsView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/products" render={props => <ProductsView user={this.state.user} tutorialCallback={this.navAction} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/product/:productId" render={props => <ProductView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/categories" render={props => <ProductCategoriesView user={this.state.user} tutorialCallback={this.navAction} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/purchases" render={props => <PurchasesView user={this.state.user} tutorialCallback={this.navAction} accountsMinimised={this.state.accountsMinimised} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/purchases" render={props => <PurchasesView user={this.state.user} tutorialCallback={this.navAction} accountsMinimised={this.state.accountsMinimised} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route path="/tutorials/zapMaths" render={props => <ZapMathsTutorialView user={this.state.user} callback={this.highlightMenu} {...props} />} />
                                                    <Route path="/tutorials/learningNinja" render={props => <LearningNinjaTutorialView user={this.state.user} callback={this.highlightMenu} {...props} />} />
                                                    <Route path="/tutorials/messageNinja" render={props => <MessageNinjaTutorialView user={this.state.user} callback={this.highlightMenu} {...props} />} />
                                                    <Route path="/tutorials/administration" render={props => <AdministratorTutorialView user={this.state.user} callback={this.highlightMenu} {...props} />} />
                                                    <Route path="/tutorialVideo/:tutorialId" render={props => <TutorialVideoView user={this.state.user} snackbar={this.showSnackbar} {...props} />} />
                                                    <Route render={props => <SchoolNameView user={this.state.user} accountsMinimised={this.state.accountsMinimised} tutorialCallback={this.navAction} accountGroups={this.state.accountGroups} callback={this.highlightMenu} {...props} />} />
                                                </Switch>
                                            </div>
                                            : (this.state.user != null && (this.state.user.switchSchool || this.state.user.accountId == null)) ?
                                                this.state.user.accountIds == null && this.state.user.role !== USER_ROLE.admin ?
                                                    <Route render={props => <CreateOrganisationView user={this.state.user} newUser={true} callback={this.handleStateChange} snackbar={this.showSnackbar} {...props} />} />
                                                    :
                                                    this.firebaseUser != null && this.firebaseUser.emailVerified ?
                                                        <Switch>
                                                            <Route path="/createOrganisation"
                                                                render={props => <CreateOrganisationView user={this.state.user} newUser={false} callback={this.handleStateChange} snackbar={this.showSnackbar} {...props} />} />
                                                            <Route render={props => <OrganisationSelectionView user={this.state.user} callback={this.handleStateChange}
                                                                handleSchoolSelect={this.handleSchoolSelect} handleSchoolSelectAdmin={this.handleSchoolSelectAdmin} {...props} />} />
                                                        </Switch> :
                                                        <Route render={props => <AccountVerificationView callback={this.handleStateChange} snackbar={this.showSnackbar} {...props} />} />
                                                : this.state.authUserChecked ?
                                                    <Route render={props => <LoginRegisterView snackbar={this.showSnackbar} {...props} />} />
                                                    : <Spinner size="sm"></Spinner>
                                    }

                                    <div id="snackbar" className={this.state.fadeIn ? "show" : ""}>
                                        <Alert>{this.state.snackbarText}</Alert>
                                    </div>
                                </div>
                            </td>
                            <td valign="top" style={{ padding: "0", minWidth: "10px" }}>

                            </td>
                        </tr>
                    </table>
                </div>
            </Router>
        );
    }

    highlightMenu(menuItem: string): void {
        window.scrollTo(0, 0);
        if (menuItem === 'adminAccounts' ||
            menuItem === 'organisationDetails' ||
            menuItem === 'paymentSettings' ||
            menuItem === 'licenses') {
            this.toggleAdmins();
        } else if (menuItem === 'accounts' ||
            menuItem === 'accountGroups' ||
            menuItem === 'devices') {
            this.toggleStudents();
        } else if (menuItem === 'learningWorlds' ||
            menuItem === 'reporting' ||
            menuItem === 'quizTemplates') {
            this.toggleLearning();
        } else if (menuItem === 'messages' ||
            menuItem === 'slots' ||
            menuItem === 'events') {
            this.toggleMessage();
        } else if (menuItem === 'zapMathsTutorial') {
            this.toggleTapMaths();
        } else if (menuItem === 'myAccount') {
            this.toggleAccount();
        }
        this.setState({
            highlightMenuItem: menuItem
        });
    }

    navClear(menuItem: string): void {
        if (this.state.user.role !== USER_ROLE.admin) {
            this.setState({
                highlightMenuItem: null,
            });
        }
    }

    toggle(): void {
        this.setState({
            collapse: !this.state.collapse
        })
    }

    navAction(menuItem: string): void {
        if (this.state.user.role !== USER_ROLE.admin) {
            const path = `schoolManagement/${this.state.user.schoolId}/admins/${this.state.user.accountId}/tutorials/`;
            console.log(path);
            firebase.database().ref(path).update({ [menuItem]: true });
            this.state.user.tutorials.push(menuItem);
            if (menuItem == "accounts") {
                this.setState({
                    usersCollapse: false,
                    zapCollapse: true,
                    learningCollapse: true,
                    messageCollapse: true,
                    helpCollapse: true,
                    accountsBubble: true,
                    adminAccountsBubble: false,
                    accountsGroupsBubble: false,
                    organisationDetailsBubble: false,
                    paymentProviderBubble: false,
                    paymentsReceivedBubble: false,
                    licensesBubble: false,
                })
            };
            if (menuItem == "organisationDetails") {
                this.setState({
                    usersCollapse: false,
                    zapCollapse: true,
                    learningCollapse: true,
                    messageCollapse: true,
                    helpCollapse: true,
                    accountsBubble: false,
                    adminAccountsBubble: false,
                    accountsGroupsBubble: false,
                    organisationDetailsBubble: true,
                    paymentProviderBubble: false,
                    paymentsReceivedBubble: false,
                    licensesBubble: false,
                })
            };
            if (menuItem == "accountsGroups") {
                this.setState({
                    usersCollapse: false,
                    zapCollapse: true,
                    learningCollapse: true,
                    messageCollapse: true,
                    helpCollapse: true,
                    accountsBubble: false,
                    adminAccountsBubble: false,
                    accountsGroupsBubble: true,
                    organisationDetailsBubble: false,
                    paymentProviderBubble: false,
                    paymentsReceivedBubble: false,
                    licensesBubble: false,
                })
            };
            if (menuItem == "licenses") {
                this.setState({
                    usersCollapse: false,
                    zapCollapse: true,
                    learningCollapse: true,
                    messageCollapse: true,
                    helpCollapse: true,
                    accountsBubble: false,
                    adminAccountsBubble: false,
                    accountsGroupsBubble: false,
                    organisationDetailsBubble: false,
                    paymentProviderBubble: false,
                    paymentsReceivedBubble: false,
                    licensesBubble: true,
                })
            };
            if (menuItem === "adminAccounts") {
                this.setState({
                    usersCollapse: false,
                    zapCollapse: true,
                    learningCollapse: true,
                    messageCollapse: true,
                    helpCollapse: true,
                    accountsBubble: false,
                    adminAccountsBubble: true,
                    accountsGroupsBubble: false,
                    organisationDetailsBubble: false,
                    paymentProviderBubble: false,
                    paymentsReceivedBubble: false,
                    licensesBubble: false,
                })
            }
        }
    }

    waitOnReloadRequest(): void {
        const path = 'versions/webAdmin';
        firebase.database().ref(path).on('value', async (webAdminSnapshot) => {
            let webAdminVersion = webAdminSnapshot.val();
            console.log("Web version", webAdminVersion);
            if (webAdminVersion > VERSION) {
                console.log("Reload required");
                let url = window.location.href;
                let parent = this;

                ['click', 'popstate', 'onload'].forEach(evt =>
                    window.addEventListener(evt, function () {
                        requestAnimationFrame(() => {
                            if (url !== window.location.href) {
                                console.log("Changed url");
                                parent.setState({
                                    reloading: true
                                });
                                window.location.reload();
                            }
                            url = window.location.href;
                        });
                    }, true)
                );
            }
        });
    }

    verifyAccount() {
        const path = `organisationDetails/${this.state.user.schoolId}/verified/`;
        console.log(path);
        firebase.database().ref(path).set(true);
    }

    showSnackbar(text: string): void {
        if (this.currentSnackbarTimeout != null) {
            clearTimeout(this.currentSnackbarTimeout);
        }
        // Get the snackbar DIV
        this.setState({
            fadeIn: true,
            snackbarText: text == null ? "Saved" : text
        });

        // After 6 seconds, remove the show class from DIV
        this.currentSnackbarTimeout = setTimeout(() => {
            this.currentSnackbarTimeout = null;
            this.setState({
                fadeIn: false,
                snackbarText: null
            });
        }, 6000);
    }


    static createCardRows(entries: Array<any>, cardCallback: Function, queryValues: string) {
        let rows: JSX.Element[] = [];
        if (entries == null) {
            return rows;
        }
        let columnCount = 4;
        for (let pos = 0; pos < entries.length; pos += columnCount) {
            let newCards = [];
            for (let plusPos = 0; plusPos < columnCount; plusPos++) {
                if (entries.length > pos + plusPos) {
                    newCards.push(
                        <Col key={"column-" + (pos + plusPos)} className='row-eq-height' md="3">
                            {cardCallback(entries[pos + plusPos], queryValues)}
                        </Col>
                    );
                } else {
                    newCards.push(
                        <Col key={"column-" + (pos + plusPos)} className='row-eq-height' md="3"> </Col>
                    );
                }
            }
            rows.push(
                <Row className="top-buffer">
                    {newCards}
                </Row>
            )
        }
        return rows;
    }

    componentDidMount(): void {
        this.waitOnReloadRequest();
        auth.onAuthStateChanged(async (firebaseUser) => {
            this.firebaseUser = firebaseUser;
            //console.log(await firebase.auth().currentUser.getIdToken());
            if (firebaseUser != null) {
                if (!firebaseUser.emailVerified) {
                    sendVerificationEmail(firebaseUser.email, this.showSnackbar);
                }
                const path = `schoolUsers/${firebaseUser.email.replace(/\./g, ',')}`;
                let snapshot = await firebase.database().ref(path).once('value');
                // Check the user hasn't been signed out in the meantime
                firebaseUser = firebase.auth().currentUser;
                if (firebaseUser == null) {
                    return;
                }
                let provider = firebaseUser.providerId;
                for (let i = 0; i < firebaseUser.providerData.length; i++) {
                    let userInfo = firebaseUser.providerData[i];
                    if (userInfo.providerId === "google.com") {
                        provider = "google.com";
                        break;
                    }
                }
                let userAccount = snapshot.val();
                let user = new User();
                if (userAccount == null || (userAccount.role !== 'admin' && userAccount.schoolAccounts == null)) {
                    user.email = firebaseUser.email;
                    user.provider = provider;
                } else {
                    let accountIds = new Map<string, UserAccount>();
                    for (let nextAccountId in userAccount.schoolAccounts) {
                        accountIds.set(nextAccountId, userAccount.schoolAccounts[nextAccountId]);
                    }
                    user.accountIds = accountIds;
                    user.role = userAccount.role;
                    user.roles = userAccount.roles == null ? new Map() : objectToMapConverter(userAccount.roles);
                    user.email = firebaseUser.email;
                    user.provider = provider;
                }
                this.setState({
                    user: user,
                    authUserChecked: true,
                });
                this.listenOnAccountChange();
            } else {
                this.setState({
                    user: null,
                    authUserChecked: true,
                });
            }
        });
    }

    listenOnAccountChange(): void {
        const path = 'schoolUsers/' + this.state.user.email.replace(/\./g, ',') + '/currentAccountId';
        const itemsRef = firebase.database().ref(path);
        itemsRef.on('value', (snapshot) => {
            console.log("Got account change");
            if (this.state.user.accountId != null && this.state.user.accountId !== snapshot.val()) {
                console.log("Account id change");
                this.state.user.switchSchool = true;
                this.state.user.accountId = null;
                this.state.user.schoolId = null;
                this.setState({});
            }
        });
    }

    async handleSwitchSchool(): Promise<void> {
        // const path = 'schoolUsers/' + this.state.user.email.replace(/\./g, ',') + '/currentAccountId';
        // const itemsRef = firebase.database().ref(path);
        // await itemsRef.remove();
        this.state.user.switchSchool = true;
        this.state.user.accountId = null;
        this.state.user.schoolId = null;
        if (this.schoolRef != null) {
            this.schoolRef.off();
            this.schoolRef = null;
        }
        if (this.userRef != null) {
            this.userRef.off();
            this.userRef = null;
        }
        if (this.adminRef != null) {
            this.adminRef.off();
            this.adminRef = null;
        }
        if (this.accountsMinimisedListener != null) {
            this.accountsMinimisedListener.off();
            this.accountsMinimisedListener = null;
        }
        if (this.accountGroupsListener != null) {
            this.accountGroupsListener.off();
            this.accountGroupsListener = null;
        }
        this.setState({});
    }

    async handleSchoolSelect(schoolId: string, accountId: string, subAccountId: string, user: User): Promise<Map<string, OrganisationDetails>> {
        this.setState({
            initialisingAccount: true,
        });
        let firebaseUser = await firebase.auth().currentUser;
        await firebase.database().ref(`schoolUsers/${firebaseUser.email.replace(/\./g, ',')}/currentAccountId`).set(accountId);
        await this.replaceToken();
        this.state.user.switchSchool = false;
        let promises = [];
        const schoolPath = `organisationDetails/${schoolId}`;
        this.schoolRef = firebase.database().ref(schoolPath);
        let schoolPromise = new Promise<void>((resolve, reject) => {
            this.schoolRef.on('value', async (snapshot) => {
                let nextOrg = OrganisationDetails.fromFirebase(snapshot.val());
                this.state.schoolList.set(schoolId, nextOrg);
                user.schoolName = nextOrg.name;
                user.schoolType = nextOrg.type;
                user.currency = nextOrg.currency == null ? "GBP" : nextOrg.currency;
                user.schoolAvatarUrl = nextOrg.imageUrl;
                user.schoolVerified = nextOrg.verified == null ? false : nextOrg.verified;
                user.countryCode = "GB"; // TODO this.state.schoolList[schoolId].countryCode == null ? "GB" : this.state.schoolList[schoolId].countryCode;
                resolve();
                this.setState({});
            }, (error: any) => console.log(error));
        });
        promises.push(schoolPromise);

        const accountPath = `schoolManagement/${schoolId}/accounts/${accountId}`;
        this.userRef = firebase.database().ref(accountPath);
        let userPromise = new Promise<void>((resolve, reject) => {
            this.userRef.on('value', async (snapshot) => {
                let userAccount = Account.fromFirebase(snapshot.val());
                user.name = userAccount.name;
                user.title = userAccount.title;
                user.personalName = userAccount.personalName;
                user.familyName = userAccount.familyName;
                resolve();
                this.setState({});
            }, (error: any) => console.log(error));
        });
        promises.push(userPromise);

        const adminPath = `schoolManagement/${schoolId}/admins/${accountId}`;
        this.adminRef = firebase.database().ref(adminPath);
        let firstTime = true;
        let adminPromise = new Promise<void>((resolve, reject) => {
            this.adminRef.on('value', async (snapshot) => {
                let adminAccount = snapshot.val();
                if (adminAccount == null) {
                    user.permissions = [];
                    user.avatarUrl = null;
                    user.tutorials = [];
                } else {
                    user.permissions = adminAccount.permissions == null ? [] : Object.keys(adminAccount.permissions);
                    user.avatarUrl = adminAccount.avatarUrl;
                    user.tutorials = adminAccount.tutorials != null ? Object.keys(adminAccount.tutorials) : [];
                }
                if (firstTime) {
                    firstTime = false;
                } else {
                    await this.replaceToken();
                }
                resolve();
                this.setState({});
            }, (error: any) => console.log(error));
        });
        promises.push(adminPromise);

        promises.push(this.setupAccountsListeners(schoolId));

        await Promise.all(promises);
        user.switchSchool = false;
        user.accountId = accountId;
        user.subAccountId = subAccountId;
        user.schoolId = schoolId;

        this.setState({
            initialisingAccount: false,
        });

        return this.state.schoolList;
    };

    async handleSchoolSelectAdmin(schoolId: string, user: User): Promise<void> {
        user.permissions = [];
        await this.replaceToken();
        Array.from(permissions.keys()).map((key) => {
            user.permissions.push(key + "_MANAGER");
            user.permissions.push(key + "_ADMINISTRATOR");
            return "";
        });
        user.accountId = "ADMIN";
        user.subAccountId = "ADMIN";
        user.name = "Admin";
        user.personalName = "Super admin";
        user.schoolId = schoolId;
        user.tutorials = [];

        const schoolPath = `organisationDetails/${schoolId}`;
        this.schoolRef = firebase.database().ref(schoolPath);
        let schoolPromise = new Promise<void>((resolve, reject) => {
            this.schoolRef.on('value', async (snapshot) => {
                let nextSchool = OrganisationDetails.fromFirebase(snapshot.val());
                this.state.schoolList.set(schoolId, nextSchool);
                user.schoolName = nextSchool.name;
                user.schoolType = nextSchool.type;
                user.schoolVerified = nextSchool.verified;
                user.schoolAvatarUrl = nextSchool.imageUrl;
                resolve();
                this.setState({});
            }, (error: any) => console.log(error));
        });
        await schoolPromise;

        await firebase.database().ref(`admin/${user.email.replace(/\./g, ',')}/currentSchoolId`).set(schoolId);

        await this.setupAccountsListeners(schoolId);
        this.setState({
            user: user
        });
    }

    async setupAccountsListeners(schoolId: string): Promise<void> {
        this.setState({
            accountsMinimised: new Map(),
            accountGroups: new Map(),
        });

        let promises = [];
        if (this.accountsMinimisedChangedListener != null) {
            promises.push(this.accountsMinimisedChangedListener.off());
        }
        if (this.accountsMinimisedRemovedListener != null) {
            promises.push(this.accountsMinimisedRemovedListener.off());
        }
        if (this.accountsMinimisedAddedListener != null) {
            promises.push(this.accountsMinimisedAddedListener.off());
        }
        if (this.accountGroupsListener != null) {
            promises.push(this.accountGroupsListener.off());
        }
        if (this.licensesListener != null) {
            promises.push(this.licensesListener());
        }
        await Promise.all(promises);

        this.accountsMinimisedChangedListener = firebase.database().ref(`schoolManagement/${schoolId}/accountsMinimised`);
        this.accountsMinimisedChangedListener.on('child_changed', (snapshot) => {
            console.log("Loading account update");
            let account = AccountMinimised.fromFirebase(snapshot.val());

            if (account.deactivated != null && !account.deactivated) {
                this.setState({
                    accountsMinimised: new Map([...this.state.accountsMinimised, [snapshot.key, account]]),
                });
            } else {
                this.state.accountsMinimised.delete(snapshot.key);
                this.setState({});
            }
        });

        this.accountsMinimisedRemovedListener = firebase.database().ref(`schoolManagement/${schoolId}/accountsMinimised`);
        this.accountsMinimisedRemovedListener.on('child_removed', (snapshot) => {
            console.log("Loading account removal");
            let newState = this.state.accountsMinimised;
            newState.delete(snapshot.key);
            this.setState({
                accountsMinimised: newState,
            });
        });

        this.accountsMinimisedAddedListener = firebase.database().ref(`schoolManagement/${schoolId}/accountsMinimised`).orderByChild('deactivated').equalTo(null);
        this.accountsMinimisedAddedListener.on('child_added', (snapshot) => {
            console.log("Loading account added");
            let account = AccountMinimised.fromFirebase(snapshot.val());
            let newState = this.state.accountsMinimised;
            newState.set(snapshot.key, account);

            this.setState({
                accountsMinimised: newState,
            });
        });

        this.accountGroupsListener = firebase.database().ref(`schoolManagement/${schoolId}/accountGroups`);
        this.accountGroupsListener.on('child_changed', (snapshot) => {
            let accountGroup = AccountGroup.fromFirebase(snapshot.val());
            let newState = this.state.accountGroups;
            if (!accountGroup.deactivated) {
                if (accountGroup.accounts == null) {
                    accountGroup.accounts = new Map();
                }
                newState.set(snapshot.key, accountGroup);
            } else {
                newState.delete(snapshot.key);
            }
            this.setState({
                accountGroups: newState,
            });
        });
        let accountGroupsRef = firebase.database().ref(`schoolManagement/${schoolId}/accountGroups`).orderByChild('deactivated').equalTo(null);
        let snapshot = await accountGroupsRef.once('value');
        let accountGroups = snapshot.val();
        let newState = new Map<string, AccountGroup>();
        for (let nextAccountGroupId in accountGroups) {
            let nextAccountGroup = AccountGroup.fromFirebase(accountGroups[nextAccountGroupId]);
            if (nextAccountGroup.accounts == null) {
                nextAccountGroup.accounts = new Map();
            }
            newState.set(nextAccountGroupId, nextAccountGroup);
        }

        this.setState({
            accountGroups: newState,
        });

        let licensesDocRef = firebase.firestore().doc(`schoolLicenses/${schoolId}`);
        await new Promise<void>(resolve => {
            this.licensesListener = licensesDocRef.onSnapshot((snapshot) => {
                let newLicenseDetails = new Map<string, LicenseActivity>();
                let newLicenses = new Map<string, boolean>();
                let snapshotData = snapshot.data();
                if (snapshotData != null) {
                    for (let nextDataSnapshotId in snapshotData) {
                        if (nextDataSnapshotId !== "licenseData") {
                            let newLicenseActivity = new LicenseActivity();
                            newLicenseActivity.enabled = snapshotData[nextDataSnapshotId];
                            newLicenseDetails.set(nextDataSnapshotId, newLicenseActivity);
                            newLicenses.set(nextDataSnapshotId, newLicenseActivity.enabled);
                        }
                    }
                    // New approach will overwrite old data
                    if (snapshotData['licenseData'] != null) {
                        for (let nextDataSnapshotId in snapshotData['licenseData']) {
                            let newLicenseActivity = LicenseActivity.fromFirebase(snapshotData['licenseData'][nextDataSnapshotId]);
                            newLicenseDetails.set(nextDataSnapshotId, newLicenseActivity);
                            newLicenses.set(nextDataSnapshotId, newLicenseActivity.enabled);
                        }
                    }
                }
                this.setState({
                    licenses: newLicenses,
                    enabledLicenseDetails: newLicenseDetails,
                });
                resolve();
            });
        });
    }

    async replaceToken(): Promise<void> {
        let idToken = await firebase.auth().currentUser.getIdToken();
        let authorization = 'Bearer ' + idToken;
        let response = await fetch('https://admin.lifeninja.net/adminRequests/generateToken', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            },
        });
        let success = response.status === 200;
        console.log("Generate", success);

        await firebase.auth().currentUser.getIdToken(true);
    }

    handleStateChange(): void {
        this.setState({});
    }

    async logout(user: User): Promise<void> {
        let google = false;
        if (user.provider === "google.com") {
            google = true;
        }
        await auth.signOut();
        if (google) {
            window.location.href = "https://accounts.google.com/Logout";
        }
    }
}

export default RootView;
import firebase from '../firebase';
import { classToObjectConverter, objectToClassConverter, objectToMapConverter } from './database_object';

export class AccountGroup {

    constructor() {
        this.details = new AccountGroupDetails();
    }

    details: AccountGroupDetails;
    accounts: Map<string, AccountGroupLink> = null;
    moderators: Map<string, AccountGroupModeratorLink> = null;
    deactivated: boolean = null;

    static fromFirebase(data: any): AccountGroup {
        let newAccountGroup = new AccountGroup();
        if (data == null) {
            return newAccountGroup;
        }
        newAccountGroup.deactivated = data.deactivated;

        if (data.details != null) {
            newAccountGroup.details = AccountGroupDetails.fromFirebase(data.details);
        }

        let newAccounts = new Map<string, AccountGroupLink>();
        if (data.accounts != null) {
            for (let nextKey of Object.keys(data.accounts)) {
                newAccounts.set(nextKey, AccountGroupLink.fromFirebase(data.accounts[nextKey]));
            }
        }
        newAccountGroup.accounts = newAccounts;

        let newModerators = new Map<string, AccountGroupModeratorLink>();
        if (data.moderators != null) {
            for (let nextKey of Object.keys(data.moderators)) {
                newModerators.set(nextKey, AccountGroupModeratorLink.fromFirebase(data.moderators[nextKey]));
            }
        }
        newAccountGroup.moderators = newModerators;

        return newAccountGroup;
    }
}

export class AccountGroupDetails {
    name: string = null;
    additional: string = null;
    chatEnabled: boolean = null;
    leaderboardEnabled: boolean = null;
    chatType: CHAT_TYPE = null;
    moderatorsName: string = null;
    updated: number = null;
    autoJoin: boolean = null;
    _lowerCaseName: string = null;

    static fromFirebase(data: any): AccountGroupDetails {
        let newAccountGroupDetails = new AccountGroupDetails();
        if (data == null) {
            return newAccountGroupDetails;
        }
        objectToClassConverter(data, newAccountGroupDetails, [], []);

        return newAccountGroupDetails;
    }

    getLowercaseName(): string {
        if (this._lowerCaseName != null) {
            return this._lowerCaseName;
        } else {
            this._lowerCaseName = this.name.toLowerCase();
            return this._lowerCaseName;
        }
    }

    toFirebase(): {} {
        let fbAccountGroupDetails: any = Object.assign({}, this);
        fbAccountGroupDetails['updated'] = firebase.database.ServerValue.TIMESTAMP;
        return fbAccountGroupDetails;
    }
}

export class AccountMinimised {
    name: string = null;
    updated: number = null;
    additional: string = null;
    deactivated: boolean = null;
    _lowerCaseName: string = null;

    static fromFirebase(data: any): AccountMinimised {
        let newAccountMinimised = new AccountMinimised();
        if (data == null) {
            return newAccountMinimised;
        }
        objectToClassConverter(data, newAccountMinimised, [], []);

        return newAccountMinimised;
    }

    getLowercaseName(): string {
        if (this._lowerCaseName != null) {
            return this._lowerCaseName;
        } else {
            this._lowerCaseName = this.name.toLowerCase();
            return this._lowerCaseName;
        }
    }
}

export class Account {
    sharingCode: string = null;
    deactivated: boolean = null;
    title: string = null;
    personalName: string = null;
    familyName: string = null;
    name: string = null;
    additional: string = null;
    remoteId: string = null;
    updated: number = null;
    subscribed: boolean = null; // WHAT IS THIS?
    acGroups: Map<string, AccountGroupLink> = null;
    isAdmin: boolean = null;
    _lowerCaseName: string;
    _id: string;

    getLowercaseName(): string {
        if (this._lowerCaseName != null) {
            return this._lowerCaseName;
        } else {
            this._lowerCaseName = this.name.toLowerCase();
            return this._lowerCaseName;
        }
    }

    static fromFirebase(data: any): Account {
        let newAccount = new Account();
        if (data == null) {
            return newAccount;
        }
        objectToClassConverter(data, newAccount, [], ["acGroups"]);

        let newAcGroups = new Map<string, AccountGroupLink>();
        if (data.acGroups != null) {
            for (let nextKey of Object.keys(data.acGroups)) {
                newAcGroups.set(nextKey, AccountGroupLink.fromFirebase(data.acGroups[nextKey]));
            }
        }
        newAccount.acGroups = newAcGroups;

        return newAccount;
    }

    toFirebase(): {} {
        let fbAccount: any = {};
        classToObjectConverter(this, fbAccount, [], ["acGroups"]);

        let acGroupsObject: any = {};
        if (this.acGroups != null) {
            for (let nextGroupId of this.acGroups.keys()) {
                acGroupsObject[nextGroupId] = this.acGroups.get(nextGroupId).toFirebase();
            }
        }
        fbAccount['acGroups'] = acGroupsObject;

        // Record latest update
        fbAccount['updated'] = firebase.database.ServerValue.TIMESTAMP;

        // Protect against earlier versions
        fbAccount['lowerCaseName'] = null;

        return fbAccount;
    }
}

export class AccountGroupLink {
    member: boolean = null;
    updated: number = null;

    static fromFirebase(data: any): AccountGroupLink {
        let newAccountGroupLink = new AccountGroupLink();
        if (data == null) {
            return newAccountGroupLink;
        }
        objectToClassConverter(data, newAccountGroupLink, [], []);

        return newAccountGroupLink;
    }

    toFirebase(): {} {
        let fbAccountLink: any = Object.assign({}, this);
        fbAccountLink['updated'] = firebase.database.ServerValue.TIMESTAMP;
        return fbAccountLink;
    }
}

export class AccountGroupModeratorLink {
    moderator: boolean = null;
    updated: number = null;

    static fromFirebase(data: any): AccountGroupModeratorLink {
        let newAccountGroupModeratorLink = new AccountGroupModeratorLink();
        if (data == null) {
            return newAccountGroupModeratorLink;
        }
        objectToClassConverter(data, newAccountGroupModeratorLink, [], []);

        return newAccountGroupModeratorLink;
    }

    toFirebase(): {} {
        let fbAccountLink: any = Object.assign({}, this);
        fbAccountLink['updated'] = firebase.database.ServerValue.TIMESTAMP;
        return fbAccountLink;
    }
}

export enum SUB_ACCOUNT_TYPE { USERNAME = "username", EMAIL = "email" }

export class AccountSubAccount {
    accountType: SUB_ACCOUNT_TYPE = null;
    relationship: string = null; // Name (e.g. parent's name)
    email: string = null;
    mnAccess: string = null;
    type: string = null; // Relationship to account (e.g. Parent)

    static fromFirebase(data: any): AccountSubAccount {
        let newAccountSubAccount = new AccountSubAccount();
        if (data == null) {
            return newAccountSubAccount;
        }
        objectToClassConverter(data, newAccountSubAccount, [], []);

        // Older sub accounts didn't have a type
        if (newAccountSubAccount.accountType == null) {
            newAccountSubAccount.accountType = SUB_ACCOUNT_TYPE.EMAIL;
        }

        return newAccountSubAccount;
    }

    toFirebase(): {} {
        let fbSubAccount: {} = Object.assign({}, this);
        return fbSubAccount;
    }
}

export enum CHAT_TYPE {
    groupChatOnly = "groupChatOnly", groupChatAndPrivate = "groupChatAndPrivate", privateOnly = "privateOnly",
    privateAndReadOnlyGroupChat = "privateAndReadOnlyGroupChat", readOnlyGroupChat = "readOnlyGroupChat"
}

export class AdminAccount {
    permissions: Map<string, boolean> = null;
    active: boolean = null;
    tutorials: Map<string, boolean> = null;

    static fromFirebase(data: any): AdminAccount {
        let newAdminAccount = new AdminAccount();
        if (data == null) {
            return newAdminAccount;
        }
        newAdminAccount.permissions = objectToMapConverter(data.permissions, [], []);
        newAdminAccount.tutorials = objectToMapConverter(data.tutorials, [], []);
        newAdminAccount.active = data.active;

        return newAdminAccount;
    }
}
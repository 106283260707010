
export function objectToMapConverter(origObject: any, subMaps?: Array<string>, complexObjects?: Array<string>): Map<any, any> {
    let response = new Map<any, any>();
    if (origObject != null) {
        for (let nextKey of Object.keys(origObject)) {
            if (complexObjects != null && complexObjects.indexOf(nextKey) !== -1) {
                continue;
            }
            if (subMaps != null && subMaps.indexOf(nextKey) !== -1) {
                response.set(nextKey, objectToMapConverter(origObject[nextKey], subMaps, complexObjects));
            } else {
                response.set(nextKey, origObject[nextKey] == null ? null : origObject[nextKey]); // Remove undefined which Firebase hates
            }
        }
    }

    return response;
}

export function objectToClassConverter(origObject: any, newInstance: any, subMaps?: Array<string>, complexObjects?: Array<string>): void {
    for (var nextKey in newInstance) {
        if (newInstance.hasOwnProperty(nextKey) && !nextKey.startsWith("_")) {
            if (complexObjects != null && complexObjects.indexOf(nextKey) !== -1) {
                continue;
            }
            if (subMaps != null && subMaps.indexOf(nextKey) !== -1) {
                newInstance[nextKey] = objectToMapConverter(origObject[nextKey], subMaps, complexObjects);
            } else {
                newInstance[nextKey] = origObject[nextKey] == null ? null : origObject[nextKey]; // Remove undefined which Firebase hates
            }
        }
    }
}

export function classToObjectConverter(origInstance: any, newObject: any, subMaps?: Array<string>, complexObjects?: Array<string>): void {
    for (var nextKey in origInstance) {
        if (origInstance.hasOwnProperty(nextKey) && !nextKey.startsWith("_")) {
            if (complexObjects != null && complexObjects.indexOf(nextKey) !== -1) {
                continue;
            }
            if (subMaps != null && subMaps.indexOf(nextKey) !== -1) {
                newObject[nextKey] = mapToObjectConverter(origInstance[nextKey], subMaps, complexObjects);
            } else {
                newObject[nextKey] = origInstance[nextKey] == null ? null : origInstance[nextKey] ; // Remove undefined which Firebase hates
            }
        }
    }
}

export function mapToObjectConverter(origMap: Map<string, any>, subMaps?: Array<string>, complexObjects?: Array<string>): {} {
    let response: any = {};
    for (let [nextKey, value] of origMap) {
        if (complexObjects != null && complexObjects.indexOf(nextKey) !== -1) {
            continue;
        }
        if (subMaps != null && subMaps.indexOf(nextKey) !== -1) {
            response[nextKey] = mapToObjectConverter(value, subMaps, complexObjects);
        } else {
            response[nextKey] = value == null ? null : value;
        }
    }

    return response;
}

export function createUpdateFragment(origObject: any, fragmentProperties: Array<string>): {} {
    let fragment: any = {};

    for (let nextKey of fragmentProperties) {
        fragment[nextKey] = origObject[nextKey];
    }

    return fragment;
}
import React, { Component } from "react";
import firebase from './firebase';
import queryString from 'query-string';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, 
    Card,
    CardBody,
    Form, 
    FormGroup,
    Label,
    Input,
    UncontrolledTooltip,
} from 'reactstrap';
import {Link, RouteComponentProps} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import TopicsSelector from "./ninja_components/topics_selector";
import { User, USER_ROLE } from "./data/user";
import { soundNames, subjectIds } from "./Prettifier";
import { ChoiceBucket, ChoiceCard, ChoiceGame, TIME_MULTIPLIER } from "./data/learning_games"
import { StoreLearningGame } from "./helpers/topic_services";
import { Topic } from "./data/home_learning";

interface IState {
    worldId: string;
    worldName: string;
    gameId: string;
    pathId: string;
    pathName: string;
    subjectFilter: string,
    choiceGame: ChoiceGame;
    currentChoiceGameTitle: string;
    currentChoiceGameSubjectId: string;
    currentChoiceGameSchoolId: string;
    currentChoiceGameTopics: string[];
    currentChoiceGameTags: Map<string, string[]>;
    currentChoiceGameInstructions: string;
    currentChoiceGameAudioInstructions: string;
    timeMultiplier: TIME_MULTIPLIER,
    currentChoiceGameCards: ChoiceCard[],
    currentChoiceGameBuckets: ChoiceBucket[],
    currentChoiceIncorrect: string[],
    user: User,
}

interface MatchParams {
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class LearningChoiceGame extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            gameId: props.match.params.gameId,
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            choiceGame: null,
            currentChoiceGameTitle: '',
            currentChoiceGameSubjectId: '',
            currentChoiceGameSchoolId: '',
            currentChoiceGameInstructions: '',
            currentChoiceGameTags: new Map(),
            currentChoiceGameTopics: [],
            currentChoiceGameAudioInstructions: '',
            currentChoiceGameCards: [],
            currentChoiceGameBuckets: [],
            timeMultiplier: TIME_MULTIPLIER.moderate,
            currentChoiceIncorrect: ["0"],
            user: props.user,
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.dealWithChange = this.dealWithChange.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.removeCard = this.removeCard.bind(this);
        this.removeBucket = this.removeBucket.bind(this);
        this.handleCardTargetChange = this.handleCardTargetChange.bind(this);
        this.handleCardNumberChange = this.handleCardNumberChange.bind(this);
        this.handleBucketTargetChange = this.handleBucketTargetChange.bind(this);
        this.handleAddBucket = this.handleAddBucket.bind(this);
        this.handleAddCard = this.handleAddCard.bind(this);
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.currentChoiceGameSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ? 
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All Minigames</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem>
                            : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem>
                            : null}
                        <BreadcrumbItem active>Choice Game</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Choice Game</div>
                        <p>The choice game requires the user to seperate a pile of cards into the correct groups.</p>
                        <div>
                            <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                <fieldset disabled={!editable}>
                                    <FormGroup>
                                        <Label for="currentChoiceGameTitle">Title *</Label>
                                        <Input maxLength={30} type="text" required name="currentChoiceGameTitle" value={this.state.currentChoiceGameTitle} onChange={(e) => this.setState({
                                            currentChoiceGameTitle: e.target.value
                                        })}></Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentChoiceGameSubjectId">Subject *</Label>
                                        <Input disabled={this.state.worldId != null || this.state.gameId !== "-1"} type="select" name="currentChoiceGameSubjectId"
                                        onChange={(e) => this.setState({
                                            currentChoiceGameSubjectId: e.target.value
                                        })}
                                        value={this.state.currentChoiceGameSubjectId}>
                                        <option value={''}>&nbsp;</option>
                                        {Array.from(subjectIds.keys()).map(subjectId => {
                                            let subjectName = subjectIds.get(subjectId);
                                            return <option value={subjectId}>{subjectName}</option>
                                        })}
                                        </Input>
                                    </FormGroup>
                                    {this.state.currentChoiceGameSubjectId === '' ? <div></div> :
                                        <TopicsSelector subject={this.state.currentChoiceGameSubjectId} countryCode={this.state.user.countryCode} currentTopics={this.state.currentChoiceGameTopics} currentTopicsTags={this.state.currentChoiceGameTags} callback={this.handleTopicsChange} />
                                    }
                                    <FormGroup>
                                    <label>
                                        Game speed <i className="fas fa-info-circle icons-info" id="help-learning-timemultiplier" />
                                        <UncontrolledTooltip placement="bottom" autohide={false} target="help-learning-timemultiplier">
                                            Control how fast or slow the game will play.
                                            Overall paths can have speed controls and the game speed will work within the context of the path speed control.
                                            For example, a game with a 'fast' speed on a path with a 'slow' speed will play at 'moderate' speed.
                                            Use this to allow developing or advanced students to play at a speed that suits them.
                                        </UncontrolledTooltip>
                                    </label>
                                        <Input type="select" value={this.state.timeMultiplier} onChange={this.dealWithChange}>
                                            <option value="fastest">Fastest</option>
                                            <option value="fast">Fast</option>
                                            <option value="moderate">Moderate</option>
                                            <option value="slow">Slow</option>
                                            <option value="slowest">Slowest</option>
                                        </Input>
                                    </FormGroup>
                                    
                                    <FormGroup>
                                        <h4>Buckets <i className="fas fa-info-circle icons-info" id="help-learningchoicegame-buckets" /></h4>
                                        {this.state.currentChoiceGameBuckets.map((nextChoiceBucket, bucketIndex) => {
                                            return <div className="border rounded form-margin">
                                                <div><b>Bucket {bucketIndex + 1}</b> {bucketIndex > 1 ? <Button type="button" color="link" onClick={() => this.removeBucket(bucketIndex)}><i 
                                                    className="material-icons material-icons-xd">cancel</i></Button> : <span/>}</div>
                                                <div>Bucket {bucketIndex + 1} Title</div>
                                                <Input required name={`currentChoiceBuckets-${bucketIndex}`}
                                                    onChange={(value) => this.handleBucketTargetChange(value, bucketIndex)}
                                                    value={nextChoiceBucket.title} />
                                            </div>
                                        })}
                                                {this.state.currentChoiceGameBuckets.length < 4 ? <Button className="altButton"  onClick={() => this.handleAddBucket()}>Additional Buckets</Button> : <span />}
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningchoicegame-buckets">
                                                    Enter the Buckets that will accept or reject the cards depening on if they are correct or not.<br />
                                                    You can have a minimum of 2 buckets and a maximum of 4 buckets.
                                                </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup>
                                        <h4>Cards <i className="fas fa-info-circle icons-info" id="help-learningchoicegame-cards" /></h4>
                                        {this.state.currentChoiceGameCards.map((nextChoiceCard, cardIndex) => {
                                            return <div className="border rounded form-margin">
                                                <div><b>Card {cardIndex + 1}</b> {cardIndex > 0 ? <Button type="button" color="link" onClick={() => this.removeCard(cardIndex)}><i
                                                    className="material-icons material-icons-xd">cancel</i></Button> : <span />}</div>
                                                <div>Card Title</div>
                                                <Input required name={`currentChoiceCards-${cardIndex}`}
                                                    onChange={(value) => this.handleCardTargetChange(value, cardIndex)}
                                                    value={nextChoiceCard.title} />
                                                <div>Correct Bucket</div>
                                                <Input required type="select" value={nextChoiceCard.bucketNumber} name="newCorrectBucket" onChange={(e) => {
                                                    nextChoiceCard.bucketNumber = parseInt(e.target.value);
                                                    this.setState({});
                                                }}>
                                                    <option value={""}>&nbsp;</option>
                                                    {this.state.currentChoiceGameBuckets.map((nextChoiceBucket, bucketIndex) => {
                                                        return (
                                                            <option value={bucketIndex} key={bucketIndex}>{this.state.currentChoiceGameBuckets[bucketIndex]!.title}</option>
                                                        )
                                                    })}
                                                </Input>
                                                </div>
                                        })}
                                                    <Button className="altButton" onClick={() => this.handleAddCard()}>Additional Card</Button>
                                                    <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningchoicegame-cards">
                                                        Enter the Cards that will be assigned to a bucket.
                                                    </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentChoiceGameInstructions">Instructions <i className="fas fa-info-circle icons-info" id="help-learningchoiceGame-instructions" /></Label>
                                        <Input type="textarea" name="currentChoiceGameInstructions" onChange={(e) => this.setState({
                                            currentChoiceGameInstructions: e.target.value
                                        })} value={this.state.currentChoiceGameInstructions} />
                                        <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningchoiceGame-instructions">
                                            The Instructions shown to the player before they start the Choice Game. 
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentChoiceGameAudio">Audio Instruction</Label>
                                        <Input type="select" name="currentChoiceGameAudio"
                                            onChange={(e) => this.setState({
                                            currentChoiceGameAudioInstructions: e.target.value
                                            })}
                                            value={this.state.currentChoiceGameAudioInstructions}>
                                            <option value={''}>&nbsp;</option>
                                            {Array.from(soundNames.keys()).map((key) => {
                                                let name = soundNames.get(key);
                                                return (
                                                    <option value={key} key={key}>{name}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                    {editable ? this.state.gameId === "-1" ?
                                        <Button disabled={this.state.currentChoiceGameSubjectId === ''} className="adminPagesButton">Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                    }
                                </fieldset>
                            </Form>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    handleAddCard(): void {
        let initialChoiceCard = new ChoiceCard();
        initialChoiceCard.title = "";
        initialChoiceCard.bucketNumber = 0;

        this.state.currentChoiceGameCards.push(initialChoiceCard);
        this.setState({});
    }

    handleAddBucket(): void {
        let initialChoiceBucket = new ChoiceBucket();
        initialChoiceBucket.title = "";

        this.state.currentChoiceGameBuckets.push(initialChoiceBucket);
        this.setState({});
    }

    removeBucket(bucketIndex: number): void {
        this.state.currentChoiceGameBuckets.splice(bucketIndex, 1);
        this.setState({});
    }

    handleBucketTargetChange(e: React.ChangeEvent<HTMLInputElement>, bucketIndex: number): void {
        this.state.currentChoiceGameBuckets[bucketIndex].title = e.target.value;
        this.setState({});
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentChoiceGameTopics: topics,
            currentChoiceGameTags: topicsTags
        });
    }
    
    handleCardNumberChange(e: React.ChangeEvent<HTMLInputElement>, index: number): void {
        this.state.currentChoiceIncorrect[index] = e.target.value;
        this.setState({});
    }

    removeCard(cardIndex: number): void {
        this.state.currentChoiceGameCards.splice(cardIndex, 1);
        this.setState({});
    }

    handleCardTargetChange(e: React.ChangeEvent<HTMLInputElement>, cardIndex: number) {
        this.state.currentChoiceGameCards[cardIndex].title = e.target.value;
        this.setState({});
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId,
        });
    }

    dealWithChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let choiceGame = this.state.choiceGame;
        choiceGame.title = this.state.currentChoiceGameTitle;
        choiceGame.buckets = this.state.currentChoiceGameBuckets;
        choiceGame.cards = this.state.currentChoiceGameCards;
        choiceGame.subjectId = this.state.currentChoiceGameSubjectId;
        choiceGame.topics = this.state.currentChoiceGameTopics;
        choiceGame.topicTags = this.state.currentChoiceGameTags;
        choiceGame.instructions = this.state.currentChoiceGameInstructions;
        choiceGame.audioInstructions = this.state.currentChoiceGameAudioInstructions;
        choiceGame.timeMultiplier = this.state.timeMultiplier;
        choiceGame.schoolId = this.state.user.schoolId;
        choiceGame.type = 'CHOICE';
        let newChoiceGame = false;
        let gameId = this.state.gameId;
        if (this.state.gameId === "-1") {
            newChoiceGame = true;
            gameId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                choiceGame.schoolId = this.state.user.schoolId;
            }
        }
        try {
            await StoreLearningGame(newChoiceGame, choiceGame, gameId, this.state.pathId, this.state.currentChoiceGameSchoolId);

            this.props.history.replace(`/learningChoiceGame/${gameId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save Failed");
        }
        this.setState({
            gameId: gameId
        })
    }

    async componentDidMount(): Promise<void> {
        try {
            let initialChoiceCard = new ChoiceCard();
            initialChoiceCard.title = "";
            initialChoiceCard.bucketNumber = 0;
            if (this.state.gameId !== "-1") {
                let choiceGameSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
                let choiceGameData = choiceGameSnapshot.data();
                let choiceGame = ChoiceGame.fromFirebase(choiceGameData);
                this.setState({
                    choiceGame: choiceGame,
                    currentChoiceGameTitle: choiceGame.title,
                    currentChoiceGameSubjectId: choiceGame.subjectId,
                    currentChoiceGameTopics: choiceGame.topics,
                    currentChoiceGameTags: choiceGame.topicTags,
                    currentChoiceGameInstructions: choiceGame.instructions,
                    currentChoiceGameAudioInstructions: choiceGame.audioInstructions,
                    currentChoiceGameCards: choiceGame.cards,
                    currentChoiceIncorrect: choiceGame.incorrect,
                    currentChoiceGameBuckets: choiceGame.buckets,
                    currentChoiceGameSchoolId: choiceGame.schoolId == null ? null : choiceGame.schoolId,
                    timeMultiplier: choiceGame.timeMultiplier != null ? choiceGame.timeMultiplier : TIME_MULTIPLIER.moderate,
                });
            } else {
                let subjectId = '';
                if (this.state.worldId != null) {
                    const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                    let worldSnapshot = await worldRef.get();
                    let world = worldSnapshot.data();
                    subjectId = world.subject;
                } else {
                    //@ts-ignore
                    subjectId = this.state.subjectFilter;
                }
                this.setState({
                    choiceGame: new ChoiceGame(),
                    currentChoiceGameTitle: '',
                    currentChoiceGameInstructions: '',
                    currentChoiceGameSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                    currentChoiceGameAudioInstructions: '',
                    currentChoiceGameSubjectId: subjectId,
                    currentChoiceGameTopics: [],
                    currentChoiceGameCards: [new ChoiceCard()],
                    currentChoiceIncorrect: [],
                    currentChoiceGameBuckets: [new ChoiceBucket(), new ChoiceBucket()],
                    timeMultiplier: TIME_MULTIPLIER.moderate,
                    currentChoiceGameTags: new Map<string, string[]>(),
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

};
export default LearningChoiceGame;
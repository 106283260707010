import React, { Component } from 'react';
import firebase from '../firebase';
import queryString from 'query-string';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, UncontrolledTooltip, InputGroup, InputGroupAddon, Card, CardHeader, CardBody, ModalBody, ModalHeader, Modal, DropdownItem, Dropdown, DropdownToggle, DropdownMenu
} from 'reactstrap';
import { Link } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import { User, USER_ROLE } from '../data/user';
import { v4 as uuidv4 } from 'uuid';
import { Product, ProductCategory, ProductStub, ProductVariant } from '../data/marketplace';
import ImageUpload from '../ninja_components/image_upload';
import CostEntry from '../ninja_components/cost_entry';

interface IState {
    productName: string;
    productId: string;
    worldId: string;
    worldName: string;
    pathId: string;
    pathName: string;
    user: User;
    currentProductSchoolId: string;
    currentCost: number,
    currentProductId: string;
    currentProductTitle: string;
    currentProductDescription: string;
    currentProductImageUrl: string;
    currentProductImagePath: string;
    currentProductVariants: Map<string, ProductVariant>;
    currentProductVariantIdsSorted: Array<string>;
    currentProductPrice: Map<number, ProductVariant>;
    currentProductTotalStock: Map<number, ProductVariant>;
    currentProductCategories: string[],
    categories: Map<string, ProductCategory>;
    categoryIdsSorted: string[];
    modal: boolean,
    tempCategoryId: string,
}

interface MatchParams {
    productId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class ProductView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            productName: "",
            categories: new Map(),
            categoryIdsSorted: [],
            productId: props.match.params.productId,
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            currentProductTitle: '',
            currentProductDescription: '',
            currentProductImageUrl: '',
            currentProductImagePath: '',
            currentProductSchoolId: '',
            currentProductCategories: [],
            currentCost: 0,
            currentProductId: '',
            user: props.user,
            currentProductVariants: new Map(),
            currentProductVariantIdsSorted: [],
            currentProductPrice: new Map(),
            currentProductTotalStock: new Map(),
            modal: false,
            tempCategoryId: null,
        };

        this.handleUpdate = this.handleUpdate.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleImageChangeFail = this.handleImageChangeFail.bind(this);
        this.deleteProductVariant = this.deleteProductVariant.bind(this);
        this.handleCostChange = this.handleCostChange.bind(this);
        this.handleAddCategory = this.handleAddCategory.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.handleAddAnotherCategory = this.handleAddAnotherCategory.bind(this);
        this.moveVariantUp = this.moveVariantUp.bind(this);
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem> : null}
                        <BreadcrumbItem><Link to={'/products'}>All Products</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.currentProductTitle === '' ? "New product" : this.state.currentProductTitle}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Product</div>
                        <p className="cardSubTitle">A product shown on the marketplace. Use product options to list different sizes, colours etc</p>
                        <Form id="productUpdate" onSubmit={this.handleUpdate}></Form>
                        <FormGroup>
                            <Label for="currentProductTitle">Name</Label>
                            <Input form="productUpdate" required maxLength={30} type="text" name="currentProductTitle" onChange={(e) => this.setState({
                                currentProductTitle: e.target.value
                            })} value={this.state.currentProductTitle} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="currentProductDescription">Description</Label>
                            <Input form="productUpdate" maxLength={100} type="textarea" required name="currentProductDescription" onChange={(e) => this.setState({
                                currentProductDescription: e.target.value
                            })} value={this.state.currentProductDescription} />
                        </FormGroup>
                        <FormGroup>
                            {this.state.currentProductCategories.map((categoryId) => {
                                let productCategory = this.state.categories.get(categoryId);
                                if (productCategory == null) {
                                    return null
                                }
                                return <span><Button key={categoryId}
                                    color={'success'}
                                    onClick={() => this.removeCategory(categoryId)}>
                                    {productCategory.name}&nbsp;&nbsp;<b>X</b>
                                </Button>&nbsp;</span>;
                            })}
                            <Button className="altButton" type="button" onClick={this.addCategory}>Add Category</Button>
                        </FormGroup>
                        <ImageUpload callback={this.handleImageChange} schoolId={this.props.user.schoolId} failureCallback={this.handleImageChangeFail} filePath={"productImages"}
                            currentImage={{ url: this.state.currentProductImageUrl, path: this.state.currentProductImagePath }} />
                        <FormGroup>
                            <div className="cardTitle2">Options <i className="fas fa-info-cirle icons-info" id="help-marketplace" /></div>
                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-marketplace">
                                Sometimes the answers must be ordered (e.g. for subtraction) and sometimes not (e.g. for addition)
                                    </UncontrolledTooltip>
                            {this.state.currentProductVariantIdsSorted.map((nextProductVariantId, productIndex) => {
                                return <div className="border rounded form-margin">
                                    <div><b>Option {productIndex + 1}</b> {productIndex > 0 ? <Button type="button" color='link' onClick={() => this.deleteProductVariant(nextProductVariantId)}><i
                                        className="material-icons material-icons-xd">cancel</i></Button> : <span />} {productIndex > 0 ? <Button type="button" color='link' onClick={() => this.moveVariantUp(productIndex)}><i
                                            className="material-icons material-icons-xd">arrow_upward</i></Button> : <span />}</div>
                                    <div>Option Description</div>
                                    <Input form="productUpdate" type="text" required name={`currentProductVariantDescription-${nextProductVariantId}`} onChange={(e) => {
                                        this.state.currentProductVariants.get(nextProductVariantId).variantDescription = e.target.value;
                                        this.setState({
                                        })
                                    }} value={this.state.currentProductVariants.get(nextProductVariantId).variantDescription} />
                                    <br />
                                    <CostEntry callback={(newCost) => this.handleCostChange(newCost, nextProductVariantId)} enabled={true}
                                        currentCost={this.state.currentProductVariants.get(nextProductVariantId).price} />
                                    <div>Total Stock</div>
                                    <Input form="productUpdate" type="number" required name={`currentProductVariantTotalStock-${nextProductVariantId}`} onChange={(e) => {
                                        this.state.currentProductVariants.get(nextProductVariantId).totalStock = e.target.value === "" ? 0 : parseInt(e.target.value);
                                        this.setState({
                                        })
                                    }} value={this.state.currentProductVariants.get(nextProductVariantId).totalStock} />

                                    {/*<Button outline color='primary' onClick={() => this.deleteProductVariant(nextProductVariantId)}>Delete Product Variant</Button>*/}
                                </div>
                            })}
                            <Button className="altButton" onClick={() => this.addProductVariant()}>Add Product Option</Button>
                        </FormGroup>
                        <div>
                            <Button form="productUpdate" className="adminPagesButton">Create</Button>
                        </div>
                        <Modal isOpen={this.state.modal} toggle={this.addCategory}>
                            <ModalHeader toggle={this.addCategory}>Categories</ModalHeader>
                            <ModalBody>
                                <div className="border rounded form-margin">
                                    <Form onSubmit={this.handleAddCategory}>
                                        <FormGroup>
                                            <Input type="select" onChange={this.handleAddAnotherCategory}>
                                                <option value=""></option>
                                                {this.state.categoryIdsSorted.map((categoryId) => {
                                                    let productCategory = this.state.categories.get(categoryId);
                                                    return <option value={categoryId}>
                                                        {productCategory.name}
                                                    </option>
                                                })}
                                            </Input><br />
                                            <Button disabled={this.state.tempCategoryId == null || this.state.tempCategoryId === ""}>Add</Button>
                                            <Button style={{ position: 'fixed', right: '10%' }}>Create Categories</Button>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </ModalBody>
                        </Modal>
                    </CardBody>
                </Card>
            </div>
        )
    }

    removeCategory(categoryId: string): void {
        let index = this.state.currentProductCategories.indexOf(categoryId);
        this.state.currentProductCategories.splice(index, 1);
        this.setState({

        })
    }

    handleAddAnotherCategory(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            tempCategoryId: e.target.value
        })
    }

    handleAddCategory(e: React.FormEvent): void {
        e.preventDefault();
        this.state.currentProductCategories.push(this.state.tempCategoryId);
        this.setState({
            tempCategoryId: null,
            modal: false
        })
    }

    addCategory(): void {
        this.setState({
            modal: !this.state.modal
        })
    }

    handleCostChange(newCost: number, nextProductVariantId: string): void {
        this.state.currentProductVariants.get(nextProductVariantId).price = newCost;
        this.setState({
        })
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            pathId: this.state.pathId,
        })
    }

    deleteProductVariant(productVariantId: string): void {
        this.state.currentProductVariantIdsSorted.splice(this.state.currentProductVariantIdsSorted.indexOf(productVariantId, 1));
        this.state.currentProductVariants.get(productVariantId).totalStock = 0;
        this.state.currentProductVariants.get(productVariantId).removed = true;
        this.setState({});
    }

    addProductVariant(): void {
        let newProductVariant = new ProductVariant();
        let productVariantId = uuidv4();
        this.state.currentProductVariants.set(productVariantId, newProductVariant);
        this.state.currentProductVariantIdsSorted.push(productVariantId);
        this.setState({});
    }

    moveVariantUp(index: number): void {
        let value = this.state.currentProductVariantIdsSorted.splice(index, 1)[0];
        this.state.currentProductVariantIdsSorted.splice(index - 1, 0, value);
        this.setState({
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let product = new Product();
        product.name = this.state.currentProductTitle; // state value set above
        product.description = this.state.currentProductDescription; // state value set above
        product.variants = this.state.currentProductVariants;
        product.variantIdsSorted = this.state.currentProductVariantIdsSorted;
        product.imageUrl = this.state.currentProductImageUrl;
        product.imagePath = this.state.currentProductImagePath;
        product.categoryIds = this.state.currentProductCategories;

        let productId = this.state.productId;
        if (this.state.productId === "-1") {
            productId = uuidv4();
        }
        try {
            await this.storeProduct(product, productId);

            this.props.history.replace(`/product/${productId}`); // Update the browser url to the new product id
            this.props.snackbar(); // Tell user it was saved
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
        this.setState({
            productId: productId
        })
    }

    async handleImageChange(url: string, path: string,): Promise<void> {
        if (this.state.productId !== "-1") {
            let imageDeets = {
                imageUrl: url,
                imagePath: path,
                updateDate: firebase.firestore.FieldValue.serverTimestamp(),
            };
            let overviewPath = `marketplace/${this.props.user.schoolId}`;
            let productPath = `${overviewPath}/products/${this.state.productId}`;
            const productRef = firebase.firestore().doc(productPath);
            let batch = firebase.firestore().batch();
            try {
                batch.update(productRef, imageDeets);

                batch.set(firebase.firestore().doc(overviewPath), {
                    products: {
                        [this.state.productId]: {
                            imageUrl: url,
                            updateDate: firebase.firestore.FieldValue.serverTimestamp(),
                        }
                    }
                }, { merge: true });

                this.setState({
                    currentProductImageUrl: url,
                    currentProductImagePath: path,
                });

                await batch.commit();
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("update failed");
            }
        } else {
            this.setState({
                currentProductImageUrl: url,
                currentProductImagePath: path,
            });
        }
    }

    handleImageChangeFail(): void {
        this.props.snackbar("image upload failed");
    }

    removeProduct(productVariantId: string): void {
        this.state.currentProductVariants.delete(productVariantId);
        this.setState({});
    }

    handleProductVariantChange(e: React.ChangeEvent<HTMLInputElement>, productVariantId: string) {
        this.state.currentProductVariants.get(productVariantId).variantDescription = e.target.value;
        this.setState({});
    }

    async storeProduct(product: Product, productId: string) {
        let batch = firebase.firestore().batch();
        try {
            let overviewPath = `marketplace/${this.props.user.schoolId}`;
            let productPath = `${overviewPath}/products/${productId}`;

            batch.set(firebase.firestore().doc(productPath), product.toFirebase());

            let initialStock = 0;
            if (product.variants != null) {
                for (let nextVariant of product.variants.values()) {
                    initialStock += nextVariant.totalStock;
                }
            }

            let minimumPrice = null;
            if (product.variants != null) {
                for (let nextVariant of product.variants.values()) {
                    if (minimumPrice == null || minimumPrice > nextVariant.price) {
                        minimumPrice = nextVariant.price;
                    }
                }
            }

            let stub = new ProductStub();
            stub.imageUrl = product.imageUrl;
            stub.minimumPrice = minimumPrice;
            stub.name = product.name;
            stub.initialStock = initialStock;
            stub.categoryIds = product.categoryIds;

            batch.set(firebase.firestore().doc(overviewPath), {
                products: {
                    [productId]: stub.toFirebase(),
                }
            }, { merge: true });

            await batch.commit();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async componentDidMount(): Promise<void> {
        try {
            const marketplaceSnapshot = await firebase.firestore().doc(`marketplace/${this.props.user.schoolId}`).get();
            let marketplaceData = marketplaceSnapshot.data();

            let productCategories = new Map<string, ProductCategory>();
            if (marketplaceData != null && marketplaceData.categories != null) {
                for (let nextEntryId of Object.keys(marketplaceData.categories)) {
                    productCategories.set(nextEntryId, ProductCategory.fromFirebase(marketplaceData.categories[nextEntryId]));
                }
            }

            let categoryIdsSorted = Array.from(productCategories.keys());
            categoryIdsSorted.sort((id1, id2) => {
                let kd1 = productCategories.get(id1);
                let kd2 = productCategories.get(id2);
                return kd1.name.localeCompare(kd2.name);
            });

            if (this.state.productId !== "-1") {
                let productSnapshot = await firebase.firestore().doc(`marketplace/${this.props.user.schoolId}/products/${this.state.productId}`).get();
                let productData = productSnapshot.data();
                let product = Product.fromFirebase(productData);
                this.setState({
                    currentProductTitle: product.name,
                    currentProductDescription: product.description,
                    currentProductImageUrl: product.imageUrl,
                    currentProductImagePath: product.imagePath,
                    currentProductVariants: product.variants,
                    currentProductCategories: product.categoryIds == null ? [] : product.categoryIds,
                    currentProductVariantIdsSorted: product.variantIdsSorted == null ? Array.from(product.variants.keys()) : product.variantIdsSorted,
                    categories: productCategories,
                    categoryIdsSorted: categoryIdsSorted,
                });
            } else {
                this.addProductVariant();
                this.setState({
                    productName: "",
                    categories: productCategories,
                    categoryIdsSorted: categoryIdsSorted,
                });
            }
        } catch (error) {
            console.log(error);
        }

    }
}

export default ProductView;
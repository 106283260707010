import React, { Component } from 'react';
import queryString from 'query-string';
import { Redirect, RouteComponentProps } from 'react-router-dom'
import firebase from "./firebase";
import { User } from './data/user';

interface IState {
    redirect: boolean;
    success: boolean;
}

interface IProps extends RouteComponentProps {
    user: User;
}

class PaymentAuthResponseView extends Component<IProps, IState> {

    constructor(props:IProps) {
        super(props);
        this.state = {
            redirect: false,
            success: true,
        };
    }

    render(): JSX.Element {
        if (this.state.redirect) {
            return <Redirect to='/paymentSettings' />
        } else if (this.state.success) {
            return <p>Processing</p>
        } else {
            return <p>Request failed. Please try again.</p>
        }
    }

    async componentDidMount(): Promise<void> {
        const values = queryString.parse(this.props.location.search);
        let code = values.code;
        console.log(code);
        // Create an OAuth API client

        let idToken = await firebase.auth().currentUser.getIdToken();
        let authorization = 'Bearer ' + idToken;
        let response = await fetch('https://admin.lifeninja.net/adminRequests/obtainSquareToken', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            },
            body: JSON.stringify({
                code: code,
                schoolId: this.props.user.schoolId
            })
        });

        if (response.status === 200) {
            this.setState({
                redirect: true
            })
        } else {
            this.setState({
                success: false
            })
        }
    }
}
export default PaymentAuthResponseView;
import React, { Component } from 'react';
import firebase from './firebase';
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, FormGroup, Label, InputGroup, Input, InputGroupAddon, 
InputGroupText, Modal, ModalHeader, ModalBody, Form, Card, CardBody } from 'reactstrap';
import './ninja_components/image_upload';
import Compress from "compress.js";
import confirm from "reactstrap-confirm";
import { v4 as uuidv4 } from 'uuid';
import { User } from './data/user';
import { LibraryImage } from './data/image_library';

interface IState {
    images: Map<string, LibraryImage>;
    listSize: number;
    collapse: boolean;
    searchText: string;
    tempSearchText: string;
    showImageModal: boolean;
    showEditModal: boolean;
    currentImageId: string;
    currentImageName: string;
    uploaded: number;
    toUpload: number;
}

interface IProps {
    user: User;
    snackbar: (text?: string) => void;
}

class ImageLibraryView extends Component<IProps, IState> {
    imagesRef: firebase.database.Query;
    timeout: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);
        this.state = {
            images: new Map(),
            listSize: 10,
            collapse: true,
            searchText: '',
            tempSearchText: '',
            showImageModal: false,
            showEditModal: false,
            currentImageId: '',
            currentImageName: '',
            uploaded: 0,
            toUpload: 0,
        };
        this.imagesRef = null;

        this.removeItem = this.removeItem.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.toggle = this.toggle.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.toggleImageModal = this.toggleImageModal.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
    }

    render(): JSX.Element {
        let imageList: string[];
        if (this.state.searchText != null && this.state.searchText !== '') {
            let searchText = this.state.searchText.toLowerCase();
            imageList = [];
            for (let [imageId, image] of this.state.images) {
                if (image.getLowerCaseName().indexOf(searchText) !== -1) {
                    imageList.push(imageId);
                }
            }
        } else {
            imageList = Array.from(this.state.images.keys());
        }

        imageList.sort((acA, acB) => {
            return this.state.images.get(acA).getLowerCaseName().localeCompare(this.state.images.get(acB).getLowerCaseName());
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>Image library</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Image library</div>
                    <p className="cardSubTitle">All images that can be used in the learning modules & questions.</p>
                    <span className={"toggle-icon"}>
                            {this.state.toUpload === this.state.uploaded ?
                                <Button onClick={() => {
                                    this.toggleImageModal();
                                }} className="altButton">
                                    Add Image
                                </Button>
                                :
                                <FormGroup>
                                    <Label>Uploaded {this.state.uploaded} of {this.state.toUpload}</Label>
                                </FormGroup>
                            }
                            <a target="_blank" href="https://docs.google.com/document/d/1Pa8dhn3nPt796eiG2K6FcY8_Dpr-rw4wGmc7RKgk7GU/edit#">
                            <Button style={{marginLeft:"15px"}} className="altButton">Read Tutorial</Button>
                            </a>
                        </span>
                <br />
                <div>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText><span className="material-icons">search</span></InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={"Search images"} type="text" name="tempSearchText" onChange={evt => this.doSearch(evt)} value={this.state.tempSearchText} />
                    </InputGroup>
                </div>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Thumbnail</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {imageList.slice(0, this.state.listSize).map((imageId) => {
                            let image = this.state.images.get(imageId);
                            return <tr>
                                <th scope="row">{image.name}</th>
                                <td><img className="d-inline-block align-top" src={image.url} width="30" height="30" alt="" /></td>
                                <td><Button color="link" onClick={async () => {
                                    this.openEditModal(imageId);
                                }}><i className="material-icons">edit</i></Button>
                                    <Button color="link" onClick={async () => {
                                        let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this image", confirmText: "Confirm" });
                                        if (result) {
                                            this.removeItem(imageId)
                                        }
                                    }}><i className="material-icons">delete</i></Button></td>
                            </tr>
                        })}
                    </tbody>
                </Table>
                {this.state.listSize < imageList.length ?
                    <div>
                        <p>Showing {this.state.listSize} of {imageList.length}</p>
                        <Button color="primary" type="button"
                            onClick={() => {
                                this.setState({
                                    listSize: this.state.listSize + 10
                                })
                            }}>
                            Show more
                        </Button>&nbsp;&nbsp;
                        <Button className="adminPagesButton" type="button"
                            onClick={() => {
                                this.setState({
                                    listSize: 999999
                                })
                            }}>
                            Show all
                        </Button>
                    </div> :
                    <div>
                        <p>Showing {imageList.length}</p>
                    </div>
                }
                <br /><br />
                <Modal isOpen={this.state.showImageModal} toggle={this.toggleImageModal}>
                    <ModalHeader toggle={this.toggleImageModal}>Add images</ModalHeader>
                    <ModalBody>
                        <div>
                            <FormGroup>
                                <Label for="file">Upload from computer</Label>
                                <InputGroup>
                                    <Input type="file" id="file" name="file" onChange={this.handleFileUpload} multiple />
                                    <Label className="custom-file-label" for="file">Choose files</Label>
                                </InputGroup>
                            </FormGroup>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.showEditModal} toggle={this.toggleEditModal}>
                    <ModalHeader toggle={this.toggleEditModal}>Change name</ModalHeader>
                    <ModalBody>
                        <Form className="border rounded form-margin" onSubmit={this.handleNameChange}>
                            <FormGroup>
                                <Label for="currentImageName">Name</Label>
                                <Input type="text" required name="currentImageName" onChange={(e) => this.setState({
                                    currentImageName: e.target.value
                                })} value={this.state.currentImageName} />
                            </FormGroup>
                            <Button className="adminPagesButton">Update</Button>
                        </Form>
                    </ModalBody>
                </Modal>
                </CardBody>
                </Card>
            </div>
        );
    }

    async handleNameChange(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        const learningImageRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/learningImages/${this.state.currentImageId}/name`);
        try {
            await learningImageRef.set(this.state.currentImageName);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
        this.setState({ showEditModal: false });
    }

    doSearch(e: React.ChangeEvent<HTMLInputElement>): void {
        let newSearchText = e.target.value; // this is the search text
        if (this.timeout) clearTimeout(this.timeout);
        this.setState({
            tempSearchText: newSearchText.toLowerCase(),
        });
        this.timeout = setTimeout(() => {
            this.setState({
                searchText: this.state.tempSearchText,
            });
        }, 300);
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleImageModal(): void {
        this.setState({ showImageModal: !this.state.showImageModal });
    }

    openEditModal(imageId: string): void {
        this.setState({
            currentImageId: imageId,
            currentImageName: this.state.images.get(imageId).name,
            showEditModal: true
        });
    }

    toggleEditModal(): void {
        this.setState({ showEditModal: !this.state.showEditModal });
    }

    handleFileUpload(e: React.ChangeEvent<HTMLInputElement>): void {
        e.stopPropagation();
        e.preventDefault();
        const compress = new Compress();
        this.setState({
            uploaded: 0,
            toUpload: e.target.files.length
        });
        this.toggleImageModal();
        for (let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++) {
            let file = e.target.files[fileIndex];
            let metadata = {
                'contentType': file.type,
                customMetadata: {
                    schoolId: this.props.user.schoolId
                }
            };
            compress.compress([file], {
                size: 2,
                maxWidth: 1024,
            }).then((data) => {
                console.log("Compressing");
                let compressedFile = Compress.convertBase64ToFile(data[0].data, data[0].ext);
                // Push to child path.
                let path = `learningImages/${uuidv4()}-${file.name}`;
                firebase.storage().ref().child(path).put(compressedFile, metadata).then((snapshot) => {
                    console.log('Uploaded', snapshot.totalBytes, 'bytes.');
                    console.log('File metadata:', snapshot.metadata);
                    // Let's get a download URL for the file.
                    snapshot.ref.getDownloadURL().then(async (url) => {
                        let image = {
                            name: file.name,
                            path: path,
                            url: url,
                        };
                        const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/learningImages/`);
                        try {
                            await accountRef.push(image);
                            this.props.snackbar(`Saved ${file.name}`);
                        } catch (error) {
                            console.log(error);
                            this.props.snackbar(`Upload ${file.name} failed`);
                        }
                        this.setState({
                            uploaded: this.state.uploaded + 1,
                        });
                    });
                }).catch((error) => {
                    console.error('Upload failed:', error);
                    this.setState({
                        uploaded: this.state.uploaded + 1,
                    });
                    this.props.snackbar("Upload failed");
                })
            }).catch((error) => {
                console.error('Compress failed:', error);
                this.setState({
                    uploaded: this.state.uploaded + 1,
                });
                this.props.snackbar("Upload failed");
            });
        }
    }

    async componentDidMount(): Promise<void> {
        this.imagesRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/learningImages`).orderByChild("deleted").equalTo(null);
        try {
            this.imagesRef.on('value', (snapshot) => {
                let images = snapshot.val();
                let newState = new Map<string, LibraryImage>();
                for (let nextImageId in images) {
                    let nextImage = LibraryImage.fromFirebase(images[nextImageId]);
                    if (!nextImage.deleted) {
                        newState.set(nextImageId, nextImage);
                    }
                }
                this.setState({
                    images: newState
                });
            });
        } catch (error) {
            console.log(error);
        }
    }

    async componentWillUnmount(): Promise<void> {
        this.imagesRef.off();
    }

    async removeItem(imageId: string): Promise<void> {
        let image = this.state.images.get(imageId);
        try {
            if (image.path != null) {
                await firebase.storage().ref().child(image.path).delete();
            }

            const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/learningImages/${imageId}/deleted`);
            await accountRef.set(true);

            this.state.images.delete(imageId);
            this.setState({});

            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }
}

export default ImageLibraryView;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Card, CardBody } from 'reactstrap';
import { AccountGroup, AccountMinimised } from './data/accounts';
import { User } from './data/user';
import { ChatMessage, CHAT_MESSAGE_TYPE } from './data/chats';

interface IState {
    accountGroup: AccountGroup;
    accountGroupId: string;
    accountId: string;
    chatMessages: Map<string, ChatMessage>;
    chatMessageIdsOrdered: string[];
    remainingMessages: boolean;
    accessAllowed: boolean;
}

interface MatchParams {
    accountGroupId: string;
    accountId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    accountGroups: Map<string, AccountGroup>;
    accountsMinimised: Map<string, AccountMinimised>;
    snackbar: (text?: string) => void;
}

class ModeratorChatTranscriptView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            accountGroup: null,
            accountGroupId: props.match.params.accountGroupId,
            accountId: props.match.params.accountId,
            chatMessages: new Map(),
            chatMessageIdsOrdered: [],
            remainingMessages: false,
            accessAllowed: true,
        };
        this.deleteToggle = this.deleteToggle.bind(this);
        this.loadChatBatch = this.loadChatBatch.bind(this);
    }

    render(): JSX.Element {

        // let chatMessagesReversed = this.state.chatMessages.slice().reverse();
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/chats'}>All chats</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/chats/${this.state.accountGroupId}`}>{this.props.accountGroups.has(this.state.accountGroupId) ? this.props.accountGroups.get(this.state.accountGroupId).details.name : ""}</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/chats/${this.state.accountGroupId}/moderatorChats`}>Moderator chats</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.props.accountsMinimised.has(this.state.accountId) ? this.props.accountsMinimised.get(this.state.accountId).name : "User"}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Moderator Chat</div>
                        <p className="cardSubTitle">The private messages for {this.props.accountsMinimised.has(this.state.accountId) ? this.props.accountsMinimised.get(this.state.accountId).name : "the user"}.</p>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Author</th>
                                    <th>Text</th>
                                    <th>Timestamp</th>
                                    <th>Deleted</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.chatMessageIdsOrdered.map((messageId) => {
                                    let chatMessage = this.state.chatMessages.get(messageId);
                                    let chatText = chatMessage.text;
                                    let chatElement = null;
                                    if (chatMessage.type != null) {
                                        if (chatMessage.type === CHAT_MESSAGE_TYPE.IMAGE) {
                                            chatElement = <img src={chatMessage.text} height="80px" alt="" />;
                                        } else if (chatMessage.type === CHAT_MESSAGE_TYPE.MAP) {
                                            chatElement = <a href={"https://www.google.com/maps/search/?api=1&query=lifeninja&query_place_id=" + chatMessage.text}>Map</a>
                                        }
                                    }
                                    let dateString = new Date(chatMessage.timestamp);
                                    let author = this.props.accountsMinimised.get(chatMessage.accountId);
                                    let authorName = author == null ? "Removed" : author.name;
                                    return <tr>
                                        <th scope="row">{authorName}</th>
                                        <td className={"break-td"}>{chatElement != null ? chatElement : chatText}</td>
                                        <td>{dateString.toDateString()} {dateString.toLocaleTimeString()}</td>
                                        <td>{chatMessage.deleted ? "Yes" : ""}</td>
                                        <td>
                                            <Button className="adminPagesButton" onClick={() => { this.deleteToggle(messageId, !chatMessage.deleted) }}>
                                                <i className="material-icons">{chatMessage.deleted ? "delete_outline" : "delete"}</i>
                                            </Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        {this.state.remainingMessages && this.state.accessAllowed ?
                            <Button className="adminPagesButton" onClick={this.loadChatBatch}>
                                Show more
                    </Button> : <span />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    async deleteToggle(messageId: string, deleted: boolean): Promise<void> {
        const accountGroupRef = firebase.database().ref(`chats/groups/${this.props.user.schoolId}/${this.state.accountGroupId}/moderationGroups/${this.state.accountId}/messages/${messageId}/deleted`);
        try {
            await accountGroupRef.set(deleted);
            let message = this.state.chatMessages.get(messageId);
            message.deleted = deleted;
            this.setState({});
            this.props.snackbar(deleted ? "Deleted" : "Un-deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }

    async loadChatBatch() {
        let batchLength = 0;
        let accountGroupsRef = firebase.database().ref(`chats/groups/${this.props.user.schoolId}/${this.state.accountGroupId}/moderationGroups/${this.state.accountId}/messages`).orderByChild('timestamp');
        if (this.state.chatMessageIdsOrdered.length === 0) {
            batchLength = 20;
            accountGroupsRef = accountGroupsRef.limitToLast(batchLength);
        } else {
            batchLength = 10;
            let lastMessageId = this.state.chatMessageIdsOrdered[this.state.chatMessageIdsOrdered.length - 1];
            let lastTimestamp = this.state.chatMessages.get(lastMessageId).timestamp - 1;
            accountGroupsRef = accountGroupsRef.limitToLast(batchLength).endAt(lastTimestamp);
        }
        let snapshot = await accountGroupsRef.once('value');
        let newChatMessages = new Map<string, ChatMessage>();
        let remainingMessages = true;
        let newOrderedIds: string[] = [];
        snapshot.forEach(function (child) {
            let message = child.val();
            newOrderedIds.push(child.key);
            newChatMessages.set(child.key, ChatMessage.fromFirebase(message));
        });
        if (newOrderedIds.length < batchLength) {
            remainingMessages = false;
        }
        newOrderedIds.reverse();
        let chatMessageIdsOrdered = [...this.state.chatMessageIdsOrdered, ...newOrderedIds];

        let allChatMessages = new Map([...this.state.chatMessages, ...newChatMessages]);
        this.setState({
            chatMessageIdsOrdered: chatMessageIdsOrdered,
            chatMessages: allChatMessages,
            remainingMessages: remainingMessages,
        });
    }

    async componentDidMount(): Promise<void> {
        try {
            await this.loadChatBatch();
        } catch (error) {
            console.log(error)
        }
    }
}

export default ModeratorChatTranscriptView;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, FormGroup, Input, Label, Tag, Card, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";
import queryString from 'query-string';
import { subjectIds, questionType } from './Prettifier';
import { Question } from './data/learning_games';
import { User } from './data/user';
import { Subject, Topic } from './data/home_learning';

interface IState {
    questions: Map<string, Question>,
    questionIdsSorted: string[],
    moreQuestions: boolean;
    subjectTags: Map<string, Subject>,
    subjectFilter: string;
}

interface MatchParams {
    quizId: string;
    startQuiz: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class QuestionsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            questions: new Map(),
            questionIdsSorted: [],
            moreQuestions: true,
            subjectTags: new Map(),
            subjectFilter: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
        };
        this.removeItem = this.removeItem.bind(this);
        this.getNextQuestionsBatch = this.getNextQuestionsBatch.bind(this);
        this.makeShortString = this.makeShortString.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
    }

    render(): JSX.Element {

        let queryValues = this.getQuerys();

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All questions</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Custom Questions</div>
                    <p className="cardSubTitle">Questions created within your organisation that can be added to mini-quizzes or live quizzes</p>
                <FormGroup>
                    <Label for="subjectFilter">Subject filter</Label>

                    <Input type="select" name="subjectFilter"
                        onChange={this.updateFilter}
                        value={this.state.subjectFilter}>
                        <option value={''}>All</option>
                        {Array.from(subjectIds.keys()).map((key) => {
                            let name = subjectIds.get(key);
                            return (
                                <option value={key} key={key}>{name}</option>
                            )
                        })
                        }
                    </Input>
                </FormGroup>
                <div className="bottom-buffer">
                    <Link to={`/learningQuestions/-1?${queryValues}`}>
                        <Button className="adminPagesButton" type="button">
                            Create question
                        </Button>
                    </Link>
                    <a target="_blank" href="https://docs.google.com/document/d/1r654ISuEywFzzj_AQarQ1FAIC_1fK6dOUQFRtnVP7nA/edit#">
                        <Button style={{marginLeft:"15px"}} className="altButton">Read Tutorial</Button>
                    </a>
                </div>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Subject</th>
                            <th>Topic</th>
                            <th>Tags</th>
                            <th>Created/Updated</th>
                            <th>Type</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.questionIdsSorted.map((questionId) => {
                            let question = this.state.questions.get(questionId);
                            if (question == null) {
                                return "";
                            }

                            let typeName = questionType.get(question.questionType);

                            let topicName = "";
                            let tagsString = "";
                            let topicId = question.topics[0];
                            if (topicId != null) {
                                let topic = this.state.subjectTags.get(question.subjectId).topics.get(topicId);
                                if (topic != null) {
                                    topicName = topic.name;
                                    if (question.topicTags.has(topicId)) {
                                        for (let nextTagId of question.topicTags.get(topicId)) {
                                            if (tagsString !== "") {
                                                tagsString += ", ";
                                            }
                                            tagsString += `${topic.tags.get(nextTagId)}`;
                                        }
                                    }
                                }
                            }

                            return <tr>
                                <th scope="row"><Link to={`learningQuestions/${questionId}`}>{this.makeShortString(question.text)}</Link></th>
                                <td>{subjectIds.get(question.subjectId)}</td>
                                <td>{topicName}</td>
                                <td>{tagsString}</td>
                                <td>{question.updated == null ? "" : question.updated.toDate().toDateString()}</td>
                                <td>{typeName}</td>
                                <td><Button color="link" onClick={async () => {
                                    let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this question", confirmText: "Confirm" });
                                    if (result) {
                                        this.removeItem(questionId)
                                    }
                                }}><i className="material-icons">delete</i></Button></td>
                            </tr>
                        })}
                    </tbody>
                </Table><br />
                <p>Showing {this.state.questionIdsSorted.length}</p>
                {this.state.moreQuestions ?
                    <div>
                        <Button className="adminPagesButton" type="button"
                            onClick={() => {
                                this.getNextQuestionsBatch(false, this.state.subjectFilter);
                            }}>
                            Show more
                        </Button>
                    </div> : <span />
                }
                <br /><br /><br />
                </CardBody>
                </Card>
            </div>
        );
    }

    async updateFilter(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let newSubjectId = e.target.value;
        await this.getNextQuestionsBatch(true, newSubjectId);
        this.setState({
            subjectFilter: newSubjectId
        });
    }

    async removeItem(questionId: string): Promise<void> {
        try {
            await firebase.firestore().doc(`learningQuestions/${questionId}`).update({ deleted: true });

            this.state.questions.delete(questionId);

            this.setState({});
            this.props.snackbar("Deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
        });
    }

    makeShortString(description: string): string {
        if (description == null) {
            return "";
        }
        if (description.length < 60) {
            return description;
        }
        let descriptionResult = description.substr(0, 30);
        let spacePos = descriptionResult.lastIndexOf(" ");
        if (spacePos !== -1) {
            descriptionResult = descriptionResult.substr(0, spacePos);
        }
        descriptionResult += "...";

        return descriptionResult;
    }

    componentDidMount(): void {
        this.getNextQuestionsBatch(true, this.state.subjectFilter);
    }

    async getNextQuestionsBatch(seed: boolean, subjectFilter: string): Promise<void> {
        if (seed != null && seed) {
            this.setState({
                questions: new Map(),
                questionIdsSorted: [],
                moreQuestions: true,
            });
        }
        let questionsRef = firebase.firestore().collection(`learningQuestions`).where("deleted", "==", null).where("schoolId", "==", this.props.user.schoolId);

        if (this.state.subjectFilter !== "") {
            questionsRef = questionsRef.where("subjectId", "==", this.state.subjectFilter);
        }
        questionsRef = questionsRef.orderBy("updated", "desc")
        if (seed == null || !seed) {
            // Find last question
            let lastId = this.state.questionIdsSorted[this.state.questionIdsSorted.length - 1];
            if (lastId != null) {
                questionsRef = questionsRef.startAt(this.state.questions.get(lastId).updated);
            }
            questionsRef = questionsRef.limit(11);
        } else {
            questionsRef = questionsRef.limit(10);
        }
        let collection = await questionsRef.get();
        let newMoreQuestions = this.state.moreQuestions;
        let questionDocs = collection.docs;
        let newQuestions = new Map<string, Question>();
        let newQuestionIdsOrdered = new Array<string>();
        if (questionDocs != null) {
            for (let nextQuestionDoc of questionDocs) {
                let nextQuestion = Question.fromFirebase(nextQuestionDoc.data());
                if (!this.state.questions.has(nextQuestionDoc.id)) {
                    newQuestionIdsOrdered.push(nextQuestionDoc.id);
                    newQuestions.set(nextQuestionDoc.id, nextQuestion);
                    if (!this.state.subjectTags.has(nextQuestion.subjectId)) {
                        let subjectTagDoc = await firebase.firestore().doc(`learningTopics/${nextQuestion.subjectId}`).get();
                        this.state.subjectTags.set(nextQuestion.subjectId, Subject.fromFirebase(subjectTagDoc.data()));
                    }
                }
            }
        }

        if (newQuestions.size < 10) {
            newMoreQuestions = false;
        }
        let questions = new Map([...this.state.questions, ...newQuestions]);

        let questionIdsSorted = [...this.state.questionIdsSorted, ...newQuestionIdsOrdered];
        this.setState({
            questionIdsSorted: questionIdsSorted,
            questions: questions,
            moreQuestions: newMoreQuestions,
        });
    }
}

export default QuestionsView;
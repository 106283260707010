import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import RootView from "./root_view";
import {
    Card, CardBody,
    CardSubtitle, Button, CardTitle, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, CardHeader, Collapse, Modal, ModalHeader, ModalBody,
    UncontrolledTooltip,
} from 'reactstrap';
import AttachmentUpload from "./ninja_components/attachment_upload";
import { Attachment } from './data/home_learning';
import IconSelection from "./ninja_components/icon_selection";
import confirm from "reactstrap-confirm";
import TopicsSelector from "./ninja_components/topics_selector";
import TopicsSearch from "./ninja_components/topics_search";
import { StoreLearningGame, StoreChangeHistory, searchQuestions, QuestionSearchStub } from "./helpers/topic_services";
import { TIME_MULTIPLIER, QUESTION_TYPE, Quizlet, Question } from './data/learning_games';
import { User, USER_ROLE } from './data/user';
import { v4 as uuidv4 } from 'uuid';
import { questionType, imageName, soundNames, subjectIds } from './Prettifier';

interface IState {
    worldId: string;
    worldName: string;
    pathId: string;
    pathName: string;
    subjectFilter: string;
    gameId: string;
    timeMultiplier: TIME_MULTIPLIER;
    quizlet: Quizlet;
    currentLearningQuizletName: string;
    currentLearningQuizletDescription: string;
    currentLearningQuizletImage: string;
    currentTopics: string[];
    currentTopicsTags: Map<string, string[]>;
    currentLearningQuizletIcon: string;
    currentLearningQuizletIconFamily: string;
    currentLearningQuizletHidden: boolean;
    currentLearningQuizletSchoolId: string;
    currentLearningQuizletLicenseId: string;
    currentLearningQuizletVideoUrl: string;
    currentLearningQuizletSound: string;
    currentLearningQuizletQuestionIds: string[];
    currentLearningQuizletAttachments: Attachment[];
    user: User;
    collapse: boolean;
    showQuestionSelect: boolean;
    currentSearchQuestionIds: string[];
    currentSearchSchoolQuestionIds: string[];
    currentSubjectId: string;
    currentSearchTopic: string;
    currentSearchTopicTags: string[][];
    currentAllTopicQuestions: Map<string, QuestionSearchStub>;
    currentAllTopicSchoolQuestions: Map<string, QuestionSearchStub>;
    questions: Map<string, Question>;
    searched: boolean;
    showIconModal: boolean;
}

interface MatchParams {
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class LearningQuizletView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            gameId: props.match.params.gameId,
            timeMultiplier: TIME_MULTIPLIER.moderate,
            quizlet: null,
            currentLearningQuizletName: '',
            currentLearningQuizletDescription: '',
            currentLearningQuizletImage: '',
            currentTopics: [],
            currentTopicsTags: new Map<string, string[]>(),
            currentLearningQuizletIcon: null,
            currentLearningQuizletIconFamily: '',
            currentLearningQuizletHidden: false,
            currentLearningQuizletSchoolId: '',
            currentLearningQuizletLicenseId: null,
            currentLearningQuizletVideoUrl: null,
            currentLearningQuizletSound: '',
            currentLearningQuizletQuestionIds: [],
            currentLearningQuizletAttachments: [],
            user: props.user,
            collapse: props.match.params.gameId !== "-1",
            showQuestionSelect: false,
            currentSearchQuestionIds: [],
            currentSearchSchoolQuestionIds: [],
            currentSubjectId: '',
            currentSearchTopic: null,
            currentSearchTopicTags: [],
            currentAllTopicQuestions: new Map(),
            currentAllTopicSchoolQuestions: new Map(),
            questions: null,
            searched: false,
            showIconModal: false,
        };

        this.dealWithChange = this.dealWithChange.bind(this);
        this.handleIconChange = this.handleIconChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.createCard = this.createCard.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleAttachmentChange = this.handleAttachmentChange.bind(this);
        this.handleAttachmentChangeFail = this.handleAttachmentChangeFail.bind(this);
        this.toggleQuestionSelect = this.toggleQuestionSelect.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.handleSearchTopicsChange = this.handleSearchTopicsChange.bind(this);
        this.searchQuestions = this.searchQuestions.bind(this);
        this.handleQuestionAdd = this.handleQuestionAdd.bind(this);
    }

    createCard(nextQuestionId: string, queryValues: string): JSX.Element {
        let nextQuestion = this.state.questions.get(nextQuestionId);
        let editable = nextQuestion.schoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);
        let quizletEditable = this.state.currentLearningQuizletSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);
        return <Card style={{ width: "100%" }}>
            <CardBody className="d-flex flex-column">
                <CardTitle>{nextQuestion.text}<br />{!editable ? <span style={{ "color": "red" }}>View only</span> : ""}</CardTitle>
                <CardSubtitle>Correct answer{nextQuestion.answers.length > 1 ? "s" : ""}: {nextQuestion.answers.join(", ")}</CardSubtitle><br />
                <p>
                    Type: {questionType.get(nextQuestion.questionType)}<br /><br />
                    Explainer: {nextQuestion.explainer}<br /><br />
                    Incorrect answers: {nextQuestion.choices == null ? "" : nextQuestion.choices.map((choiceString, index) => {
                    return <div key={index}>- {choiceString}</div>
                })
                    }
                </p>
                <div className="mt-auto">
                    <Link to={`/learningQuestions/${nextQuestionId}?${queryValues}`}>
                        <Button block outline color="primary" type="button">
                            Open
                        </Button>
                    </Link><br />
                    {quizletEditable ?
                        <Button block outline color="danger" onClick={async () => {
                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove this question from the quizlet", confirmText: "Confirm" });
                            if (result) {
                                this.removeItem(nextQuestionId)
                            }
                        }}>Remove</Button> : <span />
                    }
                </div>
            </CardBody>
        </Card>
    }


    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let rows = RootView.createCardRows(this.state.currentLearningQuizletQuestionIds, this.createCard, queryValues);

        let editable = this.state.currentLearningQuizletSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All minigames</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem> : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem> : null}
                        <BreadcrumbItem active>Quizlet</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Learning quizlet</div>
                        <p className="cardSubTitle">A quizlet is a set of closely related questions for a player to attempt.</p>
                        {(this.state.quizlet != null) ?
                            <div>
                                <Button onClick={this.toggle} className="altButton">
                                    {this.state.collapse ? "View details" : "Close"}
                                </Button>
                                <Collapse isOpen={!this.state.collapse}>

                                    <Form onSubmit={this.handleUpdate}>
                                        <fieldset disabled={!editable}>
                                            <FormGroup>
                                                <Label for="currentLearningQuizletName">Title</Label>
                                                <Input maxLength={30} type="text" required name="currentLearningQuizletName" onChange={(e) => this.setState({
                                                    currentLearningQuizletName: e.target.value
                                                })} value={this.state.currentLearningQuizletName} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentLearningQuizletDescription">Instructions</Label>
                                                <Input type="text" required className="text-textarea" name="currentLearningQuizletDescription" onChange={(e) => this.setState({
                                                    currentLearningQuizletDescription: e.target.value
                                                })} value={this.state.currentLearningQuizletDescription} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentSubjectId">Subject *</Label>
                                                <Input disabled={this.state.worldId != null || this.state.gameId !== "-1"} type="select" name="currentSubjectId"
                                                    onChange={(e) => this.setState({
                                                        currentSubjectId: e.target.value
                                                    })}
                                                    value={this.state.currentSubjectId}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                                        let subjectName = subjectIds.get(subjectId);
                                                        return <option value={subjectId}>{subjectName}</option>;
                                                    })}
                                                </Input>
                                            </FormGroup>
                                            {this.state.currentSubjectId === '' ? <div></div> :
                                                <TopicsSelector key={this.state.gameId} countryCode={this.state.user.countryCode} subject={this.state.currentSubjectId} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags} callback={this.handleTopicsChange} />
                                            }
                                            <label>
                                                Game speed <i className="fas fa-info-circle icons-info" id="help-learning-timemultiplier" />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learning-timemultiplier">
                                                    Control how fast or slow the game will play.
                                                    Overall paths can have speed controls and the game speed will work within the context of the path speed control.
                                                    For example, a game with a 'fast' speed on a path with a 'slow' speed will play at 'moderate' speed.
                                                    Use this to allow developing or advanced students to play at a speed that suits them.
                                        </UncontrolledTooltip>
                                            </label>
                                            <Input type="select" value={this.state.timeMultiplier} onChange={this.dealWithChange}>
                                                <option value="fastest">Fastest</option>
                                                <option value="fast">Fast</option>
                                                <option value="moderate">Moderate</option>
                                                <option value="slow">Slow</option>
                                                <option value="slowest">Slowest</option>
                                            </Input>
                                            <FormGroup>
                                                <Label for="currentLearningQuizletImage">Image</Label>
                                                <Input type="select" name="currentLearningQuizletImage"
                                                    onChange={(e) => this.setState({
                                                        currentLearningQuizletImage: e.target.value
                                                    })}
                                                    value={this.state.currentLearningQuizletImage}>
                                                    {Array.from(imageName.keys()).map((key) => {
                                                        let name = imageName.get(key);
                                                        return (
                                                            <option value={key} key={key}>{name}</option>
                                                        )
                                                    })
                                                    }
                                                </Input><br />
                                                <img src={'/' + this.state.currentLearningQuizletImage} height="80px" alt="" /><br /><br />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentLearningQuizletVideoUrl">Video (YouTube video id)</Label>
                                                <Input type="text" name="currentLearningQuizletVideoUrl" onChange={(e) => this.setState({
                                                    currentLearningQuizletVideoUrl: e.target.value
                                                })} value={this.state.currentLearningQuizletVideoUrl} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentLearningQuizletSound">Audio instruction *</Label>
                                                <Input type="select" name="currentLearningQuizletSound"
                                                    onChange={(e) => this.setState({
                                                        currentLearningQuizletSound: e.target.value
                                                    })}
                                                    value={this.state.currentLearningQuizletSound}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(soundNames.keys()).map((key) => {
                                                        let name = soundNames.get(key);
                                                        return (
                                                            <option value={key} key={key}>{name}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                            </FormGroup>
                                            <IconSelection callback={this.handleIconChange} currentIcon={this.state.currentLearningQuizletIcon} currentIconFamily={this.state.currentLearningQuizletIconFamily} />
                                            <AttachmentUpload callback={this.handleAttachmentChange} failureCallback={this.handleAttachmentChangeFail} filePath={"moduleAttachments"}
                                                schoolId={this.state.user.schoolId} currentAttachments={this.state.currentLearningQuizletAttachments} />

                                            {this.state.user.role === USER_ROLE.admin ?
                                                <FormGroup>
                                                    <Label for="currentLearningQuizletLicenseId">License id</Label>
                                                    <Input type="text" name="currentLearningQuizletLicenseId" onChange={(e) => this.setState({
                                                        currentLearningQuizletLicenseId: e.target.value
                                                    })} value={this.state.currentLearningQuizletLicenseId} />
                                                </FormGroup> : <span />
                                            }
                                            {editable ? this.state.gameId === "-1" ?
                                                <Button disabled={this.state.currentSubjectId === ''} className="adminPagesButton" >Create</Button> : <Button className="adminPagesButton" >Update</Button> : <span />
                                            }
                                        </fieldset>
                                    </Form>
                                </Collapse>
                            </div> : <span />
                        }
                    </CardBody></Card>

                            {this.state.quizlet == null || this.state.gameId === "-1" ? <br /> :
                                                    <Card className="mainCard top-buffer">
                                                    <CardBody className="d-flex flex-column">
                                    <div className="cardTitle2">Questions</div>
                                    {editable ?
                                        <div className="top-buffer bottom-buffer">
                                            <Link to={'/learningQuestions/-1?' + queryValues}>
                                                <Button className="adminPagesButton" type="button">
                                                    Create question
                                            </Button>
                                            </Link>&nbsp;
                                        <Button onClick={this.toggleQuestionSelect} className="adminPagesButton" type="button">
                                                Add question
                                        </Button>&nbsp;
                                        {/* <Link to={'/moduleCsv?' + queryValues}>
                                            <Button color="primary" type="button">
                                                Create questions via csv
                                            </Button>
                                        </Link> */}
                                        </div> : <span />
                                    }
                                    <div>
                                        {rows}
                                    </div>
                                    <br />
                                </CardBody>
                                </Card>
                            }
                            <Modal isOpen={this.state.showQuestionSelect} toggle={this.toggleQuestionSelect}>
                                <ModalHeader toggle={this.toggleQuestionSelect}>Select question</ModalHeader>
                                <ModalBody>
                                    <div className="border rounded form-margin">
                                        <TopicsSearch key={this.state.gameId} countryCode={this.state.user.countryCode} worldId={this.state.worldId} callback={this.handleSearchTopicsChange} />
                                        <br />
                                        <Button disabled={this.state.currentSearchTopic === ""} color="primary" onClick={this.searchQuestions}>
                                            Search
                                    </Button>
                                        <br /><br />
                                        {!this.state.searched ? <span /> :
                                            <div>
                                                {this.state.currentSearchSchoolQuestionIds == null || this.state.currentSearchSchoolQuestionIds.length === 0 ? <div><b>Custom questions</b><br />No results</div> :
                                                    <div><b>Custom questions</b><br />
                                                        {this.state.currentSearchSchoolQuestionIds.map((questionId) => {
                                                            let hideAdd = this.state.currentLearningQuizletQuestionIds.indexOf(questionId) != -1;
                                                            return <div>{this.state.currentAllTopicSchoolQuestions.get(questionId).text}  ({this.state.currentAllTopicSchoolQuestions.get(questionId).type[0].toUpperCase()}{this.state.currentAllTopicSchoolQuestions.get(questionId).type.slice(1).toLowerCase()})
                                            <Button disabled={hideAdd} color={'link'} onClick={() => this.handleQuestionAdd(questionId)}>
                                                                    <i className="material-icons">add</i>
                                                                </Button>
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                                {this.state.currentSearchQuestionIds == null || this.state.currentSearchQuestionIds.length === 0 ? <div><b>Library questions</b><br />No results</div> :
                                                    <div><b>Library questions</b><br />
                                                        {this.state.currentSearchQuestionIds.map((questionId) => {
                                                            let hideAdd = this.state.currentLearningQuizletQuestionIds.indexOf(questionId) != -1;
                                                            return <div>{this.state.currentAllTopicQuestions.get(questionId).text}  ({this.state.currentAllTopicQuestions.get(questionId).type[0].toUpperCase()}{this.state.currentAllTopicQuestions.get(questionId).type.slice(1).toLowerCase()})
                                            <Button disabled={hideAdd} color={'link'} onClick={() => this.handleQuestionAdd(questionId)}>
                                                                    <i className="material-icons">add</i>
                                                                </Button>
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </ModalBody>
                </Modal> : <div />
            </div>
        );
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    async handleQuestionAdd(questionId: string): Promise<void> {
        let questionRef = firebase.firestore().doc(`learningQuestions/${questionId}`);
        let questionSnapshot = await questionRef.get();
        let questionData = questionSnapshot.data();
        let question = Question.fromFirebase(questionData);
        this.state.questions.set(questionId, question);
        this.state.currentLearningQuizletQuestionIds.push(questionId);
        await firebase.firestore().doc(`learningGames/${this.state.gameId}`).set({ questionIds: this.state.currentLearningQuizletQuestionIds }, { merge: true });
        await StoreChangeHistory(false, this.state.gameId, this.state.pathId, this.state.currentLearningQuizletSchoolId);
        this.setState({});
    }

    handleSearchTopicsChange(topicId: string, topicsTags: string[][]): void {
        let newTopicTags = topicsTags == null ? [] : topicsTags;
        this.setState({
            currentSearchTopic: topicId,
            currentSearchTopicTags: newTopicTags,
            searched: false,
        });
    }

    async searchQuestions(): Promise<void> {
        let response = await searchQuestions(this.state.currentSearchTopic, this.state.currentSearchTopicTags, this.state.user.schoolId)
        let newQuestionIds = response.questionIds;
        newQuestionIds.sort((id1, id2) => {
            return response.allTopicQuestions.get(id1).text.localeCompare(response.allTopicQuestions.get(id2).text);
        });
        let newSchoolQuestionIds = response.schoolQuestionIds;
        newSchoolQuestionIds.sort((id1, id2) => {
            return response.allTopicSchoolQuestions.get(id1).text.localeCompare(response.allTopicSchoolQuestions.get(id2).text);
        });
        this.setState({
            searched: true,
            currentSearchQuestionIds: newQuestionIds,
            currentAllTopicQuestions: response.allTopicQuestions,
            currentSearchSchoolQuestionIds: newSchoolQuestionIds,
            currentAllTopicSchoolQuestions: response.allTopicSchoolQuestions,
        });
    }

    handleAttachmentChange(attachments: Attachment[]): void {
        this.setState({
            currentLearningQuizletAttachments: attachments,
        });
    }

    handleAttachmentChangeFail(error: any): void {
        console.log("Attachment failure", error);
        this.props.snackbar("Attachment upload failed");
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleQuestionSelect(): void {
        this.setState({
            showQuestionSelect: !this.state.showQuestionSelect,
            currentSearchQuestionIds: [],
            searched: false,
        });
    }

    showIconModal(): void {
        this.setState({
            showIconModal: !this.state.showIconModal
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId,
            gameName: this.state.currentLearningQuizletName,
            gameType: 'QUIZLET',
        });
    }

    handleIconChange(currentIcon: string, currentIconFamily: string): void {
        this.setState({
            currentLearningQuizletIcon: currentIcon,
            currentLearningQuizletIconFamily: currentIconFamily,
        });
    }

    dealWithChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let quizlet = this.state.quizlet;
        quizlet.title = this.state.currentLearningQuizletName;
        quizlet.instructions = this.state.currentLearningQuizletDescription;
        quizlet.image = this.state.currentLearningQuizletImage;
        quizlet.iconCodePoint = 59737;
        quizlet.iconCodeString = this.state.currentLearningQuizletIcon;
        quizlet.iconFontFamily = this.state.currentLearningQuizletIconFamily;
        quizlet.hidden = this.state.currentLearningQuizletHidden;
        quizlet.videoUrl = this.state.currentLearningQuizletVideoUrl;
        quizlet.sound = this.state.currentLearningQuizletSound;
        quizlet.attachments = this.state.currentLearningQuizletAttachments;
        quizlet.subjectId = this.state.currentSubjectId;
        quizlet.topics = this.state.currentTopics;
        quizlet.topicTags = this.state.currentTopicsTags;
        quizlet.licenseId = this.state.currentLearningQuizletLicenseId !== "" ? this.state.currentLearningQuizletLicenseId : null;
        quizlet.questionIds = this.state.currentLearningQuizletQuestionIds;
        quizlet.timeMultiplier = this.state.timeMultiplier;
        quizlet.deleted = null;
        quizlet.type = Quizlet.TYPE;
        if (quizlet.iconFontFamily === "MaterialIcons") {
            quizlet.iconFontPackage = null;
        } else {
            quizlet.iconFontPackage = "font_awesome_flutter";
        }
        let newQuizlet = false;
        let gameId = this.state.gameId;
        if (this.state.gameId === "-1") {
            newQuizlet = true;
            gameId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                quizlet.schoolId = this.state.user.schoolId;
            }
        }
        try {
            await StoreLearningGame(newQuizlet, quizlet, gameId, this.state.pathId, this.state.currentLearningQuizletSchoolId);

            this.props.history.replace(`/learningQuizlets/${gameId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }

        this.setState({
            gameId: gameId,
        })
    }

    async componentDidMount(): Promise<void> {
        if (this.state.gameId !== "-1") {
            let quizletSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
            let quizletData = quizletSnapshot.data();
            let quizlet = Quizlet.fromFirebase(quizletData);
            let quizletQuestions = new Map<string, Question>();
            let promises = [];
            for (let nextQuestionIndex in quizlet.questionIds) {
                let nextQuestionId = quizlet.questionIds[nextQuestionIndex];
                console.log("Getting question", nextQuestionId);
                const questionRef = firebase.firestore().doc(`learningQuestions/${nextQuestionId}`);
                promises.push(new Promise<void>((resolve, reject) => {
                    questionRef.get().then((questionSnapshot) => {
                        let questionData = questionSnapshot.data();
                        let question: Question = Question.fromFirebase(questionData);
                        let nextQuizletQuestion = question;
                        quizletQuestions.set(nextQuestionId, nextQuizletQuestion);
                        resolve();
                    }, (error) => {
                        console.log(error);
                        reject();
                    })
                }));
            }
            await Promise.all(promises);
            this.setState({
                quizlet: quizlet,
                currentLearningQuizletName: quizlet.title,
                currentLearningQuizletDescription: quizlet.instructions,
                currentLearningQuizletImage: quizlet.image,
                currentSubjectId: quizlet.subjectId,
                currentTopics: quizlet.topics == null ? [] : quizlet.topics,
                currentTopicsTags: quizlet.topicTags == null ? new Map<string, string[]>() : quizlet.topicTags,
                currentLearningQuizletIcon: quizlet.iconCodeString == null ? "question_answer" : quizlet.iconCodeString,
                currentLearningQuizletIconFamily: quizlet.iconFontFamily,
                currentLearningQuizletHidden: quizlet.hidden,
                currentLearningQuizletSchoolId: quizlet.schoolId,
                currentLearningQuizletLicenseId: quizlet.licenseId != null ? quizlet.licenseId : null,
                currentLearningQuizletVideoUrl: quizlet.videoUrl != null ? quizlet.videoUrl : null,
                currentLearningQuizletSound: quizlet.sound == null ? '' : quizlet.sound,
                currentLearningQuizletQuestionIds: quizlet.questionIds != null ? quizlet.questionIds : [],
                currentLearningQuizletAttachments: quizlet.attachments != null ? quizlet.attachments : [],
                timeMultiplier: quizlet.timeMultiplier != null ? quizlet.timeMultiplier : TIME_MULTIPLIER.moderate,
                questions: quizletQuestions,
            });
        } else {
            let subjectId = '';
            if (this.state.worldId != null) {
                const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                let worldSnapshot = await worldRef.get();
                let world = worldSnapshot.data();
                subjectId = world.subject;
            } else {
                // @ts-ignore
                subjectId = this.state.subjectFilter;
            }
            this.setState({
                quizlet: new Quizlet(),
                currentLearningQuizletName: '',
                currentLearningQuizletDescription: '',
                currentLearningQuizletImage: Array.from(imageName.keys())[0],
                currentSubjectId: subjectId,
                currentTopics: [],
                currentTopicsTags: new Map<string, string[]>(),
                currentLearningQuizletIcon: "question_answer",
                currentLearningQuizletIconFamily: "MaterialIcons",
                currentLearningQuizletHidden: false,
                currentLearningQuizletSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                currentLearningQuizletLicenseId: null,
                currentLearningQuizletVideoUrl: null,
                currentLearningQuizletSound: '',
                currentLearningQuizletQuestionIds: [],
                currentLearningQuizletAttachments: [],
                timeMultiplier: TIME_MULTIPLIER.moderate,
                questions: new Map<string, Question>(),
            });
        }
    }

    removeItem(questionId: string): void {
        let questionIndex = this.state.currentLearningQuizletQuestionIds.indexOf(questionId);
        this.state.currentLearningQuizletQuestionIds.splice(questionIndex, 1);
        this.state.quizlet.questionIds = this.state.currentLearningQuizletQuestionIds;

        const quizletRef = firebase.firestore().doc(`learningGames/${this.state.gameId}`);
        quizletRef.update({ questionIds: this.state.currentLearningQuizletQuestionIds }).then((error) => {
            if (error == null) {
                this.setState({
                });
                this.props.snackbar();
            } else {
                console.log(error);
                this.props.snackbar("Remove failed");
            }
        });
    }
}

export default LearningQuizletView;
export class User {

    constructor() {
        this.roles = new Map();
    }

    title: string;
    personalName: string;
    familyName: string;
    name: string;
    schoolId: string;
    schoolName: string;
    role: USER_ROLE;
    roles: Map<USER_ROLE, boolean>;
    countryCode: string;
    permissions: string[];
    accountId: string;
    subAccountId: string;
    schoolAvatarUrl: string;
    avatarUrl: string;
    email: string;
    tutorials: string[];
    accountIds: Map<string, UserAccount>;
    switchSchool: boolean; // I THink this is whether we are switching schools
    currency: string;
    schoolVerified: boolean;
    provider: string;
    schoolType: string;
}

export interface UserAccount {
    schoolId: string;
    subAccountId: string;
}

export enum USER_ROLE {
    admin = "admin", schoolAdmin = "schoolAdmin", learningCreator = "learningCreator", tournamentManager = "tournamentManager",
}
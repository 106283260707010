import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Spinner, Container,Alert
} from 'reactstrap';
import { sendVerificationEmail } from "./helpers/email_services";

interface IState {
    sendingVerificationEmail: boolean;
            checkingVerified: boolean;
}

interface IProps {
    snackbar: (text?: string) => void;
    callback: () => void;
}

class AccountVerificationView extends Component<IProps, IState> {
    firebaseUser: firebase.User;
    interval: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);
        this.state = {
            sendingVerificationEmail: false,
            checkingVerified: false,
        };

        this.checkVerificationStatus = this.checkVerificationStatus.bind(this);
    }

    render(): JSX.Element {

        return <Container>
            <Alert color="dark">
                <h3>Email verification</h3>
                <p>A verification email has been sent to prove that {this.firebaseUser != null ? this.firebaseUser.email : "this"} is your email address.</p>
            </Alert>

            <p>Please check your email inbox or spam folder for an email from Life Ninja</p>
            <Button disabled={this.firebaseUser == null} style={{ color: "#FFFFFF", backgroundColor: "#a5d118", borderRadius: '15px 15px 15px 15px', borderColor: "#a5d118" }} type="button" onClick={async () => {
                this.setState({
                    sendingVerificationEmail: true,
                });
                try {
                    await sendVerificationEmail(this.firebaseUser.email, this.props.snackbar);
                } finally {
                    this.setState({
                        sendingVerificationEmail: false,
                    });
                }
            }}>
                {this.state.sendingVerificationEmail ? <Spinner size="sm"></Spinner> :
                    <span className="material-icons material-icons-xd">email</span>}&nbsp;&nbsp;Send again
            </Button>
            <br /><br />
            Current status: {this.firebaseUser == null || this.firebaseUser.emailVerified ? "Verified" : <span>Not verified&nbsp;<Button disabled={this.state.checkingVerified} onClick={this.checkVerificationStatus}>{this.state.checkingVerified ? <Spinner size="sm"></Spinner> : <span />}Check now</Button></span>}
        </Container>
    }

    async checkVerificationStatus(): Promise<void> {
        this.setState({
            checkingVerified: true,
        });
        await this.firebaseUser.getIdToken(true);
        await this.firebaseUser.reload();
        if (this.firebaseUser.emailVerified) {
            clearInterval(this.interval);
            this.props.callback();
        } else {
            this.setState({
                checkingVerified: false,
            })
        }
    }

    async componentDidMount(): Promise<void> {
        this.firebaseUser = await firebase.auth().currentUser;
        this.setState({});
        this.interval = setInterval(async () => {
            if (this.firebaseUser != null) {
                this.checkVerificationStatus();
            }
        }, 10000);
    }
}

export default AccountVerificationView;
import firebase from '../firebase';
import { objectToClassConverter } from './database_object';

export class BookableEvent {

    constructor() {
        this.details = new BookableEventDetails();
    }

    deactivated: boolean = null;
    details: BookableEventDetails = null;
    preview: boolean = null;
    slots: Map<string, Slot> = null;
    bookings: Map<string, SlotBooking> = null;
    accounts: Map<string, BookableEventAccount> = null;
    accountGroups: Map<string, BookableEventAccountGroup> = null;

    static fromFirebase(data: any): BookableEvent {
        let newBookableEvent = new BookableEvent();
        if (data == null) {
            return newBookableEvent;
        }
        objectToClassConverter(data, newBookableEvent, [], ["details", "slots", "bookings", "accounts", "accountGroups"]);

        let newSlots = new Map<string, Slot>();
        if (data.slots != null) {
            for (let nextSlotId of Object.keys(data.slots)) {
                newSlots.set(nextSlotId, Slot.fromFirebase(data.slots[nextSlotId]));
            }
        }
        newBookableEvent.slots = newSlots;

        let newBookings = new Map<string, SlotBooking>();
        if (data.bookings != null) {
            for (let nextBookingId of Object.keys(data.bookings)) {
                newBookings.set(nextBookingId, SlotBooking.fromFirebase(data.bookings[nextBookingId]));
            }
        }
        newBookableEvent.bookings = newBookings;

        let newAccounts = new Map<string, BookableEventAccount>();
        if (data.accounts != null) {
            for (let nextAccountId of Object.keys(data.accounts)) {
                newAccounts.set(nextAccountId, BookableEventAccount.fromFirebase(data.accounts[nextAccountId]));
            }
        }
        newBookableEvent.accounts = newAccounts;

        let newAccountGroups = new Map<string, BookableEventAccountGroup>();
        if (data.accountGroups != null) {
            for (let nextAccountGroupId of Object.keys(data.accountGroups)) {
                newAccountGroups.set(nextAccountGroupId, BookableEventAccountGroup.fromFirebase(data.accountGroups[nextAccountGroupId]));
            }
        }
        newBookableEvent.accountGroups = newAccountGroups;

        newBookableEvent.details = BookableEventDetails.fromFirebase(data.details);

        return newBookableEvent;
    }
}

export class BookableEventDetails {
    startDate: string = null; // ISO string (no idea why)
    endDate: string = null; // ISO string (no idea why)
    name: string = null;
    description: string = null;
    autoConfirm: boolean = null;
    maxPerPerson: number = null;
    allowConfirmedCancellation: boolean = null;

    static fromFirebase(data: any): BookableEventDetails {
        let newBookableEventDetails = new BookableEventDetails();
        if (data == null) {
            return newBookableEventDetails;
        }
        objectToClassConverter(data, newBookableEventDetails);

        return newBookableEventDetails;
    }

    toFirebase(): {} {
        let fbBookableEventDetails: any = Object.assign({}, this);
        fbBookableEventDetails['dateUpdated'] = firebase.database.ServerValue.TIMESTAMP;
        return fbBookableEventDetails;
    }
}

export class BookableEventAccount {
    recipient: boolean = null;
    totalBooked: number = null;
    updated: number | any = null;

    static fromFirebase(data: any): BookableEventAccount {
        let newBookableEventAccount = new BookableEventAccount();
        if (data == null) {
            return newBookableEventAccount;
        }
        objectToClassConverter(data, newBookableEventAccount);

        return newBookableEventAccount;
    }

    toFirebase(): {} {
        let fbDetails: any = Object.assign({}, this);
        fbDetails['updated'] = firebase.database.ServerValue.TIMESTAMP;
        return fbDetails;
    }
}

export class BookableEventAccountGroup {
    futureMembers: boolean = null;
    updated: number | any = null;

    static fromFirebase(data: any): BookableEventAccountGroup {
        let newBookableEventAccountGroup = new BookableEventAccountGroup();
        if (data == null) {
            return newBookableEventAccountGroup;
        }
        objectToClassConverter(data, newBookableEventAccountGroup);

        return newBookableEventAccountGroup;
    }

    toFirebase(): {} {
        let fbDetails: any = Object.assign({}, this);
        fbDetails['updated'] = firebase.database.ServerValue.TIMESTAMP;
        return fbDetails;
    }
}

export class Slot {
    startDate: Date = null;
    endDate: Date = null;
    owners: Map<string, SlotOwner> = null;
    cost: number = null;
    maxPeople: number = null;
    locked: boolean = null;
    maxPerPerson: number = null;
    bookingCounter: number = null;
    costDescription: string = null;
    videoLink: string = null;
    videoRestricted: boolean = null;
    videoAvailableFrom: number = null;
    callLink: string = null;
    confirmed: boolean = null;
    // booked: boolean;

    constructor() {
        this.owners = new Map();
    }

    static fromFirebase(data: any): Slot {
        let newSlot = new Slot();
        if (data == null) {
            return newSlot;
        }
        objectToClassConverter(data, newSlot, [], ["accounts", "owners"]);

        let newOwners = new Map<string, SlotOwner>();
        if (data.owners != null) {
            for (let nextAccountId of Object.keys(data.owners)) {
                newOwners.set(nextAccountId, SlotOwner.fromFirebase(data.owners[nextAccountId]));
            }
        }
        newSlot.owners = newOwners;

        newSlot.startDate = new Date(data.startDate);
        newSlot.endDate = new Date(data.endDate);

        return newSlot;
    }

    toFirebase(): {} {
        let fbSlotDetails: any = Object.assign({}, this);
        fbSlotDetails['updated'] = firebase.database.ServerValue.TIMESTAMP;
        fbSlotDetails['startDate'] = this.startDate.toISOString();
        fbSlotDetails['endDate'] = this.endDate.toISOString();
        return fbSlotDetails;
    }
}

export class SlotOwner {
    owner: boolean = null;
    updated: number | any;

    static fromFirebase(data: any): SlotOwner {
        let newSlotOwner = new SlotOwner();
        if (data == null) {
            return newSlotOwner;
        }
        objectToClassConverter(data, newSlotOwner);

        return newSlotOwner;
    }
}

export class SlotBooking {
    accounts: Map<string, SlotBookingDetails> = null;

    static fromFirebase(data: any): SlotBooking {
        let newSlotBooking = new SlotBooking();
        if (data == null) {
            return newSlotBooking;
        }
        objectToClassConverter(data, newSlotBooking, [], ["accounts"]);

        let newAccounts = new Map<string, SlotBookingDetails>();
        if (data.accounts != null) {
            for (let nextAccountId of Object.keys(data.accounts)) {
                newAccounts.set(nextAccountId, SlotBookingDetails.fromFirebase(data.accounts[nextAccountId]));
            }
        }
        newSlotBooking.accounts = newAccounts;

        return newSlotBooking;
    }
}

export class SlotBookingDetails {
    count: number = null;
    confirmed: SlotBookingConfirmation = null;
    paid: SlotBookingPayment = null;

    static fromFirebase(data: any): SlotBookingDetails {
        let newSlotBookingConfirmation = new SlotBookingDetails();
        if (data == null) {
            return newSlotBookingConfirmation;
        }
        objectToClassConverter(data, newSlotBookingConfirmation, [], ["confirmed"]);

        newSlotBookingConfirmation.confirmed = SlotBookingConfirmation.fromFirebase(data.confirmed);

        return newSlotBookingConfirmation;
    }
}

export class SlotBookingConfirmation {
    count: number = null;
    updated: number = null;

    static fromFirebase(data: any): SlotBookingConfirmation {
        let newSlotBookinConfirmation = new SlotBookingConfirmation();
        if (data == null) {
            return newSlotBookinConfirmation;
        }
        objectToClassConverter(data, newSlotBookinConfirmation);

        return newSlotBookinConfirmation;
    }
}

export class SlotBookingPayment {
    count: number = null;

    static fromFirebase(data: any): SlotBookingPayment {
        let newSlotPayment = new SlotBookingPayment();
        if (data == null) {
            return newSlotPayment;
        }
        objectToClassConverter(data, newSlotPayment);

        return newSlotPayment;
    }
}

export class SlotWrapper {

    constructor(newSlot: Slot, newAccounts: Map<string, SlotBookingDetails>, newOwners: Map<string, SlotOwner>) {
        this.slot = newSlot;
        this.accounts = newAccounts;
        this.owners = newOwners;
    }

    slot: Slot = null;
    accounts: Map<string, SlotBookingDetails> = null;
    owners: Map<string, SlotOwner> = null;
}

export class SlotMinimised {
    deactivated: boolean = null;
    endDateTime: number = null;
    name: string = null;
    slotGroupId: string = null;
    startDateTime: number = null;
    updated: number = null;
    owners: Map<string, boolean> = null;

    static fromFirebase(data: any): SlotMinimised {
        let newSlotMinimised = new SlotMinimised();
        if (data == null) {
            return newSlotMinimised;
        }
        objectToClassConverter(data, newSlotMinimised, ["owners"]);

        return newSlotMinimised;
    }
}
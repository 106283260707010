import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import firebase from './firebase';
import Canvas from './ninja_components/worksheet_canvas'
import queryString from 'query-string';
import { RouteComponentProps } from "react-router";
import { WorksheetPointList, HomeLearning, HomeLearningResponse, HomeLearningActivityWorksheet, HomeLearningWorksheet } from './data/home_learning';

interface IState {
    homeworkName: string;
    activityName: string,
    worksheetUrl: string;
    worksheetPoints: WorksheetPointList[];
    subjectFilter: string;
}

interface MatchParams {
    homeworkId: string;
    activityId: string;
    accountId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
}

class WorksheetView extends Component<IProps, IState> {
    homeworkId: string;
    activityId: string;
    accountId: string;

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            subjectFilter: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
            homeworkName: values.homeworkName == null ? "" : values.homeworkName.toString(),
            activityName: values.activityName == null ? "" : values.activityName.toString(),
            worksheetUrl: '',
            worksheetPoints: [],
        };
        this.homeworkId = props.match.params.homeworkId;
        this.activityId = props.match.params.activityId;
        this.accountId = props.match.params.accountId;
        this.getQuerys = this.getQuerys.bind(this);
    }

    render(): JSX.Element {
        if (this.state.worksheetUrl === "") {
            return <div />
        }

        let queryValues = this.getQuerys();

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={`/homeworks?${queryValues}`}>All homework</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/homeworks/${this.homeworkId}`}>{this.state.homeworkName}</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/homeworkActivityWorksheet/${this.homeworkId}/${this.activityId}`}>{this.state.activityName}</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Worksheet</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="App stackParent">
                    <img src={this.state.worksheetUrl} className="stackChild" />
                    <Canvas points={this.state.worksheetPoints} />
                </div>
            </div>
        );
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
        });
    }

    async componentDidMount(): Promise<void> {
        try {
            const homeworkRef = firebase.firestore().doc(`homeworks/${this.homeworkId}`);
            let homeworkSnapshot = await homeworkRef.get();
            let homework = HomeLearning.fromFirebase(homeworkSnapshot.data());
            let activity = homework.activities.get(this.activityId) as HomeLearningActivityWorksheet;

            const responseRef = firebase.firestore().doc(`homeworkResponses/${this.accountId}--${this.homeworkId}`);
            let responseSnapshot = await responseRef.get();
            let response = HomeLearningResponse.fromFirebase(responseSnapshot.data());
            let worksheetResponse = response.activityResponses.get(this.activityId);

            let points: WorksheetPointList[] = [];
            if (worksheetResponse != null) {
                const worksheetRef = firebase.firestore().doc(`homeLearningWorksheets/${worksheetResponse.worksheetId}`);
                let worksheetSnapshot = await worksheetRef.get();
                let worksheet = HomeLearningWorksheet.fromFirebase(worksheetSnapshot.data());
                points = worksheet.worksheetPoints == null ? [] : worksheet.worksheetPoints;
            }

            this.setState({
                worksheetUrl: activity.worksheetUrl,
                worksheetPoints: points,
            });
        } catch (error) {
            console.log(error);
        }
    }
}

export default WorksheetView;
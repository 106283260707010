export const ukToRegional: Map<string, Map<string, string>> = new Map([
    ["US", new Map([
        ["year_nursery", "year_usa_preschool"],
        ["year_nursery_-_easy", "year_usa_preschool_-_easy"],
        ["year_nursery_-_intermediate", "year_usa_preschool_-_easy"],
        ["year_nursery_-_hard", "year_usa_preschool_-_intermediate"],
        ["year_reception", "year_usa_preschool"],
        ["year_reception_-_easy", "year_usa_preschool_-_intermediate"],
        ["year_reception_-_intermediate", "year_usa_preschool_-_hard"],
        ["year_reception_-_hard", "year_usa_preschool_-_hard"],
        ["year_1", "year_usa_kindergarten"],
        ["year_1_-_easy", "year_usa_preschool_-_easy"],
        ["year_1_-_intermediate", "year_usa_kindergarten_-_intermediate"],
        ["year_1_-_intermediate_", "year_usa_kindergarten_-_intermediate"],
        ["year_1_-_hard", "year_usa_kindergarten_-_hard"],
        ["year_2", "year_usa_1"],
        ["year_2_-_easy", "year_usa_1_-_easy"],
        ["year_2_-_intermediate", "year_usa_1_-_intermediate"],
        ["year_2_-_intermediate_", "year_usa_1_-_intermediate"],
        ["year_2_-_hard", "year_usa_1_-_hard"],
        ["year_3", "year_usa_2"],
        ["year_3_-_easy", "year_usa_2_-_easy"],
        ["year_3_-_intermediate", "year_usa_2_-_intermediate"],
        ["year_3_-_intermediate_", "year_usa_2_-_intermediate"],
        ["year_3_-_hard", "year_usa_2_-_hard"],
        ["year_4", "year_usa_3"],
        ["year_4_-_easy", "year_usa_3_-_easy"],
        ["year_4_-_intermediate", "year_usa_3_-_intermediate"],
        ["year_4_-_intermediate_", "year_usa_3_-_intermediate"],
        ["year_4_-_hard", "year_usa_3_-_hard"],
        ["year_5", "year_usa_4"],
        ["year_5_-_easy", "year_usa_4_-_easy"],
        ["year_5_-_intermediate", "year_usa_4_-_intermediate"],
        ["year_5_-_intermediate_", "year_usa_4_-_intermediate"],
        ["year_5_-_hard", "year_usa_4_-_hard"],
        ["year_6", "year_usa_5"],
        ["year_6_-_easy", "year_usa_5_-_easy"],
        ["year_6_-_intermediate", "year_usa_5_-_intermediate"],
        ["year_6_-_intermediate_", "year_usa_5_-_intermediate"],
        ["year_6_-_hard", "year_usa_5_-_hard"],
        ["year_7", "year_usa_6"],
        ["year_7_-_easy", "year_usa_6_-_easy"],
        ["year_7_-_intermediate", "year_usa_6_-_intermediate"],
        ["year_7_-_intermediate_", "year_usa_6_-_intermediate"],
        ["year_7_-_hard", "year_usa_6_-_hard"],
    ]),],
    ["FR", new Map([
        ["year_nursery", "year_france_petite"],
        ["year_nursery_-_easy", "year_france_petite_-_easy"],
        ["year_nursery_-_intermediate", "year_france_petite_-_intermediate"],
        ["year_nursery_-_hard", "year_france_petite_-_hard"],
        ["year_reception", "year_france_moyenne"],
        ["year_reception_-_easy", "year_france_moyenne_-_easy"],
        ["year_reception_-_intermediate", "year_france_moyenne_-_intermediate"],
        ["year_reception_-_hard", "year_france_moyenne_-_hard"],
        ["year_1", "year_france_grande"],
        ["year_1_-_easy", "year_france_grande_-_easy"],
        ["year_1_-_intermediate", "year_france_grande_-_intermediate"],
        ["year_1_-_intermediate_", "year_france_grande_-_intermediate"],
        ["year_1_-_hard", "year_france_grande_-_hard"],
        ["year_2", "year_france_cp"],
        ["year_2_-_easy", "year_france_cp_-_easy"],
        ["year_2_-_intermediate", "year_france_cp_-_intermediate"],
        ["year_2_-_intermediate_", "year_france_cp_-_intermediate"],
        ["year_2_-_hard", "year_france_cp_-_hard"],
        ["year_3", "year_france_ce1"],
        ["year_3_-_easy", "year_france_ce1_-_easy"],
        ["year_3_-_intermediate", "year_france_ce1_-_intermediate"],
        ["year_3_-_intermediate_", "year_france_ce1_-_intermediate"],
        ["year_3_-_hard", "year_france_ce1_-_hard"],
        ["year_4", "year_france_ce2"],
        ["year_4_-_easy", "year_france_ce2_-_easy"],
        ["year_4_-_intermediate", "year_france_ce2_-_intermediate"],
        ["year_4_-_intermediate_", "year_france_ce2_-_intermediate"],
        ["year_4_-_hard", "year_france_ce2_-_hard"],
        ["year_5", "year_france_cm1"],
        ["year_5_-_easy", "year_france_cm1_-_easy"],
        ["year_5_-_intermediate", "year_france_cm1_-_intermediate"],
        ["year_5_-_intermediate_", "year_france_cm1_-_intermediate"],
        ["year_5_-_hard", "year_france_cm1_-_hard"],
        ["year_6", "year_france_cm2"],
        ["year_6_-_easy", "year_france_cm2_-_easy"],
        ["year_6_-_intermediate", "year_france_cm2_-_intermediate"],
        ["year_6_-_intermediate_", "year_france_cm2_-_intermediate"],
        ["year_6_-_hard", "year_france_cm2_-_hard"],
        ["year_7", "year_france_6"],
        ["year_7_-_easy", "year_france_6_-_easy"],
        ["year_7_-_intermediate", "year_france_6_-_intermediate"],
        ["year_7_-_intermediate_", "year_france_6_-_intermediate"],
        ["year_7_-_hard", "year_france_6_-_hard"],
    ]),],
    ["ZA", new Map([
        ["year_nursery", "year_sa_reception"],
        ["year_nursery_-_easy", "year_sa_reception_-_easy"],
        ["year_nursery_-_intermediate", "year_sa_reception_-_intermediate"],
        ["year_nursery_-_hard", "year_sa_reception_-_hard"],
        ["year_reception", "year_sa_grade1"],
        ["year_reception_-_easy", "year_sa_grade1_-_easy"],
        ["year_reception_-_intermediate", "year_sa_grade1_-_intermediate"],
        ["year_reception_-_hard", "year_sa_grade1_-_hard"],
        ["year_1", "year_sa_grade2"],
        ["year_1_-_easy", "year_sa_grade2_-_easy"],
        ["year_1_-_intermediate", "year_sa_grade2_-_intermediate"],
        ["year_1_-_intermediate_", "year_sa_grade2_-_intermediate"],
        ["year_1_-_hard", "year_sa_grade2_-_hard"],
        ["year_2", "year_sa_grade3"],
        ["year_2_-_easy", "year_sa_grade3_-_easy"],
        ["year_2_-_intermediate", "year_sa_grade3_-_intermediate"],
        ["year_2_-_intermediate_", "year_sa_grade3_-_intermediate"],
        ["year_2_-_hard", "year_sa_grade3_-_hard"],
        ["year_3", "year_sa_grade4"],
        ["year_3_-_easy", "year_sa_grade4_-_easy"],
        ["year_3_-_intermediate", "year_sa_grade4_-_intermediate"],
        ["year_3_-_intermediate_", "year_sa_grade4_-_intermediate"],
        ["year_3_-_hard", "year_sa_grade4_-_hard"],
        ["year_4", "year_sa_grade5"],
        ["year_4_-_easy", "year_sa_grade5_-_easy"],
        ["year_4_-_intermediate", "year_sa_grade5_-_intermediate"],
        ["year_4_-_intermediate_", "year_sa_grade5_-_intermediate"],
        ["year_4_-_hard", "year_sa_grade5_-_hard"],
        ["year_5", "year_sa_grade6"],
        ["year_5_-_easy", "year_sa_grade6_-_easy"],
        ["year_5_-_intermediate", "year_sa_grade6_-_intermediate"],
        ["year_5_-_intermediate_", "year_sa_grade6_-_intermediate"],
        ["year_5_-_hard", "year_sa_grade6_-_hard"],
        ["year_6", "year_sa_grade7"],
        ["year_6_-_easy", "year_sa_grade7_-_easy"],
        ["year_6_-_intermediate", "year_sa_grade7_-_intermediate"],
        ["year_6_-_intermediate_", "year_sa_grade7_-_intermediate"],
        ["year_6_-_hard", "year_sa_grade7_-_hard"],
        ["year_7", "year_sa_grade8"],
        ["year_7_-_easy", "year_sa_grade8_-_easy"],
        ["year_7_-_intermediate", "year_sa_grade8_-_intermediate"],
        ["year_7_-_intermediate_", "year_sa_grade8_-_intermediate"],
        ["year_7_-_hard", "year_sa_grade8_-_hard"],
    ]),],
    ["IN", new Map([
        ["year_nursery", "year_india_preschool"],
        ["year_nursery_-_easy", "year_india_preschool_-_easy"],
        ["year_nursery_-_intermediate", "year_india_preschool_-_intermediate"],
        ["year_nursery_-_hard", "year_india_preschool_-_hard"],
        ["year_reception", "year_india_preprimary"],
        ["year_reception_-_easy", "year_india_preprimary_-_easy"],
        ["year_reception_-_intermediate", "year_india_preprimary_-_intermediate"],
        ["year_reception_-_hard", "year_india_preprimary_-_hard"],
        ["year_1", "year_india_grade1"],
        ["year_1_-_easy", "year_india_grade1_-_easy"],
        ["year_1_-_intermediate", "year_india_grade1_-_intermediate"],
        ["year_1_-_intermediate_", "year_india_grade1_-_intermediate"],
        ["year_1_-_hard", "year_india_grade1_-_hard"],
        ["year_2", "year_india_grade2"],
        ["year_2_-_easy", "year_india_grade2_-_easy"],
        ["year_2_-_intermediate", "year_india_grade2_-_intermediate"],
        ["year_2_-_intermediate_", "year_india_grade2_-_intermediate"],
        ["year_2_-_hard", "year_india_grade2_-_hard"],
        ["year_3", "year_india_grade3"],
        ["year_3_-_easy", "year_india_grade3_-_easy"],
        ["year_3_-_intermediate", "year_india_grade3_-_intermediate"],
        ["year_3_-_intermediate_", "year_india_grade3_-_intermediate"],
        ["year_3_-_hard", "year_india_grade3_-_hard"],
        ["year_4", "year_india_grade4"],
        ["year_4_-_easy", "year_india_grade4_-_easy"],
        ["year_4_-_intermediate", "year_india_grade4_-_intermediate"],
        ["year_4_-_intermediate_", "year_india_grade4_-_intermediate"],
        ["year_4_-_hard", "year_india_grade4_-_hard"],
        ["year_5", "year_india_grade5"],
        ["year_5_-_easy", "year_india_grade5_-_easy"],
        ["year_5_-_intermediate", "year_india_grade5_-_intermediate"],
        ["year_5_-_intermediate_", "year_india_grade5_-_intermediate"],
        ["year_5_-_hard", "year_india_grade5_-_hard"],
        ["year_6", "year_india_grade6"],
        ["year_6_-_easy", "year_india_grade6_-_easy"],
        ["year_6_-_intermediate", "year_india_grade6_-_intermediate"],
        ["year_6_-_intermediate_", "year_india_grade6_-_intermediate"],
        ["year_6_-_hard", "year_india_grade6_-_hard"],
        ["year_7", "year_india_grade7"],
        ["year_7_-_easy", "year_india_grade7_-_easy"],
        ["year_7_-_intermediate", "year_india_grade7_-_intermediate"],
        ["year_7_-_intermediate_", "year_india_grade7_-_intermediate"],
        ["year_7_-_hard", "year_india_grade7_-_hard"],
    ]),]
]);
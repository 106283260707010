import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import {
    FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, UncontrolledTooltip, Card, CardHeader, CardBody, Row, Col, Button, Spinner
} from 'reactstrap';
import confirm from "reactstrap-confirm";
import { User } from './data/user';

interface IState {
    paymentProvider: string;
    loaded: boolean;
}

interface IProps {
    snackbar: (text?: string) => void;
    user: User
}

class PaymentSelectionView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            paymentProvider: null,
            loaded:false,
        };
        this.handleProviderChange = this.handleProviderChange.bind(this);
        this.createCard = this.createCard.bind(this);
    }

    createCard(title: string, description: string, imageUrl: string, enabled: boolean, url: string): JSX.Element {
        return <Card style={{ width: "100%" }}>
            <CardHeader style={{ backgroundColor: enabled ? '#7036a4' : null, color: enabled ? '#fff' : null }}><b>{title}</b></CardHeader>
            <CardBody className="d-flex flex-column">
                <div>
                    <Row>
                        <Col key={`column1-${title}`} md="4">
                            <img className={"img-fluid"} src={`/images/payments/${imageUrl}`} alt="" />
                        </Col>
                        <Col key={`column2-${title}`} md="6">
                            {description}
                        </Col>
                        <Col key={`column3-${title}`} md="2">
                            <Link to={`/paymentSettings/${url}`}>
                                <Button className="altButton" style={{float:'right'}} type="button">
                                    Configure
                                </Button>
                            </Link><br /><br />
                            {enabled ?
                                <div className="float-right">Active</div>
                                : <Button className="altButton" style={{float:'right'}} type="button" onClick={
                                    () => this.handleProviderChange(url)
                                }>
                                    Enable
                                    </Button>
                            }

                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    }

    render(): JSX.Element {

        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem active>Payment provider</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Payment provider</div>
                    <p className="cardSubTitle">To allow users to make payments select your payment provider. <i className="fas fa-info-circle icons-info" id="help-paymentsettings-provider" /></p>
                    <UncontrolledTooltip placement="bottom" autohide={false} target="help-paymentsettings-provider">
                        We currently support two payment providers.
                        Further details on the payment providers can be found <a target="_blank" href="https://lifeninja.net">on our website</a>
                    </UncontrolledTooltip>
                    {
                        this.state.loaded ?
                            <div>
                                {this.createCard("Square", "Square payment provider. Charge a 2.5% transaction fee. Minimum transaction value of £1.",
                                    "square-logo-black-1.png", this.state.paymentProvider === "square", "square")}
                                <br />
                                {this.createCard("Stripe", "Stripe payment provider. Charge a 1.5% + 20p transaction fee. Minimum transaction value of £1",
                                    "stripe-logo-blue.png", this.state.paymentProvider === "stripe", "stripe")}
                            </div>
                            : <Spinner />
                    }
                </CardBody>
            </Card>
        </div>
    }

    async handleProviderChange(provider: string): Promise<void> {
        let result = await confirm({ title: "Confirm", message: "Please confirm you want to change payment provider", confirmText: "Confirm" });
        if (result) {
            this.setState({
                paymentProvider: provider,
            });
            const paymentRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/paymentSettings/public/paymentProvider`);
            try {
                await paymentRef.set(provider);
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Update failed");
            }
        }
    }

    async componentDidMount() {
        try {
            const paymentRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/paymentSettings/public/paymentProvider`);
            let snapshot = await paymentRef.once('value');
            let paymentProvider = snapshot.val();

            this.setState({
                paymentProvider: paymentProvider,
                loaded: true,
            });
        } catch (error) {
            console.log(error)
        }
    }
}

export default PaymentSelectionView;
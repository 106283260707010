import React, { Component } from 'react';
import firebase from '../firebase';
import {
    Button, FormGroup, Label, Input, InputGroup, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { Attachment } from '../data/home_learning';
import { UploadMetadata } from '@firebase/storage-types';


interface IState {
    currentAttachments: Attachment[];
    currentTempAttachment: string;
    attachmentUploading: boolean;
    addAttachmentView: boolean;
}

interface IProps {
    currentAttachments: Attachment[];
    attachmentLegend?: string;
    callback: (attachments: Array<Attachment>) => void;
    failureCallback: (message: string) => void;
    filePath: string;
    schoolId: string;
}

class AttachmentUpload extends Component<IProps, IState> {
    legend: string;

    constructor(props: IProps) {
        super(props);
        this.state = {
            currentAttachments: props.currentAttachments,
            currentTempAttachment: '',
            attachmentUploading: false,
            addAttachmentView: false,
        };
        this.legend = props.attachmentLegend != null && props.attachmentLegend.length > 0 ? props.attachmentLegend : "Attachments";
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.removeAttachment = this.removeAttachment.bind(this);
        this.toggleAddAttachmentModal = this.toggleAddAttachmentModal.bind(this);
    }

    componentDidUpdate(prevProps: IProps, prevState: IState, snapshot: any) {
        if (this.props.currentAttachments !== this.state.currentAttachments) {
            this.setState({
                currentAttachments: this.props.currentAttachments,
            });
        }
    }

    render(): JSX.Element {
        return <div>
            <Label>{this.legend}</Label><br />
            {this.state.currentAttachments.map((attachmentDeets, index) => {
                return <div key={attachmentDeets.path}>
                    <a href={attachmentDeets.url} target={"_blank"}>{attachmentDeets.name}</a>&nbsp;&nbsp;
                    <Button onClick={() => this.removeAttachment(index)} color={"link"}>
                        <i className="material-icons">delete</i>
                    </Button>
                </div>
            })}
            {!this.state.attachmentUploading ?
                <FormGroup>
                    <Button onClick={this.toggleAddAttachmentModal} outline>Add attachment</Button>
                </FormGroup>
                :
                <FormGroup>
                    <Label>Uploading...</Label>
                </FormGroup>
            }
            <Modal isOpen={this.state.addAttachmentView} toggle={this.toggleAddAttachmentModal}>
                <ModalHeader toggle={this.toggleAddAttachmentModal}>Select attachment</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="attachmentFile">Add from computer</Label>
                        <InputGroup>
                            <Input type="file" id="attachmentFile" name="attachmentFile" onChange={this.handleFileUpload} />
                            <Label className="custom-file-label" for="attachmentFile">Choose file</Label>
                        </InputGroup>
                    </FormGroup>
                </ModalBody>
            </Modal>
        </div>
    }

    toggleAddAttachmentModal(): void {
        this.setState({
            addAttachmentView: !this.state.addAttachmentView
        })
    }

    async handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        console.log("Starting upload");
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            attachmentUploading: true,
        });
        this.toggleAddAttachmentModal();
        if (e.target.files == null || e.target.files.length === 0) {
            this.props.failureCallback("Upload failed");
            return;
        }
        let file = e.target.files[0];
        if (file == null) {
            return;
        }
        let metadata: UploadMetadata = {
            'contentType': file.type,
            customMetadata: {
                schoolId: this.props.schoolId,
            }
        };
        // Push to child path.
        let attachments = this.state.currentAttachments.slice();
        let path = `${this.props.filePath}/${uuidv4()}-${file.name}`;
        try {
            let snapshot = await firebase.storage().ref().child(path).put(file, metadata);
            // Let's get a download URL for the file.
            let url = await snapshot.ref.getDownloadURL();
            let newAttachment = new Attachment();
            newAttachment.url = url;
            newAttachment.path = path;
            newAttachment.name = file.name;
            attachments.push(newAttachment);

            this.setState({
                attachmentUploading: false,
            });
            this.props.callback(attachments);
        } catch (error) {
            console.error('Upload failed:', error);
            this.setState({
                attachmentUploading: false,
            });
            this.props.failureCallback("Upload failed");
        }
    }

    async removeAttachment(index: number): Promise<void> {
        let attachment = this.state.currentAttachments[index];
        try {
            await firebase.storage().ref().child(attachment.path).delete();
            console.log('Deleted');
            let attachments = this.state.currentAttachments.slice();
            attachments.splice(index, 1);
            this.props.callback(attachments);
        } catch (error) {
            console.error('Delete failed:', error);
            this.props.failureCallback("Delete failed");
        }
    }
}

export default AttachmentUpload;
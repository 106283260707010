//@ts-check

import React, { Component } from 'react';
import firebase from './firebase';
import queryString from 'query-string';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, UncontrolledTooltip, Col, Card, CardBody
} from 'reactstrap';
import { Link } from "react-router-dom";
import TopicsSelector from "./ninja_components/topics_selector";
import { StoreLearningGame } from "./helpers/topic_services";
import { RouteComponentProps } from 'react-router';
import { User, USER_ROLE } from './data/user';
import { MTCGame, TIME_MULTIPLIER } from './data/learning_games';
import { v4 as uuidv4 } from 'uuid';
import { soundNames, subjectIds } from './Prettifier';

interface IState {
    worldId: string;
    worldName: string;
    subjectFilter: string;
    pathId: string;
    pathName: string;
    gameId: string;
    mulSpeedTest: MTCGame;
    timeMultiplier: TIME_MULTIPLIER;
    firstOperandTimesTables: number[];
    secondOperandTimesTables: number[];
    questionCount: number;
    currentmulSpeedTestSchoolId: string;
    currentmulSpeedTestTitle: string;
    currentmulSpeedTestInstructions: string;
    currentmulSpeedTestSound: string;
    currentSubjectId: string;
    currentTopics: string[],
    currentTopicsTags: Map<string, string[]>,
    user: User;
}

interface MatchParams {
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class learningMultiplicationSpeedTest extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            gameId: props.match.params.gameId,
            mulSpeedTest: null,
            timeMultiplier: TIME_MULTIPLIER.moderate,
            firstOperandTimesTables: [],
            secondOperandTimesTables: [],
            questionCount: 0,
            currentmulSpeedTestSchoolId: '',
            currentmulSpeedTestTitle: '',
            currentmulSpeedTestInstructions: '',
            currentmulSpeedTestSound: '',
            currentSubjectId: '',
            currentTopics: [],
            currentTopicsTags: new Map(),
            user: props.user,
        };
        this.timesChange = this.timesChange.bind(this);
        this.secondTimesChange = this.secondTimesChange.bind(this);
        this.dealWithChange = this.dealWithChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.currentmulSpeedTestSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All minigames</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem> : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem> : null}
                        <BreadcrumbItem active>Multiplication Speed Test</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Multiplication Speed Test</div>
                        <p className="cardSubTitle">A Multiplication Speed Test requires the user to answer the given questions in the correct amount of time before
                        the timer runs out.
                        </p>
                        {
                            (this.state.mulSpeedTest != null) ?
                                <div>
                                    <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                        <fieldset disabled={!editable}>
                                            <FormGroup>
                                                <Label for="currentmulSpeedTestTitle">Title *</Label>
                                                <Input maxLength={30} type="text" required name="currentmulSpeedTestTitle" value={this.state.currentmulSpeedTestTitle} onChange={(e) => this.setState({
                                                    currentmulSpeedTestTitle: e.target.value
                                                })}></Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentSubjectId">Subject *</Label>
                                                <Input disabled={this.state.worldId != null || this.state.gameId !== "-1"} type="select" name="currentSubjectId"
                                                    onChange={(e) => this.setState({
                                                        currentSubjectId: e.target.value
                                                    })}
                                                    value={this.state.currentSubjectId}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                                        let subjectName = subjectIds.get(subjectId);
                                                        return <option value={subjectId}>{subjectName}</option>;
                                                    })}
                                                </Input>
                                            </FormGroup>
                                            {this.state.currentSubjectId === '' ? <div></div> :
                                                <TopicsSelector subject={this.state.currentSubjectId} countryCode={this.state.user.countryCode} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags} callback={this.handleTopicsChange} />
                                            }

                                            <Label>
                                                Pick Your Desired Speed: <br />
                                                <Input type="select" value={this.state.timeMultiplier} onChange={this.dealWithChange}>
                                                    <option value="fastest">Fastest</option>
                                                    <option value="fast">Fast</option>
                                                    <option value="moderate">Moderate</option>
                                                    <option value="slow">Slow</option>
                                                    <option value="slowest">Slowest</option>
                                                </Input>
                                            </Label>
                                            <br />
                                            <div>
                                                <div id="fg-left">
                                                    <Label>1st Times Tables Number:</Label>
                                                    <FormGroup check>
                                                        <div>
                                                            <Col><Input type="checkbox" value="2" name="firstcheck-2" id="firstcheck2" checked={this.state.firstOperandTimesTables.indexOf(2) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-2">2 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="3" name="firstcheck-3" id="firstcheck3" checked={this.state.firstOperandTimesTables.indexOf(3) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-3">3 Times  Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="4" name="firstcheck-4" id="firstcheck4" checked={this.state.firstOperandTimesTables.indexOf(4) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-4">4 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="5" name="firstcheck-5" id="firstcheck5" checked={this.state.firstOperandTimesTables.indexOf(5) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-5">5 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="6" name="firstcheck-6" id="firstcheck6" checked={this.state.firstOperandTimesTables.indexOf(6) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-6">6 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="7" name="firstcheck-7" id="firstchceck7" checked={this.state.firstOperandTimesTables.indexOf(7) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-7">7 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="8" name="firstcheck-8" id="firstcheck8" checked={this.state.firstOperandTimesTables.indexOf(8) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-8">8 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="9" name="firstcheck-9" id="firstcheck9" checked={this.state.firstOperandTimesTables.indexOf(9) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-9">9 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="10" name="firstcheck-10" id="firstcheck10" checked={this.state.firstOperandTimesTables.indexOf(10) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-10">10 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="11" name="firstcheck-11" id="firstcheck11" checked={this.state.firstOperandTimesTables.indexOf(11) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-11">11 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="12" name="firstcheck-12" id="firstcheck12" checked={this.state.firstOperandTimesTables.indexOf(12) !== -1} onChange={this.timesChange} /> {' '}
                                                                <Label for="firstcheck-12">12 Times Tables</Label></Col>
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                                <div id="fg-right">
                                                    <Label>2nd Times Tables Number:</Label>
                                                    <FormGroup check>
                                                        <div>
                                                            <Col><Input type="checkbox" value="2" name="2ndcheck-2" id="2ndcheck2" checked={this.state.secondOperandTimesTables.indexOf(2) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-2">2 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="3" name="2ndcheck-3" id="2ndcheck3" checked={this.state.secondOperandTimesTables.indexOf(3) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-3">3 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="4" name="2ndcheck-4" id="2ndcheck4" checked={this.state.secondOperandTimesTables.indexOf(4) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-4">4 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="5" name="2ndcheck-5" id="2ndcheck5" checked={this.state.secondOperandTimesTables.indexOf(5) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-5">5 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="6" name="2ndcheck-6" id="2ndcheck6" checked={this.state.secondOperandTimesTables.indexOf(6) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-6">6 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="7" name="2ndcheck-7" id="2ndcheck7" checked={this.state.secondOperandTimesTables.indexOf(7) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-7">7 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="8" name="2ndcheck-8" id="2ndcheck8" checked={this.state.secondOperandTimesTables.indexOf(8) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-8">8 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="9" name="2ndcheck-9" id="2ndcheck9" checked={this.state.secondOperandTimesTables.indexOf(9) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-9">9 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="10" name="2ndcheck-10" id="2ndcheck10" checked={this.state.secondOperandTimesTables.indexOf(10) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-10">10 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="11" name="2ndchck-11" id="2ndcheck11" checked={this.state.secondOperandTimesTables.indexOf(11) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-11">11 Times Tables</Label></Col>
                                                            <Col><Input type="checkbox" value="12" name="2ndcheck-12" id="2ndcheck12" checked={this.state.secondOperandTimesTables.indexOf(12) !== -1} onChange={this.secondTimesChange} /> {' '}
                                                                <Label for="2ndcheck-12">12 Times Tables</Label></Col>
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <br />
                                            <FormGroup>
                                                <Label>
                                                    Number Of Questions *
                                        <Input required type="number" min={1} max={25} name="questionCount" onChange={(e) => this.setState({
                                                    questionCount: e.target.value === "" ? null : parseInt(e.target.value)
                                                })} value={this.state.questionCount}></Input>
                                                </Label>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentmulSpeedTestInstructions">Instructions <i className="fas fa-info-circle icons-info" id="help-learningmulSpeedTest-instructions" /></Label>
                                                <Input type="textarea" name="currentmulSpeedTestInstructions" onChange={(e) => this.setState({
                                                    currentmulSpeedTestInstructions: e.target.value
                                                })} value={this.state.currentmulSpeedTestInstructions} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningmulSpeedTest-instructions">
                                                    The Instructions shown to the player before they start the Multiplication Speed Test. Advice on any types of sums to complete rather than the how to play game.
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentmulSpeedTestSound">Audio instruction</Label>
                                                <Input type="select" name="currentmulSpeedTestSound"
                                                    onChange={(e) => this.setState({
                                                        currentmulSpeedTestSound: e.target.value
                                                    })}
                                                    value={this.state.currentmulSpeedTestSound}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(soundNames.keys()).map((key) => {
                                                        let name = soundNames.get(key);
                                                        return (
                                                            <option value={key} key={key}>{name}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                            </FormGroup>


                                            {editable ? this.state.gameId === "-1" ?
                                                <Button disabled={this.state.currentSubjectId === ''} className="adminPagesButton">Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                            }
                                        </fieldset>
                                    </Form>
                                </div> : <div />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId,
        });
    }

    dealWithChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    timesChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if (e.target.checked) {
            this.state.firstOperandTimesTables.push(parseInt(e.target.value));
        } else {
            let index = this.state.firstOperandTimesTables.indexOf(parseInt(e.target.value));
            this.state.firstOperandTimesTables.splice(index, 1);
        }
        this.setState({
        });
    }

    secondTimesChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if (e.target.checked) {
            this.state.secondOperandTimesTables.push(parseInt(e.target.value));
        } else {
            let index = this.state.secondOperandTimesTables.indexOf(parseInt(e.target.value));
            this.state.secondOperandTimesTables.splice(index, 1);
        }
        this.setState({
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let mulSpeedTest = this.state.mulSpeedTest;
        mulSpeedTest.title = this.state.currentmulSpeedTestTitle;
        mulSpeedTest.instructions = this.state.currentmulSpeedTestInstructions;
        mulSpeedTest.topics = this.state.currentTopics;
        mulSpeedTest.subjectId = this.state.currentSubjectId;
        mulSpeedTest.topicTags = this.state.currentTopicsTags;
        mulSpeedTest.sound = this.state.currentmulSpeedTestSound;
        mulSpeedTest.timeMultiplier = this.state.timeMultiplier;
        mulSpeedTest.firstOperandTimesTables = this.state.firstOperandTimesTables;
        mulSpeedTest.secondOperandTimesTables = this.state.secondOperandTimesTables;
        mulSpeedTest.questionCount = this.state.questionCount;
        mulSpeedTest.type = 'MULSPEEDTEST';
        let newmulSpeedTest = false;
        let gameId = this.state.gameId;
        if (this.state.gameId === "-1") {
            newmulSpeedTest = true;
            gameId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                mulSpeedTest.schoolId = this.state.user.schoolId;
            }
        }
        try {
            await StoreLearningGame(newmulSpeedTest, mulSpeedTest, gameId, this.state.pathId, this.state.currentmulSpeedTestSchoolId);

            this.props.history.replace(`/learningMultiplicationSpeedTest/${gameId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save Failed");
        }
        this.setState({
            gameId: gameId
        })
    }

    async componentDidMount(): Promise<void> {
        try {
            if (this.state.gameId !== "-1") {
                let mulSpeedTestSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
                let mulSpeedTestData = mulSpeedTestSnapshot.data();
                let mulSpeedTest = MTCGame.fromFirebase(mulSpeedTestData);
                this.setState({
                    mulSpeedTest: mulSpeedTest,
                    currentmulSpeedTestTitle: mulSpeedTest.title,
                    currentmulSpeedTestInstructions: mulSpeedTest.instructions,
                    currentmulSpeedTestSchoolId: mulSpeedTest.schoolId == null ? null : mulSpeedTest.schoolId,
                    currentmulSpeedTestSound: mulSpeedTest.sound == null ? '' : mulSpeedTest.sound,
                    currentSubjectId: mulSpeedTest.subjectId,
                    currentTopics: mulSpeedTest.topics == null ? [] : mulSpeedTest.topics,
                    currentTopicsTags: mulSpeedTest.topicTags == null ? new Map<string, string[]>() : mulSpeedTest.topicTags,
                    timeMultiplier: mulSpeedTest.timeMultiplier != null ? mulSpeedTest.timeMultiplier : TIME_MULTIPLIER.moderate,
                    questionCount: mulSpeedTest.questionCount != null ? mulSpeedTest.questionCount : 5,
                    firstOperandTimesTables: mulSpeedTest.firstOperandTimesTables != null ? mulSpeedTest.firstOperandTimesTables : [],
                    secondOperandTimesTables: mulSpeedTest.secondOperandTimesTables != null ? mulSpeedTest.secondOperandTimesTables : [],
                });
            } else {
                let subjectId = '';
                if (this.state.worldId != null) {
                    const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                    let worldSnapshot = await worldRef.get();
                    let world = worldSnapshot.data();
                    subjectId = world.subject;
                } else {
                    //@ts-ignore
                    subjectId = this.state.subjectFilter;
                }
                this.setState({
                    mulSpeedTest: new MTCGame(),
                    currentmulSpeedTestTitle: '',
                    currentmulSpeedTestInstructions: '',
                    currentmulSpeedTestSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                    currentmulSpeedTestSound: '',
                    currentSubjectId: subjectId,
                    currentTopics: [],
                    timeMultiplier: TIME_MULTIPLIER.moderate,
                    currentTopicsTags: new Map<string, string[]>(),
                    questionCount: 5,
                    firstOperandTimesTables: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    secondOperandTimesTables: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
};
export default learningMultiplicationSpeedTest;
import React, { Component } from "react";
import moment from 'moment';
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { User } from "../data/user";
import firebase from '../firebase';
import { Button, Alert, Form, FormGroup, Label, Input, Collapse, Card, CardHeader, CardBody, Table, Modal, ModalHeader, ModalBody, Col, Container, Row, Spinner, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { AccountGroup, AccountMinimised } from "../data/accounts";
import { StudentTopicOverview, Subject, Topic } from "../data/home_learning";
import { TableDetails, DataTableView } from "./data_table";
import { subjectIds } from '../Prettifier';
import queryString from 'query-string';
import { Objective, ObjectiveCategory, ObjectiveGroup, SubjectObjectives } from "../data/objectives";
import { getObjectives } from "../helpers/objectives_service";

interface TagAnalysis {
    passed: string[];
    lastPassed: string[];
    secure: string[];
    attempted: string[];
}

interface ShowAccountListDetails {
    title: string;
    includedAccountIds: string[];
    excludedAccountIds: string[];
}

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
    accountGroups: Map<string, AccountGroup>;
}

interface IState {
    dataLoaded: boolean;
    dataLoading: boolean;
    currentReportAccountGroups: Array<string>;
    currentReportAppId: string;
    newReportAccountGroups: Array<string>;
    newReportAppId: string;
    newAccountIdList: Array<string>;
    availableAccountIds: Array<string>;
    accountIdList: Array<string>;
    showReportDetails: boolean;
    showAccountListDetails: ShowAccountListDetails;
    collapse: boolean;
    tableDetails: TableDetails;
}

class AppDownloadsReportView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            dataLoaded: false,
            dataLoading: false,
            currentReportAccountGroups: [],
            currentReportAppId: null,
            newReportAccountGroups: [],
            newAccountIdList: [],
            newReportAppId: values.appFilter == null ? "" : values.appFilter.toString(),
            availableAccountIds: [],
            accountIdList: [],
            collapse: true,
            showReportDetails: true,
            showAccountListDetails: null,
            tableDetails: null,
        };
        this.handleReportChange = this.handleReportChange.bind(this);
        this.selectAllAccounts = this.selectAllAccounts.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleAccountGroupChange = this.handleAccountGroupChange.bind(this);
        this.toggleShowReportDetails = this.toggleShowReportDetails.bind(this);
    }

    render(): JSX.Element {
        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/reporting/zapMathsReporting'}>All Reports</Link></BreadcrumbItem>
                    <BreadcrumbItem>App Usage Report</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">App usage report</div>
                    <p className="cardSubTitle">View which students/parents have downloaded and used specific Life Ninja Network apps.</p>

                    {this.state.dataLoaded ?
                        <Button onClick={this.toggleShowReportDetails} className="altButton" style={{ width: '9%', float: 'right' }}>
                            {this.state.showReportDetails ? "Close" : "Edit report"}
                        </Button> : null
                    }
                    <Collapse isOpen={this.state.showReportDetails}>
                        <Form onSubmit={this.handleReportChange}>
                            <FormGroup>
                                <Label for="newReportAccountGroups">Account groups *</Label>
                                <Input type="select" required name="newReportAccountGroups" multiple onChange={this.handleAccountGroupChange} value={this.state.newReportAccountGroups}>
                                    {Array.from(this.props.accountGroups.keys()).map((accountGroupId) => {
                                        return (
                                            <option value={accountGroupId} key={accountGroupId}>{this.props.accountGroups.get(accountGroupId).details.name}</option>
                                        )
                                    })}
                                </Input><br />
                            </FormGroup>
                            <Collapse isOpen={!this.state.collapse}>
                                <FormGroup>
                                    <Label for="newAccountIdList">Accounts * <Button type="button" onClick={this.selectAllAccounts} color="secondary">Select all</Button></Label>
                                    <Input type="select" required name="newAccountIdList" multiple onChange={this.handleAccountChange} value={this.state.newAccountIdList}>
                                        {this.state.availableAccountIds.map((accountId) => {
                                            let account = this.props.accountsMinimised.get(accountId);
                                            if (account == null) {
                                                return null;
                                            }
                                            return (
                                                <option value={accountId} key={accountId}>{account.name}</option>
                                            )
                                        })
                                        }
                                    </Input><br />
                                </FormGroup>
                            </Collapse>
                            <FormGroup>
                                <Label for="newReportAppId">App *</Label>
                                <Input required type="select" name="newReportAppId"
                                    onChange={(e) => this.setState({
                                        newReportAppId: e.target.value
                                    })}
                                    value={this.state.newReportAppId}>
                                    <option value={''}>&nbsp;</option>
                                    <option value={'LearningNinja'}>Learning Ninja</option>
                                    <option value={'MessageNinja'}>Message Ninja</option>
                                    <option value={'TwilightEnglish'}>Help Me! English</option>
                                    <option value={'ZapMaths'}>Zap Maths</option>
                                </Input>
                            </FormGroup>
                            <Button className="adminPagesButton">{this.state.dataLoading ? <Spinner size="sm"></Spinner> : <span />}Run report</Button>
                        </Form>
                    </Collapse>
                </CardBody>
            </Card>
            <Modal isOpen={this.state.showAccountListDetails != null} toggle={() => this.setState({ showAccountListDetails: null })}>
                <ModalHeader toggle={() => this.setState({ showAccountListDetails: null })}>{this.state.showAccountListDetails == null ? "" : this.state.showAccountListDetails.title}</ModalHeader>
                <ModalBody>
                    {this.state.showAccountListDetails == null ? <span /> :
                        <Container className="border rounded form-margin">
                            <Row>
                                <Col><h3>Yes</h3>
                                    {this.state.showAccountListDetails.includedAccountIds.map((accountId) => {
                                        let account = this.props.accountsMinimised.get(accountId);
                                        if (account == null) {
                                            return null;
                                        } else {
                                            return <span>{account.name}<br /></span>;
                                        }
                                    })}
                                </Col>
                                <Col><h3>No</h3>
                                    {this.state.showAccountListDetails.excludedAccountIds.map((accountId) => {
                                        let account = this.props.accountsMinimised.get(accountId);
                                        if (account == null) {
                                            return null;
                                        } else {
                                            return <span>{account.name}<br /></span>;
                                        }
                                    })}
                                </Col>
                            </Row>
                        </Container>
                    }
                </ModalBody>
            </Modal>
            {this.state.dataLoaded ?
                <Card className="mainCard top-buffer">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle2">{this.state.accountIdList.length} student{this.state.accountIdList.length !== 1 ? "s" : ""} included in report</div>
                        <DataTableView tableDetails={this.state.tableDetails} ></DataTableView>
                    </CardBody>
                </Card> : null
            }
            <br /><br />
        </div>
    }

    toggleShowReportDetails() {
        this.setState({
            showReportDetails: !this.state.showReportDetails
        });
    }

    async handleReportChange(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        this.setState({
            dataLoading: true,
        });

        let accountIdList = this.state.newAccountIdList;

        const appUsageRef = firebase.firestore().doc(`schoolLicenses/${this.props.user.schoolId}/appUsage/${this.state.newReportAppId}`);
        let appUsageSnapshot = await appUsageRef.get();
        let appUsageData = appUsageSnapshot.data();

        let data = new Array<Array<any>>();
        for (let nextAccountId of accountIdList) {
            let downloadedDate: firebase.firestore.Timestamp = appUsageData == null ? null : appUsageData[nextAccountId];
            let downloadedDateString = downloadedDate == null ? "" : downloadedDate.toDate();
            data.push([this.props.accountsMinimised.has(nextAccountId) ? this.props.accountsMinimised.get(nextAccountId).name : "Removed account", downloadedDateString]);
        }

        let tableDetails: TableDetails = {
            headers: ["Account", "Last access"],
            data: data,
        };

        this.setState({
            currentReportAccountGroups: this.state.newReportAccountGroups,
            currentReportAppId: this.state.newReportAppId,
            accountIdList: accountIdList,
            showReportDetails: false,
            tableDetails: tableDetails,
            dataLoaded: true,
            dataLoading: false,
        });
    }

    handleAccountChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newAccountIdList.indexOf(e.target.value);
        if (index > -1) {
            this.state.newAccountIdList.splice(index, 1);
        } else {
            this.state.newAccountIdList.push(e.target.value);
        }
        this.setState({});
    }

    selectAllAccounts(): void {
        this.setState({
            newAccountIdList: this.state.availableAccountIds.slice(),
        })
    }

    handleAccountGroupChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newReportAccountGroups.indexOf(e.target.value);
        if (index > -1) {
            this.state.newReportAccountGroups.splice(index, 1);
        } else {
            this.state.newReportAccountGroups.push(e.target.value);
        }
        let accountIdList = [];
        for (let nextAccountGroupId of this.state.newReportAccountGroups) {
            let nextAccountGroup = this.props.accountGroups.get(nextAccountGroupId);
            if (nextAccountGroup != null) {
                for (let nextAccountId of nextAccountGroup.accounts.keys()) {
                    let nextAccountDeets = nextAccountGroup.accounts.get(nextAccountId);
                    if (nextAccountDeets.member != null && nextAccountDeets.member &&
                        accountIdList.indexOf(nextAccountId) === -1 && this.props.accountsMinimised.has(nextAccountId)) {
                        accountIdList.push(nextAccountId);
                    }
                }
            }
        }
        this.setState({
            availableAccountIds: accountIdList,
            collapse: false,
            accountIdList: [],
        });
    }
}

export default AppDownloadsReportView;
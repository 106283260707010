import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import queryString from 'query-string';
import {
    Card, CardBody,
    CardHeader, Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Collapse, Table
} from 'reactstrap';
import confirm from "reactstrap-confirm";
import { User, USER_ROLE } from './data/user';
import { LearningPath, LearningWorld } from './data/learning_games'
import { worldImages, bossTypes, subjectIds } from './Prettifier';

interface MatchParams {
    worldId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    snackbar: (text?: string) => void;
    user: User;
}

interface IState {
    worldId: string;
    world: LearningWorld;
    subjectFilter: string;
    currentLearningWorldName: string;
    currentLearningWorldDescription: string;
    currentLearningWorldBossName: string;
    currentLearningWorldMission: string;
    currentLearningWorldImage: string;
    currentLearningWorldGrouped: boolean;
    currentLearningWorldLicenseId: string;
    currentLearningWorldSubject: string;
    currentLearningWorldLicenseRequired: boolean;
    currentLearningWorldPathSelection: boolean;
    currentLearningWorldSchoolId: string;
    currentLearningWorldPathIds: string[];
    currentLearningWorldIntroduction: string;
    currentLearningWorldTopicIds: string[],
    introductionAvatar: string;
    paths: Map<string, LearningPath>;
    user: User;
    collapse: boolean;
}

class LearningWorldView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: props.match.params.worldId,
            world: null,
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            currentLearningWorldName: '',
            currentLearningWorldDescription: '',
            currentLearningWorldBossName: '',
            currentLearningWorldMission: '',
            currentLearningWorldImage: '',
            currentLearningWorldGrouped: false,
            currentLearningWorldLicenseId: null,
            currentLearningWorldSubject: '',
            currentLearningWorldLicenseRequired: false,
            currentLearningWorldPathSelection: false,
            currentLearningWorldSchoolId: '',
            currentLearningWorldPathIds: [],
            currentLearningWorldIntroduction: '',
            currentLearningWorldTopicIds: [],
            introductionAvatar: '',
            paths: new Map(),
            user: props.user,
            collapse: props.match.params.worldId !== "-1",
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.toggle = this.toggle.bind(this);
        this.moveItemUp = this.moveItemUp.bind(this);
        this.persistWorldChange = this.persistWorldChange.bind(this);
        this.createPathRows = this.createPathRows.bind(this);
    }

    createPathRows(pathIds: string[], queryValues: string, worldEditable: boolean): JSX.Element[] {
        let rows: JSX.Element[] = [];

        if (pathIds == null) {
            return rows;
        }
        for (let pos = 0; pos < pathIds.length; pos++) {
            let nextPathId = pathIds[pos];
            let nextPath = this.state.paths.get(nextPathId);
            let editable = nextPath.schoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);
            rows.push(
                <tr key={"column-" + (pos)}>
                    <td>
                        <Link to={`/learningPaths/${nextPathId}?${queryValues}`}>
                            {nextPath.name}</Link>
                        {!editable ? <div style={{ "color": "red" }}>View only</div> : ""}
                        {editable && nextPath.licenseId != null ?
                            <div style={{ "color": "red" }}>Licensed: {nextPath.licenseId}</div> : ""}
                    </td>
                    <td>
                        <img height="50" src={`/images/worlds/${nextPath.topologyId}.jpg`} />
                    </td>
                    <td>
                        {worldEditable && pathIds[0] !== nextPathId ?
                            <Button color="success" onClick={async () => {
                                this.moveItemUp(nextPathId)
                            }}><i className="material-icons">arrow_upward</i></Button> : <span />}
                        &nbsp;
                        {editable ?
                            <Button color="primary" onClick={async () => {
                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove this path from the learning world", confirmText: "Confirm" });
                                if (result) {
                                    this.removeItem(nextPathId);
                                }
                            }}><i className="material-icons">delete</i></Button> : <span />
                        }
                    </td>
                </tr>
            );
        }
        return rows;
    }


    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.currentLearningWorldSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        let rows = this.createPathRows(this.state.currentLearningWorldPathIds, queryValues, editable);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.world != null ? this.state.world.name : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Learning world</div>
                        <p className="cardSubTitle">A learning world groups together learning paths for management and to help students when finding revision tasks</p>
                        {this.state.world != null ? <div>
                            {this.state.worldId !== "-1" ?
                                <Button onClick={this.toggle} className="altButton">
                                    {this.state.collapse ? "View details" : "Close"}
                                </Button> : null
                            }
                            <Collapse isOpen={!this.state.collapse}>
                                <Form onSubmit={this.handleUpdate}>
                                    <fieldset disabled={!editable}>
                                        <FormGroup>
                                            <Label for="currentLearningWorldName">Name *</Label>
                                            <Input type="text" required name="currentLearningWorldName" onChange={(e) => this.setState({
                                                currentLearningWorldName: e.target.value
                                            })} value={this.state.currentLearningWorldName} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentLearningWorldImage">Image *</Label>
                                            <Input type="select" name="currentLearningWorldImage"
                                                onChange={this.handleImageChange}
                                                value={this.state.currentLearningWorldImage}>
                                                <option value={''}>&nbsp;</option>
                                                {Array.from(worldImages.keys()).map((key) => {
                                                    let name = worldImages.get(key);
                                                    return (
                                                        <option value={key} key={key}>{name}</option>
                                                    )
                                                })
                                                }
                                            </Input><br />
                                            <img src={`/images/${this.state.currentLearningWorldImage}`} height="80px" alt="" /><br /><br />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentLearningWorldSubject">Subject *</Label>
                                            <Input disabled={this.state.worldId !== "-1"} type="select" name="currentLearningWorldSubject"
                                                onChange={(e) => this.setState({
                                                    currentLearningWorldSubject: e.target.value
                                                })}
                                                value={this.state.currentLearningWorldSubject}>
                                                <option value={''}>&nbsp;</option>
                                                {Array.from(subjectIds.keys()).map(subjectId => {
                                                    let subjectName = subjectIds.get(subjectId);
                                                    return <option value={subjectId}>{subjectName}</option>;
                                                })}
                                            </Input>
                                        </FormGroup>
                                        {!this.state.user.roles.get(USER_ROLE.learningCreator) ?
                                            <FormGroup>
                                                <Label for="currentLearningWorldIntroduction">Mission text</Label>
                                                <Input type="text" name="currentLearningWorldIntroduction" onChange={(e) => this.setState({
                                                    currentLearningWorldIntroduction: e.target.value
                                                })} value={this.state.currentLearningWorldIntroduction} />
                                            </FormGroup>
                                            : <span />}
                                        {!this.state.user.roles.get(USER_ROLE.learningCreator) ?
                                            <FormGroup>
                                                <Label for="introductionAvatar">Mission avatar</Label>
                                                <Input type="select" name="introductionAvatar" value={this.state.introductionAvatar} onChange={(e) => this.setState({
                                                    introductionAvatar: e.target.value
                                                })}>
                                                    <option value="">Huw</option>
                                                    {Array.from(bossTypes.keys()).map((key) => {
                                                        let name = bossTypes.get(key);
                                                        return (
                                                            <option value={key} key={key}>{name}</option>
                                                        )
                                                    })}
                                                </Input>
                                                <img src={'/images/characters/' + (this.state.introductionAvatar === "" ? "huw.png" : this.state.introductionAvatar)} height="80px" alt="" /><br /><br />
                                            </FormGroup>
                                            : <span />}
                                        {this.state.user.role === USER_ROLE.admin ?
                                            <FormGroup>
                                                <legend>Student selects paths?</legend>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" value="true" name='currentLearningWorldPathSelection'
                                                            checked={this.state.currentLearningWorldPathSelection}
                                                            onChange={(e) => this.setState({
                                                                currentLearningWorldPathSelection: e.target.value === 'true'
                                                            })} />
                                                            &nbsp;Yes
                                                        </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" value="false" name='currentLearningWorldPathSelection'
                                                            checked={!this.state.currentLearningWorldPathSelection}
                                                            onChange={(e) => this.setState({
                                                                currentLearningWorldPathSelection: e.target.value === 'true'
                                                            })} />
                                                            &nbsp;No
                                                        </Label>
                                                </FormGroup>
                                            </FormGroup> : <span />
                                        }
                                        {this.state.user.role === USER_ROLE.admin ?
                                            <FormGroup>
                                                <Label for="currentLearningWorldLicenseId">License id</Label>
                                                <Input type="text" name="currentLearningWorldLicenseId" onChange={(e) => this.setState({
                                                    currentLearningWorldLicenseId: e.target.value
                                                })} value={this.state.currentLearningWorldLicenseId} />
                                            </FormGroup> : <span />
                                        }
                                        {this.state.user.role === USER_ROLE.admin ?
                                            <FormGroup>
                                                <legend>Learning world requires a license?</legend>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" value="true" name='currentLearningWorldLicenseRequired'
                                                            checked={this.state.currentLearningWorldLicenseRequired}
                                                            onChange={(e) => this.setState({
                                                                currentLearningWorldLicenseRequired: e.target.value === 'true'
                                                            })} />
                                                            &nbsp;Yes
                                                        </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" value="false" name='currentLearningWorldLicenseRequired'
                                                            checked={!this.state.currentLearningWorldLicenseRequired}
                                                            onChange={(e) => this.setState({
                                                                currentLearningWorldLicenseRequired: e.target.value === 'true'
                                                            })} />
                                                            &nbsp;No
                                                        </Label>
                                                </FormGroup>
                                            </FormGroup> : <span />
                                        }
                                        {editable ? this.state.worldId === "-1" ?
                                            <Button className="adminPagesButton" disabled={this.state.currentLearningWorldImage === '' || this.state.currentLearningWorldSubject === ''}>Create</Button> : <Button className="adminPagesButton" disabled={this.state.currentLearningWorldImage === '' || this.state.currentLearningWorldSubject === ''}>Update</Button> : <span />
                                        }
                                    </fieldset>
                                </Form>
                            </Collapse></div> : <br />
                        }
                    </CardBody>
                </Card>
                {this.state.world != null && this.state.worldId !== "-1" ?
                    <Card className="mainCard top-buffer">
                        <CardBody className="d-flex flex-column">
                            <div>
                                <div className="cardTitle2">Learning paths</div>
                                {editable ?
                                    <div className="top-buffer bottom-buffer">
                                        <Link to={`/learningPaths/-1?${queryValues}`}>
                                            <Button className="adminPagesButton" type="button">
                                                Add path
                                            </Button>
                                        </Link>
                                    </div> : <span />
                                }
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Topology</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card> : <br />
                }
                <br /><br /><br />
            </div >
        );
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.world != null ? this.state.world.name : "",
            subjectFilter: this.state.subjectFilter,
        });
    }

    handleImageChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            currentLearningWorldImage: e.target.value
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let world = this.state.world;
        world.name = this.state.currentLearningWorldName;
        world.description = this.state.currentLearningWorldDescription;
        world.bossName = this.state.currentLearningWorldBossName;
        world.mission = this.state.currentLearningWorldMission;
        world.image = this.state.currentLearningWorldImage;
        world.pathSelection = this.state.currentLearningWorldPathSelection;
        world.pathIds = this.state.currentLearningWorldPathIds;
        world.licenseId = this.state.currentLearningWorldLicenseId !== "" ? this.state.currentLearningWorldLicenseId : null;
        world.licenseRequired = this.state.currentLearningWorldLicenseRequired;
        world.subject = this.state.currentLearningWorldSubject;
        world.introduction = this.state.currentLearningWorldIntroduction;
        world.introductionAvatar = this.state.introductionAvatar;
        world.topicIds = this.state.currentLearningWorldTopicIds;
        world.deleted = null;

        if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
            world.schoolId = this.state.user.schoolId;
        }

        let worldObject = world.toFirebase();
        if (this.state.worldId === "-1") {

            try {
                const worldRef = firebase.firestore().collection('learningWorlds');
                let ref = await worldRef.add(worldObject);
                let newPostKey = ref.id;
                this.setState({
                    worldId: newPostKey,
                    world: world,
                    collapse: true,
                });
                this.props.history.replace(`/learningWorlds/${newPostKey}?${this.getQuerys()}`);
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        } else {
            await firebase.firestore().doc(`learningWorlds/${this.state.worldId}`).update(worldObject);
            this.persistWorldChange();
            this.setState({
                world: world,
                collapse: true,
            });
            this.props.snackbar();
        }
    }

    async persistWorldChange(): Promise<void> {
        let updateDeets = {
            updated: firebase.database.ServerValue.TIMESTAMP,
            updateType: 'WORLD',
            updateId: this.state.worldId,
        };
        if (this.state.currentLearningWorldSchoolId == null || this.state.currentLearningWorldSchoolId === '') {
            await firebase.database().ref('moduleInformation/changeHistory').push(updateDeets);
        } else {
            await firebase.database().ref(`moduleInformation/changeHistorySchools/${this.state.currentLearningWorldSchoolId}`).push(updateDeets);
        }
    }

    async moveItemUp(pathId: string): Promise<void> {
        let index = this.state.currentLearningWorldPathIds.indexOf(pathId);
        this.state.currentLearningWorldPathIds.splice(index - 1, 0, this.state.currentLearningWorldPathIds.splice(index, 1)[0]);
        await firebase.firestore().doc(`learningWorlds/${this.state.worldId}`).set({ pathIds: this.state.currentLearningWorldPathIds }, { merge: true });
        await this.persistWorldChange();
        this.setState({});
    }

    async componentDidMount(): Promise<void> {
        if (this.state.worldId !== "-1") {
            const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
            try {
                let worldSnapshot = await worldRef.get();
                let worldData = worldSnapshot.data();
                let world = LearningWorld.fromFirebase(worldData);
                let worldPaths = new Map<string, LearningPath>();
                let promises = [];
                for (let nextPathIndex in world.pathIds) {
                    let nextPathId = world.pathIds[nextPathIndex];
                    console.log("Getting path", nextPathId);
                    const pathRef = firebase.firestore().doc(`learningPaths/${nextPathId}`);
                    promises.push(new Promise<void>((resolve, reject) => {
                        pathRef.get().then((pathSnapshot) => {
                            let pathData = pathSnapshot.data();
                            let path: LearningPath = LearningPath.fromFirebase(pathData);
                            worldPaths.set(nextPathId, path);
                            resolve();
                        }, (error) => {
                            console.log(error);
                            reject();
                        })
                    }));
                }
                console.log("Awaiting promises");
                await Promise.all(promises);
                console.log("All promises done");
                this.setState({
                    world: world,
                    currentLearningWorldName: world.name,
                    currentLearningWorldDescription: world.description != null ? world.description : null,
                    currentLearningWorldBossName: world.bossName != null ? world.bossName : null,
                    currentLearningWorldMission: world.mission != null ? world.mission : null,
                    currentLearningWorldImage: world.image,
                    currentLearningWorldGrouped: world.grouped,
                    currentLearningWorldLicenseId: world.licenseId != null ? world.licenseId : null,
                    currentLearningWorldPathIds: world.pathIds,
                    currentLearningWorldLicenseRequired: world.licenseRequired,
                    currentLearningWorldSchoolId: world.schoolId,
                    currentLearningWorldSubject: world.subject == null ? '' : world.subject,
                    currentLearningWorldIntroduction: world.introduction == null ? '' : world.introduction,
                    currentLearningWorldTopicIds: world.topicIds == null ? [] : world.topicIds,
                    introductionAvatar: world.introductionAvatar == null ? '' : world.introductionAvatar,
                    paths: worldPaths,
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            this.setState({
                world: new LearningWorld(),
                currentLearningWorldName: "",
                currentLearningWorldDescription: "",
                currentLearningWorldBossName: "",
                currentLearningWorldMission: "",
                currentLearningWorldImage: Array.from(worldImages.keys())[0].substring('images/'.length),
                currentLearningWorldGrouped: false,
                currentLearningWorldLicenseId: null,
                currentLearningWorldLicenseRequired: false,
                currentLearningWorldPathIds: [],
                currentLearningWorldSubject: this.state.subjectFilter,
                currentLearningWorldIntroduction: '',
                currentLearningWorldTopicIds: [],
                introductionAvatar: '',
                currentLearningWorldSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
            });
        }
    }

    async removeItem(pathId: string): Promise<void> {
        let pathIndex = this.state.currentLearningWorldPathIds.indexOf(pathId);
        this.state.currentLearningWorldPathIds.splice(pathIndex, 1);
        this.state.world.pathIds = this.state.currentLearningWorldPathIds;

        const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
        try {
            await worldRef.update({ pathIds: this.state.currentLearningWorldPathIds });

            this.persistWorldChange();

            this.setState({});
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Remove failed");
        }
    }
}

export default LearningWorldView;
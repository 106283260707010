//@ts-check

import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import RootView from "./root_view";
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert,
    UncontrolledTooltip, Card, CardBody
} from 'reactstrap';
import TopicsSelector from "./ninja_components/topics_selector";
import { StoreLearningGame } from "./helpers/topic_services";
import { TIME_MULTIPLIER, BossGame, BossGameRound, LearningGameBadge } from './data/learning_games';
import { User, USER_ROLE } from './data/user';
import { v4 as uuidv4 } from 'uuid';
import { bossTypes, animalTypes, vehicleTypes, soundNames, subjectIds } from './Prettifier';

interface IState {
    worldId: string;
    worldName: string;
    subjectFilter: string;
    pathId: string;
    pathName: string;
    gameId: string;
    timeMultiplier: TIME_MULTIPLIER,
    animalType: string;
    animalTypeUrl: string;
    bossType: string;
    bossTypeUrl: string;
    bossLevel: BossGame,
    bossVehicleType: string;
    playerVehicleType: string;
    currentLearningBossLevelName: string;
    currentLearningBossLevelDescription: string;
    currentLearningBossLevelAnimalName: string;
    currentLearningBossLevelBossTextBefore: string;
    currentLearningBossLevelBossTextAfter: string;
    currentLearningBossLevelBossTextFail: string;
    currentLearningBossLevelAvatarTextBefore: string;
    currentLearningBossLevelAvatarTextAfter: string;
    currentSubjectId: string;
    currentTopics: string[];
    currentTopicsTags: Map<string, string[]>;
    bossRounds: BossGameRound[];
    currentLearningBossLevelHidden: boolean,
    currentLearningBossLevelSchoolId: string;
    currentLearningBossLevelLicenseId: string;
    currentLearningBossLevelSound: string;
    user: User;
    collapse: boolean;
}

interface MatchParams {
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class learningBossLevelView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            gameId: props.match.params.gameId,
            timeMultiplier: TIME_MULTIPLIER.moderate,
            animalType: '',
            animalTypeUrl: '',
            bossType: '',
            bossTypeUrl: '',
            bossLevel: null,
            bossVehicleType: '',
            playerVehicleType: '',
            currentLearningBossLevelName: '',
            currentLearningBossLevelDescription: '',
            currentLearningBossLevelAnimalName: '',
            currentLearningBossLevelBossTextBefore: '',
            currentLearningBossLevelBossTextAfter: '',
            currentLearningBossLevelBossTextFail: '',
            currentLearningBossLevelAvatarTextBefore: '',
            currentLearningBossLevelAvatarTextAfter: '',
            currentSubjectId: '',
            currentTopics: [],
            currentTopicsTags: new Map<string, string[]>(),
            bossRounds: [],
            currentLearningBossLevelHidden: false,
            currentLearningBossLevelSchoolId: '',
            currentLearningBossLevelLicenseId: null,
            currentLearningBossLevelSound: '',
            user: props.user,
            collapse: props.match.params.gameId !== "-1",
        };
        this.dealWithChange = this.dealWithChange.bind(this);
        this.animalChange = this.animalChange.bind(this);
        this.bossChange = this.bossChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.handleRoundTargetChange = this.handleRoundTargetChange.bind(this);
        this.handleRoundNumberChange = this.handleRoundNumberChange.bind(this);
        this.getGameQuerys = this.getGameQuerys.bind(this);
    }


    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.currentLearningBossLevelSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All minigames</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem> : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem> : null}
                        <BreadcrumbItem active>Boss Level</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Learning Boss level</div>
                        <p className="cardSubTitle">A Boss Level is a chance the user has to prove their skills by battling against a Boss.</p>
                        {(this.state.bossLevel != null) ?
                            <div>
                                <div>
                                    <a href={`https://zapdemo.web.app/#/boss?${this.getGameQuerys()}`} target="_blank"><Button type="button" className="adminPagesButton">Play now</Button></a><br />
                            Note that the background of the Boss game changes depending on the learning path it is part of
                        </div><br />
                                <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                    <fieldset disabled={!editable}>
                                        <FormGroup>
                                            <Label for="currentLearningBossLevelName">Title</Label>
                                            <Input maxLength={30} type="text" required name="currentLearningBossLevelName" onChange={(e) => this.setState({
                                                currentLearningBossLevelName: e.target.value
                                            })} value={this.state.currentLearningBossLevelName} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentLearningBossLevelDescription">Instructions</Label>
                                            <Input type="text" required name="currentLearningBossLevelDescription" onChange={(e) => this.setState({
                                                currentLearningBossLevelDescription: e.target.value
                                            })} value={this.state.currentLearningBossLevelDescription}></Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentLearningBossLevelSound">Audio instruction *</Label>
                                            <Input type="select" name="currentLearningBossLevelSound"
                                                onChange={(e) => this.setState({
                                                    currentLearningBossLevelSound: e.target.value
                                                })}
                                                value={this.state.currentLearningBossLevelSound}>
                                                <option value={''}>&nbsp;</option>
                                                {Array.from(soundNames.keys()).map((key) => {
                                                    let name = soundNames.get(key);
                                                    return (
                                                        <option value={key} key={key}>{name}</option>
                                                    )
                                                })
                                                }
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentSubjectId">Subject *</Label>
                                            <Input disabled={this.state.worldId != null || this.state.gameId !== "-1"} type="select" name="currentSubjectId"
                                                onChange={(e) => this.setState({
                                                    currentSubjectId: e.target.value
                                                })}
                                                value={this.state.currentSubjectId}>
                                                <option value={''}>&nbsp;</option>
                                                {Array.from(subjectIds.keys()).map(subjectId => {
                                                    let subjectName = subjectIds.get(subjectId);
                                                    return <option value={subjectId}>{subjectName}</option>;
                                                })}
                                            </Input>
                                        </FormGroup>
                                        {this.state.currentSubjectId === '' ? <div></div> :
                                            <TopicsSelector key={"bob"} countryCode={this.state.user.countryCode} subject={this.state.currentSubjectId} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags} callback={this.handleTopicsChange} />
                                        }
                                        <label>
                                            Game speed <i className="fas fa-info-circle icons-info" id="help-learning-timemultiplier" />
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-learning-timemultiplier">
                                                Control how fast or slow the game will play.
                                                Overall paths can have speed controls and the game speed will work within the context of the path speed control.
                                                For example, a game with a 'fast' speed on a path with a 'slow' speed will play at 'moderate' speed.
                                                Use this to allow developing or advanced students to play at a speed that suits them.
                                        </UncontrolledTooltip>
                                        </label>
                                        <Input type="select" value={this.state.timeMultiplier} onChange={this.dealWithChange}>
                                            <option value="fastest">Fastest</option>
                                            <option value="fast">Fast</option>
                                            <option value="moderate">Moderate</option>
                                            <option value="slow">Slow</option>
                                            <option value="slowest">Slowest</option>
                                        </Input>
                                        <br />
                                        <FormGroup>
                                            <Label for="animalType">Animal to be rescued</Label>
                                            <Input type="select" name="animalType" value={this.state.animalType} onChange={this.animalChange}>
                                                {Array.from(animalTypes.keys()).map((key) => {
                                                    let name = animalTypes.get(key);
                                                    return (
                                                        <option value={key} key={key}>{name}</option>
                                                    )
                                                })}
                                                {/* <option value="custom" key="custom">Custom - Image from the internet</option> */}
                                            </Input>
                                            {this.state.animalType === "custom" ?
                                                <div>
                                                    <FormGroup>
                                                        <Label for="animalTypeUrl">Custom animal image</Label>
                                                        <Input type="text" name="animalTypeUrl" onChange={(e) => this.setState({
                                                            animalTypeUrl: e.target.value
                                                        })} value={this.state.animalTypeUrl} />
                                                    </FormGroup>
                                                    <img src={this.state.animalTypeUrl} height="80px" alt="" />
                                                </div> :
                                                <img src={'/images/characters/' + this.state.animalType} height="80px" alt="" />
                                            }
                                            <br /><br />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentLearningBossLevelAnimalName">Animal name</Label>
                                            <Input type="text" name="currentLearningBossLevelAnimalName" onChange={(e) => this.setState({
                                                currentLearningBossLevelAnimalName: e.target.value
                                            })} value={this.state.currentLearningBossLevelAnimalName}></Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="bossType">Evil boss</Label>
                                            <Input type="select" name="bossType" value={this.state.bossType} onChange={this.bossChange}>
                                                {Array.from(bossTypes.keys()).map((key) => {
                                                    let name = bossTypes.get(key);
                                                    return (
                                                        <option value={key} key={key}>{name}</option>
                                                    )
                                                })}
                                                {/* <option value="custom" key="custom">Custom - Image from the internet</option> */}
                                            </Input>
                                            {this.state.bossType === "custom" ?
                                                <div>
                                                    <FormGroup>
                                                        <Label for="bossTypeUrl">Custom boss image</Label>
                                                        <Input type="text" name="bossTypeUrl" onChange={(e) => this.setState({
                                                            bossTypeUrl: e.target.value
                                                        })} value={this.state.bossTypeUrl} />
                                                    </FormGroup>
                                                    <img src={this.state.bossTypeUrl} height="80px" alt="" />
                                                </div> :
                                                <img src={'/images/characters/' + this.state.bossType} height="80px" alt="" />
                                            }
                                            <br /><br />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="bossVehicleType">Boss vehicle</Label>
                                            <Input type="select" name="bossVehicleType" value={this.state.bossVehicleType} onChange={(e) => this.setState({
                                                bossVehicleType: e.target.value
                                            })}>
                                                {Array.from(vehicleTypes.keys()).map((key) => {
                                                    let name = vehicleTypes.get(key);
                                                    return (
                                                        <option value={key} key={key}>{name}</option>
                                                    )
                                                })}
                                            </Input>
                                            <img src={`/images/vehicles/${this.state.bossVehicleType}.png`} height="80px" alt="" /><br /><br />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="playerVehicleType">Player vehicle</Label>
                                            <Input type="select" name="playerVehicleType" value={this.state.playerVehicleType} onChange={(e) => this.setState({
                                                playerVehicleType: e.target.value
                                            })}>
                                                {Array.from(vehicleTypes.keys()).map((key) => {
                                                    let name = vehicleTypes.get(key);
                                                    return (
                                                        <option value={key} key={key}>{name}</option>
                                                    )
                                                })}
                                            </Input>
                                            <img src={`/images/vehicles/${this.state.playerVehicleType}.png`} height="80px" alt="" /><br /><br />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="currentLearningBossLevelBossTextBefore">Boss says before challenge</Label>
                                            <Input type="text" name="currentLearningBossLevelBossTextBefore" onChange={(e) => this.setState({
                                                currentLearningBossLevelBossTextBefore: e.target.value
                                            })} value={this.state.currentLearningBossLevelBossTextBefore}></Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentLearningBossLevelAvatarTextBefore">Player response before challenge</Label>
                                            <Input type="text" name="currentLearningBossLevelAvatarTextBefore" onChange={(e) => this.setState({
                                                currentLearningBossLevelAvatarTextBefore: e.target.value
                                            })} value={this.state.currentLearningBossLevelAvatarTextBefore}></Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentLearningBossLevelBossTextAfter">Boss says if player wins challenge</Label>
                                            <Input type="text" name="currentLearningBossLevelBossTextAfter" onChange={(e) => this.setState({
                                                currentLearningBossLevelBossTextAfter: e.target.value
                                            })} value={this.state.currentLearningBossLevelBossTextAfter}></Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentLearningBossLevelAvatarTextAfter">Player response if wins challenge</Label>
                                            <Input type="text" name="currentLearningBossLevelAvatarTextAfter" onChange={(e) => this.setState({
                                                currentLearningBossLevelAvatarTextAfter: e.target.value
                                            })} value={this.state.currentLearningBossLevelAvatarTextAfter}></Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentLearningBossLevelBossTextFail">Boss says if player fails challenge</Label>
                                            <Input type="text" name="currentLearningBossLevelBossTextFail" onChange={(e) => this.setState({
                                                currentLearningBossLevelBossTextFail: e.target.value
                                            })} value={this.state.currentLearningBossLevelBossTextFail}></Input>
                                        </FormGroup>

                                        <FormGroup>
                                            <h4>Boss rounds <i className="fas fa-info-circle icons-info" id="help-learningboss-rounds" /></h4>
                                            {this.state.bossRounds.map((answer, roundIndex) => {
                                                return <div className="border rounded form-margin">
                                                    <div><b>Round {roundIndex + 1}</b></div>
                                                    <div>Correct button</div>
                                                    <Input required name={`currentBossRounds-correct-${roundIndex}`}
                                                        onChange={(value) => this.handleRoundTargetChange(value, roundIndex)}
                                                        value={this.state.bossRounds[roundIndex].correct} />
                                                    <div>Incorrect buttons</div>
                                                    <Input required name={`currentBossRounds-incorrect-${roundIndex}-0`}
                                                        onChange={(value) => this.handleRoundNumberChange(value, roundIndex, 0)}
                                                        value={this.state.bossRounds[roundIndex].incorrect[0]} />
                                                    <Input required name={`currentBossRounds-incorrect-${roundIndex}-1`}
                                                        onChange={(value) => this.handleRoundNumberChange(value, roundIndex, 1)}
                                                        value={this.state.bossRounds[roundIndex].incorrect[1]} />
                                                </div>
                                            })}
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningboss-rounds">
                                                Enter the numbers that will appear in each round. Correct number is the one the player should select.
                                        </UncontrolledTooltip>
                                        </FormGroup>

                                        {this.state.user.role === USER_ROLE.admin ?
                                            <FormGroup>
                                                <Label for="currentLearningBossLevelLicenseId">License id</Label>
                                                <Input type="text" name="currentLearningBossLevelLicenseId" onChange={(e) => this.setState({
                                                    currentLearningBossLevelLicenseId: e.target.value
                                                })} value={this.state.currentLearningBossLevelLicenseId} />
                                            </FormGroup> : <span />
                                        }
                                        {editable ? this.state.gameId === "-1" ?
                                            <Button disabled={this.state.currentSubjectId === ''} className="adminPagesButton">Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                        }
                                    </fieldset>
                                </Form>
                                <br />

                            </div> : <div />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    getGameQuerys(): string {
        return queryString.stringify({
            title: this.state.currentLearningBossLevelName,
            bossTextBefore: this.state.currentLearningBossLevelBossTextBefore,
            bossTextAfter: this.state.currentLearningBossLevelBossTextAfter,
            bossTextFail: this.state.currentLearningBossLevelBossTextFail,
            avatarTextBefore: this.state.currentLearningBossLevelAvatarTextBefore,
            avatarTextAfter: this.state.currentLearningBossLevelAvatarTextAfter,
            bossVehicleType: this.state.bossVehicleType,
            playerVehicleType: this.state.playerVehicleType,
            instructions: this.state.currentLearningBossLevelDescription,
            animalType: this.state.animalType,
            bossType: this.state.bossType,
            animalName: this.state.currentLearningBossLevelAnimalName,
            rounds: JSON.stringify(this.state.bossRounds),
        });
    }

    handleRoundNumberChange(e: React.ChangeEvent<HTMLInputElement>, roundIndex: number, index: number): void {
        let newNumber = e.target.value;
        this.state.bossRounds[roundIndex].incorrect[index] = newNumber;

        this.setState({
        });
    }

    handleRoundTargetChange(e: React.ChangeEvent<HTMLInputElement>, roundIndex: number): void {
        let newNumber = e.target.value;
        this.state.bossRounds[roundIndex].correct = newNumber;
        this.setState({});
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId,
            gameName: this.state.currentLearningBossLevelName,
            gameType: 'BOSSLEVEL',
        });
    }

    dealWithChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    animalChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            animalType: e.target.value
        });
    }

    bossChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            bossType: e.target.value
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let bossLevel = this.state.bossLevel;
        let newBossLevel = false;
        let gameId = this.state.gameId;
        if (this.state.gameId === "-1") {
            newBossLevel = true;
            gameId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                bossLevel.schoolId = this.state.user.schoolId;
            }
        }
        bossLevel.title = this.state.currentLearningBossLevelName;
        bossLevel.bossTextBefore = this.state.currentLearningBossLevelBossTextBefore;
        bossLevel.bossTextAfter = this.state.currentLearningBossLevelBossTextAfter;
        bossLevel.bossTextFail = this.state.currentLearningBossLevelBossTextFail;
        bossLevel.avatarTextBefore = this.state.currentLearningBossLevelAvatarTextBefore;
        bossLevel.avatarTextAfter = this.state.currentLearningBossLevelAvatarTextAfter;
        bossLevel.instructions = this.state.currentLearningBossLevelDescription;
        bossLevel.hidden = this.state.currentLearningBossLevelHidden;
        bossLevel.sound = this.state.currentLearningBossLevelSound;
        bossLevel.subjectId = this.state.currentSubjectId;
        bossLevel.topics = this.state.currentTopics;
        bossLevel.topicTags = this.state.currentTopicsTags;
        bossLevel.licenseId = this.state.currentLearningBossLevelLicenseId !== "" ? this.state.currentLearningBossLevelLicenseId : null;
        bossLevel.timeMultiplier = this.state.timeMultiplier;
        bossLevel.animalType = this.state.animalType;
        bossLevel.bossType = this.state.bossType;
        bossLevel.animalName = this.state.currentLearningBossLevelAnimalName;
        bossLevel.bossVehicleType = this.state.bossVehicleType;
        bossLevel.playerVehicleType = this.state.playerVehicleType;
        bossLevel.animalTypeUrl = this.state.animalTypeUrl;
        bossLevel.bossTypeUrl = this.state.bossTypeUrl;
        bossLevel.rounds = this.state.bossRounds;
        bossLevel.deleted = null;
        let badge = new LearningGameBadge();
        badge.name = gameId;
        badge.badgeImageUrl = this.state.animalType !== "custom" ? `characters/${this.state.animalType}` : this.state.animalTypeUrl;
        badge.networkBadge = this.state.animalType === "custom";
        badge.overlayText = this.state.currentLearningBossLevelAnimalName;
        bossLevel.badge = badge;
        bossLevel.type = BossGame.TYPE;
        try {
            await StoreLearningGame(newBossLevel, bossLevel, gameId, this.state.pathId, this.state.currentLearningBossLevelSchoolId);
            this.props.history.replace(`/learningBossLevel/${gameId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
        this.setState({
            gameId: gameId
        })
    }

    async componentDidMount(): Promise<void> {

        let initialBossRounds = new Array<BossGameRound>();
        for (let i = 0; i < 3; i++) {
            let round = new BossGameRound();
            round.correct = "0";
            round.incorrect = ["1", "2"];
            initialBossRounds.push(round);
        }


        if (this.state.gameId !== "-1") {
            let bossLevelSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
            let bossLevelData = bossLevelSnapshot.data();
            let bossLevel = BossGame.fromFirebase(bossLevelData);
            this.setState({
                bossLevel: bossLevel,
                currentLearningBossLevelName: bossLevel.title,
                currentLearningBossLevelBossTextAfter: bossLevel.bossTextAfter,
                currentLearningBossLevelBossTextBefore: bossLevel.bossTextBefore,
                currentLearningBossLevelBossTextFail: bossLevel.bossTextFail,
                currentLearningBossLevelAvatarTextBefore: bossLevel.avatarTextBefore,
                currentLearningBossLevelAvatarTextAfter: bossLevel.avatarTextAfter,
                currentLearningBossLevelDescription: bossLevel.instructions,
                currentLearningBossLevelAnimalName: bossLevel.animalName == null ? "" : bossLevel.animalName,
                currentTopics: bossLevel.topics == null ? [] : bossLevel.topics,
                currentTopicsTags: bossLevel.topicTags == null ? new Map<string, string[]>() : bossLevel.topicTags,
                currentLearningBossLevelHidden: bossLevel.hidden,
                currentLearningBossLevelSchoolId: bossLevel.schoolId,
                currentLearningBossLevelLicenseId: bossLevel.licenseId != null ? bossLevel.licenseId : null,
                currentLearningBossLevelSound: bossLevel.sound == null ? '' : bossLevel.sound,
                currentSubjectId: bossLevel.subjectId,
                timeMultiplier: bossLevel.timeMultiplier != null ? bossLevel.timeMultiplier : TIME_MULTIPLIER.moderate,
                animalType: bossLevel.animalType != null ? bossLevel.animalType : 'baby-penguin1.png',
                animalTypeUrl: bossLevel.animalTypeUrl != null ? bossLevel.animalTypeUrl : '',
                bossType: bossLevel.bossType != null ? bossLevel.bossType : 'octopus.png',
                bossTypeUrl: bossLevel.bossTypeUrl != null ? bossLevel.bossTypeUrl : '',
                bossVehicleType: bossLevel.bossVehicleType != null ? bossLevel.bossVehicleType : "blue-heli-1",
                playerVehicleType: bossLevel.playerVehicleType != null ? bossLevel.playerVehicleType : "green-heli-1",
                bossRounds: bossLevel.rounds != null ? bossLevel.rounds : initialBossRounds,
            });
        } else {
            let subjectId = '';
            if (this.state.worldId != null) {
                const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                let worldSnapshot = await worldRef.get();
                let world = worldSnapshot.data();
                subjectId = world.subject;
            } else {
                //@ts-ignore
                subjectId = this.state.subjectFilter;
            }
            this.setState({
                bossLevel: new BossGame(),
                currentLearningBossLevelName: '',
                currentLearningBossLevelDescription: '',
                currentLearningBossLevelAnimalName: '',
                currentLearningBossLevelBossTextBefore: '',
                currentLearningBossLevelBossTextAfter: '',
                currentLearningBossLevelBossTextFail: '',
                currentLearningBossLevelAvatarTextAfter: '',
                currentLearningBossLevelAvatarTextBefore: '',
                currentSubjectId: subjectId,
                currentTopics: [],
                currentTopicsTags: new Map<string, string[]>(),
                currentLearningBossLevelHidden: false,
                currentLearningBossLevelSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                currentLearningBossLevelLicenseId: null,
                currentLearningBossLevelSound: '',
                bossRounds: initialBossRounds,
                timeMultiplier: TIME_MULTIPLIER.moderate,
                animalType: 'baby-penguin1.png',
                animalTypeUrl: '',
                bossType: 'octopus.png',
                bossTypeUrl: '',
                bossVehicleType: "blue-heli-1",
                playerVehicleType: "green-heli-1",
            });
        }
    }
}

export default learningBossLevelView;
import React, {Component} from 'react';
import firebase from './firebase';
import {Link, RouteComponentProps} from "react-router-dom";
import {Table, Button, BreadcrumbItem, Breadcrumb, Alert, Input} from 'reactstrap';
import { User } from './data/user';
import { AccountGroup, AccountMinimised, AccountSubAccount } from './data/accounts';

interface IState {
    subAccounts: Map<string, SubAccountOverview>,
    accountGroup: AccountGroup,
    downloadedAccounts: string[],
    sendEmailList: string[],
    accountGroupId: string;
    appId: string;
    inviteSending: boolean;
}

interface MatchParams {
    accountGroupId: string;
    appId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    accountsMinimised: Map<string, AccountMinimised>;
    snackbar: (text?: string) => void;
}

interface SubAccountOverview {
    id: string;
    sendError: boolean;
    accountId: string;
    inviteSent: boolean;
    relationship: string;
    email: string;
}

class AccountGroupEmailView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            subAccounts: null,
            accountGroup: null,
            downloadedAccounts: [],
            sendEmailList: [],
            accountGroupId: props.match.params.accountGroupId,
            appId: props.match.params.appId,
            inviteSending: false,
        };
        this.sendInvites = this.sendInvites.bind(this);
        this.sendAccountEmail = this.sendAccountEmail.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    wait = (ms: number) => new Promise((r, j) => setTimeout(r, ms));

    render(): JSX.Element {

        return (
            <div className='container'>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/accountGroups'}>All account groups</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/accountGroups/${this.state.accountGroupId}`}>{this.state.accountGroup != null ? this.state.accountGroup.details.name : ""}</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Bulk invite send</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Alert color="dark">
                    <h3>Bulk email send</h3>
                    <p>Send an invite email
                        for {this.state.appId === "LearningNinja" ? "Learning Ninja" : "Message Ninja"} {this.state.accountGroup != null ? "to " + this.state.accountGroup.details.name : ""}</p>
                </Alert>
                {this.state.subAccounts != null ?
                    <div>
                        <Table>
                            <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Account</th>
                                <th>Email</th>
                                <th>Additional</th>
                                <th>Downloaded</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Array.from(this.state.subAccounts.keys()).map((subAccountId) => {
                                let subAccount = this.state.subAccounts.get(subAccountId);
                                let account = this.props.accountsMinimised.get(subAccount.accountId);
                                if (account == null) {
                                    return null;
                                }
                                return <tr color={subAccount.inviteSent ? "green" : subAccount.sendError ? "red" : ""}>
                                    <td>
                                        <Input type="checkbox" name={subAccount.id}
                                               checked={this.state.sendEmailList.indexOf(subAccount.id) !== -1}
                                               onChange={this.handleCheckboxChange}/>
                                    </td>
                                    <td>{account.name}{subAccount.relationship != null && subAccount.relationship !== "" ? ` - ${subAccount.relationship}` : ""}</td>
                                    <td>{subAccount.email}</td>
                                    <td>{account.additional}</td>
                                    <td>{this.state.downloadedAccounts.indexOf(subAccount.id) !== -1 ? "Yes" : "No"}</td>
                                </tr>
                            })}
                            </tbody>
                        </Table><br/>
                        <div>
                            <Button color="primary" type="button" disabled={this.state.inviteSending} onClick={() => {
                                this.sendInvites();
                            }}>
                                Send invites
                            </Button>
                        </div>
                    </div> : <span/>
                }
            </div>
        );
    }

    async sendInvites(): Promise<void> {
        this.setState({
            inviteSending: true,
        });

        let overallSuccess = true;
        for (let i = 0; i < this.state.sendEmailList.length; i++) {
            let subAccount = this.state.subAccounts.get(this.state.sendEmailList[i]);
            let success = await this.sendAccountEmail(subAccount);
            if (!success) {
                overallSuccess = false;
            }
            await this.wait(250); // Don't overload email service
        }

        this.setState({
            inviteSending: false,
        });
        if (overallSuccess) {
            this.props.snackbar("All emails sent");
        } else {
            this.props.snackbar("There were send failures, check for red rows");
        }
    }

    handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
        let index = this.state.sendEmailList.indexOf(e.target.name);
        if (index === -1 && e.target.checked) {
            this.state.sendEmailList.push(e.target.name);
        } else if (index !== -1 && !e.target.checked) {
            this.state.sendEmailList.splice(index, 1);
        }
        this.setState({
        });
    }

    async sendAccountEmail(subAccount: SubAccountOverview): Promise<boolean> {
        let idToken = await firebase.auth().currentUser.getIdToken();
        let authorization = 'Bearer ' + idToken;
        let response = await fetch('https://admin.lifeninja.net/adminRequests/sendAccountWelcome', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            },
            body: JSON.stringify({
                accountId: subAccount.accountId,
                schoolId: this.props.user.schoolId,
                subAccountId: subAccount.id,
                appName: this.state.appId,

            })
        });
        let success;
        if (response.status === 200) {
            subAccount.inviteSent = true;
            success = true;
        } else {
            subAccount.sendError = true;
            success = false;
        }
        this.setState({});
        return success;
    }

    async componentDidMount(): Promise<void> {
        try {
            const itemsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${this.state.accountGroupId}`);
            let accountGroupSnapshot = await itemsRef.once('value');
            let accountGroup = accountGroupSnapshot.val();

            const accountsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts`);
            let snapshot = await accountsRef.once('value');
            let subAccounts = snapshot.val();
            let subAccountsMap = new Map<string, SubAccountOverview>();
            let promises: Promise<any>[] = [];
            let downloadedAccounts: string[] = [];
            let nowDownloadedAccounts: string[] = [];
            let stateSet = false;
            for (let nextAccountId in subAccounts) {
                if (accountGroup.accounts[nextAccountId] != null) {
                    let accountDeets = accountGroup.accounts[nextAccountId];
                    if (accountDeets.member == null || !accountDeets.member) {
                        continue;
                    }
                    let account = this.props.accountsMinimised.get(nextAccountId);
                    if (account == null) {
                        continue;
                    }
                    let nextSubAccountList = subAccounts[nextAccountId].subAccounts;
                    for (let nextSubAccountId in nextSubAccountList) {
                        let nextSubAccount = nextSubAccountList[nextSubAccountId];
                        if (nextSubAccount.accountType === "username") {
                            continue;
                        }
                        nextSubAccount.inviteSent = false;
                        nextSubAccount.id = nextSubAccountId;
                        nextSubAccount.accountId = nextAccountId;
                        subAccountsMap.set(nextSubAccountId,  nextSubAccount);
                        let emailEscaped = nextSubAccount.email.replace(/\./g, ',');
                        const registeredRef = firebase.database().ref(`schoolUsers/${emailEscaped}/currentAccountId`);
                        promises.push(registeredRef.once('value').then((snapshot) => {
                            if (snapshot != null && snapshot.val() != null) {
                                console.log("snapshot val", snapshot.val());
                                downloadedAccounts.push(nextSubAccount.id);
                            } else {
                                nowDownloadedAccounts.push(nextSubAccount.id);
                            }
                            if (stateSet) {
                                this.setState({
                                    downloadedAccounts: downloadedAccounts,
                                    sendEmailList: nowDownloadedAccounts,
                                })
                            }
                        }));
                    }
                }
            }

            await Promise.all(promises);

            stateSet = true;
            this.setState({
                accountGroup: accountGroup,
                subAccounts: subAccountsMap,
                downloadedAccounts: downloadedAccounts,
                sendEmailList: nowDownloadedAccounts,
            })
        } catch (error) {
            console.log(error);
        }
    }
}

export default AccountGroupEmailView;
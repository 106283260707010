import React, { useRef, useEffect, Component } from 'react'
import { WorksheetPointList } from '../data/home_learning';

interface IProps {
    points: WorksheetPointList[];
}

interface LineDetails {
    x: number;
    y: number;
    x1: number;
    y1: number;
    type: string;
    color: string;
    size: number;
}

const Canvas = (props: IProps) => {

    const canvas = useRef<HTMLCanvasElement>();
    let ctx: CanvasRenderingContext2D = null;

    // initialize the canvas context
    useEffect(() => {
        // dynamically assign the width and height to canvas
        const canvasEle = canvas.current;
        canvasEle.width = canvasEle.clientWidth;
        canvasEle.height = canvasEle.clientHeight;

        // get context of the canvas
        ctx = canvasEle.getContext("2d");
    }, []);

    useEffect(() => {
        console.log("Here");
        for (let nextPointList of props.points) {
            let previousPoint;
            let type = nextPointList.type;
            let colorHex = nextPointList.adjustments != null && nextPointList.adjustments.has("COLOUR") ? nextPointList.adjustments.get("COLOUR") : 'ff000000';
            colorHex = '#' + colorHex.substr(2);
            let size = nextPointList.adjustments != null && nextPointList.adjustments.has("SIZE") ? Number(nextPointList.adjustments.get("SIZE")) : null;
            if (type == "TEXT") {
                if (size == null) {
                    size = 12;
                }
                ctx.lineWidth = 1;
                ctx.font = `${size}px roboto`;
                ctx.fillStyle = colorHex;
                let textParts = nextPointList.text.split("\n");
                let offset = 0;
                for (let nextText of textParts) {
                    ctx.strokeText(nextText, nextPointList.offsets[0].dx, nextPointList.offsets[0].dy + offset + size);
                    offset += size;
                }
            } else {
                if (size == null) {
                    size = 4;
                }
                for (let nextPoint of nextPointList.offsets) {
                    if (previousPoint != null) {
                        drawLine({ x: previousPoint.dx, y: previousPoint.dy, x1: nextPoint.dx, y1: nextPoint.dy, type: type, color: colorHex, size: size });
                    }
                    previousPoint = nextPoint;
                }
            }
        }


        // drawLine({ x: 50, y: 50, x1: 200, y1: 100 }, { color: 'red' });

        // drawLine({ x: 300, y: 250, x1: 260, y1: 70 }, { color: 'green', width: 5 });

        // drawLine({ x: 70, y: 240, x1: 160, y1: 120 }, { color: 'blue' });
    }, []);

    // draw a line
    const drawLine = (info: LineDetails) => {
        const { x, y, x1, y1, type, color, size } = info;

        ctx.beginPath();
        if (type === 'PENCIL') {
            ctx.globalCompositeOperation = 'source-over';
            ctx.strokeStyle = color;
            ctx.lineWidth = size;
            ctx.lineCap = "round";
        } else {
            ctx.globalCompositeOperation = 'destination-out';
            ctx.lineWidth = size;
            ctx.lineCap = "round";
        }
        ctx.moveTo(x, y);
        ctx.lineTo(x1, y1);
        ctx.stroke();
    }

    return <div className="App">
        <canvas height="1000" width="1000" ref={canvas} className="stackChild2"></canvas>
    </div>
}

export default Canvas;
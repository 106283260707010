import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, Modal, ModalHeader, ModalBody, Card, CardHeader, Collapse, CardBody, InputGroup, InputGroupAddon, UncontrolledTooltip, Spinner, CustomInput
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import EmojiSelection from "./ninja_components/emoji_selection";
import queryString from 'query-string';
import { ActivityResponseFeedback, HomeLearning, HomeLearningActivityLearningPath, HomeLearningActivityResponse, HomeLearningResponse, LearningPathOverview } from './data/home_learning';
import { LearningGame, LearningPath, LearningWorld } from './data/learning_games';
import { User } from './data/user';
import { v4 as uuidv4 } from 'uuid';
import { AccountGroup, AccountMinimised } from './data/accounts';
import EngagementAwardSelection from './ninja_components/engagement_award_selection';
import { EngagementAward } from './data/engagement';

interface IState {
    homework: HomeLearning;
    homeworkId: string;
    homeworkActivityId: string;
    subjectFilter: string;
    currentHomeworkActivityTitle: string;
    currentHomeworkActivityDescription: string;
    currentHomeworkActivityWorldId: string;
    currentHomeworkActivityPathId: string;
    currentHomeworkActivityPath: LearningPath;
    currentHomeworkActivityWorlds: Map<string, LearningWorld>;
    currentHomeworkActivityWorldIdsSorted: string[];
    currentHomeworkActivityPaths: Map<string, LearningPath>,
    currentHomeworkActivityPathIdsSorted: string[];
    currentHomeworkResponses: Map<string, HomeLearningResponse>,
    currentHomeworkCompleted: Map<string, LearningPathOverview>,
    loadingWorlds: boolean;
    loadingPaths: boolean;
    learningGames: Map<string, LearningGame>;
    allowTypeSelection: boolean;
    showFeedback: boolean;
    feedbackAccountId: string;
    newFeedback: string;
    newReviewed: boolean;
    newAwardId: string;
    filter: string;
    collapse: boolean;
    editTarget: boolean;
    haveZap: boolean;
    haveHelpMeEnglish: boolean;
    haveLearningNinja: boolean;
}

interface MatchParams {
    homeworkId: string;
    homeworkActivityId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    accountsMinimised: Map<string, AccountMinimised>;
    user: User;
    snackbar: (text?: string) => void;
    schoolLicenses: Map<string, boolean>;
}

class HomeworkActivityLearningPathView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            homework: null,
            homeworkActivityId: props.match.params.homeworkActivityId,
            homeworkId: props.match.params.homeworkId,
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            currentHomeworkActivityTitle: '',
            currentHomeworkActivityDescription: '',
            currentHomeworkActivityWorldId: '',
            currentHomeworkActivityPathId: '',
            currentHomeworkActivityPath: null,
            currentHomeworkActivityWorlds: new Map(),
            currentHomeworkActivityWorldIdsSorted: [],
            currentHomeworkActivityPathIdsSorted: [],
            currentHomeworkActivityPaths: new Map(),
            currentHomeworkCompleted: new Map(),
            currentHomeworkResponses: new Map(),
            loadingWorlds: false,
            loadingPaths: false,
            learningGames: new Map(),
            allowTypeSelection: false,
            showFeedback: false,
            feedbackAccountId: '',
            editTarget: props.match.params.homeworkActivityId === "-1",
            newFeedback: '',
            newReviewed: false,
            newAwardId: '',
            filter: 'NAME',
            collapse: props.match.params.homeworkActivityId !== "-1",
            haveZap: false,
            haveHelpMeEnglish: false,
            haveLearningNinja: false,
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.toggle = this.toggle.bind(this);
        this.loadWorlds = this.loadWorlds.bind(this);
        this.loadPaths = this.loadPaths.bind(this);
        this.handleWorldChange = this.handleWorldChange.bind(this);
        this.handleChangeTarget = this.handleChangeTarget.bind(this);
        this.handleEmojiChange = this.handleEmojiChange.bind(this);
        this.loadLearningGames = this.loadLearningGames.bind(this);
        this.handlePathChange = this.handlePathChange.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.showFeedback = this.showFeedback.bind(this);
        this.closeFeedback = this.closeFeedback.bind(this);
        this.handleFeedback = this.handleFeedback.bind(this);
        this.getGameQuerys = this.getGameQuerys.bind(this);
    }

    render() {

        if (this.state.homework == null) {
            return <Spinner></Spinner>
        }

        let queryValues = this.getQuerys();

        let orderedAccountIds: string[] = this.state.homework.accounts == null ? [] : this.state.homework.accounts.slice();
        // for (let accountId of this.state.currentHomeworkAccounts) {
        //     let account = this.props.accountsMinimised.get(accountId);
        //     let name;
        //     let additional;
        //     if (account == null) {
        //         name = "Deactivated account";
        //         additional = "";
        //     } else {
        //         name = account.name;
        //         additional = account.additional;
        //     }
        //     orderedAccountIds.push({
        //         id: accountId,
        //         name: name,
        //         additional: additional,
        //     });
        // }
        orderedAccountIds = orderedAccountIds.filter((id) => this.props.accountsMinimised.get(id) != null);
        orderedAccountIds.sort((entry1, entry2) => {
            let account1 = this.props.accountsMinimised.get(entry1);
            let account2 = this.props.accountsMinimised.get(entry2);
            return account1.getLowercaseName().localeCompare(account2.getLowercaseName());
        });

        let orderedAllIds = Array.from(this.props.accountsMinimised.keys());
        orderedAllIds = orderedAllIds.filter((el) => {
            return !orderedAccountIds.includes(el);
        });
        orderedAllIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).getLowercaseName().localeCompare(this.props.accountsMinimised.get(id2).getLowercaseName());
        });

        let currentWorld;
        if (this.state.currentHomeworkActivityWorldId != "") {
            currentWorld = this.state.currentHomeworkActivityWorlds.get(this.state.currentHomeworkActivityWorldId);
        }

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={`/homeworks?${queryValues}`}>All homework</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/homeworks/${this.state.homeworkId}?${queryValues}`}>{this.state.homework != null ? this.state.homework.title : ""}</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.currentHomeworkActivityTitle}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Learning path activity</div>
                        <p className="cardSubTitle">Users complete a learning path</p>
                        <div>
                            {this.state.homeworkId !== "-1" ?
                                <Button onClick={this.toggle} className="altButton">
                                    {this.state.collapse ? "View details" : "Close"}
                                </Button> : null
                            }</div>
                        <Collapse isOpen={!this.state.collapse}>
                            <Form onSubmit={this.handleUpdate}>
                                <FormGroup>
                                    <Label for="currentHomeworkActivityTitle">Title *</Label>
                                    <InputGroup>
                                        <Input type="text" required name="currentHomeworkActivityTitle" onChange={(e) => this.setState({
                                            currentHomeworkActivityTitle: e.target.value
                                        })} value={this.state.currentHomeworkActivityTitle} />
                                        <InputGroupAddon addonType={"append"}>
                                            <EmojiSelection callback={(emoji) => {
                                                this.handleEmojiChange(emoji, "currentHomeworkActivityTitle");
                                            }} />
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="currentHomeworkActivityDescription">Description *</Label>
                                    <InputGroup>
                                        <Input type="textarea" required name="currentHomeworkActivityDescription" onChange={(e) => this.setState({
                                            currentHomeworkActivityDescription: e.target.value
                                        })} value={this.state.currentHomeworkActivityDescription} />
                                        <InputGroupAddon addonType={"append"}>
                                            <EmojiSelection callback={(emoji) => {
                                                this.handleEmojiChange(emoji, "currentHomeworkActivityDescription");
                                            }} />
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="currentHomeworkWorldId">Learning world {
                                        this.state.currentHomeworkActivityWorldId !== "" ?
                                            <Link to={`/learningWorlds/${this.state.currentHomeworkActivityWorldId}?${queryValues}`}>&nbsp;View</Link> :
                                            <Link to={`/learningWorlds/-1?${queryValues}`}>&nbsp;Create</Link>
                                    }
                                    </Label>
                                    <Input type="select" name="currentHomeworkActivityWorldId"
                                        onChange={this.handleWorldChange}
                                        disabled={!this.state.editTarget || this.state.loadingWorlds}
                                        value={this.state.currentHomeworkActivityWorldId}>
                                        <option value={''}>{this.state.loadingWorlds ? "Loading..." : this.state.currentHomeworkActivityWorldIdsSorted.length === 0 ? "None" : "Select learning world"}</option>
                                        {this.state.currentHomeworkActivityWorldIdsSorted.map((worldId) => {
                                            let world = this.state.currentHomeworkActivityWorlds.get(worldId);
                                            return (
                                                <option value={worldId}
                                                    key={worldId}>{world.name}</option>
                                            )
                                        })
                                        }
                                    </Input>
                                </FormGroup>

                                {this.state.currentHomeworkActivityWorldId === "" ? <span /> :
                                    <FormGroup>
                                        <Label for="currentHomeworkPathId">Learning path {
                                            this.state.currentHomeworkActivityPathId !== "" ? <Link to={`/learningPaths/${this.state.currentHomeworkActivityPathId}?${queryValues}&worldId=${this.state.currentHomeworkActivityWorldId}&worldName=${currentWorld != null ? currentWorld.name : ""}`}>&nbsp;View</Link> :
                                                <Link to={`/learningPaths/-1?${queryValues}&worldId=${this.state.currentHomeworkActivityWorldId}&worldName=${currentWorld != null ? currentWorld.name : ""}`}>&nbsp;Create</Link>
                                        }
                                        </Label>
                                        <Input type="select" name="currentHomeworkPathId"
                                            onChange={this.handlePathChange}
                                            disabled={!this.state.editTarget || this.state.loadingPaths}
                                            value={this.state.currentHomeworkActivityPathId}>
                                            <option value={''}>{this.state.loadingPaths ? "Loading..." : this.state.currentHomeworkActivityPathIdsSorted.length === 0 ? "None" : "Select learning path"}</option>
                                            {this.state.currentHomeworkActivityPathIdsSorted.map((pathId) => {
                                                let path = this.state.currentHomeworkActivityPaths.get(pathId);
                                                return (
                                                    <option value={pathId}
                                                        key={pathId}>{path.name}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                        {this.state.currentHomeworkActivityPath != null && this.state.currentHomeworkActivityPath.zapEnabled != null && this.state.currentHomeworkActivityPath.zapEnabled ?
                                            <div>
                                                <span>This is a Zap enabled learning path <i className="fas fa-info-circle icons-info" id="help-homework-zap" /></span>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-homework-zap">
                                                    Zap enabled learning paths can only be played on Zap apps like Zap Maths.
                                                                        </UncontrolledTooltip>
                                            </div> : <span />
                                        }
                                        {!this.state.editTarget ?
                                            <Button className="altButton" onClick={this.handleChangeTarget}>Change learning path</Button>
                                            : <span />
                                        }
                                    </FormGroup>
                                }

                                <div>
                                    <Button className="adminPagesButton">
                                        {this.state.homeworkId !== "-1" ? "Update" : "Create homework"}
                                    </Button>
                                </div>
                            </Form>
                        </Collapse>
                    </CardBody>
                </Card>

                {this.state.homeworkId === "-1" ? <br /> :
                    <React.Fragment>
                        <Card className="mainCard top-buffer">
                            <CardBody className="d-flex flex-column">
                                <div className="cardTitle2">Responses {orderedAccountIds.length > 0 ? ` (${orderedAccountIds.length})` : ""}</div>
                                {orderedAccountIds.length > 0 ?
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name <Button disabled={this.state.filter === 'NAME'} color="link" onClick={async () => {
                                                    this.setState({
                                                        filter: 'NAME'
                                                    })
                                                }}><i className="material-icons material-icons-xs">sort</i></Button>
                                                </th>
                                                <th>Additional</th>
                                                <th>Progress</th>
                                                <th>Tasks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderedAccountIds.map((accountId) => {
                                                let homeworkEntry = this.state.currentHomeworkCompleted.get(accountId);
                                                let account = this.props.accountsMinimised.get(accountId);

                                                let homeworkResponse = this.state.currentHomeworkResponses.get(accountId);
                                                let homeworkActivityResponse;
                                                if (homeworkResponse != null) {
                                                    homeworkActivityResponse = homeworkResponse.activityResponses.get(this.state.homeworkActivityId);
                                                }

                                                let status;
                                                if (homeworkEntry == null) {
                                                    status = "Not started";
                                                } else if (this.state.currentHomeworkActivityPath == null || this.state.currentHomeworkActivityPath.gameIds == null || this.state.currentHomeworkActivityPath.gameIds.length == 0) {
                                                    status = "No games to play";
                                                } else {
                                                    status = homeworkEntry.completed == 0 ? "Not started" : homeworkEntry.completed < this.state.currentHomeworkActivityPath.gameIds.length ? "In progress" : "Completed";
                                                }

                                                let name;
                                                let additional;
                                                if (account == null) {
                                                    name = "Deactivated account";
                                                    additional = "";
                                                } else {
                                                    name = account.name;
                                                    additional = account.additional;
                                                }

                                                let activityQuerys = queryString.stringify({
                                                    studentName: name,
                                                    homeworkName: this.state.homework != null ? this.state.homework.title : "",
                                                    homeworkActivityName: this.state.currentHomeworkActivityTitle
                                                });
                                                return <tr key={accountId + 'tr'}>
                                                    <th scope="row" key={accountId + 'th'}><Link to={`/accounts/${accountId}`}>{name}</Link></th>
                                                    <td key={accountId + 'td1'}>{additional}</td>
                                                    <td key={accountId + 'td2'}>
                                                        {homeworkEntry != null ?
                                                            <div><Link to={`/homeworkStudentAnalysis/${this.state.homeworkId}/${this.state.homeworkActivityId}/${accountId}?${activityQuerys}`}>
                                                                {homeworkEntry.completed} learning path game{homeworkEntry.completed != 1 ? "s" : ""} completed
                                                                        </Link></div> : <span />
                                                        }
                                                    </td>
                                                    <td>
                                                        {homeworkActivityResponse != null && homeworkActivityResponse.completed ?
                                                            <span>
                                                                <Button onClick={() => {
                                                                    this.showFeedback(accountId);
                                                                }} color={"link"}>
                                                                    <i className="material-icons">{homeworkActivityResponse.reviewed == null || !homeworkActivityResponse.reviewed ? "rate_review" : "done_all"}</i>
                                                                </Button>
                                                                &nbsp;
                                                            </span> : <span />
                                                        }
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table> : <div>No Invitees</div>
                                }
                            </CardBody></Card>

                        {this.state.homeworkId === "-1" || this.state.currentHomeworkActivityPath == null || this.state.learningGames == null || this.state.learningGames.size === 0 ? <br /> :
                            <Card className="mainCard top-buffer">
                                <CardBody className="d-flex flex-column">
                                    <div className="cardTitle2">Games ({this.state.currentHomeworkActivityPath.gameIds.length})</div>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name <Button disabled={this.state.filter === 'NAME'} color="link" onClick={async () => {
                                                    this.setState({
                                                        filter: 'NAME'
                                                    })
                                                }}><i className="material-icons material-icons-xs">sort</i></Button>
                                                </th>
                                                <th>Details</th>
                                                <th>Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.currentHomeworkActivityPath.gameIds.map((gameId) => {
                                                let game = this.state.learningGames.get(gameId);
                                                if (game == null) {
                                                    return <tr><td></td><td></td><td></td></tr>
                                                }
                                                return <tr key={gameId + 'tr'}>
                                                    <th scope="row" key={gameId + 'th'}><Link to={`/homeworkGameAnalysis/${this.state.homeworkId}/${this.state.homeworkActivityId}/${gameId}?${this.getGameQuerys(this.state.homework.title, game.title, this.state.currentHomeworkActivityTitle)}`}>
                                                        {game.title}
                                                    </Link></th>
                                                    <td key={gameId + 'td1'}>{game.instructions}</td>
                                                    <td key={gameId + 'td2'}>{game.type.charAt(0).toUpperCase() +
                                                        game.type.slice(1).toLowerCase()}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody></Card>
                        }
                        <br /><br /><br /><br /><br /><br />

                        <Modal isOpen={this.state.showFeedback} toggle={this.closeFeedback}>
                            <ModalHeader toggle={this.closeFeedback}>Give feedback</ModalHeader>
                            <ModalBody>
                                <div>
                                    <Form onSubmit={this.handleFeedback}>
                                        <FormGroup>
                                            <Label for="newFeedback">Feedback</Label>
                                            <InputGroup>
                                                <Input type="textarea" name="newFeedback" onChange={(e) => this.setState({
                                                    newFeedback: e.target.value
                                                })} value={this.state.newFeedback} />
                                                <InputGroupAddon addonType={"append"}>
                                                    <EmojiSelection callback={(emoji) => {
                                                        this.handleEmojiChange(emoji, "newFeedback");
                                                    }} />
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <EngagementAwardSelection awardId={this.state.newAwardId} callback={(value) => this.setState({ newAwardId: value })} user={this.props.user} />
                                        <FormGroup>
                                            <CustomInput id={"newReviewed"} checked={this.state.newReviewed} type="switch" name={"newReviewed"} label={"Mark reviewed"} onChange={(val) => {
                                                this.setState({
                                                    newReviewed: val.target.checked
                                                });
                                            }} />
                                        </FormGroup>
                                        <Button className="adminPagesButton">
                                            Send
                            </Button>
                                    </Form>
                                </div>
                            </ModalBody>
                        </Modal></React.Fragment>
                }
            </div >
        );
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
        });
    }

    getGameQuerys(homeworkName: string, gameName: string, activityName: string): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
            homeworkName: homeworkName,
            gameName: gameName,
            activityName: activityName,
        });
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    closeFeedback() {
        this.setState({
            showFeedback: false,
        });
    }

    showFeedback(feedbackAccountId: string): void {
        let response = this.state.currentHomeworkResponses.get(feedbackAccountId);
        let activityResponse;
        if (response != null) {
            activityResponse = response.activityResponses.get(this.state.homeworkActivityId);
        }
        this.setState({
            showFeedback: !this.state.showFeedback,
            feedbackAccountId: feedbackAccountId,
            newFeedback: activityResponse != null && activityResponse.feedback != null ? activityResponse.feedback.message : "",
            newReviewed: activityResponse != null ? activityResponse.reviewed : true,
        });
    }

    async handleChangeTarget(): Promise<void> {
        await this.loadWorlds();
        this.setState({
            currentHomeworkActivityWorldId: '',
            currentHomeworkActivityPaths: new Map(),
            currentHomeworkActivityPathId: '',
            currentHomeworkActivityPathIdsSorted: [],
            editTarget: true,
        });
    }

    handleEmojiChange(emojiCharacter: string, target: string): void {
        if (target === "currentHomeworkActivityTitle") {
            this.setState({
                currentHomeworkActivityTitle: this.state.currentHomeworkActivityTitle + emojiCharacter
            });
        } else if (target === "currentHomeworkActivityDescription") {
            this.setState({
                currentHomeworkActivityDescription: this.state.currentHomeworkActivityDescription + emojiCharacter
            });
        } else {
            this.setState({
                newFeedback: this.state.newFeedback + emojiCharacter
            });
        }
    }

    async handleFeedback(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let response = this.state.currentHomeworkResponses.get(this.state.feedbackAccountId);
        let activityResponse: HomeLearningActivityResponse;
        if (response != null) {
            activityResponse = response.activityResponses.get(this.state.homeworkActivityId);
        }
        let newFeedbackRead = false;
        if (activityResponse != null) {
            newFeedbackRead = activityResponse.feedback != null && activityResponse.feedback.message !== this.state.newFeedback ? false : activityResponse.feedbackRead;
        } else {
            activityResponse = new HomeLearningActivityResponse();
        }
        if (activityResponse.feedback == null) {
            activityResponse.feedback = new ActivityResponseFeedback();
        }
        activityResponse.feedback.message = this.state.newFeedback;
        activityResponse.reviewed = this.state.newReviewed;
        activityResponse.feedbackRead = newFeedbackRead;
        if (this.state.newAwardId != null && this.state.newAwardId !== "") {
            let path = `engagementAwards/${this.props.user.schoolId}/awards/${this.state.newAwardId}`;
            let awardSnapshot = await firebase.firestore().doc(path).get();
            let award = EngagementAward.fromFirebase(awardSnapshot.data());
            activityResponse.feedback.awardId = this.state.newAwardId;
            activityResponse.feedback.imageUrl = award.imageUrl;
            activityResponse.feedback.points = award.points;
        }

        let url = `/homeworkResponses/${this.state.feedbackAccountId}--${this.state.homeworkId}`;
        const responseRef = firebase.firestore().doc(url);
        let update = {
            [`activityResponses.${this.state.homeworkActivityId}.reviewed`]: activityResponse.reviewed,
            [`activityResponses.${this.state.homeworkActivityId}.dateUpdated`]: firebase.firestore.FieldValue.serverTimestamp(),
            [`activityResponses.${this.state.homeworkActivityId}.feedbackRead`]: activityResponse.feedbackRead,
            [`activityResponses.${this.state.homeworkActivityId}.feedback`]: activityResponse.feedback.toFirebase(),
            [`dateUpdated`]: firebase.firestore.FieldValue.serverTimestamp(),
        }
        this.closeFeedback();
        this.state.currentHomeworkResponses.get(this.state.feedbackAccountId).activityResponses.set(this.state.homeworkActivityId, activityResponse);
        this.setState({});
        try {
            await responseRef.update(update);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let homeworkActivity = new HomeLearningActivityLearningPath();
        homeworkActivity.title = this.state.currentHomeworkActivityTitle;
        homeworkActivity.description = this.state.currentHomeworkActivityDescription;
        homeworkActivity.worldId = this.state.currentHomeworkActivityWorldId;
        homeworkActivity.pathId = this.state.currentHomeworkActivityPathId;
        homeworkActivity.type = HomeLearningActivityLearningPath.TYPE;

        await this.createHomeworkActivity(homeworkActivity);
    }

    async createHomeworkActivity(homeworkActivity: HomeLearningActivityLearningPath): Promise<void> {
        let activityId;
        if (this.state.homeworkActivityId === "-1") {
            activityId = uuidv4();
        } else {
            activityId = this.state.homeworkActivityId;
        }
        const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
        try {
            await homeworkRef.update({
                [`activities.${activityId}`]: homeworkActivity.toFirebase(),
                activityIdsOrdered: firebase.firestore.FieldValue.arrayUnion(activityId),
                dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            });
            this.setState({
                homeworkActivityId: activityId,
                collapse: true,
            });
            this.props.history.replace(`/homeworkActivityLearningPath/${this.state.homeworkId}/${activityId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async handleWorldChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let worldId = e.target.value;
        this.setState({
            currentHomeworkActivityWorldId: worldId,
            currentHomeworkActivityPaths: new Map(),
            currentHomeworkActivityPathId: '',
            currentHomeworkActivityPath: null,
            loadingPaths: true,
        });
        await this.loadPaths(worldId);
        this.setState({
            loadingPaths: false,
        });
    }

    async handlePathChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let pathId = e.target.value;
        this.setState({
            currentHomeworkActivityPath: this.state.currentHomeworkActivityPaths.get(pathId),
            currentHomeworkActivityPathId: pathId,
        });
    }

    async loadWorlds(): Promise<void> {

        const schoolLearningWorldsRef = firebase.firestore().collection('learningWorlds').where('schoolId', '==', this.props.user.schoolId).where('subject', '==', this.state.homework.subjectId);
        let schoolLearningWorldsSnapshot = await schoolLearningWorldsRef.get();

        let newLearningWorldsState = new Map<string, LearningWorld>();
        schoolLearningWorldsSnapshot.forEach((nextLearningWorldSnapshot) => {
            newLearningWorldsState.set(nextLearningWorldSnapshot.id, LearningWorld.fromFirebase(nextLearningWorldSnapshot.data()));
        });

        const sharedLearningWorldsRef = firebase.firestore().collection('learningWorlds').where('schoolId', '==', null).where('subject', '==', this.state.homework.subjectId);
        let sharedLearningWorldsSnapshot = await sharedLearningWorldsRef.get();

        sharedLearningWorldsSnapshot.forEach((nextLearningWorldSnapshot) => {
            newLearningWorldsState.set(nextLearningWorldSnapshot.id, LearningWorld.fromFirebase(nextLearningWorldSnapshot.data()));
        });

        let newLearningWorldsSorted: string[] = Array.from(newLearningWorldsState.keys());

        newLearningWorldsSorted.sort((worldAId, worldBId) => {
            let worldA = newLearningWorldsState.get(worldAId);
            let worldB = newLearningWorldsState.get(worldBId);
            return worldA.name.localeCompare(worldB.name);
        });

        this.setState({
            currentHomeworkActivityWorlds: newLearningWorldsState,
            currentHomeworkActivityWorldIdsSorted: newLearningWorldsSorted
        });
    }

    async loadPaths(worldId: string) {
        let world = this.state.currentHomeworkActivityWorlds.get(worldId);

        let promises: Promise<void>[] = [];
        let paths = new Map<string, LearningPath>();
        for (let nextPathIndex in world.pathIds) {
            let nextPathId = world.pathIds[nextPathIndex];
            console.log("Getting path", nextPathId);
            const pathRef = firebase.firestore().doc(`learningPaths/${nextPathId}`);
            promises.push(new Promise((resolve, reject) => {
                pathRef.get().then((pathSnapshot) => {
                    paths.set(nextPathId, LearningPath.fromFirebase(pathSnapshot.data()));
                    resolve();
                }, (error) => {
                    console.log(error);
                    reject();
                })
            }));
        }
        await Promise.all(promises);

        let pathIdsSorted: string[] = Array.from(paths.keys());

        pathIdsSorted.sort((pathAId, pathBId) => {
            let pathA = paths.get(pathAId);
            let pathB = paths.get(pathBId);
            return pathA.name.localeCompare(pathB.name);
        });

        this.setState({
            currentHomeworkActivityPaths: paths,
            currentHomeworkActivityPathIdsSorted: pathIdsSorted,
        });
    }

    async loadLearningGames(pathId: string): Promise<void> {
        let path = this.state.currentHomeworkActivityPaths.get(pathId);

        let promises: Promise<firebase.firestore.DocumentData>[] = [];
        path.gameIds.forEach(gameId => {
            promises.push(firebase.firestore().doc(`learningGames/${gameId}`).get());
        });
        let learningGameRefs = await Promise.all(promises);
        let learningGames = new Map<string, LearningGame>();
        learningGameRefs.forEach(learningGameRef => {
            let learningGame = learningGameRef.data();
            learningGames.set(learningGameRef.id, LearningGame.fromFirebase(learningGame));
        });
        this.setState({
            learningGames: learningGames
        });
    }

    async componentDidMount(): Promise<void> {
        const homeworkSnapshot = await firebase.firestore().doc(`homeworks/${this.state.homeworkId}`).get();

        let homework = HomeLearning.fromFirebase(homeworkSnapshot.data());

        let haveZap = this.props.schoolLicenses.has('maths') && this.props.schoolLicenses.get('maths');
        let haveLearningNinja = this.props.schoolLicenses.has('learningNinja') && this.props.schoolLicenses.get('learningNinja');
        let haveHelpMeEnglish = this.props.schoolLicenses.has('lifeninja-englit') && this.props.schoolLicenses.get('lifeninja-englit');
        if (this.state.homeworkActivityId !== "-1") {

            let homeworkActivity = homework.activities.get(this.state.homeworkActivityId) as HomeLearningActivityLearningPath;
            if (homeworkActivity.worldId != null && homeworkActivity.worldId !== "") {
                const docSnapshot = await firebase.firestore().doc(`learningWorlds/${homeworkActivity.worldId}`).get();
                let nextLearningWorld = LearningWorld.fromFirebase(docSnapshot.data());
                let nextLearningWorldId = docSnapshot.id;
                this.setState({
                    currentHomeworkActivityWorlds: new Map([[nextLearningWorldId, nextLearningWorld]]),
                    currentHomeworkActivityWorldIdsSorted: [nextLearningWorldId],
                    currentHomeworkActivityWorldId: nextLearningWorldId,
                })
            }
            if (homeworkActivity.pathId != null && homeworkActivity.pathId !== "") {
                const docSnapshot = await firebase.firestore().doc(`learningPaths/${homeworkActivity.pathId}`).get();
                let path = LearningPath.fromFirebase(docSnapshot.data());
                let nextPathId = docSnapshot.id;

                this.setState({
                    currentHomeworkActivityPaths: new Map([[nextPathId, path]]),
                    currentHomeworkActivityPathIdsSorted: [nextPathId],
                    currentHomeworkActivityPath: path,
                    currentHomeworkActivityPathId: nextPathId,
                });
                this.loadLearningGames(nextPathId);
            }

            const responsesSnapshot = await firebase.firestore().collection(`homeworkResponses`).where("schoolId", "==", this.props.user.schoolId).where("homeworkId", "==", this.state.homeworkId).get();
            let responses = responsesSnapshot.docs;
            let responseMap = new Map<string, HomeLearningResponse>();
            for (let nextResponse of responses) {
                let nextHomeLearning = HomeLearningResponse.fromFirebase(nextResponse.data());
                responseMap.set(nextHomeLearning.accountId, nextHomeLearning);
            }

            let homeworkAccounts = homework.accounts != null ? homework.accounts : [];
            let homeworkPathId = homeworkActivity.pathId != null ? homeworkActivity.pathId : null;
            this.setState({
                homework: homework,
                currentHomeworkActivityTitle: homeworkActivity.title,
                currentHomeworkActivityDescription: homeworkActivity.description,
                currentHomeworkActivityWorldId: homeworkActivity.worldId != null ? homeworkActivity.worldId : null,
                currentHomeworkActivityPathId: homeworkPathId,
                currentHomeworkResponses: responseMap,
                haveZap: haveZap,
                haveHelpMeEnglish: haveHelpMeEnglish,
                haveLearningNinja: haveLearningNinja,
            });
            if (homeworkPathId != null && homeworkPathId !== "") {
                let promises = [];
                for (let accountId of homeworkAccounts) {
                    console.log("Requesting learningGameOverviews/", accountId);
                    promises.push(firebase.firestore().doc(`learningGameOverviews/${accountId}`).get());
                }
                let documents = await Promise.all(promises);
                let homeworkCompleted = new Map<string, LearningPathOverview>();
                documents.forEach((docSnapshot) => {
                    let fullOverview = docSnapshot.data();
                    if (fullOverview != null && fullOverview.paths != null && fullOverview.paths[homeworkPathId] != null) {
                        homeworkCompleted.set(docSnapshot.id, LearningPathOverview.fromFirebase(fullOverview.paths[homeworkPathId]));
                    }
                });
                this.setState({
                    currentHomeworkCompleted: homeworkCompleted,
                });
            }
        } else {
            this.setState({
                homework: homework,
                currentHomeworkActivityTitle: '',
                currentHomeworkActivityDescription: '',
                currentHomeworkActivityWorldId: '',
                currentHomeworkActivityPathId: '',
                haveZap: haveZap,
                haveHelpMeEnglish: haveHelpMeEnglish,
                haveLearningNinja: haveLearningNinja,
            });
            this.setState({
                loadingWorlds: true,
            });

            await this.loadWorlds();
            this.setState({
                loadingWorlds: false,
            });
        }
    }
}

export default HomeworkActivityLearningPathView;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import moment from "moment";
import confirm from "reactstrap-confirm";
import DatePicker from "react-datepicker";
import { EventMinimised, KeyDate, SchoolKeyDates } from './data/events';
import { User } from './data/user';
import { v4 as uuidv4 } from 'uuid';

interface IState {
    keyDates: SchoolKeyDates;
    keyDateIdsSorted: string[];
    modalOpen: boolean;
    modalKeyDateId: string;
    newKeyDateTitle: string;
    newKeyDateDate: Date;
}

interface IProps {
    tutorialCallback: (pageName: string) => void;
    user: User;
    snackbar: (text?: string) => void;
}

class KeyDatesView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            keyDates: null,
            keyDateIdsSorted: [],
            modalOpen: false,
            modalKeyDateId: null,
            newKeyDateTitle: "",
            newKeyDateDate: new Date(),
        };

        this.removeItem = this.removeItem.bind(this);
        this.openModal = this.openModal.bind(this);
        this.handleUpdateKeyEvent = this.handleUpdateKeyEvent.bind(this);
    }

    render(): JSX.Element {
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All key dates</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Key dates</div>
                        <p className="cardSubTitle">Share key dates like term start and end dates, professional development days, etc with parents and students</p>
                        <div>
                            <Button className="adminPagesButton" type="button" onClick={() => this.openModal()}>
                                Create key date
                            </Button>
                            <a target="_blank" href="https://docs.google.com/document/d/1Wg_NXQ6RGTdZVY_KCpuXbZHa7P-OGnyH4omJYsBMOZA/edit#">
                                <Button style={{marginLeft:"15px"}} className="altButton">Read Tutorial</Button>
                            </a>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Date</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.keyDateIdsSorted.map((keyDateId) => {
                                    let keyDate = this.state.keyDates.keyDates.get(keyDateId);
                                    if (keyDate == null) {
                                        return null;
                                    }
                                    return <tr>
                                        <th scope="row">{keyDate.title}</th>
                                        <td>{keyDate.date == null ? "Unset" : moment(keyDate.date).format("ddd, MMM Do YYYY")}<br /></td>
                                        <td>
                                            <Button color="link" onClick={async () => {
                                                this.setState({
                                                    modalKeyDateId: keyDateId,
                                                    newKeyDateDate: new Date(keyDate.date),
                                                    newKeyDateTitle: keyDate.title,
                                                    modalOpen: true,
                                                });
                                            }}><i className="material-icons">edit</i></Button>
                                            <Button color="link" onClick={async () => {
                                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this key date", confirmText: "Confirm" });
                                                if (result) {
                                                    this.removeItem(keyDateId)
                                                }
                                            }}><i className="material-icons">delete</i></Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        <Modal isOpen={this.state.modalOpen} toggle={() => this.setState({ modalOpen: false })}>
                            <ModalHeader toggle={() => this.setState({ modalOpen: false })}>Key date details</ModalHeader>
                            <ModalBody>
                                <div className="border rounded form-margin">
                                    <Form onSubmit={this.handleUpdateKeyEvent}>
                                        <FormGroup>
                                            <Label for="newKeyDateTitle">Title *</Label>
                                            <Input type="text" name="newKeyDateTitle" required value={this.state.newKeyDateTitle}
                                                onChange={(event) => this.setState({
                                                    newKeyDateTitle: event.target.value
                                                })} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentEventStartDateTime">Date *</Label><br />
                                            <DatePicker
                                                dropdownMode='scroll'
                                                selected={this.state.newKeyDateDate}
                                                required
                                                dateFormat="dd MMM yyyy"
                                                minDate={new Date()}
                                                onChange={(date: Date) => this.setState({ newKeyDateDate: date })}
                                                className="form-control"
                                            />
                                        </FormGroup>
                                        <Button className="adminPagesButton">Create</Button>
                                    </Form>
                                </div>
                            </ModalBody>
                        </Modal>
                    </CardBody>
                </Card>
            </div>
        );
    }

    async handleUpdateKeyEvent(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let newKeyDate = new KeyDate();
        newKeyDate.title = this.state.newKeyDateTitle;
        newKeyDate.date = this.state.newKeyDateDate.getTime();

        let newKeyDateId;
        if (this.state.modalKeyDateId != null) {
            newKeyDateId = this.state.modalKeyDateId;
        } else {
            newKeyDateId = uuidv4();
        }

        await firebase.firestore().doc(`schoolKeyDates/${this.props.user.schoolId}`).set({
            keyDates: {
                [newKeyDateId]: newKeyDate.toFirebase()
            },
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });

        this.state.keyDates.keyDates.set(newKeyDateId, newKeyDate);

        let newKeyDateIdsSorted = Array.from(this.state.keyDates.keyDates.keys());
        newKeyDateIdsSorted.sort((id1, id2) => {
            let kd1 = this.state.keyDates.keyDates.get(id1);
            let kd2 = this.state.keyDates.keyDates.get(id2);
            return kd1.date - kd2.date;
        });

        this.setState({
            newKeyDateDate: new Date(),
            newKeyDateTitle: "",
            modalKeyDateId: null,
            modalOpen: false,
            keyDateIdsSorted: newKeyDateIdsSorted,
        });
    }

    openModal(keyDateId?: string): void {
        this.setState({
            modalOpen: true,
            modalKeyDateId: keyDateId,
        });
    }

    async componentDidMount(): Promise<void> {
        try {
            const schoolKeyDatesSnapshot = await firebase.firestore().doc(`schoolKeyDates/${this.props.user.schoolId}`).get();

            let newKeyDates: SchoolKeyDates;
            if (schoolKeyDatesSnapshot.data() != null) {
                newKeyDates = SchoolKeyDates.fromFirebase(schoolKeyDatesSnapshot.data());
            } else {
                newKeyDates = new SchoolKeyDates();
            }

            let newKeyDateIdsSorted = Array.from(newKeyDates.keyDates.keys());
            newKeyDateIdsSorted.sort((id1, id2) => {
                let kd1 = newKeyDates.keyDates.get(id1);
                let kd2 = newKeyDates.keyDates.get(id2);
                return kd2.date - kd1.date;
            });

            this.setState({
                keyDates: newKeyDates,
                keyDateIdsSorted: newKeyDateIdsSorted,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(keyDateId: string): Promise<void> {
        try {
            await firebase.firestore().doc(`schoolKeyDates/${this.props.user.schoolId}`).set({
                keyDates: {
                    [keyDateId]: firebase.firestore.FieldValue.delete()
                },
                updated: firebase.firestore.FieldValue.serverTimestamp(),
            }, { merge: true });

            this.state.keyDates.keyDates.delete(keyDateId);

            this.setState({});
            this.props.snackbar("Deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }
}
export default KeyDatesView;
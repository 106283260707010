//@ts-check

import React, { Component } from 'react';
import firebase from './firebase';
import queryString from 'query-string';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, UncontrolledTooltip, InputGroup, InputGroupAddon, Card, CardBody
} from 'reactstrap';
import { Link } from "react-router-dom";
import TopicsSelector from "./ninja_components/topics_selector";
import { StoreLearningGame } from "./helpers/topic_services";
import { RouteComponentProps } from 'react-router';
import { User, USER_ROLE } from './data/user';
import { SliceGame, SliceRound, TIME_MULTIPLIER } from './data/learning_games';
import { v4 as uuidv4 } from 'uuid';
import { soundNames, subjectIds } from './Prettifier';

interface IState {
    worldId: string;
    worldName: string;
    subjectFilter: string;
    pathId: string;
    pathName: string;
    gameId: string;
    slice: SliceGame;
    timeMultiplier: TIME_MULTIPLIER;
    currentSliceTitle: string;
    currentSliceInstructions: string;
    currentSliceSchoolId: string;
    currentSliceSound: string;
    currentTopics: string[];
    currentTopicsTags: Map<string, string[]>;
    currentSubjectId: string;
    currentSliceRounds: SliceRound[];
    user: User;
}

interface MatchParams {
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class LearningSliceView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            gameId: props.match.params.gameId,
            slice: new SliceGame(),
            timeMultiplier: TIME_MULTIPLIER.moderate,
            currentSliceTitle: '',
            currentSliceInstructions: '',
            currentSliceSchoolId: '',
            currentSliceSound: '',
            currentTopics: [],
            currentTopicsTags: new Map<string, string[]>(),
            currentSubjectId: '',
            currentSliceRounds: [],
            user: props.user,
        };
        this.dealWithChange = this.dealWithChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.addCorrectNumber = this.addCorrectNumber.bind(this);
        this.addIncorrectNumber = this.addIncorrectNumber.bind(this);
        this.addRound = this.addRound.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.handleRoundNumberChange = this.handleRoundNumberChange.bind(this);
        this.removeNumber = this.removeNumber.bind(this);
        this.removeRound = this.removeRound.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.getGameQuerys = this.getGameQuerys.bind(this);
    }

    render(): JSX.Element {
        console.log("Render");
        let queryValues = this.getQuerys();

        let editable = this.state.currentSliceSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All minigames</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem> : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem> : null}
                        <BreadcrumbItem active>Number Slice</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Number slice</div>
                        <p className="cardSubTitle">Number slice requires users to slice (by swiping their finger) across the correct numbers.
                        If they slice the incorrect numbers then the round will replay.</p>
                        {
                            (this.state.slice != null) ?
                                <div>
                                    <div>
                                        <a href={`https://zapdemo.web.app/#/slice?${this.getGameQuerys()}`} target="_blank"><Button type="button" className="adminPagesButton">Play now</Button></a>
                                    </div><br />
                                    <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                        <fieldset disabled={!editable}>
                                            <FormGroup>
                                                <Label for="currentSliceTitle">Slice title *</Label>
                                                <Input maxLength={30} type="text" required name="currentSliceTitle" onChange={(e) => this.setState({
                                                    currentSliceTitle: e.target.value
                                                })} value={this.state.currentSliceTitle} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentSubjectId">Subject *</Label>
                                                <Input disabled={this.state.worldId != null || this.state.gameId !== "-1"} type="select" name="currentSubjectId"
                                                    onChange={(e) => this.setState({
                                                        currentSubjectId: e.target.value
                                                    })}
                                                    value={this.state.currentSubjectId}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                                        let subjectName = subjectIds.get(subjectId);
                                                        return <option value={subjectId}>{subjectName}</option>;
                                                    })}
                                                </Input>
                                            </FormGroup>
                                            {this.state.currentSubjectId === '' ? <div></div> :
                                                <TopicsSelector subject={this.state.currentSubjectId} countryCode={this.state.user.countryCode} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags} callback={this.handleTopicsChange} />
                                            }

                                            <label>
                                                Game speed <i className="fas fa-info-circle icons-info" id="help-learning-timemultiplier" />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learning-timemultiplier">
                                                    Control how fast or slow the game will play.
                                                    Overall paths can have speed controls and the game speed will work within the context of the path speed control.
                                                    For example, a game with a 'fast' speed on a path with a 'slow' speed will play at 'moderate' speed.
                                                    Use this to allow developing or advanced students to play at a speed that suits them.
                                        </UncontrolledTooltip>
                                            </label>
                                            <Input type="select" value={this.state.timeMultiplier} onChange={this.dealWithChange}>
                                                <option value="fastest">Fastest</option>
                                                <option value="fast">Fast</option>
                                                <option value="moderate">Moderate</option>
                                                <option value="slow">Slow</option>
                                                <option value="slowest">Slowest</option>
                                            </Input>
                                            <FormGroup>
                                                <h4>Slice rounds <i className="fas fa-info-circle icons-info" id="help-learningslice-rounds" /></h4>
                                                {this.state.currentSliceRounds.map((answer, roundIndex) => {
                                                    let allowDelete = false;
                                                    if (this.state.currentSliceRounds[roundIndex].correct.length + this.state.currentSliceRounds[roundIndex].incorrect.length > 1) {
                                                        allowDelete = true;
                                                    }
                                                    return <div className="border rounded form-margin">
                                                        <div><b>Round {roundIndex + 1}</b> {roundIndex > 0 ? <Button type="button" color="link" onClick={() => this.removeRound(roundIndex)}><i
                                                            className="material-icons material-icons-xd">cancel</i></Button> : <span />}</div>
                                                        <div>Correct slices</div>
                                                        {this.state.currentSliceRounds[roundIndex].correct.map((answer, index) =>
                                                            <InputGroup>
                                                                <Input required name={`currentSliceRounds-correct-${roundIndex}-${index}`}
                                                                    onChange={(value) => this.handleRoundNumberChange(value, roundIndex, index, true)}
                                                                    value={this.state.currentSliceRounds[roundIndex].correct[index]} />
                                                                {allowDelete ? <InputGroupAddon addonType={"prepend"}>
                                                                    <Button type="button" outline onClick={() => this.removeNumber(roundIndex, index, true)}><i
                                                                        className="material-icons material-icons-xd">cancel</i></Button>
                                                                </InputGroupAddon> : null
                                                                }
                                                            </InputGroup>
                                                        )}
                                                        <Button className="altButton" onClick={() => this.addCorrectNumber(roundIndex)}>Additional correct number</Button>
                                                        <div>Incorrect slices</div>
                                                        {this.state.currentSliceRounds[roundIndex].incorrect.map((answer, index) =>
                                                            <InputGroup>
                                                                <Input required name={`currentSliceRounds-incorrect-${roundIndex}-${index}`}
                                                                    onChange={(value) => this.handleRoundNumberChange(value, roundIndex, index, false)}
                                                                    value={this.state.currentSliceRounds[roundIndex].incorrect[index]} />
                                                                {allowDelete ? <InputGroupAddon addonType={"prepend"}>
                                                                    <Button type="button" outline onClick={() => this.removeNumber(roundIndex, index, false)}><i
                                                                        className="material-icons material-icons-xd">cancel</i></Button>
                                                                </InputGroupAddon> : null
                                                                }
                                                            </InputGroup>
                                                        )}
                                                        <Button className="altButton" onClick={() => this.addIncorrectNumber(roundIndex)}>Additional incorrect number</Button>
                                                    </div>
                                                })}
                                                <Button className="altButton" onClick={() => this.addRound()}>Additional round</Button>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningslice-rounds">
                                                    Enter the numbers that will appear in each round. Correct numbers are the ones the player should slice.
                                        </UncontrolledTooltip>
                                            </FormGroup>


                                            <FormGroup>
                                                <Label for="currentSliceInstructions">Instructions <i className="fas fa-info-circle icons-info" id="help-learningslice-instructions" /></Label>
                                                <Input type="textarea" name="currentSliceInstructions" onChange={(e) => this.setState({
                                                    currentSliceInstructions: e.target.value
                                                })} value={this.state.currentSliceInstructions} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learningslice-instructions">
                                                    The instructions shown to the player before they start number slice. Advise on the types of sums to complete rather than the how to play the game.
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentSliceSound">Audio instruction *</Label>
                                                <Input type="select" name="currentSliceSound"
                                                    onChange={(e) => this.setState({
                                                        currentSliceSound: e.target.value
                                                    })}
                                                    value={this.state.currentSliceSound}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(soundNames.keys()).map((key) => {
                                                        let name = soundNames.get(key);
                                                        return (
                                                            <option value={key} key={key}>{name}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                            </FormGroup>

                                            {editable ? this.state.gameId === "-1" ?
                                                <Button disabled={this.state.currentSubjectId === ''} className="adminPagesButton">Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                            }
                                        </fieldset>
                                    </Form>
                                </div> : <div />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    getGameQuerys(): string {
        return queryString.stringify({
            instructions: this.state.currentSliceInstructions,
            shootRounds: JSON.stringify(this.state.currentSliceRounds),
            timeMultiplier: this.state.timeMultiplier,
        });
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>) {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId
        });
    }

    dealWithChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    addCorrectNumber(roundIndex: number): void {
        this.state.currentSliceRounds[roundIndex].correct.push("0");
        this.setState({
        });
    }

    addIncorrectNumber(roundIndex: number): void {
        this.state.currentSliceRounds[roundIndex].incorrect.push("0");
        this.setState({
        });
    }

    handleRoundNumberChange(e: React.ChangeEvent<HTMLInputElement>, roundIndex: number, index: number, correct: boolean): void {
        let newNumber = e.target.value;
        if (correct) {
            this.state.currentSliceRounds[roundIndex].correct[index] = newNumber;
        } else {
            this.state.currentSliceRounds[roundIndex].incorrect[index] = newNumber;
        }
        this.setState({
        });
    }

    addRound(): void {
        let initialRound = new SliceRound();
        initialRound.correct = ["0"];
        initialRound.incorrect = ["0", "0"];

        this.state.currentSliceRounds.push(initialRound);
        this.setState({
        });
    }

    removeNumber(roundIndex: number, index: number, correct: boolean): void {
        if (correct) {
            this.state.currentSliceRounds[roundIndex].correct.splice(index, 1);
        } else {
            this.state.currentSliceRounds[roundIndex].incorrect.splice(index, 1);
        }
        this.setState({
        });
    }

    removeRound(roundIndex: number): void {
        this.state.currentSliceRounds.splice(roundIndex, 1);
        this.setState({});
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let slice = this.state.slice;
        slice.title = this.state.currentSliceTitle;
        slice.instructions = this.state.currentSliceInstructions;
        slice.rounds = this.state.currentSliceRounds;
        slice.topics = this.state.currentTopics;
        slice.topicTags = this.state.currentTopicsTags;
        slice.sound = this.state.currentSliceSound;
        slice.timeMultiplier = this.state.timeMultiplier;
        slice.subjectId = this.state.currentSubjectId;
        slice.type = 'SLICE';
        slice.deleted = null;
        let newSlice = false;
        let gameId = this.state.gameId;
        if (this.state.gameId === "-1") {
            newSlice = true;
            gameId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                slice.schoolId = this.state.user.schoolId;
            }
        }
        try {
            await StoreLearningGame(newSlice, slice, gameId, this.state.pathId, this.state.currentSliceSchoolId);

            this.props.history.replace(`/learningSlices/${gameId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
        this.setState({
            gameId: gameId
        })
    }

    async componentDidMount(): Promise<void> {
        try {
            let initialRound = new SliceRound();
            initialRound.correct = ["0"];
            initialRound.incorrect = ["0", "0"];
            if (this.state.gameId !== "-1") {
                let sliceSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
                let sliceData = sliceSnapshot.data();
                let slice = SliceGame.fromFirebase(sliceData);
                this.setState({
                    slice: slice,
                    currentSliceTitle: slice.title,
                    currentSliceInstructions: slice.instructions,
                    currentSliceSchoolId: slice.schoolId == null ? null : slice.schoolId,
                    currentSliceRounds: slice.rounds == null ? [initialRound] : slice.rounds,
                    currentSliceSound: slice.sound == null ? '' : slice.sound,
                    currentSubjectId: slice.subjectId,
                    currentTopics: slice.topics == null ? [] : slice.topics,
                    currentTopicsTags: slice.topicTags == null ? new Map<string, string[]>() : slice.topicTags,
                    timeMultiplier: slice.timeMultiplier != null ? slice.timeMultiplier : TIME_MULTIPLIER.moderate,
                });
            } else {
                let subjectId = '';
                if (this.state.worldId != null) {
                    const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                    let worldSnapshot = await worldRef.get();
                    let world = worldSnapshot.data();
                    subjectId = world.subject;
                } else {
                    // @ts-ignore
                    subjectId = this.state.subjectFilter;
                }
                this.setState({
                    slice: new SliceGame(),
                    currentSliceTitle: '',
                    currentSliceInstructions: '',
                    currentSliceSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                    currentSliceSound: '',
                    currentSliceRounds: [initialRound],
                    timeMultiplier: TIME_MULTIPLIER.moderate,
                    currentSubjectId: subjectId,
                    currentTopics: [],
                    currentTopicsTags: new Map<string, string[]>(),
                });
            }
        } catch (error) {
            console.log(error);
        }

    }
}


export default LearningSliceView;
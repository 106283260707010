import React, { Component } from "react";
import { Alert, Button, Table, Card, CardBody } from "reactstrap";
import { CSVLink } from "react-csv";

export interface TableDetails {
    headers: Array<string>;
    data: Array<Array<any>>;
}

interface IProps {
    tableDetails: TableDetails
}

interface IState {
    sortColumn: number;
    sortAscending: boolean;
}

export class DataTableView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            sortColumn: 0,
            sortAscending: false,
        };
        this.sortData = this.sortData.bind(this);
    }

    render(): JSX.Element {

        let dataOrdered = new Array<Array<any>>();
        for (let nextEntry of this.props.tableDetails.data) {
            dataOrdered.push(nextEntry);
        }
        this.sortData(dataOrdered, 0, false); // pre-set to alphabetical
        this.sortData(dataOrdered, this.state.sortColumn, this.state.sortAscending);

        return <div>
            {/* <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle2">Details</div> */}
                    <Table>
                        <thead>
                            <tr>
                                {this.props.tableDetails.headers.map((header, index) => {
                                    return <th key={index + 'th'}>{header} <Button color="link" onClick={async () => {
                                        let sortAscending = false;
                                        if (this.state.sortColumn === index) {
                                            sortAscending = !this.state.sortAscending;
                                        }
                                        this.setState({
                                            sortColumn: index,
                                            sortAscending: sortAscending,
                                        })
                                    }}><i className="material-icons material-icons-xs">{this.state.sortColumn !== index ? "sort" : this.state.sortAscending ? "keyboard_arrow_up" : "keyboard_arrow_down"}</i></Button></th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {dataOrdered.map((dataRow, index) => {
                                return <tr key={index + 'tr'}>
                                    {dataRow.map((dataEntry, subIndex) => {
                                        let dataEntryText = dataEntry;
                                        if (dataEntry instanceof Date) {
                                            dataEntryText = dataEntry.toDateString();
                                        }
                                        return <td key={index + 'td' + subIndex}>{dataEntryText}</td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <div>
                        <CSVLink data={[this.props.tableDetails.headers, ...dataOrdered]}
                            filename={"LifeNinja-Report.csv"}
                            className="btn adminPagesButton"
                            target="_blank">Download</CSVLink>
                    </div>
                {/* </CardBody>
            </Card> */}
        </div>
    }

    sortData(dataOrdered: Array<Array<any>>, sortColumn: number, sortAscending: boolean) {
        dataOrdered.sort((entry1: Array<any>, entry2: Array<any>) => {
            let data1 = sortAscending ? entry1 : entry2;
            let data1Entry = data1[sortColumn];
            let data2 = sortAscending ? entry2 : entry1;
            let data2Entry = data2[sortColumn];
            if (data1Entry instanceof Date && data2Entry instanceof Date) {
                return data1Entry.getTime() - data2Entry.getTime();
            } else {
                let data1EntryNumber = Number(data1Entry);
                let data2EntryNumber = Number(data2Entry);
                if (isNaN(data1EntryNumber) || isNaN(data2EntryNumber)) {
                    if (data1Entry == null) {
                        return -1;
                    } else if (data2Entry == null) {
                        return 1;
                    } else {
                        return data2Entry.toString().localeCompare(data1Entry.toString());
                    }
                } else {
                    return data2EntryNumber - data1EntryNumber;
                }
            }
        });
    }
}
import React, { Component } from 'react';
import {
    Button, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Input
} from 'reactstrap';
import firebase from '../firebase';
import { User } from '../data/user';

interface IState {
    newAwardId: string;
    awards: Map<string, string>;
    awardIdsSorted: Array<string>;
}

interface IProps {
    awardId: string,
    user: User,
    callback: (awardId: string) => void;
}

class EngagementAwardSelection extends Component<IProps, IState> {
    legend: string;

    constructor(props: IProps) {
        super(props);
        this.state = {
            newAwardId: props.awardId,
            awards: new Map(),
            awardIdsSorted: [],
        };
    }

    render(): JSX.Element {
        return <FormGroup>
            <Label for="newAwardId">Awards</Label>
            <Input type="select" name="newAwardId" value={this.state.newAwardId}
                onChange={(e) => {
                    this.setState({
                        newAwardId: e.target.value
                    });
                    this.props.callback(e.target.value);
                }}>
                <option value={""}>&nbsp;</option>
                {this.state.awardIdsSorted.map((awardId) => {
                    return (
                        <option value={awardId}
                            key={awardId}>{this.state.awards.get(awardId)}</option>
                    )
                })
                }
            </Input>
        </FormGroup>
    }

    async componentDidMount(): Promise<void> {
        try {
            let awardsSnapshot = await firebase.firestore().doc(`engagementAwards/${this.props.user.schoolId}`).get();
            let awardsData = awardsSnapshot.data();
            let awards = new Map<string, string>();
            if (awardsData != null && awardsData["awardsOverview"] != null) {
                let awardsObject = awardsData["awardsOverview"];
                for (let nextAwardId of Object.keys(awardsObject)) {
                    awards.set(nextAwardId, awardsObject[nextAwardId]);
                }
            }
            let awardIdsSorted = Array.from(awards.keys());
            awardIdsSorted.sort((id1, id2) => {
                return awards.get(id1).localeCompare(awards.get(id2));
            });
            this.setState({
                awards: awards,
                awardIdsSorted: awardIdsSorted,
            });
        } catch (error) {
            console.log(error);
        }
    }
}

export default EngagementAwardSelection;
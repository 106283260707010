import firebase from "../firebase";
import { LearningGame, Question } from '../data/learning_games';
import { objectToClassConverter, objectToMapConverter } from "../data/database_object";

export async function StoreLearningGame(newGame: boolean, game: LearningGame, gameId: string, pathId: string, schoolId: string): Promise<void> {
    await firebase.firestore().runTransaction(async (batch) => {
        let oldDocSnapshot = await batch.get(firebase.firestore().doc(`learningGames/${gameId}`));
        let oldDoc = oldDocSnapshot.data();
        let oldTopics = oldDoc == null ? [] : oldDoc.topics == null ? [] : oldDoc.topics.slice();
        let oldTopicTags = oldDoc == null ? new Map<number, string[]>() : oldDoc.topicTags == null ? new Map<number, string[]>() : objectToMapConverter(oldDoc.topicTags);
        batch.set(firebase.firestore().doc(`learningGames/${gameId}`), game.toFirebase());
        let mappingFilePath = 'learningTopicMinigameMappings';
        if (schoolId != null && schoolId !== '') {
            mappingFilePath = `learningTopicSchoolMinigameMappings/${schoolId}/topics`;
        }

        let newlySetTopics = game.topics;
        let newlySetTopicTags = game.topicTags;
        let name = game.title;
        let type = game.type;

        updateBatchWithTopics(newlySetTopics, newlySetTopicTags, oldTopics, oldTopicTags, name, type, gameId, batch, mappingFilePath);

    });

    await StoreChangeHistory(newGame, gameId, pathId, schoolId);
}

function updateBatchWithTopics(newlySetTopics: string[], newlySetTopicTags: Map<string, string[]>, oldTopics: string[], oldTopicTags: Map<string, string[]>, 
        name: string, type: string, id: string, batch: firebase.firestore.Transaction, mappingFilePath: string) : void {

    let removalTopics = oldTopics.filter(value => !newlySetTopics.includes(value));
    let updatedTopics = newlySetTopics.filter(value => oldTopics.includes(value));
    let newTopics = newlySetTopics.filter(value => !oldTopics.includes(value));


    removalTopics.forEach((topicId) => {
        let updateDeets: any = { [`root`]: { [id]: firebase.firestore.FieldValue.delete() } };
        if (oldTopicTags.has(topicId)) {
            oldTopicTags.get(topicId).forEach((tagId) => {
                updateDeets[tagId] = firebase.firestore.FieldValue.arrayRemove(id);
            });
        }
        batch.set(firebase.firestore().doc(`${mappingFilePath}/${topicId}`), updateDeets, { merge: true });
    });
    updatedTopics.forEach((topicId) => {
        let removalTags = oldTopicTags.get(topicId);
        let updateDeets: any = { [`root`]: { [id]: { text: name.substr(0, 30), type: type } } };
        if (removalTags != null) {
            if (newlySetTopicTags.has(topicId)) {
                removalTags = removalTags.filter(value => !newlySetTopicTags.get(topicId).includes(value));
            }
            removalTags.forEach((tagId) => {
                updateDeets[tagId] = firebase.firestore.FieldValue.arrayRemove(id);
            });
        }
        let newTags = newlySetTopicTags.get(topicId);
        if (newTags != null) {
            if (oldTopicTags.has(topicId)) {
                newTags = newTags.filter(value => !oldTopicTags.get(topicId).includes(value));
            }
            newTags.forEach((tagId) => {
                updateDeets[tagId] = firebase.firestore.FieldValue.arrayUnion(id);
            });
        }
        batch.set(firebase.firestore().doc(`${mappingFilePath}/${topicId}`), updateDeets, { merge: true });
    });
    newTopics.forEach((topicId) => {
        let newTags = newlySetTopicTags.get(topicId);
        let updateDeets: any = { [`root`]: { [id]: { text: name.substr(0, 30), type: type } } };
        if (newTags != null) {
            newTags.forEach((tagId) => {
                updateDeets[tagId] = firebase.firestore.FieldValue.arrayUnion(id);
            });
        }
        batch.set(firebase.firestore().doc(`${mappingFilePath}/${topicId}`), updateDeets, { merge: true });
    });
}

export async function StoreChangeHistory(newGame: boolean, gameId: string, pathId: string, schoolId: string): Promise<void> {
    let updateDeets;
    if (newGame) {
        if (pathId != null) {
            let pathRef = firebase.firestore().doc(`learningPaths/${pathId}`);
            await pathRef.update({
                gameIds: firebase.firestore.FieldValue.arrayUnion(gameId)
            });
            updateDeets = {
                updated: firebase.database.ServerValue.TIMESTAMP,
                updateType: 'PATH',
                updateId: pathId,
            };
        }
    } else {
        updateDeets = {
            updated: firebase.database.ServerValue.TIMESTAMP,
            updateType: 'GAME',
            updateId: gameId,
        };
    }

    // Note, for path updates rather than retrieve the path and see if it has a school id we can be certain
    // that if this game has a school id then the path will have the same school id
    if (updateDeets != null) {
        if (schoolId == null || schoolId === '') {
            await firebase.database().ref('moduleInformation/changeHistory').push(updateDeets);
        } else {
            await firebase.database().ref(`moduleInformation/changeHistorySchools/${schoolId}`).push(updateDeets);
        }
    }
}

export async function StoreQuestion(newQuestion: boolean, question: Question, questionId: string, gameId: string, schoolId: string): Promise<void> {
    await firebase.firestore().runTransaction(async (batch) => {
        let oldDocSnapshot = await batch.get(firebase.firestore().doc(`learningQuestions/${questionId}`));
        let oldDoc = oldDocSnapshot.data();
        let oldTopics = oldDoc == null ? [] : oldDoc.topics == null ? [] : oldDoc.topics.slice();
        let oldTopicTags = oldDoc == null ? new Map<number, string[]>() : oldDoc.topicTags == null ? new Map<number, string[]>() : objectToMapConverter(oldDoc.topicTags);
        batch.set(firebase.firestore().doc(`learningQuestions/${questionId}`), question.toFirebase());
        let mappingFilePath = 'learningTopicMappings';
        if (schoolId != null && schoolId !== '') {
            mappingFilePath = `learningTopicSchoolQuestionMappings/${schoolId}/topics`;
        }
        updateBatchWithTopics(question.topics, question.topicTags, oldTopics, oldTopicTags, question.text, question.questionType, questionId, batch, mappingFilePath);
    });

    let updateDeets;
    if (newQuestion) {
        if (gameId != null) {
            let gameRef = firebase.firestore().doc(`learningGames/${gameId}`);
            await gameRef.update({
                questionIds: firebase.firestore.FieldValue.arrayUnion(questionId)
            });
            updateDeets = {
                updated: firebase.database.ServerValue.TIMESTAMP,
                updateType: 'GAME',
                updateId: gameId,
            };
        }
    } else {
        updateDeets = {
            updated: firebase.database.ServerValue.TIMESTAMP,
            updateType: 'QUESTION',
            updateId: questionId,
        };
    }
    // Note, for path updates rather than retrieve the path and see if it has a school id we can be certain
    // that if this game has a school id then the path will have the same school id
    if (updateDeets != null) {
        if (schoolId == null || schoolId === '') {
            await firebase.database().ref('moduleInformation/changeHistory').push(updateDeets);
        } else {
            await firebase.database().ref(`moduleInformation/changeHistorySchools/${schoolId}`).push(updateDeets);
        }
    }
}

export interface LearningGameSearchResult {
    gameIds: string[];
    allTopicGames: Map<string, LearningGameSearchStub>;
    schoolGameIds: string[];
    allTopicSchoolGames: Map<string, LearningGameSearchStub>;
}

export class LearningGameSearchStub {
    text: string;
    type: string;

    static fromFirebase(data: any): LearningGameSearchStub {
        let newLearningGameSearchStub = new LearningGameSearchStub();
        if (data == null) {
            return newLearningGameSearchStub;
        }
        objectToClassConverter(data, newLearningGameSearchStub);

        return newLearningGameSearchStub;
    }
}

export async function searchMinigames(searchTopicId: string, searchTopicTags: string[][], schoolId: string): Promise<LearningGameSearchResult> {
    if (searchTopicTags == null) {
        searchTopicTags = [];
    }
    let topicsRef = firebase.firestore().doc(`learningTopicMinigameMappings/${searchTopicId}`);
    let topicMappingSnapshot = await topicsRef.get();
    let allMinigames = new Map<string, LearningGameSearchStub>();
    let remainingMinigameIds: string[] = [];
    if (topicMappingSnapshot == null || topicMappingSnapshot.data() == null) {
        return {
            gameIds: [],
            allTopicGames: new Map(),
            schoolGameIds: [],
            allTopicSchoolGames: new Map(),
        };
    } else {
        let allMinigamesMappings = topicMappingSnapshot.data();
        allMinigames = rootToLearningGameStubs(allMinigamesMappings.root);
        remainingMinigameIds = Array.from(allMinigames.keys());
        for (let tagOrList of searchTopicTags) {
            remainingMinigameIds = remainingMinigameIds.filter(value => {
                let found = false;
                for (let tagId of tagOrList) {
                    if (allMinigamesMappings[tagId] != null && allMinigamesMappings[tagId].includes(value)) {
                        found = true;
                        break;
                    }
                }
                return found;
            });;
        }

        remainingMinigameIds.sort((minigameId1, minigameId2) => {
            return allMinigames.get(minigameId1).text.localeCompare(allMinigames.get(minigameId2).text);
        });
    }

    let schoolRemainingMinigameIds: string[] = [];
    let allSchoolMinigames = new Map<string, LearningGameSearchStub>();
    if (schoolId != null) {
        let schoolTopicsRef = firebase.firestore().doc(`learningTopicSchoolMinigameMappings/${schoolId}/topics/${searchTopicId}`);
        let schoolTopicMappingSnapshot = await schoolTopicsRef.get();
        let allSchoolMinigameMappings = schoolTopicMappingSnapshot.data();
        if (allSchoolMinigameMappings != null) {
            allSchoolMinigames = rootToLearningGameStubs(allSchoolMinigameMappings.root);
            schoolRemainingMinigameIds = Array.from(allSchoolMinigames.keys());
            for (let tagOrList of searchTopicTags) {
                schoolRemainingMinigameIds = schoolRemainingMinigameIds.filter(value => {
                    let found = false;
                    for (let tagId of tagOrList) {
                        if (allSchoolMinigameMappings[tagId] != null && allSchoolMinigameMappings[tagId].includes(value)) {
                            found = true;
                            break;
                        }
                    }
                    return found;
                });
            }
        }

        schoolRemainingMinigameIds.sort((minigameId1: string, minigameId2: string) => {
            return allSchoolMinigames.get(minigameId1).text.localeCompare(allSchoolMinigames.get(minigameId2).text);
        });
    }

    return {
        gameIds: remainingMinigameIds,
        allTopicGames: allMinigames,
        schoolGameIds: schoolRemainingMinigameIds,
        allTopicSchoolGames: allSchoolMinigames,
    };
}

function rootToLearningGameStubs(data: any): Map<string, LearningGameSearchStub> {
    let allMinigames = new Map<string, LearningGameSearchStub>();

    if (data != null) {
        for (let nextRootId in data) {
            let nextRootData = data[nextRootId];
            let nextStub = new LearningGameSearchStub();
            nextStub.text = nextRootData.text;
            nextStub.type = nextRootData.type;
            allMinigames.set(nextRootId, nextStub);
        }
    }

    return allMinigames;
}

export interface QuestionSearchResult {
    questionIds: string[];
    allTopicQuestions: Map<string, QuestionSearchStub>;
    schoolQuestionIds: string[];
    allTopicSchoolQuestions: Map<string, QuestionSearchStub>;
}

export class QuestionSearchStub {
    text: string;
    type: string;
}

function rootToQuestionStubs(data: any): Map<string, QuestionSearchStub> {
    let allQuestions = new Map<string, QuestionSearchStub>();

    if (data != null) {
        for (let nextRootId in data) {
            let nextRootData = data[nextRootId];
            let nextStub = new QuestionSearchStub();
            nextStub.text = nextRootData.text;
            nextStub.type = nextRootData.type;
            allQuestions.set(nextRootId, nextStub);
        }
    }

    return allQuestions;
}

export async function searchQuestions(searchTopicId: string, searchTopicTags: string[][], schoolId: string): Promise<QuestionSearchResult> {
    let remainingQuestionIds: string[] = [];
    let allQuestions = new Map<string, QuestionSearchStub>();

    let topicsRef = firebase.firestore().doc(`learningTopicMappings/${searchTopicId}`);
    let topicMappingSnapshot = await topicsRef.get();
    let allQuestionMappings = topicMappingSnapshot.data();
    if (allQuestionMappings != null) {
        allQuestions = rootToQuestionStubs(allQuestionMappings.root);
        remainingQuestionIds = Object.keys(allQuestionMappings.root);
        for (let tagOrList of searchTopicTags) {
            remainingQuestionIds = remainingQuestionIds.filter(value => {
                let found = false;
                for (let tagId of tagOrList) {
                    if (allQuestionMappings[tagId] != null && allQuestionMappings[tagId].includes(value)) {
                        found = true;
                        break;
                    }
                }
                return found;
            });
        }
    }
    remainingQuestionIds.sort((questionId1, questionId2) => {
        return allQuestions.get(questionId1).text.localeCompare(allQuestions.get(questionId2).text);
    });

    let schoolRemainingQuestionIds: string[] = [];
    let allSchoolQuestions = new Map<string, QuestionSearchStub>();
    if (schoolId != null) {
        let schoolTopicsRef = firebase.firestore().doc(`learningTopicSchoolQuestionMappings/${schoolId}/topics/${searchTopicId}`);
        let schoolTopicMappingSnapshot = await schoolTopicsRef.get();
        let allSchoolQuestionMappings = schoolTopicMappingSnapshot.data();
        if (allSchoolQuestionMappings != null) {
            allSchoolQuestions = rootToQuestionStubs(allSchoolQuestionMappings.root);
            schoolRemainingQuestionIds = Array.from(allSchoolQuestions.keys());
            for (let tagOrList of searchTopicTags) {
                schoolRemainingQuestionIds = schoolRemainingQuestionIds.filter(value => {
                    let found = false;
                    for (let tagId of tagOrList) {
                        if (allSchoolQuestionMappings[tagId] != null && allSchoolQuestionMappings[tagId].includes(value)) {
                            found = true;
                            break;
                        }
                    }
                    return found;
                });;
            }
        }

        schoolRemainingQuestionIds.sort((questionId1, questionId2) => {
            return allSchoolQuestions.get(questionId1).text.localeCompare(allSchoolQuestions.get(questionId2).text);
        });
    }

    return {
        questionIds: remainingQuestionIds,
        allTopicQuestions: allQuestions,
        schoolQuestionIds: schoolRemainingQuestionIds,
        allTopicSchoolQuestions: allSchoolQuestions,
    };
}
import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, FormText, Spinner, Card, CardBody
} from 'reactstrap';
import { Link } from "react-router-dom";
import Stripe from 'stripe';
import { User } from './data/user';
import { PrivatePaymentSettings } from './data/payment_settings';

const stripe = new Stripe('pk_live_51Hgae9JUDUqGYAI2FPrArHkZWEWnZyRtLbns36gLeAYfTUyFm8aK1F2ip2ozWDjWlQJTrRByHQ22XjakdGrxGNXh00vdu79mWF', {
    apiVersion: '2020-08-27',
    typescript: true,
});

interface IState {
    stripeAccountId: string;
    paymentSettings: PrivatePaymentSettings;
    newAccountId: string;
    loading: boolean;
    creating: boolean;
    connecting: boolean;
}

interface IProps {
    user: User;
}

class StripePaymentsView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            stripeAccountId: '',
            paymentSettings: null,
            newAccountId: '',
            loading: true,
            creating: false,
            connecting: false,
        };
        this.handleConnect = this.handleConnect.bind(this);
    }

    render(): JSX.Element {

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/paymentSettings'}>Payment provider</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Stripe</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Stripe payment settings</div>
                        <p className="cardSubTitle">To allow users to make payments, the organisation's <a target={"_blank"} href={"https://stripe.com/gb/payments"}>Stripe</a> payment settings need to be specified here.</p>
                        {this.state.paymentSettings != null ?
                            this.state.paymentSettings.connectedAccount != null && this.state.paymentSettings.connectedAccount ?
                                <div>Your Stripe account has been successfully connected to the Life Ninja network
                            <br /><br />
                                    {this.state.paymentSettings.detailsSubmitted
                                        ? <div><i className="material-icons">check_circle</i> Details have been submitted</div>
                                        : <div><i className="material-icons">error</i> You still need to setup your Stripe account details</div>
                                    }
                                    <br />
                                    {this.state.paymentSettings.chargesEnabled
                                        ? <div><i className="material-icons">check_circle</i> Your Stripe account can accept payments</div>
                                        : <div><i className="material-icons">error</i> You still need to continue your Stripe account setup to be able to accept payments</div>
                                    }<br />
                                    <Button disabled={this.state.creating || this.state.connecting} color="link" onClick={() => this.handleConnect()}>
                                        {this.state.creating ? <Spinner size="sm"></Spinner> : <span />} Access Stripe account
                            </Button>
                                </div> : this.state.paymentSettings.stripeAccountId != null ?
                                    <div>Your Stripe account has been created but not connected to the Life Ninja Network.<br />
                                        <div><Button color="link" onClick={(e) => this.handleConnect()}>Connect</Button> account to Life Ninja Network</div>
                                    </div> : <div>
                                        You do not have a Stripe account setup yet.<br /><br />
                                        <div className="border rounded form-margin">
                                            <b>Create a new Stripe account</b><br />
                                            <FormText color="muted">
                                                Create a new Stripe account. You will have full control over the Stripe account and it will be connected to the Life Ninja Network automatically.
                                    </FormText><br />
                                            <Button disabled={this.state.creating || this.state.connecting} color="link" onClick={(e) => this.handleConnect()}>
                                                {this.state.creating ? <Spinner size="sm"></Spinner> : <span />} Create
                                    </Button>
                                        </div>

                                        <div className="border rounded form-margin">
                                            <b>Connect an existing Stripe account</b><br />
                                            <FormText color="muted">
                                                If you already have a standard Stripe account then you can connect it to the Life Ninja Network
                                    </FormText><br />
                                            <FormGroup>
                                                <Label for="newAccountId">Existing account id *</Label>
                                                <Input type="text" required name="newAccountId" onChange={(e) => this.setState({
                                                    newAccountId: e.target.value
                                                })} value={this.state.newAccountId} />
                                            </FormGroup>
                                            <Button color="link" disabled={this.state.creating || this.state.connecting || this.state.newAccountId == null || this.state.newAccountId === ''} onClick={() => this.handleConnect(this.state.newAccountId)}>
                                                {this.state.connecting ? <Spinner size="sm"></Spinner> : <span />} Connect
                                    </Button>
                                        </div>
                                    </div>
                            : <Spinner size="sm"></Spinner>
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    async handleConnect(existingAccountId?: string): Promise<void> {
        console.log("Creating account");

        if (existingAccountId == null) {
            this.setState({
                creating: true,
            });
        } else {
            this.setState({
                connecting: true,
            });
        }

        let stripeConnectAccountCall = firebase.functions().httpsCallable('stripeConnectAccount');
        let response = await stripeConnectAccountCall({
            existingAccountId: existingAccountId,
        });
        console.log("Account created", response.data.accountId);
        console.log("Redirecting to", response.data.accountLinkUrl);
        window.location = response.data.accountLinkUrl;
    }

    async componentDidMount(): Promise<void> {
        try {
            let stripeConnectAccountStatusCall = firebase.functions().httpsCallable('stripeConnectAccountStatus');
            let response = await stripeConnectAccountStatusCall({});
            let paymentSettingsData = response.data;
            let paymentSettings = PrivatePaymentSettings.fromFirebase(paymentSettingsData);

            this.setState({
                paymentSettings: paymentSettings,
                loading: false,
            });
        } catch (error) {
            console.log(error)
        }
    }
}

export default StripePaymentsView;
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Table, BreadcrumbItem, Breadcrumb, Alert, Button, Card, CardBody } from 'reactstrap';
import firebase from "./firebase";
import moment from "moment";
import { ReadingRecord } from './data/home_learning';
import { RouteComponentProps } from "react-router";
import { User } from './data/user';
import { AccountMinimised } from './data/accounts';

interface IState {
    readingRecords: Map<string, ReadingRecord>,
    readingRecordIdsSorted: string[],
    accountId: string;
    moreReadingRecords: boolean;
}

interface MatchParams {
    accountId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
}

class ReadingRecordsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            readingRecords: new Map(),
            readingRecordIdsSorted: [],
            accountId: props.match.params.accountId,
            moreReadingRecords: true,
        };
        this.getNextReadingRecordsBatch = this.getNextReadingRecordsBatch.bind(this);
    }

    render(): JSX.Element {
        let studentName = this.props.accountsMinimised.has(this.state.accountId) ? this.props.accountsMinimised.get(this.state.accountId).name : "student";
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/readingRecords'}>All reading records</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Reading records for {studentName}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Reading records</div>
                        <p className="cardSubTitle">Reading records for {studentName}</p>
                        <Table>
                            <thead>
                                <tr>
                                    <th>When</th>
                                    <th>Book</th>
                                    <th>To page</th>
                                    <th>Notes</th>
                                    <th>Read by</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.readingRecordIdsSorted.map((readingRecordId) => {
                                    let readingRecord = this.state.readingRecords.get(readingRecordId);
                                    return <tr>
                                        <th scope="row">{moment(readingRecord.readingDateTime.toDate()).format("ddd, MMM Do YYYY, h:mm a")}</th>
                                        <td>{readingRecord.bookName}</td>
                                        <td>{readingRecord.position}</td>
                                        <td>{readingRecord.description}</td>
                                        <td>{readingRecord.parentReading == null || !readingRecord.parentReading ? "School" : "Parent"}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        {this.state.moreReadingRecords ?
                            <div>
                                <p>Showing {this.state.readingRecordIdsSorted.length}</p>
                                <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.getNextReadingRecordsBatch(false);
                                    }}>
                                    Show more
                        </Button>
                            </div> : <span />
                        }
                        <br /><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    componentDidMount(): void {
        this.getNextReadingRecordsBatch(true);
    }

    async getNextReadingRecordsBatch(seed: boolean): Promise<void> {
        let limit = 10;
        let readingRecordsRef = await firebase.firestore()
            .collection('readingRecords')
            .where("accountId", "==", this.state.accountId)
            .orderBy("readingDateTime", "desc");
        if (seed == null || !seed) {
            // Find last event
            let lastId = this.state.readingRecordIdsSorted[this.state.readingRecordIdsSorted.length - 1];
            if (lastId != null) {
                readingRecordsRef = readingRecordsRef.startAt("readingDateTime", "<=", this.state.readingRecords.get(lastId).readingDateTime.toDate());
                limit++; // We will get the last entry back again
            }
        }
        readingRecordsRef = readingRecordsRef.limit(limit);
        let snapshot = await readingRecordsRef.get();
        let newMoreReadingRecords = this.state.moreReadingRecords;
        if (snapshot != null) {
            if (snapshot.docs.length < limit) {
                newMoreReadingRecords = false;
            }
            for (let doc of snapshot.docs) {
                this.state.readingRecords.set(doc.id, ReadingRecord.fromFirebase(doc.data()));
            }
            let readingRecordIdsSorting = Array.from(this.state.readingRecords.keys());
            readingRecordIdsSorting.sort((aId, bId) => {
                return this.state.readingRecords.get(bId).readingDateTime.toDate().getTime() - this.state.readingRecords.get(aId).readingDateTime.toDate().getTime();
            });
            this.setState({
                readingRecordIdsSorted: readingRecordIdsSorting,
                moreReadingRecords: newMoreReadingRecords,
            });
        }
    }
}

export default ReadingRecordsView;
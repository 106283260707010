import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Input, Alert, CardBody, Card, Table, Row, Col,
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import { User, USER_ROLE } from './data/user';
import { OrganisationDetails } from './data/organisation';
import { Account } from './data/accounts';

interface IState {
    schoolList: Map<string, OrganisationDetails>,
    accountList: Map<string, Account>,
    loading: boolean;
}

interface IProps extends RouteComponentProps {
    user: User;
    callback: () => void;
    handleSchoolSelect: (schoolId: string, accountId: string, subAccountId: string, user: User) => Promise<Map<string, OrganisationDetails>>;
    handleSchoolSelectAdmin: (schoolId: string, user: User) => void;
}

class OrganisationSelectionView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            schoolList: new Map(),
            accountList: new Map(),
            loading: true,
        };
        this.handleSchoolSelectAdminTrans = this.handleSchoolSelectAdminTrans.bind(this);
        this.refreshSchoolList = this.refreshSchoolList.bind(this);
    }

    render() {
        let orderedSchoolIds: string[] = [];
        let orderedAccountIds: string[] = [];
        if (this.props.user.role === USER_ROLE.admin) {
            orderedSchoolIds = Array.from(this.state.schoolList.keys());
            orderedSchoolIds = orderedSchoolIds.filter((id) => {
                return this.state.schoolList.has(id) && this.state.schoolList.get(id).name != null;
            });
            orderedSchoolIds.sort((id1, id2) => {
                return this.state.schoolList.get(id1).name.localeCompare(this.state.schoolList.get(id2).name);
            });
        } else {
            orderedAccountIds = Array.from(this.props.user.accountIds.keys());
            orderedAccountIds = orderedAccountIds.filter((id) => {
                let schoolId = this.props.user.accountIds.get(id).schoolId;
                return schoolId != null && this.state.schoolList.has(schoolId) && this.state.schoolList.get(schoolId).name != null;
            });
            orderedAccountIds.sort((id1, id2) => {
                let schoolId1 = this.props.user.accountIds.get(id1).schoolId;
                let schoolId2 = this.props.user.accountIds.get(id2).schoolId;
                if (schoolId1 !== schoolId2) {
                    return this.state.schoolList.get(schoolId1).name.localeCompare(this.state.schoolList.get(schoolId2).name);
                } else {
                    return id1.localeCompare(id2);
                }
            });
        }

        let adminAccountIdsOrdered: string[] = [];
        let nonAdminAccountIdsOrdered: string[] = [];
        if (this.props.user.accountIds != null) {
            Array.from(this.props.user.accountIds.keys()).forEach((accountId) => {
                let account = this.state.accountList.get(accountId);
                if (account == null) {
                    return;
                }
                let accountMapping = this.props.user.accountIds.get(accountId);
                let school = this.state.schoolList.get(accountMapping.schoolId);
                if (school == null) {
                    return;
                }
                if (account.isAdmin) {
                    adminAccountIdsOrdered.push(accountId);
                } else {
                    nonAdminAccountIdsOrdered.push(accountId);
                }
            });
        }
        adminAccountIdsOrdered.sort((id1, id2) => {
            let accountMapping1 = this.props.user.accountIds.get(id1);
            let school1 = this.state.schoolList.get(accountMapping1.schoolId);
            let school1Name = school1.name == null ? "" : school1.name;

            let accountMapping2 = this.props.user.accountIds.get(id2);
            let school2 = this.state.schoolList.get(accountMapping2.schoolId);
            let school2Name = school2.name == null ? "" : school2.name;

            if (school1Name !== school2Name) {
                return school1Name.localeCompare(school2Name);
            }
            let account1 = this.state.accountList.get(id1);
            let account2 = this.state.accountList.get(id2);
            return account1.name.localeCompare(account2.name);
        });
        nonAdminAccountIdsOrdered.sort((id1, id2) => {
            let accountMapping1 = this.props.user.accountIds.get(id1);
            let school1 = this.state.schoolList.get(accountMapping1.schoolId);
            let school1Name = school1.name == null ? "" : school1.name;

            let accountMapping2 = this.props.user.accountIds.get(id2);
            let school2 = this.state.schoolList.get(accountMapping2.schoolId);
            let school2Name = school2.name == null ? "" : school2.name;

            if (school1Name !== school2Name) {
                return school1Name.localeCompare(school2Name);
            }
            let account1 = this.state.accountList.get(id1);
            let account2 = this.state.accountList.get(id2);
            return account1.name.localeCompare(account2.name);
        });

        return (
            this.state.loading ?
                <span /> :
                <div className="container">
                    <Card className="mainCard top-buffer">
                        <CardBody className="d-flex flex-column">
                            <div className="cardTitle">Organisation selection</div>
                            <p className="cardSubTitle">Choose which organisation you will be managing today</p>
                            {this.props.user.role === USER_ROLE.admin ?
                                <div>
                                    <Button type={'button'} outline onClick={() => {
                                        this.refreshSchoolList(this.props.user);
                                    }}>Refresh list</Button> <br /> <br />
                                </div>
                                : <span />
                            }
                            {this.props.user.role !== USER_ROLE.admin ?
                                <div>
                                    <div className="cardTitle2">Admin accounts</div>
                                        {adminAccountIdsOrdered.map((accountId) => {
                                            let accountMapping = this.props.user.accountIds.get(accountId);
                                            let schoolId = accountMapping.schoolId;
                                            let subAccountId = accountMapping.subAccountId;
                                            let account = this.state.accountList.get(accountId);
                                            return <Row>
                                                <Col className="col-md-3">
                                                    {account.name}
                                                </Col>
                                                <Col className="col-md-7">
                                                    {this.state.schoolList.has(schoolId) && this.state.schoolList.get(schoolId).imageUrl != null && this.state.schoolList.get(schoolId).imageUrl.length > 0 ?
                                                        <img className="rounded" src={this.state.schoolList.get(schoolId).imageUrl} width="20" height="20" alt="" />
                                                        : <span />
                                                    }&nbsp;
                                                    {this.state.schoolList.has(schoolId) ? this.state.schoolList.get(schoolId).name : ""}
                                                </Col>
                                                <Col className="col-md-2">
                                                    <Button className="altButton" style={{ marginBottom: "3px" }} value={accountId} onClick={async () => {
                                                        this.setState({
                                                            loading: true,
                                                        });
                                                        let newSchoolList = await this.props.handleSchoolSelect(schoolId, accountId, subAccountId, this.props.user);
                                                        this.setState({
                                                            schoolList: newSchoolList,
                                                            loading: false,
                                                        });
                                                        this.props.callback();
                                                    }}>Open</Button>
                                                </Col>
                                            </Row>
                                        })}
                                        {adminAccountIdsOrdered.length === 0 ? <p>You don't have any administration accounts</p> : <span />}
                                        {nonAdminAccountIdsOrdered.length > 0 ?
                                            <div>
                                                <div className="cardTitle2">Other accounts</div>
                                                <p className="cardSubTitle">These are accounts that you have for Life Ninja Network apps that do not have administrator access</p>
                                                {nonAdminAccountIdsOrdered.map((accountId) => {
                                                    let accountMapping = this.props.user.accountIds.get(accountId);
                                                    let schoolId = accountMapping.schoolId;
                                                    let account = this.state.accountList.get(accountId);
                                                    return <div>
                                                        {this.state.schoolList.has(schoolId) && this.state.schoolList.get(schoolId).imageUrl != null && this.state.schoolList.get(schoolId).imageUrl.length > 0 ?
                                                            <img className="d-inline-block align-top rounded" src={this.state.schoolList.get(schoolId).imageUrl} width="20" height="20" alt="" />
                                                            : <span />
                                                        }&nbsp;
                                                    {this.state.schoolList.has(schoolId) ? this.state.schoolList.get(schoolId).name : ""} ({account.name})
                                                </div>
                                                })}</div> : <span />
                                        }
                                </div> :
                                <div>
                                    <Input type="select" required name="currentSchool"
                                        onChange={this.handleSchoolSelectAdminTrans}
                                        value={this.props.user.schoolId}>
                                        <option>&nbsp;</option>
                                        {orderedSchoolIds.map((schoolId) => {
                                            return <option value={schoolId}>{this.state.schoolList.has(schoolId) ? `${this.state.schoolList.get(schoolId).name} - ${schoolId}` : schoolId}</option>
                                        })}
                                    </Input>
                                </div>
                            }
                            <br />
                            <div>
                                <Button className="adminPagesButton" tag={Link} to="/createOrganisation">
                                    Create new organisation
                            </Button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
        );
    }

    async refreshSchoolList(user: User): Promise<void> {
        if (user.role !== USER_ROLE.admin) {
            for (const accountId of Array.from(user.accountIds.keys())) {
                let account = user.accountIds.get(accountId);
                let nextSchoolId = account.schoolId;
                console.log("Got schoolId", nextSchoolId);
                firebase.database().ref(`schoolManagement/${nextSchoolId}/accounts/${accountId}`).once('value').then((accountSnapshot) => {
                    this.state.accountList.set(accountId, accountSnapshot.val());
                    firebase.database().ref(`organisationDetails/${nextSchoolId}`).once('value').then((schoolSnapshot) => {
                        this.state.schoolList.set(nextSchoolId, schoolSnapshot.val());
                        this.setState({});
                    });
                });
            }
        } else {
            let detailsSnapshot = await firebase.database().ref('organisationDetails/').once('value');
            let detailsData = detailsSnapshot.val();
            let newSchoolList: Map<string, any> = new Map();
            for (let nextKey of Object.keys(detailsData)) {
                newSchoolList.set(nextKey, detailsData[nextKey]);
            }
            this.setState({
                schoolList: newSchoolList
            });
        }
        this.props.callback();
    }

    async handleSchoolSelectAdminTrans(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        console.log("School id", e.target.value);
        await this.props.handleSchoolSelectAdmin(e.target.value, this.props.user);
    }

    async componentDidMount(): Promise<void> {
        this.refreshSchoolList(this.props.user);
        try {
            const path = `schoolUsers/${this.props.user.email.replace(/\./g, ',')}`;
            const itemsRef = firebase.database().ref(path);
            let snapshot = await itemsRef.once('value');
            let userAccount = snapshot.val();
            if (userAccount.currentAccountId == null && userAccount.schoolAccounts != null && userAccount.schoolAccounts.size == 1) {
                userAccount.currentAccountId = Array.from(userAccount.schoolAccounts.keys())[0];
            }
            if (!this.props.user.switchSchool && userAccount.currentAccountId != null && userAccount.schoolAccounts[userAccount.currentAccountId] != null) {
                let schoolId = userAccount.schoolAccounts[userAccount.currentAccountId].schoolId;
                let subAccountId = userAccount.schoolAccounts[userAccount.currentAccountId].subAccountId;
                let accountSnapshot = await firebase.database().ref(`schoolManagement/${schoolId}/accounts/${userAccount.currentAccountId}`).once('value');
                let accountDeets = accountSnapshot.val();
                if (accountDeets.isAdmin) {
                    if (this.props.user.role !== USER_ROLE.admin) {
                        await this.props.handleSchoolSelect(schoolId, userAccount.currentAccountId, subAccountId, this.props.user);
                    } else {
                        await this.props.handleSchoolSelectAdmin(schoolId, this.props.user);
                    }
                    this.props.callback();
                } else {
                    this.setState({
                        loading: false,
                    })
                }
            } else {
                this.props.history.replace('/');
                this.setState({
                    loading: false,
                })
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
            })
        }
    }
}

export default OrganisationSelectionView;
import React, { Component } from "react";
import moment from 'moment';
import { RouteComponentProps } from "react-router";
import { User } from "../data/user";
import firebase from '../firebase';
import Chart from "react-google-charts";
import { Button, Alert, Form, FormGroup, Label, Input, Collapse, Card, CardHeader, CardBody, Spinner } from 'reactstrap';
import { AccountGroup, AccountMinimised } from "../data/accounts";
import DatePicker from "react-datepicker";
import { ReadingRecord } from "../data/home_learning";
import { ReactGoogleChartEvent } from "react-google-charts/dist/types";
import { TableDetails, DataTableView } from "./data_table";
import Moment from "react-moment";

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
    accountGroups: Map<string, AccountGroup>;
}

interface IState {
    dataLoaded: boolean;
    dataLoading: boolean;
    currentReportAccountGroups: Array<string>;
    currentReportDateStart: moment.Moment;
    currentReportDateEnd: moment.Moment;
    newReportAccountGroups: Array<string>;
    newAccountIdList: Array<string>;
    newReportDateStart: Date;
    newReportDateEnd: Date;
    tableDetails: TableDetails;
    availableAccountIds: Array<string>;
    accountIdList: Array<string>;
    readingRecords: Map<string, ReadingRecord>;
    showReportDetails: boolean;
    reportData: string[][];
    chartEvents: ReactGoogleChartEvent[];
    collapse: boolean;
}

class ReadingRecordsReportView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataLoaded: false,
            dataLoading: false,
            currentReportAccountGroups: [],
            currentReportDateStart: null,
            currentReportDateEnd: null,
            newReportAccountGroups: [],
            newAccountIdList: [],
            newReportDateStart: null,
            newReportDateEnd: null,
            availableAccountIds: [],
            accountIdList: [],
            readingRecords: new Map(),
            collapse: true,
            showReportDetails: true,
            reportData: null,
            chartEvents: null,
            tableDetails: null,
        };
        this.createReadingReport = this.createReadingReport.bind(this);
        this.handleReportChange = this.handleReportChange.bind(this);
        this.selectAllAccounts = this.selectAllAccounts.bind(this);
        this.loadReadingRecords = this.loadReadingRecords.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleAccountGroupChange = this.handleAccountGroupChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.toggleShowReportDetails = this.toggleShowReportDetails.bind(this);
        this.getTableDetails = this.getTableDetails.bind(this);
    }

    render(): JSX.Element {
        return <div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Reading records report</div>
                    <p className="cardSubTitle">View the latest data on reading records to see daily reading record count and total count per user</p>

                    {this.state.dataLoaded ?
                        <Button onClick={this.toggleShowReportDetails} className="altButton" style={{ width: '9%', float: 'right' }}>
                            {this.state.showReportDetails ? "Close" : "Edit report"}
                        </Button> : null
                    }
                    <Collapse isOpen={this.state.showReportDetails}>
                        <Form onSubmit={this.handleReportChange}>
                            <FormGroup>
                                <Label for="newReportAccountGroups">Account groups *</Label>
                                <Input type="select" required name="newReportAccountGroups" multiple onChange={this.handleAccountGroupChange} value={this.state.newReportAccountGroups}>
                                    {Array.from(this.props.accountGroups.keys()).map((accountGroupId) => {
                                        return (
                                            <option value={accountGroupId} key={accountGroupId}>{this.props.accountGroups.get(accountGroupId).details.name}</option>
                                        )
                                    })}
                                </Input><br />
                            </FormGroup>
                            <Collapse isOpen={!this.state.collapse}>
                                <FormGroup>
                                    <Label for="newAccountIdList">Accounts * <Button type="button" onClick={this.selectAllAccounts} color="secondary">Select all</Button></Label>
                                    <Input type="select" required name="newAccountIdList" multiple onChange={this.handleAccountChange} value={this.state.newAccountIdList}>
                                        {this.state.availableAccountIds.map((accountId) => {
                                            let account = this.props.accountsMinimised.get(accountId);
                                            if (account == null) {
                                                return null;
                                            }
                                            return (
                                                <option value={accountId} key={accountId}>{account.name}</option>
                                            )
                                        })
                                        }
                                    </Input><br />
                                </FormGroup>
                            </Collapse>
                            <FormGroup>
                                <Label for="newReportDateStart">Start date</Label><br />
                                <DatePicker
                                    selected={this.state.newReportDateStart}
                                    required
                                    dateFormat="dd MMM yyyy"
                                    maxDate={moment().add(-1, 'days').toDate()}
                                    onChange={this.handleStartDateChange}
                                    className="form-control"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="newReportDateEnd">End date</Label><br />
                                <DatePicker
                                    selected={this.state.newReportDateEnd}
                                    required
                                    dateFormat="dd MMM yyyy"
                                    minDate={moment(this.state.newReportDateStart).add(1, 'days').toDate()}
                                    maxDate={new Date()}
                                    onChange={this.handleEndDateChange}
                                    className="form-control"
                                />
                            </FormGroup>
                            <Button className="adminPagesButton">{this.state.dataLoading ? <Spinner size="sm"></Spinner> : <span />}Run report</Button>
                        </Form>
                    </Collapse>
                </CardBody>
            </Card>
            {this.state.dataLoaded ?
                <Card className="mainCard top-buffer">
                    <CardBody className="d-flex flex-column">
                        <Chart
                            chartType="ColumnChart"
                            chartEvents={this.state.chartEvents}
                            width="100%"
                            height="400px"
                            data={this.state.reportData}
                            options={{
                                title: "Reads per day",
                                vAxis: {
                                    gridlines: {
                                        count: -1
                                    },
                                    minValue: 0
                                },
                                animation: {
                                    startup: true,
                                    easing: 'out',
                                    duration: 500,
                                },
                            }}
                        />
                    </CardBody>
                </Card> : <span />
            }
            {this.state.dataLoaded ?
                <Card className="mainCard top-buffer">
                    <CardBody className="d-flex flex-column">
                        <DataTableView tableDetails={this.state.tableDetails} ></DataTableView>
                    </CardBody>
                </Card> : <span />
            }
            <br/><br/>
        </div>
    }

    toggleShowReportDetails() {
        this.setState({
            showReportDetails: !this.state.showReportDetails
        });
    }

    async handleReportChange(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        this.setState({
            dataLoading: true,
        });

        let reportStart = moment(this.state.newReportDateStart).startOf('day');
        let reportEnd = moment(this.state.newReportDateEnd).endOf('day');
        let accountIdList = this.state.newAccountIdList;

        this.setState({
            currentReportAccountGroups: this.state.newReportAccountGroups,
            currentReportDateStart: reportStart,
            currentReportDateEnd: reportEnd,
            accountIdList: accountIdList,
            showReportDetails: false,
        });

        await this.loadReadingRecords(reportStart, reportEnd, accountIdList);

        this.setState({
            dataLoading: false,
        });
    }

    async loadReadingRecords(startDate: moment.Moment, endDate: moment.Moment, accountIdList: Array<string>): Promise<void> {
        let startMonthNumber = startDate.month();
        let startMonth = startMonthNumber.toString();
        if (startMonthNumber < 10) startMonth = "0" + startMonth;
        let startDayNumber = startDate.date();
        let startDay = startDayNumber.toString();
        if (startDayNumber < 10) startDay = "0" + startDay;
        let startString = `${startDate.year()}-${startMonth}-${startDay}`;
        let endMonthNumber = endDate.month();
        let endMonth = endMonthNumber.toString();
        if (endMonthNumber < 10) endMonth = "0" + endMonth;
        let endDayNumber = endDate.date();
        let endDay = endDayNumber.toString();
        if (endDayNumber < 10) endDay = "0" + endDay;
        let endString = `${endDate.year()}-${endMonth}-${endDay}`;
        let snapshot = await firebase.firestore().collection(`groupedReadingRecords`)
            .where('day', '>=', startString)
            .where('day', '<=', endString)
            .where('schoolId', '==', this.props.user.schoolId)
            .get();

        try {
            let readingRecords = new Map<string, ReadingRecord>();
            if (snapshot != null) {
                snapshot.forEach((doc) => {
                    let readGroupedRecords = doc.data();
                    if (readGroupedRecords != null && readGroupedRecords.records != null) {
                        Object.keys(readGroupedRecords.records).forEach((readingRecordId) => {
                            let readingRecord = ReadingRecord.fromFirebase(readGroupedRecords.records[readingRecordId]);
                            if (accountIdList.includes(readingRecord.accountId)) {
                                let readingDate = moment(readingRecord.readingDateTime.toDate());
                                if (readingDate.isAfter(startDate) && readingDate.isBefore(endDate)) {
                                    readingRecords.set(readingRecordId, readingRecord);
                                }
                            }
                        });
                    }
                });
            }

            await this.createReadingReport(startDate, endDate, readingRecords);

            this.setState({
                dataLoaded: true,
                readingRecords: readingRecords,
            });
        } catch (error) {
            console.log(error);
            this.props.snackbar("Load data failed");
        }
    }

    handleAccountChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newAccountIdList.indexOf(e.target.value);
        if (index > -1) {
            this.state.newAccountIdList.splice(index, 1);
        } else {
            this.state.newAccountIdList.push(e.target.value);
        }
        this.setState({});
    }

    selectAllAccounts(): void {
        this.setState({
            newAccountIdList: this.state.availableAccountIds.slice(),
        })
    }

    handleAccountGroupChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newReportAccountGroups.indexOf(e.target.value);
        if (index > -1) {
            this.state.newReportAccountGroups.splice(index, 1);
        } else {
            this.state.newReportAccountGroups.push(e.target.value);
        }
        let accountIdList = [];
        for (let nextAccountGroupId of this.state.newReportAccountGroups) {
            let nextAccountGroup = this.props.accountGroups.get(nextAccountGroupId);
            if (nextAccountGroup != null) {
                for (let nextAccountId of nextAccountGroup.accounts.keys()) {
                    let nextAccountDeets = nextAccountGroup.accounts.get(nextAccountId);
                    if (nextAccountDeets.member != null && nextAccountDeets.member &&
                        accountIdList.indexOf(nextAccountId) === -1 && this.props.accountsMinimised.has(nextAccountId)) {
                        accountIdList.push(nextAccountId);
                    }
                }
            }
        }
        this.setState({
            availableAccountIds: accountIdList,
            collapse: false,
            accountIdList: [],
        });
    }

    handleStartDateChange(date: Date): void {
        if (date != null && (this.state.newReportDateEnd == null || date.getTime() > this.state.newReportDateEnd.getTime())) {
            this.setState({
                newReportDateStart: date,
                newReportDateEnd: moment(date).add('1', 'days').toDate(),
            });
        } else {
            this.setState({
                newReportDateStart: date,
            });
        }
    }

    handleEndDateChange(date: Date): void {
        this.setState({
            newReportDateEnd: date,
        });
    }

    createReadingReport(reportStartDate: moment.Moment, reportEndDate: moment.Moment, readingRecords: Map<string, ReadingRecord>): void {
        let dayBuckets = new Array<any>();
        let endDate = reportEndDate.clone().add(1, 'days');
        let diffDays = moment.duration(reportStartDate.diff(endDate)).asDays();

        for (let i = diffDays; i <= 0; i++) {
            dayBuckets.push([endDate.clone().add(i, 'days'), 0, {}]);
        }

        for (let nextReadingRecordId of Array.from(readingRecords.keys())) {
            let nextReadingRecord = readingRecords.get(nextReadingRecordId);
            let readingDate = moment(nextReadingRecord.readingDateTime.toDate());

            for (let i = 0; i < dayBuckets.length; i++) {
                let nextDayBucket = dayBuckets[i];
                if (readingDate.isBefore(nextDayBucket[0])) {
                    if (i > 0) {
                        let currentDayBucket = dayBuckets[i - 1];
                        currentDayBucket[1]++;
                        if (currentDayBucket[2][nextReadingRecord.accountId] == null) {
                            currentDayBucket[2][nextReadingRecord.accountId] = {};
                        }
                        currentDayBucket[2][nextReadingRecord.accountId][nextReadingRecordId] = nextReadingRecord;
                    }
                    break;
                }
            }
        }
        // Remove last entry as it is just midnight
        dayBuckets.pop();

        let resultData = [["Day", "Number of reads"]];
        for (let i = 0; i < dayBuckets.length; i++) {
            resultData.push([dayBuckets[i][0].toDate(), dayBuckets[i][1]]);
        }

        const chartEvents: ReactGoogleChartEvent[] = [
            {
                eventName: "select",
                callback: async ({ chartWrapper }) => {
                    if (chartWrapper.getChart().getSelection() != null && chartWrapper.getChart().getSelection().length > 0) {
                        let selection = chartWrapper.getChart().getSelection()[0];

                        let startRow = selection.row;
                        let rowCount = 1;
                        if (selection.row == null) {
                            startRow = 0;
                            rowCount = dayBuckets.length;
                        }

                        let tableData = this.getTableDetails(startRow, rowCount, dayBuckets);


                        this.setState({
                            tableDetails: tableData,
                        });
                    }
                }
            }
        ];

        let tableData = this.getTableDetails(0, dayBuckets.length, dayBuckets);

        this.setState({
            reportData: resultData,
            chartEvents: chartEvents,
            tableDetails: tableData,
        });
    }

    getTableDetails(startRow: number, rowCount: number, dayBuckets: Array<any>): TableDetails {
        let headers = ["Student", "Total reading Count", "Home reading count"];
        let data = [];
        let dataExplosion = new Map<string, any>();
        for (let bucketIndex = startRow; bucketIndex < startRow + rowCount; bucketIndex++) {

            for (let nextAccountId of this.state.accountIdList) {
                if (!dataExplosion.has(nextAccountId)) {
                    dataExplosion.set(nextAccountId, {
                        totalRead: 0,
                        homeRead: 0,
                    });
                }
                if (dayBuckets[bucketIndex][2][nextAccountId] != null) {
                    for (let readingRecordId in dayBuckets[bucketIndex][2][nextAccountId]) {
                        let readingRecord = dayBuckets[bucketIndex][2][nextAccountId][readingRecordId];
                        dataExplosion.get(nextAccountId).totalRead++;
                        if (readingRecord.parentReading != null && readingRecord.parentReading) {
                            dataExplosion.get(nextAccountId).homeRead++;
                        }
                    }
                }
            }
        }
        for (let accountId of dataExplosion.keys()) {
            data.push([this.props.accountsMinimised.has(accountId) ? this.props.accountsMinimised.get(accountId).name : "Removed account", dataExplosion.get(accountId).totalRead, dataExplosion.get(accountId).homeRead]);
        }

        return { headers: headers, data: data };
    }
}

export default ReadingRecordsReportView;
import React, { Component } from 'react';
import { Button, ModalBody, ModalHeader, Modal, Input, Label, FormGroup, Form, Table, Card, CardBody } from 'reactstrap';
import firebase from '../firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import queryString from 'query-string';
import confirm from "reactstrap-confirm";
import { User } from '../data/user';
import { Product, ProductCategory } from '../data/marketplace';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

interface IState {
    categories: Map<string, ProductCategory>;
    categoryIdsSorted: string[];
    modal: boolean;
    newCategory: string;
}

interface IProps extends RouteComponentProps {
    tutorialCallback: (pageName: string) => void;
    user: User;
    snackbar: (text?: string) => void;
}

class ProductCategoriesView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            categories: new Map(),
            categoryIdsSorted: [],
            modal: false,
            newCategory: '',
        };
        this.removeItem = this.removeItem.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.handleCreateCategory = this.handleCreateCategory.bind(this);
    }

    render(): JSX.Element {
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/products'}>All Products</Link></BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Categories</div>
                    <p className="cardSubTitle">Some Categories</p>
                <div>
                    <Button className="adminPagesButton" type="button" onClick={this.modalToggle}>Create Category</Button>
                    <Modal isOpen={this.state.modal} toggle={this.modalToggle} >
                        <ModalHeader toggle={this.modalToggle}>Categories</ModalHeader>
                        <ModalBody>
                            <div className="border rounded form-margin">
                                <Form onSubmit={this.handleCreateCategory}>
                                    <FormGroup>
                                        <Label for="newCategoryTitle">Category Name</Label>
                                        <Input type="text" required name="newCategoryTitle" onChange={(e) => this.setState({
                                            newCategory: e.target.value
                                        })} 
                                        value={this.state.newCategory} />
                                    </FormGroup>
                                    <Button className="adminPagesButton">
                                        Create
                                    </Button>
                                </Form>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.categoryIdsSorted.map((categoryId) => {
                            let category = this.state.categories.get(categoryId);
                            if (categoryId === null) {
                                return null
                            }
                            return <tr>
                                <th scope="row">{category.name}</th>
                                <td>{ }</td>
                                <td><Button color="link" onClick={async () => {
                                    let result = await confirm({ title: "Confirm", message: "Please Confirm you want to delete this Category", ConfirmText: "Confirm" });
                                    if (result) {
                                        this.removeItem(categoryId);
                                    }
                                }}><i className="material-icons">delete</i></Button></td>
                            </tr>
                        })}
                    </tbody>
                </Table><br />
                </CardBody>
                </Card>
            </div>
        )
    }

    async handleCreateCategory(e: React.FormEvent): Promise<void> {
        e.preventDefault();

        let categoryId = uuidv4();

        let newCategory = new ProductCategory();
        newCategory.name = this.state.newCategory;

        await firebase.firestore().doc(`marketplace/${this.props.user.schoolId}`).set({
            categories: {
                [categoryId]: newCategory.toFirebase()
            },
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });

        this.state.categories.set(categoryId, newCategory);
        let categoryIdsSorted = Array.from(this.state.categories.keys());
        categoryIdsSorted.sort((id1, id2) => {
            let kd1 = this.state.categories.get(id1);
            let kd2 = this.state.categories.get(id2);
            return kd1.name.localeCompare(kd2.name);
        });

        this.setState({
            categoryIdsSorted: categoryIdsSorted
        })
    }

    modalToggle(): void {
        this.setState({
            modal: !this.state.modal,
        })
    }

    async componentDidMount(): Promise<void> {
        try {
            const marketplaceSnapshot = await firebase.firestore().doc(`marketplace/${this.props.user.schoolId}`).get();
            let marketplaceData = marketplaceSnapshot.data();

            let productCategories = new Map<string, ProductCategory>();
            if (marketplaceData != null && marketplaceData.categories != null) {
                for (let nextEntryId of Object.keys(marketplaceData.categories)) {
                    productCategories.set(nextEntryId, ProductCategory.fromFirebase(marketplaceData.categories[nextEntryId]));
                }
            }

            let categoryIdsSorted = Array.from(productCategories.keys());
            categoryIdsSorted.sort((id1, id2) => {
                let kd1 = productCategories.get(id1);
                let kd2 = productCategories.get(id2);
                return kd1.name.localeCompare(kd2.name);
            });

            this.setState({
                categories: productCategories,
                categoryIdsSorted: categoryIdsSorted,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(categoryId: string): Promise<void> {
        try {
            await firebase.firestore().doc(`marketplace/${this.props.user.schoolId}`).set({
                categories: {
                    [categoryId]: firebase.firestore.FieldValue.delete()
                },
                updated: firebase.firestore.FieldValue.serverTimestamp(),
            }, { merge: true });

            this.state.categories.delete(categoryId);
            this.state.categoryIdsSorted.splice(this.state.categoryIdsSorted.indexOf(categoryId), 1);

            this.setState({});
            this.props.snackbar("Deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }
}

export default ProductCategoriesView;
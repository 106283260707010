import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import queryString from 'query-string';
import {
    Card, CardBody, Table,
    CardHeader, Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Collapse, Modal, ModalHeader, ModalBody, UncontrolledTooltip, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import confirm from "reactstrap-confirm";
import TopicsSearch from "./ninja_components/topics_search";
import TopicsSelector from "./ninja_components/topics_selector";
import { searchMinigames, LearningGameSearchStub } from "./helpers/topic_services";
import { User, USER_ROLE } from './data/user';
import { RouteComponentProps } from "react-router";
import { LearningGame, LearningPath, TIME_MULTIPLIER } from './data/learning_games';
import { v4 as uuidv4 } from 'uuid';
import { pathImages, bossTypes, subjectIds } from './Prettifier';

interface IState {
    worldId: string;
    worldName: string;
    subjectFilter: string;
    pathId: string;
    path: LearningPath;
    timeMultiplier: TIME_MULTIPLIER;
    currentLearningPathName: string;
    currentLearningPathIntroduction: string;
    introductionAvatar: string;
    currentLearningPathExamBoards: string[];
    currentLearningPathLicenseId: string;
    currentLearningPathSchoolId: string;
    currentLearningPathGameIds: string[];
    currentLearningPathTopology: string;
    currentLearningPathAvatarUrl: string;
    zapEnabled: boolean;
    games: Map<string, LearningGame>;
    // badges: [],
    // studySections: [],
    collapse: boolean;
    showMinigameSelect: boolean;
    currentSubjectId: string;
    currentTopics: string[];
    currentTopicsTags: Map<string, string[]>;
    currentSearchGameIds: string[];
    currentSearchSchoolGameIds: string[];
    currentSearchTopic: string;
    currentSearchTopicTags: Array<Array<string>>;
    currentSearchAllTopicGames: Map<string, LearningGameSearchStub>;
    currentSearchAllTopicSchoolGames: Map<string, LearningGameSearchStub>;
    dropdownOpen: boolean;
    searched: boolean;
    user: User;
}

interface MatchParams {
    pathId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    schoolLicenses: Map<string, boolean>;
    snackbar: (text?: string) => void;
}

class LearningPathView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            pathId: props.match.params.pathId,
            path: null,
            timeMultiplier: null,
            currentLearningPathName: '',
            currentLearningPathIntroduction: '',
            introductionAvatar: '',
            currentLearningPathExamBoards: [],
            currentLearningPathLicenseId: null,
            currentLearningPathSchoolId: '',
            currentLearningPathGameIds: [],
            currentLearningPathTopology: '',
            currentLearningPathAvatarUrl: '',
            zapEnabled: false,
            games: new Map<string, LearningGame>(),
            // badges: [],
            // studySections: [],
            user: props.user,
            collapse: props.match.params.pathId !== "-1",
            showMinigameSelect: false,
            currentSubjectId: '',
            currentTopics: [],
            currentTopicsTags: new Map<string, string[]>(),
            currentSearchGameIds: [],
            currentSearchSchoolGameIds: [],
            currentSearchTopic: '',
            currentSearchTopicTags: [],
            currentSearchAllTopicGames: new Map(),
            currentSearchAllTopicSchoolGames: new Map(),
            dropdownOpen: false,
            searched: false,
        };
        this.dealWithChange = this.dealWithChange.bind(this);
        this.zapEnabledChange = this.zapEnabledChange.bind(this);
        this.handleExamBoardChange = this.handleExamBoardChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.toggle = this.toggle.bind(this);
        this.moveItemUp = this.moveItemUp.bind(this);
        this.toggleMinigameSelect = this.toggleMinigameSelect.bind(this);
        this.searchMinigames = this.searchMinigames.bind(this);
        this.handleMinigameAdd = this.handleMinigameAdd.bind(this);
        this.handleSearchTopicsChange = this.handleSearchTopicsChange.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.createMinigameRows = this.createMinigameRows.bind(this);
    }

    createMinigameRows(gameIds: string[], queryValues: string): JSX.Element[] {
        let rows: JSX.Element[] = [];

        let pathEditable = this.state.currentLearningPathSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || (this.state.user.roles.has(USER_ROLE.learningCreator) && this.state.user.roles.get(USER_ROLE.learningCreator));

        if (gameIds == null) {
            return rows;
        }
        for (let pos = 0; pos < gameIds.length; pos++) {
            let nextLearningGameId = gameIds[pos];
            let nextLearningGame = this.state.games.get(nextLearningGameId);
            let editable = nextLearningGame.schoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || (this.state.user.roles.has(USER_ROLE.learningCreator) && this.state.user.roles.get(USER_ROLE.learningCreator));


            let url = "learningQuizlets";
            let typeName = "Quizlet";
            if (nextLearningGame.type === "QUIZLET") {
                url = "learningQuizlets";
                typeName = "Quizlet";
            } else if (nextLearningGame.type === "GRID") {
                url = "learningGrids";
                typeName = "Number grid";
            } else if (nextLearningGame.type === "CIRCLE") {
                url = "learningCircles";
                typeName = "Number circle";
            } else if (nextLearningGame.type === "SLICE") {
                url = "learningSlices";
                typeName = "Number slice";
            } else if (nextLearningGame.type === "SHOOT") {
                url = "learningShoots";
                typeName = "Number shoot";
            } else if (nextLearningGame.type === "MULSPEEDTEST") {
                url = "learningMultiplicationSpeedTest";
                typeName = "Multiplication speed test";
            } else if (nextLearningGame.type === "BOSSLEVEL") {
                url = "learningBossLevel";
                typeName = "Boss Level";
            } else if (nextLearningGame.type === "CHOICE") {
                url = "learningChoiceGame";
                typeName = "Choice Game";
            } else if (nextLearningGame.type === "SPLIT") {
                url = "learningSplitGame";
                typeName = "Split Game";
            } else if (nextLearningGame.type === "TRACE") {
                url = "learningTrace";
                typeName = "Trace Game";
            }

            rows.push(
                <tr key={"column-" + (pos)}>
                    <td>
                        {pos + 1}
                    </td>
                    <td>
                        <Link to={`/${url}/${nextLearningGameId}?${queryValues}`}>
                            {nextLearningGame.title}
                        </Link>
                        {!editable ? <div style={{ "color": "red" }}>View only</div> : ""}
                        {editable && nextLearningGame.licenseId != null ?
                            <div style={{ "color": "red" }}>Licensed: {nextLearningGame.licenseId}</div> : ""}
                    </td>
                    <td>
                        {typeName}
                    </td>
                    <td>
                        {pathEditable && gameIds[0] !== nextLearningGameId ?
                            <Button color="success" onClick={async () => {
                                this.moveItemUp(nextLearningGameId)
                            }}><i className="material-icons">arrow_upward</i></Button> : <span />}
                        &nbsp;
                        {pathEditable ?
                            <Button color="primary" onClick={async () => {
                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove this game from the learning path", confirmText: "Confirm" });
                                if (result) {
                                    this.removeItem(nextLearningGameId)
                                }
                            }}><i className="material-icons">delete</i></Button> : <span />
                        }
                    </td>
                </tr>
            );
        }
        return rows;
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let rows = this.createMinigameRows(this.state.currentLearningPathGameIds, queryValues);

        let editable = this.state.currentLearningPathSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All learning worlds</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.path != null ? this.state.path.name : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Learning path</div>
                        <p className="cardSubTitle">A learning path contains a set of learning games that are either worked through progressively or at the user's choice.<br />
                        You may only want one path for a learning area (e.g. Learning world of 'Punctuation' may only have one path of 'Punctuation').<br />
                        Other learning worlds may have multiple paths that are studied independently (e.g. Learning world of 'Shakespeare' could have paths for 'Romeo & Juliet' and 'Macbeth')</p>
                        {(this.state.path != null) ?
                            <div>
                                <div>
                                    {this.state.pathId !== "-1" ?
                                        <Button onClick={this.toggle} className="altButton">
                                            {this.state.collapse ? "View details" : "Close"}
                                        </Button> : null
                                    }
                                </div>
                                <Collapse isOpen={!this.state.collapse}>
                                    <Form onSubmit={this.handleUpdate}>
                                        <fieldset disabled={!editable}>
                                            <FormGroup>
                                                <Label for="currentLearningPathName">Name</Label>
                                                <Input type="text" required name="currentLearningPathName" onChange={(e) => this.setState({
                                                    currentLearningPathName: e.target.value,
                                                })} value={this.state.currentLearningPathName} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentSubjectId">Subject *</Label>
                                                <Input disabled={this.state.worldId != null || this.state.pathId !== "-1"} type="select" name="currentSubjectId"
                                                    onChange={(e) => this.setState({
                                                        currentSubjectId: e.target.value,
                                                    })}
                                                    value={this.state.currentSubjectId}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                                        let subjectName = subjectIds.get(subjectId);
                                                        return <option value={subjectId}>{subjectName}</option>;
                                                    })}
                                                </Input>
                                            </FormGroup>
                                            {this.state.currentSubjectId === '' ? <div></div> :
                                                <TopicsSelector key={"bob"} countryCode={this.state.user.countryCode} subject={this.state.currentSubjectId} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags} callback={this.handleTopicsChange} />
                                            }
                                            <FormGroup>
                                                <Label for="currentLearningPathIntroduction">Path Introduction</Label>
                                                <Input type="text" name="currentLearningPathIntroduction" onChange={(e) => this.setState({
                                                    currentLearningPathIntroduction: e.target.value,
                                                })} value={this.state.currentLearningPathIntroduction} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="introductionAvatar">Introduction avatar</Label>
                                                <Input type="select" name="introductionAvatar" value={this.state.introductionAvatar} onChange={(e) => this.setState({
                                                    introductionAvatar: e.target.value,
                                                })}>
                                                    <option value="">Huw</option>
                                                    {Array.from(bossTypes.keys()).map((key) => {
                                                        let name = bossTypes.get(key);
                                                        return (
                                                            <option value={key} key={key}>{name}</option>
                                                        )
                                                    })}
                                                    <option value="custom" key="custom">Custom - Image from the internet</option>
                                                </Input>
                                                {this.state.introductionAvatar === "custom" ?
                                                    <div>
                                                        <FormGroup>
                                                            <Label for="currentLearningPathAvatarUrl">Custom avatar image</Label>
                                                            <Input type="text" name="currentLearningPathAvatarUrl" onChange={(e) => this.setState({
                                                                currentLearningPathAvatarUrl: e.target.value,
                                                            })} value={this.state.currentLearningPathAvatarUrl} />
                                                        </FormGroup>
                                                        <img src={this.state.currentLearningPathAvatarUrl} height="80px" alt="" />
                                                    </div> :
                                                    <img src={'/images/characters/' + (this.state.introductionAvatar === "" ? "huw.png" : this.state.introductionAvatar)} height="80px" alt="" />
                                                }
                                                <br /><br />
                                            </FormGroup>
                                            <label>
                                                Game speeds <i className="fas fa-info-circle icons-info" id="help-learning-timemultiplier" />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learning-timemultiplier">
                                                    Control how fast or slow the game timers will be for all games within the path.
                                                    Individual games can also have speed controls that work within the context of the path speed control.
                                                    For example, a game with a 'fast' speed on a path with a 'slow' speed will play at 'moderate' speed.
                                                    Use this to allow developing or advanced students to play at a speed that suits them.
                                                </UncontrolledTooltip>
                                            </label>
                                            <Input type="select" value={this.state.timeMultiplier} onChange={this.dealWithChange}>
                                                <option value={TIME_MULTIPLIER.fastest}>Fastest</option>
                                                <option value={TIME_MULTIPLIER.fast}>Fast</option>
                                                <option value={TIME_MULTIPLIER.moderate}>Moderate</option>
                                                <option value={TIME_MULTIPLIER.slow}>Slow</option>
                                                <option value={TIME_MULTIPLIER.slowest}>Slowest</option>
                                            </Input>
                                            <FormGroup>
                                                <Label for="currentLearningPathTopology">Path theme *</Label>
                                                <Input type="select" name="currentLearningPathTopology"
                                                    onChange={(e) => this.setState({
                                                        currentLearningPathTopology: e.target.value,
                                                    })}
                                                    value={this.state.currentLearningPathTopology}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(pathImages.keys()).map((key) => {
                                                        let name = pathImages.get(key);
                                                        return (
                                                            <option value={key} key={key}>{name}</option>
                                                        )
                                                    })
                                                    }
                                                </Input><br />
                                                <img src={`/images/worlds/${this.state.currentLearningPathTopology}.jpg`} height="80px" alt="" /><br /><br />
                                            </FormGroup>
                                            {this.props.schoolLicenses.has('maths') && this.props.schoolLicenses.get('maths') ?
                                                <FormGroup check>
                                                    <Input type="checkbox" name="zapEnabledCheckbox" disabled={this.state.pathId != "-1"} checked={this.state.zapEnabled} onChange={this.zapEnabledChange} />
                                                    <Label for="zapEnabledCheckbox">Zap Enabled <i className="fas fa-info-circle icons-info" id="help-zapEnabled-checkbox" /></Label>

                                                    <UncontrolledTooltip placement="bottom" autohide={false} target="help-zapEnabled-checkbox">
                                                        Zap Enabled Setting - Can only be set at creation
                                                            <br />
                                                            Zap enabled paths have more game options but can only be played on Zap Apps
                                                        </UncontrolledTooltip>
                                                </FormGroup> : <span />}
                                            <br /><br />
                                            {this.state.user.role === USER_ROLE.admin ?
                                                <FormGroup>
                                                    <Label for="currentLearningPathLicenseId">License id</Label>
                                                    <Input type="text" name="currentLearningPathLicenseId" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                                        currentLearningPathLicenseId: e.target.value,
                                                    })} value={this.state.currentLearningPathLicenseId} />
                                                </FormGroup> : <span />
                                            }
                                            {this.state.user.role === USER_ROLE.admin ?
                                                <FormGroup>
                                                    <Label for="currentLearningPathExamBoards">Exam boards</Label>
                                                    <Input type="select" name="currentLearningPathExamBoards"
                                                        onChange={this.handleExamBoardChange}
                                                        value={this.state.currentLearningPathExamBoards}
                                                        multiple>
                                                        <option value="Edexcel">Edexcel</option>
                                                        <option value="WJEC">WJEC</option>
                                                        <option value="AQA">AQA</option>
                                                    </Input>
                                                </FormGroup> : ""
                                            }
                                            {editable ? this.state.pathId === "-1" ?
                                                <Button className="adminPagesButton" disabled={this.state.currentLearningPathTopology === '' || this.state.currentSubjectId === "" || this.state.currentSubjectId == null}>Create</Button> : <Button className="adminPagesButton" disabled={this.state.currentLearningPathTopology === ''}>Update</Button> : <span />
                                            }
                                        </fieldset>
                                    </Form>
                                </Collapse>
                            </div> : <span />
                        }
                    </CardBody>
                </Card>
                {(this.state.path != null) ?
                    <Card className="mainCard top-buffer">
                        <CardBody className="d-flex flex-column">
                            {this.state.pathId === "-1" ? <br /> :
                                <div>
                                    <div className="cardTitle2">Learning games</div>
                                    {editable ?
                                        <div className="top-buffer bottom-buffer">
                                            <ButtonDropdown color="primary" isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                                <DropdownToggle caret className="adminPagesButton" >
                                                    Create new game
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>
                                                        <Link to={`/learningQuizlets/-1?${queryValues}`}>
                                                            New quizlet
                                                            </Link>
                                                    </DropdownItem>
                                                    {this.state.zapEnabled ? 
                                                    <DropdownItem>
                                                        <Link to={`/learningSplitGame/-1?${queryValues}`}>
                                                            New Split Game
                                                        </Link>
                                                    </DropdownItem> : <span />}
                                                    {this.state.zapEnabled ? 
                                                    <DropdownItem>
                                                        <Link to={`/learningChoiceGame/-1?${queryValues}`}>
                                                            New Choice Game
                                                        </Link>
                                                    </DropdownItem> : <span />}
                                                    {this.state.zapEnabled ? 
                                                    <DropdownItem>
                                                        <Link to={`/learningTrace/-1?${queryValues}`}>
                                                            New Trace Game
                                                        </Link>
                                                    </DropdownItem> : <span />}
                                                    {this.state.zapEnabled ?
                                                        <DropdownItem>
                                                            <Link to={`/learningGrids/-1?${queryValues}`}>
                                                                New number grid
                                                            </Link>
                                                        </DropdownItem> : <span />}
                                                    {this.state.zapEnabled ?
                                                        <DropdownItem>
                                                            <Link to={`/learningCircles/-1?${queryValues}`}>
                                                                New number circle
                                                            </Link>
                                                        </DropdownItem> : <span />}
                                                    {this.state.zapEnabled ?
                                                        <DropdownItem>
                                                            <Link to={`/learningShoots/-1?${queryValues}`}>
                                                                New number shoot
                                                            </Link>
                                                        </DropdownItem> : <span />}
                                                    {this.state.zapEnabled ?
                                                        <DropdownItem>
                                                            <Link to={`/learningSlices/-1?${queryValues}`}>
                                                                New number slice
                                                            </Link>
                                                        </DropdownItem> : <span />}
                                                    {this.state.zapEnabled ?
                                                        <DropdownItem>
                                                            <Link to={`/learningMultiplicationSpeedTest/-1?${queryValues}`}>
                                                                New Multiplication Speed test
                                                            </Link>
                                                        </DropdownItem> : <span />}
                                                    {this.state.zapEnabled ?
                                                        <DropdownItem>
                                                            <Link to={`/learningBossLevel/-1?${queryValues}`}>
                                                                New Boss Level
                                                            </Link>
                                                        </DropdownItem> : <span />}
                                                </DropdownMenu>
                                            </ButtonDropdown>&nbsp;
                                            <Button onClick={this.toggleMinigameSelect} className="adminPagesButton">
                                                Add existing game
                                            </Button>
                                        </div> : <span />
                                    }
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Name</th>
                                                <th>Game type</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows}
                                        </tbody>
                                    </Table>
                                    <br />
                                </div>
                            }
                        </CardBody></Card> : <br />
                }
                <Modal isOpen={this.state.showMinigameSelect} toggle={this.toggleMinigameSelect}>
                    <ModalHeader toggle={this.toggleMinigameSelect}>Select learning game</ModalHeader>
                    <ModalBody>
                        <div className="border rounded form-margin">
                            <TopicsSearch key={"Geoff"} countryCode={this.state.user.countryCode} worldId={this.state.worldId} callback={this.handleSearchTopicsChange} />
                            <br />
                            <Button disabled={this.state.currentSearchTopic === ""} color="primary" onClick={this.searchMinigames}>
                                Search
                            </Button>
                            <br /><br />
                            {!this.state.searched ? <span /> :
                                <div>
                                    {this.state.currentSearchSchoolGameIds == null || this.state.currentSearchSchoolGameIds.length === 0 ? <div><b>Custom minigames</b><br />No results</div> :
                                        <div><b>Custom minigames</b><br />
                                            {this.state.currentSearchSchoolGameIds.map((gameId) => {
                                                let hideAdd = this.state.currentLearningPathGameIds.indexOf(gameId) !== -1;
                                                return <div>{this.state.currentSearchAllTopicSchoolGames.get(gameId).text} ({this.state.currentSearchAllTopicSchoolGames.get(gameId).type[0].toUpperCase()}{this.state.currentSearchAllTopicSchoolGames.get(gameId).type.slice(1).toLowerCase()})
                                        <Button color={'link'} disabled={hideAdd} onClick={() => this.handleMinigameAdd(gameId)}><i
                                                        className="material-icons">add</i>
                                                    </Button>
                                                </div>
                                            })}
                                        </div>
                                    }
                                    {this.state.currentSearchGameIds == null || this.state.currentSearchGameIds.length === 0 ? <div><b>Library minigames</b><br />No results</div> :
                                        <div><b>Library minigames</b><br />
                                            {this.state.currentSearchGameIds.map((gameId) => {
                                                let hideAdd = this.state.currentLearningPathGameIds.indexOf(gameId) !== -1;
                                                return <div>{this.state.currentSearchAllTopicGames.get(gameId).text} ({this.state.currentSearchAllTopicGames.get(gameId).type[0].toUpperCase()}{this.state.currentSearchAllTopicGames.get(gameId).type.slice(1).toLowerCase()})
                                        <Button color={'link'} disabled={hideAdd} onClick={() => this.handleMinigameAdd(gameId)}><i
                                                        className="material-icons">add</i>
                                                    </Button>
                                                </div>
                                            })}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    async moveItemUp(gameId: string): Promise<void> {
        let index = this.state.currentLearningPathGameIds.indexOf(gameId);
        this.state.currentLearningPathGameIds.splice(index - 1, 0, this.state.currentLearningPathGameIds.splice(index, 1)[0]);
        await firebase.firestore().doc(`learningPaths/${this.state.pathId}`).set({ gameIds: this.state.currentLearningPathGameIds }, { merge: true });
        await this.persistPathChange();
        this.setState({});
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    handleSearchTopicsChange(topicId: string, topicsTags: string[][]) {
        let newTopicTags: string[][] = topicsTags == null ? [] : topicsTags;
        this.setState({
            currentSearchTopic: topicId,
            currentSearchTopicTags: newTopicTags.slice(),
            searched: false,
        });
    }

    zapEnabledChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            zapEnabled: e.target.checked,
        });
    }

    async handleMinigameAdd(gameId: string): Promise<void> {
        let gameRef = firebase.firestore().doc(`learningGames/${gameId}`);
        let gameSnapshot = await gameRef.get();
        let gameData = gameSnapshot.data();
        let game = LearningGame.fromFirebase(gameData);
        this.state.games.set(gameId, game);
        this.state.currentLearningPathGameIds.push(gameId);
        await firebase.firestore().doc(`learningPaths/${this.state.pathId}`).set({ gameIds: this.state.currentLearningPathGameIds }, { merge: true });
        await this.persistPathChange();
        this.setState({});
    }

    async searchMinigames(): Promise<void> {
        let response = await searchMinigames(this.state.currentSearchTopic, this.state.currentSearchTopicTags, this.state.user.schoolId);
        let newGameIds = response.gameIds;
        newGameIds.sort((id1, id2) => {
            return response.allTopicGames.get(id1).text.localeCompare(response.allTopicGames.get(id2).text);
        });
        let newSchoolGameIds = response.schoolGameIds;
        newSchoolGameIds.sort((id1, id2) => {
            return response.allTopicSchoolGames.get(id1).text.localeCompare(response.allTopicSchoolGames.get(id2).text);
        });
        this.setState({
            searched: true,
            currentSearchGameIds: newGameIds,
            currentSearchAllTopicGames: response.allTopicGames,
            currentSearchSchoolGameIds: newSchoolGameIds,
            currentSearchAllTopicSchoolGames: response.allTopicSchoolGames,
        });
    }

    toggleMinigameSelect(): void {
        this.setState({
            showMinigameSelect: !this.state.showMinigameSelect,
            currentSearchGameIds: [],
            currentSearchSchoolGameIds: [],
            searched: false,
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.path != null ? this.state.path.name : ""
        });
    }

    handleExamBoardChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.currentLearningPathExamBoards.indexOf(e.target.value);
        if (index > -1) {
            this.state.currentLearningPathExamBoards.splice(index, 1);
        } else {
            this.state.currentLearningPathExamBoards.push(e.target.value);
        }
        this.setState({});
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleDropdown(): void {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }

    dealWithChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let path = this.state.path;
        path.name = this.state.currentLearningPathName;
        path.introduction = this.state.currentLearningPathIntroduction;
        path.introductionAvatar = this.state.introductionAvatar;
        path.examBoards = this.state.currentLearningPathExamBoards;
        path.licenseId = this.state.currentLearningPathLicenseId !== "" ? this.state.currentLearningPathLicenseId : null;
        path.gameIds = this.state.currentLearningPathGameIds;
        path.zapEnabled = this.state.zapEnabled;
        path.topologyId = this.state.currentLearningPathTopology;
        path.timeMultiplier = this.state.timeMultiplier;
        path.subjectId = this.state.currentSubjectId;
        path.topics = this.state.currentTopics;
        path.topicTags = this.state.currentTopicsTags;
        path.introductionAvatarUrl = this.state.currentLearningPathAvatarUrl;
        path.deleted = null;
        let newPath = false;
        let pathId = this.state.pathId;
        if (this.state.pathId === "-1") {
            newPath = true;
            pathId = uuidv4();
            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                path.schoolId = this.state.user.schoolId;
            }
        }
        let pathObject = path.toFirebase();
        try {
            await firebase.firestore().doc(`learningPaths/${pathId}`).set(pathObject);
            if (newPath) {
                let pathRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                await pathRef.update({
                    pathIds: firebase.firestore.FieldValue.arrayUnion(pathId)
                });
                let updateDeets = {
                    updated: firebase.database.ServerValue.TIMESTAMP,
                    updateType: 'WORLD',
                    updateId: this.state.worldId,
                };
                // Note, rather than retrieve the world and see if it has a school id we can be certain
                // that if this path has a school id then the world will have the same school id
                if (this.state.currentLearningPathSchoolId == null || this.state.currentLearningPathSchoolId === '') {
                    await firebase.database().ref('moduleInformation/changeHistory').push(updateDeets);
                } else {
                    await firebase.database().ref(`moduleInformation/changeHistorySchools/${this.state.currentLearningPathSchoolId}`).push(updateDeets);
                }
            } else {
                await this.persistPathChange();
            }
            this.setState({
                pathId: pathId,
                path: path,
                collapse: true,
            });
            this.props.history.replace(`/learningPaths/${this.state.pathId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async persistPathChange(): Promise<void> {
        let updateDeets = {
            updated: firebase.database.ServerValue.TIMESTAMP,
            updateType: 'PATH',
            updateId: this.state.pathId,
        };
        if (this.state.currentLearningPathSchoolId == null || this.state.currentLearningPathSchoolId === '') {
            await firebase.database().ref('moduleInformation/changeHistory').push(updateDeets);
        } else {
            await firebase.database().ref(`moduleInformation/changeHistorySchools/${this.state.currentLearningPathSchoolId}`).push(updateDeets);
        }
    }

    async componentDidMount(): Promise<void> {
        if (this.state.pathId !== "-1") {
            const pathRef = firebase.firestore().doc(`learningPaths/${this.state.pathId}`);
            pathRef.get().then(async (pathSnapshot) => {
                let pathData = pathSnapshot.data();
                let path = LearningPath.fromFirebase(pathData);
                let promises = [];
                let pathGames = new Map<string, LearningGame>();
                for (let nextGameRef in path.gameIds) {
                    let gameId = path.gameIds[nextGameRef];
                    const gameRef = firebase.firestore().doc(`learningGames/${gameId}`);
                    promises.push(new Promise<void>((resolve, reject) => {
                        gameRef.get().then((gameSnapshot) => {
                            let gameData = gameSnapshot.data();
                            if (gameData != null) {
                                let game = LearningGame.fromFirebase(gameData);
                                pathGames.set(gameId, game);
                            }
                            resolve();
                        }, (error) => {
                            console.log(error);
                            reject();
                        });
                    }));
                }
                await Promise.all(promises);
                this.setState({
                    path: path,
                    currentLearningPathName: path.name,
                    currentLearningPathIntroduction: path.introduction == null ? '' : path.introduction,
                    currentLearningPathGameIds: path.gameIds,
                    currentLearningPathExamBoards: path.examBoards == null ? [] : path.examBoards,
                    currentLearningPathSchoolId: path.schoolId,
                    currentSubjectId: path.subjectId,
                    currentTopics: path.topics == null ? [] : path.topics,
                    currentTopicsTags: path.topicTags == null ? new Map<string, string[]>() : path.topicTags,
                    currentLearningPathLicenseId: path.licenseId != null ? path.licenseId : null,
                    currentLearningPathTopology: path.topologyId != null ? path.topologyId : '',
                    timeMultiplier: path.timeMultiplier != null ? path.timeMultiplier : TIME_MULTIPLIER.moderate,
                    zapEnabled: path.zapEnabled != null ? path.zapEnabled : false,
                    introductionAvatar: path.introductionAvatar != null ? path.introductionAvatar : '',
                    currentLearningPathAvatarUrl: path.introductionAvatarUrl != null ? path.introductionAvatarUrl : '',
                    games: pathGames,
                });
            }, (error) => console.log(error));
        } else {
            let subjectId = '';
            if (this.state.worldId != null) {
                const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                let worldSnapshot = await worldRef.get();
                let world = worldSnapshot.data();
                subjectId = world.subject;
            }
            let likelyZapPath = this.props.schoolLicenses.has('maths') && this.props.schoolLicenses.get('maths') && subjectId === 'maths';
            this.setState({
                path: new LearningPath(),
                currentLearningPathName: "",
                currentLearningPathIntroduction: "",
                currentLearningPathExamBoards: [],
                currentLearningPathGameIds: [],
                currentLearningPathSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                currentLearningPathTopology: '',
                introductionAvatar: '',
                currentSubjectId: subjectId,
                currentLearningPathAvatarUrl: '',
                currentTopics: [],
                currentTopicsTags: new Map<string, string[]>(),
                timeMultiplier: TIME_MULTIPLIER.moderate,
                zapEnabled: likelyZapPath,
            });
        }
    }

    async removeItem(gameId: string): Promise<void> {
        let index = this.state.currentLearningPathGameIds.indexOf(gameId);
        this.state.currentLearningPathGameIds.splice(index, 1);
        this.state.path.gameIds = this.state.currentLearningPathGameIds;
        const pathRef = firebase.firestore().doc(`learningPaths/${this.state.pathId}`);
        try {
            await pathRef.update({ gameIds: this.state.currentLearningPathGameIds });

            this.persistPathChange();

            this.setState({});
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    // removeBadge(badgeId) {
    //     const badgeRef = firebase.database().ref(`moduleInformation/badges/${badgeId}`);
    //     badgeRef.remove((error) => {
    //         if (error == null) {
    //             let index = this.state.group.badges.indexOf(badgeId);
    //             this.state.group.badges.splice(index, 1);
    //             let badgeIndex;
    //             for (badgeIndex = 0; badgeIndex < this.state.badges.length; badgeIndex++) {
    //                 if (this.state.badges[badgeIndex].badgeId === badgeId) {
    //                     break;
    //                 }
    //             }
    //             this.state.badges.splice(badgeIndex, 1);
    //             const groupRef = firebase.database().ref('moduleInformation/groups/' + this.state.groupId + '/badges');
    //             groupRef.set(this.state.group.badges, (error) => {
    //                 if (error == null) {
    //                     this.setState({});
    //                     this.props.snackbar();
    //                 } else {
    //                     console.log(error);
    //                     this.props.snackbar("Save failed");
    //                 }
    //             });
    //         } else {
    //             console.log(error);
    //             this.props.snackbar("Save failed");
    //         }
    //     });
    // }
    //
    // removeStudySection(studySectionId) {
    //     const studySectionRef = firebase.database().ref(`moduleInformation/studySections/${studySectionId}`);
    //     studySectionRef.remove((error) => {
    //         if (error == null) {
    //             let index = this.state.group.studySections.indexOf(studySectionId);
    //             this.state.group.studySections.splice(index, 1);
    //             let studySectionIndex;
    //             for (studySectionIndex = 0; studySectionIndex < this.state.studySections.length; studySectionIndex++) {
    //                 if (this.state.studySections[studySectionIndex].studySectionId === studySectionId) {
    //                     break;
    //                 }
    //             }
    //             this.state.studySections.splice(studySectionIndex, 1);
    //             const groupRef = firebase.database().ref('moduleInformation/groups/' + this.state.groupId + '/studySections');
    //             groupRef.set(this.state.group.studySections, (error) => {
    //                 if (error == null) {
    //                     this.setState({});
    //                     this.props.snackbar();
    //                 } else {
    //                     console.log(error);
    //                     this.props.snackbar("Save failed");
    //                 }
    //             });
    //         } else {
    //             console.log(error);
    //             this.props.snackbar("Save failed");
    //         }
    //     });
    // }
}

export default LearningPathView;
import { objectToClassConverter } from "./database_object";

export class SubjectObjectives {
    subjectId: string;
    categories: Map<string, ObjectiveCategory>;

    static fromFirebase(data: any): SubjectObjectives {
        let newSubjectObjectives = new SubjectObjectives();
        if (data == null) {
            return newSubjectObjectives;
        }
        objectToClassConverter(data, newSubjectObjectives, [], ["categories"]);

        let newCategories = new Map<string, ObjectiveCategory>();
        if (data.categories != null) {
            for (let nextCategoryId of Object.keys(data.categories)) {
                newCategories.set(nextCategoryId, ObjectiveCategory.fromFirebase(data.categories[nextCategoryId]));
            }
        }
        newSubjectObjectives.categories = newCategories;

        return newSubjectObjectives;
    }
}

export class ObjectiveCategory {
    title: string;
    objectiveGroups: Map<string, ObjectiveGroup>;

    static fromFirebase(data: any): ObjectiveCategory {
        let newObjectiveCategory = new ObjectiveCategory();
        if (data == null) {
            return newObjectiveCategory;
        }
        objectToClassConverter(data, newObjectiveCategory, [], ["objectiveGroups"]);

        let newCategories = new Map<string, ObjectiveGroup>();
        if (data.objectiveGroups != null) {
            for (let nextGroupId of Object.keys(data.objectiveGroups)) {
                newCategories.set(nextGroupId, ObjectiveGroup.fromFirebase(data.objectiveGroups[nextGroupId]));
            }
        }
        newObjectiveCategory.objectiveGroups = newCategories;

        return newObjectiveCategory;
    }
}

export class ObjectiveGroup {
    objectives: Map<string, Objective>;
    title: string;

    static fromFirebase(data: any): ObjectiveGroup {
        let newObjectiveGroup = new ObjectiveGroup();
        if (data == null) {
            return newObjectiveGroup;
        }
        objectToClassConverter(data, newObjectiveGroup, [], ["objectives"]);

        let newCategories = new Map<string, Objective>();
        if (data.objectives != null) {
            for (let nextObjectiveId of Object.keys(data.objectives)) {
                newCategories.set(nextObjectiveId, Objective.fromFirebase(data.objectives[nextObjectiveId]));
            }
        }
        newObjectiveGroup.objectives = newCategories;

        return newObjectiveGroup;
    }
}

export class Objective {
    topicTags: Map<string, Array<string>>;
    title: string;
    description: string;

    static fromFirebase(data: any): Objective {
        let newObjective = new Objective();
        if (data == null) {
            return newObjective;
        }
        objectToClassConverter(data, newObjective, ["topicTags"]);

        return newObjective;
    }
}

export const ukMathsCurriculum = {
    "subjectId": "maths",
    "categories": {
        "year_1": {
            "title": "Year 1",
            "objectiveGroups": {
                "number_and_place_value": {
                    "title": "Number and Place Value",
                    "objectives": {
                        "count_to_and_across_100_forwa": {
                            "title": "",
                            "description": "Count to and across 100, forwards and backwards, beginning with 0 or 1, or from any given number.",
                            "topicTags": {
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "backwards_0_20",
                                    "backwards_20",
                                    "forwards_0_20",
                                    "forwards_20"
                                ]
                            }
                        },
                        "count_read_and_write_numbers_": {
                            "title": "",
                            "description": "Count, read and write numbers to 100 in numerals; count in multiples of twos, fives and tens.",
                            "topicTags": {
                                "-LvFjJu-lPBEJy_cloTp": [
                                    "reading_one-hundred",
                                    "writing_one-hundred"
                                ],
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "count_2s",
                                    "count_5s",
                                    "count_10s"
                                ]
                            }
                        },
                        "given_a_number_identify_one_m": {
                            "title": "",
                            "description": "Given a number, identify one more and one less.",
                            "topicTags": {
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "1_more",
                                    "1_less"
                                ]
                            }
                        },
                        "identify_and_represent_numbers": {
                            "title": "",
                            "description": "Identify and represent numbers using objects and pictorial representations including the number line, and use the language of: equal to, more than, less than (fewer), most, least.",
                            "topicTags": {
                                "comparing_numbers": [
                                    "equal_to",
                                    "less_than",
                                    "greater_than"
                                ],
                                "-LvKL87IWXM0TB_zAojb": [
                                    "number_lines"
                                ]
                            }
                        },
                        "read_and_write_numbers_from_1_": {
                            "title": "",
                            "description": "Read and write numbers from 1 to 20 in numerals and words.",
                            "topicTags": {
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "forwards_0_20"
                                ],
                                "-LvFjJu-lPBEJy_cloTp": [
                                    "reading_1-5",
                                    "reading_one-ten",
                                    "reading_one-five",
                                    "reading_1-10",
                                    "writing_1-5",
                                    "writing_1-10"
                                ]
                            }
                        }
                    }
                },
                "addition_and_subtraction": {
                    "title": "Addition and Subtraction",
                    "objectives": {
                        "read_write_and_interpret_math": {
                            "title": "",
                            "description": "Read, write and interpret mathematical statements involving addition (+), subtraction (–) and equals (=) signs.",
                            "topicTags": {
                                "-LvFhtqsm6zP084UtLjL": [
                                    "addition",
                                    "subtraction",
                                    "equals"
                                ]
                            }
                        },
                        "represent_and_use_number_bonds": {
                            "title": "",
                            "description": "Represent and use number bonds and related subtraction facts within 20.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "number_bonds_20"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "number_bonds_20"
                                ]
                            }
                        },
                        "add_and_subtract_one_digit_and": {
                            "title": "",
                            "description": "Add and subtract one-digit and two-digit numbers to 20, including zero.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "1_by_1",
                                    "2_by_2",
                                    "numbers_to_20"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "1_by_1",
                                    "2_by_2",
                                    "numbers_to_20"
                                ]
                            }
                        },
                        "solve_one_stop_problems_that_i": {
                            "title": "",
                            "description": "Solve one-stop problems that involve addition and subtraction, using concrete objects and pictorial representations, and missing number problems such as 7 =  -- 9.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "pictorial_representations",
                                    "missing_numbers",
                                    "pictorial_representations"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "pictorial_representations",
                                    "missing_numbers"
                                ]
                            }
                        }
                    }
                },
                "multiplication_and_division": {
                    "title": "Multiplication and Division",
                    "objectives": {
                        "solve_one_step_problems_involv": {
                            "title": "",
                            "description": "Solve one-step problems involving multiplication and division, by calculating the answer using concrete objects, pictorial representations and arrays with the support of the teacher.",
                            "topicTags": {
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "pictorial"
                                ]
                            }
                        }
                    }
                },
                "fractions": {
                    "title": "Fractions",
                    "objectives": {
                        "recognise_find_and_name_a_hal": {
                            "title": "",
                            "description": "Recognise, find and name a half as one of two equal parts of an object, shape or quantity.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "split_shape_half"
                                ]
                            }
                        },
                        "recognise_find_and_name_a_qua": {
                            "title": "",
                            "description": "Recognise, find and name a quarter as one of four equal parts of an object, shape or quantity.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "split_shape_quarters"
                                ]
                            }
                        }
                    }
                },
                "measurement": {
                    "title": "Measurement",
                    "objectives": {
                        "compare_describe_and_solve_pr": {
                            "title": "Compare, describe and solve practical problems for:",
                            "description": "Lengths and heights (for example, long/short, longer/shorter, tall/short, double/half).",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "long_and_short",
                                    "longer_and_shorter",
                                    "tall_and_short",
                                    "double"
                                ]
                            }
                        },
                        "mass_weight_for_example_heav": {
                            "title": "",
                            "description": "Mass/weight (for example, heavy/light, heavier than, lighter than).",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "heavier_and_lighter",
                                    "heavier_and_lighter",
                                    "less_than"
                                ]
                            }
                        },
                        "capacity_and_volume_for_examp": {
                            "title": "",
                            "description": "Capacity and volume (for example, full/empty, more than, less than, half, half full, quarter.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "greater_than",
                                    "less_than",
                                    "half"
                                ]
                            }
                        },
                        "time_for_example_quicker_sl": {
                            "title": "",
                            "description": "Time (for example, quicker, slower, earlier, later).",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "wording"
                                ]
                            }
                        },
                        "measure_and_begin_to_record_th": {
                            "title": "Measure and begin to record the following:",
                            "description": "Lenghts & Heights.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "tall_and_short",
                                    "metric"
                                ]
                            }
                        },
                        "mass_weight_": {
                            "title": "",
                            "description": "Mass/weight.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "mass",
                                    "weight"
                                ]
                            }
                        },
                        "capacity_and_volume_": {
                            "title": "",
                            "description": "Capacity and volume.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "volume",
                                    "capacity"
                                ]
                            }
                        },
                        "time_hours_minutes_seconds_": {
                            "title": "",
                            "description": "Time (hours, minutes, seconds).",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "hours_minutes_seconds"
                                ]
                            }
                        },
                        "recognise_and_know_the_value_o": {
                            "title": "",
                            "description": "Recognise and know the value of different denominations of coins and notes.",
                            "topicTags": {
                                "-LvKKofzSzXmF5mi_cA0": [
                                    "coin_values"
                                ]
                            }
                        },
                        "sequence_events_in_chronologic": {
                            "title": "",
                            "description": "Sequence events in chronological order using language (for example, before and after, first, today, yesterday, tomorrow, morning, afternoon and evening).",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "relative_times",
                                    "morning_afternoon_noon_midnight"
                                ]
                            }
                        },
                        "recognise_and_use_language_rel": {
                            "title": "",
                            "description": "Recognise and use language relating of dates, including days of the week, weeks, months and years.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "days_in_week",
                                    "months_in_year",
                                    "years_and_leap"
                                ]
                            }
                        },
                        "tell_the_time_to_the_hour_and_": {
                            "title": "",
                            "description": "Tell the time to the hour and half past the hour and draw the hands on a clock face to show these times.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "12_hour_clock",
                                    "half_past"
                                ]
                            }
                        }
                    }
                },
                "geometry_properties_of_shapes": {
                    "title": "Geometry - Properties of Shapes",
                    "objectives": {
                        "recognise_and_name_common_2_d_": {
                            "title": "Recognise and name common 2-D and 3-D shapes, including:",
                            "description": "2-D shapes (for example, rectangles (including squares).",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "rectangle",
                                    "square"
                                ]
                            }
                        },
                        "3_d_shapes_for_example_cuboi": {
                            "title": "",
                            "description": "3-D shapes (for example, cuboids (including cubes), pyramids and spheres).",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "cuboid",
                                    "cube",
                                    "pyramid",
                                    "sphere"
                                ]
                            }
                        }
                    }
                },
                "geometry_position_and_direction": {
                    "title": "Geometry - Position and Direction",
                    "objectives": {
                        "describe_position_direction_a": {
                            "title": "",
                            "description": "Describe position, direction and movement, including whole, half, quater and three-quater turns.",
                            "topicTags": {
                                "-LvuReG4XjFSrmz-wf0C": [
                                    "position",
                                    "direction",
                                    "movement",
                                    "whole_turn",
                                    "half_turn",
                                    "three_quarter_turn"
                                ]
                            }
                        }
                    }
                }
            }
        },
        "year_2": {
            "title": "Year 2",
            "objectiveGroups": {
                "number_and_place_value": {
                    "title": "Number and Place Value",
                    "objectives": {
                        "count_in_steps_of_2_3_and_5_": {
                            "title": "",
                            "description": "Count in steps of 2, 3, and 5 from 0, and in tens from any number, forward and backward.",
                            "topicTags": {
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "count_2s",
                                    "count_5s",
                                    "backwards_any",
                                    "count_10s"
                                ]
                            }
                        },
                        "recognise_the_place_value_of_e": {
                            "title": "",
                            "description": "Recognise the place value of each digit in a two-digit number (tens, ones).",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "values_of_digits",
                                    "tens",
                                    "ones"
                                ]
                            }
                        },
                        "identify_represent_and_estima": {
                            "title": "",
                            "description": "Identify, represent and estimate numbers using different representations, including the number line.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "number_lines"
                                ]
                            }
                        },
                        "compare_and_order_numbers_from": {
                            "title": "",
                            "description": "Compare and order numbers from 0 up to 100; use <, > and = signs.",
                            "topicTags": {
                                "-LvFhtqsm6zP084UtLjL": [
                                    "greater_than_less_than",
                                    "equals"
                                ],
                                "-LvKL87IWXM0TB_zAojb": [
                                    "values_of_digits"
                                ]
                            }
                        },
                        "read_and_write_numbers_to_at_l": {
                            "title": "",
                            "description": "Read and write numbers to at least 100 in numerals and in words.",
                            "topicTags": {
                                "-LvFjJu-lPBEJy_cloTp": [
                                    "writing_one-hundred",
                                    "reading_one-hundred"
                                ]
                            }
                        },
                        "use_place_value_and_number_fac": {
                            "title": "",
                            "description": "Use place value and number facts to solve problems.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "number_facts"
                                ]
                            }
                        }
                    }
                },
                "addition_and_subtraction": {
                    "title": "Addition and Subtraction",
                    "objectives": {
                        "solve_problems_with_addition_a": {
                            "title": "Solve problems with addition and subtraction:",
                            "description": "Using concrete objects and pictorial representations, including those involving numbers, quantities and measures.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "pictorial_representations"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "pictorial_representations"
                                ]
                            }
                        },
                        "applying_their_increasing_know": {
                            "title": "",
                            "description": "Applying their increasing knowledge of mental and written methods.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "year_2",
                                    "written_methods"
                                ]
                            }
                        },
                        "recall_and_use_addition_and_su": {
                            "title": "",
                            "description": "Recall and use addition and subtraction facts to 20 fluently, and derive and use related facts up to 100.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "number_bonds_20",
                                    "number_bonds_20_plus"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "number_bonds_20",
                                    "number_bonds_20_plus"
                                ]
                            }
                        },
                        "add_and_subtract_numbers_using": {
                            "title": "Add and subtract numbers using concrete objects, pictorial representations, and mentally, including:",
                            "description": "A two-digit number and ones.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "pictorial_representations",
                                    "mental_addition",
                                    "concrete_objects",
                                    "2_by_1"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "pictorial_representations",
                                    "mental_subtraction",
                                    "concrete_objects",
                                    "2_by_1"
                                ]
                            }
                        },
                        "a_two_digit_number_and_tens_": {
                            "title": "",
                            "description": "A two-digit number and tens.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "mental_addition",
                                    "pictorial_representations",
                                    "concrete_objects",
                                    "2_digit_by_tens"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "mental_subtraction",
                                    "pictorial_representations",
                                    "concrete_objects",
                                    "2_digit_by_tens"
                                ]
                            }
                        },
                        "two_two_digit_numbers_": {
                            "title": "",
                            "description": "Two two-digit numbers.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "2_by_2",
                                    "pictorial_representations",
                                    "mental_addition",
                                    "concrete_objects"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "2_by_2",
                                    "pictorial_representations",
                                    "mental_subtraction",
                                    "concrete_objects"
                                ]
                            }
                        },
                        "adding_three_one_digit_numbers": {
                            "title": "",
                            "description": "Adding three one-digit numbers.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "adding_3_1_digit_numbers",
                                    "pictorial_representations",
                                    "concrete_objects"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "3_1_digit_numbers",
                                    "pictorial_representations",
                                    "concrete_objects"
                                ]
                            }
                        },
                        "show_that_addition_of_two_numb": {
                            "title": "",
                            "description": "Show that addition of two numbers can be done in any order (commutative) and subtraction of one number from another cannot.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "inverse_relationships",
                                    "pictorial_representations",
                                    "concrete_objects"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "inverse_relationships",
                                    "pictorial_representations",
                                    "concrete_objects"
                                ]
                            }
                        },
                        "recognise_and_use_the_inverse_": {
                            "title": "",
                            "description": "Recognise and use the inverse relationship between addition and subtraction and use this to check calculations and solve missing number problems.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "missing_numbers",
                                    "inverse_relationships"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "missing_numbers",
                                    "inverse_relationships"
                                ]
                            }
                        }
                    }
                },
                "multiplication_and_division": {
                    "title": "Multiplication and Division",
                    "objectives": {
                        "recall_and_use_multiplication_": {
                            "title": "",
                            "description": "Recall and use multiplication and division facts for the 2, 5 and 10 multiplication tables, including recognising odd and even numbers.",
                            "topicTags": {
                                "-LvRS0ffMltOgOJfMS63": [
                                    "2_times_tables",
                                    "5_times_tables",
                                    "10_times_tables"
                                ],
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "odd_numbers",
                                    "even_numbers"
                                ]
                            }
                        },
                        "calculate_mathematical_stateme": {
                            "title": "",
                            "description": "Calculate mathematical statements for multiplication and division within the multiplication tables and write them using the multiplication (x), division (÷) and equals (=) signs.",
                            "topicTags": {
                                "-LvFhtqsm6zP084UtLjL": [
                                    "multiplication",
                                    "division",
                                    "equals"
                                ]
                            }
                        },
                        "show_that_multiplication_of_tw": {
                            "title": "",
                            "description": "Show that multiplication of two numbers can be done in any order (commutative) and division of one number by another cannot.",
                            "topicTags": {
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "inverse_relationship"
                                ]
                            }
                        },
                        "solve_problems_involving_multi": {
                            "title": "",
                            "description": "Solve problems involving multiplcation and division, using materials, arrays, repeated addition, mental methods, and multiplication and division facts, including problems in contexts.",
                            "topicTags": {}
                        }
                    }
                },
                "fractions": {
                    "title": "Fractions",
                    "objectives": {
                        "recognise_find_name_and_writ": {
                            "title": "",
                            "description": "Recognise, find, name and write fractions 1/3, 1/4, 2/4 and 3/4 of a length, shape, set of objects or quantity.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "quarter",
                                    "third",
                                    "2_quarters",
                                    "3_quarters"
                                ]
                            }
                        },
                        "write_simple_fractions_for_exa": {
                            "title": "",
                            "description": "Write simple fractions for example, 1/2 of 6 = 3 and recognise the equivalence of 2/4 and 1/2.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "equivalent_fractions"
                                ]
                            }
                        }
                    }
                },
                "measurement": {
                    "title": "Measurement",
                    "objectives": {
                        "choose_and_use_appropriate_sta": {
                            "title": "",
                            "description": "Choose and use appropriate standard units to estimate and measure length/height in any direction (m/cm); mass (kg/g); temperature ('C); capacity (litres/ml) to the nearest appropriate unit, using rulers, scales, thermometers and measuring vessels.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "measuring_names",
                                    "metric",
                                    "grams_kg",
                                    "capacity",
                                    "ml_litre",
                                    "temperature"
                                ]
                            }
                        },
                        "compare_and_order_lengths_mas": {
                            "title": "",
                            "description": "Compare and order lengths, mass, volume/capacity and record the results using >,< and =.",
                            "topicTags": {
                                "comparing_numbers": [
                                    "less_than_greater_than"
                                ],
                                "-LvFhtqsm6zP084UtLjL": [
                                    "equals"
                                ],
                                "IMvUSqA85BDqJgtElIgL": [
                                    "mass",
                                    "volume",
                                    "capacity"
                                ]
                            }
                        },
                        "recognise_and_use_symbols_for_": {
                            "title": "",
                            "description": "Recognise and use symbols for pounds (£) and pence (p); combine amounts to make a particular value.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "pounds_pence"
                                ],
                                "-LvKKofzSzXmF5mi_cA0": [
                                    "money_problems"
                                ]
                            }
                        },
                        "find_different_combinations_of": {
                            "title": "",
                            "description": "Find different combinations of coins that equal the same amounts of money.",
                            "topicTags": {
                                "-LvKKofzSzXmF5mi_cA0": [
                                    "money_problems",
                                    "change"
                                ]
                            }
                        },
                        "solve_simple_problems_in_a_pra": {
                            "title": "",
                            "description": "Solve simple problems in a practical context involving addition and subtraction of money of the same unit, including giving change.",
                            "topicTags": {
                                "-LvKKofzSzXmF5mi_cA0": [
                                    "change",
                                    "coin_values"
                                ]
                            }
                        },
                        "compare_and_sequence_intervals": {
                            "title": "",
                            "description": "Compare and sequence intervals of time.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "hours_minutes_seconds"
                                ]
                            }
                        },
                        "tell_and_write_the_time_to_fiv": {
                            "title": "",
                            "description": "Tell and write the time to five minutes, including quarter past/to the hour and draw the hands on a clock face to show these times.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "5_past",
                                    "quarter_past",
                                    "12_hour_clock"
                                ]
                            }
                        },
                        "know_the_number_of_minutes_in_": {
                            "title": "",
                            "description": "Know the number of minutes in an hour and the number of hours in a day.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "hours_minutes_seconds"
                                ]
                            }
                        }
                    }
                },
                "geometry_properties_of_shapes": {
                    "title": "Geometry - Properties of Shapes",
                    "objectives": {
                        "identify_and_describe_the_prop": {
                            "title": "",
                            "description": "Identify and describe the properties of 2-D shapes, including the number of faces and line symmetry in a vertical line.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "lines_of_symmetry",
                                    "faces"
                                ]
                            }
                        },
                        "intentify_and_describe_the_pro": {
                            "title": "",
                            "description": "Intentify and describe the properties of 3-D shapes, including the number of edges, vertices and faces.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "edges",
                                    "vertices",
                                    "faces"
                                ]
                            }
                        },
                        "compare_and_sort_common_2_d_an": {
                            "title": "",
                            "description": "Compare and sort common 2-D and 3-D shapes and everyday objects.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "comparing_2d_3d"
                                ]
                            }
                        }
                    }
                },
                "geometry_position_and_direction": {
                    "title": "Geometry - Position and Direction",
                    "objectives": {
                        "order_and_arrange_combinations": {
                            "title": "",
                            "description": "Order and arrange combinations of mathematical objects in patterns and sequences.",
                            "topicTags": {}
                        },
                        "use_mathematical_vocabulary_to": {
                            "title": "",
                            "description": "Use mathematical vocabulary to describe position, direction and movement, including movement in a straight line and distinguishing between rotation as a turn and in terms of right angles for quarter, half and three-quarter turns (clockwise and anti-clockwise).",
                            "topicTags": {
                                "geometry": [
                                    "straight_line",
                                    "rotation"
                                ],
                                "-LvuReG4XjFSrmz-wf0C": [
                                    "half_turn",
                                    "three_quarter_turn",
                                    "clockwise_anti"
                                ]
                            }
                        }
                    }
                },
                "statistics": {
                    "title": "Statistics",
                    "objectives": {
                        "interpret_and_construct_simple": {
                            "title": "",
                            "description": "Interpret and construct simple pictograms, tally charts, block diagrams and simple tables.",
                            "topicTags": {
                                "-LvFg4M7JSo-W81cyKkF": [
                                    "pictograms",
                                    "tally_charts",
                                    "block_diagrams"
                                ]
                            }
                        },
                        "ask_and_answer_simple_question": {
                            "title": "",
                            "description": "Ask and answer simple questions by counting the number of objects in each category and sorting the categories by quantity.",
                            "topicTags": {
                                "-LvKLQQwHld852QydlaA": [
                                    "sorting"
                                ]
                            }
                        },
                        "ask_and_answer_questions_about": {
                            "title": "",
                            "description": "Ask and answer questions about totalling and comparing categorical data.",
                            "topicTags": {
                                "-LvFg4M7JSo-W81cyKkF": [
                                    "comparing"
                                ]
                            }
                        }
                    }
                }
            }
        },
        "year_3": {
            "title": "Year 3",
            "objectiveGroups": {
                "number_and_place_value": {
                    "title": "Number and Place Value",
                    "objectives": {
                        "count_from_0_in_multiples_of_4": {
                            "title": "",
                            "description": "Count from 0 in multiples of 4, 8, 50 and 100; find 10 or 100 more or less than a given number.",
                            "topicTags": {
                                "-LvRS0ffMltOgOJfMS63": [
                                    "4_times_tables",
                                    "8_times_tables"
                                ],
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "10_more_less",
                                    "100_more_less",
                                    "multiples_50",
                                    "multiples_100"
                                ]
                            }
                        },
                        "recognise_the_place_value_of_e": {
                            "title": "",
                            "description": "Recognise the place value of each digit in a three-digit number (hundreds, tens, ones).",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "values_of_digits",
                                    "hundreds",
                                    "tens",
                                    "ones"
                                ]
                            }
                        },
                        "compare_and_order_numbers_up_t": {
                            "title": "",
                            "description": "Compare and order numbers up to 1000.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "compare_to_1000"
                                ]
                            }
                        },
                        "identify_represent_and_estima": {
                            "title": "",
                            "description": "Identify, represent and estimate numbers using different representations.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "values_of_digits"
                                ]
                            }
                        },
                        "read_and_write_numbers_up_to_1": {
                            "title": "",
                            "description": "Read and write numbers up to 1000 in numerals and in words.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "read_write_to_1000"
                                ]
                            }
                        },
                        "solve_number_problems_and_prac": {
                            "title": "",
                            "description": "Solve number problems and practical problems involving these ideas.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "values_of_digits"
                                ]
                            }
                        }
                    }
                },
                "addition_subtraction": {
                    "title": "Addition & Subtraction",
                    "objectives": {
                        "add_and_subtract_numbers_menta": {
                            "title": "Add and subtract numbers mentally, including:",
                            "description": "A three-digit number and ones.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "3_by_1",
                                    "mental_addition"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "3_by_1",
                                    "mental_subtraction"
                                ]
                            }
                        },
                        "a_three_digit_number_and_tens_": {
                            "title": "",
                            "description": "A three-digit number and tens.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "3_by_2",
                                    "mental_addition"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "3_by_2",
                                    "mental_subtraction"
                                ]
                            }
                        },
                        "a_three_digit_number_and_hundr": {
                            "title": "",
                            "description": "A three-digit number and hundreds.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "3_by_3",
                                    "mental_addition"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "3_by_3",
                                    "mental_subtraction"
                                ]
                            }
                        },
                        "add_and_subtract_numbers_with_": {
                            "title": "",
                            "description": "Add and subtract numbers with up to three digits, using formal written methods of columnar addition and subtraction.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "columnar_addition"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "columnar_subtraction"
                                ]
                            }
                        },
                        "estimate_the_answer_to_a_calcu": {
                            "title": "",
                            "description": "Estimate the answer to a calculation and use inverse operations to check answers.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "inverse_relationships"
                                ],
                                "estimation": [
                                    "Addition",
                                    "Addition"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "inverse_relationships"
                                ]
                            }
                        },
                        "solve_problems_including_miss": {
                            "title": "",
                            "description": "Solve problems, including missing number problems, using number facts, place value, and more complex addition and subtraction.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "missing_numbers",
                                    "year_3"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "missing_numbers",
                                    "year_3"
                                ]
                            }
                        }
                    }
                },
                "multiplication_and_division": {
                    "title": "Multiplication and Division",
                    "objectives": {
                        "recall_and_use_multiplication_": {
                            "title": "",
                            "description": "Recall and use multiplication and division facts for the 3, 4 and 8 multiplication tables.",
                            "topicTags": {
                                "-LvRS0ffMltOgOJfMS63": [
                                    "3_times_tables",
                                    "4_times_tables",
                                    "8_times_tables"
                                ]
                            }
                        },
                        "write_and_calculate_mathematic": {
                            "title": "",
                            "description": "Write and calculate mathematical statements for multiplication and division using the multiplication tables that they know, including for two-digit numbers times one-digit numbers, using mental and progressing to formal written methods.",
                            "topicTags": {}
                        },
                        "solve_problems_including_miss": {
                            "title": "",
                            "description": "Solve problems, including missing number problems, involving multiplication and division, including positive integer scaling problems and correspondence problems in which n objects are connected to m objects.",
                            "topicTags": {}
                        }
                    }
                },
                "fractions": {
                    "title": "Fractions",
                    "objectives": {
                        "count_up_and_down_in_tenths_r": {
                            "title": "",
                            "description": "Count up and down in tenths; recognise that tenths arise from dividing an object into 10 equal parts and in dividing one-digit numbers or quantities by 10.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "equiv_fractions_10ths_100ths"
                                ]
                            }
                        },
                        "recognise_find_and_write_frac": {
                            "title": "",
                            "description": "Recognise, find and write fractions of a discrete set of objects: unit fractions and non-unit fractions with small denominators.",
                            "topicTags": {}
                        },
                        "recognise_and_show_using_diag": {
                            "title": "",
                            "description": "Recognise and show, using diagrams, equivalent fractions with small denominators.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "diagrams",
                                    "equivalent_fractions"
                                ]
                            }
                        },
                        "add_and_subtract_fractions_wit": {
                            "title": "",
                            "description": "Add and subtract fractions with the same denominator within one whole (for example, 5/7 + 1/7 = 6/7).",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "same_denominator",
                                    "addition",
                                    "subtraction"
                                ]
                            }
                        },
                        "compare_and_order_unit_fractio": {
                            "title": "",
                            "description": "Compare and order unit fractions, and fractions with the same denominators.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "compare_same_denominator"
                                ]
                            }
                        },
                        "solve_problems_that_involve_al": {
                            "title": "",
                            "description": "Solve problems that involve all of the above.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "year_3"
                                ]
                            }
                        }
                    }
                },
                "mesurement": {
                    "title": "Mesurement",
                    "objectives": {
                        "measure_compare_add_and_subt": {
                            "title": "",
                            "description": "Measure, compare, add and subtract: lengths (m/cm/mm); mass (kg/g); volume/capacity (l/ml).",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "mass",
                                    "grams_kg",
                                    "volume",
                                    "capacity",
                                    "ml_litre",
                                    "m_cm_mm"
                                ]
                            }
                        },
                        "measure_the_perimeter_of_simpl": {
                            "title": "",
                            "description": "Measure the perimeter of simple 2-D shapes.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "perimeter"
                                ]
                            }
                        },
                        "add_and_subtract_amounts_of_mo": {
                            "title": "",
                            "description": "Add and subtract amounts of money to give change, using both £ and p in practical contexts.",
                            "topicTags": {
                                "-LvKKofzSzXmF5mi_cA0": [
                                    "change"
                                ]
                            }
                        },
                        "tell_and_write_the_time_from_a": {
                            "title": "",
                            "description": "Tell and write the time from analogue clock, including using Roman numerals from I to XII, using 12-hour and 24-hour clocks.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "12-hour_to_24-hour",
                                    "24-hour_to_12-hour",
                                    "12_hour_clock"
                                ]
                            }
                        },
                        "estimate_and_read_time_with_in": {
                            "title": "",
                            "description": "Estimate and read time with increasing accuracy to the nearest minute; record and compare time in terms of seconds, minutes and hours; using vocabulary such as o'clock, a.m/p.m, morning, afternoon, noon and midnight.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "hours_minutes_seconds",
                                    "am_pm",
                                    "morning_afternoon_noon_midnight"
                                ]
                            }
                        },
                        "know_the_number_of_seconds_in_": {
                            "title": "",
                            "description": "Know the number of seconds in a minute and the number of days in each month, year and leap year.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "seconds_in_minute",
                                    "days_in_month",
                                    "years_and_leap"
                                ]
                            }
                        },
                        "compare_durations_of_events_f": {
                            "title": "",
                            "description": "Compare durations of events (for example to calculate the time taken by particular events or tasks).",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "event_durations"
                                ]
                            }
                        }
                    }
                },
                "geometry_properties_of_shapes": {
                    "title": "Geometry - Properties of Shapes",
                    "objectives": {
                        "draw_2_d_shapes_and_make_3_d_s": {
                            "title": "",
                            "description": "Draw 2-D shapes and make 3-D shapes using modelling materials; recognise 3-D shapes in different orientations and describe them.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "make_3d_shapes"
                                ]
                            }
                        },
                        "recognise_angles_as_a_property": {
                            "title": "",
                            "description": "Recognise angles as a property of shape or a description of a turn.",
                            "topicTags": {
                                "geometry": [
                                    "angles_full_turn"
                                ]
                            }
                        },
                        "identify_right_angles_recogni": {
                            "title": "",
                            "description": "Identify right angles, recognise that two right angles make a half-turn, three make three quarters of a turn and four a complete turn; identify whether angles are greater than or less than a right angle.",
                            "topicTags": {
                                "-LvuReG4XjFSrmz-wf0C": [
                                    "half_turn",
                                    "three_quarter_turn"
                                ],
                                "geometry": [
                                    "right_angles",
                                    "angles_full_turn"
                                ]
                            }
                        },
                        "identify_horizontal_and_vertic": {
                            "title": "",
                            "description": "Identify horizontal and vertical lines and pairs of perpendicular and parallel lines.",
                            "topicTags": {
                                "geometry": [
                                    "parallel_lines"
                                ]
                            }
                        }
                    }
                },
                "statistics": {
                    "title": "Statistics",
                    "objectives": {
                        "interpret_and_present_data_usi": {
                            "title": "",
                            "description": "Interpret and present data using bar charts, pictograms and tables.",
                            "topicTags": {
                                "-LvFg4M7JSo-W81cyKkF": [
                                    "bar_charts",
                                    "pictograms"
                                ]
                            }
                        },
                        "solve_one_step_and_two_step_qu": {
                            "title": "",
                            "description": "Solve one-step and two-step questions (for example, 'How many more' and 'How many fewer?') using information presented in scaled bar charts and pictograms and tables.",
                            "topicTags": {
                                "-LvFg4M7JSo-W81cyKkF": [
                                    "word_problems",
                                    "bar_charts",
                                    "pictograms"
                                ]
                            }
                        }
                    }
                }
            }
        },
        "year_4": {
            "title": "Year 4",
            "objectiveGroups": {
                "number_and_place_value": {
                    "title": "Number and Place Value",
                    "objectives": {
                        "count_in_multiples_of_6_7_9_": {
                            "title": "",
                            "description": "Count in multiples of 6, 7, 9, 25 and 1000.",
                            "topicTags": {
                                "-LvRS0ffMltOgOJfMS63": [
                                    "6_times_tables",
                                    "7_times_tables",
                                    "9_times_tables"
                                ]
                            }
                        },
                        "find_1000_more_or_less_than_a_": {
                            "title": "",
                            "description": "Find 1000 more or less than a given number.",
                            "topicTags": {
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "1000_more_less"
                                ]
                            }
                        },
                        "count_backwards_through_zero_t": {
                            "title": "",
                            "description": "Count backwards through zero to include negative numbers.",
                            "topicTags": {
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "backwards_any",
                                    "negative_numbers"
                                ]
                            }
                        },
                        "recognise_the_place_value_of_e": {
                            "title": "",
                            "description": "Recognise the place value of each digit in a four-digit number (thousands, hundreds, tens, and ones).",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "4_digit_numbers"
                                ]
                            }
                        },
                        "order_and_compare_numbers_beyo": {
                            "title": "",
                            "description": "Order and compare numbers beyond 1000.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "compare_over_1000"
                                ]
                            }
                        },
                        "identify_represent_and_estima": {
                            "title": "",
                            "description": "Identify, represent and estimate numbers using different representations.",
                            "topicTags": {
                                "estimation": [
                                    "pictorial"
                                ]
                            }
                        },
                        "round_any_number_to_the_neares": {
                            "title": "",
                            "description": "Round any number to the nearest 10, 100 or 1000.",
                            "topicTags": {
                                "-LvFi8VSA8kJzp-wOlNQ": [
                                    "ten",
                                    "hundred",
                                    "thousand"
                                ]
                            }
                        },
                        "solve_number_and_practical_pro": {
                            "title": "",
                            "description": "Solve number and practical problems that involve all of the above and with increasingly large positive numbers.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "year_4"
                                ]
                            }
                        },
                        "read_roman_numerals_to_100_i_": {
                            "title": "",
                            "description": "Read Roman Numerals to 100 (I to C) and know that over time, the numeral system changed to include the concept of zero and place value.",
                            "topicTags": {
                                "-LvFh2t_qtHrPlYpomlY": [
                                    "50_plus",
                                    "definition_of_symbols"
                                ]
                            }
                        }
                    }
                },
                "addition_and_subtraction": {
                    "title": "Addition and Subtraction",
                    "objectives": {
                        "add_and_subtract_numbers_with_": {
                            "title": "",
                            "description": "Add and subtract numbers with up to 4 digits using the formal written methods of columnar additiong and subtraction where appropriate.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "4_by_4"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "4_by_4"
                                ]
                            }
                        },
                        "estimate_and_use_inverse_opera": {
                            "title": "",
                            "description": "Estimate and use inverse operations to check answers to a calculation.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "inverse_relationships"
                                ],
                                "estimation": [
                                    "Addition",
                                    "subtraction"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "inverse_relationships"
                                ]
                            }
                        },
                        "solve_addition_and_subtraction": {
                            "title": "",
                            "description": "Solve addition and subtraction two-step problems in contexts, deciding which operations and methods to use and why.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "two_step_problems"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "two_step_problems"
                                ]
                            }
                        }
                    }
                },
                "multiplication_and_division": {
                    "title": "Multiplication and Division",
                    "objectives": {
                        "recall_multiplication_and_divi": {
                            "title": "",
                            "description": "Recall multiplication and division facts for multiplication tables up to 12 x 12.",
                            "topicTags": {
                                "-LvRS0ffMltOgOJfMS63": [
                                    "2_times_tables",
                                    "3_times_tables",
                                    "4_times_tables",
                                    "5_times_tables",
                                    "6_times_tables",
                                    "7_times_tables",
                                    "8_times_tables",
                                    "9_times_tables",
                                    "10_times_tables",
                                    "11_times_tables",
                                    "12_times_tables"
                                ]
                            }
                        },
                        "use_place_value_known_and_der": {
                            "title": "",
                            "description": "Use place value, known and derived facts to multiply and divide mentally, including: multiplying by 0 and 1; dividing by 1; multiplying together three numbers.",
                            "topicTags": {}
                        },
                        "recognise_and_use_factor_pairs": {
                            "title": "",
                            "description": "Recognise and use factor pairs and commutativity in mental calculations.",
                            "topicTags": {
                                "factors": [
                                    "common_factors"
                                ]
                            }
                        },
                        "multiply_two_digit_and_three_d": {
                            "title": "",
                            "description": "Multiply two-digit and three-digit numbers by a one-digit number using formal written layout.",
                            "topicTags": {
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "2_by_3_digit",
                                    "written_methods"
                                ],
                                "-LrOkvew79wUpkjYPl3D": [
                                    "2_by_3",
                                    "written_methods"
                                ]
                            }
                        },
                        "solve_problems_involving_multi": {
                            "title": "",
                            "description": "Solve problems involving multiplying and adding, including using the distributive law to multiple two digit numbers by one digit, integer scaling problems and harder correspondence problems such as n objects are connect to m objects.",
                            "topicTags": {
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "distributive_law"
                                ],
                                "-LrOkvew79wUpkjYPl3D": [
                                    "distributive_law"
                                ]
                            }
                        }
                    }
                },
                "fractions": {
                    "title": "Fractions",
                    "objectives": {
                        "recognise_and_show_using_diag": {
                            "title": "",
                            "description": "Recognise and show, using diagrams, families of common equivalent fractions.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "equivalent_fractions"
                                ]
                            }
                        },
                        "count_up_and_down_in_hundredth": {
                            "title": "",
                            "description": "Count up and down in hundredths; recognise that hundreths arise when dividng an object by one hundred and dividing tenths by ten.",
                            "topicTags": {}
                        },
                        "solve_problems_involving_incre": {
                            "title": "",
                            "description": "Solve problems involving increasingly harder fractions to calculate quantities, and fractions to divide quantities, including non-unit fractions where the answer is a whole number.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "division"
                                ]
                            }
                        },
                        "add_and_subtract_fractions_wit": {
                            "title": "",
                            "description": "Add and subtract fractions with the same denominator.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "addition",
                                    "subtraction",
                                    "numerator_denominator"
                                ]
                            }
                        },
                        "recognise_and_write_decimal_eq": {
                            "title": "",
                            "description": "Recognise and write decimal equivalents of any number of tenths of hundreths.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "decimal_convertion",
                                    "equivalent_fractions",
                                    "decimal_convertion"
                                ]
                            }
                        },
                        "find_the_effect_of_dividing_a_": {
                            "title": "",
                            "description": "Find the effect of dividing a one- or two-digit number by 10 and 100, identifying the value of the digits in the answer as ones, tenths and hundreths.",
                            "topicTags": {}
                        },
                        "round_decimals_with_one_decima": {
                            "title": "",
                            "description": "Round decimals with one decimal place to the nearest whole number.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "zero_point_one"
                                ],
                                "-LvFi8VSA8kJzp-wOlNQ": [
                                    "whole_numbers"
                                ]
                            }
                        },
                        "compare_numbers_with_the_same_": {
                            "title": "",
                            "description": "Compare numbers with the same number of decimal places up to two decimal places.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "zero_point_zero_one",
                                    "zero_point_one"
                                ]
                            }
                        },
                        "solve_simple_mesure_and_money_": {
                            "title": "",
                            "description": "Solve simple mesure and money problems involving fractions and decimals to two decimal places.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "money",
                                    "decimal_convertion"
                                ],
                                "-LvKL87IWXM0TB_zAojb": [
                                    "zero_point_zero_one"
                                ]
                            }
                        }
                    }
                },
                "measurement": {
                    "title": "Measurement",
                    "objectives": {
                        "convert_between_different_unit": {
                            "title": "",
                            "description": "Convert between different units of measure (for example, kilometre to metre; hour to minute).",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "metric"
                                ],
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "hours_minutes_seconds"
                                ]
                            }
                        },
                        "measure_and_calculate_the_peri": {
                            "title": "",
                            "description": "Measure and calculate the perimeter of a rectilinear figure (including squares) in centimetres and metres.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "perimeter"
                                ]
                            }
                        },
                        "find_the_area_of_rectilinear_s": {
                            "title": "",
                            "description": "Find the area of rectilinear shapes by counting sqaures.",
                            "topicTags": {
                                "-LvFhbMIRN1-haNWb-cM": [
                                    "rectilinear_shapes"
                                ]
                            }
                        },
                        "estimate_compare_and_calculat": {
                            "title": "",
                            "description": "Estimate, compare and calculate different measures, including money in pounds and pence.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "pounds_pence"
                                ]
                            }
                        },
                        "read_write_and_convert_time_b": {
                            "title": "",
                            "description": "Read, write and convert time between analogue and digital 12- and 24-hour clocks.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "12-hour_to_24-hour",
                                    "24-hour_to_12-hour"
                                ]
                            }
                        },
                        "solve_problems_involving_conve": {
                            "title": "",
                            "description": "Solve problems involving converting from hours to minutes; minutes to seconds; years to months; weeks to days.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "hours_minutes_seconds",
                                    "months_in_year",
                                    "days_in_week",
                                    "weeks_in_year"
                                ]
                            }
                        }
                    }
                },
                "geometry_properties_of_shapes": {
                    "title": "Geometry - Properties of Shapes",
                    "objectives": {
                        "compare_and_classify_geometric": {
                            "title": "",
                            "description": "Compare and classify geometric shapes, including quadrilaterals and triangles, based on their properties and sizes.",
                            "topicTags": {
                                "geometry": [
                                    "angles_in_quadrilateral",
                                    "angles_in_triangle"
                                ]
                            }
                        },
                        "identify_acute_and_obtuse_angl": {
                            "title": "",
                            "description": "Identify acute and obtuse angles and compare and order angles up to two right angles by size.",
                            "topicTags": {
                                "geometry": [
                                    "acute_and_obtuse_angles",
                                    "right_angles"
                                ]
                            }
                        },
                        "identify_lines_of_symmetry_in_": {
                            "title": "",
                            "description": "Identify lines of symmetry in 2-D shapes presented in different orientations.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "lines_of_symmetry"
                                ]
                            }
                        },
                        "complete_a_simple_symmetric_fi": {
                            "title": "",
                            "description": "Complete a simple symmetric figure with respect to a specific line of symmetry.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "lines_of_symmetry"
                                ]
                            }
                        }
                    }
                },
                "geometry_position_and_direction": {
                    "title": "Geometry - Position and Direction",
                    "objectives": {
                        "describe_positions_on_a_2_d_gr": {
                            "title": "",
                            "description": "Describe positions on a 2-D grid as coordinates in the first quadrant.",
                            "topicTags": {
                                "-LvFit1qTPz10UZLsa4o": [
                                    "four_quadrant_grid",
                                    "shapes"
                                ]
                            }
                        },
                        "describe_movements_between_pos": {
                            "title": "",
                            "description": "Describe movements between positions as translations of a given unit to the left/right and up/down.",
                            "topicTags": {
                                "-LvFit1qTPz10UZLsa4o": [
                                    "shapes"
                                ],
                                "geometry": [
                                    "translation"
                                ]
                            }
                        },
                        "plot_specified_points_and_draw": {
                            "title": "",
                            "description": "Plot specified points and draw sides to complete a given ploygon.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "polygons"
                                ],
                                "-LvFit1qTPz10UZLsa4o": [
                                    "four_quadrant_grid"
                                ]
                            }
                        }
                    }
                },
                "statistics": {
                    "title": "Statistics",
                    "objectives": {
                        "interpret_and_present_discrete": {
                            "title": "",
                            "description": "Interpret and present discrete and continuous data using appropriate graphical methods, including bar charts and time graphs.",
                            "topicTags": {
                                "-LvFg4M7JSo-W81cyKkF": [
                                    "bar_charts",
                                    "time_graphs"
                                ]
                            }
                        },
                        "solve_comparison_sum_and_diff": {
                            "title": "",
                            "description": "Solve comparison, sum and difference problems using information presented in bar charts, pictorams, tables and other graphs.",
                            "topicTags": {
                                "-LvFg4M7JSo-W81cyKkF": [
                                    "bar_charts",
                                    "pictograms",
                                    "line_graphs",
                                    "pie_charts",
                                    "comparing_graphs"
                                ]
                            }
                        }
                    }
                }
            }
        },
        "year_5": {
            "title": "Year 5",
            "objectiveGroups": {
                "number_and_place_value": {
                    "title": "Number and Place Value",
                    "objectives": {
                        "read_write_order_and_compare": {
                            "title": "",
                            "description": "Read, write, order and compare numbers to at least 1,000,000 and determine the value of each digit.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "up_to_1m",
                                    "values_of_digits"
                                ]
                            }
                        },
                        "count_forwards_and_backwards_i": {
                            "title": "",
                            "description": "Count forwards and backwards in steps of powers of 10 for any given number up to 1,000,000.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "multipliying_dividing_powers_10",
                                    "up_to_1m"
                                ]
                            }
                        },
                        "interpret_negative_numbers_in_": {
                            "title": "",
                            "description": "Interpret negative numbers in context, count forwards and backwards with positive and negative whole numbers, including through zero.",
                            "topicTags": {
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "negative_numbers",
                                    "backwards_any"
                                ]
                            }
                        },
                        "round_any_number_up_to_1_000_0": {
                            "title": "",
                            "description": "Round any number up to 1,000,000 to the nearest 10, 100, 1000, 10k and 100k.",
                            "topicTags": {
                                "-LvFi8VSA8kJzp-wOlNQ": [
                                    "ten",
                                    "hundred",
                                    "thousand",
                                    "ten_thousand",
                                    "hundred_thousand"
                                ]
                            }
                        },
                        "solve_number_problems_and_prac": {
                            "title": "",
                            "description": "Solve number problems and practical problems that involve all of the above.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "values_of_digits"
                                ]
                            }
                        },
                        "read_roman_numerals_to_1000_m": {
                            "title": "",
                            "description": "Read Roman Numerals to 1000 (M) and recognise years written in Roman numerals.",
                            "topicTags": {
                                "-LvFh2t_qtHrPlYpomlY": [
                                    "year_groups",
                                    "50_plus"
                                ]
                            }
                        }
                    }
                },
                "addition_and_subtraction": {
                    "title": "Addition and Subtraction",
                    "objectives": {
                        "add_and_subtract_whole_numbers": {
                            "title": "",
                            "description": "Add and subtract whole numbers with more than 4 digits, including using formal written methods (columnar addition and subtraction).",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "columnar_addition",
                                    "year_5"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "columnar_subtraction",
                                    "year_5"
                                ]
                            }
                        },
                        "add_and_subtract_numbers_menta": {
                            "title": "",
                            "description": "Add and subtract numbers mentally with increasingly large numbers.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "mental_addition",
                                    "year_5"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "mental_subtraction",
                                    "year_5"
                                ]
                            }
                        },
                        "use_rounding_to_check_answers_": {
                            "title": "",
                            "description": "Use rounding to check answers to calculations and determine, in the context of a problem, levels of accuracy.",
                            "topicTags": {
                                "-LvFi8VSA8kJzp-wOlNQ": [
                                    "rounding_to_check"
                                ]
                            }
                        },
                        "solve_addition_and_subtraction": {
                            "title": "",
                            "description": "Solve addition and subtraction multi-step problems in contexts, deciding which operations and methods to use and why.",
                            "topicTags": {}
                        }
                    }
                },
                "multiplication_and_division": {
                    "title": "Multiplication and Division",
                    "objectives": {
                        "identify_multiples_and_factors": {
                            "title": "",
                            "description": "Identify multiples and factors, including finding all factor pairs of a number, and common factors of two numbers.",
                            "topicTags": {
                                "factors": [
                                    "common_factors"
                                ]
                            }
                        },
                        "know_and_use_the_vocabulary_of": {
                            "title": "",
                            "description": "Know and use the vocabulary of prime numbers, prime factors and composite (non-prime) numbers.",
                            "topicTags": {
                                "-LvFgQLDbCdvlwriUI1H": [
                                    "primes"
                                ]
                            }
                        },
                        "establish_whether_a_number_up_": {
                            "title": "",
                            "description": "Establish whether a number up to 100 is prime and recall prime numbers up to 19.",
                            "topicTags": {
                                "-LvFgQLDbCdvlwriUI1H": [
                                    "primes",
                                    "primes_to_19"
                                ]
                            }
                        },
                        "multiply_numbers_up_to_4_digit": {
                            "title": "",
                            "description": "Multiply numbers up to 4 digits by a one- or two-digit number using a formal written method, including long multiplication for two-digit numbers.",
                            "topicTags": {
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "4-digit_number_with_a_2_digit",
                                    "written_methods"
                                ]
                            }
                        },
                        "multiply_and_divide_numbers_me": {
                            "title": "",
                            "description": "Multiply and divide numbers mentally drawing upon known facts.",
                            "topicTags": {
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "mental"
                                ],
                                "-LrOkvew79wUpkjYPl3D": [
                                    "mental"
                                ]
                            }
                        },
                        "divide_numbers_up_to_4_digits_": {
                            "title": "",
                            "description": "Divide numbers up to 4 digits by a one-digit number using the formal written method of short division and interpret remainders appropriately for the context.",
                            "topicTags": {
                                "-LrOkvew79wUpkjYPl3D": [
                                    "short"
                                ]
                            }
                        },
                        "multiply_and_divide_whole_numb": {
                            "title": "",
                            "description": "Multiply and divide whole numbers and those involving decimals by 10, 100 and 1000.",
                            "topicTags": {
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "decimals",
                                    "10_100_1000"
                                ],
                                "-LrOkvew79wUpkjYPl3D": [
                                    "decimals",
                                    "10_100_1000"
                                ]
                            }
                        }
                    }
                },
                "fractions_including_decimals_and_percentages_": {
                    "title": "Fractions (Including decimals and percentages)",
                    "objectives": {
                        "compare_and_order_fractions_wh": {
                            "title": "",
                            "description": "Compare and order fractions whose denominators are all multiples of the same number.",
                            "topicTags": {}
                        },
                        "identify_name_and_write_equiv": {
                            "title": "",
                            "description": "Identify, name and write equivalent fractions of a given fraction, represented visually, including tenths and hundredths.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "equivalent_fractions",
                                    "equiv_fractions_10ths_100ths"
                                ]
                            }
                        },
                        "recognise_mixed_numbers_and_im": {
                            "title": "",
                            "description": "Recognise mixed numbers and improper fractions and convert from one form to the other and write mathematical statements > 1 as a mixed number (for example, 2/5 + 4/5 = 6/5 = 1 1/5).",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "mixed_operations",
                                    "improper_proper"
                                ]
                            }
                        },
                        "add_and_subtract_fractions_wit": {
                            "title": "",
                            "description": "Add and subtract fractions with the same denominator and denominators that are multiples of the same number.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "addition",
                                    "subtraction",
                                    "numerator_denominator"
                                ]
                            }
                        },
                        "multiply_proper_fractions_and_": {
                            "title": "",
                            "description": "Multiply proper fractions and mixed numbers by whole numbers, supported by materials and diagrams.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "mixed_operations",
                                    "multiplying",
                                    "diagrams"
                                ]
                            }
                        },
                        "read_and_write_decimal_numbers": {
                            "title": "",
                            "description": "Read and write decimal numbers as fractions (for example, 0.71 = 71/100).",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "decimal_convertion"
                                ]
                            }
                        },
                        "recognise_and_use_thousandths_": {
                            "title": "",
                            "description": "Recognise and use thousandths and relate them to tenths, hundredths and decimal equivalents.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "equiv_fractions_10ths_100ths",
                                    "decimal_equiv"
                                ]
                            }
                        },
                        "round_decimals_with_two_decima": {
                            "title": "",
                            "description": "Round decimals with two decimal places to the nearest whole number and to one decimal place.",
                            "topicTags": {
                                "-LvFi8VSA8kJzp-wOlNQ": [
                                    "whole_numbers"
                                ]
                            }
                        },
                        "read_write_order_and_compare": {
                            "title": "",
                            "description": "Read, write, order and compare numbers with up to three decimal places.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "zero_point_zero_zero_one"
                                ]
                            }
                        },
                        "solve_problems_involving_numbe": {
                            "title": "",
                            "description": "Solve problems involving numbers up to three decimal places.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "zero_point_zero_zero_one"
                                ]
                            }
                        },
                        "recognise_the_percent_symbol_": {
                            "title": "",
                            "description": "Recognise the percent symbol (%) and understand that percent relates to 'number of parts per hundred', and write percentages as a fraction with denominator 100, and as a decimal.",
                            "topicTags": {
                                "-LvFhtqsm6zP084UtLjL": [
                                    "percentage"
                                ],
                                "-LvHGpKGajHpiuCy6Z9f": [
                                    "converting_to_from_fractions",
                                    "converting_to_from_decimals"
                                ]
                            }
                        },
                        "solve_problems_which_require_k": {
                            "title": "",
                            "description": "Solve problems which require knowing percentage and decimal equivalents of 1/2, 1/4, 1/5, 2/5, 4/5 and those fractions with a denominator of a multiple of 10 or 25.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "denom_10_25",
                                    "decimal_equiv_half_quarter_etc"
                                ]
                            }
                        }
                    }
                },
                "measurement": {
                    "title": "Measurement",
                    "objectives": {
                        "convert_between_different_unit": {
                            "title": "",
                            "description": "Convert between different units of metric meausre (for example, kilometre and metre; centimetre and metre; centimetre and millimetre; gram and kilogram; litre and millilitre).",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "converting_metrics"
                                ]
                            }
                        },
                        "understand_and_use_approximate": {
                            "title": "",
                            "description": "Understand and use approximate equivalences between metric units and common imperial units such as inches, pounds and pints.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "imperial_units"
                                ]
                            }
                        },
                        "measure_and_calculate_the_peri": {
                            "title": "",
                            "description": "Measure and calculate the perimeter of composite rectilinear shapes in centimetres and metres.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "perimeter",
                                    "rectilinear_shapes",
                                    "m_cm_mm"
                                ]
                            }
                        },
                        "calculate_and_compare_the_area": {
                            "title": "",
                            "description": "Calculate and compare the area of rectangles (including squares), and including using standard units, square centimetres (cm2) and square metres (m2) and estimate the area of irregular shapes.",
                            "topicTags": {
                                "-LvFhbMIRN1-haNWb-cM": [
                                    "rectangle",
                                    "cm2_m2",
                                    "irregular_shapes"
                                ]
                            }
                        },
                        "estimate_volume_for_example_": {
                            "title": "",
                            "description": "Estimate Volume (for example, using 1 cm3 blocks to build cuboids (including cubes)] and capacity [for example, using water].",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "volume",
                                    "capacity"
                                ]
                            }
                        },
                        "solve_problems_involving_conve": {
                            "title": "",
                            "description": "Solve problems involving converting between units of time.",
                            "topicTags": {
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "converting_time"
                                ]
                            }
                        },
                        "use_all_four_operations_to_sol": {
                            "title": "",
                            "description": "Use all four operations to solve problems involving measure (for example, length, mass, volume, money) using decimal notation, including scaling.",
                            "topicTags": {}
                        }
                    }
                },
                "geometry_properties_of_shapes": {
                    "title": "Geometry - Properties of Shapes",
                    "objectives": {
                        "identify_3_d_shapes_including": {
                            "title": "",
                            "description": "Identify 3-D shapes, including cubes and other cuboids, from 2-D represntations.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "cuboid",
                                    "2d_shapes_on_3d"
                                ]
                            }
                        },
                        "know_angles_are_measured_in_de": {
                            "title": "",
                            "description": "Know angles are measured in degrees: estimate and compare acute, obtuse and reflex angles.",
                            "topicTags": {
                                "geometry": [
                                    "acute_and_obtuse_angles",
                                    "reflex"
                                ]
                            }
                        },
                        "other_multiples_of_90_": {
                            "title": "",
                            "description": "Other multiples of 90°.",
                            "topicTags": {
                                "geometry": [
                                    "right_angles"
                                ]
                            }
                        },
                        "use_the_properties_of_rectangl": {
                            "title": "",
                            "description": "Use the properties of rectangles to deduce related facts and find missing lengths and angles.",
                            "topicTags": {
                                "geometry": [
                                    "missing_lengths_angles"
                                ]
                            }
                        },
                        "distinguish_between_regular_an": {
                            "title": "",
                            "description": "Distinguish between regular and irregular polygons based on reasoning about equal sides and angles.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "regular_irregular_polygons"
                                ]
                            }
                        }
                    }
                },
                "geometry_position_and_direction": {
                    "title": "Geometry - Position and Direction",
                    "objectives": {
                        "identify_describe_and_represe": {
                            "title": "",
                            "description": "Identify, describe and represent the position of a shape following a reflection or translation, using the appropriate language, and know that the shape has not changed.",
                            "topicTags": {
                                "geometry": [
                                    "reflection",
                                    "translation"
                                ]
                            }
                        }
                    }
                },
                "statistics": {
                    "title": "Statistics",
                    "objectives": {
                        "solve_comparison_sum_and_diff": {
                            "title": "",
                            "description": "Solve comparison, sum and difference problems using information presented in a line graph.",
                            "topicTags": {
                                "-LvFg4M7JSo-W81cyKkF": [
                                    "line_graphs",
                                    "comparing"
                                ]
                            }
                        },
                        "complete_read_and_interpret_i": {
                            "title": "",
                            "description": "Complete, read and interpret information in tables, including timetables.",
                            "topicTags": {
                                "-LvFg4M7JSo-W81cyKkF": [
                                    "bar_charts"
                                ]
                            }
                        }
                    }
                }
            }
        },
        "year_6": {
            "title": "Year 6",
            "objectiveGroups": {
                "number_and_place_value": {
                    "title": "Number and Place Value",
                    "objectives": {
                        "read_write_order_and_compare": {
                            "title": "",
                            "description": "Read, write, order and compare numbers up to 10,000,000 and determine the value of each digit.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "values_of_digits",
                                    "up_to_1m",
                                    "between_1m_and_10m"
                                ]
                            }
                        },
                        "round_any_whole_number_to_a_re": {
                            "title": "",
                            "description": "Round any whole number to a required degree of accuracy.",
                            "topicTags": {
                                "-LvFi8VSA8kJzp-wOlNQ": [
                                    "whole_numbers"
                                ]
                            }
                        },
                        "use_negative_numbers_in_contex": {
                            "title": "",
                            "description": "Use Negative numbers in context, and calculate intervals across zero.",
                            "topicTags": {
                                "-LvQfY2DVvQeYskFYWYr": [
                                    "negative_numbers",
                                    "backwards_any"
                                ]
                            }
                        },
                        "solve_number_and_practical_pro": {
                            "title": "",
                            "description": "Solve number and practical problems that involve all of the above.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "year_6"
                                ]
                            }
                        }
                    }
                },
                "number_addition_subtraction_multiplication_and_division": {
                    "title": "Number - Addition, Subtraction, Multiplication and Division",
                    "objectives": {
                        "multiply_multi_digit_numbers_u": {
                            "title": "",
                            "description": "Multiply multi-digit numbers up to 4 digits by a two-digit whole number using the formal written method of long multiplication.",
                            "topicTags": {
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "4-digit_number_with_a_2_digit",
                                    "long"
                                ]
                            }
                        },
                        "divide_numbers_up_to_4_digits_": {
                            "title": "",
                            "description": "Divide numbers up to 4 digits by a two-digit whole number using the formal written method of long division, and interpret remainders as whole number remainders, fractions, or by rounding, as apprpriate for the context.",
                            "topicTags": {
                                "-LrOkvew79wUpkjYPl3D": [
                                    "4_by_2_digit",
                                    "long_division",
                                    "written_methods",
                                    "remainders_fractions",
                                    "4_by_2_digit"
                                ]
                            }
                        },
                        "perform_mental_calculations_i": {
                            "title": "",
                            "description": "Perform mental calculations, including with mixed operations and large numbers.",
                            "topicTags": {
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "year_6"
                                ]
                            }
                        },
                        "identify_common_factors_commo": {
                            "title": "",
                            "description": "Identify common factors, common multiples and prime numbers.",
                            "topicTags": {
                                "factors": [
                                    "common_factors"
                                ],
                                "-LvFgQLDbCdvlwriUI1H": [
                                    "primes"
                                ]
                            }
                        },
                        "use_their_knowledge_of_the_ord": {
                            "title": "",
                            "description": "Use their knowledge of the order of operations to carry out calculations involving the four operations.",
                            "topicTags": {
                                "order_of_operations": [
                                    "bidmas"
                                ]
                            }
                        },
                        "solve_addition_and_subtraction": {
                            "title": "",
                            "description": "Solve addition and subtraction multi-step problems in contexts, deciding which operations and methods to use and why.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "year_6"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "year_6"
                                ]
                            }
                        },
                        "solve_problems_involving_addit": {
                            "title": "",
                            "description": "Solve problems involving addition, subtraction, multiplication and division.",
                            "topicTags": {
                                "-LrOBPu5ey6M6HBeRtkL": [
                                    "year_6"
                                ],
                                "-LrOkg_pCtwKWtPkIxZh": [
                                    "year_6"
                                ],
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "year_6"
                                ],
                                "-LrOkvew79wUpkjYPl3D": [
                                    "year_6"
                                ]
                            }
                        },
                        "use_estimation_to_check_answer": {
                            "title": "",
                            "description": "Use estimation to check answers to calculations and determine, in the context of a problem, an appropriate degree of accuracy.",
                            "topicTags": {
                                "estimation": [
                                    "Addition",
                                    "subtraction",
                                    "Multiplication",
                                    "division"
                                ]
                            }
                        }
                    }
                },
                "number_fractions_including_decimals_and_percentages_": {
                    "title": "Number - Fractions (Including decimals and percentages)",
                    "objectives": {
                        "use_common_factors_to_simplify": {
                            "title": "",
                            "description": "Use common factors to simplify fractions; use common multiples to express fractions in the same denomination.",
                            "topicTags": {
                                "factors": [
                                    "common_factors"
                                ],
                                "-LvFglUzuHBpIsf7lcui": [
                                    "numerator_denominator",
                                    "multiplying"
                                ]
                            }
                        },
                        "compare_and_order_fractions_i": {
                            "title": "",
                            "description": "Compare and order fractions, including fractions > 1.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "comparing"
                                ]
                            }
                        },
                        "add_and_subtract_fractions_wit": {
                            "title": "",
                            "description": "Add and subtract fractions with different denominators and mixed numbers, using the concept of equivalent fractions.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "addition",
                                    "subtraction",
                                    "mixed_operations",
                                    "equivalent_fractions"
                                ]
                            }
                        },
                        "multiply_simple_pairs_of_prope": {
                            "title": "",
                            "description": "Multiply simple pairs of proper fractions, writing the answer in its simplest form (for example, 1/4 x 1/2 = 1/8).",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "improper_proper"
                                ]
                            }
                        },
                        "divide_proper_fractions_by_who": {
                            "title": "",
                            "description": "Divide proper fractions by whole numbers (for example, 1/3 ÷ 2 = 1/6).",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "division"
                                ]
                            }
                        },
                        "associate_a_fraction_with_divi": {
                            "title": "",
                            "description": "Associate a fraction with division and calculate decimal fraction equivalents (for example, 0.375) for a simple fraction (for example, 3/8).",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "division",
                                    "equivalent_fractions",
                                    "decimal_convertion"
                                ]
                            }
                        },
                        "identify_the_value_of_each_dig": {
                            "title": "",
                            "description": "Identify the value of each digit in numbers given to three decimal places and multiply and divide numbers by 10, 100 and 1000 giving answers up to three decimal places.",
                            "topicTags": {
                                "-LvKL87IWXM0TB_zAojb": [
                                    "zero_point_zero_zero_one",
                                    "multipliying_dividing_powers_10",
                                    "mult_div_power_100",
                                    "mult_div_powers_1000"
                                ]
                            }
                        },
                        "multiply_one_digit_numbers_wit": {
                            "title": "",
                            "description": "Multiply one-digit numbers with up to two decimal places by whole numbers.",
                            "topicTags": {
                                "-LrOknoT7l2uGzFrmGtT": [
                                    "2_decimal_places"
                                ]
                            }
                        },
                        "use_written_division_methods_i": {
                            "title": "",
                            "description": "Use written division methods in cases where the answer has up to two decimal places.",
                            "topicTags": {
                                "-LrOkvew79wUpkjYPl3D": [
                                    "2_decimal",
                                    "written_methods"
                                ]
                            }
                        },
                        "solve_problems_which_require_a": {
                            "title": "",
                            "description": "Solve problems which require answers to be rounded to specified degrees of accuracy.",
                            "topicTags": {
                                "-LvFi8VSA8kJzp-wOlNQ": [
                                    "rounding_to_check"
                                ]
                            }
                        },
                        "recall_and_use_equivalences_be": {
                            "title": "",
                            "description": "Recall and use equivalences between simple fractions, decimals and percentages, including in different contexts.",
                            "topicTags": {
                                "-LvFglUzuHBpIsf7lcui": [
                                    "equivalent_fractions",
                                    "decimal_convertion",
                                    "percentage_conversion"
                                ]
                            }
                        }
                    }
                },
                "ratio_and_proportion": {
                    "title": "Ratio and Proportion",
                    "objectives": {
                        "solve_problems_involving_the_r": {
                            "title": "",
                            "description": "Solve problems involving the relative sizes of two quantities where missing values can be found by using integer multiplication and division facts.",
                            "topicTags": {}
                        },
                        "solve_problems_involving_the_c": {
                            "title": "",
                            "description": "Solve problems involving the calculation of percentages (for example, of measures, and such as 15% of 360) and the use of percentages for comparison.",
                            "topicTags": {
                                "-LvHGpKGajHpiuCy6Z9f": [
                                    "percentages_of_amounts"
                                ]
                            }
                        },
                        "solve_problems_involving_simil": {
                            "title": "",
                            "description": "Solve problems involving similar shapes where the scale factor is known or can be found.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "scale_factors",
                                    "similar"
                                ]
                            }
                        },
                        "solve_problems_involving_unequ": {
                            "title": "",
                            "description": "Solve problems involving unequal sharing and grouping using knowledge of fractions and multiples.",
                            "topicTags": {}
                        }
                    }
                },
                "algebra": {
                    "title": "Algebra",
                    "objectives": {
                        "use_simple_formulae_": {
                            "title": "",
                            "description": "Use simple formulae.",
                            "topicTags": {
                                "-LvHH3VvuvyEnyRgLtpO": [
                                    "formulas"
                                ]
                            }
                        },
                        "generate_and_describe_linaer_n": {
                            "title": "",
                            "description": "Generate and describe linaer number sequences.",
                            "topicTags": {
                                "-LvKLQQwHld852QydlaA": [
                                    "linear"
                                ]
                            }
                        },
                        "express_missing_number_problem": {
                            "title": "",
                            "description": "Express missing number problems algebraically.",
                            "topicTags": {
                                "-LvHH3VvuvyEnyRgLtpO": [
                                    "missing_number_problems"
                                ]
                            }
                        },
                        "find_pairs_of_numbers_that_sat": {
                            "title": "",
                            "description": "Find pairs of numbers that satisfy an equation with two unknowns.",
                            "topicTags": {
                                "-LvHH3VvuvyEnyRgLtpO": [
                                    "unknown_variables"
                                ]
                            }
                        },
                        "enumerate_possibilities_of_com": {
                            "title": "",
                            "description": "Enumerate possibilities of combinations of two variables.",
                            "topicTags": {
                                "-LvHH3VvuvyEnyRgLtpO": [
                                    "two_variables"
                                ]
                            }
                        }
                    }
                },
                "measurement": {
                    "title": "Measurement",
                    "objectives": {
                        "solve_problems_involving_the_c": {
                            "title": "",
                            "description": "Solve problems involving the calculation and conversaion of units of measure, using decimal notation up to three decimal places where appropriate.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "metric"
                                ],
                                "-LvKL87IWXM0TB_zAojb": [
                                    "zero_point_zero_zero_one"
                                ]
                            }
                        },
                        "use_read_write_and_convert_b": {
                            "title": "",
                            "description": "Use, read, write and convert between standard units, converting measurements of length, mass, volume and time from a smaller unit of measure to a larger unit, and vica versa, using decimal notation to up to three decimal places.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "converting_metrics",
                                    "mass",
                                    "volume",
                                    "length"
                                ],
                                "-LvFiQMoK1eJQ8NB7Se8": [
                                    "converting_time"
                                ],
                                "-LvKL87IWXM0TB_zAojb": [
                                    "zero_point_zero_zero_one"
                                ]
                            }
                        },
                        "convert_between_miles_and_kilo": {
                            "title": "",
                            "description": "Convert between miles and kilometres.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "converting_metrics",
                                    "km_miles"
                                ]
                            }
                        },
                        "recognise_that_shapes_with_the": {
                            "title": "",
                            "description": "Recognise that shapes with the same areas can have different perimeters and vice versa.",
                            "topicTags": {
                                "-LvFhbMIRN1-haNWb-cM": [
                                    "same_area_diff_perim"
                                ]
                            }
                        },
                        "recognise_when_it_is_possible_": {
                            "title": "",
                            "description": "Recognise when it is possible to use formulae for area and volume of shapes.",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "volume"
                                ],
                                "-LvHH3VvuvyEnyRgLtpO": [
                                    "formulas"
                                ]
                            }
                        },
                        "calculate_the_area_of_parallel": {
                            "title": "",
                            "description": "Calculate the area of parallelograms and triangles.",
                            "topicTags": {
                                "-LvFhbMIRN1-haNWb-cM": [
                                    "parallelograms",
                                    "triangles"
                                ]
                            }
                        },
                        "calculate_estimate_and_compar": {
                            "title": "",
                            "description": "Calculate, estimate and compare volume of cubes and cuboids using standard units, including cubic centimetres (cm3) and cubic metres (m3), and extending to other units (for example, mm3 and km3).",
                            "topicTags": {
                                "IMvUSqA85BDqJgtElIgL": [
                                    "volume",
                                    "comparing_metrics"
                                ],
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "cuboid"
                                ],
                                "geometry": [
                                    "cm_m_km_3"
                                ]
                            }
                        }
                    }
                },
                "geometry_properties_of_shapes": {
                    "title": "Geometry - Properties of Shapes",
                    "objectives": {
                        "draw_2_d_shapes_using_given_di": {
                            "title": "",
                            "description": "Draw 2-D shapes using given dimensions and angles.",
                            "topicTags": {
                                "geometry": [
                                    "draw_2d_shapes"
                                ]
                            }
                        },
                        "recognise_describe_and_build_": {
                            "title": "",
                            "description": "Recognise, describe and build simple 3-D shapes, including making nets.",
                            "topicTags": {
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "3_d_shapes"
                                ]
                            }
                        },
                        "compare_and_classify_geometric": {
                            "title": "",
                            "description": "Compare and classify geometric shapes based on their properties and sizes and find unknown angles in any triangles, quadrilaterals, and regular polygons.",
                            "topicTags": {
                                "geometry": [
                                    "unknown_angles",
                                    "angles_in_triangle",
                                    "angles_in_quadrilateral"
                                ],
                                "-LvFhNiux5Za_zUmAtFz": [
                                    "polygons"
                                ]
                            }
                        },
                        "illustrate_and_name_parts_of_c": {
                            "title": "",
                            "description": "Illustrate and name parts of circles, including radius, diameter and circumference and know that the diameter is twice the radius.",
                            "topicTags": {
                                "geometry": [
                                    "radius_diameter_circumference",
                                    "radius_definition"
                                ]
                            }
                        },
                        "recognise_angles_where_they_me": {
                            "title": "",
                            "description": "Recognise angles where they meet at a point, are on a striaght line, or are vertically opposite, and find missing angles.",
                            "topicTags": {
                                "geometry": [
                                    "straight_line",
                                    "opposite_angles"
                                ]
                            }
                        }
                    }
                },
                "geometry_position_and_direction": {
                    "title": "Geometry - Position and Direction",
                    "objectives": {
                        "describe_positions_on_the_full": {
                            "title": "",
                            "description": "Describe positions on the full coordinate grid (all four quadrants).",
                            "topicTags": {
                                "-LvFit1qTPz10UZLsa4o": [
                                    "four_quadrant_grid"
                                ]
                            }
                        },
                        "draw_and_translate_simple_shap": {
                            "title": "",
                            "description": "Draw and translate simple shapes on the coordinate plane, and reflect them in the axes.",
                            "topicTags": {
                                "-LvFit1qTPz10UZLsa4o": [
                                    "shapes"
                                ],
                                "-LvuReG4XjFSrmz-wf0C": [
                                    "reflection"
                                ]
                            }
                        }
                    }
                },
                "statistics": {
                    "title": "Statistics",
                    "objectives": {
                        "interpret_and_construct_pie_ch": {
                            "title": "",
                            "description": "Interpret and construct pie charts and line graphs and use these to solve problems.",
                            "topicTags": {
                                "-LvFg4M7JSo-W81cyKkF": [
                                    "pie_charts",
                                    "line_graphs"
                                ]
                            }
                        },
                        "calculate_and_interpret_the_me": {
                            "title": "",
                            "description": "Calculate and interpret the mean as an average.",
                            "topicTags": {
                                "-LvFg4M7JSo-W81cyKkF": [
                                    "mode_median_range_mean",
                                    "mean"
                                ]
                            }
                        }
                    }
                }
            }
        }
    }
};
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Table, BreadcrumbItem, Breadcrumb, Button, CustomInput, Card, CardBody } from 'reactstrap';
import { AccountMinimised } from './data/accounts';
import { User } from './data/user';
import firebase from './firebase';

interface IState {
    reminders: Map<string, boolean>;
}

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
}

class AllReadingRecordsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            reminders: new Map(),
        };
    }

    render(): JSX.Element {
        let orderedAccountIds = Array.from(this.props.accountsMinimised.keys());
        orderedAccountIds.sort((entry1Id, entry2Id) => {
            let account1 = this.props.accountsMinimised.get(entry1Id);
            let account2 = this.props.accountsMinimised.get(entry2Id);
            return account1.name.localeCompare(account2.name);
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All reading records</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Reading records</div>
                        <p className="cardSubTitle">Select an account to view their reading records</p>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Additional</th>
                                    <th>Daily reminder</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderedAccountIds.map((accountId) => {
                                    let account = this.props.accountsMinimised.get(accountId);
                                    return <tr>
                                        <th scope="row">{account.name}</th>
                                        <td>{account.additional}</td>
                                        <td>
                                            <CustomInput id={`reminders-${accountId}`} checked={this.state.reminders.get(accountId)} type="switch" name={`reminders-${accountId}`} label="" onChange={(e) => this.updateReminder(accountId, e.target.checked)} />
                                        </td>
                                        <td><Button color="link" onClick={async () => {
                                            this.props.history.push(`readingRecords/${accountId}`);
                                        }}><i className="material-icons">library_books</i></Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br /><br /><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    async updateReminder(accountId: string, reminder: boolean) {
        try {
            this.state.reminders.set(accountId, reminder);
            this.setState({
            });
            await firebase.firestore().doc(`readingRecordsConfig/${this.props.user.schoolId}`).set({
                reminders: {
                    [accountId]: reminder,
                }
            }, { merge: true });
            const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/${accountId}/readingRecords`);
            await accountRef.set(reminder);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
    }

    async componentDidMount() {
        try {
            let remindersSnapshot = await firebase.firestore().doc(`readingRecordsConfig/${this.props.user.schoolId}`).get();
            let reminderData = remindersSnapshot.data();
            let reminders = new Map<string, boolean>();
            if (reminderData != null && reminderData.reminders != null) {
                for (let nextAccountId of Object.keys(reminderData.reminders)) {
                    reminders.set(nextAccountId, reminderData.reminders[nextAccountId]);
                }
            }

            this.setState({
                reminders: reminders,
            });
        } catch (error) {
            console.log(error);
        }
    }
}
export default AllReadingRecordsView;
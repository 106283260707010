import {Table} from "reactstrap";
import moment from "moment";
import React, {Component} from "react";
import { AccountMinimised } from "../data/accounts";
import { Slot, SlotWrapper } from "../data/bookings";

interface IState {

}

interface IProps {
    currentSlots: Map<string, SlotWrapper>;
    slotGroup: any;
    accountsMinimised: Map<string, AccountMinimised>;
}

class SlotDetailsPrint extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    render(): JSX.Element {
        console.log("Rendering slot details");
        let currentSlotIdsOrdered = Array.from(this.props.currentSlots.keys()).slice();
        currentSlotIdsOrdered.sort((sgId1, sgId2) => {
            let slot1 = this.props.currentSlots.get(sgId1).slot;
            let slot2 = this.props.currentSlots.get(sgId2).slot;
            return slot1.startDate.getTime() - slot2.startDate.getTime();
        });
        return  <Table>
            <thead>
            <tr>
                <th>Time</th>
                <th>Slot owner(s)</th>
                <th>Booked by</th>
                {this.props.slotGroup.details.autoConfirm ? null : <th>Requested</th>}
                <th>Confirmed</th>
                <th>Paid</th>
            </tr>
            </thead>
            <tbody>
            {currentSlotIdsOrdered.map((slotId) => {
                let slotWrapper = this.props.currentSlots.get(slotId);
                let slot = slotWrapper.slot;
                if (slotWrapper.accounts == null || slotWrapper.accounts.size === 0) {
                    return <tr key={slotId + 'tr'}>
                        <th scope="row" key={slotId + 'th'}>
                            {moment(slot.startDate).format("ddd DD/MM/YYYY HH:mm")}<br/>
                            {moment(slot.endDate).format("ddd DD/MM/YYYY HH:mm")}
                        </th>
                        <td key={slotId + 'td2'}>
                            {slot.owners != null ?
                                Array.from(slot.owners.keys()).map((accountId) => {
                                    let ownerDeets = slot.owners.get(accountId);
                                    let owner = this.props.accountsMinimised.get(accountId);
                                    if (owner == null || ownerDeets.owner == null || !ownerDeets.owner) {
                                        return null;
                                    }
                                    return <div>
                                        {owner.name}
                                    </div>;
                                }) : ""}
                        </td>
                        <td key={slotId + 'td3'}>&nbsp;</td>
                        {this.props.slotGroup.details.autoConfirm ? null : <td key={slotId + 'td4'}>&nbsp;</td>}
                        <td key={slotId + 'td5'}>&nbsp;</td>
                        <td key={slotId + 'td6'}>{slot.cost != null && slot.cost > 0 ? "" : "-"}</td>
                    </tr>;
                } else {
                    let firstRowFlip = false;
                    return Array.from(slotWrapper.accounts.keys()).map((accountId) => {
                        let firstRow = true;
                        if (!firstRowFlip) {
                            firstRowFlip = true;
                        } else {
                            firstRow = false;
                        }
                        let accountDeets = slotWrapper.accounts.get(accountId);
                        let requestedCount = accountDeets.count == null ? 0 : accountDeets.count;
                        let confirmedCount = accountDeets.confirmed == null || accountDeets.confirmed.count == null ? 0 : accountDeets.confirmed.count;
                        let paidCount = accountDeets.paid == null || accountDeets.paid.count == null ? 0 : accountDeets.paid.count;
                        let accountCount = slotWrapper.accounts.size;
                        let account = this.props.accountsMinimised.get(accountId);
                        return <tr key={slotId + accountId + 'tr'}>
                            {firstRow ?
                                <th rowSpan={accountCount} scope="row" key={slotId + 'th'}>
                                    {moment(slot.startDate).format("ddd DD/MM/YYYY HH:mm")}<br/>
                                    {moment(slot.endDate).format("ddd DD/MM/YYYY HH:mm")}
                                </th> : ""
                            }
                            {firstRow ?
                                <td rowSpan={accountCount} key={slotId + 'td1'}>
                                    {slot.owners != null ?
                                        Array.from(slot.owners.keys()).map((accountId) => {
                                            let ownerDeets = slot.owners.get(accountId);
                                            let owner = this.props.accountsMinimised.get(accountId);
                                            if (owner == null || ownerDeets.owner == null || !ownerDeets.owner) {
                                                return null;
                                            }
                                            return <div>
                                                {owner.name}
                                            </div>;
                                        }) : ""}
                                </td> : ""
                            }
                            <td key={slotId + accountId + 'td2'}>
                                {account.name}
                            </td>
                            {this.props.slotGroup.details.autoConfirm ? null : <td key={slotId + accountId + "td3"}>
                                {requestedCount - confirmedCount}
                            </td>}
                            <td key={slotId + accountId + "td4"}>
                                {confirmedCount}
                            </td>
                            <td key={slotId + accountId + "td5"}>
                                {slot.cost == null || slot.cost > 0 || paidCount > 0 ? paidCount : "-"}
                            </td>
                        </tr>
                    });
                }
            })}
            </tbody>
        </Table>;
    }
}

export default SlotDetailsPrint;
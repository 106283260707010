import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, UncontrolledTooltip,
    Breadcrumb, BreadcrumbItem, Alert, Table, Card, CardHeader, CardBody, Collapse, Modal, ModalHeader, ModalBody, CustomInput, Spinner
} from 'reactstrap';
import { Link } from "react-router-dom";
import confirm from 'reactstrap-confirm';
import { AccountGroup, AccountGroupDetails, AccountGroupLink, AccountGroupModeratorLink, AccountMinimised, CHAT_TYPE } from './data/accounts';
import { User } from './data/user';
import { RouteComponentProps } from 'react-router';

interface IState {
    accountGroup: AccountGroup;
    accountGroupId: string;
    currentAccountGroupName: string;
    currentAccountGroupAdditional: string;
    currentAccountGroupAccounts: Map<string, AccountGroupLink>;
    currentAccountGroupModerators: Map<string, AccountGroupModeratorLink>;
    currentModeratorsName: string;
    currentAccountGroupChatEnabled: boolean;
    currentAccountGroupLeaderboardEnabled: boolean;
    newAccountGroupAccount: string;
    currentAccountGroupChatType: CHAT_TYPE;
    newAccountGroupAccountGroup: string;
    currentAccountGroupChatAutoJoin: boolean;
    collapse: boolean;
    collapseAccountAdd: boolean;
}

interface MatchParams {
    accountGroupId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
    schoolLicenses: Map<string, boolean>;
    accountGroups: Map<string, AccountGroup>;
    accountsMinimised: Map<string, AccountMinimised>;
}

class GroupView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            accountGroup: null,
            accountGroupId: props.match.params.accountGroupId,
            currentAccountGroupName: '',
            currentAccountGroupAdditional: '',
            currentAccountGroupAccounts: new Map<string, AccountGroupLink>(),
            currentAccountGroupModerators: new Map<string, AccountGroupModeratorLink>(),
            currentModeratorsName: 'Moderators',
            currentAccountGroupChatEnabled: false,
            currentAccountGroupLeaderboardEnabled: false,
            newAccountGroupAccount: '',
            currentAccountGroupChatType: CHAT_TYPE.groupChatAndPrivate,
            newAccountGroupAccountGroup: '',
            currentAccountGroupChatAutoJoin: false,
            collapse: props.match.params.accountGroupId !== "-1",
            collapseAccountAdd: true,
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.removeMembership = this.removeMembership.bind(this);
        this.persistUserGroup = this.persistUserGroup.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleAccountAdd = this.toggleAccountAdd.bind(this);
        this.removeModerator = this.removeModerator.bind(this);
        this.handleModeratorChange = this.handleModeratorChange.bind(this);
        this.handleGroupAdd = this.handleGroupAdd.bind(this);
    }

    render(): JSX.Element {

        let orderedIds = Array.from(this.state.currentAccountGroupAccounts.keys());
        orderedIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).name.localeCompare(this.props.accountsMinimised.get(id2).name);
        });

        let orderedAllIds = Array.from(this.props.accountsMinimised.keys());
        orderedAllIds = orderedAllIds.filter((el) => {
            return !orderedIds.includes(el);
        });
        orderedAllIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).name.localeCompare(this.props.accountsMinimised.get(id2).name);
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/accountGroups'}>All account groups</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.currentAccountGroupName}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Account Group</div>
                        <p className="cardSubTitle">Account groups link groups of users to messages, events, learning modules etc and also aid with reporting</p>
                        {this.state.accountGroup != null ?
                            <div>
                                {this.state.accountGroupId === "-1" ? null :
                                    <Button onClick={this.toggle} className="altButton">
                                        {this.state.collapse ? "View details" : "Close"}
                                    </Button>
                                }
                                <Collapse isOpen={!this.state.collapse}>
                                    <Form onSubmit={this.handleUpdate}>
                                        <FormGroup>
                                            <Label for="currentAccountGroupName">Name *</Label>
                                            <Input type="text" required name="currentAccountGroupName" onChange={(e) => this.setState({
                                                currentAccountGroupName: e.target.value
                                            })} value={this.state.currentAccountGroupName} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentAccountGroupAdditional">Additional information</Label>
                                            <Input type="text" name="currentAccountGroupAdditional" onChange={(e) => this.setState({
                                                currentAccountGroupAdditional: e.target.value
                                            })} value={this.state.currentAccountGroupAdditional} />
                                        </FormGroup>
                                        {this.props.schoolLicenses.has('maths') && this.props.schoolLicenses.get('maths') ?
                                            <FormGroup>
                                                <CustomInput id='currentAccountGroupLeaderboardEnabled' checked={this.state.currentAccountGroupLeaderboardEnabled} type="switch" name='currentAccountGroupLeaderboardEnabled' label="Leaderboard enabled?" onChange={(e) => this.setState({
                                                    currentAccountGroupLeaderboardEnabled: e.target.checked
                                                })} />
                                            </FormGroup> : <span />
                                        }
                                        {this.props.schoolLicenses.has('messaging') && this.props.schoolLicenses.get('messaging') ?
                                            <FormGroup>
                                                <CustomInput id='currentAccountGroupChatEnabled' type="switch" checked={this.state.currentAccountGroupChatEnabled} name='currentAccountGroupChatEnabled' label="Chat enabled?" onChange={(e) => this.setState({
                                                    currentAccountGroupChatEnabled: e.target.checked
                                                })} />
                                            </FormGroup>
                                            : <span />
                                        }
                                        {this.props.schoolLicenses.has('messaging') && this.props.schoolLicenses.get('messaging') && this.state.currentAccountGroupChatEnabled ?
                                            <FormGroup>
                                                <Label for="currentAccountGroupChatType">Chat type * <i className="fas fa-info-circle icons-info" id="help-accountGroup-chatType" /></Label>
                                                <Input type="select" required name="currentAccountGroupChatType" onChange={(e) => {
                                                    let chatType = CHAT_TYPE[e.target.value as keyof typeof CHAT_TYPE];
                                                    this.setState({
                                                        currentAccountGroupChatType: chatType
                                                    });
                                                }} value={this.state.currentAccountGroupChatType}>
                                                    <option value={CHAT_TYPE.groupChatOnly}>Group chat only</option>
                                                    <option value={CHAT_TYPE.groupChatAndPrivate}>Group chat and private chat with moderators</option>
                                                    <option value={CHAT_TYPE.privateOnly}>Private chat with moderators only</option>
                                                    <option value={CHAT_TYPE.privateAndReadOnlyGroupChat}>Private chat with moderators and read-only group chat</option>
                                                    <option value={CHAT_TYPE.readOnlyGroupChat}>Read-only group chat</option>
                                                </Input>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-accountGroup-chatType">
                                                    Group chat is where everyone in a group can chat with everyone else as a group (Used for general discussion).
                                                    A read-only group chat only allows moderators to send messages to a group (Used for fast-moving announcements).
                                                    Private chat allow group members to talk directly to moderators without other group members seeing the conversation (Used for absence reporting, direct teacher chat)
                                                    </UncontrolledTooltip>
                                            </FormGroup> : <span />
                                        }
                                        {this.props.schoolLicenses.has('messaging') && this.props.schoolLicenses.get('messaging') && this.state.currentAccountGroupChatEnabled && this.state.currentAccountGroupChatType != CHAT_TYPE.privateOnly ?
                                            <FormGroup>
                                                <CustomInput id='currentAccountGroupChatAutoJoin' type="switch" checked={this.state.currentAccountGroupChatAutoJoin} name='currentAccountGroupChatAutoJoin' label={<span>Auto-join group chat? <i className="fas fa-info-circle icons-info" id="help-accountGroup-autoJoin" /></span>} onChange={(e) => this.setState({
                                                    currentAccountGroupChatAutoJoin: e.target.checked
                                                })} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-accountGroup-autoJoin">
                                                    Control whether group members should automatically be joined to the group chat.
                                                    </UncontrolledTooltip>
                                            </FormGroup> : <span />
                                        }
                                        {this.props.schoolLicenses.has('messaging') != null && this.props.schoolLicenses.get('messaging') && this.state.currentAccountGroupChatEnabled && this.state.currentAccountGroupChatType !== null && this.state.currentAccountGroupChatType !== CHAT_TYPE.groupChatOnly && this.state.currentAccountGroupChatType !== CHAT_TYPE.readOnlyGroupChat ?
                                            <FormGroup>
                                                <Label for="currentModeratorsName">'Chat with moderators' button name (App users will see this)</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">Chat with</InputGroupAddon>
                                                    <Input type="text" name="currentModeratorsName" onChange={(e) => this.setState({
                                                        currentModeratorsName: e.target.value
                                                    })} value={this.state.currentModeratorsName} />
                                                </InputGroup>
                                            </FormGroup> : <span />
                                        }
                                        {this.state.accountGroupId === "-1" ?
                                            <Button className="adminPagesButton">Create</Button> :
                                            <div>
                                                <Button className="adminPagesButton">Update</Button>&nbsp;
                                                </div>
                                        }
                                    </Form>
                                </Collapse>
                            </div> : <Spinner />
                        }
                    </CardBody></Card>
                {this.state.accountGroup != null ?
                    <div>
                        {this.state.accountGroupId === "-1" ? <br /> :
                            <Card className="mainCard top-buffer">
                                <CardBody className="d-flex flex-column">
                                    <div className="cardTitle2">Members</div>
                                    <div>
                                        <Button onClick={this.toggleAccountAdd} className="adminPagesButton">
                                            Add member
                                    </Button>&nbsp;
                                    {this.props.schoolLicenses.has('learningNinja') != null && this.props.schoolLicenses.get('learningNinja') ?
                                            <Button tag={Link} className="altButton" to={`/accountGroupBulkEmail/${this.state.accountGroupId}/LearningNinja`}>Send Learning Ninja invites</Button>
                                            : <span />
                                        }&nbsp;
                                        {this.props.schoolLicenses.has('messaging') != null && this.props.schoolLicenses.get('messaging') ?
                                            <Button tag={Link} className="altButton" to={`/accountGroupBulkEmail/${this.state.accountGroupId}/MessageNinja`}>Send Message Ninja invites</Button>
                                            : <span />
                                        }
                                    </div>
                                    <br />
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Additional</th>
                                                {this.props.schoolLicenses.has('messaging') != null && this.props.schoolLicenses.get('messaging') && this.state.currentAccountGroupChatEnabled ?
                                                    <th>Chat moderators <i className="material-icons">visibility</i></th>
                                                    : null
                                                }
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderedIds.map((accountId) => {
                                                let account = this.props.accountsMinimised.get(accountId);
                                                let accountDeets = this.state.currentAccountGroupAccounts.get(accountId);
                                                if (account == null || !accountDeets.member) {
                                                    return null;
                                                }
                                                return <tr key={accountId + 'tr'}>
                                                    <th scope="row" key={accountId + 'th'}><Link
                                                        to={'/accounts/' + accountId}>{account.name}</Link>
                                                    </th>
                                                    <td key={accountId + 'td1'}>{account.additional}</td>
                                                    {this.state.currentAccountGroupChatEnabled ?
                                                        <td>{this.state.currentAccountGroupModerators.has(accountId) && this.state.currentAccountGroupModerators.get(accountId).moderator ?
                                                            <i className="material-icons">visibility</i> : null}</td>
                                                        : null
                                                    }
                                                    <td key={accountId + 'td3'}>
                                                        <Button key={accountId + 'buttonRemoveMembership'} color="link"
                                                            onClick={async () => {
                                                                let result = await confirm({
                                                                    title: "Confirm",
                                                                    message: "Please confirm you want to delete this account membership",
                                                                    confirmText: "Confirm"
                                                                });
                                                                if (result) {
                                                                    this.removeMembership(accountId)
                                                                }
                                                            }}
                                                        ><i className="material-icons">delete</i></Button>
                                                        {this.props.schoolLicenses.has('messaging') && this.props.schoolLicenses.get('messaging') && this.state.currentAccountGroupChatEnabled ?
                                                            this.state.currentAccountGroupModerators.has(accountId) && this.state.currentAccountGroupModerators.get(accountId).moderator
                                                                ? <Button key={accountId + 'buttonRemoveMod'} color="link" onClick={() => {
                                                                    this.removeModerator(accountId)
                                                                }}><i className="material-icons">visibility_off</i></Button>
                                                                : <Button key={accountId + 'buttonAddMod'} color="link" onClick={() => {
                                                                    this.handleModeratorChange(accountId)
                                                                }}><i className="material-icons">visibility</i></Button>
                                                            : null
                                                        }
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table><br />
                                </CardBody></Card>
                        }
                    </div> : <br />

                }
                <Modal isOpen={!this.state.collapseAccountAdd} toggle={this.toggleAccountAdd}>
                    <ModalHeader toggle={this.toggleAccountAdd}>Add accounts</ModalHeader>
                    <ModalBody>
                        <div className="border rounded form-margin">
                            <FormGroup>
                                <Label for="newAccountGroupAccount">Accounts</Label>
                                <Input type="select" required name="newAccountGroupAccount"
                                    onChange={(e) => this.setState({
                                        newAccountGroupAccount: e.target.value
                                    })}>
                                    <option value={""}>&nbsp;</option>
                                    {orderedAllIds.map((key) => {
                                        let additional = "";
                                        let account = this.props.accountsMinimised.get(key);
                                        if (account.additional != null && account.additional !== '') {
                                            additional = ` - ${account.additional}`;
                                        }
                                        return (
                                            <option value={key}
                                                key={key}>{account.name + additional}</option>
                                        )
                                    })
                                    }
                                </Input><br />
                            </FormGroup>
                            <Button color="success"
                                disabled={this.state.newAccountGroupAccount == null || this.state.newAccountGroupAccount === ""}
                                onClick={() => { this.handleAccountChange(false); }}>
                                Add
                            </Button>&nbsp;
                            <Button color="success"
                                disabled={this.state.newAccountGroupAccount == null || this.state.newAccountGroupAccount === ""}
                                onClick={() => { this.handleAccountChange(true); }}>
                                Add & close
                            </Button>
                        </div>
                        <div className="border rounded form-margin">
                            <FormGroup>
                                <Label for="newAccountGroupAccountGroup">Account Groups</Label>
                                <Input type="select" required name="newAccountGroupAccountGroup"
                                    onChange={(e) => this.setState({
                                        newAccountGroupAccountGroup: e.target.value
                                    })} value={this.state.newAccountGroupAccountGroup}>
                                    <option value={""}>&nbsp;</option>
                                    {Array.from(this.props.accountGroups.keys()).map((key) => {
                                        if (key === this.state.accountGroupId) {
                                            return null;
                                        }
                                        return (
                                            <option value={key}
                                                key={key}>{this.props.accountGroups.get(key).details.name}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup>
                            <Button color="success"
                                disabled={this.state.newAccountGroupAccountGroup == null || this.state.newAccountGroupAccountGroup === ""}
                                onClick={() => { this.handleGroupAdd(false); }}>
                                Add
                            </Button>&nbsp;
                            <Button color="success"
                                disabled={this.state.newAccountGroupAccountGroup == null || this.state.newAccountGroupAccountGroup === ""}
                                onClick={() => { this.handleGroupAdd(true); }}>
                                Add & close
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                <br /><br />
            </div >
        );
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleAccountAdd(): void {
        this.setState({
            collapseAccountAdd: !this.state.collapseAccountAdd,
            newAccountGroupAccount: '',
        });
    }

    async handleAccountChange(close: boolean): Promise<void> {
        let accountGroupLink = new AccountGroupLink();
        accountGroupLink.member = true;
        let accountGroupLinkData = accountGroupLink.toFirebase();

        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${this.state.accountGroupId}/accounts/${this.state.newAccountGroupAccount}`);
        await accountGroupRef.set(accountGroupLinkData, (error) => {
            if (error == null) {
                this.state.currentAccountGroupAccounts.set(this.state.newAccountGroupAccount, accountGroupLink);
                this.props.snackbar();
                this.setState({
                    newAccountGroupAccount: '',
                    collapseAccountAdd: close == null ? false : close,
                });
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
    }

    async handleGroupAdd(close: boolean): Promise<void> {
        let newGroup = this.props.accountGroups.get(this.state.newAccountGroupAccountGroup);

        let newUpdate = new Map<string, AccountGroupLink>();
        let newUpdateData = new Map<string, {}>();
        Array.from(newGroup.accounts.keys()).forEach((accountId) => {
            let nextDeets = newGroup.accounts.get(accountId);
            if (nextDeets.member) {
                let accountGroupLink = new AccountGroupLink();
                accountGroupLink.member = true;
                newUpdate.set(accountId, accountGroupLink);
                let accountGroupLinkData = accountGroupLink.toFirebase();
                newUpdateData.set(accountId, accountGroupLinkData);
            }
        });
        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${this.state.accountGroupId}/accounts/`);
        await accountGroupRef.update(newUpdateData, (error) => {
            if (error == null) {
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
        let newCurrentAccountGroupAccounts = new Map<string, AccountGroupLink>([...this.state.currentAccountGroupAccounts, ...newUpdate]);
        this.setState({
            currentAccountGroupAccounts: newCurrentAccountGroupAccounts,
            newAccountGroupAccountGroup: '',
            collapseAccountAdd: close == null ? false : close,
        });
        this.props.snackbar();
    }

    async handleModeratorChange(accountId: string): Promise<void> {
        let accountGroupModeratorLink = new AccountGroupModeratorLink();
        accountGroupModeratorLink.moderator = true;
        let accountGroupModeratorLinkData = accountGroupModeratorLink.toFirebase();

        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${this.state.accountGroupId}/moderators/${accountId}`);
        await accountGroupRef.set(accountGroupModeratorLinkData, (error) => {
            if (error == null) {
                this.state.currentAccountGroupModerators.set(accountId, accountGroupModeratorLink);
                this.props.snackbar();
                this.setState({});
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
    }

    async removeMembership(accountId: string): Promise<void> {
        await this.removeModerator(accountId, true);
        let accountGroupLink = new AccountGroupLink();
        accountGroupLink.member = false;
        let accountGroupLinkData = accountGroupLink.toFirebase();

        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${this.state.accountGroupId}/accounts/${accountId}`);
        await accountGroupRef.set(accountGroupLinkData, (error) => {
            if (error == null) {
                this.state.currentAccountGroupAccounts.delete(accountId);
                this.props.snackbar();
                this.setState({});
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
    }

    async removeModerator(accountId: string, disableSnackbar?: boolean): Promise<void> {
        let accountGroupModeratorLink = new AccountGroupModeratorLink();
        accountGroupModeratorLink.moderator = false;
        let accountGroupModeratorLinkData = accountGroupModeratorLink.toFirebase();

        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${this.state.accountGroupId}/moderators/${accountId}`);
        await accountGroupRef.set(accountGroupModeratorLinkData, (error) => {
            if (error == null) {
                this.state.currentAccountGroupModerators.set(accountId, accountGroupModeratorLink);
                if (!disableSnackbar) {
                    this.props.snackbar();
                }
                this.setState({});
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let accountGroup = this.state.accountGroup;
        if (accountGroup.details == null) {
            accountGroup.details = new AccountGroupDetails();
        }
        accountGroup.details.name = this.state.currentAccountGroupName;
        accountGroup.details.additional = this.state.currentAccountGroupAdditional;
        accountGroup.details.chatEnabled = this.state.currentAccountGroupChatEnabled;
        accountGroup.details.leaderboardEnabled = this.state.currentAccountGroupLeaderboardEnabled;
        accountGroup.details.chatType = this.state.currentAccountGroupChatType;
        accountGroup.details.moderatorsName = this.state.currentModeratorsName;
        accountGroup.details.autoJoin = this.state.currentAccountGroupChatAutoJoin;
        await this.persistUserGroup(accountGroup);
    }

    async persistUserGroup(accountGroup: AccountGroup): Promise<void> {

        if (this.state.accountGroupId === "-1") {
            try {
                const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/`);
                let promise = accountGroupRef.push({
                    details: accountGroup.details.toFirebase()
                });
                let newKey = promise.key;
                await promise;
                this.setState({
                    accountGroup: accountGroup,
                    accountGroupId: newKey,
                    collapse: true,
                });
                this.props.history.replace(`/accountGroups/${newKey}`);
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed")
            }
        } else {
            try {
                const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${this.state.accountGroupId}/details`);
                await accountGroupRef.set(accountGroup.details.toFirebase());
                this.setState({
                    accountGroup: accountGroup,
                    collapse: true,
                });
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        }
    }

    async componentDidMount(): Promise<void> {
        try {

            if (this.state.accountGroupId !== "-1") {
                const itemsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${this.state.accountGroupId}`);
                let accountGroupSnapshot = await itemsRef.once('value');
                let accountGroupData = accountGroupSnapshot.val();
                let accountGroup = AccountGroup.fromFirebase(accountGroupData);
                let activeAccounts = new Map<string, AccountGroupLink>();
                if (accountGroup.accounts != null) {
                    for (let [nextAccountId, nextAccount] of accountGroup.accounts) {
                        let nextAccountDeets = accountGroup.accounts.get(nextAccountId);
                        if (nextAccount != null && nextAccountDeets.member) {
                            if (this.props.accountsMinimised.has(nextAccountId)) {
                                activeAccounts.set(nextAccountId, accountGroup.accounts.get(nextAccountId));
                            }
                        }
                    }
                }
                this.setState({
                    accountGroup: accountGroup,
                    currentAccountGroupName: accountGroup.details.name != null ? accountGroup.details.name : "",
                    currentAccountGroupAdditional: accountGroup.details.additional != null ? accountGroup.details.additional : "",
                    currentAccountGroupAccounts: activeAccounts,
                    currentAccountGroupModerators: accountGroup.moderators != null ? accountGroup.moderators : new Map<string, AccountGroupModeratorLink>(),
                    currentModeratorsName: accountGroup.details.moderatorsName != null ? accountGroup.details.moderatorsName : 'Moderators',
                    currentAccountGroupChatEnabled: accountGroup.details.chatEnabled != null ? accountGroup.details.chatEnabled : false,
                    currentAccountGroupLeaderboardEnabled: accountGroup.details.leaderboardEnabled != null ? accountGroup.details.leaderboardEnabled : false,
                    currentAccountGroupChatType: accountGroup.details.chatType != null ? accountGroup.details.chatType : CHAT_TYPE.groupChatAndPrivate,
                    currentAccountGroupChatAutoJoin: accountGroup.details.autoJoin != null ? accountGroup.details.autoJoin : false,
                    newAccountGroupAccount: '',
                });
            } else {
                this.setState({
                    accountGroup: new AccountGroup(),
                    currentAccountGroupName: '',
                    currentAccountGroupAdditional: '',
                    currentAccountGroupAccounts: new Map<string, AccountGroupLink>(),
                    currentAccountGroupModerators: new Map<string, AccountGroupModeratorLink>(),
                    currentModeratorsName: 'Moderators',
                    currentAccountGroupChatEnabled: false,
                    currentAccountGroupLeaderboardEnabled: false,
                    currentAccountGroupChatAutoJoin: false,
                    newAccountGroupAccount: '',
                    currentAccountGroupChatType: CHAT_TYPE.groupChatAndPrivate,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export default GroupView;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import {
    Card, CardBody, Col, Row, Spinner,
    CardHeader, Button, BreadcrumbItem, Breadcrumb, Alert, Table, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import confirm from "reactstrap-confirm";
import { User } from './data/user';
import { License, LicenseActivity, LicenseApp, LicensePrice, LICENSE_TYPE } from './data/license';
import { AccountGroup, AccountMinimised } from './data/accounts';
import moment from 'moment';

interface IState {
    schoolLicenseTierRef: null,
    licenseId: string;
    license: License;
    orderedTiers: LicensePrice[];
    licensedAccounts: string[];
    newLicenseeAccountId: string;
    newLicenseeAccountGroupId: string;
    listSize: number;
    searchText: string;
    tempSearchText: string;
    collapse: boolean;
    addSpinner: boolean;
    removeSpinner: string;
}

interface MatchParams {
    licenseId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    accountGroups: Map<string, AccountGroup>;
    accountsMinimised: Map<string, AccountMinimised>;
    schoolLicenses: Map<string, LicenseActivity>;
    snackbar: (text?: string) => void;
}

class LicenseView extends Component<IProps, IState> {
    timeout: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);
        this.state = {
            schoolLicenseTierRef: null,
            licenseId: props.match.params.licenseId,
            license: null,
            orderedTiers: [],
            licensedAccounts: [],
            newLicenseeAccountId: '',
            newLicenseeAccountGroupId: '',
            listSize: 10,
            searchText: '',
            tempSearchText: '',
            collapse: true,
            addSpinner: false,
            removeSpinner: null,
        };
        this.createCard = this.createCard.bind(this);
        this.removeLicensee = this.removeLicensee.bind(this);
        this.handleLicenseeAdd = this.handleLicenseeAdd.bind(this);
        this.handleLicenseeGroupAdd = this.handleLicenseeGroupAdd.bind(this);
        this.toggle = this.toggle.bind(this);
        this.doSearch = this.doSearch.bind(this);
    }

    createCard(nextLicenseTier: LicensePrice, previousMax: number, tierAccountCount: number): JSX.Element {

        let tierActive = false;
        if (tierAccountCount > 0) {
            tierActive = true; // Check numbers to show if active
        }

        let costValue = nextLicenseTier.cost.get(this.props.user.currency);
        let costString;
        if (costValue === 0) {
            costString = "Free";
        } else {
            if (costValue < 100) {
                costString = `${costValue}p`;
            } else {
                let decimalVal = costValue / 100;
                if (costValue % 100 === 0) {
                    costString = `${decimalVal} ${this.props.user.currency}`;
                } else {
                    costString = `${(decimalVal).toFixed(2)} ${this.props.user.currency}`;
                }
            }
            costString += " / account / month";
        }

        let rangeString = nextLicenseTier.max != null ? `Accounts ${previousMax + 1} to ${nextLicenseTier.max}` : previousMax === 0 ? "Unlimited accounts" : `Accounts over ${previousMax}`;

        return <Card style={{ width: "100%" }}>
            <CardHeader
                style={{ backgroundColor: tierActive ? '#7036a4' : null, color: tierActive ? '#fff' : null }}>{rangeString}</CardHeader>
            <CardBody className="d-flex flex-column">
                <div></div>
                <div>{costString}</div>
                {tierAccountCount !== 0 ?
                    <div><br />You have <b>{tierAccountCount}</b> account{tierAccountCount != 1 ? "s" : ""} in this tier</div> : <span />
                }
            </CardBody>
        </Card>
    }

    render(): JSX.Element {
        // rows = RootView.createCardRows(this.state.orderedTiers, this.createCard, "");
        let rows = [];
        let previousMax = 0;
        let remainingLicenseCount = this.state.licensedAccounts.length;
        if (this.state.license != null) {
            let columnCount = 4;
            for (let pos = 0; pos < this.state.orderedTiers.length; pos += columnCount) {
                let newCards = [];
                for (let plusPos = 0; plusPos < columnCount; plusPos++) {
                    if (this.state.orderedTiers.length > pos + plusPos) {
                        let nextTier = this.state.orderedTiers[pos + plusPos];
                        let tierTotal = 0;
                        if (nextTier.max == null) {
                            tierTotal = Math.max(0, remainingLicenseCount - previousMax);
                        } else {
                            tierTotal = Math.max(0, Math.min(remainingLicenseCount, nextTier.max) - previousMax);
                        }
                        newCards.push(
                            <Col key={"column-" + (pos + plusPos)} className='row-eq-height' md="3">
                                {this.createCard(nextTier, previousMax, tierTotal)}
                            </Col>
                        );
                        previousMax = nextTier.max;
                    } else {
                        newCards.push(
                            <Col key={"column-" + (pos + plusPos)} className='row-eq-height' md="3"> </Col>
                        );
                    }
                }
                rows.push(
                    <Row className="top-buffer">
                        {newCards}
                    </Row>
                )
            }
            if (this.state.license.trialLength != null) {
                let schoolLicense = this.props.schoolLicenses.get(this.state.licenseId);
                let licenseString;
                if (schoolLicense == null || schoolLicense.trialStart == null) {
                    licenseString = `This license has a ${this.state.license.trialLength} day free trial`;
                } else {
                    let endDate = moment(schoolLicense.trialStart);
                    let currentDate = moment();
                    let duration = moment.duration(currentDate.diff(endDate));
                    let remainingDuration = Math.ceil(90 - duration.asDays());
                    if (remainingDuration > 0) {
                        licenseString = `You have ${remainingDuration} day${remainingDuration === 1 ? "" : "s"} of free trial remaining`;
                    }
                }
                if (licenseString != null) {
                    rows.push(
                        <Row className="top-buffer">
                            <p>{licenseString}</p>
                        </Row>
                    )
                }
            }
        }

        let accountList: string[];
        if (this.state.searchText != null && this.state.searchText !== '') {
            let searchText = this.state.searchText.toLowerCase();
            accountList = [];
            for (let accountIndex in this.state.licensedAccounts) {
                let accountId = this.state.licensedAccounts[accountIndex];
                let account = this.props.accountsMinimised.get(accountId);
                if (account != null) {
                    if (account.getLowercaseName().indexOf(searchText) !== -1) {
                        accountList.push(accountId);
                    }
                }
            }
        } else {
            accountList = this.state.licensedAccounts;
        }

        accountList.sort((acAId, acBId) => {
            let acA = this.props.accountsMinimised.get(acAId);
            let acB = this.props.accountsMinimised.get(acBId);
            if (acA == null || acB == null) {
                return acAId.localeCompare(acBId);
            }
            return acA.getLowercaseName().localeCompare(acB.getLowercaseName());
        });

        let orderedAllIds = Array.from(this.props.accountsMinimised.keys());
        orderedAllIds = orderedAllIds.filter((el) => {
            return !accountList.includes(el);
        });
        orderedAllIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).getLowercaseName().localeCompare(this.props.accountsMinimised.get(id2).getLowercaseName());
        });

        let orderedAllGroupIds = Array.from(this.props.accountGroups.keys());
        orderedAllGroupIds.sort((id1, id2) => {
            return this.props.accountGroups.get(id1).details.getLowercaseName().localeCompare(this.props.accountGroups.get(id2).details.getLowercaseName());
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/licenses'}>All licenses</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.license != null ? this.state.license.name : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">{this.state.license != null ? this.state.license.name : ""}</div>
                        <p className="cardSubTitle">{this.state.license != null ? this.state.license.description : ""}</p>
                        {this.state.license != null ?
                            <Row className="justify-content-md-center">
                                {this.state.license.screenshots.map((screenshotUrl) => {
                                    return <Col>
                                        <div className="center"><a href={screenshotUrl} target="_new">
                                            <img src={screenshotUrl} height="200px" alt="" />
                                        </a></div>
                                    </Col>
                                })}
                            </Row>
                            : <Spinner />
                        }
                        {this.state.license != null && this.state.license.videoId != null ?
                            <div className="center"><iframe width="200" height="150"
                                // @ts-ignore
                                allowfullscreen="true"
                                src={`https://www.youtube.com/embed/${this.state.license.videoId}`}>
                            </iframe></div>
                            : <span />
                        }
                        <br />
                        {this.state.license != null && this.state.license.longDescription != null ?
                            <div dangerouslySetInnerHTML={{ __html: this.state.license.longDescription }}></div> : <span />
                        }
                        <br />
                        {this.state.license != null && this.state.license.weblink != null ?
                            <p><a href={this.state.license.weblink} target="_new">
                                More information
                </a></p> : <span />
                        }
                    </CardBody>
                </Card>

                {this.state.license != null ?
                    <React.Fragment>
                        <Card className="mainCard top-buffer">
                            <CardBody className="d-flex flex-column">
                                <div className="cardTitle2">Pricing</div>
                                <div>
                                    {rows}
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="mainCard top-buffer">
                            <CardBody className="d-flex flex-column">
                                {this.state.license != null &&
                                    this.state.license.type === LICENSE_TYPE.PER_ACCOUNT &&
                                    this.props.user.permissions.includes('ACCOUNT_MANAGER') ?
                                    <div>
                                        <div className="cardTitle2">Licensees ({this.state.licensedAccounts.length})</div>
                                        <Button type="button" className="adminPagesButton buttonBuffer" onClick={() => {
                                            this.toggle()
                                        }}>Add licensees</Button>
                                        <div>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText><span className="material-icons">search</span></InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder={"Search licensees"} type="text" name="tempSearchText" onChange={evt => this.doSearch(evt)} value={this.state.tempSearchText} />
                                            </InputGroup>
                                        </div>
                                        <br />
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Additional</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {accountList.slice(0, this.state.listSize).map((accountId) => {
                                                    let account = this.props.accountsMinimised.get(accountId);
                                                    if (account == null) {
                                                        return "";
                                                    }

                                                    return <tr key={accountId + 'tr'}>
                                                        <th scope="row" key={accountId + 'th'}><Link to={'/accounts/' + accountId}>{account.name}</Link></th>
                                                        <td key={accountId + 'td1'}>{account.additional}</td>
                                                        <td key={accountId + 'td4'}>
                                                            <Button disabled={this.state.removeSpinner != null} key={accountId + 'button'} color="link" onClick={async () => {
                                                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove the license from this account", confirmText: "Confirm" });
                                                                if (result) {
                                                                    this.removeLicensee(accountId)
                                                                }
                                                            }}>
                                                                {this.state.removeSpinner === accountId ?
                                                                    <Spinner size="sm"></Spinner> :
                                                                    <i className="material-icons">delete</i>
                                                                }
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                        {this.state.listSize < accountList.length ?
                                            <div>
                                                <p>Showing {this.state.listSize} of {accountList.length}</p>
                                                <Button className="adminPagesButton" type="button"
                                                    onClick={() => {
                                                        this.setState({
                                                            listSize: this.state.listSize + 10
                                                        })
                                                    }}>
                                                    Show more
                                </Button>&nbsp;&nbsp;
                                <Button className="adminPagesButton" type="button"
                                                    onClick={() => {
                                                        this.setState({
                                                            listSize: 999999
                                                        })
                                                    }}>
                                                    Show all
                                </Button>
                                            </div> :
                                            <div>
                                                <p>Showing {accountList.length}</p>
                                            </div>
                                        }
                                        <br /><br />
                                    </div> : <span />
                                }
                            </CardBody></Card>
                        <Modal isOpen={!this.state.collapse} toggle={this.toggle}>
                            <ModalHeader toggle={this.toggle}>{this.state.addSpinner ? <Spinner size="sm"></Spinner> : ""} Add account</ModalHeader>
                            <ModalBody>
                                <div className="border rounded form-margin">
                                    <FormGroup>
                                        <Label for="newLicenseeAccountId">Accounts</Label>
                                        <Input type="select" name="newLicenseeAccountId"
                                            onChange={(e) => this.setState({
                                                newLicenseeAccountId: e.target.value
                                            })}>
                                            <option>&nbsp;</option>
                                            {orderedAllIds.map((key) => {
                                                let additional = "";
                                                if (this.props.accountsMinimised.get(key).additional != null && this.props.accountsMinimised.get(key).additional !== '') {
                                                    additional = ` - ${this.props.accountsMinimised.get(key).additional}`;
                                                }
                                                return (
                                                    <option value={key}
                                                        key={key}>{this.props.accountsMinimised.get(key).name + additional}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                    <Button disabled={this.state.addSpinner || this.state.newLicenseeAccountId == null || this.state.newLicenseeAccountId === ''} color="success" onClick={() => this.handleLicenseeAdd(false)}>Add </Button>
                            &nbsp;
                            <Button disabled={this.state.addSpinner || this.state.newLicenseeAccountId == null || this.state.newLicenseeAccountId === ''} color="success" onClick={() => this.handleLicenseeAdd(true)}>Add & close</Button>
                                </div>
                                <div className="border rounded form-margin">
                                    <FormGroup>
                                        <Label for="newLicenseeAccountGroupId">Account
                                    Groups</Label>
                                        <Input type="select" name="newLicenseeAccountGroupId"
                                            onChange={(e) => this.setState({
                                                newLicenseeAccountGroupId: e.target.value
                                            })}>
                                            <option>&nbsp;</option>
                                            {orderedAllGroupIds.map((key) => {
                                                return (
                                                    <option value={key}
                                                        key={key}>{this.props.accountGroups.get(key).details.name}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                    <Button disabled={this.state.addSpinner || this.state.newLicenseeAccountGroupId == null || this.state.newLicenseeAccountGroupId === ''} color="success" onClick={() => this.handleLicenseeGroupAdd(false)}>Add</Button>
                            &nbsp;
                            <Button disabled={this.state.addSpinner || this.state.newLicenseeAccountGroupId == null || this.state.newLicenseeAccountGroupId === ''} color="success" onClick={() => this.handleLicenseeGroupAdd(true)}>Add & close</Button>
                                </div>
                            </ModalBody>
                        </Modal>
                    </React.Fragment> : <span />
                }
            </div>
        );
    }

    doSearch(e: React.ChangeEvent<HTMLInputElement>): void {
        let newSearchText = e.target.value; // this is the search text
        if (this.timeout) clearTimeout(this.timeout);
        this.setState({
            tempSearchText: newSearchText.toLowerCase(),
        });
        this.timeout = setTimeout(() => {
            this.setState({
                searchText: this.state.tempSearchText,
            });
        }, 300);
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    async handleLicenseeAdd(close: boolean): Promise<void> {
        this.setState({
            addSpinner: true,
        });
        try {
            let addLicenses = firebase.functions().httpsCallable('addAccountLicenses');
            await addLicenses({ "schoolId": this.props.user.schoolId, "accountIds": [this.state.newLicenseeAccountId], "licenseId": this.state.licenseId });

            let index = this.state.licensedAccounts.indexOf(this.state.newLicenseeAccountId);
            if (index === -1) {
                this.state.licensedAccounts.push(this.state.newLicenseeAccountId);
            }

            this.setState({
                collapse: close,
                newLicenseeAccountId: '',
            });
            this.props.snackbar("Saved");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
        this.setState({
            addSpinner: false,
        });
    }

    async handleLicenseeGroupAdd(close: boolean): Promise<void> {
        this.setState({
            addSpinner: true,
        });
        try {
            let newGroup = this.props.accountGroups.get(this.state.newLicenseeAccountGroupId);
            try {
                let addLicenses = firebase.functions().httpsCallable('addAccountLicenses');

                let licensesToAdd = [];
                for (const nextAccountId of Array.from(newGroup.accounts.keys())) {
                    let nextDeets = newGroup.accounts.get(nextAccountId);
                    if (nextDeets.member) {
                        let index = this.state.licensedAccounts.indexOf(nextAccountId);
                        if (index === -1) {
                            this.state.licensedAccounts.push(nextAccountId);
                            licensesToAdd.push(nextAccountId);
                        }
                    }
                }

                await addLicenses({ "schoolId": this.props.user.schoolId, "accountIds": licensesToAdd, "licenseId": this.state.licenseId });

                this.setState({
                    collapse: close,
                    newLicenseeAccountGroupId: '',
                });
                this.props.snackbar("Saved");
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }

            this.setState({});
            this.props.snackbar("Saved");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
        this.setState({
            addSpinner: false,
        });
    }

    async removeLicensee(accountId: string): Promise<void> {
        this.setState({
            removeSpinner: accountId,
        });

        try {
            let removeAccountLicenses = firebase.functions().httpsCallable('removeAccountLicenses');
            await removeAccountLicenses({ "schoolId": this.props.user.schoolId, "accountIds": [accountId], "licenseId": this.state.licenseId });

            let accounts = this.state.licensedAccounts;
            accounts.splice(accounts.indexOf(accountId), 1);
            this.setState({
                removeSpinner: null,
            });
            this.props.snackbar("Saved");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async componentDidMount(): Promise<void> {
        let schoolLicenseTierRef;
        let license;
        let newOrderedTiers: LicensePrice[] = [];
        let assignedLicenses = [];
        try {
            let licensesSnapshot = await firebase.firestore().doc('generalConfig/licenseDetails').get();
            let licenseDetails = licensesSnapshot.data();
            license = License.fromFirebase(licenseDetails.licenses[this.state.licenseId]);
            let newAppNames = new Map<string, LicenseApp>();
            for (let nextAppId in licenseDetails.apps) {
                let nextApp = licenseDetails.apps[nextAppId];
                newAppNames.set(nextAppId, LicenseApp.fromFirebase(nextApp));
            }

            newOrderedTiers = license.pricing == null ? [] : license.pricing;
            newOrderedTiers.sort((a, b) => {
                if (a.max != null && b.max == null) {
                    return -1;
                } else if (a.max == null && b.max != null) {
                    return 1;
                } else if (a.max == null && b.max == null) {
                    return 0; // Should never happen!
                } else {
                    return a.max - b.max;
                }
            });

            if (license.type === LICENSE_TYPE.PER_ACCOUNT && this.props.user.permissions.includes('ACCOUNT_MANAGER')) {
                let assignedLicensesDoc = await firebase.firestore().doc(`schoolLicenses/${this.props.user.schoolId}/licenses/${this.state.licenseId}`).get();
                let allHistoricalLicensees = assignedLicensesDoc.data();
                if (allHistoricalLicensees != null) {
                    for (let nextAccountId of Object.keys(allHistoricalLicensees)) {
                        let nextLicenseDeets = allHistoricalLicensees[nextAccountId];
                        if (nextLicenseDeets.length > 0 && nextLicenseDeets[nextLicenseDeets.length - 1].end == null) {
                            assignedLicenses.push(nextAccountId);
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
        this.setState({
            schoolLicenseTierRef: schoolLicenseTierRef,
            license: license,
            licensedAccounts: assignedLicenses,
            orderedTiers: newOrderedTiers,
        });
    }
}

export default LicenseView;
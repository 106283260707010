import firebase from '../firebase';
import { objectToClassConverter } from './database_object';

export class PrivatePaymentSettings {
    stripeAccountId: string;
    stripeConnectedAccountId: string;
    connectedAccount: boolean;
    detailsSubmitted: boolean;
    chargesEnabled: boolean;

    static fromFirebase(data: any): PrivatePaymentSettings {
        let newPrivatePaymentSettings = new PrivatePaymentSettings();
        if (data == null) {
            return newPrivatePaymentSettings;
        }
        objectToClassConverter(data, newPrivatePaymentSettings);

        return newPrivatePaymentSettings;
    }
}

export class PublicPaymentSettings {
    squareLocationId: string;
    paymentProvider: string;

    static fromFirebase(data: any): PublicPaymentSettings {
        let newPublicPaymentSettings = new PublicPaymentSettings();
        if (data == null) {
            return newPublicPaymentSettings;
        }
        objectToClassConverter(data, newPublicPaymentSettings);

        return newPublicPaymentSettings;
    }
}

export class SquareLocation {
    id: string;
    name: string;

    static fromFirebase(data: any): SquareLocation {
        let newSquareLocation = new SquareLocation();
        if (data == null) {
            return newSquareLocation;
        }
        objectToClassConverter(data, newSquareLocation);

        return newSquareLocation;
    }
}

export class PaymentRecord {
    objectId: string;
    objectType: string;
    cost: number;
    accountId: string;
    title: string;
    timestamp: number;
    email: string;
    customerId: string;
    orderId: string;
    subObjectId: string;

    static fromFirebase(data: any): PaymentRecord {
        let newPaymentRecord = new PaymentRecord();
        if (data == null) {
            return newPaymentRecord;
        }
        objectToClassConverter(data, newPaymentRecord);

        return newPaymentRecord;
    }
}
import React, { Component } from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
import {
    Input
} from 'reactstrap';

const MY_API_KEY = "AIzaSyDy0JakC-IkKJBXTY3yKqkA6RcVPYKb5iA";

interface IState {
    search: string;
    value: string;
}

interface IProps {
    initialValue: string;
    updatePlace: (result: google.maps.GeocoderResult) => void;
}

export default class GoogleSuggest extends React.Component<IProps, IState> {
    state = {
        search: "",
        value: "",
    };

    componentDidMount(): void {
        this.state.value = this.props.initialValue;
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: e.target.value, value: e.target.value })
    };

    handleSelectSuggest = (geocodedPrediction: google.maps.GeocoderResult, originalPrediction: google.maps.places.AutocompletePrediction) => {
        console.log(geocodedPrediction, originalPrediction);
        this.setState({ search: "", value: geocodedPrediction.formatted_address });
        this.props.updatePlace(geocodedPrediction);
    };

    render(): JSX.Element {
        const { search, value } = this.state;
        return (
            <ReactGoogleMapLoader
                params={{
                    key: MY_API_KEY,
                    libraries: "places,geocode",
                }}
                render={googleMaps =>
                    googleMaps && (
                        <ReactGooglePlacesSuggest
                            googleMaps={googleMaps}
                            autocompletionRequest={{
                                input: search,
                                // Optional options
                                // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                            }}
                            // Optional props
                            onSelectSuggest={this.handleSelectSuggest}
                            textNoResults="My custom no results text" // null or "" if you want to disable the no results item
                            customRender={prediction => (
                                <div className="customWrapper">
                                    {prediction
                                        ? prediction.description
                                        : "My custom no results text"}
                                </div>
                            )}
                        >
                            <Input
                                type="text"
                                value={this.state.value}
                                placeholder="Search a location"
                                onChange={this.handleInputChange}
                            />
                        </ReactGooglePlacesSuggest>
                    )
                }
            />
        )
    }
}
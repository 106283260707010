import React, { Component } from "react";
import { Button, Collapse, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap";
import { User } from "../data/user";
import { Link } from "react-router-dom";

export interface TableDetails {
    headers: Array<string>;
    data: Array<Array<any>>;
}

interface IProps {
    user: User;
    callback: (title: string) => void;
}

interface IState {
    modulesOpen: boolean;
    quizzesOpen: boolean;
    reportingOpen: boolean;
}

export class LearningNinjaTutorialView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            modulesOpen: false,
            quizzesOpen: false,
            reportingOpen: false,
        };
    }

    render(): JSX.Element {
        return <ListGroup flush>
            {
                this.props.user.permissions.includes('MODULE_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('moduleStreams') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ modulesOpen: !this.state.modulesOpen }); }}>Write a module</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.modulesOpen}>
                            <ListGroupItemText>
                                <i>Modules are groups of learning questions and are sent to the Learning Ninja app for students to test themselves.</i><br /><br />
                            Why not write a module? You'll probably need to create a learning area (e.g. 'Spellings') and a topic (e.g. 'Y3 spellings').
                            Modules can then be placed in topics and will appear on the student's Learning Ninja app if you assign the topic to their account group.<br /><br />
                                <Button outline block onClick={() => this.props.callback("moduleStreams")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
            {
                this.props.user.permissions.includes('MODULE_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('quizTemplates') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ quizzesOpen: !this.state.quizzesOpen }); }}>Start a quiz</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.quizzesOpen}>
                            <ListGroupItemText>
                                <i>Quizzes are a fun way to test students questions from learning modules.</i><br /><br />
                            Why not start a quiz? First create a quiz template and then 'start' the quiz.
                            You will assign the quiz to account groups and the quiz will appear on the student's devices.
                            You can then manage the quiz so that multiple students can participate and compete, with instant reporting on student answers.<br /><br />
                                <Button outline block onClick={() => this.props.callback("quizTemplates")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
            {
                this.props.user.permissions.includes('REPORTING_MANAGER') ?
                    <ListGroupItem>
                        <ListGroupItemHeading>{this.props.user.tutorials.includes('reporting') ? <span color={"green"}>&#10004; &nbsp;</span> : ""}<Link to={"#"} onClick={() => { this.setState({ reportingOpen: !this.state.reportingOpen }); }}>Run a report</Link></ListGroupItemHeading>
                        <Collapse isOpen={this.state.reportingOpen}>
                            <ListGroupItemText>
                                <i>Reports allow you to see the studying that students have completed on Learning Ninja.</i><br /><br />
                            Why not run a report and see if any of your students have started using Learning Ninja?<br /><br />
                                <Button outline block onClick={() => this.props.callback("reporting")}>Show menu option</Button>
                            </ListGroupItemText>
                        </Collapse>
                    </ListGroupItem> : <span />
            }
        </ListGroup>
    }
}

export default LearningNinjaTutorialView;
import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Card, CardBody, Spinner
} from 'reactstrap';
import { Link } from "react-router-dom";
import { PublicPaymentSettings, SquareLocation } from './data/payment_settings';
import { User } from './data/user';

interface IState {
    paymentSettings: PublicPaymentSettings;
    currentSquareLocationId: string;
    squareLocations: SquareLocation[];
}

interface IProps {
    user: User;
    tutorialCallback: (pageName: string) => void;
    snackbar: (text?: string) => void;
}

const APP_ID = "sq0idp-8r10HGHKkJJ9frLRAej1HA";

class SquarePaymentsView extends Component<IProps, IState> {


    constructor(props: IProps) {
        super(props);
        this.state = {
            paymentSettings: null,
            currentSquareLocationId: '',
            squareLocations: [],
        };

        this.handleUpdate = this.handleUpdate.bind(this);
        this.storePaymentDetails = this.storePaymentDetails.bind(this);
    }

    render() {

        let permissions = encodeURI("PAYMENTS_WRITE PAYMENTS_READ PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS ORDERS_WRITE ORDERS_READ CUSTOMERS_WRITE CUSTOMERS_READ MERCHANT_PROFILE_READ");

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/paymentSettings'}>Payment provider</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Square</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="f-flex flex-column">
                        <div className="cardTitle">Payment settings</div>
                        <p className="cardSubTitle">To allow users to make payments, the organisation's <a target={"_blank"} href={"https://squareup.com/gb/en"}>Square</a> payment settings need to be specified here.</p>
                        {
                            (this.state.paymentSettings != null) ?
                                <div>
                                    <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                        <ol>
                                            <li><a target={"_blank"} href={"https://squareup.com/signup/gb"}>Create</a> a Square account</li>
                                            <li><a href={`https://connect.squareup.com/oauth2/authorize?client_id=${APP_ID}&scope=${permissions}`}>Connect</a> your Square account to Life Ninja</li>
                                            <li><FormGroup>
                                                <Label for="currentSquareLocationId">Select your Square location</Label>
                                                <Input type="select" name="currentSquareLocationId"
                                                    onChange={(e) => this.setState({
                                                        currentSquareLocationId: e.target.value
                                                    })}
                                                    value={this.state.currentSquareLocationId}>
                                                    <option>&nbsp;</option>
                                                    {this.state.squareLocations.map((nextLocation: SquareLocation) => {
                                                        return (
                                                            <option value={nextLocation.id}
                                                                key={nextLocation.id}>{nextLocation.name}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                            </FormGroup>
                                                <Button className="adminPagesButton">Save</Button></li>
                                        </ol>
                                    </Form>
                                </div> : <Spinner size="sm"></Spinner>
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    handleUpdate(e: React.FormEvent): void {
        e.preventDefault();
        let paymentDetails = this.state.paymentSettings;
        paymentDetails.squareLocationId = this.state.currentSquareLocationId;

        this.storePaymentDetails(paymentDetails);
        this.props.tutorialCallback('paymentSettings');
    }

    async storePaymentDetails(paymentDetails: PublicPaymentSettings): Promise<void> {
        const paymentRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/paymentSettings/public`);
        try {
            await paymentRef.set(paymentDetails);
            this.setState({
                paymentSettings: paymentDetails,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
    }

    async componentDidMount(): Promise<void> {
        try {
            const paymentRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/paymentSettings/public`);
            let snapshot = await paymentRef.once('value');
            let paymentDetails = snapshot.val();
            let newSquareLocations = [];
            if (paymentDetails == null) {
                paymentDetails = new PublicPaymentSettings();
            }

            try {
                let idToken = await firebase.auth().currentUser.getIdToken();
                let authorization = 'Bearer ' + idToken;
                let response = await fetch('https://admin.lifeninja.net/adminRequests/retrievePaymentLocations', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': authorization
                    },
                    body: JSON.stringify({
                        schoolId: this.props.user.schoolId,
                    })
                });
                if (response.status !== 201) {
                    console.log("Failed to retrieve locations");
                } else {
                    newSquareLocations = await response.json();
                    console.log(newSquareLocations);
                }
            } catch (error) {
                this.props.snackbar("Unable to retrieve Square locations");
            }

            this.setState({
                squareLocations: newSquareLocations,
                paymentSettings: paymentDetails,
                currentSquareLocationId: paymentDetails.squareLocationId,
            });
        } catch (error) {
            console.log(error)
        }
    }
}

export default SquarePaymentsView;
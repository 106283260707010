import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import {
    Button,
    FormGroup,
    Label,
    Input,
    Breadcrumb,
    BreadcrumbItem,
    Alert,
    Table,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Modal,
    ModalHeader, ModalBody, ModalFooter, Spinner
} from 'reactstrap';
import moment from "moment";
import CountdownTimer from "./ninja_components/countdown_timer";
import { subjectIds } from './Prettifier';
import { User } from './data/user';
import { AccountGroup, AccountMinimised } from './data/accounts';
import { LearningGame, Question } from './data/learning_games';
import { Topic } from './data/home_learning';
import { LeaderboardEntry, QuizRound, QuizRoundAnalysis, QuizRoundAnswer } from './data/quizzes';
import { number, string } from 'prop-types';

interface IState {
    quizId: string;
    currentQuizName: string;
    currentQuizAccountIds: string[];
    currentQuizQuestions: QuizRound[];
    currentQuestionIndex: number;
    currentQuestionActive: boolean;
    currentQuizYouTubeId: string;
    currentQuizSubjectId: string;
    currentQuizBotCount: number;
    newQuestionId: string;
    collapse: boolean;
    showModal: boolean;
    showAccountModal: boolean;
    currentQuizSchedule: string;
    allAnswers: Map<number, Map<string, QuizRoundAnswer>>;
    allAnalysis: Map<number, QuizRoundAnalysis>;
    allLeaderboard: Map<string, LeaderboardEntry>;
    currentQuizType: string;
    modalActiveQuestion: boolean;
    modalAnswers: Map<string, QuizRoundAnswer>;
    modalAccountId: string;
    modalQuestionIndex: number;
}

interface MatchParams {
    quizId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    accountsMinimised: Map<string, AccountMinimised>;
    snackbar: (text?: string) => void;
}

interface QuizRoundResultsOverview {
    correct: number;
    incorrect: number;
    flawless: number;
}

class QuizRoundChallengeStub {
    name: string;
    topic: string;
    type: string;
    answers: string;
}

class AutomatedQuizView extends Component<IProps, IState> {
    questions = new Map<string, Question>();
    minigames = new Map<string, LearningGame>();
    currentResponsesListener: firebase.database.Reference;
    currentAnalysisListener: firebase.database.Reference;
    quizDetailsListener: firebase.database.Reference;
    leaderboardListener: firebase.database.Reference;
    accountsListener: firebase.database.Reference;
    botCountListener: firebase.database.Reference;
    topicsOrdered: string[];
    topics: Map<string, Topic>;
    timer: NodeJS.Timer;

    constructor(props: IProps) {
        super(props);
        this.state = {
            quizId: props.match.params.quizId,
            currentQuizName: null,
            currentQuizAccountIds: [],
            currentQuizQuestions: [],
            currentQuestionIndex: -1,
            currentQuestionActive: false,
            currentQuizYouTubeId: '',
            currentQuizSubjectId: '',
            currentQuizBotCount: 0,
            newQuestionId: null,
            collapse: props.match.params.quizId !== "-1",
            showModal: false,
            showAccountModal: false,
            currentQuizSchedule: '',
            allAnswers: new Map(),
            allAnalysis: new Map(),
            allLeaderboard: new Map(),
            currentQuizType: null,
            modalActiveQuestion: false,
            modalAnswers: null,
            modalAccountId: null,
            modalQuestionIndex: null,
        };

        this.toggle = this.toggle.bind(this);
        this.loadQuestions = this.loadQuestions.bind(this);
        this.loadMinigames = this.loadMinigames.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeAccountModal = this.closeAccountModal.bind(this);
        this.showQuestionResponses = this.showQuestionResponses.bind(this);
        this.showAccountResponses = this.showAccountResponses.bind(this);
        this.setupAnswersListener = this.setupAnswersListener.bind(this);
        this.storeYouTube = this.storeYouTube.bind(this);
        this.startNextQuestion = this.startNextQuestion.bind(this);
        this.endCurrentQuestion = this.endCurrentQuestion.bind(this);
    }


    render(): JSX.Element {
        let answersByAccount = new Map<string, QuizRoundResultsOverview>();

        if (this.state.currentQuestionIndex > -1 && this.state.currentQuestionActive
            && this.state.allAnswers.has(this.state.currentQuestionIndex)) {
            Array.from(this.state.allAnswers.get(this.state.currentQuestionIndex).keys()).map((accountId) => {
                let value = this.state.allAnswers.get(this.state.currentQuestionIndex).get(accountId);
                if (!answersByAccount.has(accountId)) {
                    answersByAccount.set(accountId, { correct: 0, incorrect: 0, flawless: 0 });
                }
                value.correct ? answersByAccount.get(accountId).correct++ : answersByAccount.get(accountId).incorrect++;
                if (value.flawless) {
                    answersByAccount.get(accountId).flawless++;
                }
                return "";
            });
        }

        Array.from(this.state.allAnswers.keys()).map((questionIndex) => {
            if (this.state.currentQuestionIndex === questionIndex && this.state.currentQuestionActive) {
                return "";
            }
            let answerMap = this.state.allAnswers.get(questionIndex);
            if (answerMap != null) {
                Array.from(answerMap.keys()).map((accountId) => {
                    let value = answerMap.get(accountId);
                    if (!answersByAccount.has(accountId)) {
                        answersByAccount.set(accountId, { correct: 0, incorrect: 0, flawless: 0 });
                    }
                    value.correct ? answersByAccount.get(accountId).correct++ : answersByAccount.get(accountId).incorrect++;
                    if (value.flawless) {
                        answersByAccount.get(accountId).flawless++;
                    }
                    return "";
                });
            }
            return "";
        });

        let participantCount = 0;
        let scheduleMoment = null;
        if (this.state.currentQuizName != null) {
            scheduleMoment = moment(this.state.currentQuizSchedule);

            participantCount = (this.state.currentQuizBotCount != null ? this.state.currentQuizBotCount : 0) + this.state.currentQuizAccountIds.length;
        }

        let leaderboardAccountIds = null;
        if (this.state.currentQuizAccountIds != null) {
            leaderboardAccountIds = this.state.currentQuizAccountIds;
            leaderboardAccountIds.sort((acId1, acId2) => {
                let ac1Count = answersByAccount.has(acId1) ? answersByAccount.get(acId1).correct : 0;
                let ac1Drums = this.state.allLeaderboard.has(acId1) && this.state.allLeaderboard.get(acId1).drums != null ? this.state.allLeaderboard.get(acId1).drums : 0;
                let ac2Count = answersByAccount.has(acId2) ? answersByAccount.get(acId2).correct : 0;
                let ac2Drums = this.state.allLeaderboard.has(acId2) && this.state.allLeaderboard.get(acId2).drums != null ? this.state.allLeaderboard.get(acId2).drums : 0;
                return (ac2Count + ac2Drums) - (ac1Count + ac1Drums);
            });
        }

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/quizzes'}>All active quizzes</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.currentQuizName != null ? this.state.currentQuizName : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Active quiz</div>
                        <p className="cardSubTitle">Dashboard for managing a live quiz</p>
                        {this.state.currentQuizName != null ?
                            <div>
                                <CountdownTimer schedule={scheduleMoment} {...this.props} />
                                <div>
                                    <Button onClick={this.toggle} className="altButton">
                                        {this.state.collapse ? "View details" : "Close"}
                                    </Button>
                                </div>
                                <Collapse isOpen={!this.state.collapse}>
                                    <FormGroup>
                                        <Label for="currentQuizName">Name</Label>
                                        <Input disabled type="text" required name="currentQuizName"
                                            value={this.state.currentQuizName} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="Subject">Subject</Label>
                                        <Input disabled type="select" name="currentQuizSubjectId"
                                            value={this.state.currentQuizSubjectId}>
                                            {Array.from(subjectIds.keys()).map(subjectId => {
                                                let subjectName = subjectIds.get(subjectId);
                                                return <option value={subjectId}>{subjectName}</option>;
                                            })}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="schedule">Schedule</Label>
                                        <Input disabled type="text" name="currentQuizSchedule"
                                            value={scheduleMoment.format("ddd, MMM Do YYYY, h:mm a")} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentQuizYouTubeId">You tube live stream id</Label>
                                        <Input type="text" name="currentQuizYouTubeId"
                                            onChange={(e) => this.setState({
                                                currentQuizYouTubeId: e.target.value
                                            })}
                                            value={this.state.currentQuizYouTubeId} />
                                    </FormGroup>
                                    <Button onClick={this.storeYouTube} className="adminPagesButton">Update</Button>
                                </Collapse>
                            </div> : <Spinner />
                        }
                    </CardBody></Card>
                {this.state.currentQuizName != null ?
                    <div>
                        {this.state.quizId !== "-1" ?
                            <React.Fragment>
                                <Card className="mainCard top-buffer">
                                    <CardBody className="d-flex flex-column">

                                        <div className="cardTitle2">Rounds ({this.state.currentQuizQuestions.length})</div>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Topic</th>
                                                    <th>Type</th>
                                                    <th>Question</th>
                                                    <th>Correct %</th>
                                                    <th>Answered %</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.currentQuizQuestions.map((quizRound, questionIndex) => {
                                                    let questionNotMinigame = quizRound.type === "QUESTION";
                                                    let questionId = quizRound.id;
                                                    let question = new QuizRoundChallengeStub();
                                                    if (questionNotMinigame) {
                                                        let actualQuestion = this.questions.get(questionId);
                                                        if (actualQuestion == null) {
                                                            return null;
                                                        }
                                                        question.name = actualQuestion.text;
                                                        question.topic = this.topics.get(actualQuestion.topics[0]).name;
                                                        question.type = "Question";
                                                    } else {
                                                        let minigame = this.minigames.get(questionId);
                                                        if (minigame == null) {
                                                            return null;
                                                        }
                                                        question.name = minigame.instructions;
                                                        question.topic = this.topics.get(minigame.topics[0]).name;
                                                        question.type = minigame.type.charAt(0).toUpperCase() + minigame.type.slice(1).toLowerCase();
                                                    }
                                                    let answerResponses: Map<string, QuizRoundAnswer>;
                                                    if (questionIndex === this.state.currentQuestionIndex && this.state.currentQuestionActive) {
                                                        answerResponses = this.state.allAnswers.has(this.state.currentQuestionIndex) ? this.state.allAnswers.get(this.state.currentQuestionIndex) : new Map();
                                                    } else {
                                                        let allQuestionAnswerResponses = this.state.allAnswers.get(questionIndex);
                                                        answerResponses = allQuestionAnswerResponses == null ? new Map() : allQuestionAnswerResponses;
                                                    }
                                                    let correctCount = 0;
                                                    let incorrectCount = 0;
                                                    let fastest = null;
                                                    if (questionIndex < this.state.currentQuestionIndex || (this.state.currentQuestionIndex === questionIndex && !this.state.currentQuestionActive)) {
                                                        if (this.state.allAnalysis.has(questionIndex)) {
                                                            let fastestMillis = this.state.allAnalysis.get(questionIndex).fastestTime;
                                                            let startMillis = this.state.allAnalysis.get(questionIndex).startTime;
                                                            let diff = fastestMillis - startMillis;
                                                            if (diff > 0) {
                                                                fastest = moment().startOf('day').add(fastestMillis - startMillis, 'milliseconds').format('s.SS');
                                                            }
                                                        }
                                                    }
                                                    if (answerResponses != null) {
                                                        for (let nextAnswerResponse of Array.from(answerResponses.values())) {
                                                            nextAnswerResponse.correct ? correctCount++ : incorrectCount++;
                                                        }
                                                    }
                                                    let answeredCount = 0;
                                                    if (answerResponses != null) {
                                                        answeredCount = answerResponses.size;
                                                    }
                                                    let correctPercent = answeredCount === 0 ? 0 : Math.floor((correctCount / answeredCount) * 100);
                                                    let answeredPercent = participantCount === 0 ? 100 : Math.floor((answeredCount / participantCount) * 100);
                                                    return <tr key={questionId + 'tr'}>
                                                        <th scope="row"
                                                            key={questionId + 'th'}>{question.topic}</th>
                                                        <td key={questionId + 'td1'}>{question.type}</td>
                                                        <td key={questionId + 'td2'}>{question.name}</td>
                                                        <td key={questionId + 'td3'}>
                                                            <Button disabled={this.state.currentQuestionIndex < questionIndex} key={questionIndex + 'buttonShow'} className="altButton"
                                                                onClick={() => {
                                                                    this.showQuestionResponses(questionIndex)
                                                                }}>
                                                                {correctPercent}
                                                            </Button>
                                                        </td>
                                                        <td key={questionId + 'td4'}>
                                                            <Button disabled={this.state.currentQuestionIndex < questionIndex} key={questionIndex + 'buttonShow'} className="altButton"
                                                                onClick={() => {
                                                                    this.showQuestionResponses(questionIndex)
                                                                }}>
                                                                {answeredPercent}
                                                            </Button>
                                                        </td>
                                                        <td key={questionId + 'td5'}>
                                                            {this.state.currentQuizSchedule == null &&
                                                                this.state.currentQuestionIndex === questionIndex - 1 && !this.state.currentQuestionActive ?
                                                                <Button key={questionIndex + 'buttonStart'} className="adminPagesButton"
                                                                    onClick={() => {
                                                                        this.startNextQuestion()
                                                                    }}>
                                                                    Start question
                                                        </Button> : ""
                                                            }
                                                            {this.state.currentQuizSchedule == null &&
                                                                this.state.currentQuestionIndex === questionIndex && this.state.currentQuestionActive ?
                                                                <Button key={questionIndex + 'buttonStop'} className="adminPagesButton"
                                                                    onClick={() => {
                                                                        this.endCurrentQuestion()
                                                                    }}>
                                                                    End question
                                                        </Button> : ""
                                                            }
                                                            {this.state.currentQuizSchedule != null && this.state.currentQuestionIndex === questionIndex - 1 && !this.state.currentQuestionActive ?
                                                                "Next" : ""
                                                            }
                                                            {this.state.currentQuizSchedule != null && this.state.currentQuestionIndex === questionIndex && this.state.currentQuestionActive ?
                                                                "Live" : ""
                                                            }
                                                            {fastest != null ?
                                                                `${fastest}s` : ""
                                                            }
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </CardBody></Card>

                                {this.state.currentQuizType != null && this.state.currentQuizType === "tapTournament" ? <span>Participants {participantCount} of which {this.state.currentQuizBotCount != null ? this.state.currentQuizBotCount : 0} are bots</span> :
                                    <Card className="mainCard top-buffer">
                                        <CardBody className="d-flex flex-column">
                                            <div className="cardTitle2">Participants ({participantCount})</div>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Additional</th>
                                                        <th>Correct</th>
                                                        <th>Flawless</th>
                                                        <th>Drum stars</th>
                                                        <th>Incorrect</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {leaderboardAccountIds != null ? leaderboardAccountIds.map((accountId) => {
                                                        if (this.props.accountsMinimised == null || !this.props.accountsMinimised.has(accountId)) {
                                                            return "";
                                                        }
                                                        let account = this.props.accountsMinimised.get(accountId);
                                                        let additional;
                                                        let name;
                                                        if (account != null) {
                                                            additional = account.additional;
                                                            name = account.name;
                                                        } else {
                                                            additional = "";
                                                            name = accountId;
                                                        }
                                                        return <tr key={accountId + 'tr'}>
                                                            <th scope="row" key={accountId + 'th'}>{name}</th>
                                                            <td>{additional}</td>
                                                            <td key={accountId + 'td2'}>{answersByAccount.has(accountId) ? answersByAccount.get(accountId).correct : 0}</td>
                                                            <td key={accountId + 'td3'}>{answersByAccount.has(accountId) ? answersByAccount.get(accountId).flawless : 0}</td>
                                                            <td key={accountId + 'td4'}>
                                                                {this.state.allLeaderboard.has(accountId) && this.state.allLeaderboard.get(accountId).drums != null ? this.state.allLeaderboard.get(accountId).drums : 0}
                                                            </td>
                                                            <td key={accountId + 'td5'}>{answersByAccount.has(accountId) ? answersByAccount.get(accountId).incorrect : 0}</td>
                                                            <td key={accountId + 'td6'}>
                                                                <Button key={accountId + 'buttonShow'} color="link"
                                                                    onClick={() => {
                                                                        this.showAccountResponses(accountId)
                                                                    }}>
                                                                    <i className="material-icons">list</i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    }) : ""}
                                                </tbody>
                                            </Table>
                                        </CardBody></Card>
                                }
                            </React.Fragment> : <br />
                        }
                    </div> : <div />
                }
                {this.state.currentQuizName != null && this.state.currentQuestionIndex !== -1 && this.state.showModal ?
                    <Modal isOpen={this.state.showModal} toggle={this.closeModal}>
                        <ModalHeader toggle={this.closeModal}>Answers</ModalHeader>
                        <ModalBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Answers</th>
                                        <th>Correct?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentQuizAccountIds.map((accountId) => {
                                        let correct;
                                        let userAnswers;
                                        let allUsersAnswers = this.state.modalActiveQuestion ? this.state.allAnswers.get(this.state.currentQuestionIndex) : this.state.modalAnswers;
                                        if (allUsersAnswers != null &&
                                            allUsersAnswers.has(accountId)) {
                                            userAnswers = allUsersAnswers.get(accountId);
                                            correct = userAnswers.correct;
                                        } else {
                                            userAnswers = new QuizRoundAnswer();
                                        }
                                        let account = this.props.accountsMinimised.get(accountId);
                                        let name;
                                        if (account != null) {
                                            name = account.name;
                                        } else {
                                            name = accountId;
                                        }
                                        return <tr key={accountId + 'tr'}>
                                            <th scope="row" key={accountId + 'th'}><Link
                                                to={'/accounts/' + accountId}>{name}</Link>
                                            </th>
                                            <td key={accountId + 'td1'}>{userAnswers.answers != null ? userAnswers.answers.join(",") : ""}</td>
                                            <td key={accountId + 'td2'}>
                                                {correct == null ? "" : correct ? "Yes" : "No"}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" onClick={this.closeModal}
                                className="altButton">Close</Button>
                        </ModalFooter>
                    </Modal> : ""
                }
                {this.state.currentQuizName != null && this.state.showAccountModal ?
                    <Modal isOpen={this.state.showAccountModal} toggle={this.closeAccountModal}>
                        <ModalHeader toggle={this.closeAccountModal}>Answers</ModalHeader>
                        <ModalBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Question text</th>
                                        <th>Correct answers</th>
                                        <th>Actual answers</th>
                                        <th>Correct?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentQuizQuestions.map((quizRound, questionIndex) => {
                                        let questionNotMinigame = quizRound.type === "QUESTION";
                                        let questionId = quizRound.id;
                                        let question = new QuizRoundChallengeStub();
                                        if (questionNotMinigame) {
                                            let actualQuestion = this.questions.get(questionId);
                                            if (actualQuestion == null) {
                                                return null;
                                            }
                                            question.name = actualQuestion.text;
                                            question.topic = this.topics.get(actualQuestion.topics[0]).name;
                                            question.type = "Question";
                                            question.answers = actualQuestion.answers.join(',');
                                        } else {
                                            let minigame = this.minigames.get(questionId);
                                            if (minigame == null) {
                                                return null;
                                            }
                                            question.name = minigame.instructions;
                                            question.topic = this.topics.get(minigame.topics[0]).name;
                                            question.type = minigame.type.charAt(0).toUpperCase() + minigame.type.slice(1).toLowerCase();
                                        }
                                        let allQuestionAnswerResponses = this.state.allAnswers.get(questionIndex);
                                        let answerResponses: Map<string, QuizRoundAnswer>;
                                        if (allQuestionAnswerResponses == null) {
                                            if (this.state.currentQuestionIndex === questionIndex && this.state.currentQuestionActive) {
                                                answerResponses = this.state.allAnswers.has(this.state.currentQuestionIndex) ? this.state.allAnswers.get(this.state.currentQuestionIndex) : new Map();
                                            } else {
                                                answerResponses = new Map();
                                            }
                                        } else {
                                            answerResponses = allQuestionAnswerResponses;
                                        }
                                        let accountResponses = answerResponses.get(this.state.modalAccountId);
                                        if (accountResponses == null) {
                                            accountResponses = new QuizRoundAnswer();
                                        }
                                        return <tr key={questionId + 'tr'}>
                                            <th scope="row" key={questionId + 'th'}>
                                                {question.name}
                                            </th>
                                            <td key={questionId + 'td2'}>{question.answers}</td>
                                            <td key={questionId + 'td3'}>{accountResponses.answers != null ? accountResponses.answers.join(",") : ""}</td>
                                            <td key={questionId + 'td4'}>
                                                {accountResponses.correct == null ? "No answer" : accountResponses.correct ? "Yes" : "No"}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" onClick={this.closeAccountModal}
                                className="altButton">Close</Button>
                        </ModalFooter>
                    </Modal> : ""
                }
                <br/><br/><br/>
            </div>
        );
    }

    showQuestionResponses(questionIndex: number): void {
        if (questionIndex === this.state.currentQuestionIndex && this.state.currentQuestionActive) {
            this.setState({
                modalActiveQuestion: true,
                showModal: true
            });
        } else {
            this.setState({
                modalActiveQuestion: false,
                modalAnswers: this.state.allAnswers.get(questionIndex),
                modalQuestionIndex: questionIndex,
                showModal: true
            });
        }
    }

    showAccountResponses(accountId: string): void {
        this.setState({
            modalAccountId: accountId,
            showAccountModal: true
        });
    }

    async storeYouTube(): Promise<void> {
        try {
            const questionResponsesRef = firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/details/youTubeId`);
            await questionResponsesRef.set(this.state.currentQuizYouTubeId);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    closeModal(): void {
        this.setState({
            showModal: false
        });
    }

    closeAccountModal(): void {
        this.setState({
            showAccountModal: false
        });
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    async startNextQuestion(): Promise<void> {
        let newQuestionIndex = this.state.currentQuestionIndex + 1;

        this.setupAnswersListener(newQuestionIndex);

        const questionIndexRef = firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/details`);
        try {
            await questionIndexRef.update({ currentQuestionIndex: newQuestionIndex, currentQuestionActive: true });
        } catch (error) {
            console.log(error);
        }

        let spacer = "";
        if (newQuestionIndex < 10) {
            spacer = "0";
        }
        let questionId = `q_${spacer}${newQuestionIndex}`;

        const questionAnswerRef = firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/answers/${questionId}/analysis`);
        try {
            await questionAnswerRef.update({ startTime: firebase.database.ServerValue.TIMESTAMP });
        } catch (error) {
            console.log(error);
        }

        this.setState({
            currentQuestionIndex: newQuestionIndex,
            currentQuestionActive: true,
        })
    }

    async endCurrentQuestion(): Promise<void> {
        await firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/details/currentQuestionActive`).set(false);

        if (this.state.currentQuestionIndex >= this.state.currentQuizQuestions.length - 1) {
            await firebase.database().ref(`schoolQuizzes/minimised/school/${this.props.user.schoolId}/${this.state.quizId}/active`).set(false);
        }

        this.setState({
            currentQuestionActive: false,
            // allAnswers: new Map(),
        })
    }

    setupAnswersListener(questionIndex: number): void {
        if (this.currentResponsesListener != null) {
            this.currentResponsesListener.off();
        }

        if (this.currentAnalysisListener != null) {
            this.currentAnalysisListener.off();
        }

        let questionIndexExtended = "q_" + (questionIndex <= 9 ? "0" : "") + questionIndex;

        const questionResponsesRef = firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/answers/${questionIndexExtended}/responses`);
        this.currentResponsesListener = questionResponsesRef;

        const questionAnalysisRef = firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/answers/${questionIndexExtended}/analysis`);
        this.currentAnalysisListener = questionAnalysisRef;
        console.log("Adding listener");
        try {
            questionResponsesRef.on("child_added", (snapshot) => {
                let snapshotVal = snapshot.val();
                console.log("New responses value: ", snapshotVal);
                if (snapshotVal != null) {
                    let snapshotKey = snapshot.key;
                    let newAnswers = new Map<string, QuizRoundAnswer>([
                        ...this.state.allAnswers.has(this.state.currentQuestionIndex) ? this.state.allAnswers.get(this.state.currentQuestionIndex) : new Map(),
                        [snapshotKey, QuizRoundAnswer.fromFirebase(snapshotVal)]
                    ]);
                    this.setState({
                        allAnswers: new Map([
                            ...this.state.allAnswers,
                            [questionIndex, newAnswers],
                        ]),
                    })
                }
            });

            questionAnalysisRef.on("value", (snapshot) => {
                let snapshotVal = snapshot.val();
                console.log("New analysis value: ", snapshotVal);
                if (snapshotVal != null) {
                    this.setState({
                        allAnalysis: new Map([
                            ...this.state.allAnalysis,
                            [questionIndex, QuizRoundAnalysis.fromFirebase(snapshotVal)]
                        ]),
                    })
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    async getAllResponses(): Promise<void> {
        const questionResponsesRef = firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/answers/`);
        let snapshot = await questionResponsesRef.once('value');
        let allAnswers = snapshot.val() != null ? snapshot.val() : {};
        let allResponses = new Map<number, Map<string, QuizRoundAnswer>>();
        let allAnalysis = new Map<number, QuizRoundAnalysis>();
        Object.keys(allAnswers).forEach((questionIndex) => {
            let questionIndexNumber = parseInt(questionIndex.slice(2)); // TODO fix mapping
            let newResponses = new Map<string, QuizRoundAnswer>();
            if (allAnswers[questionIndex] != null) {
                let allResponsesData = allAnswers[questionIndex].responses;
                for (let nextResponseId in allResponsesData) {
                    newResponses.set(nextResponseId, QuizRoundAnswer.fromFirebase(allResponsesData[nextResponseId]));
                }
            }
            allResponses.set(questionIndexNumber, newResponses);
            allAnalysis.set(questionIndexNumber, allAnswers[questionIndex] != null ? QuizRoundAnalysis.fromFirebase(allAnswers[questionIndex].analysis) : new QuizRoundAnalysis());
        });
        this.setState({
            allAnswers: allResponses,
            allAnalysis: allAnalysis,
        });
    }

    async loadQuestions(questionIds: string[]): Promise<void> {
        let promises = [];

        for (let i = 0; i < questionIds.length; i++) {
            let questionId = questionIds[i];
            const questionRef = firebase.firestore().doc(`learningQuestions/${questionId}`);
            promises.push(questionRef.get());
        }

        let results = await Promise.all(promises);

        for (let i = 0; i < results.length; i++) {
            let questionSnapshot = results[i];
            let question = Question.fromFirebase(questionSnapshot.data());
            this.questions.set(questionSnapshot.id, question);
            this.setState({});
        }
    }

    async loadMinigames(minigameIds: string[]): Promise<void> {
        let promises = [];

        for (let i = 0; i < minigameIds.length; i++) {
            let minigameId = minigameIds[i];
            const minigameRef = firebase.firestore().doc(`learningGames/${minigameId}`);
            promises.push(minigameRef.get());
        }

        let results = await Promise.all(promises);

        for (let i = 0; i < results.length; i++) {
            let minigameSnapshot = results[i];
            let minigame = LearningGame.fromFirebase(minigameSnapshot.data());
            this.minigames.set(minigameSnapshot.id, minigame);
            this.setState({});
        }
    }

    async componentDidMount(): Promise<void> {
        try {
            this.quizDetailsListener = firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/details`);
            this.quizDetailsListener.on('value', async (quizSnapshot) => {
                let newQuizDetails = quizSnapshot.val();
                let questionIds = newQuizDetails.questionIds != null ? newQuizDetails.questionIds : [];
                if (this.topics == null) {
                    const topicsRef = firebase.firestore().collection(`learningTopics`).where('subjectId', '==', 'maths');
                    let topicSnapshot = await topicsRef.get();
                    let topicsOrdered: string[] = [];
                    let topics = new Map<string, Topic>();
                    topicSnapshot.forEach((docSnapshot) => {
                        let nextTopics = docSnapshot.data();
                        Object.keys(nextTopics.topics).forEach((topicId) => {
                            let nextTopic = Topic.fromFirebase(nextTopics.topics[topicId]);
                            topicsOrdered.push(topicId);
                            topics.set(topicId, nextTopic);
                        });
                    });
                    topicsOrdered.sort((id1, id2) => {
                        return topics.get(id1).name.localeCompare(topics.get(id2).name);
                    });
                    this.topicsOrdered = topicsOrdered;
                    this.topics = topics;

                    // TODO cope with non zap quizzes
                    let questionFilterIds = [];
                    let minigameFilterIds = [];
                    for (let i = 0; i < questionIds.length; i++) {
                        let quizRound = questionIds[i];
                        if (quizRound.type === "QUESTION") { // index is even
                            questionFilterIds.push(quizRound.id);
                        } else {
                            minigameFilterIds.push(quizRound.id);
                        }
                    }
                    this.loadQuestions(questionFilterIds);
                    this.loadMinigames(minigameFilterIds);
                }

                if (newQuizDetails.currentQuestionActive && !this.state.currentQuestionActive) {
                    this.setupAnswersListener(newQuizDetails.currentQuestionIndex);
                }

                const leaderboardRef = firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/leaderboard`);
                this.leaderboardListener = leaderboardRef;

                this.leaderboardListener.on("value", (snapshot) => {
                    let snapshotVal = snapshot.val();
                    console.log("New leaderboard value: ", snapshotVal);
                    if (snapshotVal != null) {
                        let newLeaderboards = new Map<string, LeaderboardEntry>();
                        if (snapshotVal.accounts != null) {
                            for (let nextAccountId in snapshotVal.accounts) {
                                newLeaderboards.set(nextAccountId, LeaderboardEntry.fromFirebase(snapshotVal.accounts[nextAccountId]));
                            }
                        }
                        this.setState({
                            allLeaderboard: newLeaderboards,
                        })
                    }
                });

                this.setState({
                    currentQuizName: newQuizDetails.name,
                    currentQuizSubjectId: newQuizDetails.subjectId,
                    currentQuizQuestions: questionIds,
                    currentQuizSchedule: newQuizDetails.schedule,
                    currentQuizType: newQuizDetails.type,
                    currentQuestionIndex: newQuizDetails.currentQuestionIndex,
                    currentQuestionActive: newQuizDetails.currentQuestionActive,
                    currentQuizYouTubeId: newQuizDetails.youTubeId == null ? null : newQuizDetails.youTubeId,
                });
            });
            this.botCountListener = firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/botCount`);
            this.botCountListener.on('value', async (quizSnapshot) => {
                let botCount = quizSnapshot.val();
                this.setState({
                    currentQuizBotCount: botCount,
                });
            });
            this.accountsListener = firebase.database().ref(`schoolQuizzes/quizzes/${this.state.quizId}/accounts`);
            this.accountsListener.on('value', async (quizSnapshot) => {
                let accounts = quizSnapshot.val();

                let quizAccountIds: string[] = [];
                if (accounts != null) {
                    quizAccountIds = Object.keys(accounts).slice();
                }
                quizAccountIds.sort((acId1, acId2) => {
                    return acId1.localeCompare(acId2);
                });

                this.setState({
                    currentQuizAccountIds: quizAccountIds,
                });
            });
            this.getAllResponses();

        } catch (error) {
            console.log(error);
        }
    }

    componentWillUnmount(): void {
        if (this.timer != null) {
            clearInterval(this.timer);
        }
        if (this.currentResponsesListener != null) {
            this.currentResponsesListener.off();
        }
        if (this.currentAnalysisListener != null) {
            this.currentAnalysisListener.off();
        }
        if (this.accountsListener != null) {
            this.accountsListener.off();
        }
        if (this.botCountListener != null) {
            this.botCountListener.off();
        }
        if (this.quizDetailsListener != null) {
            this.quizDetailsListener.off();
        }
        if (this.leaderboardListener != null) {
            this.leaderboardListener.off();
        }
    }
}

export default AutomatedQuizView;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, Card, CardBody } from 'reactstrap';
import confirm from "reactstrap-confirm";
import moment from "moment";
import { QuizMinimised } from './data/quizzes';
import { User, USER_ROLE } from './data/user';

interface IState {
    quizzes: Map<string, QuizMinimised>;
    quizzesOrdered: string[];
}

interface IProps {
    user: User;
    snackbar: (text?: string) => void;
}

class QuizzesView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            quizzesOrdered: [],
            quizzes: new Map()
        };
        this.removeItem = this.removeItem.bind(this);
    }

    render(): JSX.Element {

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All live quizzes</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Live quizzes</div>
                        <p className="cardSubTitle">All the quizzes that are either running or scheduled to run.</p>
                        <div>
                            <Link to={'/quizTemplates'}>
                                <Button className="adminPagesButton" type="button">
                                    Start a quiz
                        </Button>
                            </Link>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Creation/Schedule</th>
                                    <th>Completed</th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.quizzesOrdered.map((quizId) => {
                                    let quiz = this.state.quizzes.get(quizId);
                                    return <tr>
                                        <th scope="row">
                                            <Link to={`quizzes/${quizId}`}>{quiz.name}</Link>
                                        </th>
                                        <td>{moment(quiz.quizDate).format("ddd, MMM Do YYYY, h:mm a")}</td>
                                        <td>{quiz.active ? "" : "Yes"}</td>
                                        <td><Button color="link" onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this quiz", confirmText: "Confirm" });
                                            if (result) {
                                                this.removeItem(quizId)
                                            }
                                        }}><i className="material-icons">delete</i></Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        <br /><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    async componentDidMount() {
        try {
            const schoolQuizzesRef = firebase.database().ref(`schoolQuizzes/minimised/school/${this.props.user.schoolId}`).orderByChild("quizDate").startAt(true);
            let schoolQuizzesSnapshot = await schoolQuizzesRef.once('value');
            let schoolQuizzesData = schoolQuizzesSnapshot.val();
            let quizzes = new Map<string, QuizMinimised>();
            for (let nextSchoolQuizId in schoolQuizzesData) {
                quizzes.set(nextSchoolQuizId, QuizMinimised.fromFirebase(schoolQuizzesData[nextSchoolQuizId]));
            }

            if (this.props.user.roles.get(USER_ROLE.tournamentManager)) {
                const tapMathsQuizzesRef = firebase.database().ref(`schoolQuizzes/minimised/public`).orderByChild("quizDate").startAt(true);
                let tapMathsQuizzesSnapshot = await tapMathsQuizzesRef.once('value');
                let newTapMathsQuizzes = tapMathsQuizzesSnapshot.val();
                for (let nextQuizId in newTapMathsQuizzes) {
                    let nextTapMathsQuiz = newTapMathsQuizzes[nextQuizId];
                    if (nextTapMathsQuiz.type === "tapTournament") {
                        quizzes.set(nextQuizId, QuizMinimised.fromFirebase(nextTapMathsQuiz));
                    }
                }
            }

            let newOrderedQuizzes: string[] = Array.from(quizzes.keys());
            newOrderedQuizzes.sort((a, b) => {
                let aDateObj = new Date(quizzes.get(a).quizDate);
                let bDateObj = new Date(quizzes.get(b).quizDate);
                return bDateObj.getTime() - aDateObj.getTime();
            });
            this.setState({
                quizzes: quizzes,
                quizzesOrdered: newOrderedQuizzes
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(quizId: string): Promise<void> {
        const quizRef = firebase.database().ref(`schoolQuizzes/quizzes/${quizId}/details/deactivated`);
        try {
            quizRef.set(true);
            this.state.quizzes.delete(quizId);
            this.state.quizzesOrdered.splice(this.state.quizzesOrdered.indexOf(quizId), 1);
            this.setState({});
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }
}
export default QuizzesView;
export function makeShortString(description: string, length: number) {
    if (description == null) {
        return "";
    }
    if (description.length < 60) {
        return description;
    }
    let descriptionResult = description.substr(0, length);
    let spacePos = descriptionResult.lastIndexOf(" ");
    if (spacePos !== -1) {
        descriptionResult = descriptionResult.substr(0, spacePos);
    }
    descriptionResult += "...";

    return descriptionResult;
}
import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, Modal, ModalHeader, ModalBody, Card, CardHeader, Collapse, CardBody, InputGroup, InputGroupAddon, UncontrolledTooltip, Spinner, CustomInput
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import EmojiSelection from "./ninja_components/emoji_selection";
import queryString from 'query-string';
import { ActivityResponseFeedback, HomeLearning, HomeLearningActivityAdventure, HomeLearningActivityResponse, HomeLearningResponse, LearningPathOverview } from './data/home_learning';
import { User } from './data/user';
import { v4 as uuidv4 } from 'uuid';
import { AccountMinimised } from './data/accounts';
import { Adventure, AdventureResponse } from './data/adventures';
import { EngagementAward } from './data/engagement';
import EngagementAwardSelection from './ninja_components/engagement_award_selection';

interface IState {
    homework: HomeLearning;
    homeworkId: string;
    homeworkActivityId: string;
    subjectFilter: string;
    currentHomeworkActivityTitle: string;
    currentHomeworkActivityDescription: string;
    currentHomeworkActivityAdventureId: string;
    currentHomeworkActivityAdventures: Map<string, Adventure>;
    currentHomeworkActivityAdventureIdsSorted: string[];
    currentHomeworkResponses: Map<string, HomeLearningResponse>,
    loadingAdventures: boolean;
    allowTypeSelection: boolean;
    showFeedback: boolean;
    feedbackAccountId: string;
    newFeedback: string;
    newReviewed: boolean;
    newAwardId: string;
    filter: string;
    collapse: boolean;
    editTarget: boolean;
    adventureResponses: Map<string, Map<string, AdventureResponse>>;
}

interface MatchParams {
    homeworkId: string;
    homeworkActivityId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    accountsMinimised: Map<string, AccountMinimised>;
    user: User;
    snackbar: (text?: string) => void;
    schoolLicenses: Map<string, boolean>;
}

class HomeworkActivityAdventureView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            homework: null,
            homeworkActivityId: props.match.params.homeworkActivityId,
            homeworkId: props.match.params.homeworkId,
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            currentHomeworkActivityTitle: '',
            currentHomeworkActivityDescription: '',
            currentHomeworkActivityAdventureId: '',
            currentHomeworkActivityAdventures: new Map(),
            currentHomeworkActivityAdventureIdsSorted: [],
            currentHomeworkResponses: new Map(),
            loadingAdventures: false,
            allowTypeSelection: false,
            showFeedback: false,
            feedbackAccountId: '',
            editTarget: props.match.params.homeworkActivityId === "-1",
            newFeedback: '',
            newReviewed: false,
            newAwardId: '',
            filter: 'NAME',
            collapse: props.match.params.homeworkActivityId !== "-1",
            adventureResponses: new Map(),
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleAdventureChange = this.handleAdventureChange.bind(this);
        this.handleChangeTarget = this.handleChangeTarget.bind(this);
        this.handleEmojiChange = this.handleEmojiChange.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.showFeedback = this.showFeedback.bind(this);
        this.closeFeedback = this.closeFeedback.bind(this);
        this.handleFeedback = this.handleFeedback.bind(this);
    }

    render() {

        if (this.state.homework == null) {
            return <Spinner></Spinner>
        }

        let queryValues = this.getQuerys();

        let orderedAccountIds: string[] = this.state.homework.accounts == null ? [] : this.state.homework.accounts.slice();
        // for (let accountId of this.state.currentHomeworkAccounts) {
        //     let account = this.props.accountsMinimised.get(accountId);
        //     let name;
        //     let additional;
        //     if (account == null) {
        //         name = "Deactivated account";
        //         additional = "";
        //     } else {
        //         name = account.name;
        //         additional = account.additional;
        //     }
        //     orderedAccountIds.push({
        //         id: accountId,
        //         name: name,
        //         additional: additional,
        //     });
        // }
        orderedAccountIds = orderedAccountIds.filter((id) => this.props.accountsMinimised.get(id) != null);
        orderedAccountIds.sort((entry1, entry2) => {
            let account1 = this.props.accountsMinimised.get(entry1);
            let account2 = this.props.accountsMinimised.get(entry2);
            return account1.getLowercaseName().localeCompare(account2.getLowercaseName());
        });

        let orderedAllIds = Array.from(this.props.accountsMinimised.keys());
        orderedAllIds = orderedAllIds.filter((el) => {
            return !orderedAccountIds.includes(el);
        });
        orderedAllIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).getLowercaseName().localeCompare(this.props.accountsMinimised.get(id2).getLowercaseName());
        });

        let currentWorld;
        if (this.state.currentHomeworkActivityAdventureId != "") {
            currentWorld = this.state.currentHomeworkActivityAdventures.get(this.state.currentHomeworkActivityAdventureId);
        }

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={`/homeworks?${queryValues}`}>All homework</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/homeworks/${this.state.homeworkId}?${queryValues}`}>{this.state.homework != null ? this.state.homework.title : ""}</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.currentHomeworkActivityTitle}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Adventure activity</div>
                        <p className="cardSubTitle">Users complete an adventure</p>
                                <div><Button onClick={this.toggle} className="altButton">
                                        {this.state.collapse ? "View details" : "Close"}
                                    </Button></div>
                                <Collapse isOpen={!this.state.collapse}>
                                    <Form onSubmit={this.handleUpdate}>
                                        <FormGroup>
                                            <Label for="currentHomeworkActivityTitle">Title *</Label>
                                            <InputGroup>
                                                <Input type="text" required name="currentHomeworkActivityTitle" onChange={(e) => this.setState({
                                                    currentHomeworkActivityTitle: e.target.value
                                                })} value={this.state.currentHomeworkActivityTitle} />
                                                <InputGroupAddon addonType={"append"}>
                                                    <EmojiSelection callback={(emoji) => {
                                                        this.handleEmojiChange(emoji, "currentHomeworkActivityTitle");
                                                    }} />
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentHomeworkActivityDescription">Description *</Label>
                                            <InputGroup>
                                                <Input type="textarea" required name="currentHomeworkActivityDescription" onChange={(e) => this.setState({
                                                    currentHomeworkActivityDescription: e.target.value
                                                })} value={this.state.currentHomeworkActivityDescription} />
                                                <InputGroupAddon addonType={"append"}>
                                                    <EmojiSelection callback={(emoji) => {
                                                        this.handleEmojiChange(emoji, "currentHomeworkActivityDescription");
                                                    }} />
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentHomeworkActivityAdventureId">Adventure {
                                                this.state.currentHomeworkActivityAdventureId !== "" ?
                                                    <Link to={`/adventures/${this.state.currentHomeworkActivityAdventureId}?${queryValues}`}>&nbsp;View</Link> :
                                                    <Link to={`/adventures/-1?${queryValues}`}>&nbsp;Create</Link>
                                            }
                                            </Label>
                                            <Input type="select" name="currentHomeworkActivityWorldId"
                                                onChange={this.handleAdventureChange}
                                                disabled={!this.state.editTarget || this.state.loadingAdventures}
                                                value={this.state.currentHomeworkActivityAdventureId}>
                                                <option value={''}>{this.state.loadingAdventures ? "Loading..." : this.state.currentHomeworkActivityAdventureIdsSorted.length === 0 ? "None" : "Select adventure"}</option>
                                                {this.state.currentHomeworkActivityAdventureIdsSorted.map((adventureId) => {
                                                    let adventure = this.state.currentHomeworkActivityAdventures.get(adventureId);
                                                    return (
                                                        <option value={adventureId}
                                                            key={adventureId}>{adventure.details.title == null ? "" : adventure.details.title}</option>
                                                    )
                                                })
                                                }
                                            </Input>
                                        </FormGroup>

                                        <div>
                                            <Button className="adminPagesButton">
                                                {this.state.homeworkId !== "-1" ? "Update" : "Create homework"}
                                            </Button>
                                        </div>
                                    </Form>
                                </Collapse>
                                </CardBody></Card>
                                {this.state.homeworkId === "-1" ? <br /> :
                                    <Card className="mainCard top-buffer">
                                    <CardBody className="d-flex flex-column">
                                        <div className="cardTitle2">Responses {orderedAccountIds.length > 0 ? ` (${orderedAccountIds.length})` : ""}</div>
                                        {orderedAccountIds.length > 0 ?
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Name <Button disabled={this.state.filter === 'NAME'} color="link" onClick={async () => {
                                                            this.setState({
                                                                filter: 'NAME'
                                                            })
                                                        }}><i className="material-icons material-icons-xs">sort</i></Button>
                                                        </th>
                                                        <th>Additional</th>
                                                        <th>Progress</th>
                                                        <th>Tasks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderedAccountIds.map((accountId) => {
                                                        let account = this.props.accountsMinimised.get(accountId);

                                                        let homeworkResponse = this.state.currentHomeworkResponses.get(accountId);
                                                        let homeworkActivityResponse;
                                                        if (homeworkResponse != null) {
                                                            homeworkActivityResponse = homeworkResponse.activityResponses.get(this.state.homeworkActivityId);
                                                        }

                                                        let name;
                                                        let additional;
                                                        if (account == null) {
                                                            name = "Deactivated account";
                                                            additional = "";
                                                        } else {
                                                            name = account.name;
                                                            additional = account.additional;
                                                        }
                                                        return <tr key={accountId + 'tr'}>
                                                            <th scope="row" key={accountId + 'th'}><Link to={`/accounts/${accountId}`}>{name}</Link></th>
                                                            <td key={accountId + 'td1'}>{additional}</td>
                                                            <td key={accountId + 'td2'}>{!this.state.adventureResponses.has(accountId) ? <span /> :
                                                                Array.from(this.state.adventureResponses.get(accountId).keys()).map((responseId) => {
                                                                    return <div><Link to={`/homeworkAdventureAnalysis/${this.state.homeworkId}/${this.state.homeworkActivityId}/${this.state.currentHomeworkActivityAdventureId}/${responseId}?${queryValues}`}>Adventure</Link></div>
                                                                })
                                                            }</td>
                                                            <td key={accountId + 'td5'}>
                                                                {homeworkActivityResponse != null && homeworkActivityResponse.completed ?
                                                                    <span>
                                                                        <Button onClick={() => {
                                                                            this.showFeedback(accountId);
                                                                        }} color={"link"}>
                                                                            <i className="material-icons">{homeworkActivityResponse.reviewed == null || !homeworkActivityResponse.reviewed ? "rate_review" : "done_all"}</i>
                                                                        </Button>
                                                                &nbsp;
                                                            </span> : <span />
                                                                }
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </Table> : <div>No Invitees</div>
                                        }
                                    </CardBody></Card>
                                }
                                <br /><br /><br /><br /><br /><br />
                        <Modal isOpen={this.state.showFeedback} toggle={this.closeFeedback}>
                            <ModalHeader toggle={this.closeFeedback}>Give feedback</ModalHeader>
                            <ModalBody>
                                <div>
                                    <Form onSubmit={this.handleFeedback}>
                                        <FormGroup>
                                            <Label for="newFeedback">Feedback</Label>
                                            <InputGroup>
                                                <Input type="textarea" name="newFeedback" onChange={(e) => this.setState({
                                                    newFeedback: e.target.value
                                                })} value={this.state.newFeedback} />
                                                <InputGroupAddon addonType={"append"}>
                                                    <EmojiSelection callback={(emoji) => {
                                                        this.handleEmojiChange(emoji, "newFeedback");
                                                    }} />
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <EngagementAwardSelection awardId={this.state.newAwardId} callback={(value) => this.setState({ newAwardId: value })} user={this.props.user} />
                                        <FormGroup>
                                            <CustomInput id={"newReviewed"} type="switch" checked={this.state.newReviewed} name={"newReviewed"} label={"Mark reviewed"} onChange={(val) => {
                                                this.setState({
                                                    newReviewed: val.target.checked
                                                });
                                            }} />
                                        </FormGroup>
                                        <Button className="adminPagesButton">
                                            Send
                            </Button>
                                    </Form>
                                </div>
                            </ModalBody>
                        </Modal>
            </div>
        );
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
            homeworkName: this.state.homework == null ? "Homework" : this.state.homework.title,
            homeworkActivityName: this.state.currentHomeworkActivityTitle,
        });
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    closeFeedback() {
        this.setState({
            showFeedback: false,
        });
    }

    showFeedback(feedbackAccountId: string): void {
        let response = this.state.currentHomeworkResponses.get(feedbackAccountId);
        let activityResponse;
        if (response != null) {
            activityResponse = response.activityResponses.get(this.state.homeworkActivityId);
        }
        this.setState({
            showFeedback: !this.state.showFeedback,
            feedbackAccountId: feedbackAccountId,
            newFeedback: activityResponse != null && activityResponse.feedback != null ? activityResponse.feedback.message : "",
            newReviewed: activityResponse != null ? activityResponse.reviewed : true,
            newAwardId: activityResponse != null && activityResponse.feedback != null ? activityResponse.feedback.awardId : "",
        });
    }

    async handleChangeTarget(): Promise<void> {
        await this.loadAdventures();
        this.setState({
            currentHomeworkActivityAdventureId: '',
            editTarget: true,
        });
    }

    handleEmojiChange(emojiCharacter: string, target: string): void {
        if (target === "currentHomeworkActivityTitle") {
            this.setState({
                currentHomeworkActivityTitle: this.state.currentHomeworkActivityTitle + emojiCharacter
            });
        } else if (target === "currentHomeworkActivityDescription") {
            this.setState({
                currentHomeworkActivityDescription: this.state.currentHomeworkActivityDescription + emojiCharacter
            });
        } else {
            this.setState({
                newFeedback: this.state.newFeedback + emojiCharacter
            });
        }
    }

    async handleFeedback(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let response = this.state.currentHomeworkResponses.get(this.state.feedbackAccountId);
        let activityResponse: HomeLearningActivityResponse;
        if (response != null) {
            activityResponse = response.activityResponses.get(this.state.homeworkActivityId);
        }
        let newFeedbackRead = false;
        if (activityResponse != null) {
            newFeedbackRead = activityResponse.feedback != null && activityResponse.feedback.message !== this.state.newFeedback ? false : activityResponse.feedbackRead;
        } else {
            activityResponse = new HomeLearningActivityResponse();
        }
        if (activityResponse.feedback == null) {
            activityResponse.feedback = new ActivityResponseFeedback();
        }
        activityResponse.feedback.message = this.state.newFeedback;
        activityResponse.reviewed = this.state.newReviewed;
        activityResponse.feedbackRead = newFeedbackRead;
        if (this.state.newAwardId != null && this.state.newAwardId !== "") {
            let path = `engagementAwards/${this.props.user.schoolId}/awards/${this.state.newAwardId}`;
            let awardSnapshot = await firebase.firestore().doc(path).get();
            let award = EngagementAward.fromFirebase(awardSnapshot.data());
            activityResponse.feedback.awardId = this.state.newAwardId;
            activityResponse.feedback.imageUrl = award.imageUrl;
            activityResponse.feedback.points = award.points;
        }

        let url = `/homeworkResponses/${this.state.feedbackAccountId}--${this.state.homeworkId}`;
        const responseRef = firebase.firestore().doc(url);
        let update = {
            [`activityResponses.${this.state.homeworkActivityId}.reviewed`]: activityResponse.reviewed,
            [`activityResponses.${this.state.homeworkActivityId}.dateUpdated`]: firebase.firestore.FieldValue.serverTimestamp(),
            [`activityResponses.${this.state.homeworkActivityId}.feedbackRead`]: activityResponse.feedbackRead,
            [`activityResponses.${this.state.homeworkActivityId}.feedback`]: activityResponse.feedback.toFirebase(),
            [`dateUpdated`]: firebase.firestore.FieldValue.serverTimestamp(),
        }
        this.closeFeedback();
        this.state.currentHomeworkResponses.get(this.state.feedbackAccountId).activityResponses.set(this.state.homeworkActivityId, activityResponse);
        this.setState({});
        try {
            await responseRef.update(update);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let homeworkActivity = new HomeLearningActivityAdventure();
        homeworkActivity.title = this.state.currentHomeworkActivityTitle;
        homeworkActivity.description = this.state.currentHomeworkActivityDescription;
        homeworkActivity.adventureId = this.state.currentHomeworkActivityAdventureId;
        homeworkActivity.type = HomeLearningActivityAdventure.TYPE;

        await this.createHomeworkActivity(homeworkActivity);
    }

    async createHomeworkActivity(homeworkActivity: HomeLearningActivityAdventure): Promise<void> {
        let activityId;
        if (this.state.homeworkActivityId === "-1") {
            activityId = uuidv4();
        } else {
            activityId = this.state.homeworkActivityId;
        }
        const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
        try {
            await homeworkRef.update({
                [`activities.${activityId}`]: homeworkActivity.toFirebase(),
                activityIdsOrdered: firebase.firestore.FieldValue.arrayUnion(activityId),
                dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            });
            this.setState({
                homeworkActivityId: activityId,
                collapse: true,
            });
            this.props.history.replace(`/homeworkActivityAdventure/${this.state.homeworkId}/${activityId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async handleAdventureChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        this.setState({
            currentHomeworkActivityAdventureId: e.target.value,
        });
    }

    async loadAdventures(): Promise<void> {

        const schoolAdventuresRef = firebase.firestore().collection('adventures').where('schoolId', '==', this.props.user.schoolId).where('disabled', '==', false);
        let schoolAdventuresSnapshot = await schoolAdventuresRef.get();

        let newLearningWorldsState = new Map<string, Adventure>();
        schoolAdventuresSnapshot.forEach((nextAdventureSnapshot) => {
            newLearningWorldsState.set(nextAdventureSnapshot.id, Adventure.fromFirebase(nextAdventureSnapshot.data()));
        });

        const sharedAdventuresRef = firebase.firestore().collection('adventures').where('schoolId', '==', null).where('disabled', '==', false);
        let sharedAdventuresSnapshot = await sharedAdventuresRef.get();

        sharedAdventuresSnapshot.forEach((nextAdventureSnapshot) => {
            newLearningWorldsState.set(nextAdventureSnapshot.id, Adventure.fromFirebase(nextAdventureSnapshot.data()));
        });

        let newAdventuresSorted: string[] = Array.from(newLearningWorldsState.keys());

        newAdventuresSorted.sort((adventureAId, adventureBId) => {
            let adventureA = newLearningWorldsState.get(adventureAId);
            let adventureATitle = adventureA.details.title == null ? adventureAId : adventureA.details.title;
            let adventureB = newLearningWorldsState.get(adventureBId);
            let adventureBTitle = adventureB.details.title == null ? adventureBId : adventureB.details.title;
            return adventureATitle.localeCompare(adventureBTitle);
        });

        this.setState({
            currentHomeworkActivityAdventures: newLearningWorldsState,
            currentHomeworkActivityAdventureIdsSorted: newAdventuresSorted
        });
    }

    async componentDidMount(): Promise<void> {

        const homeworkSnapshot = await firebase.firestore().doc(`homeworks/${this.state.homeworkId}`).get();

        let homework = HomeLearning.fromFirebase(homeworkSnapshot.data());

        if (this.state.homeworkActivityId !== "-1") {

            let homeworkActivity = homework.activities.get(this.state.homeworkActivityId) as HomeLearningActivityAdventure;
            if (homeworkActivity.adventureId != null && homeworkActivity.adventureId !== "") {
                const docSnapshot = await firebase.firestore().doc(`adventures/${homeworkActivity.adventureId}`).get();
                let nextAdventure = Adventure.fromFirebase(docSnapshot.data());
                let nextAdventureId = docSnapshot.id;
                this.setState({
                    currentHomeworkActivityAdventures: new Map([[nextAdventureId, nextAdventure]]),
                    currentHomeworkActivityAdventureIdsSorted: [nextAdventureId],
                    currentHomeworkActivityAdventureId: nextAdventureId,
                })
            }

            const responsesSnapshot = await firebase.firestore().collection(`homeworkResponses`).where("schoolId", "==", this.props.user.schoolId).where("homeworkId", "==", this.state.homeworkId).get();
            let responses = responsesSnapshot.docs;
            let responseMap = new Map<string, HomeLearningResponse>();
            for (let nextResponse of responses) {
                let nextHomeLearning = HomeLearningResponse.fromFirebase(nextResponse.data());
                responseMap.set(nextHomeLearning.accountId, nextHomeLearning);
            }

            let homeworkAccounts = homework.accounts != null ? homework.accounts : [];
            this.setState({
                homework: homework,
                currentHomeworkActivityTitle: homeworkActivity.title,
                currentHomeworkActivityDescription: homeworkActivity.description,
                currentHomeworkActivityAdventureId: homeworkActivity.adventureId != null ? homeworkActivity.adventureId : null,
                currentHomeworkResponses: responseMap,
            });
            if (homeworkActivity.adventureId != null) {
                let promises = [];
                for (let accountId of homeworkAccounts) {
                    console.log("Requesting adventureResponses/", accountId);
                    promises.push(firebase.firestore().collection(`adventureResponses`).where("accountId", "==", accountId).where("adventureId", "==", homeworkActivity.adventureId).where("schoolId", "==", this.props.user.schoolId).get());
                }
                let documents = await Promise.all(promises);
                let homeworkCompleted = new Map<string, Map<string, AdventureResponse>>();
                documents.forEach((querySnapshot) => {
                    for (let docSnapshot of querySnapshot.docs) {
                        let fullOverview = AdventureResponse.fromFirebase(docSnapshot.data());
                        if (!homeworkCompleted.has(fullOverview.accountId)) {
                            homeworkCompleted.set(fullOverview.accountId, new Map());
                        }
                        homeworkCompleted.get(fullOverview.accountId).set(docSnapshot.id, fullOverview);
                    }
                });
                this.setState({
                    adventureResponses: homeworkCompleted,
                });
            }
        } else {
            this.setState({
                homework: homework,
                currentHomeworkActivityTitle: '',
                currentHomeworkActivityDescription: '',
                currentHomeworkActivityAdventureId: '',
            });
            this.setState({
                loadingAdventures: true,
            });

            await this.loadAdventures();
            this.setState({
                loadingAdventures: false,
            });
        }
    }
}

export default HomeworkActivityAdventureView;
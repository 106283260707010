import React, { Component } from 'react';
import firebase from './firebase';
import queryString from 'query-string';
import {
    Button, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, UncontrolledTooltip, Card, CardBody
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import { User, USER_ROLE } from './data/user';
import { v4 as uuidv4 } from 'uuid';
import { Adventure, AdventureAdventureSection, InformationSectionStub, AdventureSectionChoice, AdventureSectionStub } from './data/adventures';
import ImageUpload from './ninja_components/image_upload';

interface IState {
    adventureId: string;
    adventureTitle: string;
    adventureSectionId: string;
    adventureSection: AdventureAdventureSection;
    adventureSchoolId: string;
    subjectFilter: string;
    currentAdventureSectionName: string;
    currentAdventureSectionTitle: string;
    currentAdventureSectionInformationSectionLinks: Map<string, string>;
    currentAdventureSectionSchoolId: string;
    currentAdventureSectionId: string;
    currentAdventureSectionText: string;
    currentAdventureSectionChoices: AdventureSectionChoice[];
    currentAdventureSectionImageUrl: string;
    currentAdventureSectionImagePath: string;
    editWarning: boolean;
}

interface MatchParams {
    adventureSectionId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class AdventureSectionView extends Component<IProps, IState> {
    adventureSections = new Map<string, AdventureSectionStub>();
    informationSections = new Map<string, InformationSectionStub>();

    constructor(props: IProps) {
        super(props);
        this.getQuerys = this.getQuerys.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleBlockChange = this.handleBlockChange.bind(this);
        this.handleChoiceTextEdit = this.handleChoiceTextEdit.bind(this);
        this.handleChoiceLinkEdit = this.handleChoiceLinkEdit.bind(this);
        this.handleRemoveChoice = this.handleRemoveChoice.bind(this);
        this.handleAddChoice = this.handleAddChoice.bind(this);
        this.handleDelayEdit = this.handleDelayEdit.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleImageChangeFail = this.handleImageChangeFail.bind(this);
        this.loadSection = this.loadSection.bind(this);
        this.getStateObject = this.getStateObject.bind(this);
        this.state = this.getStateObject(props);
    }

    getStateObject(props: IProps): IState {
        const values = queryString.parse(props.location.search);
        let adventureId = values.adventureId == null ? '' : values.adventureId.toString();
        let adventureSectionId = props.match.params.adventureSectionId;
        let adventureTitle = values.adventureTitle == null ? "" : values.adventureTitle.toString();
        return {
            adventureId: adventureId,
            adventureTitle: adventureTitle,
            adventureSectionId: adventureSectionId,
            adventureSection: null,
            adventureSchoolId: '',
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            currentAdventureSectionTitle: '',
            currentAdventureSectionName: '',
            currentAdventureSectionInformationSectionLinks: new Map(),
            currentAdventureSectionSchoolId: '',
            currentAdventureSectionId: '',
            currentAdventureSectionText: '',
            currentAdventureSectionChoices: [],
            currentAdventureSectionImageUrl: null,
            currentAdventureSectionImagePath: null,
            editWarning: false,
        };
    }

    componentDidUpdate(previousProps: IProps, previousState: IState): void {
        if (this.props.match.params.adventureSectionId !== previousProps.match.params.adventureSectionId) {
            const values = queryString.parse(this.props.location.search);
            let adventureId = values.adventureId == null ? '' : values.adventureId.toString();
            let adventureSectionId = this.props.match.params.adventureSectionId;
            this.setState(this.getStateObject(this.props));
            this.loadSection(adventureId, adventureSectionId);
        }
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.adventureSchoolId === this.props.user.schoolId || this.props.user.role === USER_ROLE.admin || (this.props.user.roles.has(USER_ROLE.learningCreator) && this.props.user.roles.get(USER_ROLE.learningCreator));

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/adventures'}>All Adventures</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/adventures/${this.state.adventureId}?${queryValues}`}>{this.state.adventureTitle}</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.currentAdventureSectionTitle != null ? this.state.currentAdventureSectionTitle : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Adventure section</div>
                        <p className="cardSubTitle">
                            A section of the adventure that culminates in a question or an outcome (fail/success)<br />
                            To create a link to an information section add [[linkid|linktext]], for example [[geoffModuleLink|Geoff]] which will make the text 'Geoff' link to an information section. When
                            you add this
                            the link entry will appear at the bottom of this page ready for linking to an information section.
                        </p>
                        {this.state.editWarning ?
                            <Alert color="warning">
                                <h4>Remember to save your changes when finished editing</h4>
                            </Alert> : <span />
                        }
                        {this.state.adventureSection != null ?
                            <div>
                                <fieldset disabled={!editable}>
                                    <FormGroup>
                                        <Label for="currentAdventureSectionName">Name <i className="fas fa-info-circle icons-info" id="help-adventuresection-name" /></Label>
                                        <Input disabled={!editable} type="text" required name="currentAdventureSectionName" onChange={(e) => this.setState({
                                            currentAdventureSectionName: e.target.value
                                        })} value={this.state.currentAdventureSectionName} />
                                        <UncontrolledTooltip placement="bottom" autohide={false} target="help-adventuresection-name">
                                            Use this to give a unique name for the section, used in reports and adventure management. Try to make this unique.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="currentAdventureSectionTitle">Title <i className="fas fa-info-circle icons-info" id="help-adventuresection-title" /></Label>
                                        <Input disabled={!editable} type="text" required name="currentAdventureSectionTitle" onChange={(e) => this.setState({
                                            currentAdventureSectionTitle: e.target.value
                                        })} value={this.state.currentAdventureSectionTitle} />
                                        <UncontrolledTooltip placement="bottom" autohide={false} target="help-adventuresection-title">
                                            Shown as the title of the section when a player reaches this section. Does not need to be unique.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <ImageUpload callback={this.handleImageChange} failureCallback={this.handleImageChangeFail} schoolId={this.props.user.schoolId}
                                        currentImage={{ url: this.state.currentAdventureSectionImageUrl, path: this.state.currentAdventureSectionImagePath }} filePath={"adventureImages"}
                                        imageLegend={"Section image"} />
                                    <FormGroup>
                                        <Label for="currentAdventureSectionText">Text</Label>
                                        <Input type="textarea" rows="5" name="currentAdventureSectionText"
                                            onChange={this.handleBlockChange}
                                            value={this.state.currentAdventureSectionText} /><br />
                                    </FormGroup>
                                    <FormGroup className="border rounded form-margin">
                                        <h3>What can the user do next?</h3>
                                        <p>Leaving the choices empty means this is the end of the adventure</p>
                                        {this.state.currentAdventureSectionChoices.length > 0 ?
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Choice</th>
                                                        <th>Outcome</th>
                                                        <th>Delay</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentAdventureSectionChoices.map((choice, pos) => {
                                                        let linkId = choice.linkId;
                                                        let adventureSection = this.adventureSections.get(linkId);
                                                        let title = '';
                                                        if (adventureSection != null) {
                                                            title = adventureSection.title;
                                                        }
                                                        return <tr key={pos + 'choice-tr'}>
                                                            <th scope="row" key={linkId + 'th'}>
                                                                <Input type="text" required name={'choiceText-' + pos}
                                                                    placeholder="button text"
                                                                    onChange={this.handleChoiceTextEdit}
                                                                    value={choice.text} />
                                                            </th>
                                                            <td key={pos + 'choice-td3'} align="left">
                                                                {editable ?
                                                                    <Input type="select" name={'choiceLink-' + pos}
                                                                        value={linkId}
                                                                        onChange={(value) => {
                                                                            this.handleChoiceLinkEdit(pos, value);
                                                                        }}>
                                                                        <option></option>
                                                                        {Array.from(this.adventureSections.keys()).map((key) => {
                                                                            return (
                                                                                <option value={key} key={key}>{this.adventureSections.get(key).title}</option>
                                                                            )
                                                                        })}
                                                                    </Input> : <span />
                                                                }
                                                                <Link to={'/adventureSections/' + linkId + '?' + queryValues}>{title}</Link>
                                                            </td>
                                                            <td key={pos + 'choice-td4'}>
                                                                <Input type="number" required name={'choiceDelay-' + pos}
                                                                    placeholder="mins"
                                                                    onChange={this.handleDelayEdit}
                                                                    value={choice.delay} />
                                                            </td>
                                                            <td>
                                                                {editable ?
                                                                    <Button type="button" outline color="danger" onClick={() => {
                                                                        this.handleRemoveChoice(pos)
                                                                    }}>Remove</Button>
                                                                    : <span />
                                                                }
                                                                <br /><br />
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </Table> : <span />
                                        }
                                        {editable ?
                                            <div>
                                                <Button type="button" className="altButton" onClick={() => {
                                                    this.handleAddChoice()
                                                }}>Add new choice</Button>
                                            </div> : <br />
                                        }
                                    </FormGroup>
                                    {Array.from(this.state.currentAdventureSectionInformationSectionLinks.keys()).length > 0 ?
                                        <FormGroup className="border rounded form-margin">
                                            <h3>What additional information can the user see?</h3>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Information id</th>
                                                        <th>Information section title</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.from(this.state.currentAdventureSectionInformationSectionLinks.keys()).map((linkId, pos) => {
                                                        let informationSectionId = this.state.currentAdventureSectionInformationSectionLinks.get(linkId);
                                                        let title = '';
                                                        if (informationSectionId != null) {
                                                            let informationSection = this.informationSections.get(informationSectionId);
                                                            if (informationSection != null) {
                                                                title = informationSection.title;
                                                            }
                                                        }
                                                        return <tr key={pos + 'tr'}>
                                                            <th scope="row"
                                                                key={pos + 'th'}>{linkId}</th>
                                                            <td key={pos + 'td4'}>
                                                                {editable ?
                                                                    <Input type="select" name={"newInformationSectionId-" + pos}
                                                                        value={this.state.currentAdventureSectionInformationSectionLinks.get(linkId)}
                                                                        onChange={(value) => {
                                                                            this.handleLinkChange(linkId, value);
                                                                        }}>
                                                                        <option></option>
                                                                        {Array.from(this.informationSections.keys()).map((key) => {
                                                                            return (
                                                                                <option value={key} key={key}>{this.informationSections.get(key).title}</option>
                                                                            )
                                                                        })}
                                                                    </Input> : <span />
                                                                }
                                                                <Link to={'/informationSections/' + informationSectionId + '?' + queryValues}>{title}</Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </Table>
                                        </FormGroup>
                                        : <span />
                                    }
                                </fieldset>
                                {editable ?
                                    <Button className="adminPagesButton" onClick={() => {
                                        this.handleUpdate();
                                    }}>
                                        {this.state.adventureSectionId === "-1" ? "Create" : "Update"}
                                    </Button> : <span />
                                }
                            </div> : <div />
                        }
                        <br /><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
            adventureId: this.state.adventureId,
            adventureTitle: this.state.adventureTitle,
            adventureSectionId: this.state.adventureSectionId,
            adventureSectionTitle: this.state.adventureSection != null ? this.state.adventureSection.title : ""
        });
    }

    handleImageChangeFail(): void {
        this.props.snackbar("Image upload failed");
    }

    async handleImageChange(url: string, path: string): Promise<void> {
        let imageDeets = {
            imageUrl: url,
            imagePath: path,
        };
        const sectionRef = firebase.firestore().doc(`adventures/${this.state.adventureId}/adventureSections/${this.state.adventureSectionId}`);
        const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
        let batch = firebase.firestore().batch();
        try {
            batch.update(sectionRef, imageDeets);
            batch.update(adventureRef, { [`adventureSections.${this.state.adventureSectionId}.imageUrl`]: url });
            await batch.commit();
            this.setState({
                currentAdventureSectionImageUrl: url,
                currentAdventureSectionImagePath: path,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
    }

    handleRemoveChoice(pos: number): void {
        let newChoices = this.state.currentAdventureSectionChoices.slice();
        newChoices.splice(pos, 1);
        this.setState({
            currentAdventureSectionChoices: newChoices,
            editWarning: true,
        });
    }

    handleChoiceTextEdit(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = parseInt(e.target.name.split('-')[1]);
        let newChoices = this.state.currentAdventureSectionChoices.slice();
        newChoices[index].text = e.target.value;
        this.setState({
            currentAdventureSectionChoices: newChoices,
            editWarning: true,
        });
    }

    handleDelayEdit(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = parseInt(e.target.name.split('-')[1]);
        let newChoices = this.state.currentAdventureSectionChoices.slice();
        newChoices[index].delay = e.target.value === "" ? 0 : parseInt(e.target.value);
        this.setState({
            currentAdventureSectionChoices: newChoices,
            editWarning: true,
        });
    }

    handleChoiceLinkEdit(index: number, e: React.ChangeEvent<HTMLInputElement>): void {
        let newChoices = this.state.currentAdventureSectionChoices.slice();
        newChoices[index].linkId = e.target.value;
        this.setState({
            currentAdventureSectionChoices: newChoices,
            editWarning: true,
        });
    }

    handleAddChoice(): void {
        let newChoices = this.state.currentAdventureSectionChoices.slice();
        newChoices.push(new AdventureSectionChoice());
        this.setState({
            currentAdventureSectionChoices: newChoices,
            editWarning: true,
        });
    }

    handleBlockChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let newText = e.target.value;
        let starts = newText.split('[[');
        let newLinkIds = new Map<string, string>();
        for (let i = 0; i < starts.length; i++) {
            let end = starts[i].indexOf(']]');
            let middle = starts[i].indexOf('|');
            if (end !== -1 && middle !== -1) {
                let linkId = starts[i].substring(0, middle);
                if (this.state.currentAdventureSectionInformationSectionLinks.has(linkId)) {
                    newLinkIds.set(linkId, this.state.currentAdventureSectionInformationSectionLinks.get(linkId));
                } else {
                    newLinkIds.set(linkId, '');
                }
            }
        }
        this.setState({
            currentAdventureSectionText: newText,
            editWarning: true,
            currentAdventureSectionInformationSectionLinks: newLinkIds,
        });
    }

    handleLinkChange(linkId: string, e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            currentAdventureSectionInformationSectionLinks: new Map([...this.state.currentAdventureSectionInformationSectionLinks, [linkId, e.target.value]]),
            editWarning: true,
        });
    }

    async handleUpdate(): Promise<void> {
        let adventureSection = this.state.adventureSection;
        adventureSection.name = this.state.currentAdventureSectionName;
        adventureSection.title = this.state.currentAdventureSectionTitle;
        adventureSection.text = this.state.currentAdventureSectionText;
        adventureSection.choices = this.state.currentAdventureSectionChoices;
        adventureSection.informationLinkIds = this.state.currentAdventureSectionInformationSectionLinks;
        adventureSection.imagePath = this.state.currentAdventureSectionImagePath;
        adventureSection.imageUrl = this.state.currentAdventureSectionImageUrl;

        let updatedStub = new AdventureSectionStub();
        updatedStub.title = this.state.currentAdventureSectionName;
        updatedStub.descriptionStub = this.state.currentAdventureSectionText.length > 30 ? this.state.currentAdventureSectionText.substring(0, 30) : this.state.currentAdventureSectionText;
        updatedStub.choices = this.state.currentAdventureSectionChoices;
        updatedStub.imageUrl = this.state.currentAdventureSectionImageUrl;
        this.adventureSections.set(this.state.adventureSectionId, updatedStub);

        const sectionRef = firebase.firestore().doc(`adventures/${this.state.adventureId}/adventureSections/${this.state.adventureSectionId}`);
        const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
        let batch = firebase.firestore().batch();
        try {
            batch.update(sectionRef, adventureSection.toFirebase());
            batch.update(adventureRef, { [`adventureSections.${this.state.adventureSectionId}`]: updatedStub.toFirebase() });
            await batch.commit();

            this.setState({
                adventureSection: adventureSection,
                editWarning: false,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async loadSection(adventureId: string, sectionId: string) {
        const sectionRef = firebase.firestore().doc(`adventures/${adventureId}/adventureSections/${sectionId}`);
        let adventureSectionSnapshot = await sectionRef.get();
        let adventureSection = AdventureAdventureSection.fromFirebase(adventureSectionSnapshot.data());
        this.setState({
            adventureSection: adventureSection,
            currentAdventureSectionName: adventureSection.name == null ? adventureSection.title : adventureSection.name,
            currentAdventureSectionTitle: adventureSection.title,
            currentAdventureSectionText: adventureSection.text,
            currentAdventureSectionChoices: adventureSection.choices != null ? adventureSection.choices : [],
            currentAdventureSectionInformationSectionLinks: adventureSection.informationLinkIds != null ? adventureSection.informationLinkIds : new Map(),
            currentAdventureSectionImageUrl: adventureSection.imageUrl,
            currentAdventureSectionImagePath: adventureSection.imagePath,
            adventureSchoolId: adventureSection.schoolId,
        });
    }

    async componentDidMount(): Promise<void> {
        if (this.state.adventureSectionId !== "-1") {
            await this.loadSection(this.state.adventureId, this.state.adventureSectionId);
        } else {
            let adventureSection = new AdventureAdventureSection();
            if (this.props.user.role !== USER_ROLE.admin && !this.props.user.roles.get(USER_ROLE.learningCreator)) {
                adventureSection.schoolId = this.props.user.schoolId;
            }

            let sectionId = uuidv4();
            const adventureSectionRef = firebase.firestore().doc(`adventures/${this.state.adventureId}/adventureSections/${sectionId}`);
            const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
            let batch = firebase.firestore().batch();
            try {
                batch.set(adventureSectionRef, adventureSection.toFirebase());
                let updatedStub = new AdventureSectionStub();
                batch.update(adventureRef, { [`adventureSections.${sectionId}`]: updatedStub.toFirebase() });

                await batch.commit();
                this.setState({
                    adventureSection: adventureSection,
                    adventureSectionId: sectionId,
                    editWarning: false,
                    currentAdventureSectionName: adventureSection.name,
                    currentAdventureSectionTitle: adventureSection.title,
                    currentAdventureSectionText: adventureSection.text,
                    currentAdventureSectionChoices: adventureSection.choices != null ? adventureSection.choices : [],
                    currentAdventureSectionInformationSectionLinks: adventureSection.informationLinkIds != null ? adventureSection.informationLinkIds : new Map(),
                    currentAdventureSectionImageUrl: adventureSection.imageUrl,
                    currentAdventureSectionImagePath: adventureSection.imagePath,
                    adventureSchoolId: adventureSection.schoolId,
                });
                this.props.history.replace(`/adventureSections/${sectionId}?${this.getQuerys()}`);
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        }
        const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
        let adventureSnapshot = await adventureRef.get();
        let adventure = Adventure.fromFirebase(adventureSnapshot.data());

        this.adventureSections = adventure.adventureSections != null ? adventure.adventureSections : new Map<string, AdventureSectionStub>();
        this.informationSections = adventure.informationSections != null ? adventure.informationSections : new Map<string, InformationSectionStub>();
        this.setState({});
    }
}

export default AdventureSectionView;
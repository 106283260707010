import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Spinner, Table, Modal, ModalHeader, ModalBody, UncontrolledTooltip, CardHeader, Collapse, CardBody, InputGroup,
    InputGroupAddon, Breadcrumb, BreadcrumbItem, Alert, Card
} from 'reactstrap';
import { Link } from "react-router-dom";
import EmojiSelection from "./ninja_components/emoji_selection";
import { Ticket, TICKET_TYPE } from './data/support_tickets';
import { User } from './data/user';
import { makeShortString } from './helpers/text_lib';

interface IState {
    tickets: Ticket[],
    createTicketModal: boolean;
    newTicketTitle: string;
    newTicketDescription: string;
    newTicketType: TICKET_TYPE,
    loading: boolean;
    creating: boolean;
    closedTickets: Ticket[],
}

interface IProps {
    user: User;
    snackbar: (text?: string) => void;
}

class TicketsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            tickets: [],
            createTicketModal: false,
            newTicketTitle: '',
            newTicketDescription: '',
            newTicketType: TICKET_TYPE.INCIDENT,
            loading: true,
            creating: false,
            closedTickets: [],
        };
        this.toggleCreateTicketModal = this.toggleCreateTicketModal.bind(this);
        this.handleCreateTicket = this.handleCreateTicket.bind(this);
    }

    render() {
        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem active>All tickets</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Support tickets</div>
                    <p className="cardSubTitle">All support tickets are shown here. If you have an issue, question or feature request please let us know.</p>
                    <div>
                        <Button className="adminPagesButton" onClick={this.toggleCreateTicketModal}>
                            Create support ticket
                        </Button>
                        <a target="_blank" href="https://docs.google.com/document/d/13MkAZ0MnfzOXExraLgXYhjgTqIKcnS7k8t8nDG57q00/edit#">
                            <Button style={{marginLeft:"15px"}} className="altButton">Read Tutorial</Button>
                        </a>
                    </div>
                    <br />
                    <Alert color="dark">
                        <h3>Open tickets</h3>
                    </Alert>
                    <Table>
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Type</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Last updated</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tickets.map((ticket) => {
                                let ticketId = ticket.id;
                                return <tr key={ticketId + 'tr'}>
                                    <th scope="row" key={ticketId + 'th'}><Link to={`support/${ticket.id}`}>{ticket.id}</Link></th>
                                    <td key={ticketId + 'tdType'}>{ticket.type === "Incident" ? "Issue" : ticket.type}</td>
                                    <td key={ticketId + 'td1'}>{ticket.subject}</td>
                                    <td key={ticketId + 'td2'}>{makeShortString(ticket.description_text, 60)}</td>
                                    <td key={ticketId + 'td3'}>{new Date(ticket.updated_at).toDateString()} {new Date(ticket.updated_at).toLocaleTimeString()}</td>
                                    <td key={ticketId + 'td5'}>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    {this.state.loading ? <Spinner size="l"></Spinner> : <span />}
                    <br />
                    <Alert color="dark">
                        <h3>Closed tickets</h3>
                    </Alert>
                    <Table>
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Type</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Last updated</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.closedTickets.map((ticket) => {
                                let ticketId = ticket.id;
                                return <tr key={ticketId + 'tr'}>
                                    <th scope="row" key={ticketId + 'th'}><Link to={`support/${ticket.id}`}>{ticket.id}</Link></th>
                                    <td key={ticketId + 'tdType'}>{ticket.type === "Incident" ? "Issue" : ticket.type}</td>
                                    <td key={ticketId + 'td1'}>{ticket.subject}</td>
                                    <td key={ticketId + 'td2'}>{makeShortString(ticket.description_text, 60)}</td>
                                    <td key={ticketId + 'td3'}>{new Date(ticket.updated_at).toDateString()} {new Date(ticket.updated_at).toLocaleTimeString()}</td>
                                    <td key={ticketId + 'td5'}>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    {this.state.loading ? <Spinner size="l"></Spinner> : <span />}
                    <Modal isOpen={this.state.createTicketModal} toggle={this.toggleCreateTicketModal}>
                        <ModalHeader toggle={this.toggleCreateTicketModal}>Create support ticket</ModalHeader>
                        <ModalBody>
                            <div className="border rounded form-margin">
                                <Form onSubmit={this.handleCreateTicket}>
                                    <FormGroup>
                                        <Label for="newTicketTitle">Title *</Label>
                                        <Input type="text" required name="newTicketTitle"
                                            onChange={(e) => this.setState({
                                                newTicketTitle: e.target.value
                                            })}
                                            value={this.state.newTicketTitle} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newTicketType">Type * <i className="fas fa-info-circle icons-info" id="help-tickets-type" /></Label>
                                        <Input type="select" required name="newTicketType" onChange={(e) => this.setState({
                                            newTicketType: TICKET_TYPE[e.target.value as keyof typeof TICKET_TYPE]
                                        })} value={this.state.newTicketType}>
                                            <option value={TICKET_TYPE.INCIDENT}>Issue</option>
                                            <option value={TICKET_TYPE.QUESTION}>Question</option>
                                            <option value={TICKET_TYPE.FEATURE}>New feature suggestion</option>
                                        </Input>
                                        <UncontrolledTooltip placement="bottom" autohide={false} target="help-tickets-type">
                                            Please let us know what type of support you require so we can correctly prioritise and manage it.<br />
                                    For anything that is not working correctly please specify 'Issue' and our tech team will investigate as a priority.
                                </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newTicketDescription">Description *</Label>
                                        <Input type="textarea" required name="newTicketDescription"
                                            onChange={(e) => this.setState({
                                                newTicketDescription: e.target.value
                                            })}
                                            value={this.state.newTicketDescription} />
                                    </FormGroup>
                                    <br />
                                    <Button disabled={this.state.creating} color="success">
                                        Create {this.state.creating ? <Spinner size="sm"></Spinner> : <span />}
                                    </Button>&nbsp;
                        </Form>
                            </div>
                        </ModalBody>
                    </Modal>
                </CardBody>
            </Card>
        </div>;
    }

    toggleCreateTicketModal() {
        this.setState({
            createTicketModal: !this.state.createTicketModal,
        });
    }

    async handleCreateTicket(e: React.FormEvent) {
        e.preventDefault(); // Prevents page refresh

        this.setState({
            creating: true,
        });

        try {
            let createTicket = firebase.functions().httpsCallable('createSupportTicket');
            let newTicketResponse = await createTicket({
                "schoolId": this.props.user.schoolId,
                "subject": this.state.newTicketTitle,
                "description": this.state.newTicketDescription,
                "requestorName": this.props.user.name,
                "ticketType": this.state.newTicketType,
            });

            let newTicket = newTicketResponse.data;
            this.state.tickets.push(newTicket);

            this.setState({
                createTicketModal: false,
                creating: false,
            });

            this.props.snackbar();
        } catch (error) {
            this.setState({
                creating: false,
            });
            console.log("Ticket creation failed", error);
            this.props.snackbar("Ticket creation failed");
        }
    }

    async componentDidMount() {
        try {
            let getTickets = firebase.functions().httpsCallable('getSupportTickets');
            let newTicketsResponse = await getTickets({ "schoolId": this.props.user.schoolId });
            let newTickets = newTicketsResponse.data;

            let filteredTickets: Ticket[] = [];
            let filteredClosedTickets: Ticket[] = [];
            newTickets['results'].forEach((nextTicketData: any) => {
                let nextTicket = Ticket.fromFirebase(nextTicketData);
                if (nextTicket.status === 2) {
                    filteredTickets.push(nextTicket);
                } else {
                    filteredClosedTickets.push(nextTicket);
                }
            });

            let newClosedTicketsResponse = await getTickets({ "schoolId": this.props.user.schoolId, "status": 5 });
            let newClosedTickets = newClosedTicketsResponse.data;
            Array.prototype.push.apply(filteredClosedTickets, newClosedTickets['results']);

            this.setState({
                tickets: filteredTickets,
                closedTickets: filteredClosedTickets,
                loading: false,
            });
        } catch (error) {
            console.log(error);
            this.props.snackbar("Failed to get support tickets");
        }
    }
}

export default TicketsView;
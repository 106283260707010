import React, {Component} from 'react';
import firebase from './firebase';
import {Table, Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Collapse} from 'reactstrap';
import Chart from "react-google-charts";
import * as moment from 'moment';
import DatePicker from "react-datepicker";
import {CSVLink} from "react-csv";

import "react-datepicker/dist/react-datepicker.css";
import TopicsSelector from "./ninja_components/topics_selector";

class ReportingView extends Component {

    constructor(props) {
        super();
        this.state = {
            user: props.user,
            dataLoaded: false,
            secondaryDataLoaded: false,
            showModal: true,
            currentReportAccountGroups: [],
            currentReportModuleGroups: [],
            currentReportDateStart: null,
            currentReportDateEnd: null,
            currentReportType: null,
            currentReportAcceptedModuleIds: [],
            newReportAccountGroups: [],
            newAccountIdList: [],
            newReportModuleGroups: [],
            newReportDateStart: null,
            newReportDateEnd: null,
            newReportType: null,
            newReportTopic: [],
            newReportTopicsTags: {},
            userOutcomes: {},
            modulesChanged: [],
            showTableDetails: false,
            tableDetails: {},
            availableAccountIds: [],
            accountIdList: [],
            moduleDetails: {},
            questionDetails: {},
            readingRecords: {},
            topicAnalysis: {},
            topic: null,
            topicTags: [],
        };
        this.moduleGroups = {};
        this.toggleModal = this.toggleModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleReportChange = this.handleReportChange.bind(this);
        this.handleAccountGroupChange = this.handleAccountGroupChange.bind(this);
        this.createEngagementReport = this.createEngagementReport.bind(this);
        this.createModulePassReport = this.createModulePassReport.bind(this);
        this.createModuleTimeReport = this.createModuleTimeReport.bind(this);
        this.createFailedQuestionsReport = this.createFailedQuestionsReport.bind(this);
        this.createAppDownloadsReport = this.createAppDownloadsReport.bind(this);
        this.handleModuleGroupChange = this.handleModuleGroupChange.bind(this);
        this.isModuleInGroups = this.isModuleInGroups.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.selectAllAccounts = this.selectAllAccounts.bind(this);
        this.loadOutcomes = this.loadOutcomes.bind(this);
        this.loadReadingRecords = this.loadReadingRecords.bind(this);
        this.createTopicAnalysisReport = this.createTopicAnalysisReport.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.loadTopicAnalysis = this.loadTopicAnalysis.bind(this);

        this.reportTypes = Object.freeze(
            {
                "engagement": "engagement",
                "modulePassFail": "modulePassFail",
                "moduleTime": "moduleTime",
                "failedQuestions": "failedQuestions",
                "appDownloads": "appDownloads",
                "allReadingByDay": "allReadingByDay",
                "topicAnalysis": "topicAnalysis",
            }
        );
    }

    render() {

        let report;
        if (!this.state.dataLoaded || this.state.currentReportType == null) {
            report = <br/>
        } else if (this.state.currentReportType === this.reportTypes.engagement) {
            report = this.createEngagementReport();
        } else if (this.state.currentReportType === this.reportTypes.modulePassFail) {
            report = this.createModulePassReport();
        } else if (this.state.currentReportType === this.reportTypes.moduleTime) {
            report = this.createModuleTimeReport();
        } else if (this.state.currentReportType === this.reportTypes.failedQuestions) {
            report = this.createFailedQuestionsReport();
        } else if (this.state.currentReportType === this.reportTypes.appDownloads) {
            report = this.createAppDownloadsReport();
        } else if (this.state.currentReportType === this.reportTypes.allReadingByDay) {
            report = this.createReadingReport();
        } else if (this.state.currentReportType === this.reportTypes.topicAnalysis) {
            report = this.createTopicAnalysisReport();
        }

        return (
            <div className='container'>
                    <div className="cardTitle">Reporting</div>
                    <p className="cardSubTitle">Reports use current <i>account group</i> membership. Reporting is against live data, so changes in data are made available immediately</p>

                {!this.state.dataLoaded ?
                    <Alert className="top-buffer" color="warning">
                        <h3>Loading data</h3>
                    </Alert>
                    :
                    <div>
                        <Button className="adminPagesButton" onClick={this.toggleModal}>Modify report</Button>

                        <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
                            <Form onSubmit={this.handleReportChange}>
                                <ModalHeader toggle={this.toggleModal}>Report settings</ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label for="newReportAccountGroups">Account groups *</Label>
                                        <Input type="select" required name="newReportAccountGroups" multiple onClick={this.handleAccountGroupChange} value={this.state.newReportAccountGroups}>
                                            {Object.keys(this.props.accountGroups).map((accountGroupId) => {
                                                return (
                                                    <option value={accountGroupId} key={accountGroupId}>{this.props.accountGroups[accountGroupId].details.name}</option>
                                                )
                                            })
                                            }
                                        </Input><br/>
                                    </FormGroup>
                                    <Collapse isOpen={!this.state.collapse}>
                                        <FormGroup>
                                            <Label for="newAccountIdList">Accounts * <Button type="button" onClick={this.selectAllAccounts} className="adminPagesButton">Select all</Button></Label>
                                            <Input type="select" required name="newAccountIdList" multiple onClick={this.handleAccountChange} value={this.state.newAccountIdList}>
                                                {this.state.availableAccountIds.map((accountId) => {
                                                    let account = this.props.accountsMinimised[accountId];
                                                    if (account == null) {
                                                        return null;
                                                    }
                                                    return (
                                                        <option value={accountId} key={accountId}>{account.name}</option>
                                                    )
                                                })
                                                }
                                            </Input><br/>
                                        </FormGroup>
                                    </Collapse>
                                    <FormGroup>
                                        <Label for="newReportType">Report type</Label>
                                        <Input type="select" required name="newReportType" onChange={this.handleChange} value={this.state.newReportType}>
                                            <option>&nbsp;</option>
                                            <option value={this.reportTypes.engagement}>Student engagement</option>
                                            <option value={this.reportTypes.modulePassFail}>Module pass/fail</option>
                                            <option value={this.reportTypes.moduleTime}>Module time</option>
                                            <option value={this.reportTypes.failedQuestions}>Incorrect questions</option>
                                            <option value={this.reportTypes.appDownloads}>App downloads</option>
                                            <option value={this.reportTypes.allReadingByDay}>Reading records</option>
                                            <option value={this.reportTypes.topicAnalysis}>Topic analysis</option>
                                        </Input>
                                    </FormGroup>
                                    {this.state.newReportType !== this.reportTypes.appDownloads &&
                                        this.state.newReportType !== this.reportTypes.topicAnalysis ?
                                        <div>
                                            <FormGroup>
                                                <Label for="newReportDateStart">Start date</Label><br/>
                                                <DatePicker
                                                    selected={this.state.newReportDateStart}
                                                    required
                                                    dateFormat="dd MMM yyyy"
                                                    maxDate={moment().add(-1, 'days').toDate()}
                                                    onChange={this.handleStartDateChange}
                                                    className="form-control"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newReportDateEnd">End date</Label><br/>
                                                <DatePicker
                                                    selected={this.state.newReportDateEnd}
                                                    required
                                                    dateFormat="dd MMM yyyy"
                                                    minDate={moment(this.state.newReportDateStart).add(1, 'days').toDate()}
                                                    maxDate={new Date()}
                                                    onChange={this.handleEndDateChange}
                                                    className="form-control"
                                                />
                                            </FormGroup></div> : <span/>
                                    }
                                    {this.state.newReportType === this.reportTypes.topicAnalysis ?
                                        <TopicsSelector subject={"maths"} currentTopics={this.state.newReportTopics} reporting={true} currentTopicsTags={this.state.newReportTopicsTags} callback={this.handleTopicsChange}/>
                                        : <span/>
                                    }
                                    {this.state.newReportType === this.reportTypes.engagement || this.state.newReportType === this.reportTypes.modulePassFail
                                    || this.state.newReportType === this.reportTypes.moduleTime || this.state.newReportType === this.reportTypes.failedQuestions ?
                                        <FormGroup>
                                            <Label for="newReportModuleGroups">School topics</Label>
                                            <Input type="select" name="newReportModuleGroups" onClick={this.handleModuleGroupChange} value={this.state.newReportModuleGroups} multiple>
                                                {Object.keys(this.moduleGroups).map((moduleGroupId) => {
                                                    return <option value={moduleGroupId} key={moduleGroupId}>{this.moduleGroups[moduleGroupId].name}</option>
                                                })}
                                            </Input>
                                        </FormGroup>
                                        : <span/>
                                    }
                                </ModalBody>
                                <ModalFooter>
                                    <Button className="adminPagesButton">Generate report</Button>{' '}
                                    <Button type="button" onClick={this.toggleModal} className="altButton">Cancel</Button>
                                </ModalFooter>
                            </Form>
                        </Modal>

                        {report}

                        {this.state.showTableDetails ?
                            <div>
                                <Alert color="dark">
                                    <h3>Details</h3>
                                </Alert>
                                <Table>
                                    <thead>
                                    <tr>
                                        {this.state.tableDetails.headers.map((header, index) => {
                                            return <th key={index + 'th'}>{header}</th>
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.tableDetails.data.map((dataRow, index) => {
                                        return <tr key={index + 'tr'}>
                                            {dataRow.map((dataEntry, subIndex) => {
                                                return <td key={index + 'td' + subIndex}>{dataEntry}</td>
                                            })}
                                        </tr>
                                    })}
                                    </tbody>
                                </Table>
                                <CSVLink data={[this.state.tableDetails.headers, ...this.state.tableDetails.data]}
                                         filename={"LifeNinja-Report.csv"}
                                         className="btn btn-primary"
                                         target="_blank">Download</CSVLink>
                            </div>
                            : ""}
                    </div>
                }
                <br/><br/>
            </div>
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleTopicsChange(topics, topicsTags) {
        this.setState({
            newReportTopics: topics,
            newReportTopicsTags: topicsTags,
        });
    }

    handleModuleGroupChange(e) {
        let index = this.state.newReportModuleGroups.indexOf(e.target.value);
        if (index > -1) {
            this.state.newReportModuleGroups.splice(index, 1);
        } else {
            this.state.newReportModuleGroups.push(e.target.value);
        }
        this.setState({});
    }

    handleStartDateChange(date) {
        if (date != null && (this.state.newReportDateEnd == null || date.getTime() > this.state.newReportDateEnd.getTime())) {
            this.setState({
                newReportDateStart: date,
                newReportDateEnd: moment(date).add('1', 'days').toDate(),
            });
        } else {
            this.setState({
                newReportDateStart: date,
            });
        }
    }

    handleEndDateChange(date) {
        this.setState({
            newReportDateEnd: date,
        });
    }

    createEngagementReport() {
        let dayBuckets = [];
        let endDate = this.state.currentReportDateEnd.clone().add(1, 'days');
        let diffDays = moment.duration(this.state.currentReportDateStart.diff(endDate)).asDays();

        for (let i = diffDays; i <= 0; i++) {
            dayBuckets.push([endDate.clone().add(i, 'days'), 0, {}]);
        }

        for (let nextOutcomePos in this.state.modulesChanged) {
            let nextOutcome = this.state.modulesChanged[nextOutcomePos];
            let outcomeDate = moment(nextOutcome.startDateTime);

            if (this.isModuleInGroups(nextOutcome.moduleId)) {
                for (let i = 0; i < dayBuckets.length; i++) {
                    let nextDayBucket = dayBuckets[i];
                    if (outcomeDate.isBefore(nextDayBucket[0])) {
                        if (i > 0) {
                            let currentDayBucket = dayBuckets[i - 1];
                            currentDayBucket[1]++;
                            if (currentDayBucket[2][nextOutcome.moduleId] == null) {
                                currentDayBucket[2][nextOutcome.moduleId] = {};
                            }
                            if (currentDayBucket[2][nextOutcome.moduleId][nextOutcome.accountId] == null) {
                                currentDayBucket[2][nextOutcome.moduleId][nextOutcome.accountId] = 0;
                            }
                            currentDayBucket[2][nextOutcome.moduleId][nextOutcome.accountId]++;
                        }
                        break;
                    }
                }
            }
        }
        // Remove last entry as it is just midnight
        dayBuckets.pop();

        let resultData = [["Day", "Modules attempted"]];
        for (let i = 0; i < dayBuckets.length; i++) {
            resultData.push([dayBuckets[i][0].toDate(), dayBuckets[i][1]]);
        }

        const chartEvents = [
            {
                eventName: "select",
                callback: async ({chartWrapper}) => {
                    if (chartWrapper.getChart().getSelection() == null || chartWrapper.getChart().getSelection().length === 0) {
                        this.setState({
                            showTableDetails: false,
                        });
                    } else {
                        let selection = chartWrapper.getChart().getSelection()[0];
                        let headers = ["Module", "Student", "Module count"];
                        let data = [];
                        let startRow = selection.row;
                        let rowCount = 1;
                        if (selection.row == null) {
                            startRow = 0;
                            rowCount = dayBuckets.length;
                        }

                        let moduleIdList = [];
                        let dataExplosion = {};
                        for (let bucketIndex = startRow; bucketIndex < startRow + rowCount; bucketIndex++) {
                            moduleIdList = moduleIdList.concat(Object.keys(dayBuckets[bucketIndex][2]));

                            for (let moduleId in dayBuckets[bucketIndex][2]) {
                                for (let nextAccountIndex in this.state.accountIdList) {
                                    let nextAccountId = this.state.accountIdList[nextAccountIndex];
                                    let moduleCount = dayBuckets[bucketIndex][2][moduleId][nextAccountId];
                                    if (moduleCount == null) {
                                        moduleCount = 0;
                                    }
                                    if (dataExplosion[moduleId] == null) {
                                        dataExplosion[moduleId] = {};
                                    }
                                    if (dataExplosion[moduleId][nextAccountId] == null) {
                                        dataExplosion[moduleId][nextAccountId] = 0;
                                    }
                                    dataExplosion[moduleId][nextAccountId] += moduleCount;

                                }
                            }
                        }
                        let allModulesAndQuestions = await this.loadNewModules(moduleIdList);
                        for (let moduleId in dataExplosion) {
                            for (let accountId in dataExplosion[moduleId]) {
                                data.push([allModulesAndQuestions.modules[moduleId].name, this.props.accountsMinimised[accountId] != null ? this.props.accountsMinimised[accountId].name : "Removed account", dataExplosion[moduleId][accountId]]);
                            }
                        }
                        this.setState({
                            showTableDetails: true,
                            tableDetails: {headers: headers, data: data},
                            moduleDetails: allModulesAndQuestions.modules,
                            questionDetails: allModulesAndQuestions.questions,
                        });
                    }
                }
            }
        ];

        return <Chart
            chartType="ColumnChart"
            chartEvents={chartEvents}
            width="100%"
            height="400px"
            data={resultData}
            options={{
                title: "Daily engagement",
                vAxis: {
                    gridlines: {
                        count: -1
                    },
                    minValue: 0
                },
                animation: {
                    startup: true,
                    easing: 'out',
                    duration: 500,
                },
            }}
        />;
    }

    createModulePassReport() {

        let dayBuckets = [];
        let endDate = this.state.currentReportDateEnd.clone().add(1, 'days');
        let diffDays = moment.duration(this.state.currentReportDateStart.diff(endDate)).asDays();

        for (let i = diffDays; i <= 0; i++) {
            dayBuckets.push([endDate.clone().add(i, 'days'), 0, 0, {}]);
        }

        for (let nextOutcomePos in this.state.modulesChanged) {
            let nextOutcome = this.state.modulesChanged[nextOutcomePos];
            let outcomeDate = moment(nextOutcome.startDateTime);

            if (this.isModuleInGroups(nextOutcome.moduleId)) {
                for (let i = 0; i < dayBuckets.length; i++) {
                    let nextDayBucket = dayBuckets[i];
                    if (outcomeDate.isBefore(nextDayBucket[0])) {
                        if (i > 0) {
                            let currentDayBucket = dayBuckets[i - 1];
                            if (currentDayBucket[3][nextOutcome.moduleId] == null) {
                                currentDayBucket[3][nextOutcome.moduleId] = {};
                            }
                            if (currentDayBucket[3][nextOutcome.moduleId][nextOutcome.accountId] == null) {
                                currentDayBucket[3][nextOutcome.moduleId][nextOutcome.accountId] = {passes: 0, fails: 0};
                            }
                            if (nextOutcome.outcome.passed) {
                                currentDayBucket[1]++;
                                currentDayBucket[3][nextOutcome.moduleId][nextOutcome.accountId].passes++;
                            } else {
                                currentDayBucket[2]++;
                                currentDayBucket[3][nextOutcome.moduleId][nextOutcome.accountId].fails++;
                            }

                        }
                        break;
                    }
                }
            }
        }
        // Remove last entry as it is just midnight
        dayBuckets.pop();

        let resultData = [["Day", "Passed", "Failed"]];
        for (let i = 0; i < dayBuckets.length; i++) {
            resultData.push([dayBuckets[i][0].toDate(), dayBuckets[i][1], dayBuckets[i][2]],);
        }

        const chartEvents = [
            {
                eventName: "select",
                callback: async ({chartWrapper}) => {
                    if (chartWrapper.getChart().getSelection() == null || chartWrapper.getChart().getSelection().length === 0) {
                        this.setState({
                            showTableDetails: false,
                        });
                    } else {
                        let selection = chartWrapper.getChart().getSelection()[0];
                        let headers = ["Module", "Student", "Pass", "Fail"];
                        let data = [];
                        let startRow = selection.row;
                        let rowCount = 1;
                        if (selection.row == null) {
                            startRow = 0;
                            rowCount = dayBuckets.length;
                        }

                        let moduleIdList = [];
                        let dataExplosion = {};
                        for (let bucketIndex = startRow; bucketIndex < startRow + rowCount; bucketIndex++) {
                            moduleIdList = moduleIdList.concat(Object.keys(dayBuckets[bucketIndex][3]));

                            for (let moduleId in dayBuckets[bucketIndex][3]) {
                                for (let nextAccountIndex in this.state.accountIdList) {
                                    let nextAccountId = this.state.accountIdList[nextAccountIndex];
                                    let accountEntry = dayBuckets[bucketIndex][3][moduleId][nextAccountId];
                                    let passes;
                                    let fails;
                                    if (accountEntry == null) {
                                        passes = 0;
                                        fails = 0;
                                    } else {
                                        passes = accountEntry.passes;
                                        fails = accountEntry.fails;
                                    }
                                    if (dataExplosion[moduleId] == null) {
                                        dataExplosion[moduleId] = {};
                                    }
                                    if (dataExplosion[moduleId][nextAccountId] == null) {
                                        dataExplosion[moduleId][nextAccountId] = {passes: 0, fails: 0};
                                    }
                                    dataExplosion[moduleId][nextAccountId].passes += passes;
                                    dataExplosion[moduleId][nextAccountId].fails += fails;
                                }
                            }
                        }
                        let allModulesAndQuestions = await this.loadNewModules(moduleIdList);
                        for (let moduleId in dataExplosion) {
                            for (let accountId in dataExplosion[moduleId]) {
                                data.push([allModulesAndQuestions.modules[moduleId].name, this.props.accountsMinimised[accountId] != null ? this.props.accountsMinimised[accountId].name : "Removed account", dataExplosion[moduleId][accountId].passes, dataExplosion[moduleId][accountId].fails]);
                            }
                        }

                        this.setState({
                            showTableDetails: true,
                            tableDetails: {headers: headers, data: data},
                            moduleDetails: allModulesAndQuestions.modules,
                            questionDetails: allModulesAndQuestions.questions,
                        });
                    }
                }
            }
        ];

        return <Chart
            chartType="ColumnChart"
            chartEvents={chartEvents}
            width="100%"
            height="400px"
            data={resultData}
            options={{
                isStacked: true,
                title: "Daily pass/fail",
                vAxis: {
                    gridlines: {
                        count: -1
                    },
                    minValue: 0
                },
                animation: {
                    startup: true,
                    easing: 'out',
                    duration: 500,
                },
            }}
        />;
    }

    isModuleInGroups(moduleId) {
        return this.state.currentReportAcceptedModuleIds.length === 0 || this.state.currentReportAcceptedModuleIds.includes(moduleId);
    }

    createModuleTimeReport() {
        let dayBuckets = [];
        let endDate = this.state.currentReportDateEnd.clone().add(1, 'days');
        let diffDays = moment.duration(this.state.currentReportDateStart.diff(endDate)).asDays();

        for (let i = diffDays; i <= 0; i++) {
            dayBuckets.push([endDate.clone().add(i, 'days'), 0, {}]);
        }

        for (let nextOutcomePos in this.state.modulesChanged) {
            let nextOutcome = this.state.modulesChanged[nextOutcomePos];
            if (this.isModuleInGroups(nextOutcome.moduleId)) {
                let outcomeDate = moment(nextOutcome.startDateTime);

                for (let i = 0; i < dayBuckets.length; i++) {
                    let nextDayBucket = dayBuckets[i];
                    if (outcomeDate.isBefore(nextDayBucket[0])) {
                        if (i > 0) {
                            let currentDayBucket = dayBuckets[i - 1];
                            let secondsAccessed = moment(nextOutcome.finishDateTime).diff(outcomeDate, 'seconds');
                            currentDayBucket[1] += secondsAccessed;
                            if (currentDayBucket[2][nextOutcome.moduleId] == null) {
                                currentDayBucket[2][nextOutcome.moduleId] = {};
                            }
                            if (currentDayBucket[2][nextOutcome.moduleId][nextOutcome.accountId] == null) {
                                currentDayBucket[2][nextOutcome.moduleId][nextOutcome.accountId] = 0;
                            }
                            currentDayBucket[2][nextOutcome.moduleId][nextOutcome.accountId] += secondsAccessed;
                        }
                        break;
                    }
                }
            }
        }
        // Remove last entry as it is just midnight
        dayBuckets.pop();

        let resultData = [["Day", "Time (seconds)"]];
        for (let i = 0; i < dayBuckets.length; i++) {
            resultData.push([dayBuckets[i][0].toDate(), dayBuckets[i][1]]);
        }

        const chartEvents = [
            {
                eventName: "select",
                callback: async ({chartWrapper}) => {
                    if (chartWrapper.getChart().getSelection() == null || chartWrapper.getChart().getSelection().length === 0) {
                        this.setState({
                            showTableDetails: false,
                        });
                    } else {
                        let selection = chartWrapper.getChart().getSelection()[0];
                        let headers = ["Module", "Student", "Time (seconds)"];
                        let data = [];
                        let startRow = selection.row;
                        let rowCount = 1;
                        if (selection.row == null) {
                            startRow = 0;
                            rowCount = dayBuckets.length;
                        }

                        let moduleIdList = [];
                        let dataExplosion = {};
                        for (let bucketIndex = startRow; bucketIndex < startRow + rowCount; bucketIndex++) {
                            moduleIdList = moduleIdList.concat(Object.keys(dayBuckets[bucketIndex][2]));

                            for (let moduleId in dayBuckets[bucketIndex][2]) {
                                for (let nextAccountIndex in this.state.accountIdList) {
                                    let nextAccountId = this.state.accountIdList[nextAccountIndex];
                                    let seconds = dayBuckets[bucketIndex][2][moduleId][nextAccountId];
                                    if (seconds == null) {
                                        seconds = 0;
                                    }
                                    if (dataExplosion[moduleId] == null) {
                                        dataExplosion[moduleId] = {};
                                    }
                                    if (dataExplosion[moduleId][nextAccountId] == null) {
                                        dataExplosion[moduleId][nextAccountId] = 0;
                                    }
                                    dataExplosion[moduleId][nextAccountId] += seconds;
                                }
                            }
                        }
                        let allModulesAndQuestions = await this.loadNewModules(moduleIdList);
                        for (let moduleId in dataExplosion) {
                            for (let accountId in dataExplosion[moduleId]) {
                                data.push([allModulesAndQuestions.modules[moduleId].name, this.props.accountsMinimised[accountId] != null ? this.props.accountsMinimised[accountId].name : "Removed account", dataExplosion[moduleId][accountId]]);
                            }
                        }
                        this.setState({
                            showTableDetails: true,
                            tableDetails: {headers: headers, data: data},
                            moduleDetails: allModulesAndQuestions.modules,
                            questionDetails: allModulesAndQuestions.questions,
                        });
                    }
                }
            }
        ];

        return <Chart
            chartType="ColumnChart"
            chartEvents={chartEvents}
            width="100%"
            height="400px"
            data={resultData}
            options={{
                title: "Daily time answering questions",
                vAxis: {
                    gridlines: {
                        count: -1
                    },
                    minValue: 0
                },
                animation: {
                    startup: true,
                    easing: 'out',
                    duration: 500,
                },
            }}
        />;
    }

    createReadingReport() {
        let dayBuckets = [];
        let endDate = this.state.currentReportDateEnd.clone().add(1, 'days');
        let diffDays = moment.duration(this.state.currentReportDateStart.diff(endDate)).asDays();

        for (let i = diffDays; i <= 0; i++) {
            dayBuckets.push([endDate.clone().add(i, 'days'), 0, {}]);
        }

        for (let nextReadingRecordId in this.state.readingRecords) {
            let nextReadingRecord = this.state.readingRecords[nextReadingRecordId];
            let readingDate = moment(nextReadingRecord.readingDateTime.toDate());

            for (let i = 0; i < dayBuckets.length; i++) {
                let nextDayBucket = dayBuckets[i];
                if (readingDate.isBefore(nextDayBucket[0])) {
                    if (i > 0) {
                        let currentDayBucket = dayBuckets[i - 1];
                        currentDayBucket[1]++;
                        if (currentDayBucket[2][nextReadingRecord.accountId] == null) {
                            currentDayBucket[2][nextReadingRecord.accountId] = {};
                        }
                        currentDayBucket[2][nextReadingRecord.accountId][nextReadingRecordId] = nextReadingRecord;
                    }
                    break;
                }
            }
        }
        // Remove last entry as it is just midnight
        dayBuckets.pop();

        let resultData = [["Day", "Number of reads"]];
        for (let i = 0; i < dayBuckets.length; i++) {
            resultData.push([dayBuckets[i][0].toDate(), dayBuckets[i][1]]);
        }

        const chartEvents = [
            {
                eventName: "select",
                callback: async ({chartWrapper}) => {
                    if (chartWrapper.getChart().getSelection() == null || chartWrapper.getChart().getSelection().length === 0) {
                        this.setState({
                            showTableDetails: false,
                        });
                    } else {
                        let selection = chartWrapper.getChart().getSelection()[0];
                        let headers = ["Student", "Total reading Count", "Home reading count"];
                        let data = [];
                        let startRow = selection.row;
                        let rowCount = 1;
                        if (selection.row == null) {
                            startRow = 0;
                            rowCount = dayBuckets.length;
                        }

                        let dataExplosion = {};
                        for (let bucketIndex = startRow; bucketIndex < startRow + rowCount; bucketIndex++) {

                            for (let nextAccountId of this.state.accountIdList) {
                                if (dataExplosion[nextAccountId] == null) {
                                    dataExplosion[nextAccountId] = {
                                        totalRead: 0,
                                        homeRead: 0,
                                    };
                                }
                                if (dayBuckets[bucketIndex][2][nextAccountId] != null) {
                                    for (let readingRecordId in dayBuckets[bucketIndex][2][nextAccountId]) {
                                        let readingRecord = dayBuckets[bucketIndex][2][nextAccountId][readingRecordId];
                                        dataExplosion[nextAccountId].totalRead++;
                                        if (readingRecord.parentReading != null && readingRecord.parentReading) {
                                            dataExplosion[nextAccountId].homeRead++;
                                        }
                                    }
                                }
                            }
                        }
                        for (let accountId in dataExplosion) {
                            data.push([this.props.accountsMinimised[accountId] != null ? this.props.accountsMinimised[accountId].name : "Removed account", dataExplosion[accountId].totalRead, dataExplosion[accountId].homeRead]);
                        }
                        this.setState({
                            showTableDetails: true,
                            tableDetails: {headers: headers, data: data},
                        });
                    }
                }
            }
        ];

        return <Chart
            chartType="ColumnChart"
            chartEvents={chartEvents}
            width="100%"
            height="400px"
            data={resultData}
            options={{
                title: "Reads per day",
                vAxis: {
                    gridlines: {
                        count: -1
                    },
                    minValue: 0
                },
                animation: {
                    startup: true,
                    easing: 'out',
                    duration: 500,
                },
            }}
        />;
    }

    createTopicAnalysisReport() {
        let tagIds;
        if (this.state.topicTags != null && this.state.topicTags.length > 0) {
            tagIds = this.state.topicTags;
        } else {
            tagIds = Object.keys(this.state.topic.tags);
        }
        let tagBuckets = [];
        let dataExplosion = {};

        for (let nextTagId of tagIds) {
            console.log("Next tag id", nextTagId);
            let topicAnalysis = this.state.topicAnalysis;
            let tagName = this.state.topic.tags[nextTagId];
            console.log("Tag name", tagName);
            let tagData = [tagName];
            dataExplosion[nextTagId] = {};

            let completedCount = 0;
            let notCompletedCount = 0;
            let secureCount = 0;
            for (let i = 0; i < this.state.accountIdList.length; i++) {
                let accountId = this.state.accountIdList[i];

                let secure = false;
                let completed = false;
                if (topicAnalysis[accountId] != null && topicAnalysis[accountId][nextTagId] != null &&
                    topicAnalysis[accountId][nextTagId].hist != null && topicAnalysis[accountId][nextTagId].hist.length > 0) {
                    completed = true;
                    let flawlessCount = 0;
                    topicAnalysis[accountId][nextTagId].hist.forEach((outcomeDeets) => {
                        if (outcomeDeets.sc) {
                            flawlessCount++
                        }
                    });
                    if (flawlessCount > 4) {
                        secure = true;
                    }
                } else {
                    notCompletedCount++;
                }
                if (secure) {
                    secureCount++;
                }
                if (completed) {
                    completedCount++;
                }

                dataExplosion[nextTagId][accountId] = {secure: secure, completed: completed};
            }
            tagData.push(notCompletedCount);
            tagData.push(completedCount);
            tagData.push(secureCount);
            tagData.push(nextTagId);
            tagBuckets.push(tagData);
        }

        let resultData = [["Tag", "No attempt", "Completed", "Secure"]];
        for (let i = 0; i < tagBuckets.length; i++) {
            resultData.push([tagBuckets[i][0], tagBuckets[i][1], tagBuckets[i][2], tagBuckets[i][3]]);
        }

        const chartEvents = [
            {
                eventName: "select",
                callback: async ({chartWrapper}) => {
                    if (chartWrapper.getChart().getSelection() == null || chartWrapper.getChart().getSelection().length === 0) {
                        this.setState({
                            showTableDetails: false,
                        });
                    } else {
                        let selection = chartWrapper.getChart().getSelection()[0];
                        let headers = ["Tag", "Student", "Completed", "Secure"];
                        let data = [];
                        let startRow = selection.row;
                        let rowCount = 1;
                        if (selection.row == null) {
                            startRow = 0;
                            rowCount = tagBuckets.length;
                        }
                        for (let bucketIndex = startRow; bucketIndex < startRow + rowCount; bucketIndex++) {
                            let nextTagId = tagBuckets[bucketIndex][4];
                            let nextTagName = tagBuckets[bucketIndex][0];
                            for (let accountId in dataExplosion[nextTagId]) {
                                data.push([nextTagName, this.props.accountsMinimised[accountId] != null ? this.props.accountsMinimised[accountId].name : "Removed account", dataExplosion[nextTagId][accountId].completed ? "Yes" : "No", dataExplosion[nextTagId][accountId].secure ? "Yes" : "No"]);
                            }
                        }

                        this.setState({
                            showTableDetails: true,
                            tableDetails: {headers: headers, data: data},
                        });
                    }
                }
            }
        ];

        return <Chart
            chartType="ColumnChart"
            chartEvents={chartEvents}
            width="100%"
            height="600px"
            data={resultData}
            id={"report-div"}
            options={{
                title: this.state.topic.name,
                vAxis: {
                    title: "Number of students",
                    gridlines: {
                        count: -1
                    },
                    minValue: 0
                },
                chartArea: {
                    height: 300
                },
                animation: {
                    startup: true,
                    easing: 'out',
                    duration: 500,
                },
            }}
        />;
    }

    async loadNewModules(moduleIdList) {
        let promises = [];

        for (let moduleIndex in moduleIdList) {
            let moduleId = moduleIdList[moduleIndex];
            if (this.state.moduleDetails[moduleId] == null) {
                const moduleRef = firebase.database().ref('moduleInformation/modules/' + moduleId);
                promises.push(moduleRef.once('value'));
            }
        }

        let results = await Promise.all(promises);

        let newModules = {};
        let newQuestions = {};
        for (let i = 0; i < results.length; i++) {
            let moduleSnapshot = results[i];
            let module = moduleSnapshot.val();
            newModules[moduleSnapshot.key] = module;
            if (module.questions != null) {
                for (let questionIndex in module.questions) {
                    let question = module.questions[questionIndex];
                    newQuestions[question.id] = question;
                }
            }
        }
        return {
            modules: Object.assign({}, this.state.moduleDetails, newModules),
            questions: Object.assign({}, this.state.questionDetails, newQuestions)
        };
    }

    createFailedQuestionsReport() {
        let questionBuckets = {};
        let startDate = this.state.currentReportDateStart;
        let endDate = this.state.currentReportDateEnd.clone().add(1, 'days');
        let moduleIdList = {};

        for (let nextOutcomePos in this.state.modulesChanged) {
            let nextOutcome = this.state.modulesChanged[nextOutcomePos];
            if (this.isModuleInGroups(nextOutcome.moduleId)) {
                let outcomeDate = moment(nextOutcome.startDateTime);

                if (outcomeDate.isAfter(startDate) && outcomeDate.isBefore(endDate)) {
                    for (let nextQuestionId in nextOutcome.outcome.answers) {
                        let nextAnswer = nextOutcome.answers[nextQuestionId];
                        if (!nextAnswer.correct) {
                            if (!questionBuckets[nextQuestionId]) {
                                questionBuckets[nextQuestionId] = {count: 0, questionId: nextQuestionId, moduleId: nextOutcome.moduleId};
                                moduleIdList[nextOutcome.moduleId] = true;
                            }
                            questionBuckets[nextQuestionId].count++;
                        }
                    }
                }
            }
        }

        let resultData = [];
        for (let questionId in Object.keys(questionBuckets)) {
            resultData.push(questionBuckets[Object.keys(questionBuckets)[questionId]]);
        }
        resultData.sort((a, b) => a.count !== b.count ? b.count - a.count : a.questionId.localeCompare(b.questionId));

        if (!this.state.secondaryDataLoaded) {
            this.loadNewModules(Object.keys(moduleIdList)).then((allModulesAndQuestions) => {
                let data = resultData.map((dataArray) => {
                    let moduleEntry = allModulesAndQuestions.modules[dataArray.moduleId];
                    let questionEntry = allModulesAndQuestions.questions[dataArray.questionId];
                    return [questionEntry.text, questionEntry.answers.join(','), moduleEntry.name, dataArray.count];
                });
                this.setState({
                    moduleDetails: allModulesAndQuestions.modules,
                    questionDetails: allModulesAndQuestions.questions,
                    secondaryDataLoaded: true,
                    showTableDetails: true,
                    tableDetails: {headers: ["Question", "Answer", "Module", "Incorrect count"], data: data},
                });
            });
            return <br/>;
        }
        return <br/>
    }

    createAppDownloadsReport() {
        let resultData = [];
        let accountIdsFound = {};

        for (let accountId in this.state.userOutcomes) {
            resultData.push({
                name: this.props.accountsMinimised[accountId] != null ? this.props.accountsMinimised[accountId].name : "Removed account",
                downloaded: true,
            });
            accountIdsFound[accountId] = true;
        }

        for (let accountIdListPos in this.state.accountIdList) {
            let accountId = this.state.accountIdList[accountIdListPos];
            if (!accountIdsFound[accountId]) {
                resultData.push({
                    name: this.props.accountsMinimised[accountId] != null ? this.props.accountsMinimised[accountId].name : "Removed account",
                    downloaded: false,
                });
            }
        }

        // resultData.sort((a,b) => a.count !== b.count ? b.count - a.count : a.questionId.localeCompare(b.questionId));

        if (!this.state.secondaryDataLoaded) {
            let data = resultData.map((dataArray) => {
                return [dataArray.name, dataArray.downloaded ? "Yes" : "No"];
            });
            this.setState({
                secondaryDataLoaded: true,
                showTableDetails: true,
                tableDetails: {headers: ["Name", "Downloaded"], data: data},
            });
            return <br/>;
        }
        return <br/>
    }

    handleAccountChange(e) {
        let index = this.state.newAccountIdList.indexOf(e.target.value);
        if (index > -1) {
            this.state.newAccountIdList.splice(index, 1);
        } else {
            this.state.newAccountIdList.push(e.target.value);
        }
        this.setState({});
    }

    handleAccountGroupChange(e) {
        let index = this.state.newReportAccountGroups.indexOf(e.target.value);
        if (index > -1) {
            this.state.newReportAccountGroups.splice(index, 1);
        } else {
            this.state.newReportAccountGroups.push(e.target.value);
        }
        let accountIdList = [];
        for (let i = 0; i < this.state.newReportAccountGroups.length; i++) {
            let nextAccountGroupId = this.state.newReportAccountGroups[i];
            let nextAccountGroup = this.props.accountGroups[nextAccountGroupId];
            if (nextAccountGroup != null) {
                for (let j = 0; j < Object.keys(nextAccountGroup.accounts).length; j++) {
                    let nextAccountId = Object.keys(nextAccountGroup.accounts)[j];
                    let nextAccountDeets = nextAccountGroup.accounts[nextAccountId];
                    if (nextAccountDeets.member != null && nextAccountDeets.member &&
                        accountIdList.indexOf(nextAccountId) === -1 && this.props.accountsMinimised[nextAccountId] != null) {
                        accountIdList.push(nextAccountId);
                    }
                }
            }
        }
        this.setState({
            availableAccountIds: accountIdList,
            accountIdList: [],
        });
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    async handleReportChange(e) {
        e.preventDefault();

        let acceptedModuleIds = [];
        for (let moduleGroupIndex in this.state.newReportModuleGroups) {
            let moduleGroupId = this.state.newReportModuleGroups[moduleGroupIndex];
            let nextModuleGroup = this.moduleGroups[moduleGroupId];
            acceptedModuleIds = acceptedModuleIds.concat(nextModuleGroup.modules);
        }

        let reportStart = moment(this.state.newReportDateStart).startOf('day');
        let reportEnd = moment(this.state.newReportDateEnd).endOf('day');
        let accountIdList = this.state.newAccountIdList;
        let topics = this.state.newReportTopics;
        let topicsTags = this.state.newReportTopicsTags;

        this.setState({
            dataLoaded: false,
            secondaryDataLoaded: false,
            showModal: false,
            currentReportAccountGroups: this.state.newReportAccountGroups,
            currentReportType: this.state.newReportType,
            currentReportDateStart: reportStart,
            currentReportDateEnd: reportEnd,
            accountIdList: this.state.newAccountIdList,
            showTableDetails: false,
            currentReportModuleGroups: this.state.newReportModuleGroups,
            currentReportAcceptedModuleIds: acceptedModuleIds,
        });

        if (this.state.newReportType === this.reportTypes.engagement
            || this.state.newReportType === this.reportTypes.modulePassFail
            || this.state.newReportType === this.reportTypes.moduleTime
            || this.state.newReportType === this.reportTypes.failedQuestions
            || this.state.newReportType === this.reportTypes.appDownloads) {
            this.loadOutcomes(reportStart, reportEnd, accountIdList);
        } else if (this.state.newReportType === this.reportTypes.allReadingByDay) {
            this.loadReadingRecords(reportStart, reportEnd, accountIdList);
        } else if (this.state.newReportType === this.reportTypes.topicAnalysis) {
            this.loadTopicAnalysis(topics[0], topicsTags[topics[0]], accountIdList);
        }
    }

    async loadOutcomes(reportStart, reportEnd, accountIdList) {
        let userOutcomesPromiseList = [];
        for (let i = 0; i < accountIdList.length; i++) {
            let nextAccountId = accountIdList[i];
            userOutcomesPromiseList.push(
                firebase.firestore().collection(`moduleOutcomes`)
                    .where('accountId', '==', nextAccountId)
                    .where('finishTimestamp', '>=', reportStart.valueOf())
                    .where('finishTimestamp', '<=', reportEnd.valueOf())
                    .get()
            );
        }

        try {
            let snapshots = await Promise.all(userOutcomesPromiseList);
            let newOutcomes = [];
            let userOutcomes = {};
            for (let i = 0; i < snapshots.length; i++) {
                let nextSnapshot = snapshots[i];

                nextSnapshot.forEach(function (doc) {
                    let outcome = doc.data();
                    if (userOutcomes[outcome.accountId] == null) {
                        userOutcomes[outcome.accountId] = [];
                    }
                    newOutcomes.push(outcome);
                    userOutcomes[outcome.accountId].push(outcome);
                });
            }

            // let modulesChanged = this.getModulesChanged(newOutcomes);

            this.setState({
                dataLoaded: true,
                userOutcomes: userOutcomes,
                modulesChanged: newOutcomes,
            });
        } catch (error) {
            console.log(error);
            this.props.snackbar("Load data failed");
        }
    }

    async loadReadingRecords(reportStart, reportEnd, accountIdList) {
        let startDate = moment(reportStart);
        let endDate = moment(reportEnd);
        let startMonth = startDate.month();
        if (startMonth < 10) startMonth = "0"+startMonth;
        let startDay = startDate.date();
        if (startDay < 10) startDay = "0"+startDay;
        let startString = `${startDate.year()}-${startMonth}-${startDay}`;
        let endMonth = endDate.month();
        if (endMonth < 10) endMonth = "0"+endMonth;
        let endDay = endDate.date();
        if (endDay < 10) endDay = "0"+endDay;
        let endString = `${endDate.year()}-${endMonth}-${endDay}`;
        let snapshot = await firebase.firestore().collection(`groupedReadingRecords`)
            .where('day', '>=', startString)
            .where('day', '<=', endString)
            .where('schoolId', '==', this.state.user.schoolId)
            .get();

        try {
            let readingRecords = {};
            if (snapshot != null) {
                snapshot.forEach((doc) => {
                    let readGroupedRecords = doc.data();
                    if (readGroupedRecords != null && readGroupedRecords.records != null) {
                        Object.keys(readGroupedRecords.records).forEach((readingRecordId) => {
                            let readingRecord = readGroupedRecords.records[readingRecordId];
                            if (accountIdList.includes(readingRecord.accountId)) {
                                let readingDate = moment(readingRecord.readingDateTime.toDate());
                                if (readingDate.isAfter(startDate) && readingDate.isBefore(endDate)) {
                                    readingRecords[readingRecordId] = readingRecord;
                                }
                            }
                        });
                    }
                });
            }

            // let modulesChanged = this.getModulesChanged(newOutcomes);

            this.setState({
                dataLoaded: true,
                readingRecords: readingRecords,
            });
        } catch (error) {
            console.log(error);
            this.props.snackbar("Load data failed");
        }
    }

    async loadTopicAnalysis(topicId, topicTags, accountIdList) {
        const topicsRef = firebase.firestore().collection(`learningTopics`).where('subjectId', '==', "maths");
        let topicSnapshot = await topicsRef.get();
        let topic;
        topicSnapshot.forEach((docSnapshot) => {
            let nextTopics = docSnapshot.data();
            if (nextTopics.topics[topicId] != null) {
                topic = nextTopics.topics[topicId];
            }
        });

        let promises = [];
        for(let accountId of accountIdList) {
            console.log("Looking for accountId", accountId);
            const topicOverviewRef = firebase.firestore().doc(`studentTopicOverviews/maths--${accountId}`);
            promises.push(topicOverviewRef.get());
        }
        let topicAnalysis = {};
        let results = await Promise.all(promises);
        results.forEach((topicOverviewSnapshot) => {
            if (topicOverviewSnapshot.exists) {
                let topicOverview = topicOverviewSnapshot.data();
                if (topicOverview != null && topicOverview.topics != null &&
                    topicOverview.topics[topicId] != null) {
                    topicAnalysis[topicOverview.accountId] = topicOverview.topics[topicId];
                } else {
                    topicAnalysis[topicOverview.accountId] = {};
                }
            }
        });

        this.setState({
            dataLoaded: true,
            topic: topic,
            topicTags: topicTags,
            topicAnalysis: topicAnalysis,
        });
    }

    selectAllAccounts() {
        this.setState({
            newAccountIdList: this.state.availableAccountIds.slice(),
        })
    }

    async componentDidMount() {
        try {

            // const moduleGroupsRef = firebase.database().ref('moduleInformation/groups/');
            // snapshot = await moduleGroupsRef.once('value');
            // this.moduleGroups = snapshot.val();

            let moduleGroupIds = [];

            const schoolModuleStreamRef = firebase.database().ref('moduleInformation/streams').orderByChild("schoolId").equalTo(this.state.user.schoolId);
            let schoolModuleStreamSnapshot = await schoolModuleStreamRef.once('value');
            let schoolModuleStreams = schoolModuleStreamSnapshot.val();
            for (let moduleStreamId in schoolModuleStreams) {
                moduleGroupIds = moduleGroupIds.concat(schoolModuleStreams[moduleStreamId].moduleGroups);
            }

            let promises = [];

            for (let i = 0; i < moduleGroupIds.length; i++) {
                const moduleGroupRef = firebase.database().ref('moduleInformation/groups/' + moduleGroupIds[i]);
                promises.push(moduleGroupRef.once('value'));
            }

            let results = await Promise.all(promises);

            for (let i = 0; i < results.length; i++) {
                let moduleGroupSnapshot = results[i];
                this.moduleGroups[moduleGroupSnapshot.key] = moduleGroupSnapshot.val();
            }

            moduleGroupIds = [];

            const subscriptionModuleStreamRef = firebase.database().ref('moduleInformation/streams').orderByChild("subscriptionGroupId").equalTo("lifeninja-englit");
            let subscriptionModuleStreamSnapshot = await subscriptionModuleStreamRef.once('value');
            let subscriptionModuleStreams = subscriptionModuleStreamSnapshot.val();
            for (let moduleStreamId in subscriptionModuleStreams) {
                moduleGroupIds = moduleGroupIds.concat(subscriptionModuleStreams[moduleStreamId].moduleGroups);
            }

            promises = [];

            for (let i = 0; i < moduleGroupIds.length; i++) {
                const moduleGroupRef = firebase.database().ref('moduleInformation/groups/' + moduleGroupIds[i]);
                promises.push(moduleGroupRef.once('value'));
            }

            results = await Promise.all(promises);

            for (let i = 0; i < results.length; i++) {
                let moduleGroupSnapshot = results[i];
                this.moduleGroups[moduleGroupSnapshot.key] = moduleGroupSnapshot.val();
            }

            this.setState({
                dataLoaded: true,
                newReportDateStart: moment().endOf('day').add(-7, 'days').toDate(),
                newReportDateEnd: moment().endOf('day').toDate(),
            });
        } catch (error) {
            console.log(error)
        }
    }
}

export default ReportingView;
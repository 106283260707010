export const worldImages: Map<string, string> = new Map([
    ["worlds/addlantis.png", "Addlantis"],
    ["worlds/multivious.png", "Multivious"],
    ["worlds/subterra.png", "Subterra"],
    ["worlds/theDivide.png", "The Divide"],
    ["worlds/Other1.png", "Purple sweet"],
    ["worlds/Other2.png", "Hamburger"],
    ["worlds/Other3.png", "Gloop"],
    ["worlds/Other4.png", "Spike"],
    ["worlds/Other5.png", "Slime"],
    ["worlds/castle_world.png", "Story world - Castle"],
    ["worlds/pirate_world.png", "Story world - Pirates"],
    ["worlds/haunted_world.png", "Story world - Spooky"],
    ["worlds/jungle_world.png", "Story world - Jungle"],
]);
export const subjectIds: Map<string, string> = new Map([
    ['art-design', "Art and design"],
    ["citizenship", "Citizenship"],
    ["computing", "Computing"],
    ["design-technology", "Design and technology"],
    ["english-primary", "English (Primary years)"],
    ['english-language', "English Language (GCSE)"],
    ['english-literature', "English Literature (GCSE)"],
    ["foreign-languages", "Foreign languages"],
    ["geography", "Geography"],
    ["history", "History"],
    ["maths", "Maths (Primary years)"],
    ["maths-secondary", "Maths (Secondary years)"],
    ["music", "Music"],
    ["pshe", "Personal, social and health education (PSHE)"],
    ["phys-ed", "Physical education"],
    ["science-primary", "Science (Primary years)"],
    ["science-secondary", "Science (Secondary years)"],
    ['other', "Other"],
]);

export const soundNames: Map<string, string> = new Map([
    ["quiz_standard.m4a", "Generic introduction to quizzes"],
    ["circle_add120.m4a", "Tap numbers adding to 120"],
    ["Number_Shoot_Standard.m4a", "Create the numbers shown on the asteroid"],
    ["Number_Grid_Standard.m4a", "Swipe the numbers to create a number sentence"],
    ["Game_6_.m4a", "Tap two bonds that make the number in the middle"],
    ["Game_7_.m4a", "Slice two bonds that make 10"],
    ["Game_10.m4a", "Slice two numbers that make 20"],
    ["Game_13.m4a", "Slice two numbers that make 8"],
    ["Game_14.m4a", "Tap the words which represents numbers lower than 5"],
    ["Game_18.m4a", "Slice the numbers that represent: 4, 7 & 10"],
    ["Game_19.m4a", "Tap the number of the word shown on the asteroid"],
    ["Game_23.m4a", "Slice two numbers that make 11"],
    ["Ad_Game_6.m4a", "Slice two numbers that added together make 8"],
    ["Ad_Game_9.m4a", "Slice the correct number bonds that make 10"],
    ["Ad_Game_13.m4a", "Add two numbers that make 50"],
    ["Ad_Game_15.m4a", "Add two bonds that make 15"],
    ["Ad_Game_17.m4a", "Tap the words which represent numbers lower than 7"],
    ["Ad_Game_20.m4a", "Slice the numbers that represent: 3, 4 & 11"],
    ["Ad_Game_24.m4a", "Slice two numbers that added together make 16"],
    ["Ad2_Game_6.m4a", "Slice two numbers that added together make 27"],
    ["Ad2_Game_12.m4a", "Tap the words which represent numbers greater than 10"],
    ["Ad2_Game_14.m4a", "Slice two bonds of the number 20"],
    ["Ad2_Game_19.m4a", "Slice the numbers that represent: 12, 100 & 18"],
    ["Ad4_Game_3.m4a", "Tap two boxes which added together equal 30"],
    ["Ad4_Game_6.m4a", "Slice two numbers that added together make 52"],
    ["Ad4_Game_9.m4a", "Tap the box which correctly spells the number 63"],
    ["Ad4_Game_11.m4a", "Add two numbers that make 56"],
    ["Ad4_Game_15.m4a", "Tap the words which correctly spell: 73, 14 & 97"],
    ["Ad4_Game_17.m4a", "Slice two bonds that added together make 38"],
    ["Ad4_Game_19.m4a", "Tap the numbers which represent: 18, 27 & 12"],
    ["Ad4_Game_23.m4a", "Slice the following numbers: 12, 100 & 18"],
    ["Ad5_Game_4.m4a", "Tap the box which correctly spells the number 81"],
    ["Ad5_Game_5.m4a", "Slice two numbers that added together make 60"],
    ["Ad5_Game_8.m4a", "Swipe the number patterns found in the three times table"],
    ["Ad5_Game_11.m4a", "Slice two numbers that added together make 34"],
    ["Ad5_Game_13.m4a", "Add two numbers which equal 19"],
    ["Ad5_Game_17.m4a", "Tap the numbers that represent the following: 24, 17 & 100"],
    ["Ad5_Game_19.m4a", "Slice two bonds that added together make 50"],
    ["Ad5_Game_21.m4a", "Tap the boxes which correctly spell the numbers: 18, 4 & 40"],
    ["Ad6_Game_2.m4a", "Tap the numbers which represent: 16, 90 & 30"],
    ["Ad6_Game_4.m4a", "Tap two numbers which added together equal 63"],
    ["Ad6_Game_6.m4a", "Swipe the pattern of the 5 times table"],
    ["Ad6_Game_8.m4a", "Slice two numbers that added together make 60"],
    ["Ad6_Game_16.m4a", "Tap the numbers which represent: 20, 2 & 13"],
    ["Ad6_Game_18.m4a", "Tap two boxes which added together equal 100"],
    ["Ad6_Game_20.m4a", "Slice two number bonds which added together equal 102"],
    ["Ad6_Game_23.m4a", "Tap the box which correctly spells: 99"],
    ["Ad6_Game_25.m4a", "Slice two numbers that added together make 30"],
    ["Mul1_Game_1.m4a", "Tap the three highest numbers"],
    ["Mul1_Game_2.m4a", "Slice the highest number"],
    ["Mul1_Game_4.m4a", "Tap two bonds to create the number shown on the asteroid"],
    ["Mul1_Game_6.m4a", "Slice the numbers which have a value of 10's"],
    ["Mul1_Game_8.m4a", "Tap two number bonds for the number 10"],
    ["Mul1_Game_12.m4a", "Slice the smallest number"],
    ["Mul1_Game_13.m4a", "Slice the biggest number"],
    ["Mul1_Game_17.m4a", "Tap two number bonds for 15"],
    ["Mul1_Game_18.m4a", "Tap the numbers between 3 & 6"],
    ["Mul1_Game_22.m4a", "Tap the number which is smaller than the asteroid"],
    ["Mul2_Game-2.m4a", "Tap the largest number"],
    ["Mul2_Game_6.m4a", "Slice the numbers which have a value of 10 or 10's"],
    ["Mul2_Game_8.m4a", "Tap two number bonds that equal 20"],
    ["Mul2_Game_11.m4a", "Tap the number which comes after the asteroid"],
    ["Mul2_Game_12.m4a", "Slice the numbers which have a value of ones"],
    ["Mul2_Game_14.m4a", "Tap the numbers which have a value of 100's"],
    ["Mul2_Game_15.m4a", "Tap the greatest number"],
    ["Mul2_Game_20.m4a", "Tap the number which is bigger than the asteroid"],
    ["Mul2_Game_24.m4a", "Swipe and create a two times table number sentence"],
    ["Mul2_Game_25.m4a", "Slice the numbers found in the five times tables"],
    ["Mul3_Game_5.m4a", "Slice all ten's and/or ones"],
    ["Mul3_Game_7.m4a", "Swipe the numbers to create parts of the three times tables"],
    ["Mul3_Game_8.m4a", "Slice the numbers that have a value of 10"],
    ["Mul3_Game_15.m4a", "Slice the lowest number"],
    ["Mul3_Game_19.m4a", "Tap the three largest numbers"],
    ["Mul4_Game_6.m4a", "Tap the numbers which have a value of 10's"],
    ["Mul4_Game_8.m4a", "Swipe the numbers and create parts of the five times tables"],
    ["Mul4_Game_9.m4a", "Tap the tally which represents 4"],
    ["Mul4_Game_10.m4a", "Slice the numbers which have a value of 100's"],
    ["Mul4_Game_12.m4a", "Create parts of the 5 times tables"],
    ["Mul4_Game_23.m4a", "Tap the tally which represents 2"],
    ["Mul4_Game_24.m4a", "Tap two tally's which combined equal 4"],
    ["Mul4_Game_25.m4a", "Slice the numbers from the two times tables"],
    ["Mul5_Game_2.m4a", "Slice the greatest number"],
    ["Mul5_Game_4.m4a", "Create parts of the 10 times tables"],
    ["Mul5_Game_6.m4a", "Slice two numbers which multiplied together equal 30"],
    ["Mul5_Game_9.m4a", "Tap the words that represent values of 10's"],
    ["Mul5_Game_24.m4a", "Tap the box which shows a tally of 3"],
    ["Mul6_Game_2.m4a", "Slice the numbers which have a value of 1000's"],
    ["Mul6_Game_4.m4a", "Slice two numbers that multiplied together make 20"],
    ["Mul6_Game_9.m4a", "Tap the box that represents a value of 100's"],
    ["Mul6_Game_10.m4a", "Tap the box that represents a value of 1000's"],
    ["Sub1_Game_2.m4a", "Slice the numbers which are odd"],
    ["Sub1_Game_3.m4a", "Tap the numbers which are even"],
    ["Sub1_Game_4.m4a", "Tap the numbers which are odd"],
    ["Sub1_Game_6.m4a", "Subtract two bonds that make the number in the middle"],
    ["Sub1_Game_11.m4a", "Slice the numbers which are even"],
    ["Sub1_Game_14.m4a", "Slice the highest odd number"],
    ["Sub1_Game_16.m4a", "Tap the words which represent odd numbers"],
    ["Sub1_Game_19.m4a", "Create the total amount shown on the asteroid (i.e. 30p + 70p = £1)"],
    ["Sub1_Game_20.m4a", "Subtract two bonds to create the number shown on the asteroid"],
    ["Sub1_Game_21.m4a", "Tap the words which represent even numbers"],
    ["Sub1_Game_25.m4a", "Subtract two bonds that make 10"],
    ["Sub2_Game_3.m4a", "Swipe the numbers to create a subtraction number sentece (i.e. 16 - 6 = 10)"],
    ["Sub2_Game_11.m4a", "Slice the numbers greater than 10"],
    ["Sub2_Game_20.m4a", "Tap the symbols of the asteroid"],
    ["Sub2_Game_22.m4a", "Tap the box which represents £1"],
    ["Sub2_Game_23.m4a", "Subtract two bonds that make 6"],
    ["Sub2_Game_25.m4a", "Slice the numbers smaller than 7"],
    ["Sub3_Game_3.m4a", "Subtract two numbers to create a number sentence"],
    ["Sub3_Game_6.m4a", "Tap the boxes which show even numbers"],
    ["Sub3_Game_12.m4a", "Slice the symbols used for: Subtraction, Addition & Equals"],
    ["Sub3_Game_16.m4a", "Tap the numbers greater than the asteroid"],
    ["Sub3_Game_23.m4a", "Slice the correct addition money sentences (i.e. 50p + 50p = £1)"],
    ["Sub4_Game_7.m4a", "Tap two numbers which equal 18"],
    ["Sub4_Game_9.m4a", "Create the total amount needed"],
    ["Sub4_Game_10.m4a", "Tap the symbol which represents the equals sign"],
    ["Sub4_Game_11.m4a", "Slice two numbers which equal 12"],
    ["Sub4_Game_17.m4a", "Tap two numbers which equal 100"],
    ["Sub4_Game_18.m4a", "Slice two numbers that make 10"],
    ["Sub4_Game_22.m4a", "Tap two boxes which equal £3"],
    ["Sub4_Game_23.m4a", "Slice two numbers that make 17"],
    ["Sub4_Game_25.m4a", "Slice the symbols for: Subtraction, Multiplication and the Pound Sign"],
    ["Sub5_Game_2.m4a", "Tap the boxes which represent odd numbers"],
    ["Sub5_Game_7.m4a", "Create a subtraction number sentence"],
    ["Sub5_Game_10.m4a", "Tap the box which represents the pound sign"],
    ["Sub5_Game_11.m4a", "What number is subtracted from 30 to make 12?"],
    ["Sub5_Game_16.m4a", "Slice the number which comes after 93"],
    ["Sub5_Game_17.m4a", "Slice the number which comes after 102"],
    ["Sub5_Game_21.m4a", "Create a money maths sentence (For example, 50p + 50p = £1)"],
    ["Sub5_Game_24.m4a", "Is 37 and odd number?"],
    ["Sub5_Game_25.m4a", "Is 40 an even number?"],
    ["Sub6_Game_11.m4a", "Create a subtraction number sentence (For example, 7 - 3 = 4)"],
    ["Sub6_Game_19.m4a", "What symbols are shown on the asteroids?"],
    ["Div1_Game_2.m4a", "Tap the word which represents the number 7"],
    ["Div1_Game_3.m4a", "Tap the number which represents the words spelt on the asteroid"],
    ["Div1_Game_4.m4a", "Slice the following number: 7, 9 & 10"],
    ["Div1_Game_5.m4a", "Tap the words which represent: 4, 9 & 11"],
    ["Div1_Game_7.m4a", "Tap the numbers which are 1/4 of the asteroid"],
    ["Div1_Game_8.m4a", "Slice the words that represent: 2 & 5"],
    ["Div1_Game_9.m4a", "Tap the numbers which are 1/2 of the asteroid"],
    ["Div1_Game_11.m4a", "Tap the number which comes next"],
    ["Div1_Game_14.m4a", "Slice the words that represent: 10, 3 & 8"],
    ["Div1_Game_15.m4a", "Tap the words which represent: 2, 8 & 5"],
    ["Div1_Game_22.m4a", "Tap the number which comes before the asteroid"],
    ["Div1_Game_24.m4a", "Tap the words that represent: 10, 1 & 6"],
    ["Sub2_Game_2.m4a", "Tap the words that represent: 8, 3 & 13"],
    ["Div2_Game_3.m4a", "Tap the numbers that represent the word on the asteroid"],
    ["Div2_Game_4.m4a", "Slice the words which represent: 6, 1 & 10"],
    ["Div2_Game_5.m4a", "Which box correctly spells the number 7?"],
    ["Div2_Game_8.m4a", "Slice the words that represent: 3, 5 & 9"],
    ["Div2_Game_11.m4a", "Tap the number which comes one before the asteroid"],
    ["Sub2_Game_14.m4a", "Slice the numbers which represent: 10, 12 & 8"],
    ["Sub2_Game_15.m4a", "Tap the words which represent: 13, 3 & 10"],
    ["Div2_Game_18.m4a", "Tap the box which correctly spells the number: 14"],
    ["Div2_Game_23.m4a", "Slice the numbers which represent: 20, 30 & 40"],
    ["Div3_Game_1.m4a", "Tap the words that represent: 4, 10 & 5"],
    ["Div3_Game_3.m4a", "Which box correctly spells the number 8?"],
    ["Div3_Game_4.m4a", "Which box correctly spells the number 11?"],
    ["Div3_Game_5.m4a", "Which box correctly spells the number 3?"],
    ["Div3_Game_10.m4a", "Slice the numbers: 30, 90 & 64"],
    ["Div3_Game_15.m4a", "Slice the numbers: 81, 2 & 40"],
    ["Div3_Game_16.m4a", "Tap the box which represents: 26"],
    ["Div3_Game_21.m4a", "Which box correctly spells the number 20"],
    ["Div4_Game_1.m4a", "Tap the box which correctly spells the number 4"],
    ["Div4_Game_3.m4a", "Divide two numbers to equal the asteroid"],
    ["Div4_Game_6.m4a", "Tap the box which correctly spells the number 21"],
    ["Div4_Game_7.m4a", "Tap the box which correectly spells the number 50"],
    ["Div4_Game_8.m4a", "Slice the words which represent: 6, 34 & 20"],
    ["Div4_Game_10.m4a", "Tap two numbers which equal the asteroid"],
    ["Div4_Game_12.m4a", "Tap the numbers which are 1/4 of the one in the middle"],
    ["Div4_Game_16.m4a", "Slice the numbers which represent: 60, 30 & 91"],
    ["Div4_Game_17.m4a", "Tap the box which represents: 73"],
    ["Div4_Game_23.m4a", "Tap the number which is one less than the middle"],
    ["Div5_Game_1.m4a", "Tap the box which represents: 102"],
    ["Div5_Game_2.m4a", "Tap the box which represents: 89"],
    ["Div5_Game_4.m4a", "What does 25 divided by 5 equal?"],
    ["Div5_Game_5.m4a", "What does 22 divided by 2 equal?"],
    ["Div7_Game_7.m4a", "Which box correctly spells 74?"],
    ["Div5_Game_8.m4a", "Which box correctly spells 20?"],
    ["Div5_Game_9.m4a", "Slice the number which represents 80"],
    ["Div5_Game_12.m4a", "Slice the number which represents: 1,270"],
    ["Div5_Game_15.m4a", "What's the answer to, 100 divided by 50?"],
    ["Div5_Game_16.m4a", "What's the answer to, 8 divided by 2?"],
    ["Div5_Game_18.m4a", "Slice the number which comes one before 18"],
    ["Div5_Game_20.m4a", "How do you spell the number 41?"],
    ["Div5_Game_21.m4a", "How do you spell the number 39?"],
    ["Div5_Game_23.m4a", "What's 12 divided by 2?"],
    ["Div5_Game_24.m4a", "What's 30 divided by 10?"],
    ["Div6_Game_1.m4a", "Tap the box which represents 35"],
    ["Div6_Game_3.m4a", "What's 20 divided by 2?"],
    ["Div6_Game_4.m4a", "What's 24 divided by 4?"],
    ["Div6_Game_8.m4a", "Tap the box which spells: 78"],
    ["Div6_Game_12.m4a", "Tap the number which is 8 more than the asteroid"],
    ["Div6_Game_13.m4a", "Tap the number which is 14 more than the asteroid"],
    ["Div6_Game_14.m4a", "What's 36 divided by 4?"],
    ["Div6_Game_15.m4a", "What's 81 divided by 9?"],
    ["Div6_Game_19.m4a", "Which number represents: 748"],
    ["Div6_Game_20.m4a", "Tap the box, 9,311"],
    ["Div6_Game_23.m4a", "Slice the number, 215"],
    ["Div6_Game_24.m4a", "Tap the number which comes 9 before the asteroid"],
    ["Div6_Game_25.m4a", "Tap the number which comes 18 before the asteroid"],
]);
export const pathImages: Map<string, string> = new Map([
    ["addlantis_path1", "Addlantis path 1"],
    ["addlantis_path2", "Addlantis path 2"],
    ["addlantis_path3", "Addlantis path 3"],
    ["addlantis_path4", "Addlantis path 4"],
    ["multivious_path1", "Multivious path 1"],
    ["multivious_path2", "Multivious path 2"],
    ["multivious_path3", "Multivious path 3"],
    ["multivious_path4", "Multivious path 4"],
    ["subterra_path1", "Subterra path 1"],
    ["subterra_path2", "Subterra path 2"],
    ["subterra_path3", "Subterra path 3"],
    ["subterra_path4", "Subterra path 4"],
    ["theDivide_path1", "The Divide path 1"],
    ["theDivide_path2", "The Divide path 2"],
    ["theDivide_path3", "The Divide path 3"],
    ["theDivide_path4", "The Divide path 4"],
    ["castle_path1", "Castle path 1"],
    ["castle_path2", "Castle path 2"],
    ["castle_path3", "Castle path 3"],
    ["castle_path4", "Castle path 4"],
    ["pirate_path1", "Pirate path 1"],
    ["pirate_path2", "Pirate path 2"],
    ["pirate_path3", "Pirate path 3"],
    ["pirate_path4", "Pirate path 4"],
    ["jungle_path1", "Jungle path 1"],
    ["jungle_path2", "Jungle path 2"],
    ["jungle_path3", "Jungle path 3"],
    ["jungle_path4", "Jungle path 4"],
    ["haunted_path1", "Haunted path 1"],
    ["haunted_path2", "Haunted path 2"],
    ["haunted_path3", "Haunted path 3"],
    ["haunted_path4", "Haunted path 4"],
]);
export const bossTypes: Map<string, string> = new Map([
    ["octopus.png", "Octopus (Addlantis)"],
    ["boss-penguin1.png", "Penguin (Subterra)"],
    ["oddPink.png", "Pink camel (Multivious)"],
    ["kevin-the-space-octo.png", "Space octopus (The divide)"],
]);
export const animalTypes: Map<string, string> = new Map([
    ["baby-penguin1.png", "Baby penguin (Subterra)"],
    ["floatyGreen.png", "Floating green alien (The divide)"],
    ["littleFish.png", "Little fish (Addlantis)"],
    ["oddRed.png", "Red thing (Multivious)"],
]);
export const vehicleTypes: Map<string, string> = new Map([
    ["blue-heli-1", "Helicopter (blue)"],
    ["green-heli-1", "Helicopter (green)"],
    ["red-heli-1", "Helicopter (red)"],
    ["blue-plane-1", "Plane (blue)"],
    ["red-plane-1", "Plane (red) 1"],
    ["red-plane-2", "Plane (red) 2"],
    ["blue-spaceship-1", "Spaceship (blue)"],
    ["green-spaceship-1", "Spaceship (green)"],
    ["purple-spaceship-1", "Spaceship (purple)"],
    ["red-spaceship-1", "Spaceship (red)"],
    ["blue-sub-1", "Submarine (blue)"],
    ["green-sub-1", "Submarine (green)"],
    ["red-sub-1", "Submarine (red)"],
    ["yellow-sub-1", "Submarine (yellow)"],
]);

export interface YearGroup {
    name?: string;
    subTags?: string[];
    errorTag?: string;
}

export const yearGroups: Map<string, Map<string, YearGroup>> = new Map([
    ["GB", new Map([
        ["year_early_years", {
            name: "Early years",
            subTags: [
                "year_nursery_-_easy", "year_nursery_-_intermediate", "year_nursery_-_hard", "year_nursery",
                "year_reception_-_easy", "year_reception_-_intermediate", "year_reception_-_hard", "year_reception",
                "year_early_years_-_easy", "year_early_years_-_intermediate", "year_early_years_-_hard",
            ]
        }
        ], [
            "year_early_years_-_easy", {
                name: "Early years - Developing",
                subTags: ["year_nursery_-_easy", "year_nursery_-_intermediate"]
            }
        ], [
            "year_early_years_-_intermediate", {
                name: "Early years - Expected",
                subTags: ["year_nursery_-_hard", "year_reception_-_easy"]
            }
        ], [
            "year_early_years_-_hard", {
                name: "Early years - Greater depth",
                subTags: ["year_reception_-_intermediate", "year_reception_-_hard"]
            }
        ], [
            "year_nursery", {
                name: "Nursery",
                subTags: ["year_nursery_-_easy", "year_nursery_-_intermediate", "year_nursery_-_hard"],
            }
        ], [
            "year_nursery_-_easy", { name: "Nursery - Developing" }
        ], [
            "year_nursery_-_intermediate", { name: "Nursery - Expected" }
        ], [
            "year_nursery_-_hard", { name: "Nursery - Greater depth" }
        ], [
            "year_reception", {
                name: "Reception",
                subTags: ["year_reception_-_easy", "year_reception_-_intermediate", "year_reception_-_hard"],
            }
        ], [
            "year_reception_-_easy", { name: "Reception - Developing" }
        ], [
            "year_reception_-_intermediate", { name: "Reception - Expected" }
        ], [
            "year_reception_-_hard", { name: "Reception - Greater depth" }
        ], [
            "ks1", {
                name: "KS1",
                subTags: [
                    "year_1_-_easy", "year_1_-_intermediate", "year_1_-_hard", "year_1",
                    "year_2_-_easy", "year_2_-_intermediate", "year_2_-_hard", "year_2",
                    "ks1_-_easy", "ks1_-_intermediate", "ks1_-_hard",
                ]
            }
        ], [
            "ks1_-_easy", {
                name: "KS1 - Developing",
                subTags: ["year_1_-_easy", "year_1_-_intermediate", "year_1"],
            }
        ], [
            "ks1_(basic_understanding)_-_easy", { errorTag: "ks1_-_easy" }
        ], [
            "ks1_-_intermediate", {
                name: "KS1 - Expected",
                subTags: ["year_1_-_hard", "year_2_-_easy", "year_2"],
            }
        ], [
            "ks1_(basic_understanding)", { errorTag: "ks1_-_intermediate" }
        ], [
            "ks1_-_intermediate_", {
                errorTag: "ks1_-_intermediate"
            }
        ], [
            "ks1_-_hard", {
                name: "KS1 - Greater depth",
                subTags: ["year_2_-_intermediate", "year_2_-_hard"],
            },
        ], [
            "year_1", {
                name: "Year 1",
                subTags: ["year_1_-_easy", "year_1_-_intermediate", "year_1_-_hard"],
            }
        ], [
            "year_1_-_easy", { name: "Year 1 - Developing" }
        ], [
            "year_1_-_intermediate", { name: "Year 1 - Expected" }
        ], [
            "year_1_-_intermediate_", { errorTag: "year_1_-_intermediate" }
        ], [
            "year_1_-_hard", { name: "Year 1 - Greater depth" }
        ], [
            "year_2", {
                name: "Year 2",
                subTags: ["year_2_-_easy", "year_2_-_intermediate", "year_2_-_hard"]
            }
        ], [
            "year_2_-_easy", { name: "Year 2 - Developing" }
        ], [
            "year_2_-_intermediate", { name: "Year 2 - Expected" }
        ], [
            "year_2_-_intermediate_", { errorTag: "year_2_-_intermediate" }
        ], [
            "year_2_-_hard", { name: "Year 2 - Greater depth" }
        ], [
            "year_3", {
                name: "Year 3",
                subTags: ["year_3_-_easy", "year_3_-_intermediate", "year_3_-_hard"],
            }
        ], [
            "ks2", {
                name: "KS2",
                subTags: [
                    "year_3_-_easy", "year_3_-_intermediate", "year_3_-_hard", "year_3",
                    "year_4_-_easy", "year_4_-_intermediate", "year_4_-_hard", "year_4",
                    "year_5_-_easy", "year_5_-_intermediate", "year_5_-_hard", "year_5",
                    "year_6_-_easy", "year_6_-_intermediate", "year_6_-_hard", "year_6",
                    "ks2_-_easy", "ks2_-_intermediate", "ks2_-_hard",
                ]
            }
        ], [
            "ks2_-_easy", {
                name: "KS2 - Developing",
                subTags: ["year_3_-_easy", "year_3_-_intermediate", "year_3_-_hard", "year_4_-_easy", "year_3", "year_4"],
            }
        ], [
            "ks2_-_intermediate", {
                name: "KS2 - Expected",
                subTags: ["year_4_-_intermediate", "year_4_-_hard", "year_5_-_easy", "year_5_-_intermediate", "year_5"],
            }
        ], [
            "ks2_-_intermediate_", {
                errorTag: "ks2_-_intermediate"
            }
        ], [
            "ks2_-_hard", {
                name: "KS2 - Greater depth",
                subTags: ["year_5_-_hard", "year_6_-_easy", "year_6_-_intermediate", "year_6_-_hard", "year_6"],
            }
        ], [
            "year_3_-_easy", { name: "Year 3 - Developing" }
        ], [
            "year_3_-_intermediate", { name: "Year 3 - Expected" }
        ], [
            "year_3_-_intermediate_", { errorTag: "year_3_-_intermediate" }
        ], [
            "year_3_-_hard", { name: "Year 3 - Greater depth" }
        ], [
            "year_4", {
                name: "Year 4",
                subTags: ["year_4_-_easy", "year_4_-_intermediate", "year_4_-_hard"],
            }
        ], [
            "year_4_-_easy", { name: "Year 4 - Developing" }
        ], [
            "year_4_-_intermediate", { name: "Year 4 - Expected" }
        ], [
            "year_4_-_intermediate_", { errorTag: "year_4_-_intermediate" }
        ], [
            "year_4_-_hard", { name: "Year 4 - Greater depth" }
        ], [
            "year_5", {
                name: "Year 5",
                subTags: ["year_5_-_easy", "year_5_-_intermediate", "year_5_-_hard"],
            }
        ], [
            "year_5_-_easy", { name: "Year 5 - Developing" }
        ], [
            "year_5_-_intermediate", { name: "Year 5 - Expected" }
        ], [
            "year_5_-_intermediate_", { errorTag: "Year 5 - Expected" }
        ], [
            "year_5_-_hard", { name: "Year 5 - Greater depth" }
        ], [
            "year_6", {
                name: "Year 6",
                subTags: ["year_6_-_easy", "year_6_-_intermediate", "year_6_-_hard"],
            }
        ], [
            "year_6_-_easy", { name: "Year 6 - Developing" }
        ], [
            "year_6_-_intermediate", { name: "Year 6 - Expected" }
        ], [
            "year_6_-_intermediate_", { errorTag: "Year 6 - Expected" }
        ], [
            "year_6_-_hard", { name: "Year 6 - Greater depth" }
        ], [
            "year_7", {
                name: "Year 7",
                subTags: ["year_7_-_easy", "year_7_-_intermediate", "year_7_-_hard"],
            }
        ], [
            "year_7_-_easy", { name: "Year 7 - Developing" }
        ], [
            "year_7_-_intermediate", { name: "Year 7 - Expected" }
        ], [
            "year_7_-_hard", { name: "Year 7 - Greater depth" }
        ]
    ])
    // "US", {
    //     "year_usa_preschool", { name: "Pre-school" },
    //     "year_usa_preschool_-_easy", { name: "Pre-school - Developing" },
    //     "year_usa_preschool_-_intermediate", { name: "Pre-school - Expected" },
    //     "year_usa_preschool_-_hard", { name: "Pre-school - Greater depth" },
    //     "year_usa_kindergarten", { name: "Kindergarten" },
    //     "year_usa_kindergarten_-_easy", { name: "Kindergarten - Developing" },
    //     "year_usa_kindergarten_-_intermediate", { name: "Kindergarten - Expected" },
    //     "year_usa_kindergarten_-_hard", { name: "Kindergarten - Greater depth" },
    //     "year_usa_1", { name: "1st Grade" },
    //     "year_usa_1_-_easy", { name: "1st Grade - Developing" },
    //     "year_usa_1_-_intermediate", { name: "1st Grade - Expected" },
    //     "year_usa_1_-_hard", { name: "1st Grade - Greater depth" },
    //     "year_usa_2", { name: "2nd Grade" },
    //     "year_usa_2_-_easy", { name: "2nd Grade - Developing" },
    //     "year_usa_2_-_intermediate", { name: "2nd Grade - Expected" },
    //     "year_usa_2_-_hard", { name: "2nd Grade - Greater depth" },
    //     "year_usa_3", { name: "3rd Grade" },
    //     "year_usa_3_-_easy", { name: "3rd Grade - Developing" },
    //     "year_usa_3_-_intermediate", { name: "3rd Grade - Expected" },
    //     "year_usa_3_-_hard", { name: "3rd Grade - Greater depth" },
    //     "year_usa_4", { name: "4th Grade" },
    //     "year_usa_4_-_easy", { name: "4th Grade - Developing" },
    //     "year_usa_4_-_intermediate", { name: "4th Grade - Expected" },
    //     "year_usa_4_-_hard", { name: "4th Grade - Greater depth" },
    //     "year_usa_5", { name: "5th Grade" },
    //     "year_usa_5_-_easy", { name: "5th Grade - Developing" },
    //     "year_usa_5_-_intermediate", { name: "5th Grade - Expected" },
    //     "year_usa_5_-_hard", { name: "5th Grade - Greater depth" },
    //     "year_usa_6", { name: "6th Grade" },
    //     "year_usa_6_-_easy", { name: "6th Grade - Developing" },
    //     "year_usa_6_-_intermediate", { name: "6th Grade - Expected" },
    //     "year_usa_6_-_hard", { name: "6th Grade - Greater depth" },
    // },
    // "FR", {
    //     "year_france_petite", { name: "PS" },
    //     "year_france_petite_-_easy", { name: "PS - Developing" },
    //     "year_france_petite_-_intermediate", { name: "PS - Expected" },
    //     "year_france_petite_-_hard", { name: "PS - Greater depth" },
    //     "year_france_moyenne", { name: "MS" },
    //     "year_france_moyenne_-_easy", { name: "MS - Developing" },
    //     "year_france_moyenne_-_intermediate", { name: "MS - Expected" },
    //     "year_france_moyenne_-_hard", { name: "MS - Greater depth" },
    //     "year_france_grande", { name: "GS" },
    //     "year_france_grande_-_easy", { name: "GS - Developing" },
    //     "year_france_grande_-_intermediate", { name: "GS - Expected" },
    //     "year_france_grande_-_hard", { name: "GS - Greater depth" },
    //     "year_france_cp", { name: "CP" },
    //     "year_france_cp_-_easy", { name: "CP - Developing" },
    //     "year_france_cp_-_intermediate", { name: "CP - Expected" },
    //     "year_france_cp_-_hard", { name: "CP - Greater depth" },
    //     "year_france_ce1", { name: "CE1" },
    //     "year_france_ce1_-_easy", { name: "CE1 - Developing" },
    //     "year_france_ce1_-_intermediate", { name: "CE1 - Expected" },
    //     "year_france_ce1_-_hard", { name: "CE1 - Greater depth" },
    //     "year_france_ce2", { name: "CE2" },
    //     "year_france_ce2_-_easy", { name: "CE2 - Developing" },
    //     "year_france_ce2_-_intermediate", { name: "CE2 - Expected" },
    //     "year_france_ce2_-_hard", { name: "CE2 - Greater depth" },
    //     "year_france_cm1", { name: "CM1" },
    //     "year_france_cm1_-_easy", { name: "CM1 - Developing" },
    //     "year_france_cm1_-_intermediate", { name: "CM1 - Expected" },
    //     "year_france_cm1_-_hard", { name: "CM1 - Greater depth" },
    //     "year_france_cm2", { name: "CM2" },
    //     "year_france_cm2_-_easy", { name: "CM2 - Developing" },
    //     "year_france_cm2_-_intermediate", { name: "CM2 - Expected" },
    //     "year_france_cm2_-_hard", { name: "CM2 - Greater depth" },
    //     "year_france_6", { name: "La sixième" },
    //     "year_france_6_-_easy", { name: "La sixième - Developing" },
    //     "year_france_6_-_intermediate", { name: "La sixième - Expected" },
    //     "year_france_6_-_hard", { name: "La sixième - Greater depth" },
    // },
    // "ZA", {
    //     "year_sa_reception", { name: "Reception" },
    //     "year_sa_reception_-_easy", { name: "Reception - Developing" },
    //     "year_sa_reception_-_intermediate", { name: "Reception - Expected" },
    //     "year_sa_reception_-_hard", { name: "Reception - Greater depth" },
    //     "year_sa_grade1", { name: "Grade 1" },
    //     "year_sa_grade1_-_easy", { name: "Grade 1 - Developing" },
    //     "year_sa_grade1_-_intermediate", { name: "Grade 1 - Expected" },
    //     "year_sa_grade1_-_hard", { name: "Grade 1 - Greater depth" },
    //     "year_sa_grade2", { name: "Grade 2" },
    //     "year_sa_grade2_-_easy", { name: "Grade 2 - Developing" },
    //     "year_sa_grade2_-_intermediate", { name: "Grade 2 - Expected" },
    //     "year_sa_grade2_-_hard", { name: "Grade 2 - Greater depth" },
    //     "year_sa_grade3", { name: "Grade 3" },
    //     "year_sa_grade3_-_easy", { name: "Grade 3 - Developing" },
    //     "year_sa_grade3_-_intermediate", { name: "Grade 3 - Expected" },
    //     "year_sa_grade3_-_hard", { name: "Grade 3 - Greater depth" },
    //     "year_sa_grade4", { name: "Grade 4" },
    //     "year_sa_grade4_-_easy", { name: "Grade 4 - Developing" },
    //     "year_sa_grade4_-_intermediate", { name: "Grade 4 - Expected" },
    //     "year_sa_grade4_-_hard", { name: "Grade 4 - Greater depth" },
    //     "year_sa_grade5", { name: "Grade 5" },
    //     "year_sa_grade5_-_easy", { name: "Grade 5 - Developing" },
    //     "year_sa_grade5_-_intermediate", { name: "Grade 5 - Expected" },
    //     "year_sa_grade5_-_hard", { name: "Grade 5 - Greater depth" },
    //     "year_sa_grade6", { name: "Grade 6" },
    //     "year_sa_grade6_-_easy", { name: "Grade 6 - Developing" },
    //     "year_sa_grade6_-_intermediate", { name: "Grade 6 - Expected" },
    //     "year_sa_grade6_-_hard", { name: "Grade 6 - Greater depth" },
    //     "year_sa_grade7", { name: "Grade 7" },
    //     "year_sa_grade7_-_easy", { name: "Grade 7 - Developing" },
    //     "year_sa_grade7_-_intermediate", { name: "Grade 7 - Expected" },
    //     "year_sa_grade7_-_hard", { name: "Grade 7 - Greater depth" },
    //     "year_sa_grade8", { name: "Grade 8" },
    //     "year_sa_grade8_-_easy", { name: "Grade 8 - Developing" },
    //     "year_sa_grade8_-_intermediate", { name: "Grade 8 - Expected" },
    //     "year_sa_grade8_-_hard", { name: "Grade 8 - Greater depth" },
    //     "year_sa_grade9", { name: "Grade 9" },
    //     "year_sa_grade9_-_easy", { name: "Grade 9 - Developing" },
    //     "year_sa_grade9_-_intermediate", { name: "Grade 9 - Expected" },
    //     "year_sa_grade9_-_hard", { name: "Grade 9 - Greater depth" },
    // },
    // "IN", {
    //     "year_india_preschool", { name: "Pre-school" },
    //     "year_india_preschool_-_easy", { name: "Pre-school - Developing" },
    //     "year_india_preschool_-_intermediate", { name: "Pre-school - Expected" },
    //     "year_india_preschool_-_hard", { name: "Pre-school - Greater depth" },
    //     "year_india_preprimary", { name: "Pre-primary" },
    //     "year_india_preprimary_-_easy", { name: "Pre-primary - Developing" },
    //     "year_india_preprimary_-_intermediate", { name: "Pre-primary - Expected" },
    //     "year_india_preprimary_-_hard", { name: "Pre-primary - Greater depth" },
    //     "year_india_grade1", { name: "Grade 1" },
    //     "year_india_grade1_-_easy", { name: "Grade 1 - Developing" },
    //     "year_india_grade1_-_intermediate", { name: "Grade 1 - Expected" },
    //     "year_india_grade1_-_hard", { name: "Grade 1 - Greater depth" },
    //     "year_india_grade2", { name: "Grade 2" },
    //     "year_india_grade2_-_easy", { name: "Grade 2 - Developing" },
    //     "year_india_grade2_-_intermediate", { name: "Grade 2 - Expected" },
    //     "year_india_grade2_-_hard", { name: "Grade 2 - Greater depth" },
    //     "year_india_grade3", { name: "Grade 3" },
    //     "year_india_grade3_-_easy", { name: "Grade 3 - Developing" },
    //     "year_india_grade3_-_intermediate", { name: "Grade 3 - Expected" },
    //     "year_india_grade3_-_hard", { name: "Grade 3 - Greater depth" },
    //     "year_india_grade4", { name: "Grade 4" },
    //     "year_india_grade4_-_easy", { name: "Grade 4 - Developing" },
    //     "year_india_grade4_-_intermediate", { name: "Grade 4 - Expected" },
    //     "year_india_grade4_-_hard", { name: "Grade 4 - Greater depth" },
    //     "year_india_grade5", { name: "Grade 5" },
    //     "year_india_grade5_-_easy", { name: "Grade 5 - Developing" },
    //     "year_india_grade5_-_intermediate", { name: "Grade 5 - Expected" },
    //     "year_india_grade5_-_hard", { name: "Grade 5 - Greater depth" },
    //     "year_india_grade6", { name: "Grade 6" },
    //     "year_india_grade6_-_easy", { name: "Grade 6 - Developing" },
    //     "year_india_grade6_-_intermediate", { name: "Grade 6 - Expected" },
    //     "year_india_grade6_-_hard", { name: "Grade 6 - Greater depth" },
    //     "year_india_grade7", { name: "Grade 7" },
    //     "year_india_grade7_-_easy", { name: "Grade 7 - Developing" },
    //     "year_india_grade7_-_intermediate", { name: "Grade 7 - Expected" },
    //     "year_india_grade7_-_hard", { name: "Grade 7 - Greater depth" },
    // },
]]);

export const questionType: Map<string, string> = new Map([
    ["CHOICE", "Multiple-choice"],
    ["SPELLING", "Spelling (shown)"],
    ["SPELLING_HIDDEN", "Spelling (hidden)"],
    ["TEXT", "Free text answer"],
    ["REORDER", "Re-order statements"]
]);

export interface Permission {
    title: string;
    description: string;
    licenses?: string[];
}

export const permissions: Map<string, Permission> = new Map([
    ["ADMIN", {
        title: "Manage administrators",
        description: "Create and manage administrator details, including all permissions. Manage site settings including payment settings."
    }],
    // "SITE_ADMIN", {
    //     title: "Site administrator",
    //     description: "Manage organisation settings (e.g. payment settings)",
    // },
    ["ACCOUNT", {
        title: "Manage accounts",
        description: "Manage user accounts, account groups and school devices",
    }],
    ["MODULE", {
        title: "Manage learning",
        description: "Manage the creation and sharing of home learning and live quizzes, including learning worlds, paths, minigames and quiz templates",
        licenses: ["learningNinja", "lifeninja-englit", "maths"],
    }],
    ["MESSAGE", {
        title: "Manage news items",
        description: "Send news items and manage all news items",
        licenses: ["messaging"],
    }],
    ["EVENT", {
        title: "Manage events",
        description: "Create events and manage all events",
        licenses: ["messaging"],
    }],
    ["CHAT", {
        title: "Manage chats",
        description: "View chats and delete messages",
        licenses: ["messaging"],
    }],
    ["REPORTING", {
        title: "Run reports",
        description: "View reports on student attainment/engagement etc"
    }],
]);

export const imageName: Map<string, string> = new Map([
    ["modules/aChristmasCarol.jpg", "A Christmas Carol poster"],
    ["modules/travel.jpg", "Aeroplane & sunset"],
    ["modules/bloodBrothers.jpg", "Arm wrestle (B&W)"],
    ["books.jpg", "Bookshelf"],
    ["modules/shopping.jpg", "Clothes rack"],
    ["modules/art.jpg", "Colour splashes"],
    ["homophones.jpg", "'Grammar' written in book"],
    ["modules/random.jpg", "Holey thing"],
    ["modules/balloons.jpg", "Hot air balloons"],
    ["iceHockey.jpg", "Ice hockey"],
    ["modules/jekyllAndHyde.jpg", "Jekyll and Hyde poster"],
    ["modules/computer.jpg", "Laptop on lap"],
    ["modules/maths.jpg", "'Maths' on green board"],
    ["modules/macbeth.jpg", "Macbeth art"],
    ["modules/languages.png", "Multi-lingual 'Language'"],
    ["modules/poetry.jpg", "Old book page of writing"],
    ["poetry.jpg", "Old book page of writing 2"],
    ["modules/anInspectorCalls.jpg", "Old telephone"],
    ["modules/people.jpg", "People on street (B&W)"],
    ["devices.jpg", "Phone & tablet"],
    ["shakespeare.jpg", "Shakespeare portrait"],
    ["modules/lordOfTheFlies.jpg", "Shell"],
    ["spelling.jpg", "Spelling on blackboard"],
    ["shop.jpg", "Stack of books"],
    ["modules/illness.jpg", "Stethoscope"],
    ["modules/time.jpg", "Watch face"],
    ["worlds/addlantis.png", "Addlantis"],
    ["worlds/multivious.png", "Multivious"],
    ["worlds/subterra.png", "Subterra"],
    ["worlds/theDivide.png", "The Divide"],
    ["worlds/Other1.png", "Purple sweet"],
    ["worlds/Other2.png", "Hamburger"],
    ["worlds/Other3.png", "Gloop"],
    ["worlds/Other4.png", "Spike"],
    ["worlds/Other5.png", "Slime"],
    ["worlds/castle_world.png", "Story world - Castle"],
    ["worlds/pirate_world.png", "Story world - Pirates"],
    ["worlds/haunted_world.png", "Story world - Spooky"],
    ["worlds/jungle_world.png", "Story world - Jungle"],
]);
//@ts-check

import React, { Component } from 'react';
import firebase from './firebase';
import queryString from 'query-string';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, UncontrolledTooltip, InputGroup, InputGroupAddon, Card, CardBody
} from 'reactstrap';
import { Link } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import TopicsSelector from "./ninja_components/topics_selector";
import { StoreLearningGame } from "./helpers/topic_services";
import { GridGame, TIME_MULTIPLIER } from './data/learning_games';
import { User, USER_ROLE } from './data/user';
import { v4 as uuidv4 } from 'uuid';
import { soundNames, subjectIds } from './Prettifier';

interface IState {
    worldId: string;
    worldName: string;
    subjectFilter: string;
    pathId: string;
    pathName: string;
    gameId: string;
    grid: GridGame;
    timeMultiplier: TIME_MULTIPLIER;
    currentGridTitle: string;
    currentGridInstructions: string;
    currentGridAnswerTemplate: string;
    currentGridSchoolId: string;
    currentGridSize: number;
    currentGridSound: string;
    currentGridAnswers: Array<Array<string>>;
    currentSubjectId: string;
    currentTopics: string[],
    currentTopicsTags: Map<string, string[]>,
    currentGridNumbers: Map<number, string[]>,
    user: User;
}

interface MatchParams {
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class LearningGridView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        let initialGridNumbers = new Map<number, string[]>();
        initialGridNumbers.set(0, ["0", "0", "0"]);
        initialGridNumbers.set(1, ["0", "0", "0"]);
        initialGridNumbers.set(2, ["0", "0", "0"]);
        this.state = {
            worldId: values.worldId == null ? null : values.worldId.toString(),
            worldName: values.worldName == null ? null : values.worldName.toString(),
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            pathId: values.pathId == null ? null : values.pathId.toString(),
            pathName: values.pathName == null ? null : values.pathName.toString(),
            gameId: props.match.params.gameId,
            grid: null,
            timeMultiplier: TIME_MULTIPLIER.moderate,
            currentGridTitle: '',
            currentGridInstructions: '',
            currentGridAnswerTemplate: '',
            currentGridSchoolId: '',
            currentGridSize: 3,
            currentGridSound: '',
            currentGridAnswers: [],
            currentSubjectId: '',
            currentTopics: [],
            currentTopicsTags: new Map<string, string[]>(),
            currentGridNumbers: initialGridNumbers,
            user: props.user,
        };
        this.dealWithChange = this.dealWithChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleGridChange = this.handleGridChange.bind(this);
        this.handleGridSizeChange = this.handleGridSizeChange.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
        this.removeAnswer = this.removeAnswer.bind(this);
        this.handleAnswerNumberChange = this.handleAnswerNumberChange.bind(this);
        this.removeNumber = this.removeNumber.bind(this);
        this.addRound = this.addRound.bind(this);
        this.addCorrectNumber = this.addCorrectNumber.bind(this);
        this.getGameQuerys = this.getGameQuerys.bind(this);
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.currentGridSchoolId === this.state.user.schoolId || this.state.user.role === USER_ROLE.admin || this.state.user.roles.get(USER_ROLE.learningCreator);

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds?${queryValues}`}>All worlds</Link></BreadcrumbItem>
                            : <BreadcrumbItem><Link to={`/minigames?${queryValues}`}>All minigames</Link></BreadcrumbItem>}
                        {this.state.worldId != null ?
                            <BreadcrumbItem><Link to={`/learningWorlds/${this.state.worldId}?${queryValues}`}>{this.state.worldName}</Link></BreadcrumbItem> : null}
                        {this.state.pathId != null ?
                            <BreadcrumbItem><Link to={`/learningPaths/${this.state.pathId}?${queryValues}`}>{this.state.pathName}</Link></BreadcrumbItem> : null}
                        <BreadcrumbItem active>Number Grid</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Number grid</div>
                        <p className="cardSubTitle">A number grid requires users to slide their finger across grid squares to make a chain of squares, or tap on an individual square.
                        The slide path is only to adjacent squares, cannot be diagonal and must be continuous.</p>
                        {
                            (this.state.grid != null) ?
                                <div>
                                    <div>
                                        <a href={`https://zapdemo.web.app/#/grid?${this.getGameQuerys()}`} target="_blank"><Button type="button" className="adminPagesButton">Play now</Button></a>
                                    </div><br />
                                    <Form className="border rounded form-margin" onSubmit={this.handleUpdate}>
                                        <fieldset disabled={!editable}>
                                            <FormGroup>
                                                <Label for="currentGridTitle">Grid title *</Label>
                                                <Input maxLength={30} type="text" required name="currentGridTitle" onChange={(e) => this.setState({
                                                    currentGridTitle: e.target.value
                                                })} value={this.state.currentGridTitle} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="currentSubjectId">Subject *</Label>
                                                <Input disabled={this.state.worldId != null || this.state.gameId !== "-1"} type="select" name="currentSubjectId"
                                                    onChange={(e) => this.setState({
                                                        currentSubjectId: e.target.value
                                                    })}
                                                    value={this.state.currentSubjectId}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                                        let subjectName = subjectIds.get(subjectId);
                                                        return <option value={subjectId}>{subjectName}</option>;
                                                    })}
                                                </Input>
                                            </FormGroup>
                                            {this.state.currentSubjectId === '' ? <div></div> :
                                                <TopicsSelector subject={this.state.currentSubjectId} countryCode={this.state.user.countryCode} currentTopics={this.state.currentTopics} currentTopicsTags={this.state.currentTopicsTags} callback={this.handleTopicsChange} />
                                            }

                                            <label>
                                                Game speed <i className="fas fa-info-circle icons-info" id="help-learning-timemultiplier" />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learning-timemultiplier">
                                                    Control how fast or slow the game will play.
                                                    Overall paths can have speed controls and the game speed will work within the context of the path speed control.
                                                    For example, a game with a 'fast' speed on a path with a 'slow' speed will play at 'moderate' speed.
                                                    Use this to allow developing or advanced students to play at a speed that suits them.
                                        </UncontrolledTooltip>
                                            </label>
                                            <Input type="select" value={this.state.timeMultiplier} onChange={this.dealWithChange}>
                                                <option value="fastest">Fastest</option>
                                                <option value="fast">Fast</option>
                                                <option value="moderate">Moderate</option>
                                                <option value="slow">Slow</option>
                                                <option value="slowest">Slowest</option>
                                            </Input>
                                            <FormGroup>
                                                <Label for="currentGridSize">Grid size * <i className="fas fa-info-circle icons-info" id="help-learninggrid-gridsize" /></Label>
                                                <Input type="number" min={2} required name="currentGridSize" onChange={this.handleGridSizeChange} value={this.state.currentGridSize} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learninggrid-gridsize">
                                                    How many rows/columns the grid contains.
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label>Grid <i className="fas fa-info-circle icons-info" id="help-learninggrid-grid" /></Label>
                                                <table>
                                                    <tbody>
                                                        {[...Array(this.state.currentGridSize)].map((e1, i) =>
                                                            <tr>
                                                                {[...Array(this.state.currentGridSize)].map((e2, j) =>
                                                                    <td>
                                                                        <Input required name={`currentGridNumbers-${i}-${j}`} onChange={this.handleGridChange} value={this.state.currentGridNumbers.get(i)[j]} />
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learninggrid-grid">
                                                    Enter the numbers as they should appear on the grid.
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <h4>Swipe answers <i className="fas fa-info-circle icons-info" id="help-learninggrid-answers" /></h4>
                                                {this.state.currentGridAnswers.map((answer, answerIndex) => {
                                                    let allowDelete = false;
                                                    if (this.state.currentGridAnswers[answerIndex].length > 1) {
                                                        allowDelete = true;
                                                    }
                                                    return <div className="border rounded form-margin">
                                                        <div><b>Answer {answerIndex + 1}</b> {answerIndex > 0 ? <Button type="button" color="link" onClick={() => this.removeAnswer(answerIndex)}><i
                                                            className="material-icons material-icons-xd">cancel</i></Button> : <span />}</div>
                                                        {this.state.currentGridAnswers[answerIndex].map((answer, index) =>
                                                            <InputGroup>
                                                                <Input required name={`currentGridAnswers-${answerIndex}-${index}`}
                                                                    onChange={(value) => this.handleAnswerNumberChange(value, answerIndex, index)}
                                                                    value={this.state.currentGridAnswers[answerIndex][index]} />
                                                                {allowDelete ? <InputGroupAddon addonType={"prepend"}>
                                                                    <Button type="button" outline onClick={() => this.removeNumber(answerIndex, index)}><i
                                                                        className="material-icons material-icons-xd">cancel</i></Button>
                                                                </InputGroupAddon> : null
                                                                }
                                                            </InputGroup>
                                                        )}
                                                        <Button className="altButton" onClick={() => this.addCorrectNumber(answerIndex)}>Additional correct number</Button>
                                                    </div>
                                                })}
                                                <Button className="altButton" onClick={() => this.addRound()}>Additional answer</Button>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learninggrid-answers">
                                                    Enter the boxes that the student should swipe through in the specific order (or tap on for a single answer)
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentGridAnswerTemplate">Answer template <i className="fas fa-info-circle icons-info" id="help-learninggrid-answertemplate" /></Label>
                                                <Input type="text" name="currentGridAnswerTemplate" onChange={(e) => this.setState({
                                                    currentGridAnswerTemplate: e.target.value
                                                })} value={this.state.currentGridAnswerTemplate} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learninggrid-answertemplate">
                                                    A number sentence or similar can appear at the bottom of the grid game and be filled in as squares are selected.<br />
                                            Use keywords ::selected1:: up to ::selected9:: for the squares selected by the user.<br />
                                            E.g. a standard template would be<br />
                                            ::selected1:: + ::selected2:: = ::selected3::
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentGridInstructions">Instructions <i className="fas fa-info-circle icons-info" id="help-learninggrid-instructions" /></Label>
                                                <Input type="textarea" name="currentGridInstructions" onChange={(e) => this.setState({
                                                    currentGridInstructions: e.target.value
                                                })} value={this.state.currentGridInstructions} />
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-learninggrid-instructions">
                                                    The instructions shown to the player before they start the grid. Advise on the types of sums to complete rather than the how to play the game.
                                        </UncontrolledTooltip>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="currentGridSound">Audio instruction *</Label>
                                                <Input type="select" name="currentGridSound"
                                                    onChange={(e) => this.setState({
                                                        currentGridSound: e.target.value
                                                    })}
                                                    value={this.state.currentGridSound}>
                                                    <option value={''}>&nbsp;</option>
                                                    {Array.from(soundNames.keys()).map((key) => {
                                                        let name = soundNames.get(key);
                                                        return (
                                                            <option value={key} key={key}>{name}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                            </FormGroup>

                                            {editable ? this.state.gameId === "-1" ?
                                                <Button disabled={this.state.currentSubjectId === ''} className="adminPagesButton" >Create</Button> : <Button className="adminPagesButton">Update</Button> : <span />
                                            }
                                        </fieldset>
                                    </Form>
                                </div> : <div />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    getGameQuerys(): string {
        let gridStringified = JSON.stringify(Object.fromEntries(this.state.currentGridNumbers));
        return queryString.stringify({
            instructions: this.state.currentGridInstructions,
            answerTemplate: this.state.currentGridAnswerTemplate,
            answers: JSON.stringify(this.state.currentGridAnswers),
            timeMultiplier: this.state.timeMultiplier,
            grid: gridStringified,
        });
    }

    addRound(): void {
        this.state.currentGridAnswers.push(["0"]);
        this.setState({
        });
    }

    addCorrectNumber(roundIndex: number): void {
        this.state.currentGridAnswers[roundIndex].push("0");
        this.setState({
        });
    }

    removeNumber(roundIndex: number, index: number): void {
        this.state.currentGridAnswers[roundIndex].splice(index, 1);
        this.setState({
        });
    }

    handleAnswerNumberChange(e: React.ChangeEvent<HTMLInputElement>, roundIndex: number, index: number): void {
        this.state.currentGridAnswers[roundIndex][index] = e.target.value;
        this.setState({
        });
    }

    removeAnswer(roundIndex: number): void {
        this.state.currentGridAnswers.splice(roundIndex, 1);
        this.setState({});
    }

    handleTopicsChange(topics: string[], topicsTags: Map<string, string[]>): void {
        this.setState({
            currentTopics: topics,
            currentTopicsTags: topicsTags,
        });
    }

    getQuerys(): string {
        return queryString.stringify({
            worldId: this.state.worldId,
            worldName: this.state.worldName,
            subjectFilter: this.state.subjectFilter,
            pathId: this.state.pathId,
            pathName: this.state.pathName,
            gameId: this.state.gameId
        });
    }

    dealWithChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            timeMultiplier: TIME_MULTIPLIER[e.target.value as keyof typeof TIME_MULTIPLIER]
        });
    }

    handleGridSizeChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if (e.target.value === "") {
            this.setState({
                currentGridSize: null,
            });
            return;
        }
        let gridSize = parseInt(e.target.value);
        let newGrid = new Map<number, string[]>();
        [...Array(gridSize)].forEach((e, i) => {
            let nextEntry = this.state.currentGridNumbers.get(i);
            if (nextEntry != null) {
                nextEntry.length = gridSize;
            } else {
                nextEntry = Array<string>(gridSize);
            }
            newGrid.set(i, nextEntry);
        });
        this.setState({
            currentGridSize: gridSize,
            currentGridNumbers: newGrid,
        });
    }

    handleGridChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let parts = e.target.name.split('-');
        this.state.currentGridNumbers.get(parseInt(parts[1]))[parseInt(parts[2])] = e.target.value;
        this.setState({});
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let grid = this.state.grid;
        grid.title = this.state.currentGridTitle;
        grid.instructions = this.state.currentGridInstructions;
        grid.answerTemplate = this.state.currentGridAnswerTemplate;
        let newAnswers = new Map<number, string[]>();
        for (let index = 0; index < this.state.currentGridAnswers.length; index++) {
            newAnswers.set(index, this.state.currentGridAnswers[index]);
        }
        grid.answers = newAnswers;
        grid.grid = this.state.currentGridNumbers;
        grid.subjectId = this.state.currentSubjectId;
        grid.topics = this.state.currentTopics;
        grid.topicTags = this.state.currentTopicsTags;
        grid.sound = this.state.currentGridSound;
        grid.timeMultiplier = this.state.timeMultiplier;
        grid.deleted = null;
        grid.type = 'GRID';
        let newGrid = false;
        let gameId = this.state.gameId;
        if (this.state.gameId === "-1") {
            newGrid = true;
            gameId = uuidv4();

            if (this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator)) {
                grid.schoolId = this.state.user.schoolId;
            }
        }
        try {
            await StoreLearningGame(newGrid, grid, gameId, this.state.pathId, this.state.currentGridSchoolId);

            this.props.history.replace(`/learningGrids/${gameId}?${this.getQuerys()}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
        this.setState({
            gameId: gameId
        })
    }

    async componentDidMount(): Promise<void> {
        try {
            let initialGridNumbers = new Map<number, string[]>();
            initialGridNumbers.set(0, ["0", "0", "0"]);
            initialGridNumbers.set(1, ["0", "0", "0"]);
            initialGridNumbers.set(2, ["0", "0", "0"]);
            if (this.state.gameId !== "-1") {
                let gridSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
                let gridData = gridSnapshot.data();
                let grid = GridGame.fromFirebase(gridData);
                let gridSize = 3;
                if (grid.grid.has(0)) {
                    gridSize = grid.grid.get(0).length;
                } else {
                    grid.grid = initialGridNumbers;
                }
                this.setState({
                    grid: grid,
                    currentGridTitle: grid.title,
                    currentGridInstructions: grid.instructions,
                    currentGridAnswerTemplate: grid.answerTemplate == null ? '' : grid.answerTemplate,
                    currentGridSchoolId: grid.schoolId == null ? null : grid.schoolId,
                    currentGridAnswers: grid.answers == null ? [["0"]] : Array.from(grid.answers.values()),
                    currentGridNumbers: grid.grid,
                    currentGridSound: grid.sound == null ? '' : grid.sound,
                    currentGridSize: gridSize,
                    currentSubjectId: grid.subjectId,
                    currentTopics: grid.topics == null ? [] : grid.topics,
                    currentTopicsTags: grid.topicTags == null ? new Map<string, string[]>() : grid.topicTags,
                    timeMultiplier: grid.timeMultiplier != null ? grid.timeMultiplier : TIME_MULTIPLIER.moderate,
                });
            } else {
                let subjectId = '';
                if (this.state.worldId != null) {
                    const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
                    let worldSnapshot = await worldRef.get();
                    let world = worldSnapshot.data();
                    subjectId = world.subject;
                } else {
                    subjectId = this.state.subjectFilter;
                }
                this.setState({
                    grid: new GridGame(),
                    currentGridTitle: '',
                    currentGridInstructions: '',
                    currentGridAnswerTemplate: '',
                    currentGridSchoolId: this.state.user.role !== USER_ROLE.admin && !this.state.user.roles.get(USER_ROLE.learningCreator) ? this.state.user.schoolId : null,
                    currentGridAnswers: [["0"]],
                    currentGridSize: 3,
                    currentGridSound: '',
                    currentGridNumbers: initialGridNumbers,
                    currentSubjectId: subjectId,
                    currentTopics: [],
                    currentTopicsTags: new Map<string, string[]>(),
                    timeMultiplier: TIME_MULTIPLIER.moderate,
                });
            }
        } catch (error) {
            console.log(error);
        }

    }
}

export default LearningGridView;
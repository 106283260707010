import firebase from "../firebase";

export async function confirmUsernameUniqueness(schoolId: string, username: string, accountId: string) : Promise<void> {
    const usernamesRef = firebase.firestore().doc(`usernames/${schoolId}`);

    await firebase.firestore().runTransaction(t => {
        return t.get(usernamesRef)
            .then(doc => {
                let usernames = doc.data() == null ? null : doc.data().usernames;
                if (usernames == null) {
                    usernames = {};
                }
                if (usernames[username] == null) {
                    usernames[username] = accountId;
                } else {
                    return Promise.reject('Username already in use');
                }
                t.set(usernamesRef, { usernames: usernames });
            });
    });
}

export async function confirmUsernameBatch(schoolId: string, usernameAccountIdMap: Map<string, string>) : Promise<string[]> {
    const usernamesRef = firebase.firestore().doc(`usernames/${schoolId}`);

    let failedAccountIds = await firebase.firestore().runTransaction(t => {
        return t.get(usernamesRef)
            .then(doc => {
                let usernames = doc.data() == null ? null : doc.data().usernames;
                if (usernames == null) {
                    usernames = {};
                }
                let failedAccountIds: string[] = [];
                Array.from(usernameAccountIdMap.keys()).forEach((username) => {
                    let accountId = usernameAccountIdMap.get(username);
                    if (usernames[username] == null) {
                        usernames[username] = accountId;
                    } else {
                        failedAccountIds.push(accountId);
                    }
                    t.set(usernamesRef, { usernames: usernames });
                });
                return failedAccountIds;
            });
    });
    return failedAccountIds;
}

export async function createUsername(usernameAccountId: string, usernameSubAccountId: string, usernamePassword?: string) : Promise<string> {

    let createUsernameFunction = firebase.functions().httpsCallable('createUsername');
    let password = await createUsernameFunction({
        usernameAccountId: usernameAccountId,
        usernameSubAccountId: usernameSubAccountId,
        usernamePassword: usernamePassword,
    });

    return password.data['password'];
}

export async function setPassword(usernameAccountId: string, usernameSubAccountId: string, usernamePassword: string) : Promise<void> {
    let idToken = await firebase.auth().currentUser.getIdToken();
    let authorization = 'Bearer ' + idToken;
    let response = await fetch('https://admin.lifeninja.net/adminRequests/setUsernamePassword', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        },
        body: JSON.stringify({
            usernameAccountId: usernameAccountId,
            usernameSubAccountId: usernameSubAccountId,
            usernamePassword: usernamePassword,
        })
    });
    if (response.status !== 200) {
        throw "Unable to create user";
    }
}
import firebase from '../firebase';
import { mapToObjectConverter, objectToClassConverter } from './database_object';

export class Quiz {

    constructor() {
        this.details = new QuizDetails();
    }

    details: QuizDetails = null;
    accounts: Map<string, boolean> = null;
    schoolId: string = null;

    static fromFirebase(data: any): Quiz {
        let newQuiz = new Quiz();
        if (data == null) {
            return newQuiz;
        }
        objectToClassConverter(data, newQuiz, ["accounts"], ["details"]);

        newQuiz.details = QuizDetails.fromFirebase(data.details);

        return newQuiz;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        if (this.details != null) {
            fbObject['details'] = this.details.toFirebase();
        }
        if (this.accounts != null) {
            fbObject['accounts'] = mapToObjectConverter(this.accounts);
        }
        return fbObject;
    }
}

export class QuizMinimised {
    dateMinimisedUpdated: number = null;
    active: boolean = null;
    name: string = null;
    quizDate: string = null; // ISO format. Becomes empty when quiz is no longer active
    type: string = null;

    static fromFirebase(data: any): QuizMinimised {
        let newQuizMinimised = new QuizMinimised();
        if (data == null) {
            return newQuizMinimised;
        }
        objectToClassConverter(data, newQuizMinimised);

        return newQuizMinimised;
    }
}

export class QuizDetails {
    active: boolean = null;
    questionIds: QuizRound[] = null;
    name: string = null;
    type: string = null;
    schedule: string = null;
    subjectId: string = null;
    currentQuestionIndex: number = null;
    currentQuestionActive: boolean = null;
    schoolId: string = null;
    creation: number = null;

    static fromFirebase(data: any): QuizDetails {
        let newQuizDetails = new QuizDetails();
        if (data == null) {
            return newQuizDetails;
        }
        objectToClassConverter(data, newQuizDetails, [], ["questionIds"]);

        let newQuizRounds = new Array<QuizRound>();
        if (data.questionIds != null) {
            for (let nextQuizRound of data.questionIds) {
                newQuizRounds.push(QuizRound.fromFirebase(nextQuizRound));
            }
        }
        newQuizDetails.questionIds = newQuizRounds;

        return newQuizDetails;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);

        let roundData: any[] = [];
        if (this.questionIds != null) {
            for (let nextRound of this.questionIds) {
                roundData.push(nextRound.toFirebase());
            }
        }
        fbObject.questionIds = roundData;

        if (this.creation == null) {
            fbObject['creation'] = new Date().toISOString();
        }

        return fbObject;
    }
}

export class QuizTemplate {
    name: string = null;
    subjectId: string = null;
    creation: firebase.firestore.Timestamp = null;
    updated: firebase.firestore.Timestamp = null;
    questionIds: QuizRound[] = null;
    schoolId: string = null;
    deactivated: boolean = null;
    zapEnabled: boolean = null;
    updatedBy: string = null;

    static fromFirebase(data: any): QuizTemplate {
        let newQuizTemplate = new QuizTemplate();
        if (data == null) {
            return newQuizTemplate;
        }
        objectToClassConverter(data, newQuizTemplate, [], ["questionIds"]);

        let newQuizRounds = new Array<QuizRound>();
        if (data.questionIds != null) {
            for (let nextQuizRound of data.questionIds) {
                newQuizRounds.push(QuizRound.fromFirebase(nextQuizRound));
            }
        }
        newQuizTemplate.questionIds = newQuizRounds;

        return newQuizTemplate;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        fbObject['updated'] = firebase.firestore.FieldValue.serverTimestamp();
        if (this.creation == null) {
            fbObject['creation'] = firebase.firestore.FieldValue.serverTimestamp();
        }

        let roundData: any[] = [];
        if (this.questionIds != null) {
            for (let nextRound of this.questionIds) {
                roundData.push(nextRound.toFirebase());
            }
        }
        fbObject.questionIds = roundData;

        return fbObject;
    }
}

export class QuizRound {
    id: string = null;
    type: string = null;

    static fromFirebase(data: any): QuizRound {
        let newQuizRound = new QuizRound();
        if (data == null) {
            return newQuizRound;
        }
        objectToClassConverter(data, newQuizRound);

        return newQuizRound;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class QuizRoundAnswer {
    correct: boolean = null;
    flawless: boolean = null;
    answers: string[] = null;

    static fromFirebase(data: any): QuizRoundAnswer {
        let newQuizRoundAnswer = new QuizRoundAnswer();
        if (data == null) {
            return newQuizRoundAnswer;
        }
        objectToClassConverter(data, newQuizRoundAnswer);

        return newQuizRoundAnswer;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class LeaderboardEntry {
    drums: number = null;

    static fromFirebase(data: any): LeaderboardEntry {
        let newLeaderboardEntry = new LeaderboardEntry();
        if (data == null) {
            return newLeaderboardEntry;
        }
        objectToClassConverter(data, newLeaderboardEntry);

        return newLeaderboardEntry;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}

export class QuizRoundAnalysis {
    fastestTime: number = null;
    startTime: number = null;

    static fromFirebase(data: any): QuizRoundAnalysis {
        let newQuizRoundAnalysis = new QuizRoundAnalysis();
        if (data == null) {
            return newQuizRoundAnalysis;
        }
        objectToClassConverter(data, newQuizRoundAnalysis);

        return newQuizRoundAnalysis;
    }

    toFirebase(): {} {
        return Object.assign({}, this);
    }
}
import React, { useRef, useEffect, Component } from 'react'
import { Button } from 'reactstrap';
import { TraceGameDrawingOffset } from '../data/learning_games';

interface IProps {
    points: TraceGameDrawingOffset[];
    drawCallback: Function;
    clearCallback: Function;
}

interface LineDetails {
    x: number;
    y: number;
    x1: number;
    y1: number;
}

const Canvas = (props: IProps) => {

    const canvas = useRef<HTMLCanvasElement>();
    let canvasEle: HTMLCanvasElement = null;
    let ctx: CanvasRenderingContext2D = null;

    // initialize the canvas context
    useEffect(() => {
        // dynamically assign the width and height to canvas
        canvasEle = canvas.current;
        canvasEle.width = canvasEle.clientWidth;
        canvasEle.height = canvasEle.clientHeight;

        // get context of the canvas
        ctx = canvasEle.getContext("2d");

        let previousPoint: TraceGameDrawingOffset;

        for (let nextPoint of props.points) {
            if (nextPoint != null && previousPoint != null) {
                drawLine({ x: previousPoint.dx, y: previousPoint.dy, x1: nextPoint.dx, y1: nextPoint.dy, });
            }
            previousPoint = nextPoint;
        }
        previousPoint = null;

        canvasEle.addEventListener("mousemove", (e) => {
            if (previousPoint != null) {
                let nextPoint = new TraceGameDrawingOffset();
                nextPoint.dx = e.offsetX;
                nextPoint.dy = e.offsetY;
                props.drawCallback(nextPoint);
                if (previousPoint != null) {
                    drawLine({ x: previousPoint.dx, y: previousPoint.dy, x1: nextPoint.dx, y1: nextPoint.dy, });
                }
                previousPoint = nextPoint;
            }
        }, false);
        canvasEle.addEventListener("mousedown", (e) => {
            let nextPoint = new TraceGameDrawingOffset();
            nextPoint.dx = e.offsetX;
            nextPoint.dy = e.offsetY;
            props.drawCallback(nextPoint);
            if (previousPoint != null) {
                drawLine({ x: previousPoint.dx, y: previousPoint.dy, x1: nextPoint.dx, y1: nextPoint.dy, });
            }
            previousPoint = nextPoint;
        }, false);
        canvasEle.addEventListener("mouseup", (e) => {
            let nextPoint = new TraceGameDrawingOffset();
            nextPoint.dx = e.offsetX;
            nextPoint.dy = e.offsetY;
            props.drawCallback(nextPoint);
            if (previousPoint != null) {
                drawLine({ x: previousPoint.dx, y: previousPoint.dy, x1: nextPoint.dx, y1: nextPoint.dy, });
            }
            props.drawCallback(null);
            previousPoint = null;
        }, false);
        canvasEle.addEventListener("mouseout", (e) => {
            if (previousPoint != null) {
                let nextPoint = new TraceGameDrawingOffset();
                nextPoint.dx = e.offsetX;
                nextPoint.dy = e.offsetY;
                props.drawCallback(nextPoint);
                if (previousPoint != null) {
                    drawLine({ x: previousPoint.dx, y: previousPoint.dy, x1: nextPoint.dx, y1: nextPoint.dy, });
                }
                props.drawCallback(null);
                previousPoint = null;
            }
        }, false);


    }, []);

    // draw a line
    const drawLine = (info: LineDetails) => {
        const { x, y, x1, y1 } = info;

        ctx.beginPath();
        ctx.globalCompositeOperation = 'source-over';
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 20;
        ctx.lineCap = "round";
        ctx.moveTo(x, y);
        ctx.lineTo(x1, y1);
        ctx.stroke();
    }

    const clearCanvas = () => {
        canvas.current.getContext("2d").clearRect(0, 0, canvas.current.width, canvas.current.height);
        props.clearCallback();
    }

    return <div>
        <canvas height="400" width="400" ref={canvas} style={{ borderStyle: "solid" }}></canvas>
        <Button onClick={clearCanvas} className="adminPagesButton">Clear</Button>
    </div>
}

export default Canvas;
import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import confirm from "reactstrap-confirm";
import DatePicker from "react-datepicker";
import { User } from './data/user';
import { BookableEvent } from './data/bookings';
import { string } from 'prop-types';

interface IState {
    slotGroups: Map<string, BookableEvent>,
    slotGroupIdsSorted: BookableEventStub[],
    cloneModal: boolean;
    cloneSlotGroupId: string;
    cloneSlotGroupTitle: string;
    cloneSlotGroupStartDate: Date;
    cloneSlotGroupOldStartDate: string;
}

interface IProps {
    user: User;
    snackbar: (text?: string) => void;
}

class BookableEventStub {

    constructor(newSlotGroupId: string, newStartDate: number) {
        this.slotGroupId = newSlotGroupId;
        this.startDate = newStartDate;
    }

    slotGroupId: string;
    startDate: number;
}

class SlotGroupsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            slotGroups: new Map(),
            slotGroupIdsSorted: [],
            cloneModal: false,
            cloneSlotGroupId: '',
            cloneSlotGroupTitle: '',
            cloneSlotGroupStartDate: null,
            cloneSlotGroupOldStartDate: null,
        };
        this.removeItem = this.removeItem.bind(this);
        this.toggleCloneModal = this.toggleCloneModal.bind(this);
        this.handleCreateClone = this.handleCreateClone.bind(this);
        this.readBookableEvents = this.readBookableEvents.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    render(): JSX.Element {
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All bookable events</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Bookable event</div>
                        <p className="cardSubTitle">Allow users to book onto an event at specific times</p>
                        <div>
                            <Link to={'/slotGroups/-1'}>
                                <Button className="adminPagesButton" type="button">
                                    Create bookable event
                                </Button>
                            </Link>
                            <a target="_blank" href="https://docs.google.com/document/d/1doyjniPwG_rd2gzGa2WaGjL6o4b2SFTcT64gYhkS0_Q/edit">
                                <Button style={{marginLeft:"15px"}} className="altButton">Read Tutorial</Button>
                            </a>
                            <Link to={`/tutorialVideo/BookableEvent`}>
                                <Button style={{marginLeft:"15px"}} className="altButton">Watch Tutorial</Button>
                            </Link>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>First slot date & time</th>
                                    <th>Last slot date & time</th>
                                    <th>Published</th>
                                    <th>Slot count</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.slotGroupIdsSorted.map((slotGroupDeets) => {
                                    let slotGroupId = slotGroupDeets.slotGroupId;
                                    let slotGroup = this.state.slotGroups.get(slotGroupId);
                                    if (slotGroup == null) {
                                        return "";
                                    }
                                    return <tr key={slotGroupId + 'tr'}>
                                        <th scope="row" key={slotGroupId + 'th'}><Link to={'slotGroups/' + slotGroupId}>{slotGroup.details.name}</Link></th>
                                        <td key={slotGroupId + 'td1'}>{this.makeShortString(slotGroup.details.description)}</td>
                                        <td key={slotGroupId + 'td2'}>{slotGroup.details.startDate == null ? "Unset" : moment(slotGroup.details.startDate).format("ddd, MMM Do YYYY, h:mm a")}</td>
                                        <td key={slotGroupId + 'td3'}>{slotGroup.details.endDate == null ? "Unset" : moment(slotGroup.details.endDate).format("ddd, MMM Do YYYY, h:mm a")}</td>
                                        <td key={slotGroupId + 'td4'}>{slotGroup.preview == null || !slotGroup.preview ? "Yes" : ""}</td>
                                        <td key={slotGroupId + 'td4'}>{slotGroup.slots == null ? 0 : slotGroup.slots.size}</td>
                                        <td key={slotGroupId + 'td6'}>
                                            {slotGroup.details.startDate != null ? <Button color="link" onClick={() => {
                                                this.setState({
                                                    cloneModal: true,
                                                    cloneSlotGroupId: slotGroupId,
                                                    cloneSlotGroupTitle: slotGroup.details.name,
                                                    cloneSlotGroupOldStartDate: slotGroup.details.startDate
                                                });
                                            }}><i className="material-icons">filter_none</i></Button> : <span />} &nbsp;
                                    <Button color="link" onClick={async () => {
                                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this bookable event", confirmText: "Confirm" });
                                                if (result) {
                                                    this.removeItem(slotGroupId)
                                                }
                                            }}><i className="material-icons">delete</i></Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        <Modal isOpen={this.state.cloneModal} toggle={this.toggleCloneModal}>
                            <ModalHeader toggle={this.toggleCloneModal}>Copy bookable event</ModalHeader>
                            <ModalBody>
                                <Form className="border rounded form-margin" onSubmit={this.handleCreateClone}>
                                    <FormGroup>
                                        <Label for="cloneSlotGroupTitle">New title *</Label>
                                        <Input type="text" required name="cloneSlotGroupTitle"
                                            onChange={(e) => this.setState({
                                                cloneSlotGroupTitle: e.target.value
                                            })}
                                            value={this.state.cloneSlotGroupTitle} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="cloneSlotGroupStartDate">New start date *</Label><br />
                                        <DatePicker
                                            dropdownMode='scroll'
                                            selected={this.state.cloneSlotGroupStartDate}
                                            required
                                            dateFormat="dd MMM yyyy"
                                            minDate={new Date()}
                                            onChange={this.handleDateChange}
                                            className="form-control"
                                        />
                                    </FormGroup>
                                    <br />
                                    <Button color="success">Create</Button>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </CardBody>
                </Card>
            </div>
        );
    }

    toggleCloneModal(): void {
        this.setState({
            cloneModal: !this.state.cloneModal,
        })
    }

    makeShortString(description: string): string {
        if (description.length < 30) {
            return description;
        }
        let descriptionResult = description.substr(0, 30);
        let spacePos = descriptionResult.lastIndexOf(" ");
        if (spacePos !== -1) {
            descriptionResult = descriptionResult.substr(0, spacePos);
        }
        descriptionResult += "...";

        return descriptionResult;
    }

    handleDateChange(date: Date): void {
        this.setState({
            cloneSlotGroupStartDate: date,
        });
    }

    async handleCreateClone(e: React.FormEvent): Promise<void> {
        e.preventDefault();

        this.setState({
            cloneModal: false,
        });

        let newDate = moment(this.state.cloneSlotGroupStartDate).startOf('day');
        let oldDate = moment(this.state.cloneSlotGroupOldStartDate).startOf('day');
        let daysAdd = newDate.diff(oldDate, 'days');

        let idToken = await firebase.auth().currentUser.getIdToken();
        let authorization = 'Bearer ' + idToken;
        let response = await fetch('https://admin.lifeninja.net/adminRequests/cloneBooking', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            },
            body: JSON.stringify({
                slotGroupId: this.state.cloneSlotGroupId,
                daysAdd: daysAdd,
                title: this.state.cloneSlotGroupTitle,
            })
        });
        if (response.status === 200) {
            this.props.snackbar();
        } else {
            this.props.snackbar("Update failed");
        }
        await this.readBookableEvents();
    }

    componentDidMount(): void {
        this.readBookableEvents();
    }

    async readBookableEvents(): Promise<void> {
        const slotGroupsRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}`);
        slotGroupsRef.once('value', (snapshot) => {
            let slotGroups = snapshot.val();
            let newState = new Map<string, BookableEvent>();
            let slotGroupIdsSorting = [];
            for (let nextSlotGroupId in slotGroups) {
                let nextSlotGroupData = slotGroups[nextSlotGroupId];
                let nextSlotGroup: BookableEvent = BookableEvent.fromFirebase(nextSlotGroupData);
                if (!nextSlotGroup.deactivated) {
                    newState.set(nextSlotGroupId, nextSlotGroup);
                    slotGroupIdsSorting.push(new BookableEventStub(nextSlotGroupId, nextSlotGroup.details.startDate != null ? new Date(nextSlotGroup.details.startDate).getTime() : new Date().getTime()))
                }
            }
            slotGroupIdsSorting.sort(function (a, b) {
                return b.startDate - a.startDate;
            });
            this.setState({
                slotGroups: newState,
                slotGroupIdsSorted: slotGroupIdsSorting,
            });
        }, (error) => console.log(error));
    }

    removeItem(slotGroupId: string): void {
        const slotGroupRef = firebase.database().ref(`bookingSlots/slotGroups/${this.props.user.schoolId}/${slotGroupId}/deactivated`);
        slotGroupRef.set(true, (error) => {
            if (error == null) {
                this.state.slotGroups.delete(slotGroupId);
                this.setState({});
                this.props.snackbar();
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
    }
}
export default SlotGroupsView;
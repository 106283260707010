import firebase from '../firebase';

export async function sendVerificationEmail(email: string, snackbar: Function) : Promise<void> {
    console.log(email);
    try {
        let sendVerificationEmailFunction = firebase.functions().httpsCallable('sendVerificationEmail');
        await sendVerificationEmailFunction({ "email": email, "app": 'the administration screens' });

        snackbar("Verification email sent");
    } catch (error) {
        console.log(error);
        snackbar("Send failed - Too many attempts. Wait for a while...");
    }
}

export async function sendPasswordResetEmail(email: string, snackbar: (text?: string) => void): Promise<void> {
    console.log(email);
    try {
        let sendPasswordResetEmailFunction = firebase.functions().httpsCallable('sendPasswordResetEmail');
        await sendPasswordResetEmailFunction({ "email": email, "app": 'the administration screens' });

        snackbar("Password reset email sent");
    } catch (error) {
        console.log(error);
        snackbar("Send failed - Too many attempts. Wait for a while...");
    }
}


export async function sendAccountEmail(accountId: string, schoolId: string, subAccountId: string, appName: string, snackbar: (text?: string) => void): Promise<void> {

    try {
        let idToken = await firebase.auth().currentUser.getIdToken();
        let authorization = 'Bearer ' + idToken;
        let response = await fetch('https://admin.lifeninja.net/adminRequests/sendAccountWelcome', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            },
            body: JSON.stringify({
                accountId: accountId,
                schoolId: schoolId,
                subAccountId: subAccountId,
                appName: appName,

            })
        });
        if (response.status !== 200) {
            snackbar("Invite failed to send");
        } else {
            snackbar("Invite sent");
        }
    } catch (error) {
        snackbar("Invite failed to send");
    }
};

export async function sendAdminEmail(accountId: string, schoolId: string, newLoginSubAccountId: string, snackbar: (text?: string) => void): Promise<void> {
    let idToken = await firebase.auth().currentUser.getIdToken();
    let authorization = 'Bearer ' + idToken;
    let response = await fetch('https://admin.lifeninja.net/adminRequests/sendAdminWelcome', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        },
        body: JSON.stringify({
            accountId: accountId,
            schoolId: schoolId,
            subAccountId: newLoginSubAccountId
        })
    });
    console.log(response);
    if (response.status !== 200) {
        snackbar("Invite failed to send");
    } else {
        snackbar("Invite sent");
    }
};
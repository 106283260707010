import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input, InputGroupAddon, InputGroup, Spinner,
    Breadcrumb, BreadcrumbItem, Alert, Table, Collapse, CardBody, Card, CardHeader, Modal, ModalHeader, ModalBody, UncontrolledTooltip
} from 'reactstrap';
import { HashLink as Link } from "react-router-hash-link";
import confirm from "reactstrap-confirm";
import { confirmUsernameUniqueness, createUsername, setPassword } from "./helpers/username_services";
import { sendAccountEmail, sendAdminEmail } from './helpers/email_services';
import { permissions } from './Prettifier';
import { v4 as uuidv4 } from 'uuid';
import { Account, AccountGroup, AccountGroupLink, AccountSubAccount, AdminAccount, SUB_ACCOUNT_TYPE } from './data/accounts';
import { User } from './data/user';
import { RouteComponentProps } from 'react-router';
import { License } from './data/license';
import { createUpdateFragment, objectToMapConverter } from './data/database_object';

interface IState {
    account: Account | null;
    accountId: string;
    currentAccountName: string;
    currentAccountTitle: string;
    currentAccountPersonalName: string;
    currentAccountFamilyName: string;
    currentAccountAdditional: string;
    currentAccountRemoteId: string;
    currentAccountSubAccounts: Map<string, AccountSubAccount>;
    currentAccountGroups: Map<string, AccountGroupLink>;
    currentAdminAccountPermissions: Map<string, boolean>;
    currentLicenses: Map<string, boolean>;
    currentAdminAccountExistingPermissions: Map<string, boolean>;
    currentSharingCode: string;
    currentIsAdmin: boolean;
    newAccountGroup: string;
    newLoginEmail: string;
    oldLoginEmail: string;
    newLoginRelationship?: string | null; // Name
    newLoginSubAccountId: string | null;
    newLoginType?: string | null; // Relationship
    newLoginMNAccess: string;
    newLoginAccountType: SUB_ACCOUNT_TYPE;
    newLoginPassword: string;
    existingUsername: string;
    currentAccountSubscribed: boolean;
    inviteSending: string | null;
    licenses: Map<string, License>;
    licenseIdsOrdered: string[];
    collapse: boolean;
    collapseAccountGroupAdd: boolean;
    collapseLoginAdd: boolean;
    collapseEmailSend: boolean;
    newLoginAccountOwner: boolean;
    allUsernames: Map<string, string> | null;
    updatingLicense: string;
    showUsernameWarning: boolean;
    hidden: boolean;
    newPassword: boolean;
    newUsername: boolean;
}

interface MatchParams {
    accountId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    schoolLicenses: Map<string, boolean>;
    snackbar: (text?: string) => void;
    accountGroups: Map<string, AccountGroup>;
}

class AccountView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            account: null,
            accountId: props.match.params.accountId,
            currentAccountName: '',
            currentAccountTitle: '',
            currentAccountPersonalName: '',
            currentAccountFamilyName: '',
            currentAccountAdditional: '',
            currentAccountRemoteId: "",
            currentAccountSubAccounts: new Map(),
            currentAccountGroups: new Map(),
            currentAdminAccountPermissions: new Map<string, boolean>(),
            currentLicenses: new Map<string, boolean>(),
            currentAdminAccountExistingPermissions: new Map<string, boolean>(),
            currentSharingCode: '',
            currentIsAdmin: false,
            newAccountGroup: '',
            newLoginEmail: '',
            oldLoginEmail: '',
            newLoginSubAccountId: null,
            newLoginMNAccess: 'full',
            newLoginAccountType: SUB_ACCOUNT_TYPE.EMAIL,
            newLoginPassword: '',
            existingUsername: '',
            currentAccountSubscribed: false,
            inviteSending: null,
            licenses: new Map<string, License>(),
            licenseIdsOrdered: [],
            collapse: props.match.params.accountId !== "-1",
            collapseAccountGroupAdd: true,
            collapseLoginAdd: true,
            collapseEmailSend: true,
            newLoginAccountOwner: true,
            allUsernames: null,
            updatingLicense: null,
            showUsernameWarning: false,
            hidden: true,
            newPassword: false,
            newUsername: false,
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleCreateSubAccount = this.handleCreateSubAccount.bind(this);
        this.handleLicenseChange = this.handleLicenseChange.bind(this);
        this.handleRemoveSubAccount = this.handleRemoveSubAccount.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.persistUser = this.persistUser.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleAccountGroupAdd = this.toggleAccountGroupAdd.bind(this);
        this.sendAccountEmail = this.sendAccountEmail.bind(this);
        this.canEditPermissions = this.canEditPermissions.bind(this);
        this.toggleLoginAdd = this.toggleLoginAdd.bind(this);
        this.openLoginAdd = this.openLoginAdd.bind(this);
        this.handleAccountOwnerCheckboxChange = this.handleAccountOwnerCheckboxChange.bind(this);
        this.toggleEmailSend = this.toggleEmailSend.bind(this);
        this.toggleShow = this.toggleShow.bind(this)
        this.openEmailSend = this.openEmailSend.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        this.handlePermissionChange = this.handlePermissionChange.bind(this);
        this.handlePermissionSave = this.handlePermissionSave.bind(this);
        this.sendAdminEmail = this.sendAdminEmail.bind(this);
        this.handleAccountTypeCheckboxChange = this.handleAccountTypeCheckboxChange.bind(this);
        this.prepareUsernameLookups = this.prepareUsernameLookups.bind(this);
        this.suggestUsername = this.suggestUsername.bind(this);
        this.handleLicenseChange = this.handleLicenseChange.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.togglePasswordShow = this.togglePasswordShow.bind(this);
    }

    render(): JSX.Element {

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/accounts'}>All accounts</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.account != null ? this.state.account.name : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Account</div>
                        <p className="cardSubTitle">An account is assigned to a single individual but can be linked to many different login emails (e.g. parents/carers)
                        For a school, parents and guardians are assigned to a student's account</p>
                        {
                            (this.state.account != null) ?
                                <div>
                                    {this.state.accountId === "-1" ? null :
                                        <Button onClick={this.toggle} className="altButton">
                                            {this.state.collapse ? "View details" : "Close"}
                                        </Button>
                                    }
                                    <Collapse isOpen={!this.state.collapse}>
                                        <Form onSubmit={this.handleUpdate}>
                                            {this.state.currentIsAdmin ?
                                                <Alert className="top-buffer" color="warning">
                                                    <h3>Admin enabled account</h3>
                                                    {!this.props.user.permissions.includes('ADMIN_MANAGER') ?
                                                        <p>Only an administrator can update account details for an admin-enabled account</p> : <span />
                                                    }
                                                </Alert> : <span />
                                            }
                                            <fieldset disabled={this.state.currentIsAdmin && !this.props.user.permissions.includes('ADMIN_MANAGER')}>
                                                <FormGroup>
                                                    <Label for="currentAccountTitle">Title</Label>
                                                    <Input type="text" name="currentAccountTitle" onChange={(e) => this.setState({
                                                        currentAccountTitle: e.target.value
                                                    })} value={this.state.currentAccountTitle} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="currentAccountPersonalName">First name *</Label>
                                                    <Input type="text" required name="currentAccountPersonalName" onChange={(e) => this.setState({
                                                        currentAccountPersonalName: e.target.value
                                                    })} value={this.state.currentAccountPersonalName} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="currentAccountFamilyName">Surname *</Label>
                                                    <Input type="text" required name="currentAccountFamilyName" onChange={(e) => this.setState({
                                                        currentAccountFamilyName: e.target.value
                                                    })} value={this.state.currentAccountFamilyName} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="currentAccountAdditional">Additional information <i className="fas fa-info-circle icons-info" id="help-account-additional" /></Label>
                                                    <Input type="text" name="currentAccountAdditional" onChange={(e) => this.setState({
                                                        currentAccountAdditional: e.target.value
                                                    })} value={this.state.currentAccountAdditional} />
                                                    <UncontrolledTooltip placement="bottom" autohide={false} target="help-account-additional">
                                                        Additional information is shown when selecting accounts on other pages (e.g. news item sending, account group addition). Use it to differentiate
                                                        accounts (e.g. where two students have the same name)
                                                    </UncontrolledTooltip>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="currentAccountRemoteId">Remote id <i className="fas fa-info-circle icons-info" id="help-account-remoteId" /></Label>
                                                    <Input type="text" name="currentAccountRemoteId" onChange={(e) => this.setState({
                                                        currentAccountRemoteId: e.target.value
                                                    })} value={this.state.currentAccountRemoteId} />
                                                    <UncontrolledTooltip placement="bottom" autohide={false} target="help-account-remoteId">
                                                        If you import account data from an external system then this is the account identifier from the remote system.
                                                        Only change this if you are completely confident that you should!
                                                    </UncontrolledTooltip>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="currentSharingCode">Sharing code (generated)</Label>
                                                    <Input type="text" disabled name="currentAccountAdditional" value={this.state.currentSharingCode} />
                                                </FormGroup>
                                                {this.state.currentIsAdmin && !this.props.user.permissions.includes('ADMIN_MANAGER') ? <span /> :
                                                    this.state.accountId === "-1" ?
                                                        <Button className="adminPagesButton">Create</Button> :
                                                        <Button className="adminPagesButton">Update</Button>
                                                }
                                            </fieldset>
                                        </Form>
                                    </Collapse>
                                </div> : <Spinner />
                        }
                    </CardBody></Card>
                {this.state.account != null ?
                    <React.Fragment>
                        {this.state.accountId !== "-1" ?
                            <Card className="mainCard top-buffer">
                                <CardBody className="d-flex flex-column">
                                    <div className="cardTitle2">Login details</div>
                                    <p className="cardSubTitle">User logins linked to the account</p>
                                    <div>
                                        <Button type="button" className="adminPagesButton buttonBuffer" onClick={() => {
                                            this.openLoginAdd(null, true);
                                        }}>Add login</Button>
                                    </div>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Relationship</th>
                                                <th>Email/Username</th>
                                                {this.props.schoolLicenses.has('messaging') && this.props.schoolLicenses.get('messaging') ?
                                                    <th>Message Ninja access</th> : null}
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.from(this.state.currentAccountSubAccounts.keys()).map((subAccountId) => {
                                                let subAccount = this.state.currentAccountSubAccounts.get(subAccountId)!;
                                                return <tr key={subAccountId + 'tr'}>
                                                    <th scope="row"
                                                        key={subAccountId + 'th'}>{subAccount.relationship == null || subAccount.relationship === "" ? "" : subAccount.relationship}</th>
                                                    <td key={subAccountId + 'td1'}>{subAccount.type == null ? "" : subAccount.type}</td>
                                                    <td key={subAccountId + 'td2'}>{subAccount.accountType === "username" && subAccount.email != null ? subAccount.email.substr(0, subAccount.email.indexOf("_")) : subAccount.email}</td>
                                                    {this.props.schoolLicenses.has('messaging') != null && this.props.schoolLicenses.get('messaging') ?
                                                        <td key={subAccountId + 'td3'}>{subAccount.mnAccess == null ? "Full" : subAccount.mnAccess === "full" ? "Full" : subAccount.mnAccess === "viewAndPayments" ? "View and payments" : "View only"}</td> : null}
                                                    <td key={subAccountId + 'td4'}>
                                                        {this.state.accountId !== this.props.user.accountId || subAccountId !== this.props.user.subAccountId ?
                                                            <span>
                                                                <Button key={subAccountId + 'editButton'} type="button" color="link" onClick={() => {
                                                                    this.openLoginAdd(subAccountId, false);
                                                                }}>
                                                                    <i className="material-icons">edit</i>
                                                                </Button>
                                                                {subAccount.accountType !== "username" ? <Button key={subAccountId + 'emailButton'} type="button" color="link" onClick={() => {
                                                                    this.openEmailSend(subAccountId);
                                                                }}>
                                                                    <i className="material-icons">email</i>
                                                                </Button> : <span />
                                                                }
                                                                <Button key={subAccountId + 'button'} type="button" color="link" onClick={async () => {
                                                                    let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this login", confirmText: "Confirm" });
                                                                    if (result) {
                                                                        this.handleRemoveSubAccount(subAccountId);
                                                                    }
                                                                }}>
                                                                    <i className="material-icons">delete</i>
                                                                </Button>
                                                            </span> : "Current login"
                                                        }
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody></Card> : <span />
                        }
                        {this.state.accountId !== "-1" && this.canEditPermissions() ?
                            <Card className="mainCard top-buffer">
                                <CardBody className="d-flex flex-column">
                                    <div className="cardTitle2">Admin permissions</div>
                                    <div>
                                        {this.state.currentIsAdmin ?
                                            <Button disabled={this.state.accountId === this.props.user.accountId} className="adminPagesButton buttonBuffer" onClick={async () => {
                                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove admin access for this account", confirmText: "Confirm" });
                                                if (result) {
                                                    this.handleActiveChange(false)
                                                }
                                            }}>Disable admin</Button> :
                                            <Button className="adminPagesButton buttonBuffer" onClick={() => this.handleActiveChange(true)}>Enable admin</Button>}
                                    </div>
                                    {this.state.currentIsAdmin ?
                                        <Form onSubmit={this.handlePermissionSave}>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Permission</th>
                                                        <th>Has permission</th>
                                                        <th>Can give permission to other admins</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.from(permissions.keys()).map((key) => {
                                                        let permission = permissions.get(key)!;
                                                        let managerKey = key + "_MANAGER";
                                                        let adminKey = key + "_ADMINISTRATOR";
                                                        let disabled = false;
                                                        if (!this.props.user.permissions.includes(adminKey)) {
                                                            disabled = true;
                                                        }
                                                        if (disabled && !this.state.currentAdminAccountPermissions.get(managerKey) &&
                                                            !this.state.currentAdminAccountPermissions.get(adminKey)) {
                                                            return "";
                                                        }
                                                        let foundLicense = true;
                                                        if (permission.licenses != null) {
                                                            foundLicense = false;
                                                            for (let nextLicense of permission.licenses) {
                                                                if (this.props.schoolLicenses.has(nextLicense) && this.props.schoolLicenses.get(nextLicense)) {
                                                                    foundLicense = true;
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                        if (!foundLicense) {
                                                            return "";
                                                        }
                                                        return (
                                                            <tr key={`${key}`}>
                                                                <td key={`title-${key}`}>
                                                                    <b>{permission.title}</b><br />
                                                                    {permission.description}
                                                                </td>
                                                                <td key={`manager-${key}`} align="center">
                                                                    <Input disabled={disabled} type="checkbox" name='currentAdminAccountPermissions'
                                                                        checked={this.state.currentAdminAccountPermissions.get(managerKey)}
                                                                        onChange={(e) => this.handlePermissionChange(e, key, true)} />
                                                                </td>
                                                                <td key={`admin-${key}`} align="center">
                                                                    <Input disabled={disabled} type="checkbox" name='currentAdminAccountPermissions'
                                                                        checked={this.state.currentAdminAccountPermissions.get(adminKey)}
                                                                        onChange={(e) => this.handlePermissionChange(e, key, false)} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table><br />
                                            <Button className="adminPagesButton">Update</Button></Form> : <div>Not admin enabled</div>
                                    }
                                </CardBody></Card> : <span />
                        }
                        {this.state.accountId !== "-1" ?
                            <Card className="mainCard top-buffer">
                                <CardBody className="d-flex flex-column">
                                    <div className="cardTitle2">Licenses</div>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Has license?</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.licenseIdsOrdered.map((licenseId) => {
                                                let license = this.state.licenses.get(licenseId)!;
                                                if (license == null) {
                                                    return null;
                                                }
                                                let enabled = false;
                                                if (this.state.currentLicenses.has(licenseId)) {
                                                    enabled = this.state.currentLicenses.get(licenseId)!;
                                                }
                                                return <tr key={licenseId + 'tr'}>
                                                    <th scope="row" key={licenseId + 'th'}><Link smooth to={`/licenses/${licenseId}#licensees`}>{license.name}</Link></th>
                                                    <td key={licenseId + 'td1'}>{enabled ? "Yes" : "No"}</td>
                                                    {!enabled ? <Button disabled={this.state.updatingLicense != null} onClick={() => this.handleLicenseChange(licenseId, true)} className="altButton">
                                                        {this.state.updatingLicense === licenseId ? <span><Spinner size="sm">
                                                        </Spinner>&nbsp;Adding....</span> : <span>Add</span>}
                                                    </Button> : <Button disabled={this.state.updatingLicense != null} onClick={() => this.handleLicenseChange(licenseId, false)} className="altButton">
                                                            {this.state.updatingLicense === licenseId ? <span><Spinner size="sm">
                                                            </Spinner>&nbsp;Removing....</span> : <span>Remove</span>}
                                                        </Button>}
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </CardBody></Card> : <span />
                        }
                        {this.state.accountId !== "-1" && this.props.user.permissions.includes('ACCOUNT_MANAGER') ?
                            <Card className="mainCard top-buffer">
                                <CardBody className="d-flex flex-column">
                                    <div className="cardTitle2">Account group membership</div>
                                    <div>
                                        <Button onClick={this.toggleAccountGroupAdd} className="adminPagesButton buttonBuffer">
                                            Add account group
                                        </Button>
                                    </div>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Additional</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.from(this.state.currentAccountGroups.keys()).map((accountGroupId) => {
                                                let accountGroupDeets = this.state.currentAccountGroups.get(accountGroupId)!;
                                                let accountGroup = this.props.accountGroups.get(accountGroupId);
                                                if (accountGroup == null || !accountGroupDeets.member) {
                                                    return "";
                                                }
                                                return <tr key={accountGroupId + 'tr'}>
                                                    <th scope="row" key={accountGroupId + 'th'}><Link to={'/accountGroups/' + accountGroupId}>{accountGroup.details.name}</Link></th>
                                                    <td key={accountGroupId + 'td1'}>{accountGroup.details.additional}</td>
                                                    <td key={accountGroupId + 'td2'}><Button key={accountGroupId + 'button'} color="link" onClick={async () => {
                                                        let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this account group membership", confirmText: "Confirm" });
                                                        if (result) {
                                                            this.removeMembership(accountGroupId)
                                                        }
                                                    }}><i className="material-icons">delete</i></Button></td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table><br />
                                </CardBody></Card> : <span />
                        }
                        <Modal isOpen={!this.state.collapseAccountGroupAdd} toggle={this.toggleAccountGroupAdd}>
                            <ModalHeader toggle={this.toggleAccountGroupAdd}>Add Account Group</ModalHeader>
                            <ModalBody>
                                <div className="border rounded form-margin">
                                    <FormGroup>
                                        <Label for="newAccountGroup">Groups</Label>
                                        <Input type="select" required name="newAccountGroup"
                                            onChange={(e) => this.setState({
                                                newAccountGroup: e.target.value
                                            })}>
                                            <option value={""}>&nbsp;</option>
                                            {Array.from(this.props.accountGroups.keys()).map((key) => {
                                                return (
                                                    <option value={key} key={key}>{this.props.accountGroups.get(key)!.details.name}</option>
                                                )
                                            })
                                            }
                                        </Input><br />
                                    </FormGroup>
                                    <Button color="success"
                                        disabled={this.state.newAccountGroup == null || this.state.newAccountGroup === ""}
                                        onClick={() => {
                                            this.handleGroupChange(false);
                                        }}>
                                        Add
                            </Button>&nbsp;
                            <Button color="success"
                                        disabled={this.state.newAccountGroup == null || this.state.newAccountGroup === ""}
                                        onClick={() => {
                                            this.handleGroupChange(true);
                                        }}>
                                        Add & close
                            </Button>
                                </div>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={!this.state.collapseLoginAdd} toggle={this.toggleLoginAdd}>
                            <ModalHeader toggle={this.toggleLoginAdd}>{this.state.newLoginSubAccountId == null ? "Add" : "Edit"} login details</ModalHeader>
                            <ModalBody>
                                <div className="border rounded form-margin">
                                    <Form onSubmit={this.handleCreateSubAccount}>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="newLoginAccountType"
                                                    onChange={this.handleAccountTypeCheckboxChange} checked={this.state.newLoginAccountType === SUB_ACCOUNT_TYPE.EMAIL} />
                                    Login via email address? <i className="fas fa-info-circle icons-info" id="help-account-loginEmail" />
                                            </Label>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-account-loginEmail">
                                                A user can login using an email address or a username. Usernames should only be used when an email address is not
                                                available (e.g. young children) because username password management must be carried out by administrators rather than by the users themselves.
                                    </UncontrolledTooltip>
                                        </FormGroup><br />
                                        {this.state.newLoginAccountType === SUB_ACCOUNT_TYPE.EMAIL ?
                                            <FormGroup>
                                                <Label for="newLoginEmail">Email *</Label>
                                                <Input type="email" required name="newLoginEmail"
                                                    onChange={(e) => this.setState({
                                                        newLoginEmail: e.target.value
                                                    })}
                                                    value={this.state.newLoginEmail} />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Label for="newLoginEmail">Username</Label>
                                                <Input required name="newLoginEmail" disabled={this.state.allUsernames == null}
                                                    onChange={this.handleUsernameChange}
                                                    value={this.state.newLoginEmail} />
                                                {this.state.showUsernameWarning ?
                                                    <p>Only letters, numbers, and hyphens '-' are allowed in a username</p> : <span />
                                                }
                                            </FormGroup>
                                        }
                                        {this.state.newLoginAccountType === SUB_ACCOUNT_TYPE.EMAIL ? <span /> :
                                            <FormGroup>
                                                {this.state.newPassword || this.state.newUsername ?
                                                    <div><Label>{this.state.newUsername ? "Initialise" : "Change"} Password *</Label>
                                                        <InputGroup>
                                                            <Input
                                                                minLength={6}
                                                                // @ts-ignore
                                                                type={this.state.hidden ? 'password' : 'test'} required name="newLoginPassword"
                                                                onChange={(e) => this.setState({
                                                                    newLoginPassword: e.target.value
                                                                })}
                                                                value={this.state.newLoginPassword} />
                                                            <InputGroupAddon addonType="append">
                                                                <Button style={{ backgroundColor: '#FFFFFF', borderColor: '#D0D0D0' }} onClick={this.toggleShow} type="button"><i className="fas fa-eye" style={{ color: "black" }} /></Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </div> : <Button onClick={this.togglePasswordShow} >Change Password</Button>
                                                }
                                            </FormGroup>
                                        }
                                        {this.props.schoolLicenses.has('messaging') && this.props.schoolLicenses.get('messaging') ?
                                            <FormGroup>
                                                <Label for="newLoginMNAccess">Message Ninja access * <i className="fas fa-info-circle icons-info" id="help-account-mnAccess" /></Label>
                                                <Input type="select" required name="newLoginMNAccess" onChange={(e) => this.setState({
                                                    newLoginMNAccess: e.target.value
                                                })} value={this.state.newLoginMNAccess}>
                                                    <option value={"full"}>Full access</option>
                                                    <option value={"viewAndPayments"}>View and payments only</option>
                                                    <option value={"view"}>View only</option>
                                                </Input>
                                                <UncontrolledTooltip placement="bottom" autohide={false} target="help-account-mnAccess">
                                                    Full access (Suggested for parents/guardians): All news items, all events, all chat access, make purchases, fundraising and marketplace.<br />
                                            View and payments only (Suggested for grandparents): All unrestricted news items and events, make purchases, fundraising and marketplace.<br />
                                            View only (Suggested for young students): All unrestricted news items and events.
                                        </UncontrolledTooltip>
                                            </FormGroup> : <span />
                                        }
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="newLoginAccountOwner"
                                                    onChange={this.handleAccountOwnerCheckboxChange} checked={this.state.newLoginAccountOwner} />
                                    Login details for {this.state.currentAccountPersonalName} <i className="fas fa-info-circle icons-info" id="help-account-loginDetails" />
                                            </Label>
                                            <UncontrolledTooltip placement="bottom" autohide={false} target="help-account-loginDetails">
                                                Are these the login details that {this.state.currentAccountPersonalName} will use or are these for
                                        another person, e.g. relative, guardian, carer?
                                    </UncontrolledTooltip>
                                        </FormGroup><br />
                                        <Collapse isOpen={!this.state.newLoginAccountOwner}>
                                            <FormGroup>
                                                <Label for="newLoginRelationship">Linked user's name *</Label>
                                                <Input disabled={this.state.newLoginAccountOwner} type="text" required name="newLoginRelationship"
                                                    onChange={(e) => this.setState({
                                                        newLoginRelationship: e.target.value
                                                    })}
                                                    value={this.state.newLoginRelationship == null ? "" : this.state.newLoginRelationship} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newLoginType">Linked user's relationship to account (e.g. parent/carer)</Label>
                                                <Input disabled={this.state.newLoginAccountOwner} type="text" name="newLoginType"
                                                    onChange={(e) => this.setState({
                                                        newLoginType: e.target.value
                                                    })}
                                                    value={this.state.newLoginType == null ? "" : this.state.newLoginType} />
                                            </FormGroup>
                                        </Collapse>
                                        <br />
                                        <Button color="success">
                                            {this.state.newLoginSubAccountId == null ? "Create" : "Save"}
                                        </Button>&nbsp;
                            </Form>
                                </div>
                            </ModalBody>
                        </Modal>

                        <Modal isOpen={!this.state.collapseEmailSend} toggle={this.toggleEmailSend}>
                            <ModalHeader toggle={this.toggleEmailSend}>Send invite</ModalHeader>
                            <ModalBody>
                                <p>Send invites to the user so that they are guided to install the app and register their account</p>
                                {this.state.newLoginSubAccountId == null ? "The account has no login details" :
                                    <>
                                        <Button disabled={this.state.inviteSending != null}
                                            outline onClick={() => {
                                                this.sendAccountEmail(this.state.newLoginSubAccountId!, "LearningNinja");
                                            }}>{this.state.inviteSending != null && this.state.inviteSending === "LearningNinja" ? "Sending..." : "Send Learning Ninja invite"}</Button><br /><br />
                                        <Button disabled={this.state.inviteSending != null}
                                            outline onClick={() => {
                                                this.sendAccountEmail(this.state.newLoginSubAccountId!, "MessageNinja");
                                            }}>{this.state.inviteSending != null && this.state.inviteSending === "MessageNinja" ? "Sending..." : "Send Message Ninja invite"}</Button><br /><br />
                                        {!this.state.currentIsAdmin ? <span /> :
                                            <Button disabled={this.state.inviteSending != null} outline
                                                onClick={this.sendAdminEmail}>{this.state.inviteSending != null && this.state.inviteSending === "Admin" ? "Sending..." : "Send admin invite"}</Button>
                                        }
                                    </>
                                }
                            </ModalBody>
                        </Modal>
                    </React.Fragment> : <br />
                }
                <br /><br />
            </div>
        );
    }

    canEditPermissions(): boolean {
        return this.props.user.permissions.includes('ADMIN_MANAGER') ||
            this.props.user.permissions.includes('ADMIN_ADMINISTRATOR') ||
            this.props.user.permissions.includes('ACCOUNT_ADMINISTRATOR') ||
            this.props.user.permissions.includes('MODULE_ADMINISTRATOR') ||
            this.props.user.permissions.includes('MESSAGE_ADMINISTRATOR') ||
            this.props.user.permissions.includes('EVENT_ADMINISTRATOR') ||
            this.props.user.permissions.includes('REPORTING_ADMINISTRATOR');
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    togglePasswordShow(): void {
        this.setState({
            newPassword: true,
        });
    }

    toggleAccountGroupAdd(): void {
        this.setState({
            collapseAccountGroupAdd: !this.state.collapseAccountGroupAdd,
            newAccountGroup: '',
        });
    }

    async handleActiveChange(active: boolean): Promise<void> {
        const adminPermissionsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/admins/${this.state.accountId}/permissions`);
        await adminPermissionsRef.remove();
        const adminAccountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/${this.state.accountId}/isAdmin/`);
        await adminAccountRef.set(active, (error) => {
            if (error == null) {
                this.props.snackbar();
            } else {
                console.log(error);
                this.props.snackbar("Update failed");
            }
        });
        this.setState({
            currentAdminAccountPermissions: new Map<string, boolean>(),
            currentIsAdmin: active,
        });
    }

    handlePermissionChange(e: React.ChangeEvent<HTMLInputElement>, permissionId: string, isManager: boolean): void {
        let active = e.target.checked;
        let managerKey = permissionId + "_MANAGER";
        let adminKey = permissionId + "_ADMINISTRATOR";
        if (active) {
            // Ensure manager is always checked for administrator check (and obvs for manager check!)
            this.state.currentAdminAccountPermissions.set(managerKey, true);
            if (!isManager) {
                this.state.currentAdminAccountPermissions.set(adminKey, true);
            }
        } else {
            // Ensure administrator is always unchecked for manager uncheck (and obvs for administrator uncheck!)
            this.state.currentAdminAccountPermissions.set(adminKey, false);
            if (isManager) {
                this.state.currentAdminAccountPermissions.set(managerKey, false);
            }
        }
        this.setState({});
    }

    async handleLicenseChange(licenseId: string, active: boolean): Promise<void> {
        this.setState({
            updatingLicense: licenseId,
        });
        try {
            if (active) {
                let addLicenses = firebase.functions().httpsCallable('addAccountLicenses');
                await addLicenses({ "schoolId": this.props.user.schoolId, "accountIds": [this.state.accountId], "licenseId": licenseId });


            } else {
                let removeAccountLicenses = firebase.functions().httpsCallable('removeAccountLicenses');
                await removeAccountLicenses({ "schoolId": this.props.user.schoolId, "accountIds": [this.state.accountId], "licenseId": licenseId });
            }
            this.state.currentLicenses.set(licenseId, active);
        } finally {
            this.setState({
                updatingLicense: null,
            });
        }
    }

    async handlePermissionSave(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let permissionsToSave = new Map<string, boolean>();

        for (let [permissionId, value] of this.state.currentAdminAccountPermissions) {
            if (value != null && this.state.currentAdminAccountExistingPermissions.get(permissionId) !== value) {
                permissionsToSave.set(permissionId, value);
            }
        }
        let promises: Array<Promise<any>> = [];
        try {
            Array.from(permissionsToSave.keys()).forEach((permissionId) => {
                let adminAccountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/admins/${this.state.accountId}/permissions/${permissionId}`);
                if (permissionsToSave.get(permissionId)) {
                    promises.push(adminAccountRef.set(true));
                } else {
                    promises.push(adminAccountRef.remove());
                }
            });
            await Promise.all(promises);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
        this.setState({
            currentAdminAccountExistingPermissions: new Map<string, boolean>([...this.state.currentAdminAccountPermissions]),
        })
    }

    openLoginAdd(subAccountId: string, newUsername: boolean): void {
        let newLoginEmail = '';
        let newLoginRelationship: string | null = '';
        let newLoginAccountOwner = true;
        let newLoginType: string | null = "";
        let newLoginAccountType = SUB_ACCOUNT_TYPE.EMAIL;
        let newLoginMNAccess = "full";
        if (subAccountId != null) {
            let subAccount = this.state.currentAccountSubAccounts.get(subAccountId);
            if (subAccount == null) {
                console.log("Error, mismatch on subAccountId");
                return;
            }
            newLoginEmail = subAccount.email == null ? "" : subAccount.email;
            newLoginRelationship = subAccount.relationship;
            if (newLoginRelationship != null && newLoginRelationship !== "") {
                newLoginAccountOwner = false;
            }
            newLoginType = subAccount.type;
            newLoginAccountType = subAccount.accountType == null ? SUB_ACCOUNT_TYPE.EMAIL : subAccount.accountType;
            if (newLoginAccountType === SUB_ACCOUNT_TYPE.USERNAME) {
                newLoginEmail = newLoginEmail.substr(0, newLoginEmail.indexOf("_"));
            }
            newLoginMNAccess = subAccount.mnAccess == null ? "full" : subAccount.mnAccess;
        }
        if (newLoginAccountType === SUB_ACCOUNT_TYPE.USERNAME) {
            this.prepareUsernameLookups();
        }
        this.setState({
            newLoginSubAccountId: subAccountId,
            collapseLoginAdd: !this.state.collapseLoginAdd,
            newLoginEmail: newLoginEmail,
            existingUsername: newLoginEmail,
            newLoginAccountOwner: newLoginAccountOwner,
            newLoginRelationship: newLoginRelationship,
            newLoginMNAccess: newLoginMNAccess,
            newLoginType: newLoginType,
            newLoginAccountType: newLoginAccountType,
            oldLoginEmail: newLoginEmail,
            newPassword: newUsername,
            newUsername: newUsername,
        });
    }

    openEmailSend(subAccountId: string): void {
        this.setState({
            newLoginSubAccountId: subAccountId,
            collapseEmailSend: !this.state.collapseEmailSend,
        });
    }

    async handleRemoveSubAccount(subAccountId: string): Promise<void> {
        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${this.state.accountId}/subAccounts/${subAccountId}`);
        await accountGroupRef.remove();

        let newSubAccounts = new Map<string, AccountSubAccount>([...this.state.currentAccountSubAccounts]);
        newSubAccounts.delete(subAccountId);
        this.setState({
            currentAccountSubAccounts: newSubAccounts
        });
        this.props.snackbar();
    }

    toggleShow(): void {
        this.setState({
            hidden: !this.state.hidden
        })
    }

    async handleAccountTypeCheckboxChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let active = e.target.checked;
        let newLoginType = active ? SUB_ACCOUNT_TYPE.EMAIL : SUB_ACCOUNT_TYPE.USERNAME;
        if (!active) {
            await this.prepareUsernameLookups();
            this.setState({
                newLoginEmail: this.suggestUsername(),
                newLoginAccountType: newLoginType,
            });
        } else {
            this.setState({
                newLoginAccountType: newLoginType,
                newLoginEmail: "",
            });
        }
    }

    handleAccountOwnerCheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let active = e.target.checked;
        this.setState({
            newLoginAccountOwner: active,
            newLoginRelationship: "",
            newLoginType: "",
        });
    }

    async handleCreateSubAccount(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let newEmail = this.state.newLoginEmail.toLowerCase();
        let newLoginEmail = newEmail;

        if (this.state.newLoginAccountType === SUB_ACCOUNT_TYPE.USERNAME && this.state.existingUsername !== newEmail) {
            if (this.state.allUsernames == null) {
                console.log("Error, usernames not initialised");
                return;
            }
            try {
                await confirmUsernameUniqueness(this.props.user.schoolId, newEmail, this.state.accountId);
                this.state.allUsernames.set(this.state.newLoginEmail, this.state.accountId);
            } catch {
                this.props.snackbar("Username already in use");
                return;
            }
        }

        if (this.state.newLoginAccountType === SUB_ACCOUNT_TYPE.USERNAME) {
            newEmail = `${newEmail}_${this.props.user.schoolId}@usernames.lifeninja.net`.toLowerCase();
        }

        let subAccountDeets = new AccountSubAccount();
        subAccountDeets.relationship = this.state.newLoginRelationship != null ? this.state.newLoginRelationship : "";
        subAccountDeets.email = newEmail;
        subAccountDeets.type = this.state.newLoginType != null ? this.state.newLoginType : null;
        subAccountDeets.accountType = this.state.newLoginAccountType;
        subAccountDeets.mnAccess = this.state.newLoginMNAccess != null ? this.state.newLoginMNAccess : "full";


        let subAccountId;
        if (this.state.newLoginSubAccountId == null) {
            subAccountId = uuidv4();
        } else {
            subAccountId = this.state.newLoginSubAccountId;
        }

        if (this.state.newLoginSubAccountId == null || newLoginEmail !== this.state.oldLoginEmail) {
            console.log("Creating email entry");
            let newEmailEscaped = newEmail.replace(/\./g, ',');
            const subAccountEmailRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${this.state.accountId}/emails/${newEmailEscaped}`);
            try {
                await subAccountEmailRef.set(subAccountId);
            } catch (error) {
                this.props.snackbar("Duplicate email address");
                return;
            }
        }
        try {
            console.log("Checking if need to remove email");
            if (newLoginEmail !== this.state.oldLoginEmail && this.state.oldLoginEmail !== "") {
                console.log("Need to remove email");
                let oldEmailEscaped = this.state.oldLoginEmail.replace(/\./g, ',');
                const subAccountRemoveEmailRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${this.state.accountId}/emails/${oldEmailEscaped}`);
                await subAccountRemoveEmailRef.remove();
            }

            const subAccountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${this.state.accountId}/subAccounts/${subAccountId}`);
            await subAccountGroupRef.set(subAccountDeets);
        } catch (error) {
            console.log("Change email failed");
            if (this.state.newLoginSubAccountId == null || newLoginEmail !== this.state.oldLoginEmail) {
                console.log("Clearing email entry");
                let newEmailEscaped = newEmail.replace(/\./g, ',');
                const subAccountEmailRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${this.state.accountId}/emails/${newEmailEscaped}`);
                try {
                    await subAccountEmailRef.remove();
                } catch (error) {
                    this.props.snackbar("Email issue - contact support");
                    return;
                }
            }
        }

        if (this.state.newLoginAccountType === SUB_ACCOUNT_TYPE.USERNAME) {
            if (this.state.existingUsername !== this.state.newLoginEmail) {
                createUsername(this.state.accountId, subAccountId, this.state.newLoginPassword);
            } else if (this.state.newLoginPassword != null && this.state.newLoginPassword !== '') {
                setPassword(this.state.accountId, subAccountId, this.state.newLoginPassword);
            }
        }

        this.setState({
            currentAccountSubAccounts: new Map<string, AccountSubAccount>([
                ...this.state.currentAccountSubAccounts,
                [subAccountId, subAccountDeets]
            ]),
            newLoginPassword: '',
            collapseLoginAdd: true
        });
        this.props.snackbar();
    }





    toggleLoginAdd(): void {
        this.setState({ collapseLoginAdd: !this.state.collapseLoginAdd });
    }

    toggleEmailSend(): void {
        this.setState({ collapseEmailSend: !this.state.collapseEmailSend });
    }

    async sendAccountEmail(subAccountId: string, appName: string): Promise<void> {
        this.setState({
            inviteSending: appName,
        });
        sendAccountEmail(this.state.accountId, this.props.user.schoolId, subAccountId, appName, this.props.snackbar);
        this.setState({
            inviteSending: null,
            collapseEmailSend: true,
        });
    }

    async sendAdminEmail(): Promise<void> {
        this.setState({
            inviteSending: "Admin",
        });
        if (this.state.newLoginSubAccountId != null) {
            sendAdminEmail(this.state.accountId, this.props.user.schoolId, this.state.newLoginSubAccountId, this.props.snackbar);
        } else {
            console.log("Error, couldn't send due to no sub account");
        }
        this.setState({
            inviteSending: null,
            collapseEmailSend: true,
        });
    }

    async handleGroupChange(close: boolean): Promise<void> {
        let accountGroupLink = new AccountGroupLink();
        accountGroupLink.member = true;
        let accountGroupLinkData = accountGroupLink.toFirebase();

        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${this.state.newAccountGroup}/accounts/${this.state.accountId}`);
        await accountGroupRef.set(accountGroupLinkData, (error) => {
            if (error == null) {
                this.state.currentAccountGroups.set(this.state.newAccountGroup, accountGroupLink);
                this.props.snackbar();
                this.setState({
                    collapseAccountGroupAdd: close == null ? false : close,
                });
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
    }

    handleUsernameChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let username = e.target.value.toLowerCase();
        var re = new RegExp(/^([a-z0-9-]*)$/);
        if (re.test(e.target.value)) {
            this.setState({
                showUsernameWarning: false,
                newLoginEmail: username,
                newPassword: true,
                newUsername: true,
            });
        } else {
            this.setState({
                showUsernameWarning: true,
                newPassword: true,
                newUsername: true,
            });
            console.log("Invalid");
        }
    }

    async removeMembership(accountGroupId: string): Promise<void> {
        let accountMemberLink = new AccountGroupLink();
        accountMemberLink.member = false;

        let accountMemberLinkData = accountMemberLink.toFirebase();

        const accountGroupRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accountGroups/${accountGroupId}/accounts/${this.state.accountId}`);
        await accountGroupRef.set(accountMemberLinkData, (error) => {
            if (error == null) {
                this.state.currentAccountGroups.set(accountGroupId, accountMemberLink);
                this.props.snackbar();
                this.setState({});
            } else {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        });
    }

    handleUpdate(e: React.FormEvent): void {
        e.preventDefault();
        let account = new Account();
        account.title = this.state.currentAccountTitle;
        account.personalName = this.state.currentAccountPersonalName;
        account.familyName = this.state.currentAccountFamilyName;
        account.name = account.title != null && account.title !== '' ? account.title + " " : "";
        account.name += account.personalName + " " + account.familyName;
        account.additional = this.state.currentAccountAdditional;
        account.subscribed = this.state.currentAccountSubscribed;
        account.remoteId = this.state.currentAccountRemoteId !== "" ? this.state.currentAccountRemoteId : null;
        this.persistUser(account);
    }

    async persistUser(account: Account): Promise<void> {
        if (this.state.accountId === "-1") {
            let uniqueIdHopefully = uuidv4().substring(2, 5).toLowerCase();
            uniqueIdHopefully += new Date().getTime().toString(36);
            account.sharingCode = uniqueIdHopefully;
        }


        if (this.state.accountId === "-1") {
            try {
                const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/`);
                let promise = accountRef.push(account.toFirebase());
                let newKey = promise.key;
                await promise;
                this.setState({
                    account: account,
                    accountId: newKey,
                    currentSharingCode: account.sharingCode,
                    collapse: true,
                });
                this.openLoginAdd(null, true);
                this.props.history.replace(`/accounts/${newKey}`);
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed")
            }
        } else {
            try {
                const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/${this.state.accountId}`);
                await accountRef.update(createUpdateFragment(account.toFirebase(), ["title", "personalName", "familyName", "name", "additional", "subscribed", "remoteId", "updated"]));

                this.setState({
                    account: account,
                    collapse: true,
                });
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Update failed");
            }
        }
    }

    async prepareUsernameLookups(clearCache: boolean = false): Promise<void> {
        if (this.state.allUsernames == null || clearCache) {
            const usernamesRef = firebase.firestore().doc(`usernames/${this.props.user.schoolId}`);
            let usernamesSnapshot = await usernamesRef.get();
            let usernames = new Map<string, string>();
            if (usernamesSnapshot.data() != null) {
                usernames = objectToMapConverter(usernamesSnapshot.data());
            }
            this.setState({
                allUsernames: usernames,
            });
        }
    }

    suggestUsername(): string {
        let firstSuggestion = this.state.currentAccountPersonalName.replace(/\s/g, '') + this.state.currentAccountFamilyName.replace(/\s/g, '')[0];
        firstSuggestion = firstSuggestion.toLowerCase();
        let suggestion = firstSuggestion + "1";
        for (let i = 2; this.state.allUsernames.has(suggestion); i++) {
            suggestion = firstSuggestion + i;
        }
        return suggestion;
    }

    async componentDidMount(): Promise<void> {

        try {
            if (this.state.accountId !== "-1") {
                const accountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/${this.state.accountId}`);
                let accountSnapshot = await accountRef.once('value');
                let account = Account.fromFirebase(accountSnapshot.val());

                const subAccountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/subAccounts/${this.state.accountId}/subAccounts`);
                let subAccountSnapshot = await subAccountRef.once('value');
                let subAccountsData = subAccountSnapshot.val();
                let subAccounts = new Map<string, AccountSubAccount>();
                if (subAccounts != null) {
                    for (let nextSubAccountId in subAccountsData) {
                        subAccounts.set(nextSubAccountId, AccountSubAccount.fromFirebase(subAccountsData[nextSubAccountId]));
                    }
                }

                let adminAccount = null;
                if (this.canEditPermissions()) {
                    const adminRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/admins/${this.state.accountId}`);
                    let adminAccountSnapshot = await adminRef.once('value');
                    adminAccount = AdminAccount.fromFirebase(adminAccountSnapshot.val());
                }
                if (adminAccount == null) {
                    adminAccount = new AdminAccount();
                }

                let assignedLicensesDoc = await firebase.firestore().doc(`schoolLicenses/${this.props.user.schoolId}/students/${this.state.accountId}`).get();
                let assignedLicenses = assignedLicensesDoc.data();

                this.setState({
                    account: account,
                    currentLicenses: assignedLicenses == null ? new Map<string, boolean>() : objectToMapConverter(assignedLicenses),
                    currentAccountName: account.name,
                    currentAccountTitle: account.title != null ? account.title : null,
                    currentAccountPersonalName: account.personalName,
                    currentAccountFamilyName: account.familyName,
                    currentAccountAdditional: account.additional,
                    currentAccountSubAccounts: subAccounts,
                    currentAccountGroups: account.acGroups != null ? account.acGroups : new Map<string, AccountGroupLink>(),
                    currentAccountSubscribed: account.subscribed != null ? account.subscribed : true,
                    currentSharingCode: account.sharingCode,
                    currentAccountRemoteId: account.remoteId != null ? account.remoteId : "",
                    currentIsAdmin: account.isAdmin != null ? account.isAdmin : false,
                    newAccountGroup: '',
                    currentAdminAccountPermissions: adminAccount.permissions != null ? adminAccount.permissions : new Map<string, boolean>(),
                    currentAdminAccountExistingPermissions: adminAccount.permissions != null ? new Map([...adminAccount.permissions]) : new Map<string, boolean>(),
                });
            } else {
                this.setState({
                    account: new Account(),
                    currentAccountName: '',
                    currentAccountTitle: '',
                    currentAccountPersonalName: '',
                    currentAccountFamilyName: '',
                    currentAccountAdditional: '',
                    currentAccountRemoteId: '',
                    currentAccountSubAccounts: new Map(),
                    currentAccountGroups: new Map<string, AccountGroupLink>(),
                    currentSharingCode: '',
                    currentAccountSubscribed: true,
                    currentIsAdmin: false,
                    newAccountGroup: '',
                    currentAdminAccountPermissions: new Map<string, boolean>(),
                    currentLicenses: new Map<string, boolean>(),
                    currentAdminAccountExistingPermissions: new Map<string, boolean>(),
                });
            }

            let licensesSnapshot = await firebase.firestore().doc('generalConfig/licenseDetails').get();
            let licenseDetails = licensesSnapshot.data();
            let newLicensesState = new Map<string, License>();
            for (let nextLicenseId in licenseDetails.licenses) {
                if (!this.props.schoolLicenses.has(nextLicenseId) || !this.props.schoolLicenses.get(nextLicenseId)) {
                    continue;
                }
                let nextLicense: License = License.fromFirebase(licenseDetails.licenses[nextLicenseId]);
                newLicensesState.set(nextLicenseId, nextLicense);
            }

            let newLicenseIdsOrdered = Array.from(newLicensesState.keys()).sort((a, b) => {
                return newLicensesState.get(a).name.localeCompare(newLicensesState.get(b).name);
            });

            this.setState({
                licenses: newLicensesState,
                licenseIdsOrdered: newLicenseIdsOrdered,
            });
        } catch (error) {
            console.log(error)
        }
    }
}

export default AccountView;
//@ts-check

import React, { Component } from 'react';
import firebase from '../firebase';
import {
    Button, FormGroup, Label, Input, InputGroup, InputGroupAddon, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import Compress from 'compress.js';
import { v4 as uuidv4 } from 'uuid';
import { Image } from '../data/home_learning';
import { UploadMetadata } from '@firebase/storage-types';

var pdflib = require('pdfjs-dist/webpack');

// pdflib.GlobalWorkerOptions.workerPort = new pdfjsWorker();
// pdflib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
pdflib.GlobalWorkerOptions.workerSrc = '/pdf.worker';

interface IState {
    currentImage: Image;
    currentTempWebImage: string;
    imageUploading: boolean;
    setImageView: boolean;
}

interface IProps {
    currentImage: Image;
    filePath: string;
    accountId?: string; // Must have one of account id or school id
    schoolId?: string;
    newUploadButtonTitle?: string;
    removeUploadButtonTitle?: string;
    imageLegend?: string;
    callback: (url: string | null, path: string | null) => void;
    failureCallback: (error: string) => void;
}

class ImageUpload extends Component<IProps, IState> {
    legend: string;
    newUploadButtonTitle: string;
    removeUploadButtonTitle: string;

    constructor(props: IProps) {
        super(props);
        this.state = {
            currentImage: props.currentImage,
            currentTempWebImage: '',
            imageUploading: false,
            setImageView: false,
        };
        this.newUploadButtonTitle = props.newUploadButtonTitle == null ? "Set image" : props.newUploadButtonTitle;
        this.removeUploadButtonTitle = props.removeUploadButtonTitle == null ? "Remove image" : props.removeUploadButtonTitle;
        this.legend = props.imageLegend != null && props.imageLegend.length > 0 ? props.imageLegend : "Image";
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleFileRemove = this.handleFileRemove.bind(this);
        this.handleWebChange = this.handleWebChange.bind(this);
        this.toggleSetImageModal = this.toggleSetImageModal.bind(this);
    }

    componentDidUpdate(prevProps: IProps, prevState: IState, snapshot: any): void {
        if (this.props.currentImage.url !== this.state.currentImage.url) {
            this.setState({
                currentImage: this.props.currentImage,
            });
        }
    }

    render(): JSX.Element {
        return <div>
            <Label>{this.legend}</Label><br />
            {(this.state.currentImage == null || this.state.currentImage.url == null ||
                this.state.currentImage.url.length === 0) && !this.state.imageUploading ?
                <FormGroup>
                    <Button onClick={this.toggleSetImageModal} outline>{this.newUploadButtonTitle}</Button>
                </FormGroup>
                : this.state.imageUploading ?
                    <FormGroup>
                        <Label>Uploading...</Label>
                    </FormGroup>
                    : <FormGroup>
                        <img src={this.state.currentImage.url} height="80px" alt="" />&nbsp;&nbsp;
                        <Button onClick={this.handleFileRemove} outline>{this.removeUploadButtonTitle}</Button>
                    </FormGroup>
            }
            <Modal isOpen={this.state.setImageView} toggle={this.toggleSetImageModal}>
                <ModalHeader toggle={this.toggleSetImageModal}>Select attachment</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="file">Upload from computer</Label>
                        <InputGroup>
                            <Input type="file" id="file" name="file" onChange={this.handleFileUpload} />
                            <Label className="custom-file-label" for="file">Choose file</Label>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="currentTempWebImage">Web</Label>
                        <InputGroup>
                            <Input type="text" name="currentTempWebImage" onChange={(e) => this.setState({
                                currentTempWebImage: e.target.value
                            })} value={this.state.currentTempWebImage} />
                            <InputGroupAddon addonType={"prepend"}>
                                <Button onClick={this.handleWebChange}>Link</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </ModalBody>
            </Modal>
        </div>
    }

    toggleSetImageModal(): void {
        this.setState({
            setImageView: !this.state.setImageView
        })
    }

    async handleFileUpload(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        const compress = new Compress();
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            imageUploading: true,
        });
        this.toggleSetImageModal();
        let path = `${this.props.filePath}/${uuidv4()}`;
        if (e.target.files == null || e.target.files.length === 0) {
            this.props.failureCallback("Upload failed");
            return;
        }
        let file = e.target.files[0];
        let data;
        let ext;
        let metadata: UploadMetadata = {};
        if (this.props.accountId != null) {
            metadata['customMetadata'] = { accountId: this.props.accountId };
            console.log("Account id set", metadata['customMetadata'].accountId);
        } else if (this.props.schoolId != null) {
            metadata['customMetadata'] = { schoolId: this.props.schoolId };
            console.log("schoolId id set", metadata['customMetadata'].schoolId);
        }
        if (file.type === "application/pdf") {
            let fileReader = new FileReader();
            let superClass = this;
            fileReader.onload = async function () {

                //Step 4:turn array buffer into typed array
                try {
                    console.log(this.result);
                    // @ts-ignore
                    let typedarray = new Uint8Array(this.result);
                    console.log("GetDocument");
                    let promise = pdflib.getDocument(typedarray).promise;
                    console.log("Get pdf");
                    let pdf = await promise;


                    console.log("Get page");
                    let page = await pdf.getPage(1);

                    console.log("Create viewport");
                    const viewport = page.getViewport({
                        scale: 1
                    });
                    console.log("Create canvas");
                    const canvas = document.createElement('canvas');
                    console.log("Get context");
                    const canvasContext = canvas.getContext('2d');
                    console.log("Set dimensions");
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    console.log("Render");
                    const renderContext = {
                        canvasContext: canvasContext,
                        viewport: viewport
                    };
                    await page.render(renderContext).promise;

                    metadata['contentType'] = "image/png";
                    console.log("Convert to blob");
                    let filename = file.name.substr(0, file.name.lastIndexOf(".")) + ".png";
                    path = path + `-${filename}`;
                    let dataString = canvas.toDataURL();
                    console.log(dataString);
                    try {
                        let snapshot = await firebase.storage().ref().child(path).putString(dataString, 'data_url', metadata);
                        console.log('Uploaded', snapshot.totalBytes, 'bytes.');
                        console.log('File metadata:', snapshot.metadata);
                        // Let's get a download URL for the file.
                        snapshot.ref.getDownloadURL().then((url) => {
                            console.log('File available at', url);
                            superClass.setState({
                                imageUploading: false,
                            });
                            superClass.props.callback(url, path);
                        });
                    } catch (error) {
                        console.error('Upload failed:', error);
                        superClass.setState({
                            imageUploading: false,
                        });
                        superClass.props.failureCallback("Upload failed");
                    }
                } catch (error) {
                    console.log(error);
                }

            };

            fileReader.readAsArrayBuffer(file);
        } else {
            let dataObj = await compress.compress([file], {
                size: 2,
                maxWidth: 1024,
            });
            metadata['contentType'] = file.type;
            ext = dataObj[0].ext;
            data = dataObj[0].data;
            console.log("Compressing");
            let compressedFile = Compress.convertBase64ToFile(data, ext);
            console.log("Compressed");
            path = path + `-${file.name}`;
            try {
                let snapshot = await firebase.storage().ref().child(path).put(compressedFile, metadata);
                console.log('Uploaded', snapshot.totalBytes, 'bytes.');
                console.log('File metadata:', snapshot.metadata);
                // Let's get a download URL for the file.
                let url = await snapshot.ref.getDownloadURL();
                console.log('File available at', url);
                this.setState({
                    imageUploading: false,
                });
                this.props.callback(url, path);
            } catch (error) {
                console.error('Upload failed:', error);
                this.setState({
                    imageUploading: false,
                });
                this.props.failureCallback("Upload failed");
            }
        }
    }

    async handleFileRemove(): Promise<void> {
        if (this.state.currentImage.path != null) {
            try {
                await firebase.storage().ref().child(this.state.currentImage.path).delete();
                console.log('Deleted');
                this.props.callback(null, null);
            } catch (error) {
                console.error('Delete failed:', error);
                this.props.failureCallback("Delete failed");
            }
        } else {
            this.props.callback(null, null);
        }
    }

    handleWebChange(): void {
        this.toggleSetImageModal();
        this.props.callback(this.state.currentTempWebImage, null);
    }
}

export default ImageUpload;
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { User } from "../data/user";
import firebase from '../firebase';
import { Button, Alert, Form, FormGroup, Label, Input, Collapse, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Col, Container, Row, Spinner, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { AccountGroup, AccountMinimised } from "../data/accounts";
import { StudentTopicOverview, Subject, Topic } from "../data/home_learning";
import { TableDetails, DataTableView } from "./data_table";
import { subjectIds } from '../Prettifier';
import queryString from 'query-string';
import Chart from "react-google-charts";
import { ReactGoogleChartEvent } from "react-google-charts/dist/types";
import TopicsSelector from "../ninja_components/topics_selector";

interface ShowAccountListDetails {
    title: string;
    includedAccountIds: string[];
    excludedAccountIds: string[];
}

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
    accountGroups: Map<string, AccountGroup>;
}

interface IState {
    dataLoaded: boolean;
    dataLoading: boolean;
    currentReportAccountGroups: Array<string>;
    currentReportSubjectId: string;
    currentReportTopicId: string;
    currentReportTagIds: Array<string>;
    newReportAccountGroups: Array<string>;
    newAccountIdList: Array<string>;
    newReportSubjectId: string;
    newReportTopicId: string;
    newReportTagIds: Array<string>;
    showTableDetails: boolean;
    tableDetails: TableDetails;
    availableAccountIds: Array<string>;
    accountIdList: Array<string>;
    topicAnalysis: Map<string, StudentTopicOverview>;
    showReportDetails: boolean;
    topics: Map<string, Topic>;
    reportData: string[][];
    chartEvents: ReactGoogleChartEvent[];
    collapse: boolean;
}

class TopicTagReportView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            dataLoaded: false,
            dataLoading: false,
            currentReportAccountGroups: [],
            currentReportSubjectId: null,
            currentReportTopicId: null,
            currentReportTagIds: null,
            newReportAccountGroups: [],
            newAccountIdList: [],
            newReportSubjectId: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
            newReportTopicId: "",
            newReportTagIds: [],
            showTableDetails: false,
            availableAccountIds: [],
            accountIdList: [],
            topicAnalysis: new Map(),
            collapse: true,
            showReportDetails: true,
            tableDetails: null,
            reportData: null,
            chartEvents: null,
            topics: null,
        };
        this.createTopicAnalysisReport = this.createTopicAnalysisReport.bind(this);
        this.handleReportChange = this.handleReportChange.bind(this);
        this.selectAllAccounts = this.selectAllAccounts.bind(this);
        this.loadTopicAnalysis = this.loadTopicAnalysis.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleAccountGroupChange = this.handleAccountGroupChange.bind(this);
        this.toggleShowReportDetails = this.toggleShowReportDetails.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleTopicsChange = this.handleTopicsChange.bind(this);
    }

    render(): JSX.Element {

        let topicIdsSorted;
        if (this.state.topics == null) {
            topicIdsSorted = new Array<string>("Loading...");
        } else {
            topicIdsSorted = Array.from(this.state.topics.keys());
        }
        topicIdsSorted.sort((id1, id2) => {
            return this.state.topics.get(id1).name.localeCompare(this.state.topics.get(id2).name);
        });

        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/reporting/zapMathsReporting'}>All Reports</Link></BreadcrumbItem>
                    <BreadcrumbItem>Topic Analysis Report</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Topic analysis report</div>
                    <p className="cardSubTitle">View student attainment within a specific topic.</p>
                    {this.state.dataLoaded ?
                        <Button onClick={this.toggleShowReportDetails} className="altButton" style={{ width: '9%', float: 'right' }}>
                            {this.state.showReportDetails ? "Close" : "Edit report"}
                        </Button> : null
                    }
                    <Collapse isOpen={this.state.showReportDetails}>
                        <Form onSubmit={this.handleReportChange}>
                            <FormGroup>
                                <Label for="newReportAccountGroups">Account groups *</Label>
                                <Input type="select" required name="newReportAccountGroups" multiple onChange={this.handleAccountGroupChange} value={this.state.newReportAccountGroups}>
                                    {Array.from(this.props.accountGroups.keys()).map((accountGroupId) => {
                                        return (
                                            <option value={accountGroupId} key={accountGroupId}>{this.props.accountGroups.get(accountGroupId).details.name}</option>
                                        )
                                    })}
                                </Input><br />
                            </FormGroup>
                            <Collapse isOpen={!this.state.collapse}>
                                <FormGroup>
                                    <Label for="newAccountIdList">Accounts * <Button type="button" onClick={this.selectAllAccounts} color="secondary">Select all</Button></Label>
                                    <Input type="select" required name="newAccountIdList" multiple onChange={this.handleAccountChange} value={this.state.newAccountIdList}>
                                        {this.state.availableAccountIds.map((accountId) => {
                                            let account = this.props.accountsMinimised.get(accountId);
                                            if (account == null) {
                                                return null;
                                            }
                                            return (
                                                <option value={accountId} key={accountId}>{account.name}</option>
                                            )
                                        })
                                        }
                                    </Input><br />
                                </FormGroup>
                            </Collapse>
                            <FormGroup>
                                <Label for="newReportSubjectId">Subject *</Label>
                                <Input required type="select" name="newReportSubjectId"
                                    onChange={(e) => this.setState({
                                        newReportSubjectId: e.target.value
                                    })}
                                    value={this.state.newReportSubjectId}>
                                    <option value={''}>&nbsp;</option>
                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                        let subjectName = subjectIds.get(subjectId);
                                        return <option value={subjectId}>{subjectName}</option>;
                                    })}
                                </Input>
                            </FormGroup>
                            <Collapse isOpen={this.state.newReportSubjectId != null && this.state.newReportSubjectId !== ""}>
                                <TopicsSelector subject={this.state.newReportSubjectId} currentTopics={this.state.newReportTopicId == null || this.state.newReportTopicId === "" ? [] : [this.state.newReportTopicId]} currentTopicsTags={new Map([[this.state.newReportTopicId, this.state.newReportTagIds]])} callback={this.handleTopicsChange} />
                            </Collapse>
                            <Button className="adminPagesButton">{this.state.dataLoading ? <Spinner size="sm"></Spinner> : <span />}Run report</Button>
                        </Form>
                    </Collapse>
                </CardBody>
            </Card>
            {this.state.dataLoaded ?
                <Card className="mainCard top-buffer">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle2">{this.state.accountIdList.length} student{this.state.accountIdList.length !== 1 ? "s" : ""} included in report</div>
                        <Chart
                            chartType="ColumnChart"
                            chartEvents={this.state.chartEvents}
                            width="100%"
                            height="600px"
                            data={this.state.reportData}
                            options={{
                                title: this.state.topics.get(this.state.currentReportTopicId).name,
                                vAxis: {
                                    title: "Number of students",
                                    gridlines: {
                                        count: -1
                                    },
                                    minValue: 0
                                },
                                chartArea: {
                                    height: 300
                                },
                                animation: {
                                    startup: true,
                                    easing: 'out',
                                    duration: 500,
                                },
                            }}
                        />
                    </CardBody>
                </Card> : <span />}
            {this.state.dataLoaded && this.state.tableDetails != null ?
                <Card className="mainCard top-buffer">
                    <CardBody className="d-flex flex-column">
                        <DataTableView tableDetails={this.state.tableDetails} ></DataTableView>
                    </CardBody>
                </Card> : <span />}
            <br /><br />
        </div>
    }

    async handleSubjectChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let subjectId = e.target.value;
        this.setState({
            newReportSubjectId: subjectId,
            topics: null,
        });
        const subjectRef = firebase.firestore().collection(`learningTopics`).where('subjectId', '==', subjectId);
        let subjectSnapshot = await subjectRef.get();
        let topics = new Map<string, Topic>();
        subjectSnapshot.forEach((docSnapshot) => {
            let subjectData = docSnapshot.data();
            let subject = Subject.fromFirebase(subjectData);
            for (let nextTopicId of Array.from(subject.topics.keys())) {
                topics.set(nextTopicId, subject.topics.get(nextTopicId));
            }
        });
        this.setState({
            topics: topics,
        });
    }

    handleTopicsChange(topics: Array<string>, topicsTags: Map<string, Array<string>>) {
        let topicId = topics[0];
        let tagIds;
        if (topicId == null || topicId === "") {
            tagIds = new Array<string>();
        } else {
            tagIds = topicsTags.get(topicId);
        }
        this.setState({
            newReportTopicId: topicId,
            newReportTagIds: tagIds,
        });
    }

    toggleShowReportDetails(): void {
        this.setState({
            showReportDetails: !this.state.showReportDetails
        });
    }

    async handleReportChange(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        this.setState({
            dataLoading: true,
        });

        let accountIdList = this.state.newAccountIdList;

        if (this.state.newReportSubjectId != this.state.currentReportSubjectId) {
            await this.loadTopicAnalysis(this.state.newReportSubjectId, accountIdList);
        } else {
            await this.createTopicAnalysisReport(this.state.newReportTopicId, this.state.newReportTagIds, this.state.topicAnalysis, this.state.topics, accountIdList);
        }

        this.setState({
            currentReportAccountGroups: this.state.newReportAccountGroups,
            currentReportSubjectId: this.state.newReportSubjectId,
            currentReportTopicId: this.state.newReportTopicId,
            currentReportTagIds: this.state.newReportTagIds,
            accountIdList: accountIdList,
            showTableDetails: true,
            showReportDetails: false,
            dataLoaded: true,
            dataLoading: false,
        });
    }

    async loadTopicAnalysis(subjectId: string, accountIdList: Array<string>) {
        const subjectRef = firebase.firestore().collection(`learningTopics`).where('subjectId', '==', subjectId);
        let subjectSnapshot = await subjectRef.get();
        let topics = new Map<string, Topic>();
        subjectSnapshot.forEach((docSnapshot) => {
            let subjectData = docSnapshot.data();
            let subject = Subject.fromFirebase(subjectData);
            for (let nextTopicId of Array.from(subject.topics.keys())) {
                topics.set(nextTopicId, subject.topics.get(nextTopicId));
            }
        });

        let promises = [];
        for (let accountId of accountIdList) {
            console.log("Looking for accountId", accountId);
            const topicOverviewRef = firebase.firestore().doc(`studentTopicOverviews/${subjectId}--${accountId}`);
            promises.push(topicOverviewRef.get());
        }
        let topicAnalysis = new Map<string, StudentTopicOverview>();
        let results = await Promise.all(promises);
        results.forEach((topicOverviewSnapshot) => {
            if (topicOverviewSnapshot.exists) {
                let topicOverview = StudentTopicOverview.fromFirebase(topicOverviewSnapshot.data());
                topicAnalysis.set(topicOverview.accountId, topicOverview);
            }
        });

        this.setState({
            topics: topics,
            topicAnalysis: topicAnalysis,
        });

        await this.createTopicAnalysisReport(this.state.newReportTopicId, this.state.newReportTagIds, topicAnalysis, topics, accountIdList);
    }

    handleAccountChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newAccountIdList.indexOf(e.target.value);
        if (index > -1) {
            this.state.newAccountIdList.splice(index, 1);
        } else {
            this.state.newAccountIdList.push(e.target.value);
        }
        this.setState({});
    }

    selectAllAccounts(): void {
        this.setState({
            newAccountIdList: this.state.availableAccountIds.slice(),
        })
    }

    handleAccountGroupChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newReportAccountGroups.indexOf(e.target.value);
        if (index > -1) {
            this.state.newReportAccountGroups.splice(index, 1);
        } else {
            this.state.newReportAccountGroups.push(e.target.value);
        }
        let accountIdList = [];
        for (let nextAccountGroupId of this.state.newReportAccountGroups) {
            let nextAccountGroup = this.props.accountGroups.get(nextAccountGroupId);
            if (nextAccountGroup != null) {
                for (let nextAccountId of nextAccountGroup.accounts.keys()) {
                    let nextAccountDeets = nextAccountGroup.accounts.get(nextAccountId);
                    if (nextAccountDeets.member != null && nextAccountDeets.member &&
                        accountIdList.indexOf(nextAccountId) === -1 && this.props.accountsMinimised.has(nextAccountId)) {
                        accountIdList.push(nextAccountId);
                    }
                }
            }
        }
        this.setState({
            availableAccountIds: accountIdList,
            collapse: false,
            accountIdList: [],
        });
    }

    createTopicAnalysisReport(reportTopicId: string, reportTagIds: Array<string>, topicAnalysis: Map<string, StudentTopicOverview>, topics: Map<string, Topic>, accountIdList: Array<string>): void {
        let tagIds;
        let topic = topics.get(reportTopicId);
        if (reportTagIds != null && reportTagIds.length > 0) {
            tagIds = reportTagIds;
        } else {
            tagIds = Array.from(topic.tags.keys());
        }
        let tagBuckets = new Array<any>();
        let dataExplosion: any = {};

        for (let nextTagId of tagIds) {
            console.log("Next tag id", nextTagId);
            let tagName = topic.tags.get(nextTagId);
            console.log("Tag name", tagName);
            let tagData: Array<any> = [tagName];
            dataExplosion[nextTagId] = {};

            let completedCount = 0;
            let notCompletedCount = 0;
            let secureCount = 0;
            for (let i = 0; i < accountIdList.length; i++) {
                let accountId = accountIdList[i];

                let secure = false;
                let completed = false;
                if (topicAnalysis.has(accountId) && topicAnalysis.get(accountId).topics.has(reportTopicId) &&
                    topicAnalysis.get(accountId).topics.get(reportTopicId).has(nextTagId) &&
                    topicAnalysis.get(accountId).topics.get(reportTopicId).get(nextTagId).hist != null &&
                    topicAnalysis.get(accountId).topics.get(reportTopicId).get(nextTagId).hist.length > 0) {
                    completed = true;
                    let flawlessCount = 0;
                    for (let nextEntry of topicAnalysis.get(accountId).topics.get(reportTopicId).get(nextTagId).hist) {
                        if (nextEntry.sc) {
                            flawlessCount++
                        }
                    }
                    if (flawlessCount > 4) {
                        secure = true;
                    }
                } else {
                    notCompletedCount++;
                }
                if (secure) {
                    secureCount++;
                }
                if (completed) {
                    completedCount++;
                }

                dataExplosion[nextTagId][accountId] = { secure: secure, completed: completed };
            }
            tagData.push(notCompletedCount);
            tagData.push(completedCount);
            tagData.push(secureCount);
            tagData.push(nextTagId);
            tagBuckets.push(tagData);
        }

        let resultData = [["Tag", "No attempt", "Completed", "Secure"]];
        for (let i = 0; i < tagBuckets.length; i++) {
            resultData.push([tagBuckets[i][0], tagBuckets[i][1], tagBuckets[i][2], tagBuckets[i][3]]);
        }

        const chartEvents: ReactGoogleChartEvent[] = [
            {
                eventName: "select",
                callback: async ({ chartWrapper }) => {
                    if (chartWrapper.getChart().getSelection() == null || chartWrapper.getChart().getSelection().length === 0) {
                        this.setState({
                            showTableDetails: false,
                        });
                    } else {
                        let selection = chartWrapper.getChart().getSelection()[0];
                        let headers = ["Tag", "Student", "Completed", "Secure"];
                        let data = [];
                        let startRow = selection.row;
                        let rowCount = 1;
                        if (selection.row == null) {
                            startRow = 0;
                            rowCount = tagBuckets.length;
                        }
                        for (let bucketIndex = startRow; bucketIndex < startRow + rowCount; bucketIndex++) {
                            let nextTagId = tagBuckets[bucketIndex][4];
                            let nextTagName = tagBuckets[bucketIndex][0];
                            for (let accountId in dataExplosion[nextTagId]) {
                                data.push([nextTagName, this.props.accountsMinimised.has(accountId) ? this.props.accountsMinimised.get(accountId).name : "Removed account", dataExplosion[nextTagId][accountId].completed ? "Yes" : "No", dataExplosion[nextTagId][accountId].secure ? "Yes" : "No"]);
                            }
                        }

                        this.setState({
                            showTableDetails: true,
                            tableDetails: { headers: headers, data: data },
                        });
                    }
                }
            }
        ];

        this.setState({
            reportData: resultData,
            chartEvents: chartEvents,
        });
    }
}

export default TopicTagReportView;
//@ts-check
import React, { Component } from 'react';
import {
    Button, Form, FormGroup, Label, Input, Spinner,
    Alert, Container, Row, Col, Collapse, Card, CardGroup, InputGroupAddon, InputGroupText, InputGroup, NavLink
} from 'reactstrap';
import queryString from 'query-string';
import { auth, provider } from "./firebase";
import { sendPasswordResetEmail } from "./helpers/email_services";
import { RouteComponentProps } from 'react-router';

interface IState {
    loginEmailAddress: string;
    tosConfirmed: boolean;
    loginTypeSelected: string;
    userTypeSelected: string;
    passwordState: string;
    hidden: boolean;
    loginPassword: string;
    sendingPasswordReset: boolean;
    sendingVerificationEmail: boolean;
    checkingRegistration: boolean;
    loginRequest: boolean;
    source: string;
    id: string;
    emailVerification: boolean;
}

interface IProps extends RouteComponentProps {
    snackbar: (text?: string) => void;
}

class LoginRegisterView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = this.props.location.pathname.split("/");
        const queryValues = queryString.parse(this.props.location.search);
        let id = null;
        if (queryValues != null && queryValues['id'] != null) {
            id = queryValues['id'].toString();
        }
        let source = "";
        if (values.length > 0) {
            source = values[values.length - 1];
        }
        this.state = {
            loginEmailAddress: '',
            tosConfirmed: false,
            loginTypeSelected: null,
            userTypeSelected: null,
            passwordState: "password",
            hidden: true,
            loginPassword: '',
            sendingPasswordReset: false,
            sendingVerificationEmail: false,
            checkingRegistration: false,
            loginRequest: false,
            source: source,
            id: id,
            emailVerification: false,
        };
        this.handleEmailLogin = this.handleEmailLogin.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handlePasswordReset = this.handlePasswordReset.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
        this.handleLoginRegister = this.handleLoginRegister.bind(this);
        this.checkAccountStatus = this.checkAccountStatus.bind(this);
        this.updateLoginForm = this.updateLoginForm.bind(this);
    }

    render(): JSX.Element {

        return <div className="container">
            {this.state.source != null && this.state.source == "zap" ?
                <div>
                    <br />
                    <Alert color="success" style={{ textAlign: "center" }}>
                        <h3>Register for a 3 months free Zap Maths trial!</h3>
                    </Alert>
                </div> : <span />
            }
            <div className="top-buffer">
                <CardGroup>
                    <Card style={{ backgroundColor: '#7036a4', color: '#FFFFFF', borderColor: '#f6f6f6', borderRadius: '35px 35px 35px 35px', margin: '20px', fontSize: '20px' }}>
                        <div style={{ padding: '20px' }}></div>
                        <Container>
                            <Row style={{ backgroundColor: '#FFFFFF', }}>
                                <img style={{ height: '80px', display: 'block', margin: 'auto', padding: '5px' }} src={'/images/LNN-p.png'} />
                            </Row>
                        </Container>
                        <div style={{ padding: '30px' }}>
                            {this.state.source != null && this.state.source == "zap" ?
                                <div>
                                    <h3><img style={{ height: '80px', display: 'block', margin: 'auto', padding: '5px' }} src={'/images/zap-icon.png'} />Zap Maths</h3><h6>Powered By Life Ninja</h6><br />
                                    <p>The Life Ninja Dashboard enables you to manage your student's Zap Learning Experiences.</p>
                                    <br />
                                </div>
                                : <div>
                                    <br /><h3>Welcome to the Life Ninja network</h3><br />
                                    <p>Connecting students, teachers, and parents around the life and learning of the student.</p>
                                    <br />
                                </div>}
                            <h3>What is The Life Ninja Network?</h3><br />
                            <p>The Life Ninja Network is a collaborative ecosystem of educational apps managed from a single dashboard.</p>
                        </div>

                    </Card>
                    <Card style={{ padding: '30px', margin: '20px', borderRadius: '35px 35px 35px 35px' }}>
                        {this.state.userTypeSelected == null ? <h1>Sign in / Register</h1> : this.state.userTypeSelected === "NEW" ? <h1>Register</h1> : <h1>Sign in</h1>}
                        {(this.state.userTypeSelected == null || this.state.userTypeSelected === "NEW") && this.state.source != null && this.state.source == "zap" ? <h5>to setup Zap Maths</h5> : <span />}
                        {this.state.userTypeSelected != null ? <p><Button color="link" onClick={() => this.setState({ userTypeSelected: null })}><i className="fas fa-arrow-left" style={{ color: "black" }} /></Button></p> : <br />}

                        {this.state.userTypeSelected === "NEW" ?
                            <div>
                                First time login. Welcome to the Life Ninja Network!<br /><br />
                            </div> : <div></div>}
                        <Form onSubmit={this.updateLoginForm}>
                            {this.state.userTypeSelected == null ?
                                <FormGroup>
                                    <Label for="loginEmailAddress">Email</Label>
                                    <Input type="email" autoFocus required name="loginEmailAddress" onChange={(e) => this.setState({
                                        loginEmailAddress: e.target.value
                                    })} value={this.state.loginEmailAddress} />
                                </FormGroup>
                                :
                                <div>
                                    <FormGroup>
                                        <Label for="loginPassword">{this.state.userTypeSelected === "NEW" ? "Memorable" : ""} Password</Label>
                                        <InputGroup>
                                            <Input required autoFocus minLength={6} type={this.state.hidden ? 'password' : 'text'} value={this.state.loginPassword} className="text-textarea" name="loginPassword" onChange={this.handlePasswordChange} />
                                            <InputGroupAddon addonType="append">
                                                <Button style={{ backgroundColor: '#FFFFFF', borderColor: '#D0D0D0' }} onClick={this.toggleShow} type="button">{this.state.hidden ? <i className="fas fa-eye" style={{ color: "black" }} /> : <i className="fas fa-eye-slash" style={{ color: "black" }} />}</Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    {this.state.userTypeSelected === "NEW"
                                        ? <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" value="true" name='tosConfirmed'
                                                    checked={this.state.tosConfirmed}
                                                    required
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.setState({
                                                                tosConfirmed: true,
                                                            });
                                                        } else {
                                                            this.setState({
                                                                tosConfirmed: false,
                                                                loginTypeSelected: null
                                                            });
                                                        }
                                                    }} />
                                            &nbsp;Before registering you must tick to confirm that you agree to our <a target="_blank" rel="noopener norefferer" href={"https://lifeninja.net/licence-agreement/"}>terms of
                                            service</a>
                                            </Label>
                                        </FormGroup>
                                        : <span />
                                    }
                                </div>}
                            <br /><br />
                            {!this.state.emailVerification ?
                                <Container fluid className="flex-container" >
                                    <Row>
                                        <Col>
                                            {this.state.userTypeSelected !== "EXISTING" ? <span /> :
                                                <span>{this.state.sendingPasswordReset ? <Spinner size="sm"></Spinner> : <span />}
                                                    <Button color="#FFFFFF" style={{ color: '#7036a4' }} outline type="button" onClick={this.handlePasswordReset}>Forgot Password?</Button>
                                                </span>
                                            }
                                        </Col>
                                        <Col>
                                            <Button className="float-right" style={{ color: "#FFFFFF", backgroundColor: "#a5d118", borderRadius: '15px 15px 15px 15px', borderColor: "#a5d118", width: '90px' }}>
                                                {this.state.loginRequest || this.state.checkingRegistration ? <Spinner size="sm"></Spinner> : <span />}
                                                {this.state.userTypeSelected == null ? "Next" : this.state.userTypeSelected === "NEW" ? "Register" : "Log in"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container> : <span />
                            }
                            <br /><hr /><br /><br />
                            <Button style={{ backgroundColor: "#4285f4", color: "FFFFFF", borderRadius: '60px 60px 60px 60px', width: '100%', height: '70px', borderColor: "#4285f4" }} type="button" onClick={() => { this.setState({ loginTypeSelected: "GOOGLE", }); auth.signInWithPopup(provider); }}><h3><i className="fab fa-google" />&nbsp;&nbsp;Sign in with Google</h3></Button>
                            <br /><br /><br /><br />
                            <h6 style={{ textAlign: 'center' }} >By logging in you agree to our <a target="_blank" rel="noopener norefferer" href={"https://lifeninja.net/licence-agreement/"}>Terms of Service</a></h6>
                        </Form>
                    </Card>
                </CardGroup>
            </div>
        </div >;
    }

    updateLoginForm(e: React.FormEvent): void {
        e.preventDefault();
        this.state.userTypeSelected == null ? this.checkAccountStatus() : this.handleLoginRegister();
    }

    async checkAccountStatus(): Promise<void> {
        this.setState({
            checkingRegistration: true,
        })

        let providers = await auth.fetchSignInMethodsForEmail(this.state.loginEmailAddress);
        console.log(`providers ${providers}`);

        let registered = providers != null && providers.length > 0 && providers.indexOf("password") != -1;
        this.setState({
            userTypeSelected: registered ? "EXISTING" : "NEW",
            checkingRegistration: false,
        });
    }

    async handleLoginRegister(): Promise<void> {
        if (this.state.userTypeSelected === "EXISTING") {
            this.handleEmailLogin();
        } else {
            this.handleRegister();
        }
    }

    async handleEmailLogin(): Promise<void> {
        this.setState({
            loginRequest: true,
        });
        try {
            await auth.signInWithEmailAndPassword(this.state.loginEmailAddress, this.state.loginPassword);
            this.setState({
                loginPassword: '',
            });
        } catch (error) {
            console.log("error.code:", error.code);
            console.log("error.message:", error.message);
            this.setState({
                loginPassword: '',
            });
            this.props.snackbar(error.message);
        } finally {
            this.setState({
                loginRequest: false,
            });
        }
    }

    handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            loginPassword: e.target.value
        })
    }

    toggleShow(): void {
        this.setState({
            hidden: !this.state.hidden
        })
    }

    async handleRegister(): Promise<void> {
        try {
            await auth.createUserWithEmailAndPassword(this.state.loginEmailAddress, this.state.loginPassword);
        } catch (error) {
            console.log("error.code:", error.code);
            console.log("error.message:", error.message);
            this.props.snackbar(error.message);
        }
    }

    async handlePasswordReset(): Promise<void> {
        this.setState({
            sendingPasswordReset: true,
        });
        try {
            await sendPasswordResetEmail(this.state.loginEmailAddress, this.props.snackbar);
        } finally {
            this.setState({
                sendingPasswordReset: false,
            });
        }
    }

    componentDidMount(): void {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('email')) {
            this.setState({
                loginEmailAddress: urlParams.get('email'),
            });
        }
    }
}

export default LoginRegisterView;
import React from 'react';
import ReactDOM from 'react-dom';
//import './App.css';
import './payments/sqpaymentform-basic.css';
import RootView from './root_view';
// import serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-day-picker/lib/style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';

ReactDOM.render(<RootView />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

import firebase from '../firebase';
import { objectToClassConverter } from './database_object';

export class EventMinimised {
    title: string = null;
    description: string = null;
    startDateTime: number = null;
    endDateTime: number = null;
    recipients: number = null;

    static fromFirebase(data: any): EventMinimised {
        let newEventMinimised = new EventMinimised();
        if (data == null) {
            return newEventMinimised;
        }
        objectToClassConverter(data, newEventMinimised);

        return newEventMinimised;
    }
}

export class Event {
    details: EventDetails;
    responses: EventResponses = null;
    accounts: Map<string, EventAccount> = null;
    accountGroups: Map<string, EventAccountGroup> = null;

    constructor() {
        this.details = new EventDetails();
    }

    static fromFirebase(data: any): Event {
        let newEvent = new Event();
        if (data == null) {
            return newEvent;
        }
        objectToClassConverter(data, newEvent, [], ["details", "responses", "accounts", "accountGroups"]);

        let newAccounts = new Map<string, EventAccount>();
        if (data.accounts != null) {
            for (let nextAssignmentId of Object.keys(data.accounts)) {
                newAccounts.set(nextAssignmentId, EventAccount.fromFirebase(data.accounts[nextAssignmentId]));
            }
        }
        newEvent.accounts = newAccounts;

        let newAccountGroups = new Map<string, EventAccountGroup>();
        if (data.accountGroups != null) {
            for (let nextAssignmentId of Object.keys(data.accountGroups)) {
                newAccountGroups.set(nextAssignmentId, EventAccountGroup.fromFirebase(data.accountGroups[nextAssignmentId]));
            }
        }
        newEvent.accountGroups = newAccountGroups;

        newEvent.details = EventDetails.fromFirebase(data.details);
        newEvent.responses = EventResponses.fromFirebase(data.responses);

        return newEvent;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        fbObject['details'] = this.details == null ? null : this.details.toFirebase();
        return fbObject;
    }
}

export class EventDetails {
    title: string = null;
    description: string = null;
    startDateTime: number = null;
    endDateTime: number = null;
    imageUrl: string = null;
    imagePath: string = null;
    cost: number = null;
    costDescription: string = null;
    iconCodePoint: number = null;
    iconCodeString: string = null;
    iconFontFamily: string = null;
    iconFontPackage: string = null;
    webLink: string = null;
    videoLink: string = null;
    videoRestricted: boolean = null;
    videoAvailableFrom: number = null;
    callLink: string = null;
    placeAddress: string = null;
    placeId: string = null;
    questionRequests: Map<string, EventQuestion> = null;
    dateUpdated: number = null;

    static fromFirebase(data: any): EventDetails {
        let newEventDetails = new EventDetails();
        if (data == null) {
            return newEventDetails;
        }
        objectToClassConverter(data, newEventDetails);

        let newAccountGroups = new Map<string, EventQuestion>();
        if (data.questionRequests != null) {
            for (let nextQuestionId of Object.keys(data.questionRequests)) {
                newAccountGroups.set(nextQuestionId, EventQuestion.fromFirebase(data.questionRequests[nextQuestionId]));
            }
        }
        newEventDetails.questionRequests = newAccountGroups;

        return newEventDetails;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);

        let questionRequestsObject: any = {};
        if (this.questionRequests != null) {
            for (let nextEntryId of this.questionRequests.keys()) {
                questionRequestsObject[nextEntryId] = this.questionRequests.get(nextEntryId).toFirebase();
            }
        }
        fbObject['questionRequests'] = questionRequestsObject;

        fbObject['dateUpdated'] = firebase.database.ServerValue.TIMESTAMP;
        return fbObject;
    }
}

export class EventQuestion {
    description: string = null;
    answers: string[] = null;

    static fromFirebase(data: any): EventQuestion {
        let newEventMinimised = new EventQuestion();
        if (data == null) {
            return newEventMinimised;
        }
        objectToClassConverter(data, newEventMinimised);

        return newEventMinimised;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        return fbObject;
    }
}

export class EventResponses {
    read: Map<string, boolean> = null;
    attendance: Map<string, EventAttending> = null;
    payment: Map<string, EventPaid> = null;
    questions: Map<string, Map<string, QuestionResponse>> = null;

    static fromFirebase(data: any): EventResponses {
        let newEventResponses = new EventResponses();
        if (data == null) {
            return newEventResponses;
        }
        objectToClassConverter(data, newEventResponses, ["read"], ["attendance", "payment", "questions"]);

        let newEventAttendings = new Map<string, EventAttending>();
        if (data.attendance != null) {
            for (let nextAttendingId of Object.keys(data.attendance)) {
                newEventAttendings.set(nextAttendingId, EventAttending.fromFirebase(data.attendance[nextAttendingId]));
            }
        }
        newEventResponses.attendance = newEventAttendings;

        let newEventPaids = new Map<string, EventPaid>();
        if (data.payment != null) {
            for (let nextPaidId of Object.keys(data.payment)) {
                newEventPaids.set(nextPaidId, EventPaid.fromFirebase(data.payment[nextPaidId]));
            }
        }
        newEventResponses.payment = newEventPaids;

        let newQuestionResponses = new Map<string, Map<string, QuestionResponse>>();
        if (data.questions != null) {
            for (let nextAccountId of Object.keys(data.questions)) {
                let newQuestionResponseDeets = new Map<string, QuestionResponse>();
                newQuestionResponses.set(nextAccountId, newQuestionResponseDeets);
                for (let nextQuestionId of Object.keys(data.questions[nextAccountId])) {
                    newQuestionResponseDeets.set(nextQuestionId, QuestionResponse.fromFirebase(data.questions[nextAccountId][nextQuestionId]));
                }
            }
        }
        newEventResponses.questions = newQuestionResponses;

        return newEventResponses;
    }
}

export class EventAttending {
    attending: boolean = null;

    static fromFirebase(data: any): EventAttending {
        let newEventAttending = new EventAttending();
        if (data == null) {
            return newEventAttending;
        }
        objectToClassConverter(data, newEventAttending);

        return newEventAttending;
    }
}

export class QuestionResponse {
    response: string = null;

    static fromFirebase(data: any): QuestionResponse {
        let newQuestionResponse = new QuestionResponse();
        if (data == null) {
            return newQuestionResponse;
        }
        objectToClassConverter(data, newQuestionResponse);

        return newQuestionResponse;
    }
}

export class EventPaid {
    paid: boolean = null;

    static fromFirebase(data: any): EventPaid {
        let newEventPaid = new EventPaid();
        if (data == null) {
            return newEventPaid;
        }
        objectToClassConverter(data, newEventPaid);

        return newEventPaid;
    }
}

export class EventAccount {
    recipient: boolean = null;
    updated: number = null;

    static fromFirebase(data: any): EventAccount {
        let newEventAccount = new EventAccount();
        if (data == null) {
            return newEventAccount;
        }
        objectToClassConverter(data, newEventAccount);

        return newEventAccount;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        fbObject['updated'] = firebase.database.ServerValue.TIMESTAMP;
        return fbObject;
    }
}

export class EventAccountGroup {
    futureMembers: boolean = null;
    updated: number = null;

    static fromFirebase(data: any): EventAccountGroup {
        let newEventAccountGroup = new EventAccountGroup();
        if (data == null) {
            return newEventAccountGroup;
        }
        objectToClassConverter(data, newEventAccountGroup);

        return newEventAccountGroup;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        fbObject['updated'] = firebase.database.ServerValue.TIMESTAMP;
        return fbObject;
    }
}

export class SchoolKeyDates {
    schoolId: string = null;
    updated: number = null;
    keyDates: Map<string, KeyDate> = null;

    constructor() {
        this.keyDates = new Map();
    }

    static fromFirebase(data: any): SchoolKeyDates {
        let newSchoolKeyDates = new SchoolKeyDates();
        if (data == null) {
            return newSchoolKeyDates;
        }
        objectToClassConverter(data, newSchoolKeyDates, [], ["keyDates"]);

        let newKeyDates = new Map<string, KeyDate>();
        if (data.keyDates != null) {
            for (let nextKeyDateId of Object.keys(data.keyDates)) {
                newKeyDates.set(nextKeyDateId, KeyDate.fromFirebase(data.keyDates[nextKeyDateId]));
            }
        }
        newSchoolKeyDates.keyDates = newKeyDates;

        return newSchoolKeyDates;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        fbObject['updated'] = firebase.database.ServerValue.TIMESTAMP;
        return fbObject;
    }
}

export class KeyDate {
    title: string = null;
    date: number = null;

    static fromFirebase(data: any): KeyDate {
        let newKeyDate = new KeyDate();
        if (data == null) {
            return newKeyDate;
        }
        objectToClassConverter(data, newKeyDate);

        return newKeyDate;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        return fbObject;
    } 
}
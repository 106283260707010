import { Button, Table } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import confirm from "reactstrap-confirm";
import React, { Component } from "react";
import { BookableEvent, SlotWrapper } from "../data/bookings";
import { AccountMinimised } from "../data/accounts";

interface IState {

}

interface IProps {
    confirmAllBookedSlots: () => void;
    handleOwnerRemove: (slotId: string, accountId: string) => void;
    lockAllSlots: () => void;
    toggleSlotLocked: (slotId: string, locked: boolean) => void;
    openSlotModal: (slotId: string) => void;
    openBookedModal: (slotId: string) => void;
    openOwnerModal: (slotId: string) => void;
    handleSlotRemove: (slotId: string) => void;
    handleBookedAdd: (accountId: string, slotId: string) => void;
    handleBookedCancel: (accountId: string, slotId: string) => void;
    toggleRequestConfirm: (accountId: string, slotId: string, count: number) => void;
    toggleSlotConfirm: (slotId: string, confirmed: boolean) => void;
    makePayment: (accountId: string, slotId: string, count: number) => void;
    currentSlots: Map<string, SlotWrapper>;
    slotGroup: BookableEvent;
    accountsMinimised: Map<string, AccountMinimised>;
}

class SlotDetails extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    render() {
        console.log("Rendering slot details");
        let currentSlotIdsOrdered = Array.from(this.props.currentSlots.keys()).slice();
        currentSlotIdsOrdered.sort((sgId1, sgId2) => {
            return this.props.currentSlots.get(sgId1).slot.startDate.getTime() - this.props.currentSlots.get(sgId2).slot.startDate.getTime();
        });
        return <Table>
            <thead>
                <tr>
                    <th>Time</th>
                    <th><i className="material-icons">school</i> Owner(s)</th>
                    <th><i className="material-icons">person</i> Booked by</th>
                    {this.props.slotGroup.details.autoConfirm ? null : <th>Requests</th>}
                    <th><i className="material-icons">check</i> Confirmed</th>
                    <th><i className="material-icons">credit_card</i>Paid</th>
                    <th><i className="material-icons">lock</i> Locked</th>
                    <th>
                        {this.props.slotGroup.details.autoConfirm ? null : <Button color="primary" onClick={() => {
                            this.props.confirmAllBookedSlots()
                        }}><i className="material-icons">check_circle</i> all</Button>}
                    &nbsp;
                    <Button color="primary" onClick={() => {
                            this.props.lockAllSlots()
                        }}><i className="material-icons">lock</i> all</Button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {currentSlotIdsOrdered.map((slotId) => {
                    let slotWrapper = this.props.currentSlots.get(slotId);
                    let slot = slotWrapper.slot;
                    let anyConfirmed = false;
                    let allConfirmed = true;
                    for (let [accountId, accountDeets] of slotWrapper.accounts) {
                        if (accountDeets.confirmed != null && accountDeets.confirmed.count > 0) {
                            anyConfirmed = true;
                        }
                        if (accountDeets.count != null && accountDeets.count > 0 &&
                            (accountDeets.confirmed == null || accountDeets.confirmed.count == null || accountDeets.confirmed.count < accountDeets.count)) {
                            allConfirmed = false;
                        }
                    }
                    let startDate = moment(slot.startDate);
                    let startDateString = startDate.format("ddd DD/MM/YYYY HH:mm")
                    let endDate = moment(slot.endDate);
                    let endDateString = endDate.format("ddd DD/MM/YYYY HH:mm");
                    if (endDate.isSame(startDate, 'day')) {
                        endDateString = endDate.format("HH:mm");
                    }
                    if (slotWrapper.accounts == null || slotWrapper.accounts.size === 0) {
                        return <tr key={slotId + 'tr'}>
                            <th scope="row" key={slotId + 'th'}>
                                {startDateString} - {endDateString}
                            </th>
                            <td key={slotId + 'td2'}>
                                {slot.owners != null ?
                                    Array.from(slot.owners.keys()).map((accountId) => {
                                        let ownerDeets = slot.owners.get(accountId);
                                        let owner = this.props.accountsMinimised.get(accountId);
                                        if (owner == null || ownerDeets.owner == null || !ownerDeets.owner) {
                                            return null;
                                        }
                                        return <div>
                                            <Link to={`/accounts/${accountId}`}>{owner.name}</Link>
                                            <Button key={`${slotId}-${accountId}-ownerRemoveButton`} color="link" onClick={async () => {
                                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this owner", confirmText: "Confirm" });
                                                if (result) {
                                                    this.props.handleOwnerRemove(slotId, accountId)
                                                }
                                            }}><i className="material-icons">delete</i></Button>
                                        </div>;
                                    }) : ""}
                            </td>
                            <td key={slotId + 'td3'}>&nbsp;</td>
                            {this.props.slotGroup.details.autoConfirm ? null : <td key={slotId + 'td4'}>&nbsp;</td>}
                            <td key={slotId + 'td5'}>&nbsp;</td>
                            <td key={slotId + 'td6'}>{slot.cost != null && slot.cost > 0 ? "" : "-"}</td>
                            <td key={slotId + 'td7'}>
                                <Button key={slotId + 'closeButton'} color="link" onClick={() => {
                                    this.props.toggleSlotLocked(slotId, !slot.locked)
                                }}>
                                    <i className="material-icons">{!slot.locked ? "lock_open" : "lock"}</i>
                                </Button>
                            </td>
                            <td key={slotId + 'td8'}>
                                <Button key={slotId + 'editButton'} color="link" onClick={() => {
                                    this.props.openSlotModal(slotId)
                                }}>
                                    <i className="material-icons">edit</i>
                                </Button>&nbsp;
                            <Button key={slotId + 'addButton'} color="link" onClick={() => {
                                    this.props.openBookedModal(slotId)
                                }}>
                                    <i className="material-icons">person_add</i>
                                </Button>&nbsp;
                            <Button key={slotId + 'ownerButton'} color="link" onClick={() => {
                                    this.props.openOwnerModal(slotId)
                                }}>
                                    <i className="material-icons">school</i>
                                </Button>&nbsp;
                            <Button key={slotId + 'removeButton'} color="link" onClick={async () => {
                                    let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this slot", confirmText: "Confirm" });
                                    if (result) {
                                        this.props.handleSlotRemove(slotId)
                                    }
                                }}><i className="material-icons">delete</i></Button>
                            </td>
                        </tr>
                    } else {
                        let firstRowFlip = false;
                        return Array.from(slotWrapper.accounts.keys()).map((accountId) => {
                            let firstRow = true;
                            if (!firstRowFlip) {
                                firstRowFlip = true;
                            } else {
                                firstRow = false;
                            }
                            let accountDeets = slotWrapper.accounts.get(accountId);
                            let requestedCount = accountDeets.count == null ? 0 : accountDeets.count;
                            let confirmedCount = accountDeets.confirmed == null || accountDeets.confirmed.count == null ? 0 : accountDeets.confirmed.count;
                            let paidCount = accountDeets.paid == null || accountDeets.paid.count == null ? 0 : accountDeets.paid.count;
                            let accountCount = slotWrapper.accounts.size;
                            let account = this.props.accountsMinimised.get(accountId);
                            let name = account == null ? "Deactivated account" : account.name;
                            return <tr key={slotId + accountId + 'tr'}>
                                {firstRow ?
                                    <th rowSpan={accountCount} scope="row" key={slotId + 'th'}>
                                        {startDateString} - {endDateString}
                                    </th> : ""
                                }
                                {firstRow ?
                                    <td rowSpan={accountCount} key={slotId + 'td1'}>
                                        {slot.owners != null ?
                                            Array.from(slot.owners.keys()).map((accountId) => {
                                                let ownerDeets = slot.owners.get(accountId);
                                                let owner = this.props.accountsMinimised.get(accountId);
                                                if (owner == null || ownerDeets.owner == null || !ownerDeets.owner) {
                                                    return null;
                                                }
                                                return <div>
                                                    <Link to={`/accounts/${accountId}`}>{owner.name}</Link>
                                                    <Button key={`${slotId}-${accountId}-ownerRemoveButton`} color="link" onClick={async () => {
                                                        let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this owner", confirmText: "Confirm" });
                                                        if (result) {
                                                            this.props.handleOwnerRemove(slotId, accountId)
                                                        }
                                                    }}><i className="material-icons">delete</i></Button>
                                                </div>;
                                            }) : ""}
                                    </td> : ""
                                }
                                <td key={slotId + accountId + 'td2'}>
                                    <Link to={`/accounts/${accountId}`}>{name}</Link>
                                </td>
                                {this.props.slotGroup.details.autoConfirm ? null : <td key={slotId + accountId + "td3"}>
                                    <Button key={slotId + 'addButton'} color="link" onClick={() => {
                                        this.props.handleBookedAdd(accountId, slotId)
                                    }}>
                                        <i className="material-icons material-icons-xs">add_circle</i>
                                    </Button>
                                    {requestedCount - confirmedCount}
                                    {requestedCount - confirmedCount > 0 ?
                                        <Button key={slotId + 'cancelButton'} color="link" onClick={() => {
                                            this.props.handleBookedCancel(accountId, slotId)
                                        }}>
                                            <i className="material-icons material-icons-xs">remove_circle</i>
                                        </Button> : <span />
                                    }
                                    {confirmedCount < requestedCount ?
                                        <Button key={slotId + 'confirmButton'} color="link" onClick={() => {
                                            this.props.toggleRequestConfirm(accountId, slotId, requestedCount)
                                        }}>
                                            <i className="material-icons material-icons-xs">check_circle</i>
                                        </Button> : <span />
                                    }
                                </td>}
                                <td key={slotId + accountId + "td4"}>
                                    {!this.props.slotGroup.details.autoConfirm ? null : <Button key={slotId + 'addButton'} color="link" onClick={() => {
                                        this.props.handleBookedAdd(accountId, slotId)
                                    }}>
                                        <i className="material-icons material-icons-xs">add_circle</i>
                                    </Button>}
                                    {confirmedCount}
                                    {this.props.slotGroup.details.autoConfirm && confirmedCount > 0 ?
                                        <Button key={slotId + 'cancelButton'} color="link" onClick={() => {
                                            this.props.handleBookedCancel(accountId, slotId)
                                        }}>
                                            <i className="material-icons material-icons-xs">remove_circle</i>
                                        </Button> : <span />
                                    }
                                    {!this.props.slotGroup.details.autoConfirm && confirmedCount > 0 ?
                                        <Button key={slotId + 'unconfirmButton'} color="link" onClick={() => {
                                            this.props.toggleRequestConfirm(accountId, slotId, 0)
                                        }}>
                                            <i className="material-icons material-icons-xs">cancel</i>
                                        </Button>
                                        : <span />
                                    }
                                </td>
                                <td key={slotId + accountId + "td5"}>
                                    {slot.cost == null || slot.cost > 0 || paidCount > 0 ?
                                        <span>{paidCount}{paidCount < confirmedCount ?
                                            <Button key={slotId + 'cancelButton'} color="link" onClick={async () => {
                                                let result = await confirm({ title: "Confirm", message: "Please confirm that this slot has been paid for", confirmText: "Confirm" });
                                                if (result) {
                                                    this.props.makePayment(accountId, slotId, confirmedCount - paidCount);
                                                }
                                            }}>
                                                <i className="material-icons material-icons-xs">credit_card</i>
                                            </Button> : ""}</span> : "-"}
                                </td>
                                {firstRow ? <td rowSpan={accountCount} key={slotId + 'td4'}>
                                    <Button key={slotId + 'closeButton'} color="link" onClick={() => {
                                        this.props.toggleSlotLocked(slotId, !slot.locked)
                                    }}>
                                        <i className="material-icons">{!slot.locked ? "lock_open" : "lock"}</i>
                                    </Button>
                                </td> : ""}
                                {firstRow ? <td rowSpan={accountCount} key={slotId + 'td5'}>
                                    <Button key={slotId + 'editButton'} color="link" onClick={() => {
                                        this.props.openSlotModal(slotId)
                                    }}>
                                        <i className="material-icons">edit</i>
                                    </Button>&nbsp;
                                <Button key={slotId + 'addButton'} color="link" onClick={() => {
                                        this.props.openBookedModal(slotId)
                                    }}>
                                        <i className="material-icons">person_add</i>
                                    </Button>&nbsp;
                                <Button key={slotId + 'ownerButton'} color="link" onClick={() => {
                                        this.props.openOwnerModal(slotId)
                                    }}>
                                        <i className="material-icons">school</i>
                                    </Button>&nbsp;

                                {this.props.slotGroup.details.autoConfirm ? null :
                                        <span>
                                            {anyConfirmed ?
                                                <span>
                                                    <Button key={slotId + 'unconfirmButton'} color="link" onClick={() => {
                                                        this.props.toggleSlotConfirm(slotId, false)
                                                    }}>
                                                        <i className="material-icons">{"cancel"}</i>
                                                    </Button>
                                                </span> : <span />
                                            }
                                            {slotWrapper.accounts != null && !allConfirmed ?
                                                <Button key={slotId + 'confirmButton'} color="link" onClick={() => {
                                                    this.props.toggleSlotConfirm(slotId, true)
                                                }}>
                                                    <i className="material-icons">check_circle</i>
                                                </Button> : <span />
                                            }
                                        </span>
                                    }
                                &nbsp;
                                {!anyConfirmed ?
                                        <Button key={slotId + 'removeButton'} color="link" onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this slot", confirmText: "Confirm" });
                                            if (result) {
                                                this.props.handleSlotRemove(slotId)
                                            }
                                        }}><i className="material-icons">delete</i></Button> : <span />
                                    }

                                </td> : ""}
                            </tr>
                        });
                    }
                })}
            </tbody>
        </Table>;
    }
}

export default SlotDetails;
import React, { Component } from 'react';
import firebase from './firebase';
import queryString from 'query-string';
import {
    Button, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, Card, CardBody
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import { Adventure, AdventureInformationSection, InformationSectionStub } from './data/adventures';
import { User, USER_ROLE } from './data/user';
import { v4 as uuidv4 } from 'uuid';
import ImageUpload from './ninja_components/image_upload';

interface IState {
    adventureId: string;
    adventureTitle: string;
    informationSectionId: string;
    informationSection: AdventureInformationSection;
    adventureSchoolId: string;
    subjectFilter: string,
    currentInformationSectionTitle: string;
    currentInformationSectionInformationSectionLinks: Map<string, string>,
    currentInformationSectionSchoolId: string;
    currentInformationSectionId: string;
    currentInformationSectionText: string;
    currentInformationSectionImageUrl: string;
    currentInformationSectionImagePath: string;
    editWarning: boolean;
}

interface MatchParams {
    informationSectionId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class InformationSectionView extends Component<IProps, IState> {
    informationSections = new Map<string, InformationSectionStub>();

    constructor(props: IProps) {
        super(props);
        this.getQuerys = this.getQuerys.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleBlockChange = this.handleBlockChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleImageChangeFail = this.handleImageChangeFail.bind(this);
        this.loadSection = this.loadSection.bind(this);
        this.getStateObject = this.getStateObject.bind(this);
        this.state = this.getStateObject(props);
    }

    componentDidUpdate(previousProps: IProps, previousState: IState): void {
        if (this.props.match.params.informationSectionId !== previousProps.match.params.informationSectionId) {
            const values = queryString.parse(this.props.location.search);
            let adventureId = values.adventureId == null ? '' : values.adventureId.toString();
            let informationSectionId = this.props.match.params.informationSectionId;
            this.setState(this.getStateObject(this.props));
            this.loadSection(adventureId, informationSectionId);
        }
    }

    getStateObject(props: IProps): IState {
        const values = queryString.parse(props.location.search);
        let adventureId = values.adventureId == null ? '' : values.adventureId.toString();
        let informationSectionId = props.match.params.informationSectionId;
        return {
            adventureId: adventureId,
            adventureTitle: values.adventureTitle == null ? null : values.adventureTitle.toString(),
            informationSectionId: informationSectionId,
            informationSection: null,
            adventureSchoolId: '',
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            currentInformationSectionTitle: '',
            currentInformationSectionInformationSectionLinks: new Map(),
            currentInformationSectionSchoolId: '',
            currentInformationSectionId: '',
            currentInformationSectionText: '',
            currentInformationSectionImageUrl: null,
            currentInformationSectionImagePath: null,
            editWarning: false,
        };
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        let editable = this.state.adventureSchoolId === this.props.user.schoolId || this.props.user.role === USER_ROLE.admin || (this.props.user.roles.has(USER_ROLE.learningCreator) && this.props.user.roles.get(USER_ROLE.learningCreator));

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/adventures'}>All Adventures</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/adventures/${this.state.adventureId}?${queryValues}`}>{this.state.adventureTitle}</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.currentInformationSectionTitle != null ? this.state.currentInformationSectionTitle : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Information section</div>
                        <p className="cardSubTitle">
                            A piece of information that can be linked to from adventure sections and also from other information sections<br />
                        To create a link to another information section add [[linkid|linktext]], for example [[geoffModuleLink|Geoff]] which will make the text 'Geoff' link to an information section.
                        When you add this
                        the link entry will appear at the bottom of this page ready for linking to that information section.
                    </p>
                        {this.state.editWarning ?
                            <Alert color="warning">
                                <div className="cardTitle">Remember to save your changes when finished editing</div>
                            </Alert> : <span />
                        }
                        {this.state.informationSection != null ?
                            <div>
                                <fieldset disabled={!editable}>
                                    <FormGroup>
                                        <Label for="currentInformationSectionTitle">Information section title</Label>
                                        <Input disabled={!editable} type="text" required name="currentInformationSectionTitle" onChange={(e) => this.setState({
                                            currentInformationSectionTitle: e.target.value
                                        })} value={this.state.currentInformationSectionTitle} />
                                    </FormGroup>
                                    <ImageUpload callback={this.handleImageChange} failureCallback={this.handleImageChangeFail} schoolId={this.props.user.schoolId}
                                        currentImage={{ url: this.state.currentInformationSectionImageUrl, path: this.state.currentInformationSectionImagePath }} filePath={"adventureImages"}
                                        imageLegend={"Section image"} />
                                    <FormGroup>
                                        <Label for="currentInformationSectionText">Text</Label>
                                        <Input type="textarea" rows="5" name="currentInformationSectionText"
                                            onChange={this.handleBlockChange}
                                            value={this.state.currentInformationSectionText} /><br />
                                    </FormGroup>
                                    {this.state.currentInformationSectionInformationSectionLinks.size > 0 ?
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Link id</th>
                                                    <th>Information section</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.from(this.state.currentInformationSectionInformationSectionLinks.keys()).map((linkId) => {
                                                    let informationSectionId = this.state.currentInformationSectionInformationSectionLinks.get(linkId);
                                                    let title = '';
                                                    if (informationSectionId != null) {
                                                        let informationSection = this.informationSections.get(informationSectionId);
                                                        if (informationSection != null) {
                                                            title = informationSection.title;
                                                        }
                                                    }
                                                    return <tr key={linkId + 'tr'}>
                                                        <th scope="row"
                                                            key={linkId + 'th'}>{linkId}</th>
                                                        <td key={linkId + 'td4'} align="left">
                                                            {editable ?
                                                                <Input type="select" name="newInformationSectionId"
                                                                    value={informationSectionId}
                                                                    onChange={(value) => {
                                                                        this.handleLinkChange(linkId, value);
                                                                    }}>
                                                                    <option></option>
                                                                    {Array.from(this.informationSections.keys()).map((key) => {
                                                                        return (
                                                                            <option value={key} key={key}>{this.informationSections.get(key).title}</option>
                                                                        )
                                                                    })}
                                                                </Input> : <span />
                                                            }
                                                            <Link to={`/informationSections/${informationSectionId}?${queryValues}`}>{title}</Link>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                        : <span />
                                    }
                                </fieldset>
                                {editable ?
                                    <Button className="adminPagesButton" onClick={() => {
                                        this.handleUpdate();
                                    }}>
                                        {this.state.informationSectionId === "-1" ? "Create" : "Update"}
                                    </Button> : <span />
                                }
                            </div> : <div />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
            adventureId: this.state.adventureId,
            adventureTitle: this.state.adventureTitle,
            informationSectionId: this.state.informationSectionId,
            informationSectionTitle: this.state.informationSection != null ? this.state.informationSection.title : ""
        });
    }

    handleImageChangeFail(): void {
        this.props.snackbar("Image upload failed");
    }

    async handleImageChange(url: string, path: string): Promise<void> {
        let imageDeets = {
            imageUrl: url,
            imagePath: path,
        };
        const sectionRef = firebase.firestore().doc(`adventures/${this.state.adventureId}/informationSections/${this.state.informationSectionId}`);
        const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
        let batch = firebase.firestore().batch();
        try {
            batch.update(sectionRef, imageDeets);
            batch.update(adventureRef, { [`informationSections.${this.state.informationSectionId}.imageUrl`]: url });
            await batch.commit();
            this.setState({
                currentInformationSectionImageUrl: url,
                currentInformationSectionImagePath: path,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
    }

    handleBlockChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let newText = e.target.value;
        let starts = newText.split('[[');
        let newLinkIds = new Map<string, string>();
        for (let i = 0; i < starts.length; i++) {
            let end = starts[i].indexOf(']]');
            let middle = starts[i].indexOf('|');
            if (end !== -1 && middle !== -1) {
                let linkId = starts[i].substring(0, middle);
                if (this.state.currentInformationSectionInformationSectionLinks.has(linkId)) {
                    newLinkIds.set(linkId, this.state.currentInformationSectionInformationSectionLinks.get(linkId));
                } else {
                    newLinkIds.set(linkId, '');
                }
            }
        }
        this.setState({
            currentInformationSectionText: newText,
            editWarning: true,
            currentInformationSectionInformationSectionLinks: newLinkIds,
        });
    }

    handleLinkChange(linkId: string, e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            editWarning: true,
            currentInformationSectionInformationSectionLinks: new Map([
                ...this.state.currentInformationSectionInformationSectionLinks,
                [linkId, e.target.value]
            ])
        });
    }

    async handleUpdate(): Promise<void> {
        let informationSection = this.state.informationSection;
        informationSection.title = this.state.currentInformationSectionTitle;
        informationSection.text = this.state.currentInformationSectionText;
        informationSection.informationLinkIds = this.state.currentInformationSectionInformationSectionLinks;

        let updatedStub = new InformationSectionStub();
        updatedStub.title = this.state.currentInformationSectionTitle;
        updatedStub.descriptionStub = this.state.currentInformationSectionText.length > 30 ? this.state.currentInformationSectionText.substring(0, 30) : this.state.currentInformationSectionText;
        updatedStub.imageUrl = this.state.currentInformationSectionImageUrl;
        this.informationSections.set(this.state.informationSectionId, updatedStub);

        const sectionRef = firebase.firestore().doc(`adventures/${this.state.adventureId}/informationSections/${this.state.informationSectionId}`);
        const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
        let batch = firebase.firestore().batch();
        try {
            batch.update(sectionRef, informationSection.toFirebase());
            batch.update(adventureRef, { [`informationSections.${this.state.informationSectionId}`]: updatedStub.toFirebase() });
            await batch.commit();

            this.setState({
                informationSection: informationSection,
                editWarning: false,
            });
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async loadSection(adventureId: string, sectionId: string) {
        const sectionRef = firebase.firestore().doc(`adventures/${adventureId}/informationSections/${sectionId}`);
        let informationSectionSnapshot = await sectionRef.get();
        let informationSection = AdventureInformationSection.fromFirebase(informationSectionSnapshot.data());
        this.setState({
            informationSection: informationSection,
            currentInformationSectionTitle: informationSection.title,
            currentInformationSectionText: informationSection.text,
            currentInformationSectionInformationSectionLinks: informationSection.informationLinkIds != null ? informationSection.informationLinkIds : new Map(),
            currentInformationSectionImageUrl: informationSection.imageUrl,
            currentInformationSectionImagePath: informationSection.imagePath,
            adventureSchoolId: informationSection.schoolId,
        });
    }

    async componentDidMount(): Promise<void> {
        try {
            if (this.state.informationSectionId !== "-1") {
                await this.loadSection(this.state.adventureId, this.state.informationSectionId);
            } else {
                let informationSection = new AdventureInformationSection();
                if (this.props.user.role !== USER_ROLE.admin && !this.props.user.roles.get(USER_ROLE.learningCreator)) {
                    informationSection.schoolId = this.props.user.schoolId;
                }

                let sectionId = uuidv4();
                const informationSectionRef = firebase.firestore().doc(`adventures/${this.state.adventureId}/informationSections/${sectionId}`);
                const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
                let batch = firebase.firestore().batch();
                try {
                    batch.set(informationSectionRef, informationSection.toFirebase());
                    let updatedStub = new InformationSectionStub();
                    batch.update(adventureRef, { [`informationSections.${sectionId}`]: updatedStub.toFirebase() });
                    await batch.commit();

                    this.setState({
                        informationSection: informationSection,
                        informationSectionId: sectionId,
                        editWarning: false,
                        currentInformationSectionTitle: informationSection.title,
                        currentInformationSectionText: informationSection.text,
                        currentInformationSectionInformationSectionLinks: informationSection.informationLinkIds != null ? informationSection.informationLinkIds : new Map(),
                        adventureSchoolId: informationSection.schoolId,
                    });
                    this.props.history.replace(`/informationSections/${sectionId}?${this.getQuerys()}`);
                    this.props.snackbar();
                } catch (error) {
                    console.log(error);
                    this.props.snackbar("Save failed");
                }
            }
            const adventureRef = firebase.firestore().doc(`adventures/${this.state.adventureId}`);
            let adventureSnapshot = await adventureRef.get()
            let adventure = Adventure.fromFirebase(adventureSnapshot.data());

            this.informationSections = adventure.informationSections != null ? adventure.informationSections : new Map<string, InformationSectionStub>();
            this.setState({});
        } catch (error) {
            console.log(error);
        }
    }
}

export default InformationSectionView;
import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, Card, CardBody,
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import confirm from "reactstrap-confirm";
import { permissions } from './Prettifier';
import { User } from './data/user';

interface IState {
    adminAccountId: string;
    currentAdminAccountPermissions: Map<string, boolean>,
    currentAdminAccountExistingPermissions: Map<string, boolean>,
    currentAdminAccountActive: boolean;
    inviteSending: boolean;
    editable: boolean;
    conversion: boolean;
    loaded: boolean;
}

interface MatchParams {
    adminAccountId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class AdminAccountView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            adminAccountId: props.match.params.adminAccountId,
            currentAdminAccountPermissions: new Map(),
            currentAdminAccountExistingPermissions: new Map(),
            currentAdminAccountActive: false,
            inviteSending: false,
            editable: false,
            conversion: false,
            loaded: false,
        };

        this.handlePermissionChange = this.handlePermissionChange.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        this.handlePermissionSave = this.handlePermissionSave.bind(this);
    }

    render(): JSX.Element {

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={'/adminAccounts'}>All admin-enabled accounts</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Admin details</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Admin-enabled account</div>
                        <p className="cardSubTitle">An admin-enabled account is an account with admin permissions assigned to it</p>
                        {this.state.loaded ?
                            <div>
                                {this.props.user.permissions.includes('ADMIN_MANAGER') ||
                                    this.props.user.permissions.includes('ADMIN_ADMINISTRATOR') ||
                                    this.props.user.permissions.includes('ACCOUNT_ADMINISTRATOR') ||
                                    this.props.user.permissions.includes('MODULE_ADMINISTRATOR') ||
                                    this.props.user.permissions.includes('MESSAGE_ADMINISTRATOR') ||
                                    this.props.user.permissions.includes('EVENT_ADMINISTRATOR') ||
                                    this.props.user.permissions.includes('REPORTING_ADMINISTRATOR') ?
                                    this.state.currentAdminAccountActive ?
                                        <Button disabled={this.state.adminAccountId === this.props.user.accountId} className="adminPagesButton" onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove admin access for this account", confirmText: "Confirm" });
                                            if (result) {
                                                this.handleActiveChange(false)
                                            }
                                        }}>Disable admin</Button> :
                                        <Button className="adminPagesButton" onClick={() => this.handleActiveChange(true)}>Enable admin</Button>
                                    : <span />
                                }
                                <br /><br />
                                {this.state.currentAdminAccountActive ?
                                    <Form onSubmit={this.handlePermissionSave}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Permission</th>
                                                    <th>Has permission?</th>
                                                    <th>Can give permission to other admins?</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.from(permissions.keys()).map((key) => {
                                                    let permission = permissions.get(key);
                                                    let managerKey = key + "_MANAGER";
                                                    let adminKey = key + "_ADMINISTRATOR";
                                                    let disabled = false;
                                                    if (!this.props.user.permissions.includes(adminKey)) {
                                                        disabled = true;
                                                    }
                                                    if (disabled && !this.state.currentAdminAccountPermissions.get(managerKey) &&
                                                        !this.state.currentAdminAccountPermissions.get(adminKey)) {
                                                        return "";
                                                    }
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <b>{permission.title}</b><br />
                                                                {permission.description}
                                                            </td>
                                                            <td align="center">
                                                                <Input disabled={disabled} type="checkbox" name='currentAdminAccountPermissions'
                                                                    checked={this.state.currentAdminAccountPermissions.get(managerKey)}
                                                                    onChange={(e) => this.handlePermissionChange(e, key, true)} />
                                                            </td>
                                                            <td align="center">
                                                                <Input disabled={disabled} type="checkbox" name='currentAdminAccountPermissions'
                                                                    checked={this.state.currentAdminAccountPermissions.get(adminKey)}
                                                                    onChange={(e) => this.handlePermissionChange(e, key, false)} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                        <br />
                                        <Button className="adminPagesButton">Update</Button>
                                    </Form> : <span />
                                }
                            </div> : <br />
                        }
                        <br /><br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    handlePermissionChange(e: React.ChangeEvent<HTMLInputElement>, permissionId: string, isManager: boolean): void {
        let active = e.target.checked;
        let managerKey = permissionId + "_MANAGER";
        let adminKey = permissionId + "_ADMINISTRATOR";
        if (active) {
            // Ensure manager is always checked for administrator check (and obvs for manager check!)
            this.state.currentAdminAccountPermissions.set(managerKey, true);
            if (!isManager) {
                this.state.currentAdminAccountPermissions.set(adminKey, true);
            }
        } else {
            // Ensure administrator is always unchecked for manager uncheck (and obvs for administrator uncheck!)
            this.state.currentAdminAccountPermissions.set(adminKey, false);
            if (isManager) {
                this.state.currentAdminAccountPermissions.set(managerKey, false);
            }
        }
        this.setState({});
    }

    async handlePermissionSave(e: React.FormEvent) {
        e.preventDefault();
        let permissionsToSave = new Map<string, boolean>();
        // Database rules just check exists on admin permissions, so need to filter out all falses
        Array.from(this.state.currentAdminAccountPermissions.keys()).forEach((permissionId) => {
            let value = this.state.currentAdminAccountPermissions.get(permissionId);
            if (this.state.currentAdminAccountExistingPermissions.get(permissionId) !== value) {
                permissionsToSave.set(permissionId, value);
            }
        });
        let promises: Promise<any>[] = [];
        try {
            Array.from(permissionsToSave.keys()).forEach((permissionId) => {
                let adminAccountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/admins/${this.state.adminAccountId}/permissions/${permissionId}`);
                if (permissionsToSave.get(permissionId) === true) {
                    promises.push(adminAccountRef.set(true));
                } else {
                    promises.push(adminAccountRef.remove());
                }
            });
            await Promise.all(promises);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
        this.setState({
            currentAdminAccountExistingPermissions: new Map([...this.state.currentAdminAccountPermissions]),
        })
    }

    async handleActiveChange(active: boolean): Promise<void> {
        const adminPermissionsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/admins/${this.state.adminAccountId}/permissions`);
        try {
            await adminPermissionsRef.remove();
            const adminAccountRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/${this.state.adminAccountId}/isAdmin/`);
            await adminAccountRef.set(active);

            if (active) {
                this.setState({
                    currentAdminAccountActive: active,
                });
            } else {
                this.setState({
                    currentAdminAccountPermissions: new Map(),
                    currentAdminAccountActive: active,
                });
            }
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Update failed");
        }
    }

    async componentDidMount(): Promise<void> {
        const permissionsRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/admins/${this.state.adminAccountId}/permissions`);
        let permissionsSnapshot = await permissionsRef.once('value');
        let permissions = permissionsSnapshot.val();

        const isAdminRef = firebase.database().ref(`schoolManagement/${this.props.user.schoolId}/accounts/${this.state.adminAccountId}/isAdmin`);
        let isAdminSnapshot = await isAdminRef.once('value');
        let isAdmin = isAdminSnapshot.val();
        let newPermissions = new Map<string, boolean>()
        if (permissions != null) {
            for (let nextPermissionId in permissions) {
                newPermissions.set(nextPermissionId, permissions[nextPermissionId]);
            }
        }
        this.setState({
            currentAdminAccountPermissions: newPermissions,
            currentAdminAccountExistingPermissions: new Map([...newPermissions]),
            currentAdminAccountActive: isAdmin != null ? isAdmin : false,
            loaded: true,
        });
    }
}

export default AdminAccountView;
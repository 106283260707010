import { objectToClassConverter } from "./database_object";
import firebase from "../firebase";

export class EngagementAward {
    cost: number = null;
    title: string = null;
    description: string = null;
    points: number = null;
    imageUrl: string = null;
    imagePath: string = null;
    updateDate: number = null;
    removed: boolean = null;
    userCreateable: boolean = null;

    static fromFirebase(data: any): EngagementAward {
        let newEngagementAward = new EngagementAward();
        if (data == null) {
            return newEngagementAward;
        }
        objectToClassConverter(data, newEngagementAward);

        return newEngagementAward;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        fbObject['updateDate'] = firebase.firestore.FieldValue.serverTimestamp();
        return fbObject;
    }
}
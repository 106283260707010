import React, { Component } from 'react';
import firebase from './firebase';
import {
    Breadcrumb, BreadcrumbItem, Card, CardBody
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import queryString from 'query-string';
import { HomeLearning, HomeLearningActivityLearningPath, LearningGameOutcome } from './data/home_learning';
import { LearningGame } from './data/learning_games';
import { AccountMinimised } from './data/accounts';

interface IState {
    homeworkName: string;
    gameName: string;
    activityName: string,
    homeworkId: string;
    homeworkActivityId: string;
    gameId: string;
    homework: HomeLearning;
    outcomesMap: Map<string, LearningGameOutcome[]>,
    learningGame: LearningGame;
}

interface MatchParams {
    homeworkId: string;
    homeworkActivityId: string;
    gameId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    accountsMinimised: Map<string, AccountMinimised>;
}

class HomeworkGameAnalysis extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            homeworkName: values.homeworkName == null ? "" : values.homeworkName.toString(),
            gameName: values.gameName == null ? "" : values.gameName.toString(),
            activityName: values.activityName == null ? "" : values.activityName.toString(),
            homeworkId: props.match.params.homeworkId,
            homeworkActivityId: props.match.params.homeworkActivityId,
            gameId: props.match.params.gameId,
            homework: null,
            outcomesMap: new Map(),
            learningGame: null,
        };
    }

    render(): JSX.Element {
        let orderedAccountIds: string[] = [];
        if (this.state.homework != null) {
            orderedAccountIds = this.state.homework.accounts.slice();
            orderedAccountIds = orderedAccountIds.filter((id) => this.props.accountsMinimised.get(id) != null);
            orderedAccountIds.sort((id1, id2) => {
                let account1 = this.props.accountsMinimised.get(id1);
                let account2 = this.props.accountsMinimised.get(id2);
                return account1.getLowercaseName().localeCompare(account2.getLowercaseName());
            });
        }

        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/homeworks'}>All homework</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={`/homeworks/${this.state.homeworkId}`}>{this.state.homeworkName}</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={`/homeworkActivityLearningPath/${this.state.homeworkId}/${this.state.homeworkActivityId}`}>{this.state.activityName}</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.state.gameName}</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">{this.state.gameName}</div>
                    <p className="cardSubTitle">{this.state.homeworkName}</p>
                    {
                        (this.state.learningGame != null) ? <div>
                            {orderedAccountIds.map(accountId => {
                                let account = this.props.accountsMinimised.get(accountId);
                                let name;
                                let additional;
                                if (account == null) {
                                    name = "Deactivated account";
                                    additional = "";
                                } else {
                                    name = account.name;
                                    additional = account.additional;
                                }
                                let outcomes = this.state.outcomesMap.get(accountId);
                                let analysis;
                                if (outcomes == null || outcomes.length == 0) {
                                    analysis = <div>Not played</div>
                                } else {
                                    let successCount = 0;
                                    let flawlessCount = 0;
                                    let failureCount = 0;
                                    let fastest = -1;
                                    outcomes.forEach(nextOutcome => {
                                        if (nextOutcome.completed) {
                                            successCount++;
                                        }
                                        if (nextOutcome.flawless) {
                                            flawlessCount++;
                                        } else {
                                            failureCount++;
                                        }
                                        let timeTaken = nextOutcome.endDateTime - nextOutcome.startDateTime;
                                        if (fastest === -1 || fastest > timeTaken) {
                                            fastest = timeTaken;
                                        }
                                    });
                                    analysis = <div>Completed: {successCount > 0 ? <span>&#10004; ({successCount} time{successCount === 1 ? "" : "s"})</span> : <span>&#10060;</span>}
                                        <br />Flawless: {flawlessCount > 0 ? <span>&#10004;</span> : <span>&#10060;</span>}
                                        <br />Fastest: {(fastest / 1000).toFixed(2)} seconds</div>
                                }
                                return <div><b>{account.name} {account.additional}</b><br />{analysis}<br /></div>
                            })}
                        </div> : <div>Loading...</div>
                    }
                </CardBody>
            </Card>
        </div>
    }

    async componentDidMount(): Promise<void> {
        const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
        let homeworkSnapshot = await homeworkRef.get();
        let homework = HomeLearning.fromFirebase(homeworkSnapshot.data());
        let homeworkActivity = homework.activities.get(this.state.homeworkActivityId) as HomeLearningActivityLearningPath;

        let learningGameSnapshot = await firebase.firestore().doc(`learningGames/${this.state.gameId}`).get();
        let learningGame = LearningGame.fromFirebase(learningGameSnapshot);

        let promises: Promise<firebase.firestore.DocumentData>[] = [];
        homework.accounts.forEach(accountId => {
            promises.push(firebase.firestore().collection(`learningGameOutcomes`)
                .where('accountId', '==', accountId)
                .where('pathId', '==', homeworkActivity.pathId)
                .where('gameId', '==', this.state.gameId).get());
        });

        let outcomesSnapshots = await Promise.all(promises);

        let outcomesMap = new Map<string, LearningGameOutcome[]>();
        outcomesSnapshots.forEach(outcomesRef => {
            outcomesRef.forEach((doc: any) => {
                let outcome = LearningGameOutcome.fromFirebase(doc.data());
                if (!outcomesMap.has(outcome.accountId)) {
                    outcomesMap.set(outcome.accountId, []);
                }
                outcomesMap.get(outcome.accountId).push(outcome);
            });
        });

        this.setState({
            homework: homework,
            outcomesMap: outcomesMap,
            learningGame: learningGame,
        });
    }
}

export default HomeworkGameAnalysis;
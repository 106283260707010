import React, { Component } from 'react';
import firebase from './firebase';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, Table, Modal, ModalHeader, ModalBody, Card, CardHeader, Collapse, CardBody,
    InputGroup, InputGroupAddon, DropdownToggle, ButtonDropdown, DropdownMenu, DropdownItem, CustomInput
} from 'reactstrap';
import { Link, RouteComponentProps } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EmojiSelection from "./ninja_components/emoji_selection";
import confirm from "reactstrap-confirm";
import AttachmentUpload from './ninja_components/attachment_upload';
import queryString from 'query-string';
import { subjectIds } from './Prettifier';
import { Attachment, HomeLearning, HomeLearningActivity, HomeLearningActivityAdventure, HomeLearningActivityWorksheet, HomeLearningLink, HomeLearningResponse, HomeLearningActivityResponse, LearningPathOverview, HomeLearningActivityBasic } from './data/home_learning';
import { User } from './data/user';
import { AccountGroup, AccountMinimised } from './data/accounts';
import { createUpdateFragment } from './data/database_object';
import TopicsSelector from './ninja_components/topics_selector';

interface IState {
    homework: HomeLearning;
    homeworkId: string;
    subjectFilter: string;
    currentHomeworkTitle: string;
    currentHomeworkStartDateTime: Date;
    currentHomeworkDueDateTime: Date;
    currentHomeworkDescription: string;
    currentHomeworkAccounts: string[];
    currentHomeworkAttachments: Array<Attachment>,
    currentHomeworkLinks: Array<HomeLearningLink>,
    currentHomeworkSubjectId: string;
    currentHomeworkResponses: Map<string, HomeLearningResponse>,
    currentHomeworkActivities: Map<string, HomeLearningActivity>;
    currentHomeworkActivitiesOrderedIds: Array<string>;
    newHomeworkAccountGroup: string;
    newHomeworkAccountGroupFutures: boolean;
    newHomeworkAccount: string;
    newInviteesView: boolean;
    editTarget: boolean;
    allowTypeSelection: boolean;
    showFeedback: boolean;
    feedbackAccountId: string;
    newFeedback: string;
    filter: string;
    collapse: boolean;
    haveZap: boolean;
    haveHelpMeEnglish: boolean;
    haveLearningNinja: boolean;
    dropdownOpen: boolean;
}

interface MatchParams {
    homeworkId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    accountsMinimised: Map<string, AccountMinimised>;
    user: User;
    accountGroups: Map<string, AccountGroup>;
    snackbar: (text?: string) => void;
    schoolLicenses: Map<string, boolean>;
}

class HomeworkView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            homework: null,
            homeworkId: props.match.params.homeworkId,
            subjectFilter: values.subjectFilter == null ? null : values.subjectFilter.toString(),
            currentHomeworkTitle: '',
            currentHomeworkStartDateTime: null,
            currentHomeworkDueDateTime: null,
            currentHomeworkDescription: '',
            currentHomeworkAccounts: [],
            currentHomeworkAttachments: [],
            currentHomeworkLinks: [],
            currentHomeworkSubjectId: '',
            currentHomeworkResponses: new Map(),
            currentHomeworkActivities: new Map(),
            currentHomeworkActivitiesOrderedIds: [],
            newHomeworkAccountGroup: '',
            newHomeworkAccountGroupFutures: true,
            newHomeworkAccount: '',
            newInviteesView: false,
            editTarget: props.match.params.homeworkId === "-1",
            allowTypeSelection: false,
            showFeedback: false,
            feedbackAccountId: '',
            newFeedback: '',
            filter: 'NAME',
            collapse: props.match.params.homeworkId !== "-1",
            haveZap: false,
            haveHelpMeEnglish: false,
            haveLearningNinja: false,
            dropdownOpen: false,
        };
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleRecipientAdd = this.handleRecipientAdd.bind(this);
        this.handleRecipientGroupAdd = this.handleRecipientGroupAdd.bind(this);
        this.createHomework = this.createHomework.bind(this);
        this.toggleNewInviteesModal = this.toggleNewInviteesModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleEmojiChange = this.handleEmojiChange.bind(this);
        this.handleAttachmentChange = this.handleAttachmentChange.bind(this);
        this.handleAttachmentChangeFail = this.handleAttachmentChangeFail.bind(this);
        this.handleLinkAdd = this.handleLinkAdd.bind(this);
        this.handleLinkRemove = this.handleLinkRemove.bind(this);
        this.handleLinkNameChange = this.handleLinkNameChange.bind(this);
        this.handleLinkUrlChange = this.handleLinkUrlChange.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.showFeedback = this.showFeedback.bind(this);
        this.closeFeedback = this.closeFeedback.bind(this);
        this.handleFeedback = this.handleFeedback.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.moveItemUp = this.moveItemUp.bind(this);
    }

    render() {
        let queryValues = this.getQuerys();

        let orderedAccountIds: string[] = this.state.currentHomeworkAccounts.slice();
        orderedAccountIds = orderedAccountIds.filter((id) => this.props.accountsMinimised.get(id) != null);
        orderedAccountIds.sort((entry1, entry2) => {
            let account1 = this.props.accountsMinimised.get(entry1);
            let account2 = this.props.accountsMinimised.get(entry2);
            return account1.getLowercaseName().localeCompare(account2.getLowercaseName());
        });

        let orderedAllIds = Array.from(this.props.accountsMinimised.keys());
        orderedAllIds = orderedAllIds.filter((el) => {
            return !orderedAccountIds.includes(el);
        });
        orderedAllIds.sort((id1, id2) => {
            return this.props.accountsMinimised.get(id1).getLowercaseName().localeCompare(this.props.accountsMinimised.get(id2).getLowercaseName());
        });

        let orderedAllGroupIds = Array.from(this.props.accountGroups.keys());
        orderedAllGroupIds.sort((id1, id2) => {
            return this.props.accountGroups.get(id1).details.getLowercaseName().localeCompare(this.props.accountGroups.get(id2).details.getLowercaseName());
        });

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to={`/homeworks?${queryValues}`}>All homework</Link></BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.homework != null ? this.state.homework.title : ""}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Home learning</div>
                        <p className="">Home learning is a series of activities assigned to students, with visibility of progress and the option to give feedback</p>
                        {(this.state.homework != null) ?
                            <div>
                                <Button onClick={this.toggle} className="altButton">
                                    {this.state.collapse ? "View details" : "Close"}
                                </Button>
                                <Collapse isOpen={!this.state.collapse}>
                                    <Form onSubmit={this.handleUpdate}>
                                        <FormGroup>
                                            <Label for="currentHomeworkTitle">Title *</Label>
                                            <InputGroup>
                                                <Input type="text" required name="currentHomeworkTitle" onChange={(e) => this.setState({
                                                    currentHomeworkTitle: e.target.value
                                                })} value={this.state.currentHomeworkTitle} />
                                                <InputGroupAddon addonType={"append"}>
                                                    <EmojiSelection callback={(emoji) => {
                                                        this.handleEmojiChange(emoji, "currentHomeworkTitle");
                                                    }} />
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentHomeworkDescription">Description *</Label>
                                            <InputGroup>
                                                <Input type="textarea" required name="currentHomeworkDescription" onChange={(e) => this.setState({
                                                    currentHomeworkDescription: e.target.value
                                                })} value={this.state.currentHomeworkDescription} />
                                                <InputGroupAddon addonType={"append"}>
                                                    <EmojiSelection callback={(emoji) => {
                                                        this.handleEmojiChange(emoji, "currentHomeworkDescription");
                                                    }} />
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentHomeworkSubjectId">Subject *</Label>
                                            <Input type="select" name="currentHomeworkSubjectId"
                                                onChange={this.handleSubjectChange}
                                                disabled={this.state.homeworkId !== "-1"}
                                                value={this.state.currentHomeworkSubjectId}>
                                                <option value={''}>&nbsp;</option>
                                                {Array.from(subjectIds.keys()).map(subjectId => {
                                                    let subjectName = subjectIds.get(subjectId);
                                                    return <option value={subjectId}>{subjectName}</option>;
                                                })}
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentHomeworkStartDateTime">Start date &amp; time *</Label><br />
                                            <DatePicker
                                                dropdownMode='scroll'
                                                selected={this.state.currentHomeworkStartDateTime}
                                                showTimeSelect
                                                required
                                                dateFormat="dd MMM yyyy - HH:mm"
                                                timeFormat="HH:mm"
                                                minDate={new Date()}
                                                onChange={this.handleStartDateChange}
                                                className="form-control"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="currentHomeworkDueDateTime">Due date &amp; time</Label><br />
                                            <DatePicker
                                                dropdownMode='scroll'
                                                selected={this.state.currentHomeworkDueDateTime}
                                                showTimeSelect
                                                dateFormat="dd MMM yyyy - HH:mm"
                                                timeFormat="HH:mm"
                                                minDate={this.state.currentHomeworkStartDateTime}
                                                onChange={this.handleEndDateChange}
                                                className="form-control"
                                            />
                                        </FormGroup>
                                        {this.state.haveLearningNinja || (this.state.haveHelpMeEnglish && this.state.currentHomeworkSubjectId != null && this.state.currentHomeworkSubjectId === "english-literature") ?
                                            <div>
                                                <AttachmentUpload callback={this.handleAttachmentChange} failureCallback={this.handleAttachmentChangeFail} filePath={"homeworkAttachments"}
                                                    schoolId={this.props.user.schoolId} currentAttachments={this.state.currentHomeworkAttachments} />
                                                <Label>Web links</Label>
                                                {this.state.currentHomeworkLinks.map((nextLink, index) => {
                                                    return <FormGroup>
                                                        <InputGroup>
                                                            <Input type="text" placeholder="Title" name={`currentHomeworkLinkName${index}`} onChange={(value) => this.handleLinkNameChange(value, index)} value={nextLink.name} />
                                                            <Input type="url" placeholder="Link address" name={`currentHomeworkLinkUrl${index}`} onChange={(value) => this.handleLinkUrlChange(value, index)} value={nextLink.url} />
                                                            <InputGroupAddon addonType={"append"}>
                                                                <Button onClick={() => this.handleLinkRemove(index)} color={"link"}>
                                                                    <i className="material-icons">delete</i>
                                                                </Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                })}
                                                <FormGroup>
                                                    <Button className="altButton" onClick={this.handleLinkAdd} outline>Add web link</Button>
                                                </FormGroup>
                                            </div> : <span />
                                        }
                                        <div>
                                            <Button className="adminPagesButton">
                                                {this.state.homeworkId !== "-1" ? "Update" : "Create homework"}
                                            </Button>
                                        </div>
                                    </Form>
                                </Collapse>
                                <br />
                            </div> : <span />
                        }
                    </CardBody>
                </Card>
                {(this.state.homework != null) ?

                    <Card className="mainCard top-buffer">
                        <CardBody className="d-flex flex-column">
                            {this.state.homeworkId === "-1" ? <br /> :
                                <div>
                                    <div className="cardTitle2">Activities {orderedAccountIds.length > 0 ? ` (${orderedAccountIds.length})` : ""}</div>
                                    <div className="top-buffer">
                                        <ButtonDropdown color="primary" isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                            <DropdownToggle caret className="adminPagesButton" >
                                                Create new activity
                                        </DropdownToggle>
                                            <DropdownMenu>
                                                {this.state.haveLearningNinja ?
                                                    <DropdownItem>
                                                        <Link to={`/homeworkActivityBasic/${this.state.homeworkId}/-1?${queryValues}`}>
                                                            New basic activity
                                                </Link>
                                                    </DropdownItem>
                                                    : <span />
                                                }
                                                <DropdownItem>
                                                    <Link to={`/homeworkActivityLearningPath/${this.state.homeworkId}/-1?${queryValues}`}>
                                                        New learning path activity
                                                </Link>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Link to={`/homeworkActivityAdventure/${this.state.homeworkId}/-1?${queryValues}`}>
                                                        New adventure activity
                                                </Link>
                                                </DropdownItem>
                                                {this.state.haveLearningNinja ?
                                                    <DropdownItem>
                                                        <Link to={`/homeworkActivityWorksheet/${this.state.homeworkId}/-1?${queryValues}`}>
                                                            New worksheet activity
                                                    </Link>
                                                    </DropdownItem> : <span />}
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </div>
                                    <br /><br />
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>% complete</th>
                                                <th>% reviewed</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.currentHomeworkActivitiesOrderedIds.map((activityId, index) => {
                                                let activity = this.state.currentHomeworkActivities.get(activityId);
                                                let totalPeople = orderedAccountIds.length;
                                                let countDone = 0;
                                                let countReviewed = 0;
                                                for (let [nextResponseId, nextResponse] of this.state.currentHomeworkResponses) {
                                                    if (nextResponse.activityResponses != null && nextResponse.activityResponses.has(activityId)) {
                                                        let nextActivityResponse = nextResponse.activityResponses.get(activityId);
                                                        if (nextActivityResponse.completed != null && nextActivityResponse.completed) {
                                                            countDone++;
                                                        }
                                                        if (nextActivityResponse.reviewed != null && nextActivityResponse.reviewed) {
                                                            countReviewed++;
                                                        }
                                                    }
                                                }
                                                let percentDone = totalPeople == 0 ? 0 : (countDone / totalPeople) * 100;
                                                let percentReviewed = countDone == 0 ? 0 : (countReviewed / countDone) * 100;

                                                let homeworkLink;
                                                if (activity.type == HomeLearningActivityBasic.TYPE) {
                                                    homeworkLink = "homeworkActivityBasic";
                                                } else if (activity.type == HomeLearningActivityWorksheet.TYPE) {
                                                    homeworkLink = "homeworkActivityWorksheet";
                                                } else if (activity.type == HomeLearningActivityAdventure.TYPE) {
                                                    homeworkLink = "homeworkActivityAdventure";
                                                } else {
                                                    homeworkLink = "homeworkActivityLearningPath";
                                                }

                                                return <tr>
                                                    <td><Link to={`/${homeworkLink}/${this.state.homeworkId}/${activityId}?${queryValues}`}>{activity.title}</Link></td>
                                                    <td>{activity.getTypeName()}</td>
                                                    <td>{percentDone.toFixed(0)}</td>
                                                    <td>{percentReviewed.toFixed(0)}</td>
                                                    <td key={`actions-${activityId}`}>
                                                        <Button color="link" onClick={async () => {
                                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this activity", confirmText: "Confirm" });
                                                            if (result) {
                                                                this.removeItem(activityId);
                                                            }
                                                        }
                                                        }><i className="material-icons">delete</i></Button>
                                                        &nbsp;
                                                        {index > 0 ?
                                                            <Button color="link" onClick={async () => {
                                                                this.moveItemUp(activityId);
                                                            }
                                                            }><i className="material-icons">arrow_upward</i></Button> : <span />
                                                        }
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </CardBody></Card>
                    : <span />
                }
                {(this.state.homework != null) ?

                    <Card className="mainCard top-buffer">
                        <CardBody className="d-flex flex-column">
                            {this.state.homeworkId === "-1" ? <br /> :
                                <div>
                                    <div className="cardTitle2">Invitees {orderedAccountIds.length > 0 ? ` (${orderedAccountIds.length})` : ""}
                                        <span className={"toggle-icon"}>

                                        </span>
                                    </div>
                                    <div className="top-buffer bottom-buffer">
                                        <Button onClick={this.toggleNewInviteesModal} className="adminPagesButton">
                                            Add invitee
                                            </Button>
                                    </div>
                                    {orderedAccountIds.length > 0 ?
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Name <Button disabled={this.state.filter === 'NAME'} color="link" onClick={async () => {
                                                        this.setState({
                                                            filter: 'NAME'
                                                        })
                                                    }}><i className="material-icons material-icons-xs">sort</i></Button>
                                                    </th>
                                                    <th>Additional</th>
                                                    <th>Progress</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderedAccountIds.map((accountId) => {
                                                    let homeworkResponse = this.state.currentHomeworkResponses.get(accountId);
                                                    let account = this.props.accountsMinimised.get(accountId);

                                                    let status;
                                                    if (homeworkResponse == null) {
                                                        status = "Not started";
                                                    } else {
                                                        let allComplete = true;
                                                        let allReviewed = true;

                                                        for (let activityId of this.state.currentHomeworkActivitiesOrderedIds) {
                                                            let found = false;
                                                            if (homeworkResponse.activityResponses != null && homeworkResponse.activityResponses.has(activityId)) {
                                                                let nextActivityResponse = homeworkResponse.activityResponses.get(activityId);
                                                                if (nextActivityResponse.completed == null || !nextActivityResponse.completed) {
                                                                    allComplete = false;
                                                                }
                                                                if (nextActivityResponse.reviewed == null || !nextActivityResponse.reviewed) {
                                                                    allReviewed = false;
                                                                }
                                                                found = true;
                                                            }
                                                            if (!found) {
                                                                allComplete = false;
                                                                allReviewed = false;
                                                            }
                                                            if (!allComplete && !allReviewed) {
                                                                break;
                                                            }
                                                        }
                                                        status = allReviewed ? "Reviewed" : allComplete ? "Completed" : "Incomplete";
                                                    }

                                                    let name;
                                                    let additional;
                                                    if (account == null) {
                                                        name = "Deactivated account";
                                                        additional = "";
                                                    } else {
                                                        name = account.name;
                                                        additional = account.additional;
                                                    }
                                                    return <tr key={accountId + 'tr'}>
                                                        <th scope="row" key={accountId + 'th'}><Link to={`/accounts/${accountId}`}>{name}</Link></th>
                                                        <td key={accountId + 'td1'}>{additional}</td>
                                                        <td key={accountId + 'td2'}>{status}</td>
                                                        <td key={accountId + 'td5'}>
                                                            {homeworkResponse != null && homeworkResponse.completed ?
                                                                <span>
                                                                    <Button onClick={() => {
                                                                        this.showFeedback(accountId);
                                                                    }} color={"link"}>
                                                                        <i className="material-icons">{homeworkResponse.reviewed == null || !homeworkResponse.reviewed ? "done_outline" : "notes"}</i>
                                                                    </Button>
                                                                &nbsp;
                                                            </span> : <span />
                                                            }
                                                            <Button key={accountId + 'button'} color="link" onClick={async () => {
                                                                let result = await confirm({ title: "Confirm", message: "Please confirm you want to remove this recipient", confirmText: "Confirm" });
                                                                if (result) {
                                                                    this.removeRecipient(accountId)
                                                                }
                                                            }}>
                                                                <i className="material-icons">delete</i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table> : <div>No Invitees</div>
                                    }
                                </div>
                            }
                        </CardBody>
                    </Card> : <span />
                }
                <Modal isOpen={this.state.showFeedback} toggle={this.closeFeedback}>
                    <ModalHeader toggle={this.closeFeedback}>Give feedback</ModalHeader>
                    <ModalBody>
                        <div>
                            <Form onSubmit={this.handleFeedback}>
                                <FormGroup>
                                    <Label for="newFeedback">Feedback *</Label>
                                    <InputGroup>
                                        <Input type="textarea" required name="newFeedback" onChange={(e) => this.setState({
                                            newFeedback: e.target.value
                                        })} value={this.state.newFeedback} />
                                        <InputGroupAddon addonType={"append"}>
                                            <EmojiSelection callback={(emoji) => {
                                                this.handleEmojiChange(emoji, "newFeedback");
                                            }} />
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <Button color="success">
                                    Send
                            </Button>
                            </Form>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.newInviteesView} toggle={this.toggleNewInviteesModal}>
                    <ModalHeader toggle={this.toggleNewInviteesModal}>Add accounts</ModalHeader>
                    <ModalBody>
                        <div className="border rounded form-margin">
                            <FormGroup>
                                <Label for="newHomeworkAccount">Accounts</Label>
                                <Input type="select" name="newHomeworkAccount"
                                    onChange={(e) => this.setState({
                                        newHomeworkAccount: e.target.value
                                    })}>
                                    <option value={""}>&nbsp;</option>
                                    {/*TODO*/}
                                    {orderedAllIds.map((key) => {
                                        return (
                                            <option value={key}
                                                key={key}>{this.props.accountsMinimised.get(key).name}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup>
                            <Button color="success" disabled={this.state.newHomeworkAccount == null || this.state.newHomeworkAccount === ""}
                                onClick={() => this.handleRecipientAdd(false)}>
                                Add
                            </Button>&nbsp;
                            <Button color="success" disabled={this.state.newHomeworkAccount == null || this.state.newHomeworkAccount === ""}
                                onClick={() => this.handleRecipientAdd(true)}>
                                Add & close
                            </Button>
                        </div>
                        <div className="border rounded form-margin">
                            <FormGroup>
                                <Label for="newHomeworkAccountGroup">Account Groups</Label>
                                <Input type="select" name="newHomeworkAccountGroup"
                                    onChange={(e) => this.setState({
                                        newHomeworkAccountGroup: e.target.value
                                    })}>
                                    <option value={""}>&nbsp;</option>
                                    {/*TODO*/}
                                    {orderedAllGroupIds.map((key) => {
                                        return (
                                            <option value={key}
                                                key={key}>{this.props.accountGroups.get(key).details.name}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup>
                            <Button color="success" disabled={this.state.newHomeworkAccountGroup == null || this.state.newHomeworkAccountGroup === ""}
                                onClick={() => this.handleRecipientGroupAdd(false)}>
                                Add
                            </Button>&nbsp;
                            <Button color="success" disabled={this.state.newHomeworkAccountGroup == null || this.state.newHomeworkAccountGroup === ""}
                                onClick={() => this.handleRecipientGroupAdd(true)}>
                                Add & close
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div >
        );
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
            subjectId: this.state.currentHomeworkSubjectId,
        });
    }

    toggle(): void {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleDropdown(): void {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }

    toggleNewInviteesModal(): void {
        this.setState({
            newInviteesView: !this.state.newInviteesView,
            newHomeworkAccountGroupFutures: true,
            newHomeworkAccountGroup: '',
            newHomeworkAccount: '',
        })
    }

    async removeItem(activityId: string): Promise<void> {
        let index = this.state.currentHomeworkActivitiesOrderedIds.indexOf(activityId);
        if (index !== -1) {
            const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
            await homeworkRef.update({
                [`activities.${activityId}`]: firebase.firestore.FieldValue.delete(),
                activityIdsOrdered: firebase.firestore.FieldValue.arrayRemove(activityId),
                dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            }).catch((error) => {
                console.log(error);
                this.props.snackbar("Save failed");
            });
            this.state.currentHomeworkActivitiesOrderedIds.splice(index, 1);
            this.state.currentHomeworkActivities.delete(activityId);
            this.setState({});
        }
    }

    async moveItemUp(activityId: string): Promise<void> {
        let index = this.state.currentHomeworkActivitiesOrderedIds.indexOf(activityId);
        if (index !== -1 && index > 0) {
            this.state.currentHomeworkActivitiesOrderedIds.splice(index, 1);
            this.state.currentHomeworkActivitiesOrderedIds.splice(index - 1, 0, activityId);
            const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
            await homeworkRef.update({
                activityIdsOrdered: this.state.currentHomeworkActivitiesOrderedIds,
                dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            }).catch((error) => {
                console.log(error);
                this.props.snackbar("Save failed");
            });

            this.setState({});
        }
    }

    closeFeedback() {
    }

    showFeedback(feedbackAccountId: string): void {
        let response = this.state.currentHomeworkResponses.get(feedbackAccountId);
        this.setState({
            showFeedback: !this.state.showFeedback,
            feedbackAccountId: feedbackAccountId,
            newFeedback: response != null ? response.feedback : "",
        });
    }

    handleLinkRemove(index: number): void {
        let newLinks = this.state.currentHomeworkLinks.slice();
        newLinks.splice(index, 1);
        this.setState({
            currentHomeworkLinks: newLinks,
        });
    }

    handleLinkAdd(): void {
        let newLinks = this.state.currentHomeworkLinks.slice();
        let newLink = new HomeLearningLink();
        newLink.name = "";
        newLink.url = "";
        newLinks.push(newLink);
        this.setState({
            currentHomeworkLinks: newLinks,
        });
    }

    handleLinkNameChange(e: React.ChangeEvent<HTMLInputElement>, index: number): void {
        let newLinks = this.state.currentHomeworkLinks.slice();
        newLinks[index].name = e.target.value;
        this.setState({
            currentHomeworkLinks: newLinks,
        });
    }

    handleLinkUrlChange(e: React.ChangeEvent<HTMLInputElement>, index: number): void {
        let newLinks = this.state.currentHomeworkLinks.slice();
        newLinks[index].url = e.target.value;
        this.setState({
            currentHomeworkLinks: newLinks,
        });
    }

    handleAttachmentChange(currentAttachments: Attachment[]): void {
        this.setState({
            currentHomeworkAttachments: currentAttachments
        });
    }

    handleAttachmentChangeFail(): void {
        this.props.snackbar("Attachment upload failed");
    }

    handleStartDateChange(date: Date): void {
        if (date != null && (this.state.currentHomeworkDueDateTime == null || date.getTime() > this.state.currentHomeworkDueDateTime.getTime())) {
            this.setState({
                currentHomeworkStartDateTime: date,
                currentHomeworkDueDateTime: null,
            });
        } else {
            this.setState({
                currentHomeworkStartDateTime: date,
            });
        }
    }

    handleEndDateChange(date: Date): void {
        this.setState({
            currentHomeworkDueDateTime: date,
        });
    }

    async handleRecipientAdd(close: boolean): Promise<void> {
        const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
        await homeworkRef.update({
            accounts: firebase.firestore.FieldValue.arrayUnion(this.state.newHomeworkAccount),
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        }).catch((error) => {
            console.log(error);
            this.props.snackbar("Save failed");
        });
        this.state.currentHomeworkAccounts.push(this.state.newHomeworkAccount);
        this.setState({
            newInviteesView: close == null ? true : !close,
        });
        this.props.snackbar();
    }

    async handleRecipientGroupAdd(close: boolean): Promise<void> {
        let newGroup = this.props.accountGroups.get(this.state.newHomeworkAccountGroup);

        let newUpdate: string[] = [];
        Array.from(newGroup.accounts.keys()).forEach((accountId) => {
            let nextDeets = newGroup.accounts.get(accountId);
            if (nextDeets.member && this.state.currentHomeworkAccounts.indexOf(accountId) === -1) {
                newUpdate.push(accountId);
            }
        });
        const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
        await homeworkRef.update({
            accounts: firebase.firestore.FieldValue.arrayUnion(...newUpdate),
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        }).catch((error) => {
            console.log(error);
            this.props.snackbar("Save failed");
        });

        this.setState({
            currentHomeworkAccounts: [...this.state.currentHomeworkAccounts, ...newUpdate],
            newInviteesView: close == null ? true : !close,
        });
        this.props.snackbar();
    }

    handleEmojiChange(emojiCharacter: string, target: string): void {
        if (target === "currentHomeworkTitle") {
            this.setState({
                currentHomeworkTitle: this.state.currentHomeworkTitle + emojiCharacter
            });
        } else if (target === "currentHomeworkDescription") {
            this.setState({
                currentHomeworkDescription: this.state.currentHomeworkDescription + emojiCharacter
            });
        } else {
            this.setState({
                newFeedback: this.state.newFeedback + emojiCharacter
            });
        }
    }

    async handleFeedback(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let url = `/homeworkResponses/${this.state.feedbackAccountId}--${this.state.homeworkId}`;
        const responseRef = firebase.firestore().doc(url);
        let update = {
            feedback: this.state.newFeedback,
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        }
        this.closeFeedback();
        this.state.currentHomeworkResponses.get(this.state.feedbackAccountId).feedback = this.state.newFeedback;
        this.state.currentHomeworkResponses.get(this.state.feedbackAccountId).reviewed = true;
        this.setState({});
        try {
            await responseRef.update(update);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
    }

    async removeRecipient(accountId: string): Promise<void> {
        let index = this.state.currentHomeworkAccounts.indexOf(accountId);
        if (index !== -1) {
            const homeworkRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
            await homeworkRef.update({
                accounts: firebase.firestore.FieldValue.arrayRemove(accountId),
                dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            }).catch((error) => {
                console.log(error);
                this.props.snackbar("Save failed");
            });
            this.state.currentHomeworkAccounts.splice(index, 1);
            this.setState({});
        }
    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        let homework = new HomeLearning();
        homework.title = this.state.currentHomeworkTitle;
        homework.description = this.state.currentHomeworkDescription;
        homework.startDateTime = this.state.currentHomeworkStartDateTime.getTime();
        homework.dueDateTime = this.state.currentHomeworkDueDateTime == null ? null : this.state.currentHomeworkDueDateTime.getTime();
        homework.schoolId = this.props.user.schoolId;
        homework.subjectId = this.state.currentHomeworkSubjectId;
        homework.attachments = this.state.currentHomeworkAttachments;
        homework.links = this.state.currentHomeworkLinks;
        if (this.state.homeworkId === "-1") {
            homework.setById = this.props.user.accountId;
        }
        homework.updatedBy = this.props.user.accountId;
        homework.disabled = false;

        await this.createHomework(homework);
    }

    async createHomework(homework: HomeLearning): Promise<void> {
        if (this.state.homeworkId === "-1") {
            homework.createdBy = this.props.user.accountId;
            const homeworkRef = firebase.firestore().collection(`homeworks`);
            try {
                let ref = await homeworkRef.add(homework.toFirebase());
                this.setState({
                    homework: homework,
                    homeworkId: ref.id,
                    collapse: true,
                    editTarget: false,
                });
                this.props.history.replace(`/homeworks/${ref.id}`);
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Save failed");
            }
        } else {
            let updateList = ["title", "description", "startDateTime", "dueDateTime", "schoolId", "subjectId", "attachments", "links", "setById", "updatedBy", "disabled", "dateUpdated"];
            const eventRef = firebase.firestore().doc(`homeworks/${this.state.homeworkId}`);
            try {
                await eventRef.update(createUpdateFragment(homework.toFirebase(), updateList));
                this.setState({
                    homework: homework,
                    collapse: true,
                    editTarget: false,
                });
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Update failed");
            }
        }
    }

    async handleSubjectChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let subjectId = e.target.value;
        this.setState({
            currentHomeworkSubjectId: subjectId,
        });
    }

    async componentDidMount(): Promise<void> {
        let haveZap = this.props.schoolLicenses.has('maths') && this.props.schoolLicenses.get('maths');
        let haveLearningNinja = this.props.schoolLicenses.has('learningNinja') && this.props.schoolLicenses.get('learningNinja');
        let haveHelpMeEnglish = this.props.schoolLicenses.has('lifeninja-englit') && this.props.schoolLicenses.get('lifeninja-englit');
        if (this.state.homeworkId !== "-1") {
            const homeworkSnapshot = await firebase.firestore().doc(`homeworks/${this.state.homeworkId}`).get();

            let homework = HomeLearning.fromFirebase(homeworkSnapshot.data());

            const responsesSnapshot = await firebase.firestore().collection(`homeworkResponses`).where("schoolId", "==", this.props.user.schoolId).where("homeworkId", "==", this.state.homeworkId).get();
            let responses = responsesSnapshot.docs;
            let responseMap = new Map<string, HomeLearningResponse>();
            for (let nextResponse of responses) {
                let nextHomeLearning = HomeLearningResponse.fromFirebase(nextResponse.data());
                responseMap.set(nextHomeLearning.accountId, nextHomeLearning);
            }

            let homeworkAccounts = homework.accounts != null ? homework.accounts : [];
            this.setState({
                homework: homework,
                currentHomeworkTitle: homework.title,
                currentHomeworkStartDateTime: homework.startDateTime != null ? new Date(homework.startDateTime) : null,
                currentHomeworkDueDateTime: homework.dueDateTime != null ? new Date(homework.dueDateTime) : null,
                currentHomeworkDescription: homework.description,
                currentHomeworkAccounts: homeworkAccounts,
                currentHomeworkAttachments: homework.attachments != null ? homework.attachments : [],
                currentHomeworkLinks: homework.links != null ? homework.links : [],
                currentHomeworkResponses: responseMap,
                currentHomeworkSubjectId: homework.subjectId != null ? homework.subjectId : null,
                currentHomeworkActivitiesOrderedIds: homework.activityIdsOrdered != null ? homework.activityIdsOrdered : [],
                currentHomeworkActivities: homework.activities != null ? homework.activities : new Map(),
                haveZap: haveZap,
                haveHelpMeEnglish: haveHelpMeEnglish,
                haveLearningNinja: haveLearningNinja,
            });
        } else {
            this.setState({
                homework: new HomeLearning(),
                currentHomeworkTitle: '',
                currentHomeworkDescription: '',
                currentHomeworkStartDateTime: null,
                currentHomeworkDueDateTime: null,
                currentHomeworkAccounts: [],
                currentHomeworkAttachments: [],
                currentHomeworkLinks: [],
                currentHomeworkSubjectId: this.state.subjectFilter,
                currentHomeworkActivitiesOrderedIds: [],
                currentHomeworkActivities: new Map(),
                haveZap: haveZap,
                haveHelpMeEnglish: haveHelpMeEnglish,
                haveLearningNinja: haveLearningNinja,
            });
        }
    }
}

export default HomeworkView;
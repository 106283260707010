import React, { Component } from 'react';
import {
    Button, FormGroup, Label, Modal, ModalHeader, ModalBody, Input, InputGroupAddon, InputGroup, Form
} from 'reactstrap';

export const LIFE_NINJA_TRANSACTION = 0.01;
export const PAYMENT_PROCESSOR_TRANSACTION = 0.025;

interface IState {
    newCost: string;
    currentCost: number;
    setCostModal: boolean;
}

interface IProps {
    currentCost: number;
    callback: (cost: number) => void;
    enabled: boolean;
    iconLegend?: string;
}

class CostEntry extends Component<IProps, IState> {
    legend: string;

    constructor(props: IProps) {
        super(props);
        this.state = {
            newCost: props.currentCost == null ? "0" : (props.currentCost / 100).toFixed(2),
            currentCost: props.currentCost == null ? 0 : props.currentCost,
            setCostModal: false,
        };
        this.legend = props.iconLegend != null && props.iconLegend.length > 0 ? props.iconLegend : "Cost";
        this.toggleCostModal = this.toggleCostModal.bind(this);
        this.handleCostSet = this.handleCostSet.bind(this);
        this.handleCostChange = this.handleCostChange.bind(this);
    }

    componentDidUpdate(prevProps: IProps, prevState: IState, snapshot: any): void {
        if (this.props.currentCost !== this.state.currentCost ||
            this.props.enabled !== prevProps.enabled) {
            this.setState({
                currentCost: this.props.currentCost,
            });
        }
    }

    render(): JSX.Element {
        let processingCost = CostEntry.getProcessingCost(parseFloat(this.state.newCost) * 100);
        let totalCost = parseFloat(this.state.newCost) * 100 - processingCost;
        let display = (this.state.currentCost / 100).toFixed(2);
        return <div>
            <Label>{this.legend}</Label>
            <FormGroup>
                {this.state.currentCost != null && this.state.currentCost > 0 ?
                    <span>£{display}&nbsp;</span>
                    : <span>No cost&nbsp;</span>
                }
                {this.props.enabled ?
                    <Button type="button" outline onClick={this.toggleCostModal}>Set cost</Button>
                    : <span />
                }
            </FormGroup>
            <Modal isOpen={this.state.setCostModal} toggle={this.toggleCostModal}>
                <ModalHeader toggle={this.toggleCostModal}>Set cost</ModalHeader>
                <ModalBody>
                    <Form id="saveCost" onSubmit={this.handleCostSet}></Form>
                    <FormGroup>
                        <Label for="newCost">Cost</Label>
                        <InputGroup>
                            <InputGroupAddon addonType={"prepend"}>
                                £
                            </InputGroupAddon>
                            <Input form="saveCost" type="number" step='0.01' min="0.01" name="newCost"
                                onChange={this.handleCostChange}
                                value={this.state.newCost} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Processing costs £{(processingCost / 100).toFixed(2)}
                        </Label><br />
                        <Label>
                            Total to be received £{(totalCost / 100).toFixed(2)}
                        </Label><br />
                        <Button outline onClick={() => {
                            let newCostWithFees = CostEntry.addProcessingCost(parseFloat(this.state.newCost) * 100);
                            this.setState({
                                newCost: (newCostWithFees / 100).toFixed(2)
                            });
                        }}>Pass processing costs to user</Button>
                    </FormGroup>
                    <div><Button form="saveCost" color="success">Set cost</Button></div>
                </ModalBody>
            </Modal>
        </div>
    }

    toggleCostModal(): void {
        this.setState({
            setCostModal: !this.state.setCostModal,
            newCost: (this.state.currentCost / 100).toFixed(2),
        })
    }

    static getProcessingCost(cost: number): number {
        let processingCost = Math.round(cost * LIFE_NINJA_TRANSACTION);
        processingCost += CostEntry.bankersRound(cost * PAYMENT_PROCESSOR_TRANSACTION);
        return processingCost;
    }

    static addProcessingCost(expectedCost: number): number {
        let newCost = Math.round(expectedCost / (1 - (LIFE_NINJA_TRANSACTION + PAYMENT_PROCESSOR_TRANSACTION)));
        let processingCost = CostEntry.getProcessingCost(newCost);
        while (newCost - processingCost !== expectedCost) {
            if (newCost - processingCost < expectedCost) {
                newCost++;
            } else {
                newCost--;
            }
            processingCost = CostEntry.getProcessingCost(newCost);
        }
        return newCost;
    }

    static bankersRound(x: number): number {
        let r = Math.round(x);
        return (((((x > 0) ? x : (-x)) % 1) === 0.5) ? (((0 === (r % 2))) ? r : (r - 1)) : r);
    }

    handleCostSet(e: React.FormEvent): void {
        e.preventDefault();
        console.log("Callback called", e);
        let updatedCost = Math.round(parseFloat(this.state.newCost) * 100);
        if (isNaN(updatedCost)) {
            updatedCost = 0
        }
        this.setState({
            currentCost: updatedCost,
            setCostModal: false,
        });
        this.props.callback(updatedCost);
    }

    handleCostChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let newInputCost = e.target.value;
        if (newInputCost === "") {
            newInputCost = "0"
        }
        this.setState({
            newCost: e.target.value
        });
    }
}

export default CostEntry;
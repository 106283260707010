import firebase from "../firebase";
import { objectToClassConverter } from "./database_object";

export class Marketplace {

    products: Map<string, ProductStub>;
    categories: Map<string, ProductCategory>;

    constructor() {
        this.products = new Map();
        this.categories = new Map();
    }

    static fromFirebase(data: any): Marketplace {
        let newMarketplace = new Marketplace();
        if (data == null) {
            return newMarketplace;
        }
        objectToClassConverter(data, newMarketplace);

        let newProductStubs = new Map<string, ProductStub>();
        if (data.products != null) {
            for (let nextProductId of Object.keys(data.products)) {
                newProductStubs.set(nextProductId, ProductStub.fromFirebase(data.products[nextProductId]));
            }
        }
        newMarketplace.products = newProductStubs;

        let newProductCategories = new Map<string, ProductCategory>();
        if (data.categories != null) {
            for (let nextCategoryId of Object.keys(data.categories)) {
                newProductCategories.set(nextCategoryId, ProductCategory.fromFirebase(data.categories[nextCategoryId]));
            }
        }
        newMarketplace.categories = newProductCategories;

        return newMarketplace;
    }
}

export class ProductStub {
    name: string = null;
    minimumPrice: number = null;
    imageUrl: string = null;
    initialStock: number = null;
    updateDate: firebase.firestore.Timestamp = null;
    categoryIds: Array<string> = null;

    static fromFirebase(data: any): ProductStub {
        let newProductStub = new ProductStub();
        if (data == null) {
            return newProductStub;
        }
        objectToClassConverter(data, newProductStub);

        return newProductStub;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        fbObject.updateDate = firebase.firestore.FieldValue.serverTimestamp();
        return fbObject;
    }
}

export class Product {
    name: string = null;
    description: string = null;
    imageUrl: string = null;
    imagePath: string = null;
    categoryIds: Array<string> = null;
    removed: boolean = null;
    variants: Map<string, ProductVariant> = null;
    variantIdsSorted: Array<string> = null;
    updateDate: firebase.firestore.Timestamp = null; // timestamp

    static fromFirebase(data: any): Product {
        let newProduct = new Product();
        if (data == null) {
            return newProduct;
        }
        objectToClassConverter(data, newProduct, [], ["variants"]);

        let newVariants = new Map<string, ProductVariant>();
        if (data.variants != null) {
            for (let nextVariantId of Object.keys(data.variants)) {
                newVariants.set(nextVariantId, ProductVariant.fromFirebase(data.variants[nextVariantId]));
            }
        }
        newProduct.variants = newVariants;

        return newProduct;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        let variantsData: any = {};
        if (this.variants != null) {
            for (let [nextVariantsId, value] of this.variants) {
                variantsData[nextVariantsId] = value.toFirebase();
            }
        }
        fbObject.variants = variantsData;

        fbObject.updateDate = firebase.firestore.FieldValue.serverTimestamp();

        return fbObject;
    }
}

export class ProductVariant {
    variantDescription: string = null;
    price: number = null; //pence
    totalStock: number = null;
    removed: boolean = null;

    static fromFirebase(data: any): ProductVariant {
        let newProductVariant = new ProductVariant();
        if (data == null) {
            return newProductVariant;
        }
        objectToClassConverter(data, newProductVariant);

        return newProductVariant;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        return fbObject;
    }
}

export class ProductCategory {
    name: string = null;

    static fromFirebase(data: any): ProductCategory {
        let newProductCategory = new ProductCategory();
        if (data == null) {
            return newProductCategory;
        }
        objectToClassConverter(data, newProductCategory);

        return newProductCategory;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        return fbObject;
    }
}

export class Purchase {
    title: string = null;
    orderId: string = null;
    accountId: string = null;
    subAccountId: string = null;
    orderDate: firebase.firestore.Timestamp = null;
    updateDate: firebase.firestore.Timestamp = null;
    cost: number = null; // pence
    count: number = null;
    productId: string = null;
    variantId: string = null;
    received: boolean = null;

    static fromFirebase(data: any): Purchase {
        let newPurchase = new Purchase();
        if (data == null) {
            return newPurchase;
        }
        objectToClassConverter(data, newPurchase);

        return newPurchase;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);

        fbObject.updateDate = firebase.firestore.FieldValue.serverTimestamp();

        return fbObject;
    }
}
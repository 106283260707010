//@ts-check

import React, { Component } from 'react';
import firebase from '../firebase';
import queryString from 'query-string';
import {
    Button, Form, FormGroup, Label, Input,
    Breadcrumb, BreadcrumbItem, Alert, UncontrolledTooltip, InputGroup, InputGroupAddon, Spinner, CustomInput, Card, CardBody
} from 'reactstrap';
import { Link } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import { User, USER_ROLE } from '../data/user';
import { v4 as uuidv4 } from 'uuid';
import { Product, ProductVariant } from '../data/marketplace';
import { EngagementAward } from '../data/engagement';
import ImageUpload from '../ninja_components/image_upload';
import EmojiSelection from '../ninja_components/emoji_selection';

interface IState {
    awardTitle: string;
    awardDescription: string;
    awardPoints: number;
    awardCost: number;
    awardImageUrl: string;
    awardImagePath: string;
    awardUserCreateable: boolean;
    awardId: string;
    loaded: boolean;
}

interface MatchParams {
    awardId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    user: User;
    snackbar: (text?: string) => void;
}

class AwardView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            awardTitle: "",
            awardCost: 0,
            awardDescription: "",
            awardImagePath: "",
            awardImageUrl: "",
            awardPoints: 0,
            awardId: props.match.params.awardId,
            loaded: false,
            awardUserCreateable: false,
        };

        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleEmojiChange = this.handleEmojiChange.bind(this);
        this.storeAward = this.storeAward.bind(this);
        this.handleImageChangeFail = this.handleImageChangeFail.bind(this);
    }

    render(): JSX.Element {
        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/engagementAwards'}>All engagement awards</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.state.awardTitle != null ? this.state.awardTitle : ""}</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Engagement award</div>
                    <p className="cardSubTitle">An engagement award can be linked to a news item, home learning feedback, chat message, or avatar.</p>
                    {
                        (this.state.loaded) ?
                            <div>
                                <Form onSubmit={this.handleUpdate}>
                                    <FormGroup>
                                        <Label for="awardTitle">Title *</Label>
                                        <InputGroup>
                                            <Input type="text" required name="awardTitle" onChange={(e) => this.setState({
                                                awardTitle: e.target.value
                                            })} value={this.state.awardTitle} />
                                            <InputGroupAddon addonType={"prepend"}>
                                                <EmojiSelection callback={(emoji) => {
                                                    this.handleEmojiChange(emoji);
                                                }} />
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="awardDescription">Description *</Label>
                                        <InputGroup>
                                            <Input type="textarea" required name="awardDescription" onChange={(e) => this.setState({
                                                awardDescription: e.target.value
                                            })} value={this.state.awardDescription} />
                                        </InputGroup>
                                    </FormGroup>
                                    <ImageUpload callback={this.handleImageChange} failureCallback={this.handleImageChangeFail} schoolId={this.props.user.schoolId} filePath={"awardImages"}
                                        currentImage={{ url: this.state.awardImageUrl, path: this.state.awardImagePath }} />
                                    <FormGroup>
                                        <CustomInput id='awardUserCreateable' type="switch" checked={this.state.awardUserCreateable} name='awardUserCreateable' label="Can be created by users?" onChange={(e) => this.setState({
                                            awardUserCreateable: e.target.checked
                                        })} />
                                    </FormGroup>
                                    {this.state.awardUserCreateable ?
                                        <FormGroup>
                                            <Label for="awardCost">Cost for users to create</Label>
                                            <Input type="number" name="awardCost" min={0} onChange={(e) => this.setState({
                                                awardCost: e.target.value === "" ? 0 : parseInt(e.target.value)
                                            })} value={this.state.awardCost} />
                                        </FormGroup> : <span />
                                    }
                                    <FormGroup>
                                        <Label for="awardPoints">Points received</Label>
                                        <Input type="number" name="awardPoints" min={0} onChange={(e) => this.setState({
                                            awardPoints: e.target.value === "" ? 0 : parseInt(e.target.value)
                                        })} value={this.state.awardPoints} />
                                    </FormGroup>
                                    {this.state.awardId === "-1" ?
                                        <Button className="adminPagesButton">Create</Button> :
                                        <div>
                                            <Button className="adminPagesButton">Update</Button>&nbsp;
                                </div>
                                    }
                                </Form>
                            </div> : <Spinner></Spinner>
                    }
                </CardBody>
            </Card>
        </div>

    }

    async handleUpdate(e: React.FormEvent): Promise<void> {
        e.preventDefault(); // Prevents page refresh
        let award = new EngagementAward();
        award.title = this.state.awardTitle;
        award.description = this.state.awardDescription;
        award.cost = this.state.awardCost;
        award.imagePath = this.state.awardImagePath;
        award.imageUrl = this.state.awardImageUrl;
        award.points = this.state.awardPoints;
        award.removed = false;

        let awardId = this.state.awardId;
        let newAward = false;
        if (this.state.awardId === "-1") {
            newAward = true;
            awardId = uuidv4();
        }
        try {
            await this.storeAward(newAward, award, awardId);

            this.props.history.replace(`/engagementAwards/${awardId}`);
            this.props.snackbar();
        } catch (error) {
            console.log(error);
            this.props.snackbar("Save failed");
        }
        this.setState({
            awardId: awardId
        });
    }

    async handleImageChange(url: string, path: string): Promise<void> {
        if (this.state.awardId !== "-1") {
            let imageDeets = {
                imageUrl: url,
                imagePath: path,
            };
            const awardRef = firebase.firestore().doc(`engagementAwards/${this.props.user.schoolId}/awards/${this.state.awardId}`);
            try {
                await awardRef.update(imageDeets);
                this.setState({
                    awardImageUrl: url,
                    awardImagePath: path,
                });
                this.props.snackbar();
            } catch (error) {
                console.log(error);
                this.props.snackbar("Update failed");
            }
        } else {
            this.setState({
                awardImageUrl: url,
                awardImagePath: path,
            });
        }
    }

    handleImageChangeFail(): void {
        this.props.snackbar("Image upload failed");
    }

    handleEmojiChange(emojiCharacter: string): void {
        this.setState({
            awardTitle: this.state.awardTitle + emojiCharacter
        });
    }

    async storeAward(newAward: boolean, award: EngagementAward, awardId: string) {
        await firebase.firestore().runTransaction(async (batch) => {
            let overviewPath = `engagementAwards/${this.props.user.schoolId}`;
            let productPath = `${overviewPath}/awards/${awardId}`;

            batch.set(firebase.firestore().doc(overviewPath), {
                awardsOverview: {
                    [awardId]: award.title,
                },
                updated: firebase.firestore.FieldValue.serverTimestamp(),
            }, { merge: true });

            batch.set(firebase.firestore().doc(productPath), award.toFirebase());
        });
    }

    async componentDidMount(): Promise<void> {
        try {
            if (this.state.awardId !== "-1") {
                let awardSnapshot = await firebase.firestore().doc(`engagementAwards/${this.props.user.schoolId}/awards/${this.state.awardId}`).get();
                let awardData = awardSnapshot.data();
                let award = EngagementAward.fromFirebase(awardData);
                this.setState({
                    awardTitle: award.title,
                    awardCost: award.cost,
                    awardDescription: award.description,
                    awardImagePath: award.imagePath,
                    awardImageUrl: award.imageUrl,
                    awardPoints: award.points,
                    awardUserCreateable: award.userCreateable,
                    loaded: true,
                });
            } else {
                this.setState({
                    awardTitle: "",
                    awardCost: 0,
                    awardDescription: "",
                    awardImagePath: "",
                    awardImageUrl: "",
                    awardPoints: 0,
                    awardUserCreateable: false,
                    loaded: true,
                });
            }
        } catch (error) {
            console.log(error);
        }

    }
}

export default AwardView;
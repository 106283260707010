import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { User } from "../data/user";
import firebase from '../firebase';
import { Button, Alert, Form, FormGroup, Label, Input, Collapse, Card, CardHeader, CardBody, Spinner, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { AccountGroup, AccountMinimised } from "../data/accounts";
import { LearningGameOutcome, StudentTopicOverview } from "../data/home_learning";
import { TableDetails, DataTableView } from "./data_table";
import { subjectIds } from '../Prettifier';
import queryString from 'query-string';
import Chart from "react-google-charts";
import { ReactGoogleChartEvent } from "react-google-charts/dist/types";
import { LearningGame, LearningPath, LearningWorld } from "../data/learning_games";
import { Link } from "react-router-dom";

interface PassFailData {
    accountId: string;
    pass: number;
    fail: number;
}

interface IProps extends RouteComponentProps {
    user: User;
    snackbar: (text?: string) => void;
    accountsMinimised: Map<string, AccountMinimised>;
    accountGroups: Map<string, AccountGroup>;
}

interface IState {
    dataLoaded: boolean;
    dataLoading: boolean;
    newReportAccountGroups: Array<string>;
    newAccountIdList: Array<string>;
    newReportSubjectId: string;
    newReportWorldId: string;
    newReportPathId: string;
    zapPaths: boolean;
    showTableDetails: boolean;
    tableDetails: TableDetails;
    availableAccountIds: Array<string>;
    topicAnalysis: Map<string, StudentTopicOverview>;
    showReportDetails: boolean;
    worlds: Map<string, LearningWorld>;
    worldIdsSorted: Array<string>;
    paths: Map<string, LearningPath>;
    pathIdsSorted: Array<string>;
    games: Map<string, LearningGame>;
    passFailReportData: string[][];
    passFailChartEvents: ReactGoogleChartEvent[];
    engagementReportData: string[][];
    engagementChartEvents: ReactGoogleChartEvent[];
    timeReportData: string[][];
    timeChartEvents: ReactGoogleChartEvent[];
    collapse: boolean;
    loadingWorlds: boolean;
    loadingPaths: boolean;
    userOutcomes: Map<string, Array<LearningGameOutcome>>;
}

class LearningPathAnalysisReportView extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        let subjectId = values.appFilter == null ? "" : values.appFilter === "ZapMaths" ? "maths" : "";
        this.state = {
            dataLoaded: false,
            dataLoading: false,
            newReportAccountGroups: [],
            newAccountIdList: [],
            newReportSubjectId: subjectId,
            zapPaths: values.appFilter == null ? false : values.appFilter === "ZapMaths" ? true : false,
            newReportWorldId: "",
            newReportPathId: "",
            showTableDetails: false,
            availableAccountIds: [],
            topicAnalysis: new Map(),
            collapse: true,
            showReportDetails: true,
            tableDetails: null,
            passFailReportData: null,
            passFailChartEvents: null,
            engagementReportData: null,
            engagementChartEvents: null,
            timeChartEvents: null,
            timeReportData: null,
            worlds: new Map(),
            worldIdsSorted: [],
            paths: new Map(),
            pathIdsSorted: [],
            games: new Map(),
            loadingWorlds: false,
            loadingPaths: false,
            userOutcomes: null,
        };
        this.handleReportChange = this.handleReportChange.bind(this);
        this.selectAllAccounts = this.selectAllAccounts.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleAccountGroupChange = this.handleAccountGroupChange.bind(this);
        this.toggleShowReportDetails = this.toggleShowReportDetails.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.createLearningGamePassReport = this.createLearningGamePassReport.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
        this.handlePathChange = this.handlePathChange.bind(this);
        this.handleWorldChange = this.handleWorldChange.bind(this);
        this.loadGames = this.loadGames.bind(this);
        this.loadOutcomes = this.loadOutcomes.bind(this);
        this.loadPaths = this.loadPaths.bind(this);
        this.loadWorlds = this.loadWorlds.bind(this);
        this.createLearningGameEngagementReport = this.createLearningGameEngagementReport.bind(this);

        if (subjectId !== "") {
            this.loadWorlds(subjectId);
        }
    }

    render(): JSX.Element {

        let queryValues = this.getQuerys();

        let currentWorld = this.state.newReportWorldId == null || this.state.worlds == null ? null : this.state.worlds.get(this.state.newReportWorldId);

        return <div>
            <div className="top-buffer">
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/reporting/zapMathsReporting'}>All Reports</Link></BreadcrumbItem>
                    <BreadcrumbItem>Learning Path Analysis Report</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="mainCard">
                <CardBody className="d-flex flex-column">
                    <div className="cardTitle">Learning Path Analysis Report</div>
                    <p className="cardSubTitle">View student engagement, flawless, and time played within a specific learning path.</p>

                    {this.state.dataLoaded ?
                        <Button onClick={this.toggleShowReportDetails} className="altButton" style={{ width: '9%', float: 'right' }}>
                            {this.state.showReportDetails ? "Close" : "Edit report"}
                        </Button> : null
                    }
                    <Collapse isOpen={this.state.showReportDetails}>
                        <Form onSubmit={this.handleReportChange}>
                            <FormGroup>
                                <Label for="newReportAccountGroups">Account groups *</Label>
                                <Input type="select" required name="newReportAccountGroups" multiple onChange={this.handleAccountGroupChange} value={this.state.newReportAccountGroups}>
                                    {Array.from(this.props.accountGroups.keys()).map((accountGroupId) => {
                                        return (
                                            <option value={accountGroupId} key={accountGroupId}>{this.props.accountGroups.get(accountGroupId).details.name}</option>
                                        )
                                    })}
                                </Input><br />
                            </FormGroup>
                            <Collapse isOpen={!this.state.collapse}>
                                <FormGroup>
                                    <Label for="newAccountIdList">Accounts * <Button type="button" onClick={this.selectAllAccounts} color="secondary">Select all</Button></Label>
                                    <Input type="select" required name="newAccountIdList" multiple onChange={this.handleAccountChange} value={this.state.newAccountIdList}>
                                        {this.state.availableAccountIds.map((accountId) => {
                                            let account = this.props.accountsMinimised.get(accountId);
                                            if (account == null) {
                                                return null;
                                            }
                                            return (
                                                <option value={accountId} key={accountId}>{account.name}</option>
                                            )
                                        })
                                        }
                                    </Input><br />
                                </FormGroup>
                            </Collapse>
                            <FormGroup>
                                <Label for="newReportSubjectId">Subject *</Label>
                                <Input required type="select" name="newReportSubjectId"
                                    onChange={this.handleSubjectChange}
                                    value={this.state.newReportSubjectId}>
                                    <option value={''}>&nbsp;</option>
                                    {Array.from(subjectIds.keys()).map(subjectId => {
                                        let subjectName = subjectIds.get(subjectId);
                                        return <option value={subjectId}>{subjectName}</option>;
                                    })}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="newReportWorldId">Learning world * {
                                    this.state.newReportWorldId !== "" ?
                                        <Link to={`/learningWorlds/${this.state.newReportWorldId}?${queryValues}`}>&nbsp;View</Link> :
                                        <Link to={`/learningWorlds/-1?${queryValues}`}>&nbsp;Create</Link>
                                }
                                </Label>
                                <Input type="select" name="newReportWorldId"
                                    onChange={this.handleWorldChange}
                                    disabled={this.state.newReportSubjectId == null || this.state.newReportSubjectId === "" || this.state.loadingWorlds}
                                    value={this.state.newReportWorldId}>
                                    <option value={''}>{this.state.loadingWorlds ? "Loading..." : this.state.worldIdsSorted.length === 0 ? "None" : "Select learning world"}</option>
                                    {this.state.worldIdsSorted.map((worldId) => {
                                        let world = this.state.worlds.get(worldId);
                                        return (
                                            <option value={worldId}
                                                key={worldId}>{world.name}</option>
                                        )
                                    })
                                    }
                                </Input>
                            </FormGroup>

                            {this.state.newReportWorldId === "" ? <span /> :
                                <FormGroup>
                                    <Label for="currentHomeworkPathId">Learning path {
                                        this.state.newReportPathId !== "" ? <Link to={`/learningPaths/${this.state.newReportPathId}?${queryValues}&worldId=${this.state.newReportWorldId}&worldName=${currentWorld != null ? currentWorld.name : ""}`}>&nbsp;View</Link> :
                                            <Link to={`/learningPaths/-1?${queryValues}&worldId=${this.state.newReportPathId}&worldName=${currentWorld != null ? currentWorld.name : ""}`}>&nbsp;Create</Link>
                                    }
                                    </Label>
                                    <Input type="select" name="currentHomeworkPathId"
                                        onChange={this.handlePathChange}
                                        disabled={this.state.loadingPaths}
                                        value={this.state.newReportPathId}>
                                        <option value={''}>{this.state.loadingPaths ? "Loading..." : this.state.pathIdsSorted.length === 0 ? "None" : "Select learning path"}</option>
                                        {this.state.pathIdsSorted.map((pathId) => {
                                            let path = this.state.paths.get(pathId);
                                            return (
                                                <option value={pathId}
                                                    key={pathId}>{path.name}</option>
                                            )
                                        })
                                        }
                                    </Input>
                                </FormGroup>
                            }
                            <Button className="adminPagesButton">{this.state.dataLoading ? <Spinner size="sm"></Spinner> : <span />}Run report</Button>
                        </Form>
                    </Collapse>
                </CardBody>
            </Card>
            {this.state.dataLoaded ?
                <React.Fragment>
                    <Card className="mainCard top-buffer">
                        <CardBody className="d-flex flex-column">
                            <div className="cardTitle2">{this.state.newAccountIdList.length} student{this.state.newAccountIdList.length !== 1 ? "s" : ""} included in report</div>
                            <Chart
                                chartType="ColumnChart"
                                chartEvents={this.state.engagementChartEvents}
                                width="100%"
                                height="400px"
                                data={this.state.engagementReportData}
                                options={{
                                    title: "Student engagement",
                                    vAxis: {
                                        gridlines: {
                                            count: -1
                                        },
                                        minValue: 0
                                    },
                                    animation: {
                                        startup: true,
                                        easing: 'out',
                                        duration: 500,
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>
                    <Card className="mainCard top-buffer">
                        <CardBody className="d-flex flex-column">
                            <Chart
                                chartType="ColumnChart"
                                chartEvents={this.state.passFailChartEvents}
                                width="100%"
                                height="400px"
                                data={this.state.passFailReportData}
                                options={{
                                    title: "Student flawless completion",
                                    vAxis: {
                                        gridlines: {
                                            count: -1
                                        },
                                        minValue: 0
                                    },
                                    animation: {
                                        startup: true,
                                        easing: 'out',
                                        duration: 500,
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>
                    <Card className="mainCard top-buffer">
                        <CardBody className="d-flex flex-column">
                            <Chart
                                chartType="ColumnChart"
                                chartEvents={this.state.timeChartEvents}
                                width="100%"
                                height="400px"
                                data={this.state.timeReportData}
                                options={{
                                    title: "Student time playing",
                                    vAxis: {
                                        gridlines: {
                                            count: -1
                                        },
                                        minValue: 0
                                    },
                                    animation: {
                                        startup: true,
                                        easing: 'out',
                                        duration: 500,
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>
                </React.Fragment> : <span />
            }
            {this.state.dataLoaded && this.state.tableDetails != null ?
                <Card className="mainCard top-buffer">
                    <CardBody className="d-flex flex-column">
                        <DataTableView tableDetails={this.state.tableDetails} ></DataTableView>
                    </CardBody>
                </Card> : <span />
            }
            <br /><br />
        </div>
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.newReportSubjectId,
        });
    }

    async handleSubjectChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let subjectId = e.target.value;
        this.setState({
            newReportSubjectId: subjectId,
            newReportWorldId: "",
            newReportPathId: "",
        });
        await this.loadWorlds(subjectId);
    }

    async handleWorldChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let worldId = e.target.value;
        this.setState({
            newReportWorldId: worldId,
        });
        await this.loadPaths(worldId);
    }

    async handlePathChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let pathId = e.target.value;
        this.setState({
            newReportPathId: pathId,
        });
        await this.loadGames(pathId);
    }

    toggleShowReportDetails(): void {
        this.setState({
            showReportDetails: !this.state.showReportDetails
        });
    }

    async handleReportChange(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        this.setState({
            dataLoading: true,
        });

        await this.loadOutcomes();

        this.setState({
            showTableDetails: true,
            showReportDetails: false,
            dataLoaded: true,
            dataLoading: false,
        });
    }

    async loadWorlds(subjectId: string): Promise<void> {
        this.setState({
            loadingWorlds: true,
        });

        const schoolLearningWorldsRef = firebase.firestore().collection('learningWorlds').where('schoolId', '==', this.props.user.schoolId).where('subject', '==', subjectId);
        let schoolLearningWorldsSnapshot = await schoolLearningWorldsRef.get();

        let newLearningWorldsState = new Map<string, LearningWorld>();
        schoolLearningWorldsSnapshot.forEach((nextLearningWorldSnapshot) => {
            newLearningWorldsState.set(nextLearningWorldSnapshot.id, LearningWorld.fromFirebase(nextLearningWorldSnapshot.data()));
        });

        const sharedLearningWorldsRef = firebase.firestore().collection('learningWorlds').where('schoolId', '==', null).where('subject', '==', subjectId);
        let sharedLearningWorldsSnapshot = await sharedLearningWorldsRef.get();

        sharedLearningWorldsSnapshot.forEach((nextLearningWorldSnapshot) => {
            newLearningWorldsState.set(nextLearningWorldSnapshot.id, LearningWorld.fromFirebase(nextLearningWorldSnapshot.data()));
        });

        let newLearningWorldsSorted: string[] = Array.from(newLearningWorldsState.keys());

        newLearningWorldsSorted.sort((worldAId, worldBId) => {
            let worldA = newLearningWorldsState.get(worldAId);
            let worldB = newLearningWorldsState.get(worldBId);
            return worldA.name.localeCompare(worldB.name);
        });

        this.setState({
            loadingWorlds: false,
            worlds: newLearningWorldsState,
            worldIdsSorted: newLearningWorldsSorted
        });
    }

    async loadPaths(worldId: string) {
        this.setState({
            loadingPaths: true,
        });

        let world = this.state.worlds.get(worldId);

        let promises: Promise<void>[] = [];
        let paths = new Map<string, LearningPath>();
        for (let nextPathIndex in world.pathIds) {
            let nextPathId = world.pathIds[nextPathIndex];
            console.log("Getting path", nextPathId);
            const pathRef = firebase.firestore().doc(`learningPaths/${nextPathId}`);
            promises.push(new Promise((resolve, reject) => {
                pathRef.get().then((pathSnapshot) => {
                    let path = LearningPath.fromFirebase(pathSnapshot.data());
                    if (!this.state.zapPaths || (this.state.zapPaths && path.zapEnabled)) {
                        paths.set(nextPathId, path);
                    }
                    resolve();
                }, (error) => {
                    console.log(error);
                    reject();
                })
            }));
        }
        await Promise.all(promises);

        let pathIdsSorted: string[] = Array.from(paths.keys());

        pathIdsSorted.sort((pathAId, pathBId) => {
            let pathA = paths.get(pathAId);
            let pathB = paths.get(pathBId);
            return pathA.name.localeCompare(pathB.name);
        });

        this.setState({
            paths: paths,
            loadingPaths: false,
            pathIdsSorted: pathIdsSorted,
        });
    }

    async loadGames(pathId: string) {
        let path = this.state.paths.get(pathId);

        let promises: Promise<void>[] = [];
        let games = new Map<string, LearningGame>();
        for (let nextGameId of path.gameIds) {
            const pathRef = firebase.firestore().doc(`learningGames/${nextGameId}`);
            promises.push(new Promise((resolve, reject) => {
                pathRef.get().then((pathSnapshot) => {
                    games.set(nextGameId, LearningGame.fromFirebase(pathSnapshot.data()));
                    resolve();
                }, (error) => {
                    console.log(error);
                    reject();
                })
            }));
        }
        await Promise.all(promises);

        this.setState({
            games: games,
        });
    }

    async loadOutcomes() {
        let userOutcomesPromiseList = [];
        for (let i = 0; i < this.state.newAccountIdList.length; i++) {
            let nextAccountId = this.state.newAccountIdList[i];
            userOutcomesPromiseList.push(
                firebase.firestore().collection(`learningGameOutcomes`)
                    .where('accountId', '==', nextAccountId)
                    .where('worldId', '==', this.state.newReportWorldId)
                    .where('pathId', '==', this.state.newReportPathId)
                    .get()
            );
        }

        try {
            let snapshots = await Promise.all(userOutcomesPromiseList);
            let userOutcomes = new Map<string, Array<LearningGameOutcome>>();
            for (let i = 0; i < snapshots.length; i++) {
                let nextSnapshot = snapshots[i];

                nextSnapshot.forEach(function (doc) {
                    let outcome = LearningGameOutcome.fromFirebase(doc.data());
                    if (!userOutcomes.has(outcome.accountId)) {
                        userOutcomes.set(outcome.accountId, new Array<LearningGameOutcome>());
                    }
                    userOutcomes.get(outcome.accountId).push(outcome);
                });
            }

            await this.createLearningGamePassReport(userOutcomes);
            await this.createLearningGameEngagementReport(userOutcomes);
            await this.createLearningGameTimeReport(userOutcomes);

            this.setState({
                dataLoaded: true,
                userOutcomes: userOutcomes,
            });
        } catch (error) {
            console.log(error);
            this.props.snackbar("Load data failed");
        }
    }

    createLearningGamePassReport(userOutcomes: Map<string, Array<LearningGameOutcome>>) {

        let studentBuckets = new Map<string, PassFailData>();

        for (let accountId of this.state.newAccountIdList) {
            studentBuckets.set(accountId, { accountId: accountId, pass: 0, fail: 0 });
            if (userOutcomes.has(accountId)) {
                for (let nextOutcome of userOutcomes.get(accountId)) {
                    if (nextOutcome.flawless) {
                        studentBuckets.get(accountId).pass++;
                    } else {
                        studentBuckets.get(accountId).fail++;
                    }
                }
            }
        }

        let resultData: Array<any> = [["Student", "Flawless", "Not flawless"]];
        for (let nextPassFail of Array.from(studentBuckets.values())) {
            let account = this.props.accountsMinimised.get(nextPassFail.accountId);
            if (account != null) {
                resultData.push([account.name, nextPassFail.pass, nextPassFail.fail],);
            }
        }

        const chartEvents: ReactGoogleChartEvent[] = [
            {
                eventName: "select",
                callback: async ({ chartWrapper }) => {
                    if (chartWrapper.getChart().getSelection() == null || chartWrapper.getChart().getSelection().length === 0) {
                        this.setState({
                            showTableDetails: false,
                        });
                    } else {
                        let selection = chartWrapper.getChart().getSelection()[0];
                        let startRow = selection.row;
                        let rowCount = 1;
                        if (selection.row == null) {
                            startRow = 0;
                            rowCount = studentBuckets.size;
                        }

                        let data = new Array<any>();
                        for (let bucketIndex = startRow; bucketIndex < startRow + rowCount; bucketIndex++) {
                            let accountId = this.state.newAccountIdList[bucketIndex];
                            let gameIdsList = this.state.paths.get(this.state.newReportPathId).gameIds;

                            let account = this.props.accountsMinimised.get(accountId);
                            if (account != null) {

                                for (let gameId of gameIdsList) {
                                    let pass = 0;
                                    let fail = 0;
                                    for (let nextOutcome of userOutcomes.get(accountId)) {
                                        if (nextOutcome.gameId === gameId) {
                                            if (nextOutcome.flawless) {
                                                pass++;
                                            } else {
                                                fail++;
                                            }
                                        }
                                    }

                                    data.push([account.name, this.state.games.get(gameId).title, pass, fail]);
                                }
                            }
                        }

                        let tableDetails: TableDetails = { headers: ["Student", "Learning game", "Flawless", "Not flawless"], data: data };

                        this.setState({
                            showTableDetails: true,
                            tableDetails: tableDetails,
                        });
                    }
                }
            }
        ];

        this.setState({
            passFailChartEvents: chartEvents,
            passFailReportData: resultData,
        });
    }

    createLearningGameEngagementReport(userOutcomes: Map<string, Array<LearningGameOutcome>>) {

        let studentBuckets = new Map<string, boolean>();

        for (let accountId of this.state.newAccountIdList) {
            studentBuckets.set(accountId, false);
            if (userOutcomes.has(accountId)) {
                for (let nextOutcome of userOutcomes.get(accountId)) {
                    if (nextOutcome.completed) {
                        studentBuckets.set(accountId, true);
                    }
                }
            }
        }

        let resultData: Array<any> = [["Student", "Engaged"]];
        for (let accountId of Array.from(studentBuckets.keys())) {
            let account = this.props.accountsMinimised.get(accountId);
            if (account != null) {
                resultData.push([account.name, studentBuckets.get(accountId) ? 1 : 0],);
            }
        }

        const chartEvents: ReactGoogleChartEvent[] = [
            {
                eventName: "select",
                callback: async ({ chartWrapper }) => {
                    if (chartWrapper.getChart().getSelection() == null || chartWrapper.getChart().getSelection().length === 0) {
                        this.setState({
                            showTableDetails: false,
                        });
                    } else {
                        let selection = chartWrapper.getChart().getSelection()[0];
                        let startRow = selection.row;
                        let rowCount = 1;
                        if (selection.row == null) {
                            startRow = 0;
                            rowCount = studentBuckets.size;
                        }

                        let data = new Array<any>();
                        for (let bucketIndex = startRow; bucketIndex < startRow + rowCount; bucketIndex++) {
                            let accountId = this.state.newAccountIdList[bucketIndex];
                            let gameIdsList = this.state.paths.get(this.state.newReportPathId).gameIds;

                            let account = this.props.accountsMinimised.get(accountId);
                            if (account != null) {

                                for (let gameId of gameIdsList) {
                                    let engaged = false;
                                    let fail = 0;
                                    if (userOutcomes.has(accountId)) {
                                        for (let nextOutcome of userOutcomes.get(accountId)) {
                                            if (nextOutcome.gameId === gameId) {
                                                engaged = true;
                                                break;
                                            }
                                        }
                                    }

                                    data.push([account.name, this.state.games.get(gameId).title, engaged ? "Yes" : ""]);
                                }
                            }
                        }

                        let tableDetails: TableDetails = { headers: ["Student", "Learning game", "Engaged"], data: data };

                        this.setState({
                            showTableDetails: true,
                            tableDetails: tableDetails,
                        });
                    }
                }
            }
        ];

        this.setState({
            engagementChartEvents: chartEvents,
            engagementReportData: resultData,
        });
    }

    createLearningGameTimeReport(userOutcomes: Map<string, Array<LearningGameOutcome>>) {

        let studentBuckets = new Map<string, number>();

        for (let accountId of this.state.newAccountIdList) {
            studentBuckets.set(accountId, 0);
            if (userOutcomes.has(accountId)) {
                for (let nextOutcome of userOutcomes.get(accountId)) {
                    if (nextOutcome.completed) {
                        studentBuckets.set(accountId, (nextOutcome.endDateTime - nextOutcome.startDateTime) / 1000);
                    }
                }
            }
        }

        let resultData: Array<any> = [["Student", "Seconds played"]];
        for (let accountId of Array.from(studentBuckets.keys())) {
            let account = this.props.accountsMinimised.get(accountId);
            if (account != null) {
                resultData.push([account.name, studentBuckets.get(accountId)],);
            }
        }

        const chartEvents: ReactGoogleChartEvent[] = [
            {
                eventName: "select",
                callback: async ({ chartWrapper }) => {
                    if (chartWrapper.getChart().getSelection() == null || chartWrapper.getChart().getSelection().length === 0) {
                        this.setState({
                            showTableDetails: false,
                        });
                    } else {
                        let selection = chartWrapper.getChart().getSelection()[0];
                        let startRow = selection.row;
                        let rowCount = 1;
                        if (selection.row == null) {
                            startRow = 0;
                            rowCount = studentBuckets.size;
                        }

                        let data = new Array<any>();
                        for (let bucketIndex = startRow; bucketIndex < startRow + rowCount; bucketIndex++) {
                            let accountId = this.state.newAccountIdList[bucketIndex];
                            let gameIdsList = this.state.paths.get(this.state.newReportPathId).gameIds;

                            let account = this.props.accountsMinimised.get(accountId);
                            if (account != null) {

                                for (let gameId of gameIdsList) {
                                    let time = 0;
                                    let count = 0;
                                    for (let nextOutcome of userOutcomes.get(accountId)) {
                                        if (nextOutcome.gameId === gameId) {
                                            time += nextOutcome.endDateTime - nextOutcome.startDateTime;
                                            count++;
                                        }
                                    }

                                    let timeSeconds: number = parseFloat((time / 1000).toFixed(2));
                                    let averageSeconds: number = count == 0 ? 0 : parseFloat((time / count / 1000).toFixed(2));
                                    data.push([account.name, this.state.games.get(gameId).title, timeSeconds, averageSeconds]);
                                }
                            }
                        }

                        let tableDetails: TableDetails = { headers: ["Student", "Learning game", "Play time (s)", "Average game time (s)"], data: data };

                        this.setState({
                            showTableDetails: true,
                            tableDetails: tableDetails,
                        });
                    }
                }
            }
        ];

        this.setState({
            timeChartEvents: chartEvents,
            timeReportData: resultData,
        });
    }

    handleAccountChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newAccountIdList.indexOf(e.target.value);
        if (index > -1) {
            this.state.newAccountIdList.splice(index, 1);
        } else {
            this.state.newAccountIdList.push(e.target.value);
        }
        this.setState({});
    }

    selectAllAccounts(): void {
        this.setState({
            newAccountIdList: this.state.availableAccountIds.slice(),
        })
    }

    handleAccountGroupChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let index = this.state.newReportAccountGroups.indexOf(e.target.value);
        if (index > -1) {
            this.state.newReportAccountGroups.splice(index, 1);
        } else {
            this.state.newReportAccountGroups.push(e.target.value);
        }
        let accountIdList = [];
        for (let nextAccountGroupId of this.state.newReportAccountGroups) {
            let nextAccountGroup = this.props.accountGroups.get(nextAccountGroupId);
            if (nextAccountGroup != null) {
                for (let nextAccountId of nextAccountGroup.accounts.keys()) {
                    let nextAccountDeets = nextAccountGroup.accounts.get(nextAccountId);
                    if (nextAccountDeets.member != null && nextAccountDeets.member &&
                        accountIdList.indexOf(nextAccountId) === -1 && this.props.accountsMinimised.has(nextAccountId)) {
                        accountIdList.push(nextAccountId);
                    }
                }
            }
        }
        this.setState({
            availableAccountIds: accountIdList,
            collapse: false,
            newAccountIdList: [],
        });
    }
}

export default LearningPathAnalysisReportView;
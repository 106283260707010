import React, {Component} from 'react';
import {
    Button, Modal,
} from 'reactstrap';
import EmojiPicker, { IEmojiData } from 'emoji-picker-react';

interface IState {
    showEmojiModal: boolean;
}

interface IProps {
    iconLegend?: string;
    callback: (character: string) => void;
}

class EmojiSelection extends Component<IProps, IState> {
    legend: string;

    constructor(props: IProps) {
        super(props);
        this.state = {
            showEmojiModal: false,
        };
        this.legend = props.iconLegend != null && props.iconLegend.length > 0 ? props.iconLegend : "Icon";
        this.handleEmojiSelect = this.handleEmojiSelect.bind(this);
        this.toggleEmojiModal = this.toggleEmojiModal.bind(this);
    }

    render(): JSX.Element {
        return <React.Fragment>
            <Button type="button" outline onClick={this.toggleEmojiModal}><i className="material-icons material-icons-xd">insert_emoticon</i></Button>
            <Modal isOpen={this.state.showEmojiModal} toggle={this.toggleEmojiModal}>
                <EmojiPicker onEmojiClick={this.handleEmojiSelect}/>
            </Modal>
        </React.Fragment>
    }

    handleEmojiSelect(e: MouseEvent, data: IEmojiData): void {
        let value = data.emoji;
        
        this.toggleEmojiModal();
        this.props.callback(value);
    }

    toggleEmojiModal(): void {
        this.setState({
            showEmojiModal: !this.state.showEmojiModal
        });
    }
}

export default EmojiSelection;
import { Objective, ObjectiveCategory, ObjectiveGroup, SubjectObjectives, ukMathsCurriculum } from "../data/objectives";

export function getObjectives(): Map < string, SubjectObjectives> {
    // let counting = new Objective();
    // counting.title = "";
    // counting.description = "Count to and across 100, forwards and backwards, beginning with 0 or 1, or from any given number";
    // counting.topicTags = new Map();
    // counting.topicTags.set("-LvQfY2DVvQeYskFYWYr", ["backwards_0_20", "backwards_20", "forwards_0_20", "forwards_20"]);

    // let doubling = new Objective();
    // doubling.title = "";
    // doubling.description = "Count, read and write numbers to 100 in numerals; count in multiples of twos, fives and tens";
    // doubling.topicTags = new Map();
    // doubling.topicTags.set("-LvFjJu-lPBEJy_cloTp", ["reading_one-hundred", "writing_one-hundred"]);
    // doubling.topicTags.set("-LvQfY2DVvQeYskFYWYr", ["count_2s", "count_5s", "count_10s"]);

    // let numberPlaceGroup = new ObjectiveGroup();
    // numberPlaceGroup.title = "Number and place value";
    // numberPlaceGroup.objectives = new Map([["counting", counting], ["doubling", doubling]]);

    // let statements = new Objective();
    // statements.title = "";
    // statements.description = "Read, write and interpret mathematical statements involving addition (+), subtraction (–) and equals (=) signs";
    // statements.topicTags = new Map();
    // statements.topicTags.set("-LvFhtqsm6zP084UtLjL", ["addition", "subtraction", "equals"]);

    // let addSubtractgroup = new ObjectiveGroup();
    // addSubtractgroup.title = "Addition and subtraction";
    // addSubtractgroup.objectives = new Map([["statements", statements]]);

    // let category = new ObjectiveCategory();
    // category.objectiveGroups = new Map([["number_place", numberPlaceGroup], ["add_subtract", addSubtractgroup]]);
    // category.title = "Year 1";

    let subjectObjectives = SubjectObjectives.fromFirebase(ukMathsCurriculum);

    return new Map([["maths", subjectObjectives]]);
}
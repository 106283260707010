import React, { Component } from 'react';
import firebase from './firebase';
import { Link } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, Card, CardBody } from 'reactstrap';
import moment from "moment";
import confirm from "reactstrap-confirm";
import { EventMinimised } from './data/events';
import { User } from './data/user';

interface IState {
    events: Map<string, EventMinimised>;
    eventIdsSorted: string[];
    moreEvents: boolean;
}

interface IProps {
    tutorialCallback: (pageName: string) => void;
    user: User;
    snackbar: (text?: string) => void;
}

class EventsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            events: new Map(),
            eventIdsSorted: [],
            moreEvents: true,
        };

        this.removeItem = this.removeItem.bind(this);
        this.getNextEventsBatch = this.getNextEventsBatch.bind(this);
    }

    render(): JSX.Element {
        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All events</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Events</div>
                        <p className="cardSubTitle">All the events that have been shared with accounts. These appear in the events feed of users linked to the accounts.</p>
                        <div>
                            <Link to={'/events/-1'}>
                                <Button className="adminPagesButton" type="button" onClick={() => this.props.tutorialCallback('events')}>
                                    Create event
                        </Button>
                            </Link>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Recipients</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.eventIdsSorted.map((eventId) => {
                                    let event = this.state.events.get(eventId);
                                    if (event == null) {
                                        return null;
                                    }
                                    return <tr>
                                        <th scope="row"><Link to={'events/' + eventId}>{event.title}</Link></th>
                                        <td>{this.makeShortString(event.description)}</td>
                                        <td>{event.startDateTime == null ? "Unset" : moment(event.startDateTime).format("ddd, MMM Do YYYY, h:mm a")}<br /></td>
                                        <td>{event.endDateTime == null ? "Unset" : moment(event.endDateTime).format("ddd, MMM Do YYYY, h:mm a")}</td>
                                        <td>{event.recipients}</td>
                                        <td><Button color="link" onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this event", confirmText: "Confirm" });
                                            if (result) {
                                                this.removeItem(eventId)
                                            }
                                        }}><i className="material-icons">delete</i></Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        {this.state.moreEvents ?
                            <div>
                                <p>Showing {this.state.eventIdsSorted.length}</p>
                                <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.getNextEventsBatch(false);
                                    }}>
                                    Show more
                        </Button>
                            </div> : <span />
                        }
                        <br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    makeShortString(description: string): string {
        if (description == null) {
            return "";
        }
        if (description.length < 30) {
            return description;
        }
        let descriptionResult = description.substr(0, 30);
        let spacePos = descriptionResult.lastIndexOf(" ");
        if (spacePos !== -1) {
            descriptionResult = descriptionResult.substr(0, spacePos);
        }
        descriptionResult += "...";

        return descriptionResult;
    }

    componentDidMount(): void {
        this.getNextEventsBatch(true);
    }

    async getNextEventsBatch(seed: boolean): Promise<void> {
        let accountsRef = firebase.database().ref(`schoolEvents/eventsMinimised/${this.props.user.schoolId}`).orderByChild("startDateTime").startAt(true);
        if (seed == null || !seed) {
            // Find last event
            let lastId = this.state.eventIdsSorted[this.state.eventIdsSorted.length - 1];
            if (lastId != null) {
                accountsRef = accountsRef.endAt(this.state.events.get(lastId).startDateTime);
            }
        }
        accountsRef = accountsRef.limitToLast(10);
        try {
            let snapshot = await accountsRef.once('value');
            let newMoreEvents = this.state.moreEvents;
            let eventsData = snapshot.val();

            let newEvents = new Map<string, EventMinimised>();
            let newEventIdsSorted: string[] = [];
            if (eventsData != null) {
                if (Object.keys(eventsData).length < 10) {
                    newMoreEvents = false;
                }
                for (let nextEventId in eventsData) {
                    if (this.state.eventIdsSorted.indexOf(nextEventId) == -1) {
                        newEventIdsSorted.push(nextEventId);
                        newEvents.set(nextEventId, EventMinimised.fromFirebase(eventsData[nextEventId]));
                    }
                }
            } else {
                newMoreEvents = false;
            }
            newEventIdsSorted.sort((a, b) => {
                return newEvents.get(b).startDateTime - newEvents.get(a).startDateTime;
            });

            this.setState({
                eventIdsSorted: [...this.state.eventIdsSorted, ...newEventIdsSorted],
                events: new Map([...this.state.events, ...newEvents]),
                moreEvents: newMoreEvents,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(eventId: string): Promise<void> {
        try {
            const eventDeactivatedRef = firebase.database().ref(`schoolEvents/events/${this.props.user.schoolId}/${eventId}/deactivated`);
            await eventDeactivatedRef.set(true);

            this.state.events.delete(eventId);

            this.setState({});
            this.props.snackbar("Deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }
}
export default EventsView;
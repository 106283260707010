//@ts-check

import React, { Component } from 'react';
import {
    Button, FormGroup, Label, Input, Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { Topic } from '../data/home_learning';
import firebase from "../firebase";
import { YearGroup, yearGroups } from "../Prettifier";

interface IState {
    worldId: string;
    currentTopics: string[];
    currentTopicsTags: Map<string, string[]>;
    subject: string;
    currentTopic: string;
    currentTopicTags: Map<string, string[]>;
    topicsOrdered: string[];
    topics: Map<string, Topic>;
    showTagSelector: boolean;
    showYearGroupSelector: boolean;
    newTag: string;
}

interface IProps {
    subject: string;
    currentTopics: string[];
    currentTopicsTags: Map<string, string[]>;
    worldId?: string;
    countryCode?: string;
    callback: (topics: string[], topicsTags: Map<string, string[]>) => void;
}

class TopicsSelector extends Component<IProps, IState> {
    regionalYearGroups: Map<string, YearGroup>;
    allYearGroupTags: string[];

    constructor(props: IProps) {
        super(props);
        let topicId = props.currentTopics == null || props.currentTopics.length === 0 ? null : props.currentTopics[0];
        let topicTags = props.currentTopicsTags == null ? new Map<string, string[]>() : props.currentTopicsTags;
        let countryCode = props.countryCode == null ? "GB" : props.countryCode;
        if (yearGroups.has(countryCode)) {
            countryCode = "GB";
        }
        this.regionalYearGroups = yearGroups.get(countryCode);
        let newAllYearGroupTags = [];
        for (let nextRegionId of Array.from(yearGroups.keys())) {
            newAllYearGroupTags.push(...Array.from(yearGroups.get(nextRegionId).keys()));
        }
        this.allYearGroupTags = newAllYearGroupTags;
        this.state = {
            worldId: props.worldId,
            currentTopics: props.currentTopics,
            currentTopicsTags: props.currentTopicsTags,
            subject: props.subject,
            currentTopic: topicId,
            currentTopicTags: topicTags,
            topicsOrdered: null,
            topics: null,
            showTagSelector: false,
            showYearGroupSelector: false,
            newTag: null,
        };
        this.setToggleTag = this.setToggleTag.bind(this);
        this.prepareTopics = this.prepareTopics.bind(this);
        this.fireCallback = this.fireCallback.bind(this);
        this.handleTopicChange = this.handleTopicChange.bind(this);
    }

    componentDidMount(): void {
        this.prepareTopics(this.props.subject);
    }

    componentWillReceiveProps(nextProps: IProps): void {
        if (nextProps.subject !== this.state.subject) {
            this.setState({
                subject: nextProps.subject,
                currentTopic: null,
                currentTopicTags: null,
            });
            this.prepareTopics(nextProps.subject);
            this.props.callback([], new Map());
        }
    }

    async prepareTopics(subject: string): Promise<void> {
        console.log("Preparing topics");
        if (subject == null && this.state.worldId != null) {
            const worldRef = firebase.firestore().doc(`learningWorlds/${this.state.worldId}`);
            let worldSnapshot = await worldRef.get();
            let world = worldSnapshot.data();
            subject = world.subject;
        }

        const topicsRef = firebase.firestore().collection(`learningTopics`).where('subjectId', '==', subject);
        let topicSnapshot = await topicsRef.get();
        let topicsOrdered = new Array<string>();
        let topics = new Map<string, Topic>();
        topicSnapshot.forEach((docSnapshot) => {
            let nextTopics = docSnapshot.data();
            Object.keys(nextTopics.topics).forEach((topicId) => {
                let nextTopic = Topic.fromFirebase(nextTopics.topics[topicId]);
                topicsOrdered.push(topicId);
                topics.set(topicId, nextTopic);
            });
        });
        topicsOrdered.sort((id1, id2) => {
            return topics.get(id1).name.localeCompare(topics.get(id2).name);
        });
        this.setState({
            subject: subject,
            topicsOrdered: topicsOrdered,
            topics: topics,
        });
    }

    render(): JSX.Element {
        if (this.state.topics == null) {
            return <div>
                <FormGroup>
                    <Label>Topic</Label>
                    <div>Loading...</div>
                </FormGroup>
            </div>
        }
        let orderedTagIds = new Array<string>();
        if (this.state.currentTopic != null && this.state.topics.has(this.state.currentTopic) && this.state.topics.get(this.state.currentTopic).tags != null && this.state.topics.get(this.state.currentTopic).tags.size != 0) {
            orderedTagIds = Array.from(this.state.topics.get(this.state.currentTopic).tags.keys());
            orderedTagIds = orderedTagIds.filter((tagId) => {
                // Remove suggested year groups
                return this.allYearGroupTags.indexOf(tagId) === -1;
            });
            orderedTagIds.sort((id1, id2) => {
                let tag1 = this.state.topics.get(this.state.currentTopic).tags.get(id1);
                let tag2 = this.state.topics.get(this.state.currentTopic).tags.get(id2);
                return tag1.localeCompare(tag2);
            });
        }
        return <div>
            <FormGroup>
                <Label for="currentTopic">Topic</Label>
                <Input required type="select" name="currentTopic"
                    onChange={this.handleTopicChange}
                    value={this.state.currentTopic}>
                    <option key={'def'}>Select topic</option>
                    {this.state.topicsOrdered.map((topicId) => {
                        let topic = this.state.topics.get(topicId);
                        return  <option value={topicId} key={topicId}>{topic.name}</option>
                    })}
                </Input>
            </FormGroup>
            <FormGroup>
                {this.state.currentTopicTags == null || !this.state.currentTopicTags.has(this.state.currentTopic) ? <span /> :
                    <span>
                        {this.state.currentTopicTags.get(this.state.currentTopic).map((tagId) => {
                            let topic = this.state.topics.get(this.state.currentTopic);
                            if (topic == null) {
                                return;
                            }
                            let tagName;
                            if (this.allYearGroupTags.indexOf(tagId) === -1) {
                                tagName = topic.tags.get(tagId);
                            } else {
                                tagName = this.regionalYearGroups.get(tagId).name;
                            }
                            if (tagName == null) {
                                console.log("Tag not found ", tagId);
                                return;
                            }
                            return <span><Button key={tagId}
                                color={'success'}
                                onClick={() => this.setToggleTag(tagId)}>
                                {tagName}&nbsp;&nbsp;<b>X</b>
                            </Button>&nbsp;</span>;
                        })}
                    </span>
                }
                {this.state.currentTopic == null ? <span /> : !this.state.topics.has(this.state.currentTopic) || this.state.topics.get(this.state.currentTopic).tags == null || this.state.topics.get(this.state.currentTopic).tags.size === 0 ?
                    <span>No tags available</span> :
                    <span>
                        <Button key={"addTag"}
                            color={'primary'}
                            onClick={() => this.setState({ showTagSelector: true })}>
                            {"Add tag  +"}
                        </Button>&nbsp;
                        <Button key={"addYearGroup"}
                            color={'primary'}
                            onClick={() => this.setState({ showYearGroupSelector: true })}>
                            {"Add year group  +"}
                        </Button>
                        <Modal isOpen={this.state.showTagSelector || this.state.showYearGroupSelector} toggle={() => this.setState({ showTagSelector: false, showYearGroupSelector: false })}>
                            <ModalHeader toggle={() => this.setState({ showTagSelector: false, showYearGroupSelector: false })}>Add tag</ModalHeader>
                            <ModalBody>
                                <Label for="newTag">Tags</Label>
                                <Input required type="select" name="newTag"
                                    onChange={(e) => this.setState({
                                        newTag: e.target.value
                                    })}
                                    value={this.state.newTag}>
                                    <option value={""} key={'def'}>Select tag</option>
                                    {this.state.showTagSelector ? orderedTagIds.map((tagId) => {
                                        if (this.state.currentTopicTags.has(this.state.currentTopic) &&
                                            this.state.currentTopicTags.get(this.state.currentTopic).indexOf(tagId) !== -1) {
                                            return null;
                                        }
                                        let tag = this.state.topics.get(this.state.currentTopic).tags.get(tagId);
                                        return (
                                            <option value={tagId} key={tagId}>{tag}</option>
                                        )
                                    })
                                        : Array.from(this.regionalYearGroups.keys()).map((tagId) => {
                                            let tag = this.regionalYearGroups.get(tagId);
                                            if (tag.errorTag != null) {
                                                return;
                                            }
                                            if (this.state.currentTopicTags.has(this.state.currentTopic) &&
                                                this.state.currentTopicTags.get(this.state.currentTopic).indexOf(tagId) !== -1) {
                                                return null;
                                            }

                                            return (
                                                <option value={tagId} key={tagId}>{tag.name}</option>
                                            )
                                        })
                                    }
                                </Input>
                                <br />
                                <Button color="success"
                                    disabled={this.state.newTag == null || this.state.newTag === ""}
                                    onClick={() => {
                                        this.setToggleTag(this.state.newTag);
                                        this.setState({
                                            newTag: null,
                                        });
                                    }}>
                                    Add
                                </Button>&nbsp;
                                <Button color="success"
                                    disabled={this.state.newTag == null || this.state.newTag === ""}
                                    onClick={() => {
                                        this.setToggleTag(this.state.newTag);
                                        this.setState({
                                            showTagSelector: false,
                                            showYearGroupSelector: false,
                                            newTag: null,
                                        });
                                    }}>
                                    Add & close
                                </Button>
                            </ModalBody>
                        </Modal>
                    </span>
                }
            </FormGroup>
        </div>
    }

    setToggleTag(tagId: string): void {
        if (!this.state.currentTopicTags.has(this.state.currentTopic)) {
            this.state.currentTopicTags.set(this.state.currentTopic, []);
        }
        let index = this.state.currentTopicTags.get(this.state.currentTopic).indexOf(tagId);
        if (index === -1) {
            this.state.currentTopicTags.get(this.state.currentTopic).push(tagId);
        } else {
            this.state.currentTopicTags.get(this.state.currentTopic).splice(index, 1);
        }
        this.setState({});
        this.fireCallback(this.state.currentTopic, this.state.currentTopicTags);
    }

    fireCallback(topicId: string, topicTags: Map<string, string[]>): void {
        topicId == null ? this.props.callback([], new Map()) :
            this.props.callback([topicId], topicTags);
    }

    handleTopicChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let topicId = e.target.value;
        let topicList = new Map<string, string[]>();
        this.setState({
            currentTopic: topicId,
            currentTopicTags: topicList,
        });
        this.fireCallback(topicId, topicList);
    }
}

export default TopicsSelector;
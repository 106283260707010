import React, { Component } from 'react';
import firebase from './firebase';
import { Link, RouteComponentProps } from "react-router-dom";
import { Table, Button, BreadcrumbItem, Breadcrumb, Alert, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import moment from "moment";
import confirm from "reactstrap-confirm";
import queryString from 'query-string';
import { subjectIds } from './Prettifier';
import { HomeLearning } from './data/home_learning';
import { User } from './data/user';

interface IState {
    homeworks: Map<string, HomeLearning>;
    homeworkIdsSorted: string[];
    moreHomeworks: boolean;
    subjectFilter: string;
}

interface IProps extends RouteComponentProps {
    tutorialCallback: (pageName: string) => void;
    user: User;
    snackbar: (text?: string) => void;
}

class HomeworksView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const values = queryString.parse(props.location.search);
        this.state = {
            homeworks: new Map(),
            homeworkIdsSorted: [],
            moreHomeworks: true,
            subjectFilter: values.subjectFilter == null ? "" : values.subjectFilter.toString(),
        };

        this.removeItem = this.removeItem.bind(this);
        this.getNextHomeworksBatch = this.getNextHomeworksBatch.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.getQuerys = this.getQuerys.bind(this);
    }

    render(): JSX.Element {
        let queryValues = this.getQuerys();

        return (
            <div>
                <div className="top-buffer">
                    <Breadcrumb>
                        <BreadcrumbItem active>All homework</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="mainCard">
                    <CardBody className="d-flex flex-column">
                        <div className="cardTitle">Homework</div>
                        <p className="cardSubTitle">All the homework that have been shared with account groups. These appear in the zap or learning ninja apps.</p>
                        <FormGroup>
                            <Label for="subjectFilter">Subject filter</Label>

                            <Input type="select" name="subjectFilter"
                                onChange={this.updateFilter}
                                value={this.state.subjectFilter}>
                                <option value={''}>All</option>
                                {Array.from(subjectIds.keys()).map((key) => {
                                    let name = subjectIds.get(key);
                                    return (
                                        <option value={key} key={key}>{name}</option>
                                    )
                                })
                                }
                            </Input>
                        </FormGroup>
                        <div>
                            <Link to={`/homeworks/-1?${queryValues}`}>
                                <Button className="adminPagesButton" type="button" onClick={() => this.props.tutorialCallback('homeworks')}>
                                    Create homework
                        </Button>
                            </Link>
                        </div>
                        <br />
                        <Table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Subject</th>
                                    <th>Start date</th>
                                    <th>Due date</th>
                                    <th>Recipients</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.homeworkIdsSorted.map((homeworkId) => {
                                    let homework = this.state.homeworks.get(homeworkId);
                                    if (homework == null) {
                                        return null;
                                    }
                                    return <tr>
                                        <th scope="row"><Link to={`homeworks/${homeworkId}?${queryValues}`}>{homework.title}</Link></th>
                                        <td>{this.makeShortString(homework.description)}</td>
                                        <td>{subjectIds.get(homework.subjectId)}</td>
                                        <td>{homework.startDateTime == null ? "" : moment(homework.startDateTime).format("ddd, MMM Do YYYY")}<br /></td>
                                        <td>{homework.dueDateTime == null ? "" : moment(homework.dueDateTime).format("ddd, MMM Do YYYY")}</td>
                                        <td>{homework.accounts == null ? "0" : homework.accounts.length}</td>
                                        <td><Button color="link" onClick={async () => {
                                            let result = await confirm({ title: "Confirm", message: "Please confirm you want to delete this homework", confirmText: "Confirm" });
                                            if (result) {
                                                this.removeItem(homeworkId)
                                            }
                                        }}><i className="material-icons">delete</i></Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table><br />
                        {this.state.moreHomeworks ?
                            <div>
                                <p>Showing {this.state.homeworkIdsSorted.length}</p>
                                <Button className="adminPagesButton" type="button"
                                    onClick={() => {
                                        this.getNextHomeworksBatch(false, this.state.subjectFilter);
                                    }}>
                                    Show more
                        </Button>
                            </div> : <span />
                        }
                        <br />
                    </CardBody>
                </Card>
            </div>
        );
    }

    getQuerys(): string {
        return queryString.stringify({
            subjectFilter: this.state.subjectFilter,
        });
    }

    async updateFilter(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        let newSubject = e.target.value;
        await this.getNextHomeworksBatch(true, newSubject);
        this.setState({
            subjectFilter: newSubject
        });
    }

    makeShortString(description: string): string {
        if (description == null) {
            return "";
        }
        if (description.length < 30) {
            return description;
        }
        let descriptionResult = description.substr(0, 30);
        let spacePos = descriptionResult.lastIndexOf(" ");
        if (spacePos !== -1) {
            descriptionResult = descriptionResult.substr(0, spacePos);
        }
        descriptionResult += "...";

        return descriptionResult;
    }

    componentDidMount(): void {
        this.getNextHomeworksBatch(true, this.state.subjectFilter);
    }

    async getNextHomeworksBatch(seed: boolean, subjectFilter: string): Promise<void> {
        if (seed != null && seed) {
            this.setState({
                homeworks: new Map(),
                homeworkIdsSorted: [],
                moreHomeworks: true,
            });
        }
        let homeworksRef = firebase.firestore().collection(`homeworks`)
            .where('schoolId', '==', this.props.user.schoolId)
            .where('disabled', '==', false);

        if (subjectFilter !== "") {
            homeworksRef = homeworksRef.where("subjectId", "==", subjectFilter);
        }
        homeworksRef = homeworksRef.orderBy("startDateTime")
        if (seed == null || !seed) {
            // Find last homework
            let lastId = this.state.homeworkIdsSorted[this.state.homeworkIdsSorted.length - 1];
            if (lastId != null) {
                homeworksRef = homeworksRef.endAt(this.state.homeworks.get(lastId).startDateTime);
            }
            homeworksRef = homeworksRef.limitToLast(11);
        } else {
            homeworksRef = homeworksRef.limitToLast(10);
        }
        try {
            let snapshot = await homeworksRef.get();
            let newMoreHomeworks = this.state.moreHomeworks;
            let homeworks = snapshot.docs;

            let newHomeworks = new Map<string, HomeLearning>();
            let newHomeworkIdsSorted: string[] = [];
            if (homeworks != null) {
                for (let nextHomeworkSnapshot of homeworks) {
                    let nextHomeworkId = nextHomeworkSnapshot.id;
                    if (!this.state.homeworks.has(nextHomeworkId)) {
                        newHomeworks.set(nextHomeworkId, HomeLearning.fromFirebase(nextHomeworkSnapshot.data()));
                        newHomeworkIdsSorted.push(nextHomeworkId);
                    }
                }
            }
            newHomeworkIdsSorted.reverse();

            if (newHomeworkIdsSorted.length < 10) {
                newMoreHomeworks = false;
            }
            this.setState({
                homeworkIdsSorted: [...this.state.homeworkIdsSorted, ...newHomeworkIdsSorted],
                homeworks: new Map([...this.state.homeworks, ...newHomeworks]),
                moreHomeworks: newMoreHomeworks,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async removeItem(homeworkId: string): Promise<void> {
        try {
            const homeworkDeactivatedRef = firebase.firestore().doc(`homeworks/${homeworkId}`);
            await homeworkDeactivatedRef.update({ disabled: true });

            this.state.homeworks.delete(homeworkId);

            this.setState({});
            this.props.snackbar("Deleted");
        } catch (error) {
            console.log(error);
            this.props.snackbar("Delete failed");
        }
    }
}
export default HomeworksView;
import { mapToObjectConverter, objectToClassConverter } from "./database_object";

export enum NEW_MEMBER_LOGIN_TYPE { username = "username", email = "email", both = "both"}

export class OrganisationDetails {
    name: string = null;
    verified: boolean = null;
    pendingVerification: boolean = null; // Name change verification
    type: string = null;
    supportEmail: string = null;
    placeId: string = null;
    placeAddress: string = null;
    imageUrl: string = null;
    imagePath: string = null;
    phone: string = null;
    currency: string = null;
    countryCode: string = null;
    public: boolean = null;
    publicId: string = null;
    newMemberAccountGroups: Map<string, boolean> = null;
    newMemberLicenses: Map<string, boolean> = null;
    newMemberLoginType: NEW_MEMBER_LOGIN_TYPE = null;

    toFirebase(): {} {
        let fbGame: any = Object.assign({}, this);
        if (this.newMemberAccountGroups != null) {
            fbGame['newMemberAccountGroups'] = mapToObjectConverter(this.newMemberAccountGroups);
            fbGame['newMemberLicenses'] = mapToObjectConverter(this.newMemberLicenses);
        }
        return fbGame;
    }

    static fromFirebase(data: any): OrganisationDetails {
        let newOrganisationDetails = new OrganisationDetails();
        if (data == null) {
            return newOrganisationDetails;
        }
        objectToClassConverter(data, newOrganisationDetails, ["newMemberAccountGroups", "newMemberLicenses"]);

        return newOrganisationDetails;
    }
}

export class OrganisationCreation extends OrganisationDetails {
    contact: OrganisationContactDetails = null;
    signup: OrganisationReferenceDetails = null;
}

export class OrganisationContactDetails {
    email: string = null;
    firstName: string = null;
    surname: string = null;
}

export class OrganisationReferenceDetails {
    referenceSource: string = null;
    referenceAdditional: string = null;
    tosConfirmed: boolean = null;
    licenseSelection: string[] = null;
}
import firebase from '../firebase';
import { objectToClassConverter } from './database_object';
import { Attachment } from './home_learning';

export class NewsItemMinimised {
    title: string = null;
    description: string = null;
    dateUpdated: number = null;
    dateMinimisedUpdated: number = null;
    recipients: number = null;

    static fromFirebase(data: any): NewsItemMinimised {
        let newNewsItemMinimised = new NewsItemMinimised();
        if (data == null) {
            return newNewsItemMinimised;
        }
        objectToClassConverter(data, newNewsItemMinimised);

        return newNewsItemMinimised;
    }
}

export class NewsItem {

    constructor() {
        this.details = new NewsItemDetails();
        this.responses = new NewsItemResponses();
    }

    accounts: Map<string, NewsItemAccount> = null;
    accountGroups: Map<string, NewsItemAccountGroup> = null;
    details: NewsItemDetails = null;
    responses: NewsItemResponses = null;

    static fromFirebase(data: any): NewsItem {
        let newNewsItem = new NewsItem();
        if (data == null) {
            return newNewsItem;
        }
        objectToClassConverter(data, newNewsItem, [], ["accounts", "accountGroups", "details", "responses"]);

        let newAccounts = new Map<string, NewsItemAccount>();
        if (data.accounts != null) {
            for (let nextAssignmentId of Object.keys(data.accounts)) {
                newAccounts.set(nextAssignmentId, NewsItemAccount.fromFirebase(data.accounts[nextAssignmentId]));
            }
        }
        newNewsItem.accounts = newAccounts;

        let newAccountGroups = new Map<string, NewsItemAccountGroup>();
        if (data.accountGroups != null) {
            for (let nextAssignmentId of Object.keys(data.accountGroups)) {
                newAccountGroups.set(nextAssignmentId, NewsItemAccountGroup.fromFirebase(data.accountGroups[nextAssignmentId]));
            }
        }
        newNewsItem.accountGroups = newAccountGroups;

        newNewsItem.details = NewsItemDetails.fromFirebase(data.details);
        newNewsItem.responses = NewsItemResponses.fromFirebase(data.responses);

        return newNewsItem;
    }
}

export class NewsItemDetails {
    imageUrl: string = null;
    imagePath: string = null;
    title: string = null;
    description: string = null;
    dateUpdated: number = null;
    iconCodePoint: number = null;
    iconCodeString: string = null;
    iconFontFamily: string = null;
    iconFontPackage: string = null;
    sentFrom: string = null;
    webLink: string = null;
    sentFromId: string = null;
    showAvatar: boolean = null;
    attachments: Attachment[] = null;
    restricted: boolean = null;
    reward: NewsItemReward = null;

    static fromFirebase(data: any): NewsItemDetails {
        let newNewsDetails = new NewsItemDetails();
        if (data == null) {
            return newNewsDetails;
        }
        objectToClassConverter(data, newNewsDetails, [], ["attachments"]);

        let newAttachments = new Array<Attachment>();
        if (data.attachments != null) {
            for (let nextAttachmentId of Object.keys(data.attachments)) {
                newAttachments.push(Attachment.fromFirebase(data.attachments[nextAttachmentId]));
            }
        }
        newNewsDetails.attachments = newAttachments;

        if (data.reward != null) {
            newNewsDetails.reward = NewsItemReward.fromFirebase(data.reward);
        }

        return newNewsDetails;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        fbObject['dateUpdated'] = firebase.database.ServerValue.TIMESTAMP;
        if (this.reward != null) {
            fbObject['reward'] = this.reward.toFirebase();
        }
        return fbObject;
    }
}

export class NewsItemReward {
    points: number = null;
    reason: string = null;
    awardId: string = null;
    imageUrl: string = null;

    static fromFirebase(data: any): NewsItemReward {
        let newNewsItemReward = new NewsItemReward();
        if (data == null) {
            return newNewsItemReward;
        }
        objectToClassConverter(data, newNewsItemReward);

        return newNewsItemReward;
    }

    toFirebase(): {} {
        let fbObject: any = Object.assign({}, this);
        return fbObject;
    }
}

export class NewsItemAccount {
    recipient: boolean = null;
    updated: number = null;

    static fromFirebase(data: any): NewsItemAccount {
        let newNewsItemAccount = new NewsItemAccount();
        if (data == null) {
            return newNewsItemAccount;
        }
        objectToClassConverter(data, newNewsItemAccount);

        return newNewsItemAccount;
    }
}

export class NewsItemAccountGroup {
    futureMembers: boolean = null;
    updated: number = null;

    static fromFirebase(data: any): NewsItemAccountGroup {
        let newNewsItemAccountGroup = new NewsItemAccountGroup();
        if (data == null) {
            return newNewsItemAccountGroup;
        }
        objectToClassConverter(data, newNewsItemAccountGroup);

        return newNewsItemAccountGroup;
    }
}

export class NewsItemResponses {
    likes: Map<string, boolean> = null;
    read: Map<string, boolean> = null;

    static fromFirebase(data: any): NewsItemResponses {
        let newNewsItemResponse = new NewsItemResponses();
        if (data == null) {
            return newNewsItemResponse;
        }
        objectToClassConverter(data, newNewsItemResponse, ["likes", "read"]);

        return newNewsItemResponse;
    }
}